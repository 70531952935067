import type { ElementType, ReactNode } from 'react';
import { forwardRef, useId, useLayoutEffect } from 'react';

import { usePopoverContext } from 'hooks/contexts/usePopoverContext';

type PopoverDescriptionProps = {
  /** The element to wrap the children with */
  element: ElementType;
  /** The content for the description */
  children: ReactNode;
};

const PopoverDescription = forwardRef<HTMLElement, PopoverDescriptionProps>(({ element: Element, ...props }, ref) => {
  const { setDescriptionId } = usePopoverContext();
  const id = useId();

  /*
   * Only sets `aria-describedby` on the Popover root element
   * if this component is mounted inside it.
   */
  useLayoutEffect(() => {
    setDescriptionId(id);
    return () => setDescriptionId(undefined);
  }, [id, setDescriptionId]);

  return <Element {...props} id={id} ref={ref} />;
});

export default PopoverDescription;
