import { memo } from 'react';

import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { RooftopIntegration, RooftopIntegrationSettings } from 'enums/columns/rooftopIntegration';
import { useUser } from 'hooks/contexts/useUser';
import type { RooftopIntegrationFragment } from 'store/api/graph/interfaces/types';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const {
  ABOUT_US_URL,
  CONTACT_US_URL,
  ALL_INVENTORY_URL,
  NEW_INVENTORY_URL,
  USED_INVENTORY_URL,
  CPO_INVENTORY_URL,
  SERVICE_URL,
  SERVICE_MENU_URL,
  SERVICE_APPOINTMENT_URL,
  TIRE_FINDER_URL,
  TIRE_REQUEST_QUOTE_URL,
  PARTS_REQUEST_URL,
  PERFORMANCE_PARTS_URL,
  ACCESSORIES_URL,
  MAP_DIRECTIONS_URL,
  SPECIALS_URL,
} = RooftopIntegration;

const { BOLD } = ItemGridRowDisplayType;

const gridConfig = (isWhiteLabelScoped: boolean): ItemGridRowProps[] => [
  {
    fields: [
      ABOUT_US_URL,
      CONTACT_US_URL,
      ALL_INVENTORY_URL,
      NEW_INVENTORY_URL,
      USED_INVENTORY_URL,
      CPO_INVENTORY_URL,
      SERVICE_URL,
      SERVICE_MENU_URL,
      SERVICE_APPOINTMENT_URL,
      TIRE_FINDER_URL,
      TIRE_REQUEST_QUOTE_URL,
      PARTS_REQUEST_URL,
      PERFORMANCE_PARTS_URL,
      ACCESSORIES_URL,
      MAP_DIRECTIONS_URL,
      SPECIALS_URL,
    ],
    displayType: [BOLD],
    canEdit: isWhiteLabelScoped,
  },
];

export const RooftopIntegrationURLsTab = memo<ItemTabProps<RooftopIntegrationFragment>>(
  ({ item: rooftopIntegration, onEdit }) => {
    const { isWhiteLabelScoped } = useUser();

    return rooftopIntegration ? (
      <>
        {gridConfig(isWhiteLabelScoped).map(config => (
          <ItemGridRowSection
            config={{ ...config }}
            item={rooftopIntegration}
            key={formatItemGridRowSectionKey(config.fields)}
            onEdit={authorizedCallback({
              cb: (field?: string) => onEdit(field, translate.t('integration_other')),
              isAuth: !!onEdit,
            })}
            settings={RooftopIntegrationSettings}
          />
        ))}
      </>
    ) : null;
  }
);
