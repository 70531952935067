import { memo, type SVGProps } from 'react';

const CircleMinusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M8.137 13.137h7.726c.319 0 .588-.11.808-.329.22-.22.329-.489.329-.808a1.1 1.1 0 0 0-.33-.808 1.1 1.1 0 0 0-.808-.33H8.138a1.1 1.1 0 0 0-.808.33A1.1 1.1 0 0 0 7 12c0 .32.11.589.33.808.219.22.488.33.807.33ZM12 22.203a9.946 9.946 0 0 1-3.983-.803 10.3 10.3 0 0 1-3.238-2.179 10.3 10.3 0 0 1-2.18-3.238A9.945 9.945 0 0 1 1.798 12c0-1.415.267-2.743.803-3.983a10.3 10.3 0 0 1 2.179-3.238 10.3 10.3 0 0 1 3.238-2.18A9.945 9.945 0 0 1 12 1.798c1.415 0 2.743.267 3.983.803a10.3 10.3 0 0 1 3.238 2.179 10.3 10.3 0 0 1 2.18 3.238A9.946 9.946 0 0 1 22.202 12a9.946 9.946 0 0 1-.803 3.983 10.3 10.3 0 0 1-2.179 3.238 10.3 10.3 0 0 1-3.238 2.18 9.946 9.946 0 0 1-3.983.802Zm0-2.275c2.217 0 4.093-.767 5.627-2.3 1.534-1.535 2.301-3.41 2.301-5.628 0-2.217-.767-4.093-2.3-5.627-1.535-1.534-3.41-2.301-5.628-2.301-2.217 0-4.093.767-5.627 2.3C4.839 7.908 4.072 9.783 4.072 12c0 2.217.767 4.093 2.3 5.627 1.535 1.534 3.41 2.301 5.628 2.301Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(CircleMinusIcon);
