import LoggingService from 'components/core/logging/LoggingService';
import { type Intl, translate } from 'utils/intlUtils';

/**
 * More info on errors: https://edealer-wiki.netlify.app/documentation/error-handling.html
 */
export interface ApiError {
  name?: string;
  extensions?: {
    classification?: string;
    fields: string[];
    debug?: string;
    status?: number;
  };
  message?: string;
}

export default interface ApiErrors {
  errors: ApiError[];
}

/** Get an array of errors from an api response */
export const getApiErrors = (e: any): ApiError[] => e?.networkError?.result?.errors || [e?.networkError];

/** Get the first error object that contains a message field */
export const getValidApiErrorObject = (errors: any) =>
  (Array.isArray(errors) ? errors : getApiErrors(errors))?.find?.(error => error?.message);

/** Get all error objects that contain a message field */
export const getValidApiErrorObjects = (errors: any) =>
  (Array.isArray(errors) ? errors : getApiErrors(errors))?.filter?.(error => error?.message);

/** Get the message from the first error object that contains a message field */
export const getApiErrorsMessage = (errors: any, fallbackMessage?: Intl): string =>
  getValidApiErrorObject(errors)?.message || translate.t(fallbackMessage || 'error_loading');

/** Get all message fields from a collection of api error objects */
export const getApiErrorsMessages = (errors: any, fallbackMessage?: Intl): string[] => {
  const validErrors = getValidApiErrorObjects(errors);
  return validErrors.length > 1 ? validErrors.map(e => e.message) : [getApiErrorsMessage(errors, fallbackMessage)];
};

/**
 * Used to centralize and log api error information.
 *
 * Needed so that monitoring services can fingerprint and group errors accordingly.
 * The debug error message is a non-localized developer-only error message
 * which is used by default for fingerprinting.
 *
 * @param errors An error or array of errors from api.
 * @param customMessage Custom error message to override the api error message.
 */
export const logApiError = (errors: any, customMessage?: string) => {
  const validApiError = getValidApiErrorObject(errors);
  const apiDebugErrorMessage = validApiError?.extensions?.debug;
  const errorClassification = validApiError?.extensions?.classification;
  const errorObject = Array.isArray(errors) ? undefined : errors;
  const errorMessage = errors?.message || '';

  const fingerprintMessage = `LogApiError: ${
    customMessage || apiDebugErrorMessage || errorClassification || 'Unknown API error.'
  }${errorMessage && ` - ${errorMessage}`}`;
  LoggingService.error({
    message: fingerprintMessage, // For grouping and reporting e.g. Datadog.
    messageContext: errors, // Raw data
    error: errorObject, // For introspection by lib
  });
};
