import 'react-day-picker/dist/style.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { format } from 'date-fns';
import { enCA, enUS, es, fr } from 'date-fns/locale';
import type { CaptionProps, DayModifiers, DayPickerSingleProps } from 'react-day-picker';
import { DayPicker, useNavigation } from 'react-day-picker';
import styled from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import ChevronLeftIcon from 'components/ui/icons/ChevronLeftIcon';
import ChevronRightIcon from 'components/ui/icons/ChevronRightIcon';
import { Clickable } from 'components/ui/shared/Button';
import { useUser } from 'hooks/contexts/useUser';
import { usePrevious } from 'hooks/usePrevious';
import { BODY_TEXT, BODY_TEXT_SECONDARY } from 'styles/color';
import { BLUE_500, NEUTRAL_0, NEUTRAL_200 } from 'styles/tokens';
import { FONT_SIZE_12, FONT_SIZE_14, FONT_WEIGHT_BOLD } from 'styles/typography';
import { getDateTime, getFormattedDateTimeString, getJSDate } from 'utils/dateUtils';
import { Locale, translate } from 'utils/intlUtils';

// Map supported locales to date-fns locales
const localeMap = {
  [Locale.EN_CA]: enCA,
  [Locale.EN_US]: enUS,
  [Locale.FR_CA]: fr,
  [Locale.ES_US]: es,
} as const;

const DayPickerContainer = styled.div`
  .rdp {
    --rdp-cell-size: 30px;

    margin: 0;
    width: 100%;
  }

  .rdp-month {
    width: 100%;
  }

  .rdp-table {
    border-collapse: separate;
    margin-left: -15px;
    max-width: unset;
    width: calc(100% + 30px);
  }

  .rdp-head_cell {
    color: ${BODY_TEXT_SECONDARY};
    font-size: ${FONT_SIZE_12};
    font-weight: ${FONT_WEIGHT_BOLD};
    height: 25px;
    opacity: 0.6;
  }

  .rdp-cell {
    color: ${BODY_TEXT};
    font-size: ${FONT_SIZE_14};
    height: 30px;
    min-width: 30px;
    position: relative;
  }

  .rdp-day {
    display: inline-flex;
    line-height: 1;

    &.rdp-day_disabled {
      color: ${NEUTRAL_200};
      font-size: ${FONT_SIZE_14};
      opacity: 1;
    }

    &.highlighted:not(.rdp-day_selected) {
      color: ${BLUE_500};
      font-size: ${FONT_SIZE_14};

      &.rdp-day_disabled {
        color: ${NEUTRAL_200};
      }

      &::after {
        color: ${BLUE_500};
        content: '∙';
        display: block;
        font-size: 24px;
        left: 0;
        position: absolute;
        top: 12px;
        width: 100%;
      }
    }
  }

  .rdp-day_selected {
    background-color: ${BLUE_500};
    outline: none;
    color: ${NEUTRAL_0};

    &:hover {
      background-color: ${BLUE_500};
    }
  }
`;

const InlineClickable = styled(Clickable)`
  display: inline;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }
`;

const MonthTitle = styled.div`
  color: ${BODY_TEXT};
  display: inline-block;
  font-size: ${FONT_SIZE_14};
  margin: 0 14px;
  overflow: hidden;
  padding-top: 2px;
  text-align: center;
  white-space: nowrap;
  width: 140px;
`;

const NavBar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;
  text-align: right;

  > :first-child {
    flex: 1;
    margin-top: 2px;
  }
`;

const Caption = ({ displayMonth }: CaptionProps) => {
  const { user } = useUser();
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  const locale = localeMap[user.locale?.languageTag as Locale] || localeMap[Locale.EN_CA];

  const handleGoToPreviousMonth = useCallback(() => {
    if (previousMonth) {
      goToMonth(previousMonth);
    }
  }, [goToMonth, previousMonth]);

  const handleGoToNextMonth = useCallback(() => {
    if (nextMonth) {
      goToMonth(nextMonth);
    }
  }, [goToMonth, nextMonth]);

  return (
    <NavBar>
      <Label>{translate.t('date')}</Label>
      <InlineClickable data-testid="rdp-previous-button" onClick={handleGoToPreviousMonth}>
        <ChevronLeftIcon fill={BODY_TEXT} />
      </InlineClickable>
      <MonthTitle>{format(displayMonth, 'MMMM yyyy', { locale })}</MonthTitle>
      <InlineClickable data-testid="rdp-next-button" onClick={handleGoToNextMonth}>
        <ChevronRightIcon fill={BODY_TEXT} />
      </InlineClickable>
    </NavBar>
  );
};

interface Props {
  /** The default date to select, if any */
  defaultValue?: string;
  /** The dates to highlight in the calendar */
  highlightedDates?: Array<string>;
  /** The initial month to display in the calendar */
  initialMonth?: Date;
  /** Local date in the format 'uuuu-MM-dd'T'HH:mm:ss.SSS' (ISO-8601) */
  selectedDate?: string;
  /** Callback when date is clicked, emits ISO-8601 date string */
  onChange?: (date: string | undefined) => void;
  /** Callback when prev/next month nav button is clicked */
  onMonthChange?: (date: string) => void;
  /** `ReactDayPicker` props */
  settings?: DayPickerSingleProps;
}

/**
 * A Calendar for selecting dates
 */
const DatePicker = ({
  defaultValue,
  highlightedDates,
  initialMonth,
  onChange,
  onMonthChange,
  selectedDate,
  settings,
}: Props) => {
  const initialSelectedDays = getJSDate(selectedDate);
  const [selectedDay, setSelectedDay] = useState(initialSelectedDays || getJSDate(defaultValue) || undefined);
  const [currentMonth, setCurrentMonth] = useState(initialSelectedDays || getJSDate());
  const highlightedModifiers = useMemo<DayModifiers>(
    () => ({ highlighted: highlightedDates?.map(dateTimeString => getJSDate(dateTimeString)).filter(Boolean) || [] }),
    [highlightedDates]
  );
  const refOnChange = useRef(onChange);
  const refOnMonthChange = useRef(onMonthChange);
  const { user } = useUser();

  const locale = localeMap[user.locale?.languageTag as Locale] || localeMap[Locale.EN_CA];

  const onDayClick = useCallback((date, modifiers: DayModifiers) => {
    // Don't select day if it is disabled
    if (modifiers.disabled) {
      return;
    }

    setSelectedDay(date);
    refOnChange.current?.(getFormattedDateTimeString(getDateTime(date)));
  }, []);
  const onMonthClick = useCallback(date => {
    setCurrentMonth(date);
    return refOnMonthChange.current?.(date);
  }, []);

  const selectedDatePrev = usePrevious(selectedDate);
  const dayPickerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (selectedDatePrev !== selectedDate) {
      // React-day-picker only supports local time so offset adjustment is required
      const selectedDays = selectedDate && getJSDate(selectedDate);

      setSelectedDay(selectedDays || undefined);
      setCurrentMonth(selectedDays || null);
    }
  }, [selectedDate, selectedDatePrev]);

  return (
    <DayPickerContainer ref={div => (dayPickerRef.current = div!)}>
      <DayPicker
        components={{ Caption }}
        defaultMonth={initialMonth}
        formatters={{ formatWeekdayName: date => format(date, 'eeeee', { locale }) }}
        locale={locale}
        mode="single"
        modifiers={highlightedModifiers}
        modifiersClassNames={{ highlighted: 'highlighted' }}
        month={currentMonth || undefined}
        onDayClick={onDayClick}
        onMonthChange={onMonthClick}
        onSelect={setSelectedDay}
        selected={selectedDay}
        {...settings}
      />
    </DayPickerContainer>
  );
};

export default DatePicker;
