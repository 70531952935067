import { memo, type SVGProps } from 'react';

const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M12 15.113a1.2 1.2 0 0 1-.429-.078 1.054 1.054 0 0 1-.373-.245l-4.594-4.594a1.08 1.08 0 0 1-.317-.796A1.08 1.08 0 0 1 7.4 8.288c.32 0 .584.105.796.316L12 12.41l3.804-3.805a1.08 1.08 0 0 1 .796-.316A1.08 1.08 0 0 1 17.712 9.4c0 .32-.105.584-.316.796l-4.594 4.594a1.053 1.053 0 0 1-.373.245 1.2 1.2 0 0 1-.429.078Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(ChevronDownIcon);
