import { memo, useCallback, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import { useWebsiteDetailsMetaQueryFromWebsite } from 'components/sections/shared/ItemMetaHelpers';
import CollapsibleRouteSection from 'components/ui/details/collapsibleSections/CollapsibleRouteSection';
import { WebsiteRoutesTabFiltersSection } from 'components/ui/details/WebsiteRouteFilterSection';
import WebsiteRouteSortingSection from 'components/ui/details/WebsiteRouteSortingSection';
import PromptDialog from 'components/ui/dialogs/PromptDialog';
import VehicleAlertIcon from 'components/ui/icons/VehicleAlertIcon';
import Banner from 'components/ui/shared/Banner';
import { CTAButton } from 'components/ui/shared/Button';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { ExternalSites } from 'enums/routePath';
import { collapsibleRouteSectionTestId, ElementTestId } from 'enums/testing';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { WebsiteRouteResponseType } from 'store/api/graph/responses/responseTypes';
import { DIVIDER } from 'styles/color';
import { NEUTRAL_800 } from 'styles/tokens';
import { FONT_SIZE_20 } from 'styles/typography';
import { translate } from 'utils/intlUtils';
import { authorizedCallback, fullGroupOrAboveScopes } from 'utils/permissionUtils';

import type { RouteFilterGroup } from '../createModify/websiteRoutes/WebsiteRouteFilterBadges';
import { deleteWebsiteRoute, getRouteFilterGroup } from '../createModify/websiteRoutes/websiteRouteFilterUtils';
import ItemGridCellContentExternalLink from '../shared/ItemGridCellExternalLink';

const { t } = translate;

export const LinkContainer = styled.div`
  display: flex;
`;

const WebsiteRouteHeader = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const BottomBorder = styled.hr`
  padding: 0;
  margin: 0;
  background: ${DIVIDER};
  height: 1px;
  border: none;
`;

const HeaderText = styled(PrimaryText)`
  color: ${NEUTRAL_800};
  font-size: ${FONT_SIZE_20};
`;

const WebsiteRoutesTab = memo<ItemTabProps>(({ item: website, metadata: websiteMetadata }) => {
  const { builderConfig } = useBuilderConfig();
  const { data: metadata } = useWebsiteDetailsMetaQueryFromWebsite(website, websiteMetadata);
  const {
    user: { scope },
    hasPermissions,
  } = useUser();
  const { toggleTier } = useCreateModify();

  const [deletePendingRouteId, setDeletePendingRouteId] = useState<string>();

  const isEditAllowed =
    hasPermissions(builderConfig[BuilderType.WEBSITE_ROUTE_MODIFY].requiredPermissions) &&
    fullGroupOrAboveScopes.includes(scope);

  const openRouteModifyBuilder = useCallback(
    routeId => {
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.WEBSITE_ROUTE_MODIFY,
        itemId: website.id,
        seededData: {
          routeId,
          website,
        },
        metadata,
        title: t('route_other'),
        entityType: EntityType.WEBSITE,
        isCreating: false,
      });
    },
    [metadata, toggleTier, website]
  );

  const openRouteCreateBuilder = useCallback(() => {
    toggleTier(CreateModifyTiers.TIER_0, {
      tierId: CreateModifyTiers.TIER_0,
      type: BuilderType.WEBSITE_ROUTE_CREATE,
      itemId: website.id,
      seededData: {
        website,
      },
      metadata,
      title: t('route_other'),
      entityType: EntityType.WEBSITE,
      isCreating: true,
    });
  }, [metadata, toggleTier, website]);

  const getRouteFilterGroupsForAllFilters = useCallback((route: WebsiteRouteResponseType, metadata) => {
    const allFilterStrings: RouteFilterGroup[][] = [];
    for (const filter of route.inventoryItemSegment.filters) {
      const filterStrings = getRouteFilterGroup(filter, metadata?.mutation);
      allFilterStrings.push(filterStrings);
    }
    return allFilterStrings;
  }, []);

  const onDelete = useCallback(
    (routeId: string) =>
      authorizedCallback({
        cb: () => setDeletePendingRouteId(routeId),
        isAuth: isEditAllowed,
      }),
    [isEditAllowed]
  );

  return (
    <>
      <WebsiteRouteHeader>
        <PromptDialog
          isConfirmDestructive
          isOpen={!!deletePendingRouteId}
          message={t('hard_delete_message')}
          onCancel={() => setDeletePendingRouteId(undefined)}
          onComplete={() => setDeletePendingRouteId(undefined)}
          onConfirm={async () => {
            if (!deletePendingRouteId) {
              return;
            }

            return deleteWebsiteRoute(deletePendingRouteId);
          }}
          title={t('hard_delete_title')}
        />
        <HeaderText>{t('website_route_other')}</HeaderText>
        {isEditAllowed && (
          <CTAButton
            css={css`
              background: ${NEUTRAL_800};
              width: 132px;
            `}
            data-testid={ElementTestId.WEBSITE_CREATE_ROUTE_BUTTON}
            onClick={authorizedCallback({
              cb: () => openRouteCreateBuilder(),
              isAuth: isEditAllowed,
            })}
          >
            {t('create_route')}
          </CTAButton>
        )}
      </WebsiteRouteHeader>
      <BottomBorder />
      <div data-testid={ElementTestId.WEBSITE_ROUTES_CONTAINER}>
        {website.routes.map(route => {
          const authorizedOnEdit = authorizedCallback({
            cb: () => openRouteModifyBuilder(route.id),
            isAuth: isEditAllowed,
          });
          const hasFilters = route.inventoryItemSegment.filters && route.inventoryItemSegment.filters.length > 0;

          return (
            <div key={`collapsible-section-${route.path.value}`}>
              <CollapsibleRouteSection
                bannerJSX={
                  hasFilters ? null : (
                    <Banner
                      ctaLabel={t('add_x', [t('filter_other')])}
                      icon={<VehicleAlertIcon />}
                      onClick={authorizedOnEdit}
                      title={t('empty_path_banner_message')}
                    />
                  )
                }
                label={t('path')}
                onDelete={onDelete(route.id)}
                onEdit={authorizedOnEdit}
                renderSummary={() => (
                  <LinkContainer>
                    <ItemGridCellContentExternalLink to={website.url + ExternalSites.V4_INVENTORY + route.path.value}>
                      {`/${t('inventory').toLowerCase()}${route.path.value}`}
                    </ItemGridCellContentExternalLink>
                  </LinkContainer>
                )}
                testId={collapsibleRouteSectionTestId(route.id)}
                tooltipInfoText={t('website_path_tooltip')}
              >
                {hasFilters ? (
                  <WebsiteRoutesTabFiltersSection
                    filters={getRouteFilterGroupsForAllFilters(route, metadata)}
                    path={route.path.value}
                  />
                ) : null}
                {route?.initialItemSorting?.length ? (
                  <WebsiteRouteSortingSection
                    items={route?.initialItemSorting}
                    metadata={{ websiteSortOptions: metadata.websiteSortOptions }}
                  />
                ) : null}
              </CollapsibleRouteSection>
            </div>
          );
        })}
      </div>
    </>
  );
});

export default WebsiteRoutesTab;
