import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { clamp, debounce, isNil } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import { useConditionalMountEffect } from 'hooks/useConditionalMountEffect';
import { NEUTRAL_0 } from 'styles/tokens';
import { nonSafari13BrowserStyles } from 'utils/styledUtils';

import type { ScrollableRef as TranslateScrollableRef } from './interfaces/Scrollable';
import type { DefaultScrollableProps } from './Scrollable';

const SCROLLBAR_MAX_SIZE = 17; // px

const Scrollbar = styled.div<{ scrollHeight?: number; scrollWidth?: number }>`
  position: absolute;
  overflow: auto;

  &::after {
    display: block;
    content: '';
  }

  ${({ scrollHeight = 0 }) =>
    scrollHeight &&
    css`
      top: 0;
      right: 0;
      max-height: 100%;
      max-width: ${SCROLLBAR_MAX_SIZE}px;
      overflow-x: hidden;

      &::after {
        width: ${SCROLLBAR_MAX_SIZE}px;
        height: ${scrollHeight}px;
      }
    `};

  ${({ scrollWidth = 0 }) =>
    scrollWidth &&
    css`
      bottom: 0;
      left: 0;
      max-height: ${SCROLLBAR_MAX_SIZE}px;
      max-width: 100%;
      overflow-y: hidden;

      &::after {
        height: ${SCROLLBAR_MAX_SIZE}px;
        width: ${scrollWidth}px;
      }
    `};
`;

const TranslateScrollableContainer = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  -ms-overflow-style: auto;
`;

const ContentContainer = styled.div<{ disabledX?: boolean; disabledY?: boolean }>`
  overflow: ${({ disabledX, disabledY }) => `${disabledX && disabledY ? 'hidden' : 'visible'}`};
  background: ${NEUTRAL_0};
  display: inline-flex;
  flex-direction: column;
  position: relative;
  transform: translateZ(0);
  min-width: 100%;
  height: 100%;

  /**
   * Issue with scrollbars failing to render on versions of Safari 13 and under. For some reason, will-change: transform
   * is the culprit, however it offers a significant performance boost on other browsers, so we still want to include it.
   * If the users browser is Safari <= v13, then we set the will-change to auto instead of transform to avoid the rendering issues.
   */
  ${nonSafari13BrowserStyles`
    :hover {
      will-change: transform;
    }
  `}
`;
export interface TranslateScrollableProps {
  disabledX?: boolean;
  disabledY?: boolean;
}

interface Props extends DefaultScrollableProps, TranslateScrollableProps {}

/**
 * A wrapper component that simulates overflow: auto by:
 * - Hiding overflow
 * - Listening to onWheel events to css tranform content position.
 * - Rendering independent native scrollbars that overlap content, to mitigate layout shift.
 */
const TranslateScrollable = forwardRef<TranslateScrollableRef | undefined, Props>(
  ({ children, disabledX = false, disabledY = false, onScroll = () => {} }, ref) => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const [scrollWidth, setScrollWidth] = useState(0);

    const currentScrollRef = useRef({ x: 0, y: 0 });
    const containerRef = useRef<HTMLDivElement>(null);
    const contentContainerRef = useRef<HTMLDivElement>(null);
    const scrollbarXRef = useRef<HTMLDivElement>(null);
    const scrollbarYRef = useRef<HTMLDivElement>(null);

    const onResize = debounce(() => {
      if (contentContainerRef.current) {
        setScrollHeight(contentContainerRef.current?.scrollHeight);
      } else {
        requestAnimationFrame(onResize);
      }
    }, 50);

    useEffect(() => {
      const containerRefCurrent = containerRef?.current;

      /** Recalculate scroll measurements when a layout shift occurs */
      const ro = new ResizeObserver(onResize);

      if (containerRefCurrent) {
        ro.observe(containerRefCurrent);
      }

      return () => {
        if (containerRefCurrent) {
          ro.unobserve(containerRefCurrent);
        }
      };
    }, [onResize]);

    useConditionalMountEffect(
      () => {
        /** Wait for contentContainerRef to render prior to setting scroll dimensions */
        setScrollHeight(contentContainerRef.current!.scrollHeight);
        setScrollWidth(contentContainerRef.current!.scrollWidth);
      },
      () => !!contentContainerRef.current
    );

    const updateScroll = (x: number | null, y: number | null) => {
      const visibleHeight = containerRef.current!.offsetHeight;
      const contentHeight = contentContainerRef.current!.scrollHeight;

      const scrollbarSize = disabledY ? 0 : scrollbarYRef.current!.offsetHeight - scrollbarYRef.current!.clientHeight;
      const offsetY = disabledX ? 0 : scrollbarSize;

      let curX;
      let curY;

      if (!isNil(x) && !disabledX) {
        const lowerBound =
          -(contentContainerRef?.current!.scrollWidth - containerRef?.current!.offsetWidth) - scrollbarSize;

        curX = clamp(x, lowerBound, 0);
        /** Update scroll position of Scrollbar elements using computed values */
        /** TODO: [#2424] Remove non-null assertion on -x, and below on -y once @types/lodash is integrated */
        scrollbarXRef.current!.scrollLeft = -x;
      } else {
        curX = currentScrollRef.current.x;
      }

      if (!isNil(y) && !disabledY) {
        /** Constrain scrollYPosition to lower and upper bounds dictated by content/visible height */
        const lowerBound = contentHeight + offsetY > visibleHeight ? -(contentHeight + offsetY - visibleHeight) : 0;

        curY = clamp(y, lowerBound, 0);
        /** Update scroll position of Scrollbar elements using computed values */
        scrollbarYRef.current!.scrollTop = -y;
      } else {
        curY = currentScrollRef.current.y;
      }

      /** Update "currentScroll" state to correspond with computed values */
      currentScrollRef.current = { x: curX, y: curY };

      contentContainerRef.current!.style.transform = `translateY(${currentScrollRef.current.y}px) translateX(${currentScrollRef.current.x}px)`;
    };

    const onContainerScroll = e => {
      let scrollX: number | null;
      let scrollY: number | null;
      const { current: currentScroll } = currentScrollRef;

      if ([scrollbarXRef.current, scrollbarYRef.current].filter(Boolean).includes(e.currentTarget)) {
        scrollX = disabledX ? null : -scrollbarXRef.current!.scrollLeft;
        scrollY = disabledY ? null : -scrollbarYRef.current!.scrollTop;
      } else {
        scrollX = disabledX ? null : currentScroll.x - e.deltaX;
        scrollY = disabledY ? null : currentScroll.y - e.deltaY;
      }

      updateScroll(scrollX, scrollY);

      /* Run parent scroll handlers*/
      onScroll?.(e);
    };

    useImperativeHandle(
      ref,
      (): TranslateScrollableRef => ({
        updateScroll,
        container: () => containerRef?.current as HTMLDivElement,
      })
    );

    return (
      <TranslateScrollableContainer onWheel={onContainerScroll} ref={containerRef}>
        <ContentContainer disabledX={disabledX} disabledY={disabledY} ref={contentContainerRef}>
          {children}
        </ContentContainer>
        {!disabledY && <Scrollbar onScroll={onContainerScroll} ref={scrollbarYRef} scrollHeight={scrollHeight} />}
        {!disabledX && <Scrollbar onScroll={onContainerScroll} ref={scrollbarXRef} scrollWidth={scrollWidth} />}
      </TranslateScrollableContainer>
    );
  }
);

export default TranslateScrollable;
