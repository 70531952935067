import type StepField from 'components/core/createModify/interfaces/stepField';
import type { CarfaxReportErrors } from 'components/sections/createModify/inventoryItems/retailItem/steps/interfaces';
import type { CarfaxReport, Rooftop } from 'store/api/graph/interfaces/types';
import type { VinDecodeResponseType } from 'store/api/graph/responses/responseTypes';

export enum DetailsInventoryItemBuilderFields {
  CARFAX_REPORT_ID = 'carfaxReportId',
  CARFAX_REPORT_BADGES = 'carfaxReportBadges',
  CARFAX_REPORT_SHOW_WEB = 'carfaxReportShowWeb',
  TYPE = 'type',
  ROOFTOP_ID = 'rooftopId',
  VIN = 'vin',
  STOCK_NUMBER = 'stockNumber',
  STATUS = 'status',
  WARRANTY_CATEGORY_SHOW_WEB = 'warranty.showWeb',
  WARRANTY_CATEGORY_OVERRIDE = 'warranty.quebecCategoryOverride',
  MAKE_ID = 'makeId',
  MODEL_ID = 'modelId',
  SUB_MODEL_ID = 'subModelId',
  TAG_IDS = 'tagIds',
  TRIM_ID = 'trimId',
  YEAR = 'year',
  MSRP = 'msrp',
  VEHICLE_EXTERIOR_COLOR = 'vehicleAttributes.exteriorColor',
  NOTE = 'note',
}

export interface VinDecode {
  // If there are multiple trims to select from this method will be called to render the warning
  renderTrimWarning(): void;
  // Once the user has selected a trim, this method will be called to clear any warnings
  clearTrimSubStep(): void;
  /**
   * This method will fetch a carfax report. The vehicle's VIN must be provided, as well as the rooftop that this
   * vehicle is associated with. The rooftop needs a carfax id, if none is found then no report can be fetched. This
   * method will return the CarfaxReport data, or the error type if either no carfax is found or the rooftop doesn't
   * have a carfax account.
   *
   * The error status will be 400 if there was no report found, so we only need to show an error message if the error
   * status is something other than 400 (network error, some other 5xx type error, etc).
   * @param vin - the vehicle's VIN (string)
   * @param rooftop - the rooftop associated with this vehicle (Rooftop)
   */
  getCarfaxReport(
    vin: string,
    rooftop: Partial<Rooftop>
  ): Promise<{ carfaxReport?: CarfaxReport; errorType?: CarfaxReportErrors }>;
  /**
   * This method will attempt to decode a VIN and get the carfax report. If the VIN is successfully decoded,
   * the decoded data will be returned, the data will contain two properties - vinDecode for the vin decode response,
   * and carfaxReport for the carfax report.
   *
   * If the VIN requires trim selection, or is invalid, then the method
   * will return null. If the user wishes to proceed with an invalid VIN, the sub class must
   * override the proceedWithInvalidVin() method with a custom implementation to handle this scenario.
   *
   * If a VIN is invalid, or if the supplied rooftop does not have a carfax ID, then no carfax report will be fetched.
   *
   * @returns an object with two properties - vinDecode with the VinDecodeResponseType, and carfaxReport with the
   * CarfaxReport. The CarfaxReport can be null, meaning no report was found. If the VIN requires further action
   * from the user (trim selection, proceeding with an invalid VIN warning, etc), then null will be returned instead
   * of the decoded data (since no data has been decoded)
   */
  decodeVin(options: {
    // The rooftop to decode this VIN against (to find matching carfax reports and MMST values)
    rooftop: Partial<Rooftop>;
    /**
     * Vin decoding process can be triggered when saving a builder step, or it can be triggered when refreshing the
     * VIN field to reload vehicle data. This flag determines how the vin decoding process was triggered, so any
     * differences in the decoding flow can be handled.
     */
    isRefreshing?: boolean;
  }): Promise<Partial<VinDecodeResponseType> | null>;
  // If the user wishes to proceed with an invalid VIN that could not be decoded, this method will be called
  proceedWithInvalidVin?(): void;
}

export interface MMSTFields {
  // Determine if a given field is an MMST field
  isMMSTField(field: string);
  // Method to reset list of provided MMST fields to an empty value
  clearMMSTFields(fields: string[]);
  // Method that gets called whenever the builder sub-panel is opened for any MMST field
  toggleMMSTSubPanel(stepField: StepField);
  /*
   * Method that gets called whenever an MMST field is about to be selected (allows for warning prompt to be shown
   * before the field value is actually changed)
   */
  checkForMMSTFieldWarning?(stepField: StepField, value: any);
  // Method to update an MMST field with a new value
  updateMMSTField?(stepField: StepField, value: any);
  // Get the affected fields that will change whenever an MMST value is modified
  getMMSTWarningAffectedFields?(stepField: StepField): string[] | null;
  // Show a warning prompt whenever an MMST field is about to change
  showMMSTWarning?(affectedFields: string[], onConfirmCallback: () => void);
}
