import { isNil } from 'lodash-es';

import { DateTimeFormat } from 'enums/dateTimeFormat';
import type { Duration } from 'store/api/graph/interfaces/types';
import { DurationUnit } from 'store/api/graph/interfaces/types';
import { NONE_LIST_OPTION } from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

import { getCurrentLocalDateTime, getDateTime, getFormattedDateTimeString } from './dateUtils';
import { localeStorage } from './storage/intl';

const { HOUR_MINUTE_SEPARATOR, TIME_TYPE_FORMAT } = DateTimeFormat;

export type TimeObject = {
  /** Number of hours, currently 12hr clock */
  hour: number;
  /** Number of minutes */
  minute: number;
  /** AM/PM */
  meridiem: MeridiemIndicator;
};

export enum MeridiemIndicator {
  AM = 'a.m.',
  PM = 'p.m.',
}

/**
 * Converts a timestamp of format `DateTimeFormat.TIME_STAMP_FORMAT` to a `TimeObject`.
 */
export const getTimeObjectFromTimeStamp = (time?: string | null): TimeObject | null => {
  const timeValues = time?.split?.(/:| /) || [];
  if (timeValues.length !== 3) {
    return null;
  }

  return {
    hour: Number.parseInt(timeValues[0]),
    minute: Number.parseInt(timeValues[1]),
    meridiem: timeValues[2] as MeridiemIndicator,
  };
};

/**
 * Converts a `TimeObject` to a timestamp of format `DateTimeFormat.TIME_STAMP_FORMAT`.
 */
export const getTimeStampFromTimeObject = (time?: TimeObject | null): string => {
  if (!time) {
    return '';
  }

  const formattedTimeComponents = [time.hour, time.minute].map(timePart => timePart.toString().padStart(2, '0'));
  return `${formattedTimeComponents[0]}${HOUR_MINUTE_SEPARATOR}${formattedTimeComponents[1]} ${time.meridiem}`;
};

/**
 * Constructs a JSDate from the given `date` and `time`.
 * @param {string} timestamp Formatted as `DateTimeFormat.TIME_STAMP_FORMAT`
 * @param {string} date Foramtted as `DateTimeFormat.YEAR_MONTH_DAY_DASH_FORMAT`
 */
export const getJSDateFromTimeStamp = (timestamp: string, date: string) => {
  const dateTime = getDateTime(`${date} ${timestamp}`, TIME_TYPE_FORMAT);
  return dateTime ? getFormattedDateTimeString(dateTime) : null;
};

/**
 * List of pre-defined duration options the user can choose when creating Duration type inputs (currently only
 * applicable to Appointment section).
 * TODO: Remove once API provides list of duration options via metadata/query
 */
export const durationOptions = {
  five_min_before: { amount: 5, unit: DurationUnit.MINUTES },
  ten_min_before: { amount: 10, unit: DurationUnit.MINUTES },
  fifteen_min_before: { amount: 15, unit: DurationUnit.MINUTES },
  thirty_min_before: { amount: 30, unit: DurationUnit.MINUTES },
  one_hour_before: { amount: 1, unit: DurationUnit.HOURS },
  two_hour_before: { amount: 2, unit: DurationUnit.HOURS },
  one_week_before: { amount: 1, unit: DurationUnit.WEEKS },
} as const;

/**
 * Get the ID of a duration input type from the pre-defined list of available duration options. If the duration
 * is null, then return the NONE_LIST_OPTION id (special list option constant used to clear the field)
 *
 * @param {Duration} duration - The Duration value (includes amount + unit)
 */
export const getDurationId = (
  duration: Duration | null
): keyof typeof durationOptions | (typeof NONE_LIST_OPTION)['name'] => {
  if (duration) {
    const durationOptionKeys = Object.keys(durationOptions) as (keyof typeof durationOptions)[];
    const selectedDurationOptionKey = durationOptionKeys.find(
      option => durationOptions[option]?.amount === duration?.amount && durationOptions[option]?.unit === duration?.unit
    );

    return selectedDurationOptionKey || NONE_LIST_OPTION.name;
  }

  return NONE_LIST_OPTION.name;
};

/**
 * Get the label of a duration input type from the pre-defined list of available duration options. The key used
 * in the duration options is the same one used for translation. If the provided Duration is valid but not included
 * in the list of pre-defined options, then a simple formatted string containing the amount + unit is derived.
 *
 * @param {Duration} duration - The Duration value (includes amount + unit)
 */
export const getDurationLabel = (duration: Duration) => {
  if (!duration) {
    return translate.t('none');
  }
  const label = getDurationId(duration);
  return label ? translate.t(label) : `${duration?.amount} ${duration?.unitName}`;
};

export const DEFAULT_SEARCH_DEBOUNCE = 250;

/**
 * Get a string representation of a DateTime relative to now, following our custom specification
 * e.g. If today is 'Thu, 06 Feb 2020 13:35:55 GMT' then
 *      getRelativeTime('2020-02-01T20:10:00.000Z') -> 4 days ago`
 * @param options.todayInsteadOfNow Return dates within today as "Today", instead of one minute before "Now".
 */

export const getRelativeTime = (date: string | undefined, options?: { todayInsteadOfNow: boolean }) => {
  const dateTime = getDateTime(date);
  const relativeTimeString = dateTime?.setLocale(localeStorage.get() || '').toRelative();
  if (!dateTime || isNil(relativeTimeString)) {
    return null;
  }

  const currentDateTime = getCurrentLocalDateTime();
  const oneMinuteAgo = currentDateTime.minus({ seconds: 60 });
  const oneWeekAgo = currentDateTime.minus({ days: 7 });
  const { t } = translate;

  const oneMinuteAgoDiff = oneMinuteAgo.diff(dateTime, 'seconds').toObject()?.seconds;
  const oneWeekAgoDiff = oneWeekAgo.diff(dateTime, 'days').toObject()?.days;

  if (
    options?.todayInsteadOfNow &&
    dateTime <= currentDateTime.endOf('day') &&
    dateTime >= currentDateTime.startOf('day')
  ) {
    return t('today');
  } else if (!options?.todayInsteadOfNow && Number(oneMinuteAgoDiff) >= -120 && Number(oneMinuteAgoDiff) <= 0) {
    return t('now');
  } else if (Number(oneWeekAgoDiff) >= -14 && Number(oneWeekAgoDiff) <= 0) {
    return relativeTimeString;
  } else {
    return getFormattedDateTimeString(dateTime.toISO(), DateTimeFormat.DATE_YEAR_FORMAT) || null;
  }
};
