import { css } from 'styled-components/macro';

import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import type { NumberInputSettings } from 'components/ui/forms/shared/NumberInput';
import { BuilderType } from 'enums/builderType';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { InventoryItemAttributesPointer } from 'enums/inventoryItemAttributesPointer';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import {
  InventoryItemType,
  MotorcycleAttributesInputParameter,
  RetailItemModifyParameter,
  VehicleAttributesInputParameter,
} from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';
import { getInventoryAttributesField } from 'utils/formatting/inventoryItemFormatUtils';
import { getInventoryItemTypeConfig } from 'utils/inventoryItemUtils';

import { DetailsInventoryItemBuilderFields } from './interfaces';

const commonInventoryItemAttributes = (attributePointer: InventoryItemAttributesPointer) => {
  const { _displacement, _cylinders, _fuelType } =
    attributePointer === InventoryItemAttributesPointer.VEHICLE
      ? VehicleAttributesInputParameter
      : MotorcycleAttributesInputParameter;

  return defineDetailsFields({
    [getInventoryAttributesField(attributePointer, 'displacement')]: {
      label: 'engine',
      queryAlias: 'displacement',
      groupType: StepFieldType.DISPLACEMENT,
      clear: { field: _displacement, target: attributePointer },
    },
    [getInventoryAttributesField(attributePointer, 'cylinders')]: {
      label: 'cylinders',
      queryAlias: 'cylinders',
      groupType: StepFieldType.DROPDOWN,
      options: `mutation.item.${attributePointer}.cylinders`,
      subStep: [SubStepType.DEFAULT],
      clear: { field: _cylinders, target: attributePointer },
    },
    [getInventoryAttributesField(attributePointer, 'fuelType')]: {
      label: 'fuel_type',
      queryAlias: 'fuelType',
      groupType: StepFieldType.DROPDOWN,
      options: `mutation.item.${attributePointer}.fuelType`,
      subStep: [SubStepType.DEFAULT],
      clear: { field: _fuelType, target: attributePointer },
      hasSeparator: true,
    },
    /*
     * TODO: [#1577] support options in motorcycles
     *
     *[`${attributePointer}.separator-1`]: {
     *  groupType: StepFieldType.SEPARATOR,
     *},
     *[`${attributePointer}.options`]: {
     *  label: 'option_other',
     *  queryAlias: 'options',
     *  groupType: StepFieldType.TAGS,
     *  groupSubTypes: [StepFieldSubType.MULTI_SELECT],
     *  options: `mutation.item.${attributePointer}.options`,
     *  subStep: [SubStepType.DEFAULT],
     *},
     */
  });
};

/**
 * Master list of inventoryItem fields.
 * Unnecessary fields are filtered in `DetailsStep`.
 */
export const CommonInventoryItemFields = () =>
  defineDetailsFields({
    // YMMT Fields
    [DetailsInventoryItemBuilderFields.YEAR]: {
      label: 'year_one',
      groupType: StepFieldType.DROPDOWN,
      subStep: [SubStepType.ASYNC],
      forceAdvance: true,
      clear: { field: RetailItemModifyParameter._year },
    },
    [DetailsInventoryItemBuilderFields.MAKE_ID]: {
      label: 'make_one',
      queryAlias: 'makeName',
      groupType: StepFieldType.DROPDOWN,
      subStep: [SubStepType.ASYNC],
      forceAdvance: true,
      subStepAddConfig: SubStepAddConfigs[BuilderType.MAKE_CREATE],
    },
    [DetailsInventoryItemBuilderFields.MODEL_ID]: {
      label: 'model_one',
      queryAlias: 'modelName',
      groupType: StepFieldType.DROPDOWN,
      subStep: [SubStepType.ASYNC],
      forceAdvance: true,
      subStepAddConfig: SubStepAddConfigs[BuilderType.MODEL_CREATE],
      clear: { field: RetailItemModifyParameter._modelId },
    },
    [DetailsInventoryItemBuilderFields.SUB_MODEL_ID]: {
      label: 'submodel_one',
      queryAlias: 'subModelName',
      groupType: StepFieldType.DROPDOWN,
      subStep: [SubStepType.ASYNC],
      forceAdvance: true,
      clear: { field: RetailItemModifyParameter._subModelId },
      subStepAddConfig: SubStepAddConfigs[BuilderType.SUBMODEL_CREATE],
    },
    [DetailsInventoryItemBuilderFields.TRIM_ID]: {
      label: 'trim',
      queryAlias: 'trimName',
      groupType: StepFieldType.DROPDOWN,
      subStep: [SubStepType.ASYNC],
      clear: { field: RetailItemModifyParameter._trimId },
      hasSeparator: true,
      subStepAddConfig: SubStepAddConfigs[BuilderType.TRIM_CREATE],
    },
    // Vehicle Attributes
    [InventoryItem.VEHICLE_BODY_TYPE]: {
      label: 'body_type',
      queryAlias: 'bodyType',
      groupType: StepFieldType.DROPDOWN,
      options: `mutation.item.vehicleAttributes.bodyType`,
      subStep: [SubStepType.DEFAULT],
      clear: { field: VehicleAttributesInputParameter._bodyType, target: InventoryItemAttributesPointer.VEHICLE },
    },
    [InventoryItem.VEHICLE_CAB_TYPE]: {
      label: 'cab_type',
      queryAlias: 'cabType',
      groupType: StepFieldType.DROPDOWN,
      options: `mutation.item.vehicleAttributes.cabType`,
      subStep: [SubStepType.DEFAULT],
      clear: { field: VehicleAttributesInputParameter._cabType, target: InventoryItemAttributesPointer.VEHICLE },
    },
    [InventoryItem.VEHICLE_CARGO_BED_LENGTH]: {
      label: 'cargo_bed_length_in_feet',
      queryAlias: 'cargoBedLength',
      groupType: StepFieldType.NUMBER,
      settings: {
        allowDecimal: true,
      } as NumberInputSettings,
      clear: { field: VehicleAttributesInputParameter._cargoBedLength, target: InventoryItemAttributesPointer.VEHICLE },
    },
    [InventoryItem.VEHICLE_EXTERIOR_COLOR]: {
      label: 'exterior_color',
      queryAlias: 'exteriorColor',
      groupType: StepFieldType.COLOR,
      groupSubTypes: [StepFieldSubType.COLOR],
      options: `mutation.item.vehicleAttributes.exteriorColor`,
      subStep: [SubStepType.DEFAULT],
      clear: { field: VehicleAttributesInputParameter._exteriorColor, target: InventoryItemAttributesPointer.VEHICLE },
    },
    [InventoryItem.VEHICLE_INTERIOR_COLOR]: {
      label: 'interior_color',
      queryAlias: 'interiorColor',
      groupType: StepFieldType.COLOR,
      groupSubTypes: [StepFieldSubType.COLOR],
      options: `mutation.item.vehicleAttributes.interiorColor`,
      subStep: [SubStepType.DEFAULT],
      clear: { field: VehicleAttributesInputParameter._interiorColor, target: InventoryItemAttributesPointer.VEHICLE },
    },
    [InventoryItem.VEHICLE_NUMBER_OF_DOORS]: {
      label: 'number_of_doors',
      queryAlias: 'numberOfDoors',
      groupType: StepFieldType.DROPDOWN,
      options: `mutation.item.vehicleAttributes.numberOfDoors`,
      subStep: [SubStepType.DEFAULT],
      clear: { field: VehicleAttributesInputParameter._numberOfDoors, target: InventoryItemAttributesPointer.VEHICLE },
    },
    [InventoryItem.VEHICLE_NUMBER_OF_PASSENGERS]: {
      label: 'number_of_passengers',
      queryAlias: 'numberOfPassengers',
      groupType: StepFieldType.DROPDOWN,
      options: `mutation.item.vehicleAttributes.numberOfPassengers`,
      subStep: [SubStepType.DEFAULT],
      clear: {
        field: VehicleAttributesInputParameter._numberOfPassengers,
        target: InventoryItemAttributesPointer.VEHICLE,
      },
    },
    [InventoryItem.VEHICLE_TRANSMISSION]: {
      label: 'transmission',
      queryAlias: 'transmission',
      groupType: StepFieldType.DROPDOWN,
      options: `mutation.item.vehicleAttributes.transmission`,
      subStep: [SubStepType.DEFAULT],
      clear: { field: VehicleAttributesInputParameter._transmission, target: InventoryItemAttributesPointer.VEHICLE },
    },
    [InventoryItem.VEHICLE_DRIVE_TRAIN]: {
      label: 'drive_train',
      queryAlias: 'driveTrain',
      groupType: StepFieldType.DROPDOWN,
      options: `mutation.item.vehicleAttributes.driveTrain`,
      subStep: [SubStepType.DEFAULT],
      clear: { field: VehicleAttributesInputParameter._driveTrain, target: InventoryItemAttributesPointer.VEHICLE },
    },
    // Motorcycle attributes
    [InventoryItem.MOTORCYCLE_CATEGORY]: {
      label: 'category',
      queryAlias: 'category',
      groupType: StepFieldType.DROPDOWN,
      options: `mutation.item.motorcycleAttributes.category`,
      subStep: [SubStepType.DEFAULT],
      clear: { field: MotorcycleAttributesInputParameter._category, target: InventoryItemAttributesPointer.MOTORCYCLE },
    },
    [InventoryItem.MOTORCYCLE_COLOR]: {
      label: 'color',
      queryAlias: 'color',
      groupType: StepFieldType.COLOR,
      groupSubTypes: [StepFieldSubType.COLOR],
      options: `mutation.item.motorcycleAttributes.color`,
      subStep: [SubStepType.DEFAULT],
      clear: { field: MotorcycleAttributesInputParameter._color, target: InventoryItemAttributesPointer.MOTORCYCLE },
    },
    /*
     * TODO: [#1577] move separator and options back into commonInventoryItemAttributes when
     * motorcycle options are supported
     */
    ...commonInventoryItemAttributes(InventoryItemAttributesPointer.VEHICLE),
    ...commonInventoryItemAttributes(InventoryItemAttributesPointer.MOTORCYCLE),
    [InventoryItem.VEHICLE_OPTIONS]: {
      label: 'option_other',
      queryAlias: 'options',
      groupType: StepFieldType.TAGS,
      groupSubTypes: [StepFieldSubType.MULTI_SELECT],
      options: `mutation.item.vehicleAttributes.options`,
      subStep: [SubStepType.DEFAULT],
      hasSeparator: true,
    },
  });

const vehicleTypeConfig = getInventoryItemTypeConfig(InventoryItemType.VEHICLE);
const motorcycleTypeConfig = getInventoryItemTypeConfig(InventoryItemType.MOTORCYCLE);

export const InventoryItemTypeSettings = {
  // TODO: [ED-8911]
  types: [
    {
      id: InventoryItemType.VEHICLE,
      icon: vehicleTypeConfig.icon,
      label: vehicleTypeConfig.titleLabel,
    },
    {
      id: InventoryItemType.MOTORCYCLE,
      icon: motorcycleTypeConfig.icon,
      label: motorcycleTypeConfig.titleLabel,
    },
  ],
};

export const commonInventoryItemNoteField = defineDetailsFields({
  [DetailsInventoryItemBuilderFields.NOTE]: {
    label: 'note_one',
    groupType: StepFieldType.TEXT_AREA,
    containerInnerStyles: css`
      textarea {
        height: 321px;
      }
    `,
    tooltipContents: 'for_internal_dealer_use_only',
    clear: { field: RetailItemModifyParameter._note },
  },
});
