import { useMemo, useState } from 'react';

import { css } from 'styled-components/macro';

import MyAccountDetailsTab from 'components/sections/myAccount/MyAccountDetailsTab';
import MyAccountMenuItems from 'components/sections/myAccount/MyAccountMenuItems';
import UserPermissionsTab from 'components/sections/users/UserPermissionsTab';
import NoResultsIcon from 'components/ui/icons/NoResultsIcon';
import { PageLoader } from 'components/ui/loading/Loader';
import Placeholder from 'components/ui/placeholders/Placeholder';
import type ItemTab from 'components/ui/shared/interfaces/ItemTab';
import ItemDetailsContainer from 'containers/ItemDetailsContainer';
import { CardContainer, Container } from 'containers/ItemsContainer';
import NestedViewContainer from 'containers/nestedView/NestedViewContainer';
import { userDetailsQuery } from 'containers/users/UsersContainerQuery';
import { BuilderType } from 'enums/builderType';
import { ItemViewType } from 'enums/ItemViewType';
import { RoutePath } from 'enums/routePath';
import { TabType } from 'enums/tabType';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { useUser } from 'hooks/contexts/useUser';
import { withSearch } from 'hooks/contexts/withSearch';
import { useMountEffect } from 'hooks/useMountEffect';
import { useQuery } from 'hooks/useQuery';
import { useRouter } from 'hooks/useRouter';
import { getApiErrorsMessage } from 'store/api/graph/interfaces/apiErrors';
import { AccessLevel, EntityType, ResourceType } from 'store/api/graph/interfaces/types';
import { CARD_WIDTH } from 'styles/layouts';
import type { RequiredPermissions } from 'types/Permissions';
import { type Intl, translate } from 'utils/intlUtils';

const { t } = translate;

interface Props {
  /**
   * The tab that should be displayed in the details view.
   *
   * @example
   * <MyAccountContainer showIndividualTab={TabType.DETAILS} />
   * <MyAccountContainer showIndividualTab={TabType.USER_PERMISSIONS} />
   */
  showIndividualTab: TabType.DETAILS | TabType.USER_PERMISSIONS;
  /**
   * The key to use for the title of the details section.
   *
   * @example
   * <MyAccountContainer titleKey="general" />
   */
  titleKey: Intl;
}

const MyAccountContainer = ({ showIndividualTab, titleKey }: Props) => {
  const { viewItems, close } = useNestedView();
  const {
    user: { id: userId },
    hasPermissions,
  } = useUser();
  const router = useRouter();

  const [isDetailsUpdating, setIsDetailsUpdating] = useState(false);

  // Permissions
  const hasEditPermission = hasPermissions([{ resource: ResourceType.USERS, level: AccessLevel.ADVANCED }]);
  const isUsersAdmin = hasPermissions([{ resource: ResourceType.USERS, level: AccessLevel.FULL }]);

  // Queries
  const detailsVariables = useMemo(
    () => ({ variables: { id: userId, d_isUsersAdmin: isUsersAdmin }, ignore: !userId }),
    [userId, isUsersAdmin]
  );
  const {
    isLoaded: isDetailsLoaded,
    isLoading: isDetailsLoading,
    error: detailsError,
    data: detailsData,
  } = useQuery(userDetailsQuery, detailsVariables);

  const myAccountTabs: ItemTab[] = useMemo(
    () =>
      [
        showIndividualTab === TabType.DETAILS && { tabId: TabType.DETAILS, component: MyAccountDetailsTab },
        showIndividualTab === TabType.USER_PERMISSIONS &&
          ({
            tabId: TabType.USER_PERMISSIONS,
            component: UserPermissionsTab,
            label: 'permissions',
            requiredPermissions: [{ resource: ResourceType.USERS, level: AccessLevel.FULL }] as RequiredPermissions,
          } satisfies ItemTab),
      ]
        .filter(Boolean)
        .filter(({ requiredPermissions }) => !requiredPermissions || hasPermissions(requiredPermissions)),
    [hasPermissions, showIndividualTab]
  );

  // Closing any secondary views that may be open
  useMountEffect(close);

  // If there was an API error fetching items, display error placeholder
  if (detailsError) {
    return (
      <Placeholder
        buttonText={t('refresh')}
        css="height: 100%;"
        icon={<NoResultsIcon />}
        onClick={() => router.push(RoutePath.HOME)}
        subtitle={t('error_contact_us')}
        title={getApiErrorsMessage(detailsError)}
      />
    );
  }

  return (
    <Container>
      <CardContainer css="flex: 1;">
        {isDetailsLoaded && (
          <ItemDetailsContainer
            detailsTitleRenderMethod={() => t(titleKey)}
            editBuilder={BuilderType.USER_MODIFY}
            entityType={EntityType.USER}
            isEditAllowed={hasEditPermission}
            isUpdating={isDetailsUpdating}
            itemData={detailsData}
            itemId={userId}
            menuItems={
              <MyAccountMenuItems
                isUpdating={isDetailsUpdating}
                item={detailsData?.item}
                setIsDetailsUpdating={setIsDetailsUpdating}
                type={ItemViewType.SPLIT_VIEW}
              />
            }
            preLoadedItem={detailsData}
            setIsDetailsUpdating={setIsDetailsUpdating}
            tabs={myAccountTabs}
          />
        )}
      </CardContainer>
      {viewItems.length > 0 && (
        <CardContainer
          css={css`
            width: ${CARD_WIDTH};
          `}
        >
          {/**
           * Manually set key based on dependencies, so react automatically mounts/unmounts
           * the component such that rules of react are followed.
           */}
          <NestedViewContainer key={`NestedViewContainer-${viewItems[0].entityType}-${viewItems[0].type}`} />
        </CardContainer>
      )}
      {isDetailsLoading && <PageLoader />}
    </Container>
  );
};

export default withSearch(MyAccountContainer, {});
