import { useCallback } from 'react';

import { css } from 'styled-components/macro';

import { taskModify } from 'components/sections/createModify/tasks/TaskCreateModifyQuery';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import { EntityType, TaskStatus } from 'store/api/graph/interfaces/types';
import type { TaskResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { RED_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const { COMPLETE, IN_PROGRESS, NOT_STARTED } = TaskStatus;

interface Props extends MenuItemProps {
  item: TaskResponseType;
}

const TaskMenuItems = ({ item, setIsDetailsUpdating, onUpdateArchiveStatus }: Props) => {
  let status;
  let statusName;
  const { builderConfig } = useBuilderConfig();
  const { t } = translate;
  const isArchived = isItemArchived(item);
  const { id, status: statusPrev } = item || {};
  const { toggleTier } = useCreateModify();
  const { hasPermissions } = useUser();

  switch (statusPrev) {
    case NOT_STARTED:
    case COMPLETE: {
      statusName = t('in_progress');
      status = IN_PROGRESS;
      break;
    }

    case IN_PROGRESS: {
      statusName = t('complete');
      status = COMPLETE;
      break;
    }

    default: {
      break;
    }
  }

  // onClick callbacks
  const onModifyClicked = toggleTier.bind(null, CreateModifyTiers.TIER_0, {
    tierId: CreateModifyTiers.TIER_0,
    type: BuilderType.TASK_MODIFY,
    entityType: EntityType.TASK,
    title: t('modify_task'),
    isCreating: false,
    itemId: id,
  });
  const onMarkStatusClicked = useCallback(async () => {
    setIsDetailsUpdating(true);
    await client
      .mutate({
        mutation: taskModify,
        variables: { id, status },
      })
      .catch((error: Error) => {
        logApiError(error);
      })
      .finally(() => setIsDetailsUpdating(false));
  }, [id, status, setIsDetailsUpdating]);

  const disabled = !hasPermissions(builderConfig[BuilderType.TASK_MODIFY].requiredPermissions);

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('mark_item_as_status', [item?.__typename, statusName]),
      onClick: () => void onMarkStatusClicked(),
      disabled: !statusName || disabled,
    },
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled,
    },
    {
      label: t(isArchived ? 'unarchive' : 'archive'),
      onClick: () => onUpdateArchiveStatus?.(),
      disabled: !onUpdateArchiveStatus,
      isArchivable: false,
      titleCss: css`
        color: ${RED_500};
      `,
    },
  ];

  return <MenuItems isArchived={isArchived} items={menuItemsConfig} />;
};

export default TaskMenuItems;
