import { memo, type SVGProps } from 'react';

const LanguageEN = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={24} viewBox="0 0 24 24" width={24} {...props}>
    <path
      d="M5.73 15h3.858a.92.92 0 0 1 .688.287c.184.192.277.43.277.713s-.093.52-.277.712a.917.917 0 0 1-.688.288H4.765a.917.917 0 0 1-.688-.288A.986.986 0 0 1 3.8 16V8a.99.99 0 0 1 .277-.713A.917.917 0 0 1 4.765 7h4.823a.92.92 0 0 1 .688.287c.184.192.277.43.277.713s-.093.52-.277.713A.917.917 0 0 1 9.588 9H5.73v2h2.895c.273 0 .502.096.687.287.185.192.277.43.277.713s-.092.52-.277.713a.917.917 0 0 1-.687.287H5.729v2Zm7.122 1.64c.247.24.552.36.917.36.364 0 .67-.12.916-.36.247-.239.37-.536.37-.89V11l2.25 5.375c.108.188.247.338.419.453.171.115.375.172.61.172h.708c.322 0 .595-.11.82-.328a1.07 1.07 0 0 0 .338-.797V8.25c0-.354-.123-.651-.37-.89a1.264 1.264 0 0 0-.916-.36c-.365 0-.67.12-.917.36-.246.239-.37.536-.37.89v4.463l-2.25-5.088a1.328 1.328 0 0 0-.419-.453 1.074 1.074 0 0 0-.61-.172h-.708c-.322 0-.595.11-.82.328a1.07 1.07 0 0 0-.338.797v7.625c0 .354.124.651.37.89Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(LanguageEN);
