import 'styled-components/macro';

import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import FalseIcon from 'components/ui/icons/FalseIcon';
import PersonSearchIcon from 'components/ui/icons/PersonSearchIcon';
import TagIcon from 'components/ui/icons/TagIcon';
import TrueIcon from 'components/ui/icons/TrueIcon';
import { ArchiveBadge } from 'components/ui/shared/ArchiveBadge';
import Badge, { Badges } from 'components/ui/shared/Badge';
import { StatusColors } from 'enums/statusColors';
import type { TradeInItemResponseType } from 'store/api/graph/responses/responseTypes';
import { GREEN_500, RED_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props {
  tradeInItem: TradeInItemResponseType;
}

export const TradeInItemBadges = ({ tradeInItem, ...props }: Props) => {
  const { lead, tagName, guaranteedValue, status, tradeInItemStatus, statusName } = tradeInItem;
  const statusColor = StatusColors[status] || StatusColors[tradeInItemStatus];
  return (
    <Badges {...props}>
      <ArchiveBadge isArchived={isItemArchived(tradeInItem)} />
      <Badge color={statusColor} title={`${t('status')}: ${statusName}`}>
        {statusName}
      </Badge>
      <Badge title={`${t('lead_one')}: ${lead?.displayName}`}>
        <PersonSearchIcon css="margin-right: 0.5ch;" height={10} width={10} />
        {lead?.displayName}
      </Badge>
      <Badge title={`${t('tags')}: ${tagName.length}`}>
        <TagIcon css="margin-right: 1ch;" height={10} width={10} />
        {tagName.length}
      </Badge>
      <Badge css="padding-right: 2px" title={`${t('value_sent')}: ${guaranteedValue ? t('yes') : t('no')}`}>
        Value sent{' '}
        {guaranteedValue ? (
          <TrueIcon color={GREEN_500} css="margin-right: 1ch;" height={10} width={10} />
        ) : (
          <FalseIcon color={RED_500} css="margin-right: 1ch;" height={10} width={10} />
        )}
      </Badge>
    </Badges>
  );
};
