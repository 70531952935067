import { css } from 'styled-components/macro';

import { DEFAULT_STEP_FIELD_HEIGHT } from 'components/core/createModify/stepFields/StepFieldLayout';
import { GuaranteedTradeBuilderFields } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/interfaces';
import { StepFieldType } from 'enums/stepFieldType';
import { TradeInGuaranteedValueModifyParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

const GuaranteedTradeValueFields = defineDetailsFields({
  [GuaranteedTradeBuilderFields.AMOUNT]: {
    label: 'trade_value',
    groupType: StepFieldType.CURRENCY,
    clear: { field: TradeInGuaranteedValueModifyParameter._amount },
  },
  [GuaranteedTradeBuilderFields.COMMENTS]: {
    label: 'note_for_the_lead',
    groupType: StepFieldType.TEXT_AREA,
    clear: { field: TradeInGuaranteedValueModifyParameter._comments },
    containerStyles: css`
      height: calc(100% - ${DEFAULT_STEP_FIELD_HEIGHT});
    `,
  },
});

export default GuaranteedTradeValueFields;
