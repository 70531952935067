import type { HTMLAttributes, MouseEventHandler } from 'react';
import { useCallback } from 'react';

import styled from 'styled-components/macro';

import { BLUE_500, NEUTRAL_050, NEUTRAL_200, NEUTRAL_300 } from 'styles/tokens';

type Props = HTMLAttributes<HTMLInputElement>;
const SwitchInput = styled.input<Props>`
  display: none;
`;

const knobSize = 20;
const SwitchContainer = styled.label<{ checked: boolean }>`
  cursor: pointer;
  display: flex;
  height: 26px;
  width: 56px;
  border-radius: 13px;
  border: 1px solid ${props => (props.checked ? BLUE_500 : NEUTRAL_300)};
  background-color: ${props => (props.checked ? NEUTRAL_050 : NEUTRAL_050)};
  align-items: center;
  position: relative;

  ::before {
    position: absolute;
    content: '';
    height: ${knobSize}px;
    width: ${knobSize}px;
    left: ${props => (props.checked ? `calc(100% - ${knobSize + 4}px)` : '4px')};
    border-radius: 13px;
    background-color: ${props => (props.checked ? BLUE_500 : NEUTRAL_200)};
    transition: 0.1s;
  }
`;

interface SwitchProps extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  /** The onChange callback for the switch */
  onChange?: (value: { currentTarget: { value: boolean } }) => void;
  /** Testing ID to reference this step field input */
  testId?: string;
  /** The value of the switch */
  value?: boolean;
}

const Switch = ({ value, onChange = () => {}, testId, ...props }: SwitchProps) => {
  const handleSwitchInputOnClick = useCallback<MouseEventHandler<HTMLInputElement>>(
    event => event.stopPropagation(),
    []
  );
  return (
    <SwitchContainer aria-disabled={props['disabled']} checked={!!value} data-testid={testId}>
      <SwitchInput
        onClick={handleSwitchInputOnClick}
        type="checkbox"
        {...props}
        checked={!!value}
        onChange={useCallback(
          e => {
            const value = e.currentTarget.checked;
            onChange({ currentTarget: { value } });
          },
          [onChange]
        )}
      />
    </SwitchContainer>
  );
};

export default Switch;
