import { get, isNil } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import { LockingFieldIcon } from 'components/ui/details/ActionableTitle';
import FalseIcon from 'components/ui/icons/FalseIcon';
import TrueIcon from 'components/ui/icons/TrueIcon';
import Badge, { Badges, BadgeSizes } from 'components/ui/shared/Badge';
import { InventoryItem, InventoryItemSettings } from 'enums/columns/inventoryItem';
import { ElementTestId } from 'enums/testing';
import type { RetailItemResponseType } from 'store/api/graph/responses/responseTypes';
import { GREEN_500, RED_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { hasLockedField } from '../shared/utils';

const { t } = translate;
interface RetailItemFlagsInterface {
  /** Object containing the flag data e.g. `retailItem` entity */
  flagData: Record<string, unknown>;
  /** List of flags to target in `flagData` */
  flagTargets?: string[];
  /** Settings object that is configured by the column enums, e.g.  `InventoryItemSettings` */
  settings?: TableSettingsType;
  /** Whether lock icons should be displayed for lockable fields */
  isFieldLockingVisible?: boolean;
}

const LockTooltipButtonContainerStyle = css`
  display: inline-flex;
  position: absolute;
`;

const Text = styled.div<{ isLocked: boolean }>`
  display: inline-block;

  ${({ isLocked }) => css`
    margin-left: ${isLocked ? '16px' : '0'};
  `}
`;

export const RetailItemFlags = ({
  flagData,
  flagTargets = [
    InventoryItem.SHOW_WEB,
    InventoryItem.FEATURED,
    InventoryItem.AS_IS,
    InventoryItem.CERTIFIED,
    InventoryItem.DEMO,
    InventoryItem.ON_ORDER,
    InventoryItem.IN_TRANSIT,
  ],
  settings = InventoryItemSettings,
  isFieldLockingVisible,
}: RetailItemFlagsInterface) => (
  <Badges>
    {flagTargets
      .filter(flag => get(flagData, flag) !== undefined)
      .map(flag => {
        const flagState = get(flagData, flag);
        const isLocked = isFieldLockingVisible && hasLockedField(flag, flagData as RetailItemResponseType);

        return (
          !isNil(flagState) && (
            <Badge
              key={flag}
              size={BadgeSizes.LARGE}
              title={`${t('flag_one')}: ${flagState === true ? t('yes') : t('no')}`}
            >
              {isLocked && (
                <LockingFieldIcon
                  styles={LockTooltipButtonContainerStyle}
                  testId={`${ElementTestId.DETAILS_VIEW_LOCK_ICON}-${flag}`}
                />
              )}
              <Text isLocked={!!isLocked}>{settings[flag].label}</Text>
              {flagState === true ? (
                <TrueIcon color={GREEN_500} css="margin-left: 1ch;" height={14} width={14} />
              ) : (
                <FalseIcon color={RED_500} css="margin-left: 1ch;" height={14} width={14} />
              )}
            </Badge>
          )
        );
      })}
  </Badges>
);
