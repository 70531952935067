import { memo, type SVGProps } from 'react';

import { NEUTRAL_700 } from 'styles/tokens';

const SpinnerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill={NEUTRAL_700} height={30} viewBox="0 0 30 30" width={30} {...props}>
    <path
      d="M15 21.667c.92 0 1.667.746 1.667 1.666v5a1.667 1.667 0 11-3.334 0v-5c0-.92.747-1.666 1.667-1.666zM7.923 19.72a1.667 1.667 0 012.357 2.357l-3.535 3.535a1.667 1.667 0 11-2.357-2.357l3.535-3.535zm11.817.02a1.667 1.667 0 012.337-.02l3.535 3.535a1.667 1.667 0 01-2.357 2.357l-3.535-3.535a1.667 1.667 0 01.02-2.337zM6.667 13.333a1.667 1.667 0 010 3.334h-5a1.667 1.667 0 110-3.334h5zm21.666 0a1.667 1.667 0 110 3.334h-5a1.667 1.667 0 010-3.334h5zm-5.076-8.945a1.667 1.667 0 012.357 2.357l-3.536 3.535a1.667 1.667 0 01-2.358-2.357l3.537-3.535zm-18.849.02a1.667 1.667 0 012.337-.02l3.535 3.535a1.667 1.667 0 01-2.357 2.357L4.388 6.745a1.667 1.667 0 01.02-2.337zM15 0c.92 0 1.667.746 1.667 1.667v5a1.667 1.667 0 01-3.334 0v-5C13.333.747 14.08 0 15 0z"
      fillRule="nonzero"
    />
  </svg>
);

export default memo(SpinnerIcon);
