import type { MouseEventHandler, ReactNode } from 'react';

import type { FlattenSimpleInterpolation } from 'styled-components/macro';
import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import TextRow from 'components/core/typography/TextRow';
import ChevronRightIcon from 'components/ui/icons/ChevronRightIcon';
import { Clickable, FillWithClickable } from 'components/ui/shared/Button';
import { BODY_TEXT, DIVIDER } from 'styles/color';
import { SECTION_PADDING } from 'styles/spacing';
import { NEUTRAL_050, SPACE_300 } from 'styles/tokens';
import { FONT_SIZE_14, LETTER_SPACING_DEFAULT } from 'styles/typography';

export const ListSection = styled.section<{ disabled?: boolean }>`
  padding: 15px 17px;
  border-bottom: 1px solid ${DIVIDER};
  width: 100%;
  position: relative;

  > * {
    opacity: ${props => (props.disabled ? 0.5 : 1)};
  }

  && * {
    ${props =>
      props.disabled
        ? css`
            pointer-events: none;
          `
        : ''}
  }

  /** Only add margin-bottom to ListSection children that are NOT last-child, or 'buttons' */
  > :not(:last-child):not(${Clickable}):not(${FillWithClickable}) {
    margin-bottom: 14px;
  }
`;

export const ListSectionTitle = styled(Label)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * Container for search inputs used to filter <ListItems />
 */
export const ListSearchSection = styled(ListSection)`
  padding: ${SPACE_300};

  &:last-child {
    border-bottom: none;
  }
`;

export const ListSectionItemTitle = styled(ListSectionTitle)`
  font-weight: normal;
  text-transform: none;
  font-size: ${FONT_SIZE_14};
  letter-spacing: ${LETTER_SPACING_DEFAULT};
  pointer-events: none;

  svg {
    fill: ${BODY_TEXT};
  }
`;

const ListSectionItem = styled(ListSection)`
  padding: ${SECTION_PADDING};

  &:last-child {
    border-bottom: none;
  }

  :hover {
    background: ${NEUTRAL_050};
  }
`;

export interface ListItemType {
  label: string;
  labelElement?: JSX.Element;
  icon?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  testId?: string;
  /** Custom css for ListItem title */
  titleCss?: FlattenSimpleInterpolation;
}

export const ListItem = ({
  label,
  labelElement,
  icon = <ChevronRightIcon css="margin-left: 30px;" />,
  className,
  onClick,
  testId,
  titleCss,
}: ListItemType) => (
  <ListSectionItem className={className} data-testid={testId}>
    <FillWithClickable onClick={onClick} />
    <ListSectionItemTitle css={titleCss}>
      {labelElement || label}
      {icon}
    </ListSectionItemTitle>
  </ListSectionItem>
);

const RevisionTextRow = styled(TextRow)`
  justify-content: space-between;
`;

export const RevisionListItem = ({
  label,
  labelElement,
  icon = <ChevronRightIcon css="margin-left: 25px;" />,
  className,
  onClick,
}: ListItemType) => (
  <ListSectionItem className={className}>
    <FillWithClickable onClick={onClick} />
    <ListSectionItemTitle>
      <RevisionTextRow>
        {labelElement || label}
        {icon}
      </RevisionTextRow>
    </ListSectionItemTitle>
  </ListSectionItem>
);

export type CommonListItem = ListItemType & { id: string };

interface ListItemsProps {
  items: CommonListItem[];
  searchQuery?: string;
}

/**
 * Generates a collection of <ListItem /> that can be optionally filtered by a searchQuery.
 * Useful for displaying a list of search result items.
 */
export const ListItems = ({ items = [], searchQuery = '' }: ListItemsProps) => (
  <>
    {items.map(
      item =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase()) && (
          <ListItem
            icon={item.icon}
            key={item.id}
            label={item.label}
            labelElement={item.labelElement}
            onClick={item.onClick}
            testId={item.testId}
          />
        )
    )}
  </>
);
