import { memo, type SVGProps } from 'react';

const DetailsViewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M3.072 20.203c-.63 0-1.167-.221-1.61-.665a2.193 2.193 0 0 1-.665-1.61V6.072c0-.63.221-1.167.665-1.61.444-.443.98-.665 1.61-.665h1.88c.63 0 1.167.222 1.61.665.443.444.665.98.665 1.61v11.856c0 .63-.222 1.166-.665 1.61-.444.444-.98.665-1.61.665h-1.88Zm8.43 0c-.63 0-1.166-.221-1.61-.665a2.193 2.193 0 0 1-.665-1.61V6.072c0-.63.222-1.167.665-1.61.444-.443.98-.665 1.61-.665h9.432c.63 0 1.166.222 1.61.665.444.444.665.98.665 1.61v11.856c0 .63-.221 1.166-.665 1.61-.444.444-.98.665-1.61.665h-9.432Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(DetailsViewIcon);
