import { BannerStyle } from 'enums/bannerStyle';
import { translate } from 'utils/intlUtils';

import ArchiveIcon from '../icons/ArchiveIcon';
import Banner from '../shared/Banner';

const { t } = translate;

interface Props {
  onUpdateArchiveStatus?: () => void;
}

const ArchiveBanner = ({ onUpdateArchiveStatus }: Props) => (
  <Banner
    ctaLabel={t('unarchive')}
    icon={<ArchiveIcon />}
    onClick={onUpdateArchiveStatus}
    styleVariant={BannerStyle.WARNING}
    title={t('this_item_archived')}
  />
);

export default ArchiveBanner;
