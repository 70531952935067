import { memo } from 'react';
import 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import type { TagResponseType } from 'store/api/graph/responses/responseTypes';

interface Props extends TagResponseType, ListItemProps {}

// TODO: Add Storybook entry
const TagListItem = ({ name, typeName, suffixIcon, listItemType }: Props) => (
  <ListItem listItemType={listItemType} suffixIcon={suffixIcon}>
    <ListItemDetails>
      <TextRow>
        <PrimaryText>{name.value}</PrimaryText>
      </TextRow>
      <TextRow>
        <SecondaryText>{typeName}</SecondaryText>
      </TextRow>
    </ListItemDetails>
  </ListItem>
);

export default memo(TagListItem);
