import type { ElementType } from 'react';
import { useMemo } from 'react';

import styled from 'styled-components/macro';

import type { StepLegendComponentProps } from 'components/core/createModify/interfaces/stepLegend';
import Label from 'components/core/typography/Label';
import Paragraph from 'components/core/typography/Paragraph';
import PrimaryText from 'components/core/typography/PrimaryText';
import InfoIcon from 'components/ui/icons/InfoIcon';
import { CopyButtonGridViewWrapper } from 'components/ui/layouts/Grid';
import { Clickable } from 'components/ui/shared/Button';
import { CopyButton } from 'components/ui/shared/CopyButton';
import { builderStepLegendTestId, ElementTestId } from 'enums/testing';
import { BLUE_500, NEUTRAL_0, NEUTRAL_100, NEUTRAL_300, NEUTRAL_800 } from 'styles/tokens';
import { FONT_SIZE_14, FONT_WEIGHT_BOLD, LETTER_SPACING_DENSE } from 'styles/typography';
import { translate } from 'utils/intlUtils';
import { decimalPercentToString } from 'utils/stringUtils';

const Container = styled.div`
  min-height: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
`;

const StepNavContainer = styled.div`
  padding-bottom: 25px;
  border-bottom: 1px solid ${NEUTRAL_100};

  &:not(:only-child) {
    margin-bottom: 25px;
  }
`;

const StepRow = styled(Clickable)<{ isActive: boolean; disabled: boolean }>`
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    min-height: 35px;
    color: ${NEUTRAL_0};
    border-radius: 25px;
    font-size: ${FONT_SIZE_14};
    letter-spacing: ${LETTER_SPACING_DENSE};
    font-weight: ${FONT_WEIGHT_BOLD};
    margin-right: 18px;
  }

  ${({ isActive, disabled }) => `
    span {
      background: ${disabled ? NEUTRAL_300 : isActive ? BLUE_500 : NEUTRAL_800};
    }
    ${PrimaryText} {
      color: ${disabled ? NEUTRAL_300 : isActive ? BLUE_500 : NEUTRAL_800};
    }
  `}

  &:last-child {
    padding-bottom: 0;
  }
`;

const CompletePercent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompleteBar = styled.div`
  margin: 25px 0;
  width: 100%;
  height: 8px;
  background: ${NEUTRAL_100};
  border-radius: 5px;
  overflow: hidden;
`;

const CompleteTrack = styled.div.attrs<{ width: string }>(props => ({
  style: { ...props },
}))<{ width: string }>`
  height: 100%;
  background: ${BLUE_500};
`;

const Tooltip = styled.div`
  width: 100%;
  background: ${NEUTRAL_0};
  border-radius: 5px;
  padding: 15px;

  ${Label} {
    display: flex;
    align-items: center;

    svg {
      color: ${NEUTRAL_100};
      margin-right: 16px;
      top: -1px;
    }
  }

  ${CopyButtonGridViewWrapper} {
    margin-top: 15px;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * Contextual by which step is currently being accessed (each step defines their own tooltip).
 * Shown as a little context bubble
 */
export const StepLegendTooltip = ({ tier: { activeStep } }: StepLegendComponentProps) =>
  activeStep?.tooltip ? (
    <Tooltip>
      <Label>
        <InfoIcon />
        {activeStep.tooltip.title}
      </Label>
      <CopyButtonGridViewWrapper>
        <Paragraph>{activeStep.tooltip.content}</Paragraph>
        {activeStep.tooltip.canCopyContent && <CopyButton textToCopy={activeStep.tooltip.content} />}
      </CopyButtonGridViewWrapper>
    </Tooltip>
  ) : null;

/**
 * Step navigation component
 */
export const StepLegendNav = ({ onNavigate, tier: { steps = [], activeStep, type } }: StepLegendComponentProps) => (
  <StepNavContainer data-testid={ElementTestId.BUILDER_STEP_LEGEND_CONTAINER}>
    {steps &&
      steps.map((step, indx) => (
        <StepRow
          data-testid={builderStepLegendTestId(type, step.id)}
          disabled={!step.isEnabled}
          isActive={activeStep === step}
          key={step.id}
          onClick={onNavigate.bind(null, step)}
        >
          <span>{indx + 1}</span>
          <PrimaryText>{step.stepName}</PrimaryText>
        </StepRow>
      ))}
  </StepNavContainer>
);

/**
 * Completion percentage of a builder, reliant on data. As of now, only `RetailItem` has `completePercent`
 */
export const StepLegendCompletionPercent = ({ tier: { data } }: StepLegendComponentProps) => {
  const completePercent = useMemo(() => decimalPercentToString(data.completePercent || 0), [data.completePercent]);
  return (
    <CompletePercent>
      <PrimaryText>{translate.t('x_completed', [completePercent])}</PrimaryText>
      <CompleteBar>
        <CompleteTrack width={completePercent} />
      </CompleteBar>
    </CompletePercent>
  );
};

interface StepLegendProps extends StepLegendComponentProps {
  /**
   * List of legend components to build out. If `legend` is `undefined`,
   * default to showing the `StepLegendNav` & `StepLegendTooltip`, `null` shows nothing
   */
  legend?: ElementType<StepLegendComponentProps>[] | null;
  className?: string;
}

/**
 * The right side navigation that controls which legend component is currently being rendered.
 * No errors are rendered in this legend, the `ErrorComponent` will fly over top of it.
 */
export const StepLegend = ({
  tier,
  legend = [StepLegendNav, StepLegendTooltip],
  className,
  ...props
}: StepLegendProps) => {
  const content = useMemo(
    () => legend?.map(RenderElement => <RenderElement key={RenderElement.name} {...props} tier={tier} />),
    [legend, props, tier]
  );

  const PreviewContents =
    tier.stepPreviewValue && tier.activeStep?.previewContents ? tier.activeStep.previewContents : null;

  return (
    <Container className={className}>
      {content}
      {PreviewContents && (
        <PreviewContainer>
          <PreviewContents {...tier.stepPreviewValue} />
        </PreviewContainer>
      )}
    </Container>
  );
};
