import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import UserListItem from 'components/ui/lists/UserListItem';
import { BuilderType } from 'enums/builderType';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

const ConversationLinkedFields = defineDetailsFields({
  participants: {
    label: 'participant_other',
    queryAlias: 'participantIds',
    required: true,
    groupType: StepFieldType.RENDER_OBJECT,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    renderElement: UserListItem,
    subLabel: 'participants_builder_sublabel',
    subStep: [SubStepType.ASYNC],
    overrideSubStepSearchLabel: 'users',
    hasSeparator: true,
  },
  tagIds: {
    label: 'tags',
    queryAlias: 'tagName',
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.ASYNC],
    subStepAddConfig: SubStepAddConfigs[BuilderType.TAGS_CREATE],
  },
});

export default ConversationLinkedFields;
