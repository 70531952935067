import { useMemo } from 'react';

import styled, { css } from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import Text from 'components/core/typography/Text';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import type { OpenHoursInput } from 'store/api/graph/interfaces/types';
import { BODY_TEXT_SECONDARY } from 'styles/color';
import { NEUTRAL_0, SPACE_200 } from 'styles/tokens';
import { getDateTime } from 'utils/dateUtils';
import { translate } from 'utils/intlUtils';

import type { DayNameInWeek } from './interface';

const Container = styled.div`
  background: ${NEUTRAL_0};
`;

const DayHoursLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${SPACE_200};
`;

const HoursLabelContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

const { t } = translate;

/**
 * Render a day and the open/close hours
 */
const DayHoursLabel = ({
  day,
  startTime,
  endTime,
}: {
  /** The Day label */
  day: string;
  /** The start time (in the Time format) */
  startTime?: string;
  /** The close time (in the Time format) */
  endTime?: string;
}) => {
  const hours = useMemo(() => {
    if (!startTime || !endTime) {
      return (
        <Text
          css={css`
            color: ${BODY_TEXT_SECONDARY};
          `}
        >
          {t('closed')}
        </Text>
      );
    }

    return (
      <>
        <Text>{getDateTime(startTime, DateTimeFormat.HOUR_24_TIME_FORMAT)?.toFormat(DateTimeFormat.TIME_FORMAT)}</Text>
        <Text css={'margin: 0 5px 0 5px;'}>{t('to').toLowerCase()}</Text>
        <Text>{getDateTime(endTime, DateTimeFormat.HOUR_24_TIME_FORMAT)?.toFormat(DateTimeFormat.TIME_FORMAT)}</Text>
      </>
    );
  }, [startTime, endTime]);

  return (
    <DayHoursLabelContainer>
      <PrimaryText
        css={
          startTime
            ? undefined
            : css`
                color: ${BODY_TEXT_SECONDARY};
              `
        }
      >
        {day}
      </PrimaryText>
      <HoursLabelContainer>{hours}</HoursLabelContainer>
    </DayHoursLabelContainer>
  );
};

/**
 * Render a list of weekly hours
 */
const DepartmentWeeklyHours = ({
  openHours,
  days,
}: {
  /** The list of open/closing hours */
  openHours: OpenHoursInput[];
  /** The list of days in this week */
  days: DayNameInWeek[];
}) => (
  <Container>
    {days.map(day => {
      const hours = openHours.find(dayHours => dayHours.day === day.dayValue);
      return <DayHoursLabel day={day.dayName} endTime={hours?.close} key={day.dayValue} startTime={hours?.open} />;
    })}
  </Container>
);

export default DepartmentWeeklyHours;
