import { memo, type SVGProps } from 'react';
const LanguageFR = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={24} viewBox="0 0 24 24" width={24} {...props}>
    <path
      d="M4.934 17c-.319 0-.588-.11-.808-.33a1.095 1.095 0 0 1-.33-.808V8.138c0-.319.11-.588.33-.808.22-.22.49-.329.808-.329h4.917c.319 0 .588.11.808.33.22.219.33.488.33.807 0 .32-.11.59-.33.809-.22.22-.49.329-.808.329H6.07v1.79h2.81c.31 0 .573.108.787.321.213.214.32.476.32.787 0 .312-.107.574-.32.788a1.07 1.07 0 0 1-.788.32H6.072v2.582c0 .319-.11.588-.33.808-.22.22-.489.329-.808.329Zm9.072 0c-.32 0-.589-.11-.808-.33a1.095 1.095 0 0 1-.33-.808V8.138c0-.319.11-.588.33-.808.22-.22.489-.329.808-.329h4.114a2.01 2.01 0 0 1 1.472.611c.408.408.611.899.611 1.473v2.042a1.8 1.8 0 0 1-.547 1.326 2.138 2.138 0 0 1-1.29.632l1.123 2.345c.185.36.17.712-.045 1.056a1.037 1.037 0 0 1-.932.515c-.207 0-.398-.056-.572-.168a1.139 1.139 0 0 1-.404-.448l-1.565-3.223h-.828v2.702c0 .319-.11.588-.329.808-.22.22-.489.329-.808.329Zm1.137-5.934h2.821V9.275h-2.82v1.79Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(LanguageFR);
