import { memo, useCallback, useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { difference, differenceBy } from 'lodash-es';

import PrimaryText from 'components/core/typography/PrimaryText';
import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowFieldProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import CollapsibleSection from 'components/ui/details/collapsibleSections/CollapsibleSection';
import Badge, { Badges, BadgeSizes } from 'components/ui/shared/Badge';
import {
  Rooftop as RooftopColumns,
  RooftopPackageFeature,
  RooftopSettings,
  UNLIMITED_INVENTORY_LIMIT_VALUE,
} from 'enums/columns/rooftop';
import { ElementTestId } from 'enums/testing';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useUser } from 'hooks/contexts/useUser';
import type { RooftopDetailsContainerQuery } from 'store/api/graph/interfaces/types';
import { GuaranteedOfferPackage, PaymentOption } from 'store/api/graph/interfaces/types';
import { canUserEditFeatureBundles } from 'utils/featureBundleUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { PLACEHOLDER } from 'utils/formatUtils';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const { t } = translate;
type PackageFeatureConfigs = {
  key: RooftopPackageFeature | RooftopColumns;
  render?: ItemGridRowFieldProps['gridCellRenderMethod'];
}[];

interface PackageSectionProps {
  rooftop: RooftopDetailsContainerQuery['item'];
  onEditCallback: (activeField?: string) => void;
}

const PackageSection = memo<PackageSectionProps>(({ rooftop, onEditCallback }) => {
  const flags = useFlags();
  const userContext = useUser();
  const builderConfigContext = useBuilderConfig();

  const canUserEdit = canUserEditFeatureBundles({
    builderConfigContext,
    userContext,
    featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
  });
  const bundle = rooftop.bundle;

  /**
   * Shows only `addOns` which are extensions of the `basePackage`.
   * If `addOns.X` is set, package will use this value instead of `basePackage.features.X` value.
   * Thus, for some features we have to filter `basePackage` data from the `addOns` data, to get
   * difference to show in the UI as the actual manually selected `addOns` e.g. paymentOptions.
   */
  const featureBundleTags = useMemo(() => {
    const { addOns, package: basePackage } = bundle || {};
    if (!addOns) {
      return [];
    }

    return [
      !!addOns.oemName && t('tag_add_on_oem_program_x', [addOns.oemName]),
      !!addOns.lead.enabled && t('tag_add_on_leads'),
      !!addOns.lead.conversations && t('tag_add_on_conversations'),
      addOns.lead.smsMailboxLimit !== null && t('tag_add_on_sms_mailbox_limit'),
      !!addOns.lead.forwarding && t('tag_add_on_forwarding'),
      !!addOns.appointment.enabled && t('tag_add_on_appointments'),
      !!addOns.task.enabled && t('tag_add_on_tasks'),
      !!addOns.tradeIn.enabled && t('tag_add_on_trade_in'),
      !!addOns.tradeIn.guaranteedOfferPackage &&
        addOns.tradeIn.guaranteedOfferPackage !== GuaranteedOfferPackage.LEGACY &&
        `${t('tag_add_on_guaranteed_offer')} (${addOns.tradeIn.guaranteedOfferPackage})`,
      !!addOns.tradeIn.guaranteedOfferPackage &&
        addOns.tradeIn.guaranteedOfferPackage === GuaranteedOfferPackage.LEGACY &&
        t('tag_add_on_guaranteed_trade'),
      !!addOns.retail.enabled && t('tag_add_on_retail'),
      !!addOns.retail.buildAndPrice && t('tag_add_on_build_and_price'),
      !!addOns.retail.showroom && t('tag_add_on_showroom'),
      ...(addOns.retail.premiumExportIntegrations
        ? differenceBy(
            addOns.retail.premiumExportIntegrations,
            basePackage.features.retail.premiumExportIntegrations as [],
            'id'
          ).map(item => t('tag_add_on_retail_x', [item?.name]))
        : []
      ).filter(Boolean),
      ...(addOns.retail.paymentOptions
        ? difference(addOns.retail.paymentOptions, basePackage.features.retail.paymentOptions as []).map(payment => {
            switch (payment) {
              case PaymentOption.CASH: {
                return t('tag_add_on_payment_option_cash');
              }

              case PaymentOption.LEASE: {
                return t('tag_add_on_payment_option_lease');
              }

              case PaymentOption.FINANCE: {
                return t('tag_add_on_payment_option_finance');
              }

              default: {
                return false;
              }
            }
          })
        : []
      ).filter(Boolean),
      addOns.retail.itemLimit !== null &&
        addOns.retail.itemLimit !== UNLIMITED_INVENTORY_LIMIT_VALUE &&
        t('tag_add_on_retail_inventory_increase'),
      addOns.retail.itemLimit === UNLIMITED_INVENTORY_LIMIT_VALUE && t('tag_add_on_retail_unlimited_inventory'),
      !!addOns.retailPortal.enabled && t('tag_add_on_retail_portal'),
    ].filter(Boolean);
  }, [bundle]);

  const renderGridRowSection = useCallback(
    ({
      packageFeatureConfigs,
      editField,
    }: {
      /** The package feature configuration for this section */
      packageFeatureConfigs: PackageFeatureConfigs;
      /** Optional edit field. When passed in, this grid section will be editable. */
      editField?: RooftopColumns | RooftopPackageFeature;
    }) => {
      const config = {
        fields: packageFeatureConfigs.map<ItemGridRowFieldProps>(({ key: id, render: gridCellRenderMethod }) => ({
          id,
          gridCellRenderMethod,
        })),
        displayType: [ItemGridRowDisplayType.BOLD],
      };

      return (
        <ItemGridRowSection
          canEdit={canUserEdit && !!editField}
          config={config}
          customColumnFillMethod={() => 1}
          item={rooftop}
          key={formatItemGridRowSectionKey(config.fields)}
          onEdit={onEditCallback}
          settings={RooftopSettings}
        />
      );
    },
    [rooftop, canUserEdit, onEditCallback]
  );

  if (!bundle) {
    return null;
  }

  const itemLimit = bundle.features.retail.itemLimit;
  const isUnlimited = itemLimit === UNLIMITED_INVENTORY_LIMIT_VALUE;

  return (
    <CollapsibleSection
      label={t('rooftop_package_one')}
      onEdit={authorizedCallback({
        cb: () => onEditCallback(RooftopColumns.PACKAGE),
        isAuth: canUserEdit,
      })}
      renderSummary={() => (
        <>
          <PrimaryText>{bundle.package.name}</PrimaryText>
          <Badges data-testid={ElementTestId.FEATURE_BUNDLE_TAGS}>
            {featureBundleTags.map(tag => (
              <Badge key={tag} size={BadgeSizes.LARGE} title={`${t('rooftop_package_one')}: ${tag}`}>
                {tag}
              </Badge>
            ))}
          </Badges>
        </>
      )}
      testId={ElementTestId.FEATURE_BUNDLE_PACKAGE_SECTION}
    >
      {renderGridRowSection({
        packageFeatureConfigs: [{ key: RooftopPackageFeature.OEM_NAME }],
      })}
      <CollapsibleSection label={t('lead_other')} testId={ElementTestId.FEATURE_BUNDLE_LEAD_SECTION}>
        {renderGridRowSection({
          packageFeatureConfigs: [
            { key: RooftopPackageFeature.LEAD_ENABLED },
            { key: RooftopPackageFeature.LEAD_SMS_MAILBOX_LIMIT },
            { key: RooftopPackageFeature.LEAD_CONVERSATIONS },
            { key: RooftopPackageFeature.LEAD_FORWARDING },
          ],
        })}
      </CollapsibleSection>
      <CollapsibleSection label={t('appointments_other')} testId={ElementTestId.FEATURE_BUNDLE_APPOINTMENTS_SECTION}>
        {renderGridRowSection({ packageFeatureConfigs: [{ key: RooftopPackageFeature.APPOINTMENT_ENABLED }] })}
      </CollapsibleSection>
      <CollapsibleSection label={t('tasks_other')} testId={ElementTestId.FEATURE_BUNDLE_TASKS_SECTION}>
        {renderGridRowSection({ packageFeatureConfigs: [{ key: RooftopPackageFeature.TASK_ENABLED }] })}
      </CollapsibleSection>
      <CollapsibleSection
        label={t('trade_in_inventory')}
        testId={ElementTestId.FEATURE_BUNDLE_TRADE_IN_INVENTORY_SECTION}
      >
        {renderGridRowSection({
          packageFeatureConfigs: [
            { key: RooftopPackageFeature.TRADE_IN_ENABLED },
            {
              key: RooftopPackageFeature.GUARANTEED_OFFER_PACKAGE,
              render: (settings, item) => (
                <PrimaryText>
                  {item.bundle.features.tradeIn.guaranteedOfferPackage === GuaranteedOfferPackage.LEGACY
                    ? t('guaranteed_trade')
                    : item.bundle.features.tradeIn.guaranteedOfferPackageName || PLACEHOLDER}
                </PrimaryText>
              ),
            },
          ],
        })}
      </CollapsibleSection>
      <CollapsibleSection
        addBottomBorder={false}
        label={t('retail_inventory')}
        testId={ElementTestId.FEATURE_BUNDLE_RETAIL_INVENTORY_SECTION}
      >
        {renderGridRowSection({
          packageFeatureConfigs: [
            { key: RooftopPackageFeature.RETAIL_ENABLED },
            { key: RooftopPackageFeature.RETAIL_BUILD_AND_PRICE },
            { key: RooftopPackageFeature.RETAIL_SHOWROOM },
            { key: RooftopPackageFeature.RETAIL_PAYMENT_OPTION },
            {
              key: RooftopPackageFeature.RETAIL_PREMIUM_EXPORT_INTEGRATION,
            },
            {
              key: RooftopPackageFeature.RETAIL_ITEM_LIMIT,
              render: () => <PrimaryText>{isUnlimited ? t('unlimited') : itemLimit}</PrimaryText>,
            },
          ],
        })}
      </CollapsibleSection>
      <CollapsibleSection label={t('retail_portal')} testId={ElementTestId.FEATURE_BUNDLE_RETAIL_PORTAL_SECTION}>
        {renderGridRowSection({ packageFeatureConfigs: [{ key: RooftopPackageFeature.RETAIL_PORTAL_ENABLED }] })}
      </CollapsibleSection>
    </CollapsibleSection>
  );
});

export default PackageSection;
