import { gql } from '@apollo/client';

import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import { retailBulkAdjustment } from 'store/api/graph/fragments/retailBulkAdjustment';
import { retailBulkAdjustmentDetail } from 'store/api/graph/fragments/retailBulkAdjustmentDetail';
import { retailBulkAdjustmentList } from 'store/api/graph/fragments/retailBulkAdjustmentList';

export const rooftopRetailBulkAdjustmentsDetailsQuery = gql`
  query RooftopRetailBulkAdjustmentsDetailsQuery($id: ID!) {
    item: retailBulkAdjustment(id: $id) {
      ...RetailBulkAdjustmentDetailFragment
    }
  }
  ${retailBulkAdjustmentDetail}
`;

export const rooftopRetailBulkAdjustmentsCondensedListQuery = gql`
  query RooftopRetailBulkAdjustmentsCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $filter: RetailBulkAdjustmentConnectionFilterInput
  ) {
    connection: retailBulkAdjustmentConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...RetailBulkAdjustmentListFragment
        }
      }
    }
  }
  ${pageInfo}
  ${retailBulkAdjustmentList}
`;

/**
 * Query that gets the count of all bulk adjustments under a provided filter
 */
export const rooftopRetailBulkAdjustmentsCountQuery = gql`
  query RooftopRetailBulkAdjustmentsCountQuery($keyword: String, $rooftopId: [ID!], $groupId: ID) {
    bulk_adjustment_description: retailBulkAdjustmentConnection(
      first: 1
      keyword: $keyword
      filter: { rooftopId: $rooftopId, type: DESCRIPTION, groupId: $groupId }
    ) {
      pageInfo {
        totalEdges
      }
    }
    bulk_adjustment_pricing: retailBulkAdjustmentConnection(
      first: 1
      keyword: $keyword
      filter: { rooftopId: $rooftopId, type: PRICE, groupId: $groupId }
    ) {
      pageInfo {
        totalEdges
      }
    }
    bulk_adjustment_overlay: retailBulkAdjustmentConnection(
      first: 1
      keyword: $keyword
      filter: { rooftopId: $rooftopId, type: OVERLAY, groupId: $groupId }
    ) {
      pageInfo {
        totalEdges
      }
    }
    bulk_adjustment_promotion: retailBulkAdjustmentConnection(
      first: 1
      keyword: $keyword
      filter: { rooftopId: $rooftopId, type: PROMOTION, groupId: $groupId }
    ) {
      pageInfo {
        totalEdges
      }
    }
    bulk_adjustment_disclaimer: retailBulkAdjustmentConnection(
      first: 1
      keyword: $keyword
      filter: { rooftopId: $rooftopId, type: DISCLAIMER, groupId: $groupId }
    ) {
      pageInfo {
        totalEdges
      }
    }
  }
`;

export const retailBulkAdjustmentsCondensedListQuery = gql`
  query RetailBulkAdjustmentsCondensedListQuery($id: ID!) {
    item: retailItem(id: $id) {
      bulkAdjustments {
        ...RetailBulkAdjustmentFragment
      }
    }
  }

  ${retailBulkAdjustment}
`;
