import 'styled-components/macro';

import StatusBadge from 'components/sections/shared/StatusBadge';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import ConversationsIcon from 'components/ui/icons/ConversationsIcon';
import PersonSearchIcon from 'components/ui/icons/PersonSearchIcon';
import TestDriveIcon from 'components/ui/icons/TestDriveIcon';
import { ArchiveBadge } from 'components/ui/shared/ArchiveBadge';
import Badge, { Badges } from 'components/ui/shared/Badge';
import type { RetailItemResponseType } from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props {
  retailItem: RetailItemResponseType;
}

export const RetailItemBadges = ({ retailItem, ...props }: Props) => (
  <Badges {...props}>
    <ArchiveBadge isArchived={isItemArchived(retailItem)} />
    <StatusBadge item={retailItem} />
    <Badge title={`${t('conversations')}: ${retailItem.conversationsCount}`}>
      <ConversationsIcon css="margin-right: 1ch;" height={10} width={10} /> {retailItem.conversationsCount}
    </Badge>
    <Badge title={`${t('lead_other')}: ${retailItem.leadsCount}`}>
      <PersonSearchIcon css="margin-right: 0.5ch;" height={10} width={10} /> {retailItem.leadsCount}
    </Badge>
    <Badge title={`${t('test_drives')}: ${retailItem.testDrivesCount}`}>
      <TestDriveIcon css="margin-right: 0.5ch;" height={10} width={10} /> {retailItem.testDrivesCount}
    </Badge>
  </Badges>
);
