import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import { RooftopFinanceSegmentRatesBuilderFields } from 'components/sections/createModify/rooftopPricingSegments/financePricing/steps/interfaces';
import { RooftopFinancePricingSegment, RooftopPricingSegment } from 'enums/columns/rooftopPricingSegments';
import { RooftopPricingSegmentConfigurationPointer } from 'enums/rooftopPricingSegmentConfigurationPointer';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { FinanceRetailPricingConfigurationInputParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';
import { getNestedConfigurationField } from 'utils/formatting/pricingUtils';

const RatesFields = defineDetailsFields({
  [RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_PRICING_SOURCE]: {
    label: 'incentives_and_rates_source',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_PRICING_SOURCE_NAME,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_PRICING_SOURCE),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    required: true,
  },
  [RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES]: {
    label: 'include_after_tax_rebates',
    groupType: StepFieldType.SWITCH,
    tooltipContents: 'include_after_tax_rebates_tooltip',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_INCLUDE_AFTER_TAX_REBATES,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_INCLUDE_AFTER_TAX_REBATES),
    ],
    clear: {
      field: FinanceRetailPricingConfigurationInputParameter._includeAfterTaxRebates,
      target: RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION,
    },
    hasSeparator: true,
  },
  [RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_DEFAULT_TERM_TYPE]: {
    label: 'default_term',
    queryAlias: [
      RooftopFinancePricingSegment.CONFIGURATION_DEFAULT_TERM_TYPE_NAME,
      getNestedConfigurationField(RooftopFinancePricingSegment.CONFIGURATION_DEFAULT_TERM_TYPE),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
  },
  [RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_INTEREST_RATE_TYPE]: {
    label: 'interest_rate_type',
    queryAlias: [
      RooftopFinancePricingSegment.CONFIGURATION_INTEREST_RATE_TYPE_NAME,
      getNestedConfigurationField(RooftopFinancePricingSegment.CONFIGURATION_INTEREST_RATE_TYPE),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
  },
  [RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_AVAILABLE_TERMS]: {
    label: 'available_term_other',
    queryAlias: [
      RooftopFinancePricingSegment.CONFIGURATION_AVAILABLE_TERMS,
      getNestedConfigurationField(RooftopFinancePricingSegment.CONFIGURATION_AVAILABLE_TERMS),
    ],
    groupType: StepFieldType.BADGES,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.DEFAULT],
    required: true,
  },
  [RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_DEFAULT_TERM]: {
    label: 'default_term',
    queryAlias: [
      RooftopFinancePricingSegment.CONFIGURATION_DEFAULT_TERM,
      getNestedConfigurationField(RooftopFinancePricingSegment.CONFIGURATION_DEFAULT_TERM),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    required: true,
    hasSeparator: true,
  },
});

export default RatesFields;
