import { memo, type SVGProps } from 'react';

const EditLinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 20 20" width={20} {...props}>
    <path
      d="M3.278 17.67a.916.916 0 0 1-.674-.275.916.916 0 0 1-.274-.674v-2.13a1.873 1.873 0 0 1 .549-1.331L13.32 2.834a1.88 1.88 0 0 1 .577-.374c.211-.086.433-.13.665-.13.23 0 .453.044.671.13.218.087.41.217.577.39l1.36 1.366c.173.163.3.354.382.574.081.22.122.44.122.663 0 .232-.04.454-.122.667a1.646 1.646 0 0 1-.382.58L6.75 17.12a1.871 1.871 0 0 1-1.331.549H3.277ZM14.557 6.613l1.166-1.161-1.171-1.177-1.172 1.167 1.176 1.171Z"
      fill="currentcolor"
    />
  </svg>
);

export default memo(EditLinkIcon);
