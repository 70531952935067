import { useCallback, useMemo, useState } from 'react';

import { userModify } from 'components/sections//createModify/users/UserCreateModifyQuery';
import UserQueryMethods from 'components/sections/createModify/users/UserQueryMethods';
import PromptDialog from 'components/ui/dialogs/PromptDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { CustomEntity } from 'enums/extendedEntityType';
import { ElementTestId } from 'enums/testing';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';
import type { UserResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { impersonationManager } from 'utils/impersonationUtils';
import { translate } from 'utils/intlUtils';

interface Props extends MenuItemProps {
  item: UserResponseType;
}
const { t } = translate;
const getActiveStatusLabel = active => (active ? t('deactivate_user') : t('activate_user'));

const UserMenuItems = ({ item }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const {
    user: { id: myId },
    hasPermissions,
    impersonateAsUser,
  } = useUser();
  const { toggleTier } = useCreateModify();
  const { id, active } = item || {};
  const [prevActive, setPrevActive] = useState<boolean | undefined>(undefined);
  const [isTogglingActivate, setIsTogglingActivate] = useState(false);
  const activeStatusLabel = useMemo(() => getActiveStatusLabel(prevActive ?? active), [active, prevActive]);
  // A user can edit themselves, even if they don't have the requiredPermissions for editing other Users
  const canEdit = item?.editableByMe
    ? true
    : hasPermissions(builderConfig[BuilderType.USER_MODIFY].requiredPermissions);

  // onClick callbacks
  const onModifyClicked = toggleTier.bind(null, CreateModifyTiers.TIER_0, {
    tierId: CreateModifyTiers.TIER_0,
    type: BuilderType.USER_MODIFY,
    entityType: CustomEntity.USER,
    title: t('modify_user'),
    isCreating: false,
    itemId: id,
  });

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled: !canEdit,
    },
    {
      label: impersonationManager.isCurrentlyImpersonating ? t('logout_of_user', [t('user')]) : t('login_as_user'),
      onClick: () => impersonateAsUser(impersonationManager.isCurrentlyImpersonating ? undefined : item),
      disabled:
        !canEdit ||
        id === myId ||
        (!impersonationManager.isCurrentlyImpersonating &&
          !hasPermissions([{ resource: ResourceType.AS_USER, level: AccessLevel.FULL }])),
      isArchivable: false,
      testId: ElementTestId.LOGIN_AS_USER_MEATBALL_MENU_ITEM,
    },
    {
      label: activeStatusLabel,
      onClick: () => setIsTogglingActivate(true),
      disabled: !canEdit,
      isArchivable: false,
    },
  ];

  return (
    <>
      <MenuItems isArchived={!active} items={menuItemsConfig} />
      <PromptDialog
        isFixedToTop
        isOpen={isTogglingActivate}
        onCancel={useCallback(() => {
          setIsTogglingActivate(false);
          setPrevActive(undefined);
        }, [])}
        onClose={() => setPrevActive(undefined)}
        onConfirm={useCallback(async () => {
          setPrevActive(active);
          await client.mutate({
            mutation: userModify,
            variables: { id, active: !active },
            refetchQueries: UserQueryMethods.refetchQueries,
          });
        }, [id, active])}
        title={activeStatusLabel}
      />
    </>
  );
};

export default UserMenuItems;
