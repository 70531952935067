import { memo, useCallback } from 'react';

import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { RooftopLeadForwarding, RooftopLeadForwardingSettings } from 'enums/columns/rooftopLeadForwarding';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const { SOURCE_NAMES, ROOFTOP_NAME, ADF, EMAIL } = RooftopLeadForwarding;

const { BOLD } = ItemGridRowDisplayType;

export const RooftopLeadForwardingDetailsTab = memo<ItemTabProps>(({ item, metadata, onEdit }) => {
  const gridConfig: ItemGridRowProps[] = [
    { fields: [ROOFTOP_NAME], displayType: [BOLD] },
    { fields: [SOURCE_NAMES], displayType: [BOLD], canEdit: true },
    { fields: [EMAIL], displayType: [BOLD], canEdit: true },
    { fields: [ADF], displayType: [BOLD], canEdit: true },
  ];

  // Callbacks
  const onEditCallback = useCallback((field?: string) => onEdit(field, translate.t('forwarding_rule')), [onEdit]);

  return item ? (
    <>
      {gridConfig.map(config => (
        <ItemGridRowSection
          config={config}
          item={item}
          key={formatItemGridRowSectionKey(config.fields)}
          metadata={metadata}
          onEdit={authorizedCallback({ cb: onEditCallback, isAuth: !!onEdit })}
          settings={RooftopLeadForwardingSettings}
        />
      ))}
    </>
  ) : null;
});
