import 'styled-components/macro';

import NoResultsIcon from 'components/ui/icons/NoResultsIcon';
import { ElementTestId } from 'enums/testing';
import { useSearch } from 'hooks/contexts/useSearch';
import { useUser } from 'hooks/contexts/useUser';
import { translate } from 'utils/intlUtils';

import Placeholder from './Placeholder';

const NoResultsPlaceholder = () => {
  const { t } = translate;
  const { clearSearchParams, clearGlobalSearchParams } = useSearch();
  const { isWhiteLabelScoped } = useUser();

  const buttons = [t('clear_filters')];

  /* Admin users have the ability to clear global filters */
  if (isWhiteLabelScoped) {
    buttons.push(t('clear_global_filter_other'));
  }

  return (
    <Placeholder
      buttonText={buttons}
      css="height: 100%;"
      icon={<NoResultsIcon />}
      onClick={(e, button) => {
        if (button === 0) {
          clearSearchParams(true);
        } else {
          clearGlobalSearchParams();
        }
      }}
      subtitle={t('no_results_for_selected_filter_message')}
      testId={ElementTestId.NO_RESULTS_PLACEHOLDER}
      title={t('no_results')}
    />
  );
};

export default NoResultsPlaceholder;
