import { memo, type SVGProps } from 'react';

const FilterSlidersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M12.048 21.185c-.307 0-.567-.104-.778-.313a1.047 1.047 0 0 1-.318-.776V16.09c0-.31.106-.568.318-.777.211-.209.47-.313.778-.313.307 0 .566.104.775.313.21.21.314.468.314.777V17h6.91c.31 0 .568.104.777.313.21.21.313.468.313.777 0 .307-.104.566-.313.778a1.048 1.048 0 0 1-.776.317h-6.91v.91c0 .31-.105.568-.314.777a1.054 1.054 0 0 1-.776.313Zm-8.096-2c-.309 0-.567-.105-.776-.317a1.066 1.066 0 0 1-.314-.778c0-.308.105-.566.314-.776.209-.21.467-.314.776-.314h4.006c.309 0 .568.104.777.313.208.21.313.468.313.777 0 .307-.105.566-.313.778a1.047 1.047 0 0 1-.777.317H3.952Zm4.006-4.095c-.307 0-.567-.106-.778-.318a1.058 1.058 0 0 1-.317-.778v-.904h-2.91c-.31 0-.568-.105-.777-.313A1.055 1.055 0 0 1 2.862 12c0-.309.105-.568.314-.777.209-.208.467-.313.776-.313h2.91V10c0-.309.106-.568.318-.777.212-.208.47-.313.778-.313.307 0 .566.105.775.313.21.21.315.468.315.777v4c0 .309-.105.568-.313.777a1.054 1.054 0 0 1-.777.313Zm4.084-2c-.309 0-.568-.105-.777-.313a1.055 1.055 0 0 1-.313-.777c0-.309.105-.568.313-.777.21-.208.468-.313.777-.313h8.006c.309 0 .567.105.776.313.21.21.313.468.313.777s-.104.568-.313.777a1.055 1.055 0 0 1-.776.313h-8.006ZM16.048 9c-.307 0-.567-.104-.778-.313a1.048 1.048 0 0 1-.318-.777V3.904c0-.308.106-.567.318-.776.211-.209.47-.313.778-.313.307 0 .566.104.775.313.21.209.314.468.314.776v.91h2.91c.31 0 .568.105.777.314.21.209.313.468.313.776s-.104.567-.313.779a1.047 1.047 0 0 1-.776.317h-2.91v.91c0 .31-.105.568-.314.777a1.054 1.054 0 0 1-.776.313ZM3.952 7c-.309 0-.567-.106-.776-.317a1.065 1.065 0 0 1-.314-.779c0-.307.105-.565.314-.775.209-.21.467-.314.776-.314h8.006c.309 0 .568.104.777.313.208.209.313.468.313.776s-.105.567-.313.779a1.047 1.047 0 0 1-.777.317H3.952Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(FilterSlidersIcon);
