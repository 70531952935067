import { memo, useCallback, useMemo } from 'react';

import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import Text from 'components/core/typography/Text';
import { getShoppingToolsTitle } from 'components/sections/rooftops/shoppingTools/utils';
import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import CollapsibleSection from 'components/ui/details/collapsibleSections/CollapsibleSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { BuilderType } from 'enums/builderType';
import { RooftopShoppingTools, RooftopShoppingToolsSettings } from 'enums/columns/rooftopShoppingTools';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { CustomEntity } from 'enums/extendedEntityType';
import { FeatureBundleSet } from 'enums/featureBundle';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import type { ShoppingToolsDetailsQuery } from 'store/api/graph/interfaces/types';
import { FONT_WEIGHT_BOLD } from 'styles/typography';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const {
  SHOPPING_TOOLS_CONFIG_MAKES,
  SHOPPING_TOOLS_CONFIG_YEAR_MODELS,
  SHOPPING_TOOLS_CONFIG_INCLUDE_INCENTIVES,
  SHOPPING_TOOLS_CONFIG_INCLUDE_BULK_ADJUSTMENTS,
  SHOPPING_TOOLS_CONFIG_SHOW_LEASE_PAYMENTS,
  SHOPPING_TOOLS_CONFIG_SHOW_FINANCE_PAYMENTS,
  SHOPPING_TOOLS_CONFIG_INCLUDE_COMMERCIAL_VEHICLES,
  SHOPPING_TOOLS_CONFIG_SHOWROOM_DISCLAIMER_TEMPLATE,
  SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_DISCLAIMER_TEMPLATE,
  SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_CONFIG,
  SHOPPING_TOOLS_CONFIG_SHOWROOM_CONFIG,
} = RooftopShoppingTools;

const { BOLD, RICH_TEXT } = ItemGridRowDisplayType;

const YearModelLabel = styled(Text)`
  font-weight: ${FONT_WEIGHT_BOLD};
  padding-top: 10px;

  :last-child {
    padding-bottom: 10px;
  }
`;

const { t } = translate;

interface Props {
  /**
   * Indicates whether the details view should be closed after saving.
   */
  shouldCloseOnSave?: boolean;
}

export const ShoppingToolsDetailsTab = memo<ItemTabProps<ShoppingToolsDetailsQuery['item'], Props>>(
  ({ item: shoppingTools, metadata, onEdit, shouldCloseOnSave = true }) => {
    const { toggleTier } = useCreateModify();
    const nestedViewContext = useNestedView();
    const { flags } = useFeatureFlags();
    const shoppingToolsConfiguration = shoppingTools?.shoppingToolsConfiguration;

    const isShowroomFeatureEnabled = useMemo(
      () =>
        shoppingTools
          ? isFeatureEnabledForRooftop({
              rooftop: shoppingTools,
              feature: FeatureBundleSet.RETAIL_SHOWROOM,
              featureFlagOn: flags.showroomConfigurationEnabled,
            })
          : false,
      [shoppingTools, flags]
    );

    const isBuildAndPriceFeatureEnabled = useMemo(
      () =>
        shoppingTools
          ? isFeatureEnabledForRooftop({
              rooftop: shoppingTools,
              feature: FeatureBundleSet.RETAIL_BUILD_AND_PRICE,
              featureFlagOn: flags.showroomConfigurationEnabled,
            })
          : false,
      [shoppingTools, flags]
    );

    const topRowConfig: ItemGridRowProps[] = [
      {
        fields: [SHOPPING_TOOLS_CONFIG_SHOWROOM_CONFIG],
        displayType: [BOLD],
        canEdit: true,
        gridCellRenderMethod: (_, item) => (
          <PrimaryText>
            {item.shoppingToolsConfiguration?.showroomConfiguration ? t('enabled') : t('disabled')}
          </PrimaryText>
        ),
      },
      {
        fields: [SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_CONFIG],
        displayType: [BOLD],
        canEdit: true,
        gridCellRenderMethod: (_, item) => (
          <PrimaryText>
            {item.shoppingToolsConfiguration?.buildAndPriceConfiguration ? t('enabled') : t('disabled')}
          </PrimaryText>
        ),
      },
      !!shoppingToolsConfiguration && { fields: [SHOPPING_TOOLS_CONFIG_MAKES], displayType: [BOLD], canEdit: true },
    ].filter(Boolean);

    const otherConfig: ItemGridRowProps[] = [
      { fields: [SHOPPING_TOOLS_CONFIG_INCLUDE_COMMERCIAL_VEHICLES], displayType: [BOLD], canEdit: true },
      { fields: [SHOPPING_TOOLS_CONFIG_INCLUDE_BULK_ADJUSTMENTS], displayType: [BOLD], canEdit: true },
      { fields: [SHOPPING_TOOLS_CONFIG_INCLUDE_INCENTIVES], displayType: [BOLD], canEdit: true },
      { fields: [SHOPPING_TOOLS_CONFIG_SHOW_FINANCE_PAYMENTS], displayType: [BOLD], canEdit: true },
      { fields: [SHOPPING_TOOLS_CONFIG_SHOW_LEASE_PAYMENTS], displayType: [BOLD], canEdit: true },
      { fields: [SHOPPING_TOOLS_CONFIG_SHOWROOM_DISCLAIMER_TEMPLATE], displayType: [RICH_TEXT], canEdit: true },
      { fields: [SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_DISCLAIMER_TEMPLATE], displayType: [RICH_TEXT], canEdit: true },
    ];

    // Callbacks
    const onEditCallback = useCallback(
      (field?: string) => {
        toggleTier(CreateModifyTiers.TIER_0, {
          tierId: CreateModifyTiers.TIER_0,
          type: BuilderType.SHOPPING_TOOLS_MODIFY,
          entityType: CustomEntity.ROOFTOP_SHOPPING_TOOLS,
          isCreating: false,
          title: t('modify_x', [getShoppingToolsTitle({ isBuildAndPriceFeatureEnabled, isShowroomFeatureEnabled })]),
          itemId: shoppingTools?.id,
          seededData: { id: shoppingTools?.id },
          activeField: field,
          data: shoppingTools,
          onStepSave: async (_, data) => {
            if (!data.shoppingToolsConfiguration && shouldCloseOnSave) {
              nestedViewContext.close();
            }
          },
        });
      },
      [
        toggleTier,
        isBuildAndPriceFeatureEnabled,
        isShowroomFeatureEnabled,
        shoppingTools,
        shouldCloseOnSave,
        nestedViewContext,
      ]
    );

    // Authorized edit callback for editing any fields
    const authEditCallback = useMemo(
      () => authorizedCallback({ cb: onEditCallback, isAuth: !!onEdit }),
      [onEdit, onEditCallback]
    );

    return (
      <>
        {topRowConfig
          .filter(config => {
            if (config.fields.includes(SHOPPING_TOOLS_CONFIG_SHOWROOM_CONFIG)) {
              return isShowroomFeatureEnabled;
            } else if (config.fields.includes(SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_CONFIG)) {
              return isBuildAndPriceFeatureEnabled;
            }
            return true;
          })
          .map(config => (
            <ItemGridRowSection
              config={config}
              item={shoppingTools}
              key={formatItemGridRowSectionKey(config.fields)}
              metadata={metadata}
              onEdit={authEditCallback}
              settings={RooftopShoppingToolsSettings}
            />
          ))}

        {shoppingToolsConfiguration?.yearModels.map(item => (
          <CollapsibleSection
            key={item.year}
            label={`${item.year.toString()} ${t('model_other')}`}
            onEdit={authEditCallback ? () => authEditCallback(SHOPPING_TOOLS_CONFIG_YEAR_MODELS) : undefined}
          >
            {item.models.map(model => {
              const make = shoppingToolsConfiguration?.makes?.find(make => make.id === model.makeId);
              const makeLabel = make ? `${make.name.value} ` : '';
              return (
                <YearModelLabel key={`${model.id}-${item.year}`}>
                  {makeLabel}
                  {model.name.value}
                </YearModelLabel>
              );
            })}
          </CollapsibleSection>
        ))}

        {shoppingToolsConfiguration &&
          otherConfig
            .filter(config => {
              if (config.fields.includes(SHOPPING_TOOLS_CONFIG_SHOWROOM_DISCLAIMER_TEMPLATE)) {
                // Filter out showroom fields if the showroom configuration is not enabled
                return isShowroomFeatureEnabled && !!shoppingTools?.shoppingToolsConfiguration?.showroomConfiguration;
              } else if (config.fields.includes(SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_DISCLAIMER_TEMPLATE)) {
                // Filter out build & price fields if the build & price configuration is not enabled
                return (
                  isBuildAndPriceFeatureEnabled &&
                  !!shoppingTools?.shoppingToolsConfiguration?.buildAndPriceConfiguration
                );
              }

              return true;
            })
            .map(config => (
              <ItemGridRowSection
                config={config}
                item={shoppingTools}
                key={formatItemGridRowSectionKey(config.fields)}
                metadata={metadata}
                onEdit={authEditCallback}
                settings={RooftopShoppingToolsSettings}
              />
            ))}
      </>
    );
  }
);
