import type { HTMLProps, ReactNode } from 'react';
import { cloneElement, forwardRef, isValidElement } from 'react';

import { useMergeRefs } from '@floating-ui/react';

import { usePopoverContext } from 'hooks/contexts/usePopoverContext';

interface PopoverTriggerProps {
  /** The children to render inside or as the tigger element */
  children: ReactNode;
  /** Whether the children should be the trigger element, or rendered within a default button */
  asChild?: boolean;
}

const PopoverTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement> & PopoverTriggerProps>(
  ({ children, asChild = false, ...props }, propRef) => {
    const context = usePopoverContext();
    const childrenRef = (children as any)?.ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows passing any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed',
        })
      );
    }

    return (
      <button
        // This data attribute can be used as a hook for styling the trigger based on the state
        data-state={context.open ? 'open' : 'closed'}
        ref={ref}
        type="button"
        {...context.getReferenceProps(props)}
      >
        {children}
      </button>
    );
  }
);

export default PopoverTrigger;
