import { Navigate, Route } from 'react-router-dom';

import DynamicImport from 'components/core/DynamicImport';
import RestrictedRoute from 'components/core/routing/RestrictedRoute';
import MyAccountContainer from 'containers/myAccount/MyAccountContainer';
import { SecondaryViewType } from 'containers/nestedView/interfaces';
import SettingsContentContainer from 'containers/settings/SettingsContentContainer';
import { CustomEntity } from 'enums/extendedEntityType';
import { RoutePath, SubRoute } from 'enums/routePath';
import { TabType } from 'enums/tabType';
import { EntityType, SortDirection } from 'store/api/graph/interfaces/types';

const SettingsLayout = DynamicImport(() => import('components/ui/layouts/SettingsLayout'));

const SettingsRoutes = () => (
  <Route
    element={<RestrictedRoute authorizedPermissions={null} component={SettingsLayout} />}
    handle={{ pathPattern: RoutePath.SETTINGS }}
    path={RoutePath.SETTINGS}
  >
    <Route handle={{ pathPattern: `/${SubRoute.GROUP}/:groupId` }} path={`${SubRoute.GROUP}/:groupId?`}>
      <Route
        element={
          <SettingsContentContainer
            entityType={CustomEntity.USER}
            setQueryVars={({ group }) => ({
              filter: { groupId: group?.id },
              sort: [
                { id: 'active', sortDirection: SortDirection.DESCENDING },
                { id: 'updated', sortDirection: SortDirection.DESCENDING },
              ],
            })}
            titleKey="users"
            viewType={SecondaryViewType.LIST}
          />
        }
        handle={{ labelKey: 'users', pathPattern: `/${SubRoute.USERS}` }}
        path={SubRoute.USERS}
      />
    </Route>
    <Route handle={{ pathPattern: `/${SubRoute.ROOFTOP}/:rooftopId` }} path={`${SubRoute.ROOFTOP}/:rooftopId?`}>
      <Route
        element={
          <SettingsContentContainer
            entityType={CustomEntity.ROOFTOP}
            setQueryVars={({ rooftop }) => ({ id: rooftop?.id })}
            viewType={SecondaryViewType.DETAILS}
          />
        }
        handle={{ labelKey: 'general', pathPattern: `/${SubRoute.GENERAL}` }}
        path={SubRoute.GENERAL}
      />
      <Route
        element={
          <SettingsContentContainer
            entityType={CustomEntity.ROOFTOP_BULK_ADJUSTMENT}
            setQueryVars={({ rooftop }) => ({
              filter: {
                rooftopId: rooftop?.id,
              },
            })}
            titleKey="bulk_adjustment_other"
            viewType={SecondaryViewType.LIST}
          />
        }
        handle={{ labelKey: 'bulk_adjustment_other', pathPattern: `/${SubRoute.BULK_ADJUSTMENTS}` }}
        path={SubRoute.BULK_ADJUSTMENTS}
      />
      <Route
        element={
          <SettingsContentContainer
            entityType={CustomEntity.ROOFTOP_TAGS}
            setQueryVars={({ rooftop }) => ({
              rooftopId: rooftop?.id,
            })}
            titleKey="tags"
            viewType={SecondaryViewType.LIST}
          />
        }
        handle={{ labelKey: 'tags', pathPattern: `/${SubRoute.TAGS}` }}
        path={SubRoute.TAGS}
      />
      <Route
        element={
          <SettingsContentContainer
            entityType={EntityType.LEAD_FORWARD}
            setQueryVars={({ rooftop }) => ({
              id: rooftop?.id,
            })}
            titleKey="lead_forwarding"
            viewType={SecondaryViewType.LIST}
          />
        }
        handle={{ labelKey: 'lead_forwarding', pathPattern: `/${SubRoute.LEAD_FORWARDING}` }}
        path={SubRoute.LEAD_FORWARDING}
      />
      <Route
        element={
          <SettingsContentContainer
            entityType={CustomEntity.ROOFTOP_DEPARTMENTS}
            setQueryVars={({ rooftop }) => ({
              id: rooftop?.id,
            })}
            titleKey="departments"
            viewType={SecondaryViewType.LIST}
          />
        }
        handle={{ labelKey: 'departments', pathPattern: `/${SubRoute.DEPARTMENTS}` }}
        path={SubRoute.DEPARTMENTS}
      />
      <Route
        element={
          <SettingsContentContainer
            entityType={CustomEntity.ROOFTOP_PRICING_SEGMENTS}
            setQueryVars={({ rooftop }) => ({
              filter: {
                rooftopId: rooftop?.id,
              },
            })}
            titleKey="pricing_segment_other"
            viewType={SecondaryViewType.LIST}
          />
        }
        handle={{ labelKey: 'pricing_segment_other', pathPattern: `/${SubRoute.PRICING_SEGMENTS}` }}
        path={SubRoute.PRICING_SEGMENTS}
      />
      <Route
        element={
          <SettingsContentContainer
            entityType={CustomEntity.ROOFTOP_INTEGRATIONS}
            setQueryVars={({ rooftop }) => ({
              filter: {
                rooftopId: rooftop?.id,
              },
            })}
            titleKey="integration_other"
            viewType={SecondaryViewType.LIST}
          />
        }
        handle={{ labelKey: 'integrations', pathPattern: `/${SubRoute.INTEGRATIONS}` }}
        path={SubRoute.INTEGRATIONS}
      />
      <Route
        element={
          <SettingsContentContainer
            entityType={EntityType.MAILBOX}
            setQueryVars={({ rooftop }) => ({
              filter: {
                rooftopId: rooftop?.id,
              },
            })}
            titleKey="mailboxes"
            viewType={SecondaryViewType.LIST}
          />
        }
        handle={{ labelKey: 'mailboxes', pathPattern: `/${SubRoute.MAILBOXES}` }}
        path={SubRoute.MAILBOXES}
      />
      <Route
        element={
          <SettingsContentContainer
            entityType={CustomEntity.ROOFTOP_CONTACTS}
            setQueryVars={({ rooftop }) => ({
              filter: {
                archived: false,
                rooftopId: rooftop?.id,
              },
            })}
            titleKey="contacts"
            viewType={SecondaryViewType.LIST}
          />
        }
        handle={{ labelKey: 'contacts', pathPattern: `/${SubRoute.CONTACTS}` }}
        path={SubRoute.CONTACTS}
      />
      <Route
        element={
          <SettingsContentContainer
            entityType={CustomEntity.SETTINGS_ROOFTOP_SHOPPING_TOOLS}
            setQueryVars={({ rooftop }) => ({
              id: rooftop?.id,
            })}
            titleKey="showroom_and_build_and_price"
            viewType={SecondaryViewType.DETAILS}
          />
        }
        handle={{
          labelKey: 'showroom_and_build_and_price',
          pathPattern: `/${SubRoute.SHOWROOM_BUILD_AND_PRICE}`,
        }}
        path={SubRoute.SHOWROOM_BUILD_AND_PRICE}
      />
    </Route>
    <Route handle={{ pathPattern: `/${SubRoute.ACCOUNT}` }} path={SubRoute.ACCOUNT}>
      <Route element={<Navigate to={SubRoute.GENERAL} />} index />
      <Route
        element={<MyAccountContainer showIndividualTab={TabType.DETAILS} titleKey="general" />}
        handle={{ labelKey: 'general', pathPattern: `/${SubRoute.GENERAL}` }}
        path={SubRoute.GENERAL}
      />
      <Route
        element={<MyAccountContainer showIndividualTab={TabType.USER_PERMISSIONS} titleKey="permissions" />}
        handle={{ labelKey: 'permissions', pathPattern: `/${SubRoute.PERMISSIONS}` }}
        path={SubRoute.PERMISSIONS}
      />
    </Route>
  </Route>
);
export default SettingsRoutes;
