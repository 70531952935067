import { Fragment, useCallback, useMemo } from 'react';

import RetailItemActivity from 'components/sections/inventoryItems/retailItems/RetailItemActivity';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import type { Intl } from 'utils/intlUtils';

import {
  AppointmentLinkedTabSection,
  AttachmentsLinkedTabSection,
  ConversationLinkedTabSection,
  CreditApplicationLinkedTabSection,
  InventoryItemsLinkedTabSection,
  LeadLinkedTabSection,
  PanelsLinkedTabSection,
  PurchasedByLinkedTabSection,
  RetailBulkAdjustmentLinkedTabSection,
  RetailIntegrationLinkedTabSection,
  RooftopConfigurationsLinkedTabSection,
  RooftopLinkedTabSection,
  RooftopShoppingToolsLinkedTabSection,
  TasksLinkedTabSection,
  TradeInAppraisalsLinkedTabSection,
  TradeInAppraiserLinkedTabSection,
  TradeInGuaranteedValueLinkedTabSection,
  TradeInUsersAppraisalsLinkedTabSection,
  UserLinkedTabSection,
  VehiclePurchasedLinkedTabSection,
  WebsiteIntegrationLinkedTabSection,
} from './LinkedTabSections';

/*
 * TODO: Move to a more generic location
 * Common props shared by all sections
 *
 * Example configuring Appointments viewability:
 * +------------+---------------+------------------------+
 * | isReadOnly | isEditAllowed |       UI Result        |
 * +------------+---------------+------------------------+
 * | True       | True          | Hidden                 |
 * | True       | False         | Hidden                 |
 * | False      | False         | Upcoming Appointments  |
 * | False      | True          | Create Appointment |+| |
 * +------------+---------------+------------------------+
 *
 */
export interface SectionProps {
  /** Generic props unique to specific sections. */
  [key: string]: any;
  /** Whether user can click on list items. */
  isInteractive?: boolean;
  /**
   * Whether section is a list of items or a single view item.
   *
   *  - When set to false, the user will have the ability to add one item to a linked section (if there are
   *    already no items and a valid onAdd method is included) but no more after that.
   *
   *  - When set to true, the user is able to add as many items to a linked section that they want (provided
   *    a valid onAdd method is included and isReadOnly is not true)
   *
   * Note that omitting this property is the same as explicitly setting it to false.
   */
  isList?: boolean;
  /** Title of the section. */
  sectionTitle?: Intl;
  /** Title of the section when empty. */
  placeholderTitle?: Intl;
  /** Method to invoke to add data to a section. */
  onAdd?: () => void;
  /** Sets this section to read only (no onAdd or onEdit callbacks will be made) */
  isReadOnly?: boolean;
  /** Whether or not this section is nested */
  isNested?: boolean;
  /** Whether or not to use MAX_LINKED_LIST_ITEMS for a list */
  showAll?: boolean;
  /** Whether or not current user has builder `requiredPermissions`. Defaults to `true`. */
  isEditAllowed?: boolean;
  /** Whether or not this section is visible. Defaults to `true` */
  isVisible?: boolean;
}

export type SectionConfigType = { type: LinkedTabSection; settings?: SectionProps };

// TODO: Create generic PANELS section
export enum LinkedTabSection {
  USER = 'USER',
  LEAD = 'LEAD',
  PANELS = 'PANELS',
  INVENTORY_ITEMS = 'INVENTORY_ITEMS',
  TRADE_IN_GUARANTEED_VALUE = 'TRADE_IN_GUARANTEED_VALUE',
  TRADE_IN_APPRAISALS = 'TRADE_IN_APPRAISALS',
  TRADE_IN_USER_APPRAISALS = 'TRADE_IN_USER_APPRAISALS',
  TRADE_IN_APPRAISER = 'TRADE_IN_APPRAISER',
  ROOFTOP_CONFIGURATIONS = 'ROOFTOP_CONFIGURATIONS',
  RETAIL_INTEGRATIONS = 'RETAIL_INTEGRATIONS',
  RETAIL_ACTIVITY = 'RETAIL_ACTIVITY',
  RETAIL_ADJUSTMENTS = 'RETAIL_ADJUSTMENTS',
  TASKS = 'TASKS',
  APPOINTMENTS = 'APPOINTMENTS',
  ROOFTOP = 'ROOFTOP',
  ROOFTOP_SHOPPING_TOOLS = 'ROOFTOP_SHOPPING_TOOLS',
  CREATED_BY = 'CREATED_BY',
  ATTACHMENTS = 'ATTACHMENTS',
  CONVERSATION = 'CONVERSATION',
  ENVIRONMENT = 'ENVIRONMENT',
  PURCHASED_BY = 'PURCHASED_BY',
  VEHICLE_PURCHASED = 'VEHICLE_PURCHASED',
  LENDER_APPLICATION = 'LENDER_APPLICATION',
  WEBSITE_INTEGRATIONS = 'WEBSITE_INTEGRATIONS',
}
export interface LinkedTabSectionProps {
  /** Props shared by all tabs, passed down by the item container e.g. item data */
  itemTabProps: ItemTabProps;
  /** Title used in builder for all sections within the `LinkedTab` */
  builderTitle?: Intl;
  /** Shared and specific props used to configure `LinkedTabSections` e.g. overriding default `sectionTitle` */
  settings?: SectionProps;
}

export interface LinkedTabProps extends LinkedTabSectionProps {
  sections: (LinkedTabSection | SectionConfigType)[];
}

export const LinkedTab = ({ sections, ...tabProps }: LinkedTabProps) => {
  const { id } = tabProps?.itemTabProps?.item || {};
  const getSections = useCallback(
    (sectionName: LinkedTabSection, settings?: any) =>
      ({
        [LinkedTabSection.USER]: <UserLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.LEAD]: <LeadLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.INVENTORY_ITEMS]: <InventoryItemsLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.TRADE_IN_GUARANTEED_VALUE]: (
          <TradeInGuaranteedValueLinkedTabSection {...tabProps} settings={settings} />
        ),
        [LinkedTabSection.TRADE_IN_APPRAISALS]: <TradeInAppraisalsLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.TRADE_IN_USER_APPRAISALS]: (
          <TradeInUsersAppraisalsLinkedTabSection {...tabProps} settings={settings} />
        ),
        [LinkedTabSection.TRADE_IN_APPRAISER]: <TradeInAppraiserLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.APPOINTMENTS]: <AppointmentLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.TASKS]: <TasksLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.PANELS]: <PanelsLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.RETAIL_INTEGRATIONS]: <RetailIntegrationLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.RETAIL_ACTIVITY]: <RetailItemActivity retailItem={tabProps?.itemTabProps?.item} />,
        [LinkedTabSection.CREATED_BY]: (
          <UserLinkedTabSection
            {...tabProps}
            settings={{ ...settings, isReadOnly: true, sectionTitle: 'created_by' }}
          />
        ),
        [LinkedTabSection.ROOFTOP]: <RooftopLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.ATTACHMENTS]: <AttachmentsLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.CONVERSATION]: <ConversationLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.ROOFTOP_CONFIGURATIONS]: (
          <RooftopConfigurationsLinkedTabSection {...tabProps} settings={settings} />
        ),
        [LinkedTabSection.ROOFTOP_SHOPPING_TOOLS]: (
          <RooftopShoppingToolsLinkedTabSection {...tabProps} settings={settings} />
        ),
        [LinkedTabSection.RETAIL_ADJUSTMENTS]: (
          <RetailBulkAdjustmentLinkedTabSection {...tabProps} settings={settings} />
        ),
        [LinkedTabSection.PURCHASED_BY]: <PurchasedByLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.VEHICLE_PURCHASED]: <VehiclePurchasedLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.LENDER_APPLICATION]: <CreditApplicationLinkedTabSection {...tabProps} settings={settings} />,
        [LinkedTabSection.WEBSITE_INTEGRATIONS]: (
          <WebsiteIntegrationLinkedTabSection {...tabProps} settings={settings} />
        ),
      })[sectionName],
    [tabProps]
  );

  const sectionsToRender = useMemo(
    () =>
      sections
        .filter(section => typeof section === 'string' || section.settings?.isVisible !== false)
        .map(section => {
          const config = section as SectionConfigType;
          const sectionName = config?.type || section;
          const settings = {
            ...config?.settings,
            isEditAllowed:
              !isItemArchived(tabProps?.itemTabProps?.item) &&
              (config?.settings?.isEditAllowed === undefined ? true : config?.settings?.isEditAllowed),
          } as SectionProps;

          return (
            <Fragment key={`${sectionName}-${id}-${config?.settings?.fieldName}`}>
              {getSections(sectionName, settings)}
            </Fragment>
          );
        }),
    [getSections, id, sections, tabProps?.itemTabProps?.item]
  );

  return <>{sectionsToRender}</>;
};
