import { memo, type SVGProps } from 'react';

const ItalicIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 20 20" width={20} {...props}>
    <path
      d="M5.198 15.998c-.321 0-.594-.113-.82-.337a1.115 1.115 0 0 1-.336-.82c0-.321.112-.594.337-.819.225-.225.498-.337.82-.337h2.206l2.455-7.36H7.728a1.12 1.12 0 0 1-.82-.337 1.115 1.115 0 0 1-.336-.82c0-.321.112-.594.337-.819.224-.224.498-.337.82-.337h6.25c.32 0 .594.113.818.337.225.225.337.498.337.82 0 .321-.112.594-.337.819a1.115 1.115 0 0 1-.819.337h-1.79l-2.455 7.36h1.715c.322 0 .595.112.82.337.224.225.336.498.336.82 0 .321-.112.594-.336.819a1.115 1.115 0 0 1-.82.337h-6.25Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(ItalicIcon);
