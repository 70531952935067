import { memo, useCallback, useEffect, useMemo } from 'react';

import { cloneDeep, get } from 'lodash-es';
import styled from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import HeroSection from 'components/ui/details/HeroSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { BuilderType } from 'enums/builderType';
import { Rooftop, RooftopSettings } from 'enums/columns/rooftop';
import { FeatureBundleSet } from 'enums/featureBundle';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useUser } from 'hooks/contexts/useUser';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import type { RooftopDetailsContainerQuery } from 'store/api/graph/interfaces/types';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';
import { LINE_HEIGHT_2, NEUTRAL_050 } from 'styles/tokens';
import { FONT_SIZE_14, FONT_WEIGHT_SEMI_BOLD } from 'styles/typography';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { LDContextKinds } from 'utils/featureFlagUtils';
import { formatCommonCells, formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

import PackageSection from './PackageSection';

const {
  ID,
  GROUP_NAME,
  GROUP_ID,
  WHITE_LABEL_NAME_VALUE,
  ACTIVE,
  LOCALE,
  TIME_ZONE,
  DESCRIPTION,
  LOCATION,
  PLACE_ID,
  EMAIL_GENERAL,
  EMAIL_BILLING,
  EMAIL_MAILBOX_SUBDOMAIN,
  PHONE_NUMBER,
  FAX_NUMBER,
  WEBSITE,
  WEBSITE_PROVIDER,
  CARFAX_ID,
  CREATED,
  UPDATED,
  DEALERTRACK_ID,
  DEALERTRACK_CONFIRM_ID,
  WARRANTY_TYPES,
} = Rooftop;

const { BOLD, RICH_TEXT, EXTERNAL_LINK } = ItemGridRowDisplayType;

/**
 * Get the bottom grid configuration for the rooftop detail view
 */
export const getGridConfigBottom = ({
  hasFullRooftopPermissions,
  isWhiteLabelScoped,
  isLeadFeatureEnabled,
  isWarrantyTypeAvailable,
}: {
  hasFullRooftopPermissions: boolean;
  isWhiteLabelScoped: boolean;
  isLeadFeatureEnabled: boolean;
  isWarrantyTypeAvailable: boolean;
}): ItemGridRowProps[] => {
  const config: ItemGridRowProps[] = [
    {
      fields: [
        { id: WEBSITE, displayType: [EXTERNAL_LINK] },
        { id: WEBSITE_PROVIDER, displayType: [BOLD], canEdit: hasFullRooftopPermissions },
      ],
      canEdit: true,
    },
    {
      fields: [EMAIL_GENERAL, EMAIL_BILLING],
      displayType: [BOLD],
      canEdit: true,
    },
    {
      fields: [PHONE_NUMBER, FAX_NUMBER],
      displayType: [BOLD],
      canEdit: true,
    },
    { fields: [TIME_ZONE, PLACE_ID], displayType: [BOLD], canEdit: true },
    { fields: [LOCATION], canEdit: true },
    // If there is no warranty types available, do not show warranty type field
    isWarrantyTypeAvailable && { fields: [WARRANTY_TYPES], displayType: [BOLD], canEdit: true },
    {
      fields: [
        // User needs to be whitelabel scoped in order to view edit the carfax id
        isWhiteLabelScoped && CARFAX_ID,
        // Dealertrack fields are only relevant if the lead feature is enabled
        isLeadFeatureEnabled && DEALERTRACK_ID,
        isLeadFeatureEnabled && DEALERTRACK_CONFIRM_ID,
      ].filter(Boolean),
      displayType: [BOLD],
      canEdit: true,
    },
    { fields: [DESCRIPTION], displayType: [RICH_TEXT], canEdit: true },
    { fields: [ACTIVE], displayType: [BOLD] },
    { fields: [CREATED, UPDATED], displayType: [BOLD] },
  ].filter(Boolean);

  return config;
};

const gridConfigTop: ItemGridRowProps[] = [
  { fields: [{ id: ID, canCopy: true }, WHITE_LABEL_NAME_VALUE], displayType: [BOLD] },
  { fields: [{ id: GROUP_ID, canCopy: true }, GROUP_NAME], displayType: [BOLD] },
  { fields: [EMAIL_MAILBOX_SUBDOMAIN, { id: LOCALE, canEdit: true }], displayType: [BOLD] },
];

const EmptyLogoPlaceholder = styled.div`
  background: ${NEUTRAL_050};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  place-content: center;

  ${Label} {
    font-size: ${FONT_SIZE_14};
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
    line-height: ${LINE_HEIGHT_2};
    text-align: center;
  }
`;

const RooftopDetailsTab = memo<ItemTabProps<RooftopDetailsContainerQuery['item']>>(({ item: rooftop, onEdit }) => {
  const { builderConfig } = useBuilderConfig();
  const { user, isWhiteLabelScoped, hasPermissions } = useUser();
  const { flags, updateFeatureFlagContext } = useFeatureFlags();

  useEffect(() => {
    void updateFeatureFlagContext([
      {
        kind: LDContextKinds.rooftop,
        context: { key: rooftop?.id || '', name: rooftop?.name.en || undefined },
      },
    ]);
  }, [rooftop, updateFeatureFlagContext]);

  // onClick callbacks, TIME_ZONE and PLACE_ID detail fields should map to the LOCATION field in the Rooftop Builder
  const onEditCallback = useCallback(
    activeField => {
      onEdit(
        [Rooftop.TIME_ZONE, Rooftop.PLACE_ID].includes(activeField) ? Rooftop.LOCATION : activeField,
        translate.t('rooftop')
      );
    },
    [onEdit]
  );

  // Format grid config, based on the users permission/scope level
  const formattedGridTop = useMemo(
    () =>
      // Conditionally remove specific common fields if the user does not have the required scope
      formatCommonCells(gridConfigTop, user.scope, {
        itemId: ID,
        whiteLabel: WHITE_LABEL_NAME_VALUE,
        groupId: GROUP_ID,
      }),
    [user.scope]
  );

  const formattedGridBottom = useMemo(() => {
    if (!rooftop) {
      return [];
    }

    const hasFullRooftopPermissions = hasPermissions([{ resource: ResourceType.ROOFTOPS, level: AccessLevel.FULL }]);
    const config = cloneDeep(
      getGridConfigBottom({
        hasFullRooftopPermissions,
        isWhiteLabelScoped,
        isWarrantyTypeAvailable: !!rooftop?.availableWarrantyTypes?.length,
        isLeadFeatureEnabled: isFeatureEnabledForRooftop({
          rooftop,
          feature: FeatureBundleSet.LEAD,
          featureFlagOn: flags.rooftopPackageEnabled,
        }),
      })
    );
    return formatCommonCells(config, user.scope, { itemId: ID, whiteLabel: WHITE_LABEL_NAME_VALUE });
  }, [rooftop, hasPermissions, isWhiteLabelScoped, flags.rooftopPackageEnabled, user.scope]);

  // First two rows of the grid will be on the same hero as the logo hero image
  const HERO_GRID_ROWS = 2;

  const rooftopLogoUrl = get(rooftop, Rooftop.LOGO_URL) as string | undefined;

  return (
    <>
      <HeroSection
        fallbackSrc={
          <EmptyLogoPlaceholder>
            <Label>{translate.t('no_dealership_logo')}</Label>
          </EmptyLogoPlaceholder>
        }
        image={rooftopLogoUrl}
        onEdit={authorizedCallback({
          cb: () => onEditCallback(Rooftop.LOGO_URL),
          isAuth: hasPermissions(builderConfig[BuilderType.ROOFTOPS_MODIFY].requiredPermissions),
        })}
        ratioOfImageToContent={0.35}
      >
        {formattedGridTop.slice(0, HERO_GRID_ROWS).map(config => {
          const fields = config.fields;

          return (
            <ItemGridRowSection
              config={{ ...config, fields }}
              item={rooftop}
              key={formatItemGridRowSectionKey(config.fields)}
              onEdit={authorizedCallback({
                cb: onEditCallback,
                isAuth: hasPermissions(builderConfig[BuilderType.ROOFTOPS_MODIFY].requiredPermissions),
              })}
              settings={RooftopSettings}
            />
          );
        })}
      </HeroSection>
      {formattedGridTop.slice(HERO_GRID_ROWS).map(config => (
        <ItemGridRowSection
          config={config}
          item={rooftop}
          key={formatItemGridRowSectionKey(config.fields)}
          onEdit={authorizedCallback({
            cb: onEditCallback,
            isAuth: hasPermissions(builderConfig[BuilderType.ROOFTOPS_MODIFY].requiredPermissions),
          })}
          settings={RooftopSettings}
        />
      ))}

      {flags.rooftopPackageEnabled && rooftop && <PackageSection onEditCallback={onEditCallback} rooftop={rooftop} />}

      {formattedGridBottom.map(config => (
        <ItemGridRowSection
          config={config}
          item={rooftop}
          key={formatItemGridRowSectionKey(config.fields)}
          onEdit={authorizedCallback({
            cb: onEditCallback,
            isAuth: hasPermissions(builderConfig[BuilderType.ROOFTOPS_MODIFY].requiredPermissions),
          })}
          settings={RooftopSettings}
        />
      ))}
    </>
  );
});

export default RooftopDetailsTab;
