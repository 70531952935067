import { useMemo, useState } from 'react';

import styled from 'styled-components/macro';

import TertiaryText from 'components/core/typography/TertiaryText';
import TextRow from 'components/core/typography/TextRow';
import FilterableListDialog from 'components/ui/dialogs/FilterableListDialog';
import type { CommonListItem } from 'components/ui/lists/CommonList';
import { userCondensedListQuery } from 'containers/users/UsersContainerQuery';
import { ElementTestId } from 'enums/testing';
import { useQuery } from 'hooks/useQuery';
import type { UserResponseType } from 'store/api/graph/responses/responseTypes';
import { FONT_SIZE_13 } from 'styles/typography';
import { translate } from 'utils/intlUtils';

interface Props {
  /** The function to call when a user is selected from the dialog */
  onUserSelected: (user: UserResponseType) => void;
  /** The ID of the current user */
  myId: string;
  /** What to do when closing the dialog */
  onCancel: () => void;
}

const RooftopLabel = styled(TertiaryText)`
  font-size: ${FONT_SIZE_13};
`;

/**
 * A FilterableListDialog that allows for server-side searching of all users.
 * Filters out users who are not active.
 */
const UserSelector = ({ onUserSelected, myId, onCancel }: Props) => {
  const [listFilterKeyword, setListFilterKeyword] = useState<string>();

  const { data: users } = useQuery(userCondensedListQuery, {
    variables: { first: 100, keyword: listFilterKeyword, filter: { active: true } },
  });

  const options: CommonListItem[] = useMemo(() => {
    if (!users?.connection) {
      return [];
    }
    const allUsers: UserResponseType[] = users.connection.edges.map(edge => edge.node);
    const filteredUsers = allUsers.filter(user => user.id !== myId);
    return filteredUsers.map(user => ({
      label: user.displayName,
      id: user.id,
      labelElement: (
        <div>
          <TextRow>{user.displayName}</TextRow>
          {user.rooftopName && user.rooftopName.length > 0 && (
            <TextRow>
              <RooftopLabel>{user.rooftopName[0].name.value}</RooftopLabel>
            </TextRow>
          )}
        </div>
      ),
      onClick: () => {
        onUserSelected(user);
        onCancel();
      },
    }));
  }, [users.connection, myId, onUserSelected, onCancel]);

  return (
    <FilterableListDialog
      headerTitle={translate.t('login_as')}
      isFixedToTop
      isOpen
      isUsingServerSideSearch
      listItems={options}
      onCancel={() => {
        onCancel();
        setListFilterKeyword('');
      }}
      onSearchTermChanged={keyword => setListFilterKeyword(keyword)}
      searchPlaceholderText={translate.t('search_dealers')}
      testId={ElementTestId.LOGIN_AS_MODAL}
      width={350}
    />
  );
};

export default UserSelector;
