import { createContext } from 'react';

import type { SecondaryViewType, SecondaryViewWidth } from 'containers/nestedView/interfaces';
import type { SecondaryDetailViewProps, SecondaryListTabViewProps } from 'containers/nestedView/NestedViewSettings';
import type { ExtendedEntityType } from 'enums/extendedEntityType';
import type { PaginationProps } from 'enums/pagination';
import type { TabType } from 'enums/tabType';
import type { SetViewParameters } from 'providers/NestedViewProvider';

export interface SecondaryViewItem {
  /** Title of the section being viewed */
  title: string;
  /** The type of view this item is */
  type: SecondaryViewType;
  /** A method used to transform the data from the API (may be unnecessary) */
  queryAdapter?: (data: unknown) => void;
  /** The queryVars required for this view to show its info (used mainly for condensedlist) */
  queryVars?: Record<string, unknown>;
  /** The entity being viewed */
  entityType: ExtendedEntityType;
  /** The width of this Secondary View (Default is SecondaryViewWidth.DEFAULT) */
  width: SecondaryViewWidth;
  /** The configuration for this view */
  config: SecondaryDetailViewProps | SecondaryListTabViewProps;
  /** Any seeded data being passed into the view */
  seededData?: any;
}

export interface NestedPaginationState extends PaginationProps {
  tabId: TabType;
}

export interface NestedViewContextInterface {
  /**
   * An array of view items, the first item in the array is the currently active view
   */
  viewItems: SecondaryViewItem[];
  /**
   * Method to set a new view, pushing it to the top of the viewItems array.
   * If the view is a details view, it will replace the existing viewItems array.
   */
  setView: (args: SetViewParameters) => void;
  /**
   * Update current view - allow passing in of seeddata
   */
  updateCurrentView: <T = any>(data?: T) => void;
  /**
   * Method to go back to the previous view
   */
  back: () => void;
  /**
   * Method to close the nested view and clear all viewItems
   */
  close: () => void;
  /**
   * Whether or not a details view is currently being shown
   */
  isDetailsShown: boolean;
  // Global state
  /**
   * The currently active tab in the details view
   */
  activeDetailsTab?: TabType;
  /**
   * Method to update the active tab in the details view
   */
  setActiveDetailsTab: (tab?: TabType) => void;
  /**
   * The currently active tab in the list view
   */
  activeListTab?: TabType;
  /**
   * Method to update the active tab in the list view
   */
  setActiveListTab: (tab?: TabType) => void;
  /**
   * The last item that was opened in the list view
   */
  lastOpenedItem?: string;
  /**
   * Method to update the last item that was opened in the list view
   */
  setLastOpenedItem: (position?: any) => void;
  /**
   * The pagination state for the list view
   */
  pagination?: NestedPaginationState[];
  /**
   * Method to update the pagination state for the list view
   */
  setPagination: (pagination?: NestedPaginationState[]) => void;
  /**
   * The keyword search term for the list view
   */
  keyword?: string;
  /**
   * Method to update the keyword search term for the list view
   */
  setKeyword: (keyword?: string) => void;
}

/**
 * The context class used and referenced by for nested view rendering
 */
export const NestedViewContext = createContext<NestedViewContextInterface | undefined>(undefined);
