import { useCallback, useEffect, useState } from 'react';

import MultiLineText from 'components/core/typography/MultiLineText';
import { StructuredDialog } from 'components/ui/dialogs/StructuredDialog';
import ErrorIcon from 'components/ui/icons/ErrorIcon';
import { useUser } from 'hooks/contexts/useUser';
import { RED_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const ImpersonationError = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const { impersonationError, clearImpersonationError } = useUser();

  useEffect(() => {
    setOpen(impersonationError !== undefined);
  }, [impersonationError]);

  const onClose = useCallback(() => {
    setOpen(false);
    clearImpersonationError();
  }, [clearImpersonationError]);

  return (
    <StructuredDialog
      header={translate.t('cannot_login_as_user')}
      icon={<ErrorIcon color={RED_500} height={72} width={72} />}
      isOpen={isOpen}
      onClose={onClose}
    >
      <MultiLineText>{impersonationError}</MultiLineText>
    </StructuredDialog>
  );
};

export default ImpersonationError;
