import { memo, useCallback, useMemo } from 'react';

import { cloneDeep, get } from 'lodash-es';
import invariant from 'tiny-invariant';

import { RooftopFinancePricingBuilderSteps } from 'components/sections/createModify/rooftopPricingSegments/financePricing/steps/interfaces';
import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import ActionableTitle from 'components/ui/details/ActionableTitle';
import EditIcon from 'components/ui/icons/EditIcon';
import { Section } from 'components/ui/layouts/CardLayout';
import PercentageListItem from 'components/ui/lists/PercentageListItem';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import {
  RooftopFinancePricingSegment,
  RooftopFinancePricingSegmentSettings,
  RooftopPricingSegment,
} from 'enums/columns/rooftopPricingSegments';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useUser } from 'hooks/contexts/useUser';
import useEditField from 'hooks/useEditField';
import type { RooftopRetailFinancePricingDetailQuery } from 'store/api/graph/interfaces/types';
import { PricingSource } from 'store/api/graph/interfaces/types';
import { BODY_TEXT } from 'styles/color';
import { getPricingSegmentBuilderFromPaymentOption } from 'utils/formatting/pricingUtils';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const { t } = translate;

const { CONFIGURATION_PRICING_SOURCE_NAME, CONFIGURATION_PRICING_SOURCE, CONFIGURATION_INCLUDE_AFTER_TAX_REBATES } =
  RooftopPricingSegment;

const {
  CONFIGURATION_AVAILABLE_TERMS,
  CONFIGURATION_DEFAULT_TERM,
  CONFIGURATION_INTEREST_RATE_TYPE_NAME,
  CONFIGURATION_DEFAULT_TERM_TYPE_NAME,
} = RooftopFinancePricingSegment;

const { BOLD } = ItemGridRowDisplayType;

export const FinanceSegmentConfigurationTab = memo<ItemTabProps<RooftopRetailFinancePricingDetailQuery['item']>>(
  ({ item, metadata }) => {
    invariant(item.configuration.__typename === 'FinanceRetailPricingConfiguration');

    const { builderConfig } = useBuilderConfig();
    const { hasPermissions } = useUser();

    const isCustomSource = item?.configuration?.pricingSource === PricingSource.CUSTOM;

    const gridConfig: ItemGridRowProps[] = useMemo(
      () => [
        {
          fields: [
            CONFIGURATION_PRICING_SOURCE_NAME,
            !isCustomSource && CONFIGURATION_INCLUDE_AFTER_TAX_REBATES,
          ].filter(Boolean),
          displayType: [BOLD],
          canEdit: true,
        },
        { fields: [CONFIGURATION_AVAILABLE_TERMS], displayType: [BOLD], canEdit: true },
        { fields: [CONFIGURATION_DEFAULT_TERM], displayType: [BOLD], canEdit: true },
        { fields: [CONFIGURATION_DEFAULT_TERM_TYPE_NAME], displayType: [BOLD], canEdit: true },
        { fields: [CONFIGURATION_INTEREST_RATE_TYPE_NAME], displayType: [BOLD], canEdit: true },
      ],
      [isCustomSource]
    );

    const builderType = useMemo(
      () => getPricingSegmentBuilderFromPaymentOption(item.paymentOption, { isModify: true }),
      [item]
    );
    const isAllowedToEdit = useMemo(
      () => !!builderType?.type && hasPermissions(builderConfig[builderType.type].requiredPermissions),
      [builderType.type, hasPermissions, builderConfig]
    );

    const activeStep = builderConfig[builderType.type].steps.find(
      step => step.id === RooftopFinancePricingBuilderSteps.RATES
    );
    const onEdit = useEditField({
      tierData: {
        tierId: CreateModifyTiers.TIER_0,
        title: `${translate.t('pricing_segment_one')} (${item?.paymentOptionName})`,
        itemId: item?.id,
        activeStep,
        ...builderType,
      },
    });

    const onEditCallback = useCallback(field => onEdit(field, undefined, cloneDeep(item)), [onEdit, item]);

    return (
      <>
        {gridConfig.map(config => {
          const fields = config.fields.filter(field => {
            if (
              [CONFIGURATION_INTEREST_RATE_TYPE_NAME, CONFIGURATION_DEFAULT_TERM_TYPE_NAME].includes(
                field as RooftopFinancePricingSegment
              )
            ) {
              return get(item, CONFIGURATION_PRICING_SOURCE) !== PricingSource.CUSTOM;
            } else if (
              [CONFIGURATION_AVAILABLE_TERMS, CONFIGURATION_DEFAULT_TERM].includes(
                field as RooftopFinancePricingSegment
              )
            ) {
              return get(item, CONFIGURATION_PRICING_SOURCE) === PricingSource.CUSTOM;
            } else {
              return true;
            }
          });

          // If all the fields have been hidden, then just skip this grid section
          if (fields.length === 0) {
            return null;
          }
          return (
            <ItemGridRowSection
              config={{ ...config, fields }}
              item={item}
              key={formatItemGridRowSectionKey(fields)}
              metadata={metadata}
              onEdit={authorizedCallback({
                cb: onEditCallback,
                isAuth: !!onEdit,
              })}
              settings={RooftopFinancePricingSegmentSettings}
            />
          );
        })}
        {item.configuration.__typename === 'FinanceRetailPricingConfiguration' &&
          item.configuration.interestRates?.map(interestRate => (
            <Section key={`rate-section-${interestRate.term}`}>
              <ActionableTitle
                icon={<EditIcon />}
                label={`${interestRate.term} ${t('month_term')}`}
                onEdit={authorizedCallback({ cb: () => onEditCallback(null), isAuth: isAllowedToEdit })}
              />
              <PercentageListItem
                colour={BODY_TEXT}
                decimals={2}
                label={t('interest_rate')}
                percent={interestRate.interestRate}
              />
            </Section>
          ))}
      </>
    );
  }
);
