import { memo } from 'react';

import PrimaryText from 'components/core/typography/PrimaryText';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import Badge, { BadgeColors, Badges } from 'components/ui/shared/Badge';
import type { RooftopIntegrationResponseType } from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';

interface Props extends RooftopIntegrationResponseType, ListItemProps {}

// TODO: Add storybook entry
const RooftopIntegrationListItem = ({ active, primary, runOnce, integrationName, suffixIcon, listItemType }: Props) => {
  const { t } = translate;
  const activeLabel = t(active ? 'active' : 'inactive');

  return (
    <ListItem listItemType={listItemType} suffixIcon={suffixIcon}>
      <ListItemDetails>
        <TextRow>
          <PrimaryText>{integrationName.value}</PrimaryText>
        </TextRow>
        <TextRow>
          <Badges>
            <Badge
              color={active ? BadgeColors.POSITIVE : BadgeColors.NEGATIVE}
              title={`${t('status')}: ${activeLabel}`}
            >
              {activeLabel}
            </Badge>
            {!!primary && <Badge title={t('primary')}>{t('primary')}</Badge>}
            {!!runOnce && <Badge title={t('run_once')}>{t('run_once')}</Badge>}
          </Badges>
        </TextRow>
      </ListItemDetails>
    </ListItem>
  );
};

export default memo(RooftopIntegrationListItem);
