import { memo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { RooftopContact, RooftopContactSettings } from 'enums/columns/rooftopContact';
import { FeatureBundleSet } from 'enums/featureBundle';
import type { Rooftop } from 'store/api/graph/interfaces/types';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const { TYPE_NAME, FIRST_NAME, LAST_NAME, COMPANY, PHONE_NUMBER, EMAIL, DEFAULT_APPRAISER } = RooftopContact;

const { BOLD } = ItemGridRowDisplayType;

/**
 * Note: This grid is always used in a nested view, so will always see one field per row.
 */

const getGridConfigurations = (isTradeInEnabled): ItemGridRowProps[] => {
  const gridConfigRowOne: ItemGridRowProps = {
    fields: [TYPE_NAME],
    displayType: [BOLD],
    canEdit: false,
  };

  const gridConfigRowTwo: ItemGridRowProps = {
    fields: [FIRST_NAME, LAST_NAME, COMPANY, PHONE_NUMBER, EMAIL],
    displayType: [BOLD],
    canEdit: true,
  };

  if (isTradeInEnabled) {
    gridConfigRowTwo.fields.push(DEFAULT_APPRAISER);
  }

  return [gridConfigRowOne, gridConfigRowTwo];
};

export const RooftopContactDetailsTab = memo<ItemTabProps>(({ item: contact, onEdit }) => {
  const flags = useFlags();

  const isTradeInEnabled = isFeatureEnabledForRooftop({
    rooftop: contact.rooftop as Pick<Rooftop, 'bundle' | 'id'>,
    feature: FeatureBundleSet.TRADE_IN,
    featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
  });

  return contact ? (
    <>
      {getGridConfigurations(isTradeInEnabled).map(config => (
        <ItemGridRowSection
          config={config}
          item={contact}
          key={formatItemGridRowSectionKey(config.fields)}
          onEdit={authorizedCallback({
            cb: (field?: string) => onEdit(field, translate.t('contacts')),
            isAuth: !!onEdit,
          })}
          settings={RooftopContactSettings}
        />
      ))}
    </>
  ) : null;
});
