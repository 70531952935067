import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import LocationListItem from 'components/ui/lists/LocationListItem';
import { RooftopDepartment } from 'enums/columns/rooftopDepartment';
import { StepFieldType } from 'enums/stepFieldType';
import { RooftopDepartmentModifyParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

import { RooftopDepartmentsDetailsBuilderFields } from './interfaces';

const DetailFields = defineDetailsFields({
  [RooftopDepartmentsDetailsBuilderFields.TYPE]: {
    label: 'type',
    queryAlias: [RooftopDepartment.TYPE_NAME],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'metadata.mutation.rooftopDepartment.type',
    clear: { field: RooftopDepartmentModifyParameter._type },
  },
  [RooftopDepartmentsDetailsBuilderFields.EMAIL]: {
    label: 'email_one',
    placeholder: 'email_one',
    queryAlias: [RooftopDepartment.EMAIL],
    clear: { field: RooftopDepartmentModifyParameter._email },
  },
  [RooftopDepartmentsDetailsBuilderFields.PHONE_NUMBER]: {
    label: 'phone_number_one',
    placeholder: 'phone_number_one',
    queryAlias: [RooftopDepartment.PHONE_NUMBER],
    clear: { field: RooftopDepartmentModifyParameter._phoneNumber },
    hasSeparator: true,
  },
  [RooftopDepartmentsDetailsBuilderFields.LOCATION]: {
    label: 'location',
    placeholder: 'location',
    groupType: StepFieldType.RENDER_OBJECT,
    subStep: [SubStepType.CUSTOM],
    renderElement: LocationListItem,
    clear: { field: RooftopDepartmentModifyParameter._location },
    hasSeparator: true,
  },
});

export default DetailFields;
