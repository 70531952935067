import { get, isEqual } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { UNLIMITED_INVENTORY_LIMIT_VALUE } from 'enums/columns/rooftop';
import type {
  FeatureBundleInput,
  FeaturePackage,
  PaymentOption,
  RooftopBuilderMetaQuery,
  RooftopCreateMutationVariables,
  RooftopDetailsContainerQuery,
  RooftopModifyMutationVariables,
} from 'store/api/graph/interfaces/types';
import {
  AppointmentFeatureInputParameter,
  FeatureSetInputParameter,
  GuaranteedOfferPackage,
  LeadFeatureInputParameter,
  RetailFeatureInputParameter,
  RetailPortalFeatureInputParameter,
  TaskFeatureInputParameter,
  TradeInFeatureInputParameter,
} from 'store/api/graph/interfaces/types';
import type { SelectOption } from 'store/api/graph/responses/responseTypes';
import {
  defineFieldValues,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

import { RooftopPackageBuilderFields } from './interfaces';

const { t } = translate;

type RooftopDetails = RooftopDetailsContainerQuery['item'];

class PackageStep extends StepComponentCore<
  RooftopDetails,
  RooftopBuilderMetaQuery,
  RooftopCreateMutationVariables | RooftopModifyMutationVariables
> {
  private readonly globalTypes = [{ type: StepFieldDisplayType.OMITTED, active: true }];

  constructor(
    props: StepComponentProps<
      RooftopDetails,
      RooftopBuilderMetaQuery,
      RooftopCreateMutationVariables | RooftopModifyMutationVariables
    >
  ) {
    super(props);
    const {
      tier: { data, formData, isCreating },
    } = props;

    const packageId = isCreating ? formData?.packageId : data?.bundle?.package.id;
    this.setDefaultFieldsByPackageId.bind(this);
    this.setDefaultFieldsByPackageId(packageId, !!packageId);
  }

  private getDefaultAddOnValueByPackageId(selectedPackageId: string | undefined, field: RooftopPackageBuilderFields) {
    if (!selectedPackageId) {
      return null;
    }

    const {
      tier: {
        metadata: { featurePackages },
      },
    } = this.props;

    const featurePackage = this.getFeaturePackage(featurePackages, selectedPackageId);

    return {
      [RooftopPackageBuilderFields.APPOINTMENT_ENABLED]: !!featurePackage?.features.appointment.enabled,
      [RooftopPackageBuilderFields.TASK_ENABLED]: !!featurePackage?.features.task.enabled,
      [RooftopPackageBuilderFields.LEAD_ENABLED]: !!featurePackage?.features.lead.enabled,
      [RooftopPackageBuilderFields.LEAD_CONVERSATIONS]: !!featurePackage?.features.lead.conversations,
      [RooftopPackageBuilderFields.LEAD_SMS_MAILBOX_LIMIT]: featurePackage?.features.lead.smsMailboxLimit,
      [RooftopPackageBuilderFields.LEAD_FORWARDING]: featurePackage?.features.lead.forwarding,
      [RooftopPackageBuilderFields.TRADE_IN_ENABLED]: !!featurePackage?.features.tradeIn.enabled,
      [RooftopPackageBuilderFields.GUARANTEED_OFFER_PACKAGE]: featurePackage?.features.tradeIn.guaranteedOfferPackage,
      [RooftopPackageBuilderFields.RETAIL_ENABLED]: !!featurePackage?.features.retail.enabled,
      [RooftopPackageBuilderFields.RETAIL_BUILD_AND_PRICE]: !!featurePackage?.features.retail.buildAndPrice,
      [RooftopPackageBuilderFields.RETAIL_SHOWROOM]: !!featurePackage?.features.retail.showroom,
      [RooftopPackageBuilderFields.RETAIL_ITEM_LIMIT]: featurePackage?.features.retail.itemLimit,
      [RooftopPackageBuilderFields.RETAIL_PAYMENT_OPTION]: featurePackage?.features.retail.paymentOptions,
      [RooftopPackageBuilderFields.RETAIL_PREMIUM_EXPORT_INTEGRATION]:
        featurePackage?.features.retail.premiumExportIntegrations,
      [RooftopPackageBuilderFields.RETAIL_PORTAL_ENABLED]: !!featurePackage?.features.retailPortal.enabled,
      [RooftopPackageBuilderFields.OEM]: featurePackage?.features.oem,
    }[field];
  }

  /**
   * Get the default saved add on value. If we creating a new rooftop, then we'll need to check any saved
   * values found in previous stepFieldData. If we are modifying a rooftop, then we can see the previously
   * saved bundle data
   */
  private getDefaultSavedAddOnValue({
    stepField,
    dataField,
  }: {
    stepField: RooftopPackageBuilderFields;
    dataField: string;
  }) {
    const {
      tier: { data, isCreating, stepFieldData },
    } = this.props;

    return isCreating ? stepFieldData?.[stepField] : get(data?.bundle?.addOns, dataField);
  }

  /**
   * Takes any type and converts it to a `SelectOption` format.
   *
   * @example Useful for objects to `SelectOption` conversions.
   */
  private normalizeToSelectOption(selectedValue: any[] | null) {
    if (!selectedValue || selectedValue.length === 0) {
      return null;
    }

    return selectedValue.map(value => {
      if (typeof value === 'string') {
        return value;
      }

      return value?.id;
    });
  }

  /**
   * Set initial state of package fields.
   * Rule: Default package can only be extended, thus base featureSet should not be modifiable.
   *
   * All Toggles:
   *  - If enabled in base package, should be disabled.
   * Select Options:
   *  - If enabled in base package, should be disabled.
   * All nested fields:
   *  - If parent disabled, all nested fields should be disabled regardless.
   */
  private setDefaultFieldsByPackageId(id?: string, shouldApplyAddOns = false) {
    const {
      tier: { activeStep, data, metadata },
    } = this.props;

    const {
      featurePackages,
      integrations,
      metadata: {
        mutation: {
          rooftop: {
            bundle: {
              addOns: {
                retail: { paymentOptions },
                tradeIn: { guaranteedOfferPackage: guaranteedOfferVersionOptions },
              },
            },
          },
        },
      },
    } = metadata;

    const featurePackage = this.getFeaturePackage(featurePackages, id);

    const getCurrentAddOns = (args: { stepField: RooftopPackageBuilderFields; dataField: string }) =>
      shouldApplyAddOns ? this.getDefaultSavedAddOnValue(args) : null;

    const shouldHideField = !featurePackage;
    const isLeadEnabledByDefault = this.getDefaultAddOnValueByPackageId(id, RooftopPackageBuilderFields.LEAD_ENABLED);
    const isAppointmentEnabledByDefault = this.getDefaultAddOnValueByPackageId(
      id,
      RooftopPackageBuilderFields.APPOINTMENT_ENABLED
    );
    const isTaskEnabledByDefault = this.getDefaultAddOnValueByPackageId(id, RooftopPackageBuilderFields.TASK_ENABLED);
    const isTradeInEnabledByDefault = this.getDefaultAddOnValueByPackageId(
      id,
      RooftopPackageBuilderFields.TRADE_IN_ENABLED
    );
    const isRetailEnabledByDefault = this.getDefaultAddOnValueByPackageId(
      id,
      RooftopPackageBuilderFields.RETAIL_ENABLED
    );
    const isRetailBuildAndPriceEnabledByDefault = this.getDefaultAddOnValueByPackageId(
      id,
      RooftopPackageBuilderFields.RETAIL_BUILD_AND_PRICE
    );
    const isRetailShowroomEnabledByDefault = this.getDefaultAddOnValueByPackageId(
      id,
      RooftopPackageBuilderFields.RETAIL_SHOWROOM
    );
    const isLeadConversationEnabledByDefault = this.getDefaultAddOnValueByPackageId(
      id,
      RooftopPackageBuilderFields.LEAD_CONVERSATIONS
    );
    const isLeadForwardingEnabledByDefault = this.getDefaultAddOnValueByPackageId(
      id,
      RooftopPackageBuilderFields.LEAD_FORWARDING
    );
    const isUnlimitedInventoryEnabledByDefault =
      this.getDefaultAddOnValueByPackageId(id, RooftopPackageBuilderFields.RETAIL_ITEM_LIMIT) ===
      UNLIMITED_INVENTORY_LIMIT_VALUE;
    const isRetailPortalEnabledByDefault = this.getDefaultAddOnValueByPackageId(
      id,
      RooftopPackageBuilderFields.RETAIL_PORTAL_ENABLED
    );

    const itemLimit =
      getCurrentAddOns({
        stepField: RooftopPackageBuilderFields.RETAIL_ITEM_LIMIT,
        dataField: 'retail.itemLimit',
      }) || this.getDefaultAddOnValueByPackageId(id, RooftopPackageBuilderFields.RETAIL_ITEM_LIMIT);
    const smsMailboxLimit =
      getCurrentAddOns({
        stepField: RooftopPackageBuilderFields.LEAD_SMS_MAILBOX_LIMIT,
        dataField: 'lead.smsMailboxLimit',
      }) || this.getDefaultAddOnValueByPackageId(id, RooftopPackageBuilderFields.LEAD_SMS_MAILBOX_LIMIT);
    const defaultPaymentOptions =
      getCurrentAddOns({
        stepField: RooftopPackageBuilderFields.RETAIL_PAYMENT_OPTION,
        dataField: 'retail.paymentOptions',
      }) ||
      this.getDefaultAddOnValueByPackageId(id, RooftopPackageBuilderFields.RETAIL_PAYMENT_OPTION) ||
      [];

    // Need to check length for default options to populate as disabled
    const premiumExportAddOnOptions = getCurrentAddOns({
      stepField: RooftopPackageBuilderFields.RETAIL_PREMIUM_EXPORT_INTEGRATION,
      dataField: 'retail.premiumExportIntegrations',
    });
    const defaultPremiumExportOptions = premiumExportAddOnOptions?.length
      ? premiumExportAddOnOptions
      : this.getDefaultAddOnValueByPackageId(id, RooftopPackageBuilderFields.RETAIL_PREMIUM_EXPORT_INTEGRATION);

    const defaultGuaranteedOfferPackage = this.getDefaultAddOnValueByPackageId(
      id,
      RooftopPackageBuilderFields.GUARANTEED_OFFER_PACKAGE
    );

    const savedAddOnsLeads = getCurrentAddOns({
      stepField: RooftopPackageBuilderFields.LEAD_ENABLED,
      dataField: 'lead.enabled',
    });
    const savedAddOnsLeadConversations = getCurrentAddOns({
      stepField: RooftopPackageBuilderFields.LEAD_CONVERSATIONS,
      dataField: 'lead.conversations',
    });
    const savedAddOnsLeadForwarding = getCurrentAddOns({
      stepField: RooftopPackageBuilderFields.LEAD_FORWARDING,
      dataField: 'lead.forwarding',
    });
    const savedAddOnsAppointments = getCurrentAddOns({
      stepField: RooftopPackageBuilderFields.APPOINTMENT_ENABLED,
      dataField: 'appointment.enabled',
    });
    const savedAddOnsTasks = getCurrentAddOns({
      stepField: RooftopPackageBuilderFields.TASK_ENABLED,
      dataField: 'task.enabled',
    });
    const savedAddOnsTradeIn = getCurrentAddOns({
      stepField: RooftopPackageBuilderFields.TRADE_IN_ENABLED,
      dataField: 'tradeIn.enabled',
    });
    const savedAddOnsGuaranteedOffer = getCurrentAddOns({
      stepField: RooftopPackageBuilderFields.GUARANTEED_OFFER_PACKAGE,
      dataField: 'tradeIn.guaranteedOfferPackage',
    });
    const savedAddOnsRetail = getCurrentAddOns({
      stepField: RooftopPackageBuilderFields.RETAIL_ENABLED,
      dataField: 'retail.enabled',
    });
    const savedAddOnsRetailBuildAndPrice = getCurrentAddOns({
      stepField: RooftopPackageBuilderFields.RETAIL_BUILD_AND_PRICE,
      dataField: 'retail.buildAndPrice',
    });
    const savedAddOnsRetailShowroom = getCurrentAddOns({
      stepField: RooftopPackageBuilderFields.RETAIL_SHOWROOM,
      dataField: 'retail.showroom',
    });
    const savedAddOnsRetailPortal = getCurrentAddOns({
      stepField: RooftopPackageBuilderFields.RETAIL_PORTAL_ENABLED,
      dataField: 'retailPortal.enabled',
    });

    const defaultOEM =
      getCurrentAddOns({ stepField: RooftopPackageBuilderFields.OEM, dataField: 'oem' }) ||
      this.getDefaultAddOnValueByPackageId(id, RooftopPackageBuilderFields.OEM);

    const defaultFields: StepFields = {
      [RooftopPackageBuilderFields.PACKAGE]: {
        selectedValue: featurePackage,
      },
      [RooftopPackageBuilderFields.OEM]: {
        selectedValue: defaultOEM,
      },
      [RooftopPackageBuilderFields.LEAD_ENABLED]: this.getFieldSettings(
        isLeadEnabledByDefault,
        shouldHideField,
        savedAddOnsLeads || isLeadEnabledByDefault
      ),
      [RooftopPackageBuilderFields.LEAD_SMS_MAILBOX_LIMIT]: this.getFieldSettings(
        !isLeadEnabledByDefault && !savedAddOnsLeads,
        shouldHideField,
        smsMailboxLimit || null
      ),
      [RooftopPackageBuilderFields.LEAD_CONVERSATIONS]: this.getFieldSettings(
        (!isLeadEnabledByDefault && !savedAddOnsLeads) || isLeadConversationEnabledByDefault,
        shouldHideField,
        !!savedAddOnsLeadConversations || isLeadConversationEnabledByDefault
      ),
      [RooftopPackageBuilderFields.LEAD_FORWARDING]: this.getFieldSettings(
        (!isLeadEnabledByDefault && !savedAddOnsLeads) || isLeadForwardingEnabledByDefault,
        shouldHideField,
        !!savedAddOnsLeadForwarding || isLeadForwardingEnabledByDefault
      ),

      /**
       * Appointments
       */
      [RooftopPackageBuilderFields.APPOINTMENT_ENABLED]: this.getFieldSettings(
        isAppointmentEnabledByDefault,
        shouldHideField,
        !!savedAddOnsAppointments || isAppointmentEnabledByDefault
      ),

      /**
       * Task
       */
      [RooftopPackageBuilderFields.TASK_ENABLED]: this.getFieldSettings(
        isTaskEnabledByDefault,
        shouldHideField,
        !!savedAddOnsTasks || isTaskEnabledByDefault
      ),

      /**
       * Trade In
       */
      [RooftopPackageBuilderFields.TRADE_IN_ENABLED]: this.getFieldSettings(
        isTradeInEnabledByDefault,
        shouldHideField,
        !!savedAddOnsTradeIn || isTradeInEnabledByDefault
      ),
      [RooftopPackageBuilderFields.GUARANTEED_OFFER_PACKAGE]: {
        ...this.getFieldSettings(
          !isTradeInEnabledByDefault && !savedAddOnsTradeIn,
          shouldHideField,
          savedAddOnsGuaranteedOffer || defaultGuaranteedOfferPackage
        ),
        options: guaranteedOfferVersionOptions
          .filter(({ id }) =>
            [GuaranteedOfferPackage.LEGACY, GuaranteedOfferPackage.ESSENTIAL, GuaranteedOfferPackage.INSIGHT].includes(
              id as GuaranteedOfferPackage
            )
          )
          .map(option => ({
            ...option,
            name: `${option.id === GuaranteedOfferPackage.LEGACY ? t('guaranteed_trade') : option.name} ${
              option.id === defaultGuaranteedOfferPackage ? `(${t('default')})` : ''
            }`.trim(),
            disabled: option.id === defaultGuaranteedOfferPackage,
          })),
      },

      /**
       * Retail
       */
      [RooftopPackageBuilderFields.RETAIL_ENABLED]: this.getFieldSettings(
        isRetailEnabledByDefault,
        shouldHideField,
        !!savedAddOnsRetail || isRetailEnabledByDefault
      ),
      [RooftopPackageBuilderFields.RETAIL_BUILD_AND_PRICE]: this.getFieldSettings(
        (!isRetailEnabledByDefault && !savedAddOnsRetail) || isRetailBuildAndPriceEnabledByDefault,
        shouldHideField,
        !!savedAddOnsRetailBuildAndPrice || isRetailBuildAndPriceEnabledByDefault
      ),
      [RooftopPackageBuilderFields.RETAIL_SHOWROOM]: this.getFieldSettings(
        (!isRetailEnabledByDefault && !savedAddOnsRetail) || isRetailShowroomEnabledByDefault,
        shouldHideField,
        !!savedAddOnsRetailShowroom || isRetailShowroomEnabledByDefault
      ),
      [RooftopPackageBuilderFields.RETAIL_PAYMENT_OPTION]: {
        ...this.getFieldSettings(
          !isRetailEnabledByDefault && !savedAddOnsRetail,
          shouldHideField,
          defaultPaymentOptions
        ),
        options: paymentOptions?.map(option => ({
          ...option,
          disabled: featurePackage?.features.retail.paymentOptions?.some(id => id === option.id),
        })),
      },
      [RooftopPackageBuilderFields.RETAIL_PREMIUM_EXPORT_INTEGRATION]: {
        ...this.getFieldSettings(
          !isRetailEnabledByDefault && !savedAddOnsRetail,
          shouldHideField,
          defaultPremiumExportOptions
        ),
        options: integrations?.map(option => ({
          ...(option as SelectOption),
          disabled: featurePackage?.features.retail.premiumExportIntegrations?.some(({ id }) => id === option.id),
        })),
      },
      [RooftopPackageBuilderFields.RETAIL_UNLIMITED_INVENTORY]: this.getFieldSettings(
        (!isRetailEnabledByDefault && !savedAddOnsRetail) || isUnlimitedInventoryEnabledByDefault,
        shouldHideField,
        itemLimit === UNLIMITED_INVENTORY_LIMIT_VALUE
      ),
      [RooftopPackageBuilderFields.RETAIL_ITEM_LIMIT]: this.getFieldSettings(
        (!isRetailEnabledByDefault && !savedAddOnsRetail) || itemLimit === UNLIMITED_INVENTORY_LIMIT_VALUE,
        shouldHideField,
        !!itemLimit && itemLimit !== UNLIMITED_INVENTORY_LIMIT_VALUE ? itemLimit : null
      ),

      /**
       * Retail Portal
       *
       * This field should always be enabled even though it is set to `true` in the package. So this is a one-off
       * as compared to the other fields
       */
      [RooftopPackageBuilderFields.RETAIL_PORTAL_ENABLED]: this.getFieldSettings(
        false,
        shouldHideField,
        savedAddOnsRetailPortal ?? isRetailPortalEnabledByDefault
      ),
    };

    this.fields = objectToStepFieldArray(activeStep?.fields, defaultFields);
    this.fields = defineFieldValues(this.fields, data, metadata);

    return defaultFields;
  }

  onFieldSelection(stepField: StepField<RooftopDetails, RooftopBuilderMetaQuery>, value: unknown): void {
    super.onFieldSelection(stepField, value);

    if (stepField.queryVar === RooftopPackageBuilderFields.GUARANTEED_OFFER_PACKAGE) {
      const currentSelectedFeaturePackage = getStepField(
        RooftopPackageBuilderFields.PACKAGE,
        this.fields
      ).selectedValue;
      const defaultGuarantedOfferPackage = this.getDefaultAddOnValueByPackageId(
        currentSelectedFeaturePackage?.id,
        RooftopPackageBuilderFields.GUARANTEED_OFFER_PACKAGE
      );
      const currentGuaranteedOfferPackage = stepField.selectedValue?.id || stepField.selectedValue;
      const selectedGuaranteedOfferPackage = value as SelectOption | null;
      const guaranteedOfferPackageWasDeselected = currentGuaranteedOfferPackage === selectedGuaranteedOfferPackage?.id;

      if (
        !!defaultGuarantedOfferPackage &&
        currentGuaranteedOfferPackage !== defaultGuarantedOfferPackage &&
        guaranteedOfferPackageWasDeselected
      ) {
        this.setField(RooftopPackageBuilderFields.GUARANTEED_OFFER_PACKAGE, {
          displayType: stepField.displayType,
          selectedValue: defaultGuarantedOfferPackage,
        });
      }
    }

    switch (stepField.queryVar) {
      case RooftopPackageBuilderFields.PACKAGE: {
        const currentPackage = stepField.selectedValue as FeaturePackage;
        const selectedPackage = value as FeaturePackage;
        const packageWasDeselected = currentPackage?.id === selectedPackage.id;

        for (const fieldId of Object.values(RooftopPackageBuilderFields)) {
          if (fieldId === RooftopPackageBuilderFields.PACKAGE) {
            continue;
          }

          this.hideField(fieldId, packageWasDeselected);
        }

        // Check the package's features to ensure that default-enabled features cannot be disabled
        if (!packageWasDeselected) {
          // Get default values for the new package and set the fields
          this.setDefaultFieldsByPackageId(selectedPackage.id);

          // Clear errors when we switch packages
          for (const stepField of this.fields) {
            super.clearFieldError(stepField);
          }
        }
        break;
      }

      case RooftopPackageBuilderFields.RETAIL_PAYMENT_OPTION: {
        const selectedValue = value as (string | SelectOption)[];

        this.setField(RooftopPackageBuilderFields.RETAIL_PAYMENT_OPTION, {
          // If there are no payment options selected, then the field should be set to null instead of an empty array []
          selectedValue: selectedValue.length > 0 ? this.normalizeToSelectOption(selectedValue) : null,
        });
        break;
      }

      case RooftopPackageBuilderFields.RETAIL_PREMIUM_EXPORT_INTEGRATION: {
        const selectedValue = value as (string | SelectOption)[];

        this.setField(RooftopPackageBuilderFields.RETAIL_PREMIUM_EXPORT_INTEGRATION, {
          // If there are no payment options selected, then the field should be set to null instead of an empty array []
          selectedValue: selectedValue.length > 0 ? this.normalizeToSelectOption(selectedValue) : null,
        });
        break;
      }
    }
  }

  onFieldChange(
    stepField: StepField<RooftopDetails, RooftopBuilderMetaQuery>,
    e: Record<'currentTarget', { value: any }>
  ): void {
    super.onFieldChange(stepField, e);

    const {
      tier: { metadata },
    } = this.props;
    const currentSelectedFeaturePackage = getStepField(RooftopPackageBuilderFields.PACKAGE, this.fields).selectedValue;

    const featurePackage = this.getFeaturePackage(metadata.featurePackages, currentSelectedFeaturePackage?.id);

    if (stepField.queryVar === RooftopPackageBuilderFields.TRADE_IN_ENABLED) {
      const tradeInEnabled = typeof stepField.selectedValue === 'boolean' && stepField.selectedValue;

      this.disableField(RooftopPackageBuilderFields.GUARANTEED_OFFER_PACKAGE, !tradeInEnabled, null);
    }

    if (stepField.queryVar === RooftopPackageBuilderFields.LEAD_ENABLED) {
      const leadEnabled = typeof stepField.selectedValue === 'boolean' && stepField.selectedValue;

      this.disableField(
        RooftopPackageBuilderFields.LEAD_SMS_MAILBOX_LIMIT,
        !leadEnabled,
        leadEnabled ? featurePackage?.features.lead.smsMailboxLimit : null
      );

      this.disableField(
        RooftopPackageBuilderFields.LEAD_CONVERSATIONS,
        !leadEnabled,
        leadEnabled ? !!featurePackage?.features.lead.conversations : false
      );

      this.disableField(
        RooftopPackageBuilderFields.LEAD_FORWARDING,
        !leadEnabled,
        leadEnabled ? !!featurePackage?.features.lead.forwarding : false
      );
    }

    // Enable/Disable all 'nested' fields under Retail Inventory when depending on its value
    else if (stepField.queryVar === RooftopPackageBuilderFields.RETAIL_ENABLED) {
      const retailEnabled = typeof stepField.selectedValue === 'boolean' && stepField.selectedValue;

      this.disableField(
        RooftopPackageBuilderFields.RETAIL_SHOWROOM,
        !retailEnabled,
        retailEnabled ? !!featurePackage?.features.retail.showroom : false
      );

      this.disableField(
        RooftopPackageBuilderFields.RETAIL_PAYMENT_OPTION,
        !retailEnabled,
        retailEnabled ? featurePackage?.features.retail.paymentOptions : null
      );

      this.disableField(
        RooftopPackageBuilderFields.RETAIL_PREMIUM_EXPORT_INTEGRATION,
        !retailEnabled,
        retailEnabled ? featurePackage?.features.retail.premiumExportIntegrations : null
      );

      this.disableField(
        RooftopPackageBuilderFields.RETAIL_BUILD_AND_PRICE,
        !retailEnabled,
        retailEnabled ? !!featurePackage?.features.retail.buildAndPrice : false
      );

      const itemLimit = featurePackage?.features.retail.itemLimit;
      this.disableField(
        RooftopPackageBuilderFields.RETAIL_UNLIMITED_INVENTORY,
        !retailEnabled,
        retailEnabled ? itemLimit === UNLIMITED_INVENTORY_LIMIT_VALUE : false
      );

      this.disableField(
        RooftopPackageBuilderFields.RETAIL_ITEM_LIMIT,
        !retailEnabled || itemLimit === UNLIMITED_INVENTORY_LIMIT_VALUE,
        !retailEnabled || itemLimit === UNLIMITED_INVENTORY_LIMIT_VALUE ? null : itemLimit
      );
    }

    // Toggle the Retail Inventory Limit field depending on the Unlimited field's value
    if (stepField.queryVar === RooftopPackageBuilderFields.RETAIL_UNLIMITED_INVENTORY) {
      const unlimitedEntryEnabled = typeof stepField.selectedValue === 'boolean' && stepField.selectedValue;
      const itemLimit = featurePackage?.features.retail.itemLimit;

      this.disableField(
        RooftopPackageBuilderFields.RETAIL_ITEM_LIMIT,
        unlimitedEntryEnabled,
        unlimitedEntryEnabled ? null : itemLimit === UNLIMITED_INVENTORY_LIMIT_VALUE ? 0 : itemLimit
      );
    }
  }

  private getFeaturePackage = (
    featurePackages: RooftopBuilderMetaQuery['featurePackages'],
    packageId: string | undefined
  ) => featurePackages.find(featurePackage => featurePackage.id === packageId);

  private getFieldSettings = <TDefaultValue,>(
    shouldDisableField: boolean,
    shouldHideField: boolean,
    defaultValue: TDefaultValue
  ): Partial<StepField<RooftopDetails, RooftopBuilderMetaQuery>> => ({
    displayType: setDisplayTypes([
      ...this.globalTypes,
      { type: StepFieldDisplayType.HIDDEN, active: shouldHideField },
      { type: StepFieldDisplayType.DISABLED, active: shouldDisableField },
    ]),
    selectedValue: defaultValue,
  });

  private setField = (
    key: string,
    { displayType, selectedValue }: Partial<StepField<RooftopDetails, RooftopBuilderMetaQuery>>
  ) => {
    // Get stepfield based on key
    const stepField = getStepField(key, this.fields);

    stepField.displayType = displayType;
    stepField.selectedValue = selectedValue;

    return stepField;
  };

  private hideField(key: RooftopPackageBuilderFields, shouldHide: boolean) {
    return this.setField(key, {
      displayType: setDisplayTypes([...this.globalTypes, { type: StepFieldDisplayType.HIDDEN, active: shouldHide }]),
    });
  }

  private disableField<TValue>(key: RooftopPackageBuilderFields, shouldDisable: boolean, selectedValue?: TValue) {
    return this.setField(key, {
      // Disable step field if root is not enabled and vice versa
      displayType: setDisplayTypes([
        ...this.globalTypes,
        { type: StepFieldDisplayType.DISABLED, active: shouldDisable },
      ]),
      selectedValue,
    });
  }

  /**
   * Returning modifiedValue if there are any changes, if not it will return null
   */
  getAddOnValue<TReturnValue = any>(
    key: RooftopPackageBuilderFields,
    currentValue?: TReturnValue | null,
    modifiedValueOverride?: TReturnValue | null
  ) {
    const modifiedValue =
      modifiedValueOverride ??
      getStepField<TReturnValue, RooftopDetails, RooftopBuilderMetaQuery>(key, this.fields)?.selectedValue;

    // Equality check for non-objectlike data
    if (isEqual(currentValue, modifiedValue)) {
      return null;
    }

    // Check if the value is an array
    if (Array.isArray(modifiedValue)) {
      if (modifiedValue.length === 0) {
        return null;
      }

      if (Array.isArray(currentValue)) {
        if (modifiedValue.length !== currentValue.length) {
          return modifiedValue;
        }

        /**
         * Compare the arrays to see if there are any changes
         *
         * If objectlike, check `id` property otherwise check raw value.
         */
        return modifiedValue.every((value, idx) => value?.id || value === currentValue[idx]?.id || currentValue[idx])
          ? null
          : modifiedValue;
      }
    }

    if (typeof modifiedValue === 'boolean') {
      return modifiedValue ?? null;
    }

    if (typeof modifiedValue === 'number') {
      return modifiedValue ?? null;
    }

    if (Array.isArray(modifiedValue)) {
      return modifiedValue.length === 0 ? null : modifiedValue;
    }

    return modifiedValue || null;
  }

  /**
   * Get overridden addOns for mutation.
   *
   * Note: Base package values must also be included in payload, in addition to the new addOns.
   */
  getAddOnVariables(): RooftopCreateMutationVariables | RooftopModifyMutationVariables {
    const {
      tier: { isCreating },
    } = this.props;

    const selectedPackage = getStepField(RooftopPackageBuilderFields.PACKAGE, this.fields)?.selectedValue?.id;
    const selectedOem = getStepField(RooftopPackageBuilderFields.OEM, this.fields)?.selectedValue;

    // AppointmentFeatureInput
    const appointmentEnabled = this.getAddOnValue<boolean | null>(
      RooftopPackageBuilderFields.APPOINTMENT_ENABLED,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.APPOINTMENT_ENABLED)
    );

    // TradeInFeatureInput
    const tradeInEnabled = this.getAddOnValue<boolean | null>(
      RooftopPackageBuilderFields.TRADE_IN_ENABLED,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.TRADE_IN_ENABLED)
    );
    let guaranteedOfferPackage = this.getAddOnValue<GuaranteedOfferPackage | null>(
      RooftopPackageBuilderFields.GUARANTEED_OFFER_PACKAGE,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.GUARANTEED_OFFER_PACKAGE)
    );
    // @ts-expect-error `GuaranteedOfferPackage` is an `enum` but we are expecting an object instead?
    guaranteedOfferPackage = guaranteedOfferPackage?.id || guaranteedOfferPackage;

    // TaskFeatureInput
    const taskEnabled = this.getAddOnValue<boolean | null>(
      RooftopPackageBuilderFields.TASK_ENABLED,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.TASK_ENABLED)
    );

    // LeadFeatureInput
    const leadEnabled = this.getAddOnValue<boolean | null>(
      RooftopPackageBuilderFields.LEAD_ENABLED,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.LEAD_ENABLED)
    );
    const leadConversations = this.getAddOnValue<boolean | null>(
      RooftopPackageBuilderFields.LEAD_CONVERSATIONS,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.LEAD_CONVERSATIONS)
    );
    const leadForwarding = this.getAddOnValue<boolean | null>(
      RooftopPackageBuilderFields.LEAD_FORWARDING,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.LEAD_FORWARDING)
    );
    const leadSmsMailboxLimit = this.getAddOnValue<number>(
      RooftopPackageBuilderFields.LEAD_SMS_MAILBOX_LIMIT,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.LEAD_SMS_MAILBOX_LIMIT)
    );

    // RetailFeatureInput
    const retailEnabled = this.getAddOnValue<boolean | null>(
      RooftopPackageBuilderFields.RETAIL_ENABLED,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.RETAIL_ENABLED)
    );
    const retailBuildAndPrice = this.getAddOnValue<boolean | null>(
      RooftopPackageBuilderFields.RETAIL_BUILD_AND_PRICE,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.RETAIL_BUILD_AND_PRICE)
    );
    const retailShowroom = this.getAddOnValue<boolean | null>(
      RooftopPackageBuilderFields.RETAIL_SHOWROOM,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.RETAIL_SHOWROOM)
    );
    const retailPaymentOptions = this.getAddOnValue<PaymentOption[]>(
      RooftopPackageBuilderFields.RETAIL_PAYMENT_OPTION,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.RETAIL_PAYMENT_OPTION) || []
    );

    // RetailPortalFeatureInput
    const retailPortalEnabled = this.getAddOnValue<boolean | null>(
      RooftopPackageBuilderFields.RETAIL_PORTAL_ENABLED,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.RETAIL_PORTAL_ENABLED)
    );

    /**
     * Special case: Api response is an array of objects, however saving needs an array of IDs
     */
    let retailPremiumExportIntegrations = this.getAddOnValue<string[]>(
      RooftopPackageBuilderFields.RETAIL_PREMIUM_EXPORT_INTEGRATION,
      this.getDefaultAddOnValueByPackageId(
        selectedPackage,
        RooftopPackageBuilderFields.RETAIL_PREMIUM_EXPORT_INTEGRATION
      ) || []
    );
    retailPremiumExportIntegrations = this.normalizeToSelectOption(retailPremiumExportIntegrations);

    /**
     * Special case: Fake `retailUnlimitedInventory` field used to populate real api field `retailItemLimit`
     */
    const retailUnlimitedInventory = this.getAddOnValue<boolean | null>(
      RooftopPackageBuilderFields.RETAIL_UNLIMITED_INVENTORY,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.RETAIL_ITEM_LIMIT)
    );
    const retailItemLimit = this.getAddOnValue<number>(
      RooftopPackageBuilderFields.RETAIL_ITEM_LIMIT,
      this.getDefaultAddOnValueByPackageId(selectedPackage, RooftopPackageBuilderFields.RETAIL_ITEM_LIMIT),
      retailUnlimitedInventory ? UNLIMITED_INVENTORY_LIMIT_VALUE : null
    );

    const variables: FeatureBundleInput = {
      packageId: selectedPackage,
      addOns: {
        appointment: { enabled: appointmentEnabled },
        retail: {
          enabled: retailEnabled,
          buildAndPrice: retailBuildAndPrice,
          showroom: retailShowroom,
          paymentOptions: retailPaymentOptions,
          premiumExportIntegrationIds: retailPremiumExportIntegrations,
          itemLimit: retailItemLimit,
        },
        retailPortal: {
          enabled: retailPortalEnabled,
        },
        lead: {
          enabled: leadEnabled,
          conversations: leadConversations,
          forwarding: leadForwarding,
          smsMailboxLimit: leadSmsMailboxLimit,
        },
        task: {
          enabled: taskEnabled,
        },
        tradeIn: { enabled: tradeInEnabled, guaranteedOfferPackage },
        oem: selectedOem?.id,
      },
    };

    if (!isCreating) {
      if (variables.addOns?.appointment) {
        variables.addOns.appointment._clear = [
          appointmentEnabled === null && AppointmentFeatureInputParameter._enabled,
        ].filter(Boolean);
      }

      if (variables.addOns?.task) {
        variables.addOns.task._clear = [taskEnabled === null && TaskFeatureInputParameter._enabled].filter(Boolean);
      }

      if (variables.addOns?.retail) {
        variables.addOns.retail._clear = [
          retailEnabled === null && RetailFeatureInputParameter._enabled,
          retailItemLimit === null && RetailFeatureInputParameter._itemLimit,
          retailPaymentOptions === null && RetailFeatureInputParameter._paymentOptions,
          retailBuildAndPrice === null && RetailFeatureInputParameter._buildAndPrice,
          retailShowroom === null && RetailFeatureInputParameter._showroom,
          retailPremiumExportIntegrations === null && RetailFeatureInputParameter._premiumExportIntegrationIds,
        ].filter(Boolean);
      }

      if (variables.addOns?.retailPortal) {
        variables.addOns.retailPortal._clear = [
          retailPortalEnabled === null && RetailPortalFeatureInputParameter._enabled,
        ].filter(Boolean);
      }

      if (variables.addOns?.lead) {
        variables.addOns.lead._clear = [
          leadEnabled === null && LeadFeatureInputParameter._enabled,
          leadSmsMailboxLimit === null && LeadFeatureInputParameter._smsMailboxLimit,
          leadConversations === null && LeadFeatureInputParameter._conversations,
          leadForwarding === null && LeadFeatureInputParameter._forwarding,
        ].filter(Boolean);
      }
      if (variables.addOns?.tradeIn) {
        variables.addOns.tradeIn._clear = [
          tradeInEnabled === null && TradeInFeatureInputParameter._enabled,
          guaranteedOfferPackage === null && TradeInFeatureInputParameter._guaranteedOfferPackage,
        ].filter(Boolean);
      }
      if (variables.addOns) {
        variables.addOns._clear = [!selectedOem && FeatureSetInputParameter._oem].filter(Boolean);
      }
    }

    return { bundle: variables } as RooftopModifyMutationVariables;
  }

  async save() {
    const {
      tier: { steps },
    } = this.props;

    const variables = this.getAddOnVariables();
    const success = await super.save(undefined, variables);

    if (!success) {
      return false;
    }

    // Enabling other steps for edit/navigation
    for (const step of steps!) {
      step.isEnabled = true;
    }

    return true;
  }
}

export default PackageStep;
