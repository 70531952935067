import { memo, type SVGProps } from 'react';

const ExternalLinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={16} viewBox="0 0 24 24" width={16} {...props}>
    <path
      d="M5.072 21.203c-.63 0-1.167-.221-1.61-.665a2.193 2.193 0 0 1-.665-1.61V5.072c0-.63.221-1.167.665-1.61.443-.444.98-.665 1.61-.665h5.79c.32 0 .59.11.809.329.22.22.329.49.329.808 0 .32-.11.589-.33.808a1.1 1.1 0 0 1-.807.33H5.072v13.856h13.856v-5.79c0-.32.11-.59.33-.809.22-.22.489-.329.808-.329.319 0 .588.11.808.33.22.219.33.488.33.807v5.791c0 .63-.223 1.166-.666 1.61-.444.444-.98.665-1.61.665H5.072ZM18.928 6.657l-8.253 8.253a1.061 1.061 0 0 1-.79.314 1.082 1.082 0 0 1-.79-.32 1.075 1.075 0 0 1-.316-.792c0-.318.105-.582.317-.793l8.247-8.247h-2.206a1.1 1.1 0 0 1-.808-.33A1.1 1.1 0 0 1 14 3.934c0-.319.11-.588.33-.808a1.1 1.1 0 0 1 .807-.33h4.929c.319 0 .588.11.808.33.22.22.33.49.33.808v4.928c0 .32-.11.59-.33.809a1.1 1.1 0 0 1-.808.329 1.1 1.1 0 0 1-.808-.33 1.1 1.1 0 0 1-.33-.807V6.657Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(ExternalLinkIcon);
