import { useCallback } from 'react';

import { getNewFacetFilterValue } from 'components/ui/filters/common';
import Button, { Buttons } from 'components/ui/shared/Button';
import { ClientFacetFilterId } from 'enums/clientFacetFilter';
import { useSearch } from 'hooks/contexts/useSearch';
import type { Facet, FacetFilter } from 'store/api/graph/interfaces/types';

const FacetTagFilter = ({ filter }) => {
  const { getSearchParamAsArray, updateSearchParam } = useSearch();
  const selectedFacetIds = getSearchParamAsArray(filter.id);

  const isSelected = useCallback(
    (facet: Facet): boolean => {
      let selected;

      switch (facet.id) {
        case ClientFacetFilterId.ALL: {
          selected = getSearchParamAsArray(filter.id).length === 0;
          break;
        }

        default: {
          selected = selectedFacetIds.includes(facet.id);
        }
      }

      return selected;
    },
    [filter.id, getSearchParamAsArray, selectedFacetIds]
  );

  const onClickHandler = useCallback(
    (facet: Facet, filter: FacetFilter) => {
      let callback;

      switch (facet.id) {
        case ClientFacetFilterId.ALL: {
          callback = updateSearchParam(filter.id, undefined);
          break;
        }

        default: {
          callback = updateSearchParam(
            filter.id,
            getNewFacetFilterValue({
              selectedFacetIds,
              multiSelect: filter.multiSelect,
              facetId: facet.id,
              selected: !isSelected(facet),
            })
          );
        }
      }

      return callback;
    },
    [isSelected, selectedFacetIds, updateSearchParam]
  );

  return (
    <Buttons>
      {filter.facets.map(facet => (
        <Button key={facet.id} onClick={() => onClickHandler(facet, filter)} selected={isSelected(facet)}>
          {facet.name}
        </Button>
      ))}
    </Buttons>
  );
};
export default FacetTagFilter;
