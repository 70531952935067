import { memo } from 'react';

import styled from 'styled-components/macro';

import Label from 'components/core/typography/Label';

import { MultiNavLinkSubItemCSS, MultiNavLinkSubItemsContainerCSS } from './shared/CommonNavItems';
import { WrapLink } from './shared/WrapLink';

export const MultiNavLinkSubItemsContainer = styled.div`
  ${MultiNavLinkSubItemsContainerCSS}
`;

const MutliNavLinkSubItemsContainerInner = styled.div`
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.13);
  border-radius: 5px;
`;

const MultiNavLinkSubItem = styled(WrapLink)`
  ${MultiNavLinkSubItemCSS}
`;

interface MultiNavLinkSubItem {
  label: string;
  url: string;
  title?: string;
}

interface Props {
  items: MultiNavLinkSubItem[];
}

const MultiNavLinkSubItems = ({ items = [] }: Props) => (
  <MultiNavLinkSubItemsContainer>
    <MutliNavLinkSubItemsContainerInner>
      {items.map(item => (
        <MultiNavLinkSubItem key={item.url} title={item.title || item.label} to={item.url}>
          <Label>{item.label}</Label>
        </MultiNavLinkSubItem>
      ))}
    </MutliNavLinkSubItemsContainerInner>
  </MultiNavLinkSubItemsContainer>
);

export default memo(MultiNavLinkSubItems);
