import type { ReactNode } from 'react';

import styled, { css } from 'styled-components/macro';

import Button from 'components/ui/shared/Button';
import CounterBadge from 'components/ui/shared/CounterBadge';
import { BODY_TEXT_TERTIARY } from 'styles/color';
import { BORDER_RADIUS_200, NEUTRAL_075, NEUTRAL_800, SPACE_200 } from 'styles/tokens';

const ToggleButton = styled(Button)<{ isOpen: boolean }>`
  border-radius: ${BORDER_RADIUS_200};
  flex-shrink: 0;
  padding: 0;

  ${({ isOpen }) => css`
    background: ${isOpen ? BODY_TEXT_TERTIARY : NEUTRAL_075};
  `}
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${SPACE_200};

  svg {
    color: ${NEUTRAL_800};
  }
`;

interface Props {
  /** The active number of filters being applied */
  activeFiltersCount?: number;
  /** Whether the filter is open, this dictates the state of the toggle button */
  isOpen: boolean;
  /** Callback for when this button is clicked */
  setIsOpen: (isOpen: boolean) => void;
  /** The icons to use in the button */
  icons: {
    /** Icon to use when the button is in an unselected state (ie to open the filter) */
    openIcon: ReactNode;
    /** Icon to use when the button is in a selected state (ie to close the filter)  */
    closedIcon?: ReactNode;
  };
  /** Callback for mouse entering the button (used in tooltips) */
  onMouseEnter?: () => void;
  /** Callback for mouse leaving the button (used in tooltips) */
  onMouseLeave?: () => void;
  /** Testing id for this button */
  testId?: string;
}

const ToggleFilters = ({
  activeFiltersCount = 0,
  isOpen,
  setIsOpen,
  icons,
  onMouseEnter,
  onMouseLeave,
  testId,
}: Props) => {
  const closeIcon = icons?.closedIcon ?? icons.openIcon;

  return (
    <ToggleButton
      data-testid={testId}
      isOpen={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <CounterBadge count={activeFiltersCount}>
        <Content>{isOpen ? closeIcon : icons.openIcon}</Content>
      </CounterBadge>
    </ToggleButton>
  );
};

export default ToggleFilters;
