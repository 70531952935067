import { useCallback, useState } from 'react';

import FormDialog from 'components/ui/dialogs/FormDialog';
import SaveFilterViewForm from 'components/ui/dialogs/forms/SaveFilterViewForm';
import Loader from 'components/ui/loading/Loader';
import { saveFilterView } from 'containers/filters/savedFilterViewUtils';
import { getApiErrorsMessage } from 'store/api/graph/interfaces/apiErrors';
import type { ConnectionFilter, SavedConnectionFilterFragment } from 'store/api/graph/interfaces/types';
import { translate } from 'utils/intlUtils';

const { t } = translate;

/**
 * The save filter view dialog, which is a dialog that allows the user to save a filter view
 */
export const SaveFilterViewDialog = ({
  isOpen,
  onSaved,
  onClose,
  routePath,
  searchParams,
}: {
  /** Whether this dialog is open and active */
  isOpen: boolean;
  /** Callback for whenever the filter view is saved */
  onSaved: (results: SavedConnectionFilterFragment) => void;
  /** Callback for whenever this dialog closes */
  onClose: () => void;
  /** The current route path that this filter view is on */
  routePath: string;
  /** The search parameters for this filter view that will be saved */
  searchParams: ConnectionFilter;
}) => {
  const [saveFilterViewErrors, setSaveFilterViewErrors] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const onCloseCallback = useCallback(() => {
    setSaveFilterViewErrors(undefined);
    setIsLoading(false);
    onClose();
  }, [onClose]);

  const doSubmit = useCallback(
    (name: string) => {
      const performSubmit = async () => {
        setIsLoading(true);
        try {
          const results = await saveFilterView({ name, routePath, filters: searchParams });

          if (results) {
            onSaved(results);
          } else {
            setSaveFilterViewErrors(t('generic_error_message'));
          }
        } catch (error_) {
          const error = getApiErrorsMessage(error_);
          setSaveFilterViewErrors(error);
        } finally {
          setIsLoading(false);
        }
      };

      void performSubmit();
    },
    [onSaved, routePath, searchParams]
  );

  return (
    <FormDialog isFixedToTop={true} isOpen={isOpen} onCancel={() => onCloseCallback()} title={t('save_filter_one')}>
      <SaveFilterViewForm
        error={saveFilterViewErrors}
        onClose={() => onCloseCallback()}
        onSubmit={name => doSubmit(name)}
      />
      {isLoading && <Loader />}
    </FormDialog>
  );
};
