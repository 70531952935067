import LoggingService from 'components/core/logging/LoggingService';
import type { PaginationProps } from 'enums/pagination';
import { Pagination } from 'enums/pagination';
import type { PageInfo } from 'store/api/graph/interfaces/types';
import { acceptableEmptyParams } from 'utils/filterUtils';
import { storageManager } from 'utils/storageUtils';

const graphBaseUrlStorage = storageManager.createOrFetchStorage<string>('graphBaseUrl');

/**
 * The API To target when using getGraphURL
 */
export enum APIType {
  /** Base API url, default value */
  BASE,
  /** Actual graphql API, `/graphql` */
  GRAPHQL,
  /** For websocket subscriptions, `/graphql-ws` */
  SUBSCRIPTION,
}

/**
 * Returns a params object with preset `first` (and conditionally `after`) param(s),
 * according to startCursor and pageSize calculations.
 */
export const calculatePreviousPaginationParams = (
  startCursor: number,
  pageSize = Pagination.LIST_LENGTH
): PaginationProps => {
  const after = (startCursor - 1 || 0) - pageSize;
  return after <= 0 ? { first: pageSize } : { first: pageSize, after: after.toString() };
};

/**
 * Transforms search parameters to match our common connection's shape;
 * it additionally sets any required pagination arguments if missing.
 */
export const parseConnectionParams = (params, listLength = Pagination.LIST_LENGTH): any => {
  const { first, after, last, sort, before, keyword, searchFilter, ...filter } = params;

  for (const param of acceptableEmptyParams) {
    if (!filter[param]) {
      delete filter[param];
    }
  }

  return { filter, before, after, sort, searchFilter, keyword, [before ? 'last' : 'first']: listLength };
};

/**
 * Triggers file download as browser does not prompt by default
 *
 * @param {object} data
 * @param {string} filename
 * @param {string} type
 */
export const downloadAttachment = (data: BlobPart, filename: string, type = '') => {
  const blob = new Blob([data], { type });
  const link = document.createElement('a');

  if (link.download !== undefined) {
    /*
     * Feature detection
     * Browsers that support HTML5 download attribute
     */
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.append(link); // Required for FF
    link.click();
    link.remove();
  }
};

/**
 * Creates `PageInfo` based on 0 pagination connections, used mainly for `metadata` type tables
 */
export const parsePageInfo = (): Omit<PageInfo, '__typename'> => ({
  endCursor: String(Pagination.LIST_LENGTH),
  hasNextPage: false,
  hasPreviousPage: false,
  startCursor: '1',
  totalEdges: Pagination.LIST_LENGTH,
});

/**
 * Gets the current API url from either localStorage or the default environment
 */
export const getGraphURL = (type: APIType = APIType.BASE): string => {
  const debugUrl = (graphBaseUrlStorage.get() || process.env.REACT_APP_GRAPH_BASE || '').replace('https://', '');
  const isEnvDebugging = process.env.REACT_APP_FEATURE_ENV_DEBUGGING === 'true';

  let url;
  switch (type) {
    case APIType.GRAPHQL: {
      url = isEnvDebugging ? `https://${debugUrl}/graphql` : process.env.REACT_APP_GRAPH_GRAPHQL;
      break;
    }

    case APIType.SUBSCRIPTION: {
      url = isEnvDebugging ? `wss://${debugUrl}/graphql-ws` : process.env.REACT_APP_GRAPH_SUBSCRIPTIONS;
      break;
    }

    default:
    case APIType.BASE: {
      url = isEnvDebugging ? `https://${debugUrl}` : process.env.REACT_APP_GRAPH_BASE;
      break;
    }
  }

  return url;
};

/**
 * Sets the current API url to localStorage for use everywhere
 */
export const setGraphBaseURL = (url?: string) => {
  if (url === undefined) {
    LoggingService.debug({ message: 'Failed to change graph base, no url specified' });
    return;
  }

  // Set only if it's different from current env, to prevent old urls being persisted in localStorage.
  if (process.env.REACT_APP_GRAPH_BASE && !process.env.REACT_APP_GRAPH_BASE.includes(url)) {
    graphBaseUrlStorage.set(url);
    window.location.reload();
  } else {
    clearGraphBaseURL();
  }
};

/**
 * Clears the current API url in localStorage.
 */
export const clearGraphBaseURL = () => {
  graphBaseUrlStorage.remove();
  window.location.reload();
};

/**
 * A util to assert an object `is` a specific TS type when it contains a specific `__typename` value.
 * The `Type` generic is used to assert the type when the specified `__typename` exists.
 *
 * @param object An object to evaluate
 * @param typename The __typename value that will be evaluated
 */
export const isApiType = <Type extends { __typename?: string }>(
  object: unknown,
  typename: Type['__typename']
): object is Type =>
  !!object && typeof object === 'object' && '__typename' in object && object?.__typename === typename;
