import { useCallback, useMemo, useState } from 'react';

import { uniqBy } from 'lodash-es';

import { LinkedSectionHeader } from 'components/ui/details/LinkedSectionHeader';
import CirclePlusIcon from 'components/ui/icons/CirclePlusIcon';
import { LinkedSection } from 'components/ui/layouts/CardLayout';
import { ListItems } from 'components/ui/layouts/ListItem';
import { CreditApplicationListItem } from 'components/ui/lists/CreditApplicationListItem';
import { ElementTestId } from 'enums/testing';
import type { CreditApplication } from 'store/api/graph/interfaces/types';
import { EntityType } from 'store/api/graph/interfaces/types';
import { NEUTRAL_700 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

import LinkedListViewer from '../LinkedListViewer';
import type { SectionProps } from '../LinkedTab';

import CreditApplicationsErrorModal, { CreditApplicationBuilderErrorReason } from './CreditApplicationsErrorModal';
import type { CreditApplicationQueryVariables } from './useCreditApplicationQuery';

const { t } = translate;

// Only one application is shown in the section; the rest will be revealed when that application is clicked on.
const MAX_LISTED_APPLICATIONS = 1;

type CreditApplicationsSectionProps = Omit<SectionProps, 'isList' | 'metadata'> & {
  /** The list of credit applications the lead has made */
  creditApplications: CreditApplication[];
  /** Flags that determine whether or not the Credit Application builder can be used */
  featureEligibility: {
    /** Whether or not the lead has a first and last name */
    leadHasName: boolean;
    /** Whether or not the lead has DealerTrack info, both a `dealertrackId` and a `dealertrackConfirmId` */
    rooftopHasDealerTrackInfo: boolean;
  };
};

const CreditApplicationsSection = ({
  creditApplications,
  featureEligibility,
  onAdd,
  canAdd,
  isNested,
}: CreditApplicationsSectionProps) => {
  const numItems = creditApplications?.length || 0;
  const hasItems = numItems > 0;

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const renderedRetailItems = useMemo(() => {
    if (!hasItems) {
      return null;
    }
    const itemsToRender = uniqBy(creditApplications, 'id').slice(0, MAX_LISTED_APPLICATIONS);
    return itemsToRender.map(item => <CreditApplicationListItem key={item.id} {...item} />);
  }, [hasItems, creditApplications]);

  const onSectionHeaderClicked = useCallback(() => {
    if (!featureEligibility.rooftopHasDealerTrackInfo || !featureEligibility.leadHasName) {
      setShowErrorModal(true);
    } else {
      setShowErrorModal(false);
      onAdd();
    }
  }, [onAdd, featureEligibility]);

  return (
    <LinkedSection>
      <LinkedSectionHeader
        hasItems={hasItems}
        onAdd={authorizedCallback({ cb: onSectionHeaderClicked, isAuth: !!onAdd })}
        suffixIcon={<CirclePlusIcon color={NEUTRAL_700} />}
        testId={ElementTestId.SUBMIT_TO_LENDER_LINKED_SECTION_HEADER}
        title={t('submit_to_lender')}
      />
      <ListItems>
        {renderedRetailItems}
        {numItems > MAX_LISTED_APPLICATIONS && (
          <LinkedListViewer
            isNested={isNested}
            listEntity={EntityType.FINANCE_APPLICATION}
            overrideQueryVars={
              {
                id: creditApplications?.[0]?.id,
                leadId: creditApplications?.[0]?.lead?.id,
              } as CreditApplicationQueryVariables
            }
            remainingItems={numItems - MAX_LISTED_APPLICATIONS}
            title={t('dealertrack')}
            viewMoreTranslationKey="view_x_more_submission"
          />
        )}
      </ListItems>
      <CreditApplicationsErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        reason={
          featureEligibility.rooftopHasDealerTrackInfo
            ? CreditApplicationBuilderErrorReason.LEAD_NAME
            : CreditApplicationBuilderErrorReason.ROOFTOP_DEALERTRACK_INFO
        }
      />
    </LinkedSection>
  );
};

export default CreditApplicationsSection;
