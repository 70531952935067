import { memo } from 'react';
import 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import type { RooftopResponseType } from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';

import Badge, { BadgeColors, Badges } from '../shared/Badge';

interface Props extends RooftopResponseType, ListItemProps {}

const RooftopListItem = ({ suffixIcon, listItemType, name, address, regionCode, city, groupName, active }: Props) => {
  const { t } = translate;
  const activeLabel = t(active ? 'active' : 'inactive');

  return (
    <ListItem listItemType={listItemType} suffixIcon={suffixIcon}>
      <ListItemDetails>
        <TextRow>
          <PrimaryText>{name.value}</PrimaryText>
        </TextRow>
        <TextRow>
          <SecondaryText>{groupName.name.value}</SecondaryText>
        </TextRow>
        <TextRow>
          <SecondaryText>{`${address.value}, ${city.value}, ${regionCode.value}`}</SecondaryText>
        </TextRow>
        <TextRow>
          <Badges>
            <Badge
              color={active ? BadgeColors.POSITIVE : BadgeColors.NEGATIVE}
              title={`${t('status')}: ${activeLabel}`}
            >
              {activeLabel}
            </Badge>
          </Badges>
        </TextRow>
      </ListItemDetails>
    </ListItem>
  );
};

export default memo(RooftopListItem);
