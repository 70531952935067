import { ContainerStyles } from 'components/core/createModify/stepFields/StepFieldLayout';
import { StepFieldType } from 'enums/stepFieldType';
import { RooftopModifyParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

import { RooftopDescriptionBuilderFields } from './interfaces';

const DescriptionFields = defineDetailsFields({
  [RooftopDescriptionBuilderFields.DESCRIPTION]: {
    label: 'description',
    placeholder: 'enter_description',
    groupType: StepFieldType.TEXT_AREA,
    active: true,
    containerStyles: ContainerStyles.FULL_HEIGHT,
    clear: { field: RooftopModifyParameter._description },
  },
});

export default DescriptionFields;
