import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import { InventoryItemTypeSettings } from 'components/sections/createModify/inventoryItems/steps/DetailsInventoryItemFields';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { StepFieldType } from 'enums/stepFieldType';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

const CaptureVinFields = defineDetailsFields({
  [DetailsInventoryItemBuilderFields.TYPE]: {
    groupType: StepFieldType.TYPES,
    settings: {
      ...InventoryItemTypeSettings,
    },
  },
  [DetailsInventoryItemBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: 'rooftop',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
  },
  [DetailsInventoryItemBuilderFields.VIN]: { label: 'vin', selectedValue: '' },
  [DetailsInventoryItemBuilderFields.STOCK_NUMBER]: { label: 'stock_number' },
});

export default CaptureVinFields;
