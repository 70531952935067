import { memo } from 'react';

import 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import BellRingingIcon from 'components/ui/icons/BellRingingIcon';
import Image from 'components/ui/images/Images';
import { ListItem, ListItemDetails, ListItemSeparator, ListItemSeparatorWrapper } from 'components/ui/layouts/ListItem';
import { ImageSize, ImageType } from 'enums/imageType';
import type { TradeInAppraisalExternal } from 'store/api/graph/interfaces/types';
import { BLUE_500 } from 'styles/tokens';
import { formatFullName } from 'utils/formatUtils';
import { getRelativeTime } from 'utils/timeUtils';

const ExternalAppraiserListItem = ({
  contact: {
    rgbHex,
    firstName,
    lastName,
    displayName,
    rooftop: {
      name: { value: rooftopName },
    },
  },
  updated,
}: TradeInAppraisalExternal) => (
  <ListItem css="flex-wrap: wrap;">
    <Image fallbackSrc={{ firstName, lastName, rgbHex }} size={ImageSize.LIST_ITEM} type={ImageType.USER} />
    <ListItemDetails css="flex: 1;">
      <TextRow css="grid-template-columns: auto auto 1fr auto;">
        <PrimaryText>{formatFullName({ displayName, firstName, lastName })}</PrimaryText>
        <SecondaryText>&bull;</SecondaryText>
        <SecondaryText css="flex: 1;">{getRelativeTime(updated)}</SecondaryText>
        <BellRingingIcon color={BLUE_500} height={16} width={16} />
      </TextRow>
      <TextRow>
        <SecondaryText>{rooftopName}</SecondaryText>
      </TextRow>
    </ListItemDetails>
    <ListItemSeparatorWrapper>
      <ListItemSeparator />
    </ListItemSeparatorWrapper>
  </ListItem>
);

export default memo(ExternalAppraiserListItem);
