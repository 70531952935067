import { cloneDeep, get, isNil, isObject, merge, set } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { ListCondition } from 'components/core/createModify/stepFields/subSteps/listCondition';
import LoggingService from 'components/core/logging/LoggingService';
import type { ExtendedEntityType } from 'enums/extendedEntityType';
import { CustomEntity } from 'enums/extendedEntityType';
import { RetailAdjustmentAmountType } from 'enums/retailBulkAdjustment';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import type { ApiError } from 'store/api/graph/interfaces/apiErrors';
import type { RetailItem, TradeInItem } from 'store/api/graph/interfaces/types';
import { DisplacementUnit, EntityType, PaymentOption } from 'store/api/graph/interfaces/types';
import type { SelectOption, UserType } from 'store/api/graph/responses/responseTypes';
import { type Intl, MultilingualStringValue, translate } from 'utils/intlUtils';
import { getInventoryItemTypeConfig } from 'utils/inventoryItemUtils';

const { t } = translate;

/**
 * Method that recursively strips out '__typename' from variables, API does not accept __typename
 * @param variables
 */
export const stripTypeNames = variables => {
  for (const key of Object.keys(variables)) {
    if (key === '__typename') {
      delete variables[key];
    } else if (variables[key]) {
      let varRef = variables[key];
      // Strip out any __typename artifacts
      if (varRef?.__typename) {
        delete varRef.__typename;

        // Unsetting any unnecessary variables
        if (Object.keys(varRef).length === 0) {
          varRef = undefined;
        }
      }

      variables[key] = typeof varRef === 'object' ? stripTypeNames(varRef) : varRef;
    }
  }

  return variables;
};

/**
 * Method that recursively prepares variables for API consumption
 */
export const formatApiValues = <
  TFormattedValues extends object = any,
  TVariables = { [K in keyof TFormattedValues]: any },
>(
  variables: TVariables
): TFormattedValues => {
  const doFormatting = variables => {
    // Custom formatting
    for (const key of Object.keys(variables)) {
      if (variables[key]) {
        // Recursively iterate and format any nested fields
        if (isObject(variables[key])) {
          variables[key] = doFormatting(variables[key]);
        }

        let varRef = variables[key];
        // Monetary
        switch (varRef.__typename) {
          case 'MonetaryAmount': {
            // TODO: move all typenames to a constant
            varRef = varRef.amount;
            break;
          }

          case 'MultilingualString': {
            varRef = [MultilingualStringValue.EN, MultilingualStringValue.FR, MultilingualStringValue.ES].some(
              key => !!varRef[key]
            )
              ? { ...varRef, value: undefined }
              : null;
            break;
          }

          case 'Mileage': {
            varRef = { ...varRef, formattedAmount: undefined };
            break;
          }

          default: {
            if (varRef.unit) {
              // If this variable has a unit, then the corresponding amount value must be converted to a number
              const amount = Number.parseFloat(varRef.amount);
              if (Number.isNaN(amount)) {
                varRef = null;
              } else {
                varRef.amount = amount;
              }
            }
          }
        }

        variables[key] = varRef;
      }
    }

    return variables;
  };

  // Clone, otherwise mutation will affect details page
  const formattedVariables = doFormatting(cloneDeep(variables));

  // After formatting variables, strip out any __typename fields
  return stripTypeNames(formattedVariables);
};

/**
 * Method that handles any custom formatting required to make field values comply with expected API values
 */
export const formatFieldValues = <TData extends Record<string, any> = any, TMetaData extends Record<string, any> = any>(
  fields: StepField<TData, TMetaData>[],
  variables: any
) => {
  for (const field of fields) {
    let formattedValue = get(
      field,
      'selectedValue.id',
      !isNil(field.selectedValue) && field.selectedValue !== '' ? field.selectedValue : null
    );

    // Array of objects
    if (Array.isArray(formattedValue)) {
      formattedValue = formattedValue.map(entry => entry.id || entry);
    }

    // Formatting for displacement type fields
    if (field.groupType === StepFieldType.DISPLACEMENT) {
      formattedValue = formattedValue
        ? {
            amount: Number(formattedValue),
            unit: DisplacementUnit.LITERS,
          }
        : null;
    }
    // Formatting for Mileage
    else if (field.groupType === StepFieldType.MILEAGE) {
      formattedValue =
        ((!!formattedValue?.amount || Number(formattedValue?.amount) === 0) && {
          amount: formattedValue.amount,
          unit: formattedValue.unit,
        }) ||
        null;
    }
    // Formatting for currency values
    else if (field.groupType === StepFieldType.CURRENCY && formattedValue === '') {
      formattedValue = 0;
    }
    // Formatting for multilingual strings
    else if (field.groupType === StepFieldType.MULTILINGUAL_TOGGLE_FIELD) {
      /*
       * If there are no defined values for any language in the multilingual toggle field, then the formattedValue
       * here should just be null instead of { 'en': null, 'fr': null, ...etc }
       */
      formattedValue = [MultilingualStringValue.EN, MultilingualStringValue.FR, MultilingualStringValue.ES].some(
        lang => !!formattedValue?.[lang]
      )
        ? { ...formattedValue, value: undefined }
        : null;
    }
    // Converting grouped fields to variables object
    else if (field.groupSubTypes?.includes(StepFieldSubType.FIELD_GROUP) && (field.options as StepField[])) {
      for (const item of field.options as StepField[]) {
        set(variables, field.queryVar, item.selectedValue);
      }
    }

    set(variables, field.queryVar, formattedValue);
  }
  return variables;
};

/**
 * Method that will format API values so that they can be used in StepFields. Basically the reverse of the method
 * formatFieldValues.
 * TODO: Will add additional __typename cases when they are needed.
 * @param data - the data to format
 */
export const formatApiValuesForFields = (data: any) => {
  const doFormatting = variables => {
    // Custom formatting
    for (const key of Object.keys(variables)) {
      if (variables[key]) {
        // Recursively iterate and format any nested fields
        if (isObject(variables[key])) {
          variables[key] = doFormatting(variables[key]);
        }

        let varRef = variables[key];
        // Monetary
        if (varRef.__typename === 'MonetaryAmount' || varRef.__typename === 'Displacement') {
          // TODO: move all typenames to a constant
          varRef = varRef.amount;
        } else if (varRef.__typename === 'MultilingualString') {
          varRef = varRef?.value;
        }

        variables[key] = varRef;
      }
    }

    return variables;
  };

  return doFormatting(cloneDeep(data));
};

/**
 * Helper method to access the options of a StepField in case it is a string pointer
 */
export const getOptionsFromStepField = <
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
>(
  stepField: StepField<TData, TMetaData>,
  metadata: any
): SelectOption[] | StepField<TData, TMetaData>[] =>
  typeof stepField?.options === 'string' ? get(metadata, stepField.options, []) : stepField?.options || [];

/**
 * Helper method that formats `_clear` fields based on the content of each field
 */
export const formatClearFields = <TData extends Record<string, any> = any, TMetaData extends Record<string, any> = any>(
  fields: StepField<TData, TMetaData>[]
) => {
  const clearFields = {};

  for (const field of fields) {
    if (!field.clear) {
      continue;
    }

    const value =
      field.selectedValue?.id ||
      field.selectedValue?.amount ||
      field.selectedValue?.value ||
      field.selectedValue?.address ||
      (!!field.selectedValue &&
        [MultilingualStringValue.EN, MultilingualStringValue.FR, MultilingualStringValue.ES].some(
          key => !!field.selectedValue[key]
        )) ||
      /**
       * If empty arrays are valid and do not need to be cleared, then the field should only be cleared if it has a nil
       * value. However, if empty arrays should be cleared, then the field will be cleared if the array length is
       * less than 1.
       */
      (field.clear.allowEmptyArray ? !isNil(field.selectedValue) : field.selectedValue?.length) ||
      (typeof field.selectedValue !== 'object' && !isNil(field.selectedValue) && field.selectedValue !== '') ||
      (field.groupType === StepFieldType.PHOTO && field.selectedValue) ||
      (field.groupType === StepFieldType.MILEAGE &&
        !isNil(field.selectedValue.amount) &&
        field.selectedValue.amount !== '') ||
      null;

    if (isNil(value) || value === '' || value === NONE_LIST_OPTION.id) {
      const clearTarget = field.clear.target ? `${field.clear.target}._clear` : '_clear';
      const clearArray = get(clearFields, clearTarget, []) as string[];
      set(clearFields, clearTarget, [...clearArray, field.clear.field]);
    }
  }
  return clearFields;
};

/**
 * Helper method that converts a StepFields object dictionary into a StepField[] array
 */
export const objectToStepFieldArray = <
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
>(
  stepFields: StepFields<TData, TMetaData> | undefined,
  stepFieldPresets: StepFields<TData, TMetaData> = {}
) => {
  for (const key of Object.keys(stepFieldPresets)) {
    if (stepFields?.[key] === undefined) {
      delete stepFieldPresets[key];
    }
  }

  const mergedStepFields = merge({}, stepFields, stepFieldPresets);
  return Object.keys(mergedStepFields).map(key => ({ queryVar: key, ...mergedStepFields[key] }));
};

/**
 * Utility for getting StepField data. No formatting or processing of the step field data is done, the field's
 * queryVar is used as a lookup key, and the value is the fields selectedValue.
 * @param fields - Array of StepField
 */
export const getStepFieldData = <TData extends Record<string, any> = any, TMetaData extends Record<string, any> = any>(
  fields: StepField<TData, TMetaData>[]
): Record<string, any> => {
  const fieldData = {};
  for (const field of fields) {
    fieldData[field.queryVar] = field.selectedValue;
  }
  return fieldData;
};

/**
 * Helper method that sets selectedValues, options, and placeholders based on the data & metadata
 */
export const defineFieldValues = <TData extends Record<string, any> = any, TMetaData extends Record<string, any> = any>(
  stepFields: StepField<TData, TMetaData>[],
  data: TData,
  metadata?: any
): StepField<TData, TMetaData>[] => {
  // Set selected values where applicable
  for (const field of stepFields) {
    const fieldValue = get(
      data,
      field.useQueryAliasForDataRetrieval && !!field.queryAlias
        ? Array.isArray(field.queryAlias)
          ? field.queryAlias[0]
          : field.queryAlias
        : field.queryVar
    );
    if (
      fieldValue !== undefined &&
      field.selectedValue === undefined &&
      !field?.displayType?.includes(StepFieldDisplayType.HIDDEN)
    ) {
      const value = fieldValue?.amount ?? fieldValue;

      const options = metadata && (getOptionsFromStepField(field, metadata) as SelectOption[]);

      // Value derived from metadata options if applicable
      if (!!options?.length && value) {
        const newVal = Array.isArray(value)
          ? // Deriving values from metadata if the selected value is an array of id's or options (e.g. tagIds)
            value.map(item => options.find(({ id }) => item.id === id || item === id) || item)
          : // Default fallback deriving a singular value from the list of options
            options.find(opt => opt.id === value.id || opt.id === String(value)) || value;
        field.selectedValue = newVal;
      } else {
        field.selectedValue = value;
      }
    }

    if (!field.placeholder && field.label) {
      field.placeholder = t(`${field.type === StepFieldType.DROPDOWN ? 'select' : 'input'}_x`, [
        t(field.label as Intl),
      ]);
    }
  }

  return stepFields;
};

/**
 * Helper method that sets a series of StepFields selectedValues, based on any data fields that match the queryVar
 * or queryAlias of a StepField. Works similar to defineFieldValues(), except it does not skip StepFields that
 * already have a defined selectedValue, and will match StepFields based on queryVar OR queryAlias.
 * @param stepFields - Array of StepFields
 * @param data - The data payload to overwrite StepField selectedValues with
 */
export const overwriteFieldSelectedValues = (stepFields: StepField[], data: any): StepField[] => {
  const stepFieldsCopy = cloneDeep(stepFields);
  for (const field of stepFieldsCopy) {
    // Find any values based off the fields queryVar
    let dataValue = get(data, field.queryVar);

    if (dataValue === undefined) {
      // If no values exist for queryVar, use queryAlias instead
      for (const queryAlias of [field.queryAlias].flat().filter(Boolean)) {
        dataValue = get(data, queryAlias);
        if (dataValue !== undefined) {
          field.selectedValue = dataValue;
        }
      }
    } else {
      field.selectedValue = dataValue;
    }
  }
  return stepFieldsCopy;
};

/**
 * Helper method formats a client-side error based off of the `stepField`
 */
export const formatStepError = <TData extends Record<string, any> = any, TMetaData extends Record<string, any> = any>(
  stepField: StepField<TData, TMetaData>,
  defaultMessage?: string
): ApiError => {
  const queryAlias =
    !!stepField.queryAlias && Array.isArray(stepField.queryAlias) ? stepField.queryAlias[0] : stepField.queryAlias;
  const fieldLabel = stepField.label || queryAlias || stepField.queryVar;
  const isSelectionField =
    stepField.groupType === StepFieldType.DROPDOWN || stepField.groupSubTypes?.includes(StepFieldSubType.MULTI_SELECT);
  const defaultError = `${t('no_x', [fieldLabel])}: ${
    isSelectionField ? t('make_selection_to_continue') : t('enter_x_to_continue', [fieldLabel])
  }`;

  return {
    extensions: { fields: [queryAlias || stepField.queryVar] },
    message: stepField.customError || defaultMessage || defaultError,
  };
};

/**
 * Helper method to get field by identifier (queryAlias or queryVar)
 *
 * @param fieldId - the identifier to look for
 * @param fields - the list of StepFields to search through
 * @param ignoreAlias - whether or not a field's queryAlias should be ignored when looking for a match
 */
export const getStepField = <
  TReturnValue = any,
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
>(
  fieldId: string,
  fields: StepField<TData, TMetaData, TReturnValue>[],
  ignoreAlias = false
): StepField<TData, TMetaData, TReturnValue> => {
  const stepField = fields.find(
    ({ queryVar, queryAlias }) =>
      !!fieldId && (queryVar === fieldId || (!ignoreAlias && (queryAlias === fieldId || queryAlias?.includes(fieldId))))
  );

  if (!stepField) {
    LoggingService.debug({ message: `No stepfield found for: ${fieldId}` });
    return {} as StepField<TData, TMetaData, TReturnValue>;
  }

  return stepField;
};

/**
 * Format helper method to parse options into groups
 */
export const getGroupedOptions = (
  options: any[],
  subStepGroups?: ListCondition[]
): { label?: string; items: any[]; id?: string }[] => {
  if (subStepGroups) {
    return subStepGroups
      .map(({ conditionProp, conditionValue, label, id }) => ({
        id,
        label,
        items: options.filter(option => get(option, conditionProp) === conditionValue),
      }))
      .filter(({ items }) => items.length > 0);
  }
  return [{ items: options }];
};

/**
 * Helper method for adding a StepFieldDisplayType value to an array of StepFieldDisplayTypes. This method
 * accepts either a StepField, which will re-assign its displayType with the new list of added displayTypes,
 * or an array of existing StepFieldDisplayType's to which the displayType will be added to, and then returned.
 *
 * @param target: The array of StepFieldDisplayTypes
 * @param type: The StepFieldDisplayType that needs to be added
 */
export const addDisplayType = (
  target: StepField | StepFieldDisplayType[] | undefined = [],
  type: StepFieldDisplayType
) => {
  const isStepField = !Array.isArray(target);
  const displayTypes = isStepField ? target.displayType || [] : target;

  const newDisplayTypes = displayTypes.includes(type) ? displayTypes : [...displayTypes, type];

  if (isStepField) {
    target.displayType = newDisplayTypes;
  }

  return newDisplayTypes;
};

/**
 * Helper method for removing a StepFieldDisplayType value from an array of StepFieldDisplayTypes. This method
 * accepts either a StepField, which will re-assign its displayType with the new list of removed displayTypes,
 * or an array of existing StepFieldDisplayType's to which the displayType will be removed from, and then returned.
 *
 * @param target: The array of StepFieldDisplayTypes
 * @param type: The StepFieldDisplayType that needs to be removed
 */
export const removeDisplayType = (
  target: StepField | StepFieldDisplayType[] | undefined = [],
  type: StepFieldDisplayType
) => {
  const isStepField = !Array.isArray(target);
  const useDisplayTypes = isStepField ? target.displayType || [] : target;
  const newDisplayTypes = useDisplayTypes.filter(n => n !== type) || [];

  if (isStepField) {
    target.displayType = newDisplayTypes;
  }

  return newDisplayTypes;
};

export type DisplayTypeConfig = {
  type: StepFieldDisplayType;
  active: boolean;
};
/**
 * Helper method for setting a StepFieldDisplayType value from an array of StepFieldDisplayTypes. This method
 * accepts either a StepField, which will re-assign its displayType with the updated list of displayTypes,
 * or an array of existing StepFieldDisplayType's to which the displayType will be added/removed, and then returned.
 *
 * @param configs: The configurations for adding/removing StepFieldDisplayTypes (expects the form of DisplayTypeConfig)
 * @param target: The array of StepFieldDisplayTypes to add/remove StepFieldDisplayTypes, or StepField
 */
export const setDisplayTypes = <TData extends Record<string, any> = any, TMetaData extends Record<string, any> = any>(
  configs: DisplayTypeConfig[] | DisplayTypeConfig,
  target: StepField<TData, TMetaData> | StepFieldDisplayType[] | undefined = []
) => {
  const isStepField = !Array.isArray(target);
  let displayTypes = isStepField ? target?.displayType : target;

  for (const { active, type } of [configs].flat()) {
    displayTypes = (active ? addDisplayType : removeDisplayType)(displayTypes, type);
  }

  if (isStepField) {
    target.displayType = displayTypes;
  }

  return displayTypes;
};

/**
 * Shortcut helper that will hide and omit StepFields in bulk. Uses getStepField to get the StepField, then uses
 * setDisplayTypes to set HIDDEN and OMITTED display configurations.
 *
 * @param fieldsToHide - array of StepField identifiers
 * @param allFields - array of StepFields to search through
 */
export const hideAndOmitFields = (fieldsToHide: string[], allFields: StepField[]) => {
  for (const field of fieldsToHide) {
    const currentField = getStepField(field, allFields);
    setDisplayTypes(
      [
        { type: StepFieldDisplayType.HIDDEN, active: true },
        { type: StepFieldDisplayType.OMITTED, active: true },
      ],
      currentField
    );
  }
};

/**
 * A utility parser that will generate ListCondition categories for an array of objects that have a 'category' field
 */
export const parseCategoryGroups = (options: { category: string }[]) =>
  options.reduce((acc, { category }) => {
    if (!acc.some(({ conditionValue }) => conditionValue === category)) {
      acc.push({
        conditionProp: 'category',
        conditionValue: category,
        label: category,
      });
    }
    return acc;
  }, [] as ListCondition[]);

/**
 * A special list item constant used in dropdown type step fields. If this option is selected, then that field will
 * be included in the queries _clear variables. This is helpful for providing a 'None' list option that resets
 * a value by clearing it.
 */
export const NONE_LIST_OPTION = { id: 'NONE_OPTION', name: 'none' } as const;

/**
 * Util for generating the array of payment methods given the payment method StepField fields. Returns an array of
 * the PaymentOption, where each PaymentOption is included depending on whether or not the corresponding StepField
 * input switch is set to true and not `HIDDEN` from the UI.
 * @param cashField - StepField switch for including cash methods
 * @param financeField - StepField switch for including finance method
 * @param leaseField - StepField switch for including lease method
 */
export const getPaymentOptions = (
  cashField: StepField,
  financeField: StepField,
  leaseField: StepField
): PaymentOption[] =>
  [
    !cashField.displayType?.includes(StepFieldDisplayType.HIDDEN) && !!cashField.selectedValue && PaymentOption.CASH,
    !financeField.displayType?.includes(StepFieldDisplayType.HIDDEN) &&
      !!financeField.selectedValue &&
      PaymentOption.FINANCE,
    !leaseField.displayType?.includes(StepFieldDisplayType.HIDDEN) && !!leaseField.selectedValue && PaymentOption.LEASE,
  ].filter(Boolean);

/**
 * This util is used for both bulk adjustment and single price adjustment builders. In both of these builders,
 * a fixed or percentage value can be entered via a text field with a toggle to specify fixed or percent. When editing,
 * if a fixed value is supplied, then the percentage value must be set to null (and vice-versa). Since this operation
 * is identical in both builders, it has been moved to its own util method.
 *
 * @param fixedField - The field that holds the fixed value
 * @param percentageField - The field that holds the percentage value
 * @param fixedOrPercentageToggleField - The field the user uses to input a value, and specify the type (perc. vs fixed)
 * @param isNegative - Whether or not the fixed or percentage value is a discount (ie negative value)
 */
export const setFixedAndPercentageValuesFromToggleFieldState = (
  fixedField: StepField,
  percentageField: StepField,
  fixedOrPercentageToggleField: StepField,
  isNegative: boolean
) => {
  const amount = fixedOrPercentageToggleField.selectedValue;

  // If no amount has been entered, then the percentage and fixed fields should both be null
  if (isNil(amount?.value)) {
    percentageField.selectedValue = null;
    fixedField.selectedValue = null;
  } else if (amount?.id === RetailAdjustmentAmountType.PERCENTAGE) {
    fixedField.selectedValue = null;
    percentageField.selectedValue = ((isNegative ? -1 : 1) * Number(amount.value)) / 100;
  } else {
    percentageField.selectedValue = null;
    fixedField.selectedValue = (isNegative ? -1 : 1) * Number(amount.value);
  }
};

export const getFormattedFixedPrice = amount => Math.abs(amount);
// `amount` is a value in the range of -1 to 1.
export const getFormattedPercentagePrice = amount => Number(Math.abs(amount * 100).toFixed(2));

export const getUsersRooftop = (user: UserType) =>
  user.rooftops?.[0] && {
    ...user.rooftops?.[0],
    name: user.rooftops?.[0].name?.value,
  };

/**
 * Get the title (translation key) for the builder when editing an entity from the table view
 */
export const getBuilderTitleForTables = ({
  entityType,
  itemData,
}: {
  /** The entity type for this builder */
  entityType: ExtendedEntityType;
  /** The data of the entity being editing */
  itemData: any;
}): Intl | undefined => {
  // TODO: [ED-8911]
  if (entityType === EntityType.TRADE_IN_ITEM) {
    const data = itemData as TradeInItem;
    if (!data?.type) {
      return 'trade_ins_one';
    }
    return getInventoryItemTypeConfig(data.type).titleLabel;
  } else if (entityType === EntityType.RETAIL_ITEM) {
    const data = itemData as RetailItem;
    if (!data?.type) {
      return 'retail_item_one';
    }
    return getInventoryItemTypeConfig(data.type).titleLabel;
  }

  return {
    [EntityType.APPOINTMENT]: 'appointment',
    [EntityType.TASK]: 'task',
    [CustomEntity.USER]: 'user',
    [EntityType.ROOFTOP]: 'rooftop',
    [EntityType.LEAD]: 'lead_one',
  }[entityType];
};
