import { useCallback, useState } from 'react';

import { contactModify } from 'components/sections/createModify/contacts/ContactCreateModifyQueries';
import ContactQueryMethods from 'components/sections/createModify/contacts/ContactQueryMethods';
import ArchiveDialog from 'components/ui/dialogs/ArchiveDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { CustomEntity } from 'enums/extendedEntityType';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import type { ContactResponseType } from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props extends MenuItemProps {
  item: ContactResponseType;
}

const RooftopContactsMenuItems = ({ item }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const { archived } = item || {};
  const { hasPermissions } = useUser();
  const { toggleTier } = useCreateModify();
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false);

  const onModifyClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.CONTACT_MODIFY,
        entityType: CustomEntity.ROOFTOP_CONTACTS,
        title: t('modify_contact'),
        isCreating: false,
        itemId: item?.id,
        seededData: {
          rooftop: item?.rooftop,
        },
      }),
    [item, toggleTier]
  );

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled: !hasPermissions(builderConfig[BuilderType.CONTACT_MODIFY].requiredPermissions),
    },
    {
      label: t(archived ? 'unarchive' : 'archive'),
      onClick: () => setIsArchiveDialogOpen(true),
      isArchivable: false,
      disabled: !hasPermissions(builderConfig[BuilderType.CONTACT_MODIFY].requiredPermissions),
    },
  ];

  return (
    <>
      <MenuItems isArchived={archived} items={menuItemsConfig} />
      <ArchiveDialog
        entityTypeLabel={t('contact')}
        isDialogOpen={isArchiveDialogOpen}
        item={item as Record<string, unknown>}
        mutation={contactModify}
        refetchQueryNames={ContactQueryMethods.refetchQueries}
        setIsDialogOpen={setIsArchiveDialogOpen}
      />
    </>
  );
};

export default RooftopContactsMenuItems;
