import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { isNil } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import Label, { TertiaryLabel } from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import Checkbox from 'components/ui/forms/shared/Checkbox';
import FalseIcon from 'components/ui/icons/FalseIcon';
import TrueIcon from 'components/ui/icons/TrueIcon';
import { ElementTestId, toggleFieldTestId } from 'enums/testing';
import { GREEN_500, RED_500 } from 'styles/tokens';
import { isFrCA, translate } from 'utils/intlUtils';

const { t } = translate;

export const ToggleRow = styled.div`
  display: flex;

  > :first-child {
    flex: 1;
  }

  > :not(:first-child) {
    margin-left: 31px;
  }
`;

const ToggleRowText = styled(Text)`
  display: flex;
  align-items: center;
`;

export const ToggleTitle = props => {
  const isFrench = isFrCA();
  return (
    <ToggleRow {...props}>
      <Label>{t('flags')}</Label>
      <TertiaryLabel
        css={css`
          transform: translateX(${isFrench ? '2.4ch' : '1ch'});
        `}
      >
        {t('yes')}
      </TertiaryLabel>
      <TertiaryLabel
        css={css`
          transform: translateX(${isFrench ? '1ch' : '0.25ch'});
        `}
      >
        {t('no')}
      </TertiaryLabel>
    </ToggleRow>
  );
};

type CheckedValue = boolean | string | undefined;

const getValue = (value: CheckedValue, isBinary?: boolean) =>
  isNil(value) && !isBinary ? value : String(value) === 'true';

interface Props {
  /** The label of the input */
  label: string;
  /** The "checked" value for the input */
  value: CheckedValue;
  /** A callback to run when the toggle is toggled */
  onChange?: (val: boolean) => void;
  /** Determines whether `value` will always be parsed as true/false */
  isBinary?: boolean;
  /** A css className that will be output on ToggleRow container */
  className?: string;
  /** ID used for referencing this input in tests */
  testId?: string;
  /** A component to render before the label */
  childrenBeforeLabel?: ReactNode;
}

const Toggle = ({ label, value, onChange, isBinary, testId, childrenBeforeLabel, ...props }: Props) => {
  const [isToggled, setIsToggled] = useState<CheckedValue>(getValue(value, isBinary));
  const onToggle = useCallback(
    checked => {
      const newValue = !isBinary && checked === isToggled ? undefined : checked;
      setIsToggled(newValue);
      onChange?.(newValue);
    },
    [isBinary, isToggled, onChange]
  );

  useEffect(() => {
    setIsToggled(getValue(value, isBinary));
  }, [value, isBinary]);

  return (
    <ToggleRow {...props} data-testid={testId}>
      <ToggleRowText>
        {childrenBeforeLabel}
        {label}
      </ToggleRowText>
      <Checkbox
        checked={String(isToggled) === 'true'}
        icon={<TrueIcon color={GREEN_500} />}
        onChange={onToggle.bind(null, true)}
        round
        testId={testId ? toggleFieldTestId(testId, ElementTestId.TOGGLE_ON) : undefined}
      />
      <Checkbox
        checked={String(isToggled) === 'false'}
        icon={<FalseIcon color={RED_500} />}
        onChange={onToggle.bind(null, false)}
        round
        testId={testId ? toggleFieldTestId(testId, ElementTestId.TOGGLE_OFF) : undefined}
      />
    </ToggleRow>
  );
};

export default Toggle;
