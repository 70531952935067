import type { ComponentProps, ComponentType } from 'react';

import { ConnectionSearchProvider } from 'providers/ConnectionSearchProvider';

export function withSearch<T extends object>(
  Component: ComponentType<T>,
  searchProviderProps: Optional<ComponentProps<typeof ConnectionSearchProvider>>
): ComponentType<T> {
  return (props: T) => (
    <ConnectionSearchProvider {...searchProviderProps}>
      <Component {...props} />
    </ConnectionSearchProvider>
  );
}
