/**
 * FVD (Font Variant Description) declarations.
 * A way to describe a set of font properties.
 *
 * More info: https://github.com/typekit/fvd
 */
export const BOLD_FVD = {
  n4: 400,
  n5: 500,
  n6: 600,
  n8: 800,
};

// Line height
export const LINE_HEIGHT_MINIMUM = 1;
export const LINE_HEIGHT_CONDENSED_ULTRA = 'normal';
export const LINE_HEIGHT_CONDENSED = 1.25;
export const LINE_HEIGHT_REDUCED = 1.4;
export const LINE_HEIGHT_DEFAULT = 1.715;

// Letter spacing
export const LETTER_SPACING_DENSE = '-0.0125rem'; // -0.2px
export const LETTER_SPACING_DEFAULT = '0';
export const LETTER_SPACING_EXPANDED_1 = '0.04375rem'; // 0.7px
export const LETTER_SPACING_EXPANDED_2 = '0.056875rem'; // 0.91px
export const LETTER_SPACING_EXPANDED_3 = '0.0625rem'; // 1px

// Font size
export const FONT_SIZE_8 = '0.5rem'; // 8px;
export const FONT_SIZE_9 = '0.5625rem'; // 9px
export const FONT_SIZE_10 = '0.625rem'; // 10px;
export const FONT_SIZE_11 = '0.6875rem'; // 11px
export const FONT_SIZE_12 = '0.75rem'; // 12px
export const FONT_SIZE_13 = '0.8125rem'; // 13px
export const FONT_SIZE_14 = '0.875rem'; // 14px
export const FONT_SIZE_16 = '1rem'; // 16px
export const FONT_SIZE_18 = '1.125rem'; // 18px
export const FONT_SIZE_20 = '1.25rem'; // 20px
export const FONT_SIZE_24 = '1.5rem'; // 24px
export const FONT_SIZE_28 = '1.8rem'; // 28px
export const FONT_SIZE_40 = '2.5rem'; // 40px
export const FONT_SIZE_44 = '2.75rem'; // 44px

/** Font-weight: 400 */
export const FONT_WEIGHT_NORMAL = BOLD_FVD.n4;
/** Font-weight: 500 */
export const FONT_WEIGHT_SEMI_BOLD = BOLD_FVD.n5;
/** Font-weight: 600 */
export const FONT_WEIGHT_BOLD = BOLD_FVD.n6;
/** Font-weight: 800 */
export const FONT_WEIGHT_BOLDER = BOLD_FVD.n8;

export const FONT_FAMILY =
  "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
