import type { DocumentNode } from 'graphql';
import { merge } from 'lodash-es';

import { ApolloFetchPolicy } from 'enums/apollo';
import { type QueryConfig, useQuery } from 'hooks/useQuery';
import type {
  RetailBulkAdjustmentsCondensedListQuery,
  RetailBulkAdjustmentsCondensedListQueryVariables,
} from 'store/api/graph/interfaces/types';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';

export const useRetailBulkAdjustmentListQuery = (
  query: DocumentNode,
  config: QueryConfig<RetailBulkAdjustmentsCondensedListQuery, RetailBulkAdjustmentsCondensedListQueryVariables>
): CustomQueryResult =>
  useQuery<RetailBulkAdjustmentsCondensedListQuery, RetailBulkAdjustmentsCondensedListQueryVariables>(
    query,
    merge({}, config, {
      options: {
        fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
      },
      queryAdapter: ({ item: { bulkAdjustments } }) => ({
        connection: {
          edges: bulkAdjustments,
        },
        metadata: {},
      }),
    })
  );
