import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import { RooftopIntegrationDetailsFields } from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import { RooftopIntegration } from 'enums/columns/rooftopIntegration';
import { StepFieldType } from 'enums/stepFieldType';
import { RooftopIntegrationModifyParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

const DetailFields = defineDetailsFields({
  [RooftopIntegrationDetailsFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: RooftopIntegration.ROOFTOP_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
  },
  [RooftopIntegrationDetailsFields.INTEGRATION_TYPE]: {
    label: 'type',
    placeholder: 'type',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
    hasSeparator: true,
  },
  [RooftopIntegrationDetailsFields.INTEGRATION_ID]: {
    label: 'integration_one',
    placeholder: 'integration_one',
    queryAlias: RooftopIntegration.INTEGRATION_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
  },
  [RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_SALES_NUMBER]: {
    label: 'desktop_sales_number',
    groupType: StepFieldType.PHONE_NUMBER,
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_SERVICE_NUMBER]: {
    label: 'desktop_service_number',
    groupType: StepFieldType.PHONE_NUMBER,
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_PARTS_NUMBER]: {
    label: 'desktop_parts_number',
    groupType: StepFieldType.PHONE_NUMBER,
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_SALES_NUMBER]: {
    label: 'mobile_sales_number',
    groupType: StepFieldType.PHONE_NUMBER,
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_SERVICE_NUMBER]: {
    label: 'mobile_service_number',
    groupType: StepFieldType.PHONE_NUMBER,
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_PARTS_NUMBER]: {
    label: 'mobile_parts_number',
    groupType: StepFieldType.PHONE_NUMBER,
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [RooftopIntegrationDetailsFields.ACTIVE]: {
    label: 'active',
    queryAlias: RooftopIntegration.ACTIVE,
    groupType: StepFieldType.SWITCH,
    clear: { field: RooftopIntegrationModifyParameter._active },
  },
});

export default DetailFields;
