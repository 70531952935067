import { Fragment, useCallback } from 'react';

import { css } from 'styled-components/macro';

import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';
import { BLUE_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

import type { ListSelectionSortableGroup, ListSelectionSortableProps } from './interfaces';
import { ListSelectionSortableGroupLabel } from './interfaces';
import ListSelectionSortableGroupList from './ListSelectionSortableGroupList';

const { t } = translate;
const sortableHoverCss = css`
  &:hover {
    box-shadow: inset 0 0 0 2px ${BLUE_500};
  }
`;

const ListSelectionSortable = ({
  selectedOptions = [],
  filteredOptions = [],
  onSelect = () => {},
  isMultiSelect,
  baseClassName,
  styleVariant,
  onListItemDrag,
  onDone,
  options = [],
  hasSearchKeyword,
}: ListSelectionSortableProps) => {
  const itemIsActive = useCallback(
    (itemId: string) =>
      !!selectedOptions.some(
        (selectedOption: string | SubStepOption) =>
          (selectedOption as string) === itemId || (selectedOption as SubStepOption)?.id === itemId
      ),
    [selectedOptions]
  );

  const selectedGroupOptions = selectedOptions
    .filter(selectedOption =>
      filteredOptions.find(
        filteredOption => filteredOption.id === ((selectedOption as SubStepOption)?.id || selectedOption)
      )
    )
    .map(option => (typeof option === 'string' ? options.find(({ id }) => id === option) : option))
    .filter(Boolean);
  const unselectedGroupOptions = filteredOptions.filter(item => !itemIsActive(item.id as string));
  const sortableGroups: ListSelectionSortableGroup[] = [
    {
      items: selectedGroupOptions,
      label: t(ListSelectionSortableGroupLabel.SELECTED),
      isSortable: !hasSearchKeyword,
      placeholder: {
        title: t('list_selection_sortable_placeholder_title'),
        subtitle: t('list_selection_sortable_placeholder_subtitle'),
      },
      scrollLimit: 7,
      hoverCss: sortableHoverCss,
    },
    {
      items: unselectedGroupOptions,
      label: t(ListSelectionSortableGroupLabel.UNSELECTED),
      hoverCss: sortableHoverCss,
    },
  ];

  return (
    <Fragment>
      <ListSelectionSortableGroupList
        baseClassName={baseClassName}
        group={sortableGroups}
        isMultiSelect={isMultiSelect}
        itemIsActive={itemIsActive}
        onDone={onDone}
        onListItemDrag={onListItemDrag}
        onSelect={onSelect}
        styleVariant={styleVariant}
      />
    </Fragment>
  );
};

export default ListSelectionSortable;
