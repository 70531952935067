import 'react-app-polyfill/stable';
import 'normalize.css';
import { createRoot } from 'react-dom/client';

import LoggingService from 'components/core/logging/LoggingService';
import { bootSequence as intlBootSequence } from 'utils/intlUtils';
import { fontBootSequence } from 'utils/typographyUtils';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';

// Initialize error logging
LoggingService.init();

// Initialize app
const renderApp = () => {
  const container = document.querySelector('#root');
  if (!container) {
    throw new Error('Init Error: No container to renderApp!');
  }

  // TODO [ED-3190]: Support StrictMode
  const root = createRoot(container);
  root.render(<App />);
};

const run = async () => {
  try {
    // Let's go
    await Promise.all([fontBootSequence(), intlBootSequence()]);

    renderApp();

    /**
     * If you want your app to work offline and load faster, you can change Unregister() to register() below.
     * Note this comes with some pitfalls. Learn more about service workers: https://bit.ly/CRA-PWA
     */
    serviceWorker.unregister();

    if (!process.env.REACT_APP_GRAPH_BASE) {
      throw new Error('Provide REACT_APP_GRAPH_BASE');
    }
  } catch (error: any) {
    LoggingService.error({
      message: 'Index.tsx: run error',
      error,
    });
  }
};

void run();
