import type { Dispatch, MouseEventHandler, ReactNode, SetStateAction } from 'react';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { isNil } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import type Step from 'components/core/createModify/interfaces/step';
import { Tab, Tabs } from 'components/core/navigation/shared/Tabs';
import Label from 'components/core/typography/Label';
import PrimaryText from 'components/core/typography/PrimaryText';
import ArchiveBanner from 'components/ui/details/ArchiveBanner';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import ArrowLeftIcon from 'components/ui/icons/ArrowLeftIcon';
import { HeaderSection } from 'components/ui/layouts/CardLayout';
import { Column, EntityContainer } from 'components/ui/layouts/EntityLayout';
import Loader from 'components/ui/loading/Loader';
import CollapseLinkedSectionButton from 'components/ui/menus/CollapseLinkedSectionButton';
import MenuButton, { MenuButtonType, MenuPosition } from 'components/ui/menus/MenuButton';
import IconButton, { IconButtonStyle } from 'components/ui/shared/IconButton';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import type ItemTab from 'components/ui/shared/interfaces/ItemTab';
import Scrollable from 'components/ui/shared/Scrollable';
import { VerticalDivider } from 'components/ui/shared/VerticalDivider';
import type { ExtendedEntityType } from 'enums/extendedEntityType';
import type { ScrollableType } from 'enums/scrollableType';
import { TabType } from 'enums/tabType';
import { detailsViewTabsTestId, ElementTestId } from 'enums/testing';
import { useNestedView } from 'hooks/contexts/useNestedView';
import useEditField from 'hooks/useEditField';
import useEntityView from 'hooks/useEntityView';
import { usePrevious } from 'hooks/usePrevious';
import { useRouter } from 'hooks/useRouter';
import { DIVIDER } from 'styles/color';
import { CARD_WIDTH } from 'styles/layouts';
import { NEUTRAL_0, NEUTRAL_100 } from 'styles/tokens';
import { type Intl, translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';
import { saveInitialLinkedSectionToggledState } from 'utils/persistanceUtils';

import type { CommonItemsConfigProps } from './ItemsContainer';

const { t } = translate;

const TabsContainer = styled(Tabs)<{ showBorder?: boolean }>`
  flex-shrink: 0;
  ${({ showBorder }) =>
    showBorder &&
    css`
      border-bottom: 1px solid ${DIVIDER};
    `}
`;

const EntityColumn = styled(Column)<{ ref?: any }>`
  background: ${NEUTRAL_0};
  height: 100%;
  min-width: ${CARD_WIDTH};
`;

const Container = styled.div<{ ref?: any }>`
  display: flex;
  position: relative;
  background: ${NEUTRAL_100};
  overflow: hidden;
  height: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 0%;
  background: ${NEUTRAL_0};

  &:not(:only-child) {
    &:first-child {
      margin-right: 6px;
    }

    &:last-child {
      flex: none;
      flex-shrink: 0;
      width: ${CARD_WIDTH};
    }
  }
`;

/**
 * Added the `height` and `margin-top` offset due to the current padding on our share `HeaderSection` component.
 * This can be removed once we update the padding across all `HeaderSection` component
 */
const Divider = styled(VerticalDivider)`
  height: 36px;
  margin: -5px 12px 0;
`;

export type ItemDetailsConfigProps = {
  /** The render method used to determine the title of each details section */
  detailsTitleRenderMethod?: (item: any) => string;
  detailsScrollableType?: ScrollableType;
};

interface DetailsTabsProps {
  entityType: ExtendedEntityType;
  tabs: Omit<ItemTab, 'component'>[];
  activeTab?: TabType;
  setActiveTab?: (tabId: TabType) => void;
  showBorder?: boolean;
}

export const DetailsTabs = ({ entityType, tabs, activeTab, setActiveTab, showBorder }: DetailsTabsProps) => {
  const renderedTabs = useMemo(
    () => (
      <TabsContainer showBorder={showBorder}>
        {tabs.map(({ tabId, label, labelCount }) => (
          <Tab
            data-testid={detailsViewTabsTestId(entityType, tabId)}
            isInteractive={activeTab !== tabId}
            key={tabId}
            onClick={() => setActiveTab?.(tabId)}
            selected={activeTab === tabId}
          >
            <Label>{`${t(label || (tabId as Intl))}${isNil(labelCount) ? '' : ` (${labelCount})`}`}</Label>
          </Tab>
        ))}
      </TabsContainer>
    ),
    [tabs, activeTab, setActiveTab, showBorder, entityType]
  );

  return renderedTabs;
};

interface RenderedTabProps extends ItemTabProps {
  tab: ItemTab | undefined;
}

interface Props extends Omit<CommonItemsConfigProps, 'basePath'>, ItemDetailsConfigProps {
  /** Whether or not the container is updating */
  isUpdating: boolean;
  /** Data returned by details query for a given item */
  itemData: any;
  /** ID of the item being rendered */
  itemId: string;
  /** Pre loaded item to render while we fetch updated details */
  preLoadedItem: ReactNode;
  /** List of tabs to render for item */
  tabs: ItemTab[];
  /** Menu items to render in `...` menu of details tab */
  menuItems: ReactNode;
  /** Callback to display loading spinner during update actions  */
  setIsDetailsUpdating?: Dispatch<SetStateAction<boolean>>;
  /** Whether or not the user has edit permissions */
  isEditAllowed: boolean;
  /** Callback for when a modify builder is saved  */
  onSave?: (step: Step, data: Record<string, any>) => Promise<void>;
  /** Callback when a user updates the archive status of an item */
  onUpdateArchiveStatus?: () => void;
  /** Custom banner to render above tabs */
  customBanner?: (item?: any) => JSX.Element | undefined;
  /** Whether a back button should be displayed in the header section */
  shouldDisplayBackButton?: boolean;
}

export const RenderedTab = ({ tab, item, metadata, onEdit, onSave, setIsDetailsUpdating }: RenderedTabProps) => {
  if (!tab) {
    return null;
  }

  return (
    <tab.component
      item={item}
      metadata={metadata}
      onEdit={onEdit}
      onSave={onSave}
      setIsDetailsUpdating={setIsDetailsUpdating}
    />
  );
};

/**
 * Gets a formatted title for all details sections.
 */
export const getDetailsTitle = (item?: any, entityLabel?: string) => entityLabel;

const LargeInnerScreenBreakpoint = 630;

const ItemDetailsContainer = (props: Props) => {
  const {
    itemId,
    itemData: { metadata, item: data },
    preLoadedItem,
    isUpdating = false,
    tabs,
    editBuilder,
    entityType,
    detailsTitleRenderMethod,
    menuItems,
    setIsDetailsUpdating,
    detailsScrollableType,
    isEditAllowed,
    onSave,
    onUpdateArchiveStatus,
    customBanner,
    shouldDisplayBackButton = false,
  } = useMemo(() => props, [props]);
  const { isLinkedSectionCollapsed: isCollapsed } = useEntityView();
  const { location, push: navigate, sectionPath } = useRouter();
  const { viewItems } = useNestedView();
  const itemIdPrev = usePrevious(itemId);
  const firstTabId = tabs.at(0)?.tabId;
  const defaultTabIdPrev = usePrevious(firstTabId);

  const [activeTab, setActiveTab] = useState<TabType | undefined>(firstTabId);
  const [isSplitView, setIsSplitView] = useState(false);
  const [isLinkedSectionCollapsed, setIsLinkedSectionCollapsed] = useState<boolean>(isCollapsed);
  const item = useMemo(() => (isUpdating ? preLoadedItem : data), [data, isUpdating, preLoadedItem]);
  const detailsTitle = useMemo(() => {
    const entityLabel = detailsTitleRenderMethod?.(item) || '';
    return getDetailsTitle(item, entityLabel);
  }, [item, detailsTitleRenderMethod]);
  const containerRef = useRef<HTMLElement>();

  /**
   * Handles back button navigation. Using the current pathname to navigate to the parents pathname
   */
  const handleBackButtonClick = useCallback(() => {
    const currentPathParts = location.pathname.split('/');
    const parentsPath = currentPathParts.slice(0, -1).join('/');

    return navigate(`${parentsPath}${location.search}`);
  }, [location.pathname, location.search, navigate]);

  /**
   * Handles collapsible button click. Toggle Linked section between expanded and collapsed state
   */
  const handleOnCollapsibleButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    e => {
      e.preventDefault();

      setIsLinkedSectionCollapsed(isCollapsed => {
        const shouldCollapse = !isCollapsed;

        saveInitialLinkedSectionToggledState({ forPathname: sectionPath, shouldCollapse });
        return shouldCollapse;
      });
    },
    [sectionPath]
  );

  /*
   * Callback that opens up the create/modify builder and scrolls to the field in question
   * TODO: Localize title
   */
  const onEdit = useEditField({
    tierData: {
      itemId: item?.id,
      title: item?.type?.toLowerCase(),
      type: editBuilder,
      entityType,
      onStepSave: onSave,
    },
  });

  /*
   * Have to cast here because `authorizedCallback` potentially could return null if no fallback is provided
   */
  // Permissions
  const authorizedOnEdit = authorizedCallback({
    cb: onEdit,
    isAuth: isEditAllowed,
  }) as RenderedTabProps['onEdit'];

  const detailTabs = useMemo(
    () => tabs.filter(({ tabId }) => !isSplitView || TabType.LINKED !== tabId),
    [isSplitView, tabs]
  );

  const { renderedLinkedDetails, linkedTab } = useMemo(() => {
    const tab = tabs.find(({ tabId }) => TabType.LINKED === tabId)!;

    if (!isSplitView || !tab?.component) {
      return { renderedLinkedDetails: null, linkedTab: [tab] };
    }

    const { component: TabComponent, tabId } = tab;

    return {
      renderedLinkedDetails: (
        <TabComponent
          item={item}
          key={tabId}
          metadata={metadata}
          onEdit={authorizedOnEdit}
          setIsDetailsUpdating={setIsDetailsUpdating}
        />
      ),
      linkedTab: [tab],
    };
  }, [tabs, isSplitView, item, metadata, authorizedOnEdit, setIsDetailsUpdating]);

  /**
   * Dispatch a resize event when we toggle between expanded and collapsed state of linked section. This would
   * trigger resize event in `useFlexGrid` hook, so that it would re-arrange the rows and columns
   *
   * @link https://github.com/carmigo/edealer-web-v2/blob/master/src/hooks/useFlexGrid.ts#L78-L85
   */
  useLayoutEffect(() => {
    const resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);
  }, [isCollapsed]);

  // Closing secondary view every time `itemId` changes
  useLayoutEffect(() => {
    if (itemIdPrev !== itemId || defaultTabIdPrev !== firstTabId) {
      setActiveTab(detailTabs[0]?.tabId);
    }
  }, [defaultTabIdPrev, detailTabs, firstTabId, itemId, itemIdPrev]);

  // Resize effect
  useLayoutEffect(() => {
    const onResized = () => {
      const isNowSplit = !!containerRef.current && containerRef.current.offsetWidth >= LargeInnerScreenBreakpoint;
      if (isNowSplit !== isSplitView) {
        setIsSplitView(isNowSplit);

        if (!isNowSplit && viewItems.length > 0 && tabs.some(({ tabId }) => TabType.LINKED === tabId)) {
          setActiveTab(TabType.LINKED);
        } else if (TabType.LINKED === activeTab! && (viewItems.length === 0 || isNowSplit)) {
          setActiveTab(tabs.find(({ tabId }) => tabId !== activeTab)?.tabId);
        }
      }
    };

    window.addEventListener('resize', onResized);

    // Dispatching event for resizing so it is heard by other listeners, method supports IE11
    const resizeEvent = window.document.createEvent('UIEvents') as any;
    resizeEvent.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);

    return () => window.removeEventListener('resize', onResized);
  }, [setIsSplitView, activeTab, tabs, isSplitView, viewItems, isUpdating]);

  const customBannerToRender = customBanner ? customBanner(item) : undefined;

  return (
    <EntityContainer data-testid={ElementTestId.ITEMS_DETAILS_VIEW_CONTAINER}>
      <EntityColumn ref={containerRef}>
        <HeaderSection>
          {shouldDisplayBackButton && (
            <>
              <IconButton
                data-testid={ElementTestId.ITEMS_DETAILS_VIEW_BACK_BUTTON}
                onClick={handleBackButtonClick}
                styleVariant={IconButtonStyle.SUBTLE}
                tooltip={{ children: t('back_to_list') }}
              >
                <ArrowLeftIcon />
              </IconButton>
              <Divider style={{ marginRight: '16px' }} />
            </>
          )}
          <PrimaryText>{detailsTitle}</PrimaryText>
          {/* Because visibility is determined by the `MenuButton` ref's nextChildren, 
              internal `menuItems` updates may not always update `MenuButton` state */}
          <MenuButton
            key={`menuButton-${itemId}-${isUpdating}`}
            position={MenuPosition.TOP_RIGHT}
            testId={ElementTestId.MEATBALL_MENU_BUTTON_CONTAINER}
            type={MenuButtonType.MORE}
          >
            {menuItems}
          </MenuButton>
          {isSplitView && !!renderedLinkedDetails && !isUpdating && (
            <>
              <Divider />
              <CollapseLinkedSectionButton
                isCollapsed={isLinkedSectionCollapsed}
                onClick={handleOnCollapsibleButtonClick}
              />
            </>
          )}
        </HeaderSection>
        {isUpdating ? (
          <Loader css="background: transparent;" />
        ) : (
          <Container>
            <ContentContainer>
              {isItemArchived(item) ? (
                <ArchiveBanner onUpdateArchiveStatus={onUpdateArchiveStatus} />
              ) : (
                customBannerToRender
              )}
              {detailTabs?.length > 1 && (
                <DetailsTabs
                  activeTab={activeTab}
                  entityType={entityType}
                  setActiveTab={setActiveTab}
                  tabs={detailTabs}
                />
              )}
              <Scrollable
                css="position: relative;"
                disabledX
                key={`${activeTab}-${itemId}-details`}
                scrollableType={detailsScrollableType}
              >
                <RenderedTab
                  item={data}
                  metadata={metadata}
                  onEdit={authorizedOnEdit}
                  onSave={onSave}
                  setIsDetailsUpdating={setIsDetailsUpdating}
                  tab={tabs.find(tab => tab.tabId === activeTab)}
                />
              </Scrollable>
            </ContentContainer>
            {isSplitView && !!renderedLinkedDetails && !isLinkedSectionCollapsed && !isUpdating && (
              <ContentContainer data-testid={ElementTestId.ITEMS_DETAILS_LINKED_SECTION}>
                <DetailsTabs activeTab={TabType.LINKED} entityType={entityType} showBorder={true} tabs={linkedTab} />
                <Scrollable css="position: relative;" key={`${activeTab}-${itemId}-details`}>
                  {renderedLinkedDetails}
                </Scrollable>
              </ContentContainer>
            )}
          </Container>
        )}
      </EntityColumn>
    </EntityContainer>
  );
};

export default ItemDetailsContainer;
