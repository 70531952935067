import { isObject, uniqBy } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type { StepComponentProps, StepComponentState } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore, { defaultState } from 'components/core/createModify/stepFields/StepComponentCore';
import { getGroupOptions, getRooftopOptions } from 'components/sections/shared/ItemMetaHelpers';
import { PromptDialogIcon } from 'components/ui/dialogs/PromptDialog';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { getApiErrors } from 'store/api/graph/interfaces/apiErrors';
import type { Group } from 'store/api/graph/interfaces/types';
import { UserScope } from 'store/api/graph/interfaces/types';
import {
  addDisplayType,
  defineFieldValues,
  getStepField,
  objectToStepFieldArray,
  removeDisplayType,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';
import { isEnumValue } from 'utils/typeUtils';

import { UserDetailBuilderFields } from './interfaces';

const { t } = translate;

interface UserDetailsStepState extends StepComponentState {
  scopeType?: UserScope;
}

class DetailsStep extends StepComponentCore {
  state: UserDetailsStepState = {
    ...defaultState,
    scopeType: UserScope.GLOBAL,
  };

  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { data: currentData, isCreating, activeStep, metadata, formData, seededData },
    } = props;

    const {
      subContexts: {
        userContext: {
          user: { scope },
          user,
          isWhiteLabelScoped,
        },
      },
    } = context;
    this.context = context;

    const data = formData || currentData;

    const { groupName } = data;

    const scopeOptions: StepFieldOptions[] = [
      {
        id: UserScope.GLOBAL,
        name: t('global'),
        data: { description: t('user_scope_global_message') },
      },
      {
        id: UserScope.WHITE_LABEL,
        name: t('white_label'),
        data: { description: t('user_scope_white_label_message') },
      },
      {
        id: UserScope.FULL_GROUP,
        name: t('full_group'),
        data: { description: t('user_scope_full_group_message') },
      },
      {
        id: UserScope.PARTIAL_GROUP,
        name: t('partial_group'),
        data: { description: t('user_scope_partial_group_message') },
      },
    ].filter(scopeOption => {
      switch (scopeOption.id) {
        case UserScope.GLOBAL: {
          return seededData?.groupId ? false : scope === UserScope.GLOBAL;
        }

        case UserScope.WHITE_LABEL: {
          return seededData?.groupId ? false : [UserScope.GLOBAL, UserScope.WHITE_LABEL].includes(scope);
        }

        case UserScope.FULL_GROUP: {
          return [UserScope.GLOBAL, UserScope.WHITE_LABEL, UserScope.FULL_GROUP].includes(scope);
        }

        default: {
          return [UserScope.GLOBAL, UserScope.WHITE_LABEL, UserScope.FULL_GROUP, UserScope.PARTIAL_GROUP].includes(
            scope
          );
        }
      }
    });

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [UserDetailBuilderFields.SCOPE]: {
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !isCreating },
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
        ]),
        selectedValue: UserScope.WHITE_LABEL,
        options: scopeOptions,
      },
      [UserDetailBuilderFields.ROOFTOP_IDS_LIMITED_TO]: {
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: isCreating || data.scope !== UserScope.PARTIAL_GROUP },
        ]),
        selectedValue:
          (!isCreating && data.scope === UserScope.PARTIAL_GROUP && data.rooftops) || seededData?.rooftopIdsLimitedTo,
        required: true,
      },
      [UserDetailBuilderFields.GROUP_ID]: {
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !isCreating },
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
        ]),
        /**
         * `seededData` value is set here since if you try and toggle back to the
         * Details step from the Permissions step the input placeholder will be displayed with
         * the Group's ID instead of the name itself. Same issue arises with the rooftops field.
         * More info on ED-7527.
         */
        selectedValue: seededData?.groupName,
        required: true,
      },
      [UserDetailBuilderFields.WHITE_LABEL_ID]: {
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !isCreating },
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
        ]),
        required: true,
      },
      [UserDetailBuilderFields.SUBSCRIBED_EMAIL]: {
        selectedValue: isCreating || data.subscribedEmail,
      },
      [UserDetailBuilderFields.LOCALE]: {
        selectedValue:
          data?.locale &&
          metadata.metadata.mutation?.user?.locale.find(
            ({ id }) => id === data.locale.id || id === data.locale.languageTag
          ),
      },
      [UserDetailBuilderFields.AVATAR_IMAGE]: {
        selectedValue: data.avatar?.url,
      },
    });

    // Async subpanel configurations
    const rooftopsField = getStepField(UserDetailBuilderFields.ROOFTOP_IDS_LIMITED_TO, this.fields);

    this.asyncConfigurations = {
      [UserDetailBuilderFields.ROOFTOP_IDS_LIMITED_TO]: {
        request: async keyword => {
          const whiteLabelId = this.getWhiteLabelId(groupName);

          const groupIdField = getStepField(UserDetailBuilderFields.GROUP_ID, this.fields);

          const groupId = isCreating ? groupIdField.selectedValue.id : data.groupName.id;

          const rooftops = await getRooftopOptions({
            user,
            keyword,
            isWhiteLabelScoped,
            groupId,
            whiteLabelId,
          });

          return uniqBy(
            [
              ...rooftops,
              ...(rooftopsField.selectedValue
                ?.filter(({ name: { value } }) => !keyword || value.includes(keyword))
                .map(({ id, name: { value } }) => ({ id, name: value })) || []),
            ],
            'id'
          );
        },
      },
      [UserDetailBuilderFields.GROUP_ID]: {
        request: keyword => getGroupOptions(keyword),
      },
    };

    if (isCreating) {
      this.setCurrentScope(data, scope);
    }

    // Assigning pre-defined values if available
    this.fields = defineFieldValues(this.fields, data, metadata);
  }

  /**
   *  Sets the scope of the User you are creating or modifying, determined by
   *  checking if `groupId`, `whiteLabelId` and `rooftopIdsLimitedTo` are null or not.
   *  Checks also if the user doing the editing is Partial Group scope
   *  and will limit scope options to only Partial Group if so.
   */
  private setCurrentScope(data: Record<string, any>, scope: UserScope): void {
    const scopeField = getStepField(UserDetailBuilderFields.SCOPE, this.fields);

    if (!Array.isArray(scopeField.options)) {
      return;
    }

    const scopeFieldOptions = scopeField.options;

    const globalIdIndex = scopeFieldOptions.findIndex(item => item.id === UserScope.GLOBAL);
    const whiteLabelIdIndex = scopeFieldOptions.findIndex(item => item.id === UserScope.WHITE_LABEL);
    const fullGroupIdIndex = scopeFieldOptions.findIndex(item => item.id === UserScope.FULL_GROUP);
    const partialGroupIdIndex = scopeFieldOptions.findIndex(item => item.id === UserScope.PARTIAL_GROUP);

    let scopeId: string | number | undefined;

    const isPartialGroupUser = scope === UserScope.PARTIAL_GROUP;

    if (isPartialGroupUser) {
      scopeId = scopeFieldOptions[partialGroupIdIndex].id;
    } else {
      /**
       * Check if there is set data within the User builder.
       * If so, determine the scope of the user that's been edited with the data that is returned.
       * If no data, then set `currentScope` to default `FULL_GROUP` scope since we are creating a user from scratch.
       */
      if (Object.keys(data).length > 0) {
        scopeId = data.scope?.selectedValue;

        if (data.groupId == null) {
          scopeId =
            data.whiteLabelId == null ? scopeFieldOptions[globalIdIndex].id : scopeFieldOptions[whiteLabelIdIndex].id;
        }

        if (data.groupId !== null) {
          scopeId =
            data.rooftopIdsLimitedTo == null
              ? scopeFieldOptions[fullGroupIdIndex].id
              : scopeFieldOptions[partialGroupIdIndex].id;
        }
      } else {
        scopeId = scopeFieldOptions[fullGroupIdIndex].id;
      }
    }

    // Set the scope value here
    scopeField.selectedValue = scopeId;
    const userScope = isEnumValue(UserScope, scopeId) ? scopeId : undefined;
    if (userScope) {
      this.updateFieldsToScope(userScope);
    }
  }

  /**
   * Gets the ID of the White Label that a Group is under.
   * If you are creating a new User then search with the ID of your current Group selection.
   * If you are modifying an existing User then it will just use the White Label ID
   * attached to the User's Group under `group`.
   */
  private getWhiteLabelId(group: Group): string {
    if (group) {
      return group.whiteLabel?.id;
    }

    const groupFieldWhiteLabelId = this.fields.find(({ queryVar }) => queryVar === UserDetailBuilderFields.GROUP_ID)
      ?.selectedValue?.whiteLabel?.id;

    return groupFieldWhiteLabelId;
  }

  // Toggling fields on/off depending on the selected scope
  updateFieldsToScope = (type: UserScope) => {
    const {
      tier: { isCreating, seededData },
    } = this.props;

    const {
      subContexts: {
        userContext: {
          user: { scope, whiteLabel, group, rooftops },
        },
      },
    } = this.context!;

    // List of fields available to each scope
    const visibleFields = {
      [UserScope.WHITE_LABEL]: [UserDetailBuilderFields.WHITE_LABEL_ID],
      [UserScope.FULL_GROUP]: [UserDetailBuilderFields.GROUP_ID],
      [UserScope.PARTIAL_GROUP]: [UserDetailBuilderFields.GROUP_ID, UserDetailBuilderFields.ROOFTOP_IDS_LIMITED_TO],
    };

    // Disabling unnecessary fields and enabling used ones
    for (const fieldId of [
      UserDetailBuilderFields.WHITE_LABEL_ID,
      UserDetailBuilderFields.GROUP_ID,
      UserDetailBuilderFields.ROOFTOP_IDS_LIMITED_TO,
    ]) {
      const targetField = getStepField(fieldId, this.fields);

      if (visibleFields[type]?.includes(fieldId)) {
        targetField.displayType = removeDisplayType(targetField.displayType, StepFieldDisplayType.HIDDEN);

        // Special case, rooftopIds should be disabled if `groupId` has no value
        if (fieldId === UserDetailBuilderFields.GROUP_ID) {
          const rooftopIdsField = getStepField(UserDetailBuilderFields.ROOFTOP_IDS_LIMITED_TO, this.fields);

          setDisplayTypes(
            [{ type: StepFieldDisplayType.DISABLED, active: !targetField.selectedValue }],
            rooftopIdsField
          );
        }

        switch (fieldId) {
          case UserDetailBuilderFields.GROUP_ID: {
            const defaultGroup = seededData?.groupId || group;
            const rooftopIdsField = getStepField(UserDetailBuilderFields.ROOFTOP_IDS_LIMITED_TO, this.fields);

            setDisplayTypes(
              [{ type: StepFieldDisplayType.DISABLED, active: !targetField.selectedValue }],
              rooftopIdsField
            );
            setDisplayTypes(
              [
                {
                  type: StepFieldDisplayType.DISABLED,
                  active: !!(
                    [UserScope.FULL_GROUP, UserScope.PARTIAL_GROUP].includes(scope) ||
                    (isCreating && !!seededData?.groupId)
                  ),
                },
              ],
              targetField
            );

            targetField.selectedValue = targetField.displayType.includes(StepFieldDisplayType.DISABLED)
              ? defaultGroup
              : targetField.selectedValue;
            break;
          }

          case UserDetailBuilderFields.ROOFTOP_IDS_LIMITED_TO: {
            const isDisabled = targetField.displayType?.includes(StepFieldDisplayType.DISABLED);

            /*
             * If the rooftop field is not already disabled, it should be disabled if the user creating
             * this entity has a PARTIAL_SCOPE
             */
            if (!isDisabled) {
              setDisplayTypes(
                [{ type: StepFieldDisplayType.DISABLED, active: scope === UserScope.PARTIAL_GROUP }],
                targetField
              );
            }

            targetField.selectedValue = targetField.displayType?.includes(StepFieldDisplayType.DISABLED)
              ? rooftops
              : targetField.selectedValue;
            break;
          }

          case UserDetailBuilderFields.WHITE_LABEL_ID: {
            setDisplayTypes(
              [{ type: StepFieldDisplayType.DISABLED, active: scope === UserScope.WHITE_LABEL }],
              targetField
            );

            targetField.selectedValue = targetField.displayType.includes(StepFieldDisplayType.DISABLED)
              ? whiteLabel
              : targetField.selectedValue;
            break;
          }
        }

        /*
         * Hiding the separator in the case of partial groups where `groupId` and `rooftopId` are grouped
         * together by design
         */
        targetField.hasSeparator =
          type !== UserScope.PARTIAL_GROUP || fieldId === UserDetailBuilderFields.ROOFTOP_IDS_LIMITED_TO;
      } else {
        addDisplayType(targetField, StepFieldDisplayType.HIDDEN);
        targetField.selectedValue = null;
      }
    }
  };

  // Overriding field selection callback
  onFieldSelection(stepField: StepField, value: any) {
    super.onFieldSelection(stepField, value);

    if (stepField.queryVar === UserDetailBuilderFields.GROUP_ID) {
      const rooftopIdsField = getStepField(UserDetailBuilderFields.ROOFTOP_IDS_LIMITED_TO, this.fields);
      const newFieldValue = getStepField(stepField.queryVar, this.fields).selectedValue;

      setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !newFieldValue }, rooftopIdsField);

      if (!newFieldValue) {
        rooftopIdsField.selectedValue = null;
      }

      this.setTier({ seededData: { ...this.props.tier.seededData, groupName: value, rooftopIdsLimitedTo: null } });
    }

    if (stepField.queryVar === UserDetailBuilderFields.ROOFTOP_IDS_LIMITED_TO) {
      this.setTier({ seededData: { ...this.props.tier.seededData, rooftopIdsLimitedTo: value } });
    }

    if (stepField.queryVar === UserDetailBuilderFields.SCOPE) {
      const userScope: UserScope | undefined = isEnumValue(UserScope, value.id) ? value.id : undefined;
      if (userScope) {
        this.updateFieldsToScope(userScope);
      }
    }
  }

  /**
   * If the user is editing themselves and has changed their email, show a warning prompt.
   *
   * @returns true if they did change it, and false otherwise.
   */
  showPromptIfUserChangedEmail() {
    const {
      tier: { data },
      onStepComplete,
      toggleClosePrompt,
    } = this.props;

    const {
      subContexts: {
        userContext: { user },
      },
    } = this.context!;

    if (user.id === data.id) {
      const emailField = getStepField(UserDetailBuilderFields.EMAIL, this.fields);
      if (emailField.selectedValue?.trim() !== data.email) {
        toggleClosePrompt({
          title: t('update_email'),
          message: t('change_email_message'),
          messageIcon: PromptDialogIcon.WARNING,
          confirmText: t('yes_continue'),
          cancelText: t('dont_update'),
          onConfirm: async () => {
            try {
              await super.save();
              return true;
            } catch (error) {
              this.setTier({ errors: getApiErrors(error) });
              return false;
            }
          },
          onClose: () => {
            toggleClosePrompt(undefined);
          },
          onComplete: success => {
            // On complete, close this prompt
            toggleClosePrompt(undefined);
            // If the user clicked Yes, clear the close prompt and mark this step complete
            if (success) {
              this.setOnClosePrompt(undefined);
              onStepComplete(true);
            }
            // Otherwise, reset the close prompt to default settings
            else {
              this.setOnClosePrompt(this.defaultClosePrompt);
            }
          },
          onCancel: () => {
            this.setOnClosePrompt(undefined);
          },
        });
        return true;
      }
    }

    return false;
  }

  async save() {
    const {
      tier: { steps },
    } = this.props;

    const avatarField = getStepField(UserDetailBuilderFields.AVATAR_IMAGE, this.fields);

    const userUpdatedEmail = this.showPromptIfUserChangedEmail();
    if (userUpdatedEmail) {
      return false;
    }

    const success = await super.save(
      {},
      {
        // If the avatar is not a File, then send null, as the avatar image has not been changed
        avatarImage: isObject(avatarField.selectedValue) ? avatarField.selectedValue : null,
      }
    );
    if (!success) {
      return false;
    }

    const permissionsStep = steps!.find(step => step.id === 'USER_PERMISSIONS');
    if (permissionsStep) {
      permissionsStep.isEnabled = true;
    }

    return true;
  }
}

export default DetailsStep;
