import { memo, type SVGProps } from 'react';

const PercentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M7.453 11.114A3.51 3.51 0 0 1 4.87 10.04a3.536 3.536 0 0 1-1.067-2.588c0-1.011.355-1.872 1.067-2.583.71-.712 1.572-1.067 2.583-1.067 1.011 0 1.874.355 2.588 1.067a3.51 3.51 0 0 1 1.072 2.583 3.527 3.527 0 0 1-1.072 2.588 3.527 3.527 0 0 1-2.588 1.072Zm0-2.276c.385 0 .712-.134.981-.404.27-.27.405-.596.405-.981 0-.386-.135-.71-.405-.977a1.346 1.346 0 0 0-.981-.398c-.385 0-.71.133-.977.398a1.328 1.328 0 0 0-.398.976c0 .386.133.713.398.982.266.27.592.405.977.405Zm9.094 11.365a3.53 3.53 0 0 1-2.588-1.07 3.52 3.52 0 0 1-1.072-2.586c0-1.01.357-1.872 1.072-2.585a3.531 3.531 0 0 1 2.588-1.07c1.011 0 1.873.357 2.587 1.071a3.524 3.524 0 0 1 1.07 2.586 3.52 3.52 0 0 1-1.072 2.585 3.525 3.525 0 0 1-2.585 1.07Zm0-2.275c.386 0 .71-.134.977-.403a1.34 1.34 0 0 0 .398-.978c0-.384-.133-.71-.398-.978a1.323 1.323 0 0 0-.977-.402c-.384 0-.712.135-.981.403a1.33 1.33 0 0 0-.405.98c0 .383.135.709.405.977.27.267.597.401.981.401Zm-11.954 1.48a1.078 1.078 0 0 1-.317-.796c0-.32.105-.584.317-.796l13.235-13.23c.211-.21.476-.316.793-.314a1.085 1.085 0 0 1 1.11 1.11c0 .315-.106.579-.318.79L6.183 19.408a1.08 1.08 0 0 1-.795.316c-.32 0-.584-.105-.795-.317Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(PercentIcon);
