import { memo, type SVGProps } from 'react';

const DollarSignCircledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M12 22.203a9.952 9.952 0 0 1-3.984-.802 10.295 10.295 0 0 1-3.237-2.18 10.294 10.294 0 0 1-2.18-3.237A9.952 9.952 0 0 1 1.797 12c0-1.415.267-2.743.802-3.984A10.295 10.295 0 0 1 4.78 4.78a10.295 10.295 0 0 1 3.237-2.18A9.952 9.952 0 0 1 12 1.797c1.415 0 2.743.267 3.984.802 1.24.535 2.32 1.262 3.237 2.18a10.295 10.295 0 0 1 2.18 3.237A9.952 9.952 0 0 1 22.203 12a9.952 9.952 0 0 1-.802 3.984 10.295 10.295 0 0 1-2.18 3.237 10.295 10.295 0 0 1-3.237 2.18 9.952 9.952 0 0 1-3.984.802Zm0-2.275c2.217 0 4.093-.767 5.627-2.3 1.534-1.535 2.301-3.41 2.301-5.628 0-2.217-.767-4.093-2.3-5.627-1.535-1.534-3.41-2.301-5.628-2.301-2.217 0-4.093.767-5.627 2.3C4.839 7.908 4.072 9.783 4.072 12c0 2.217.767 4.093 2.3 5.627 1.535 1.534 3.41 2.301 5.628 2.301ZM11.975 19c.245 0 .46-.087.646-.26a.848.848 0 0 0 .277-.645v-.31c.825-.15 1.54-.474 2.144-.974.604-.5.906-1.254.906-2.261 0-.712-.202-1.365-.606-1.958-.404-.593-1.212-1.106-2.424-1.54-.996-.333-1.682-.625-2.057-.875-.376-.25-.563-.585-.563-1.007 0-.41.149-.732.447-.968.299-.236.722-.354 1.27-.354.336 0 .629.056.877.169.248.112.458.273.628.48.179.217.378.358.598.425.22.067.429.053.624-.042a.921.921 0 0 0 .518-.546.75.75 0 0 0-.08-.695 3.703 3.703 0 0 0-.976-.972 2.624 2.624 0 0 0-1.256-.453v-.309a.841.841 0 0 0-.28-.649.925.925 0 0 0-.643-.256.925.925 0 0 0-.643.256.841.841 0 0 0-.28.649v.31c-.85.19-1.503.565-1.962 1.123-.458.558-.688 1.179-.688 1.862 0 .8.236 1.447.709 1.942.472.495 1.226.926 2.26 1.292.999.36 1.695.685 2.09.977.394.292.591.672.591 1.139 0 .534-.186.926-.558 1.177-.371.25-.836.375-1.394.375-.425 0-.809-.1-1.151-.3-.342-.2-.628-.503-.857-.908a1.162 1.162 0 0 0-.558-.508.89.89 0 0 0-.689-.003.904.904 0 0 0-.536.511.846.846 0 0 0 .005.717c.27.57.633 1.035 1.087 1.393.454.359.988.602 1.601.732v.347c0 .253.093.47.277.649a.9.9 0 0 0 .646.268Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(DollarSignCircledIcon);
