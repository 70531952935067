import { memo, type SVGProps } from 'react';

const ListNumberedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 20 20" width={20} {...props}>
    <path
      d="M3.125 18.333a.607.607 0 0 1-.625-.625.607.607 0 0 1 .625-.625h1.458v-.625h-.625a.607.607 0 0 1-.625-.625.607.607 0 0 1 .625-.625h.625v-.625H3.125a.607.607 0 0 1-.625-.625.607.607 0 0 1 .625-.625H5c.236 0 .434.08.594.24.16.16.24.358.24.594V15c0 .236-.08.434-.24.594a.806.806 0 0 1-.594.24.81.81 0 0 1 .594.239c.16.16.24.358.24.594v.833c0 .236-.08.434-.24.594a.806.806 0 0 1-.594.24H3.125Zm0-5.833a.607.607 0 0 1-.625-.625v-1.667c0-.236.08-.434.24-.593.16-.16.357-.24.593-.24h1.25V8.75H3.125a.607.607 0 0 1-.625-.625.607.607 0 0 1 .625-.625H5c.236 0 .434.08.594.24.16.16.24.357.24.593v1.459c0 .236-.08.434-.24.594a.806.806 0 0 1-.594.24H3.75v.624h1.458a.607.607 0 0 1 .625.625.607.607 0 0 1-.625.625H3.125Zm1.25-5.833a.607.607 0 0 1-.625-.625V2.917h-.625a.607.607 0 0 1-.625-.625.607.607 0 0 1 .625-.625h1.25A.607.607 0 0 1 5 2.292v3.75a.607.607 0 0 1-.625.625Zm3.958 9.166a.806.806 0 0 1-.593-.24A.806.806 0 0 1 7.5 15c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24h8.334c.236 0 .434.08.593.24.16.16.24.358.24.594s-.08.434-.24.594a.806.806 0 0 1-.593.24H8.333Zm0-5a.806.806 0 0 1-.593-.24A.806.806 0 0 1 7.5 10c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24h8.334c.236 0 .434.08.593.24.16.16.24.358.24.594s-.08.434-.24.594a.806.806 0 0 1-.593.24H8.333Zm0-5a.806.806 0 0 1-.593-.24A.806.806 0 0 1 7.5 5c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24h8.334c.236 0 .434.08.593.24.16.16.24.358.24.594s-.08.434-.24.594a.806.806 0 0 1-.593.24H8.333Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(ListNumberedIcon);
