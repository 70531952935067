import { useEffect, useRef } from 'react';

import { NetworkStatus, useQuery } from '@apollo/client';
import { merge } from 'lodash-es';
import { useParams } from 'react-router-dom';

import Loader from 'components/ui/loading/Loader';
import { CardContainer } from 'containers/ItemsContainer';
import NestedViewContainer from 'containers/nestedView/NestedViewContainer';
import { groupSettingsDetailsQuery, rooftopSettingsDetailsQuery } from 'containers/settings/SettingsQuery';
import type { NestedViewContextInterface } from 'contexts/NestedViewContext';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { withSearch } from 'hooks/contexts/withSearch';
import type {
  GroupSettingsDetailsQuery,
  GroupSettingsDetailsQueryVariables,
  RooftopSettingsDetailsQuery,
  RooftopSettingsDetailsQueryVariables,
} from 'store/api/graph/interfaces/types';
import { type Intl, translate } from 'utils/intlUtils';

const { t } = translate;

interface QueryVarProps {
  group?: GroupSettingsDetailsQuery['group'];
  rooftop?: RooftopSettingsDetailsQuery['rooftop'];
}

interface Props extends Pick<Parameters<NestedViewContextInterface['setView']>[0], 'entityType' | 'viewType'> {
  /**
   * A callback that sets the query variables for the nested view container.
   * This callback is used to pass the group or rooftop data to the nested view container.
   */
  setQueryVars: (props: QueryVarProps) => Record<string, any>;
  /**
   * A key to use for the title of the nested view.
   * This is used to translate the title of the nested view.
   */
  titleKey?: Intl;
}

const SettingsContentContainer = ({ entityType, setQueryVars, titleKey, viewType }: Props) => {
  const { groupId, rooftopId } = useParams();
  const { setView, viewItems } = useNestedView();
  const setViewRef = useRef(setView);
  const viewItem = viewItems.at(0);

  /**
   * Fetches the group details from the API.
   * This is done to get the group name, id and feature bundle, which are used to render the nested view container.
   */
  const { data: groupData, networkStatus: groupNetworkStatus } = useQuery<
    GroupSettingsDetailsQuery,
    GroupSettingsDetailsQueryVariables
  >(groupSettingsDetailsQuery, {
    skip: !groupId,
    variables: { id: groupId || '' },
  });

  /**
   * Fetches the rooftop details from the API.
   * This is done to get the rooftop name, id and feature bundle, which are used to render the nested view container.
   */
  const { data: rooftopData, networkStatus: rooftopNetworkStatus } = useQuery<
    RooftopSettingsDetailsQuery,
    RooftopSettingsDetailsQueryVariables
  >(rooftopSettingsDetailsQuery, {
    skip: !rooftopId,
    variables: { id: rooftopId || '' },
  });

  const isReady = rooftopNetworkStatus === NetworkStatus.ready || groupNetworkStatus === NetworkStatus.ready;

  /**
   * Sets the view item when the query is ready.
   * This is done to ensure that the view item is set correctly
   * and that the nested view container is rendered with the correct props.
   */
  useEffect(() => {
    if (isReady && (rooftopData?.rooftop || groupData?.group)) {
      const group = groupData?.group;
      const rooftop = rooftopData?.rooftop;

      /**
       * Some nested views list read from `groupName`
       * @link {https://github.com/carmigo/edealer-web-v2/blob/master/src/containers/nestedView/NestedViewSettings.ts#L568}
       */
      const extendedRooftop = merge({}, rooftop, {
        groupName: rooftop?.group,
      });

      setViewRef.current({
        entityType,
        viewType,
        title: titleKey ? t(titleKey) : '',
        queryVars: setQueryVars({ group, rooftop }),
        data: { group, rooftop: extendedRooftop },
        replaceViewItems: true,
      });
    }
  }, [entityType, groupData?.group, isReady, rooftopData?.rooftop, setQueryVars, titleKey, viewType]);

  if (
    (rooftopId && rooftopNetworkStatus === NetworkStatus.loading) ||
    (groupId && groupNetworkStatus === NetworkStatus.loading)
  ) {
    return <Loader />;
  }

  if (
    (rooftopId && rooftopNetworkStatus !== NetworkStatus.ready) ||
    (groupId && groupNetworkStatus !== NetworkStatus.ready) ||
    !viewItem
  ) {
    return null;
  }

  return (
    <CardContainer>
      {/**
       * Manually set key based on dependencies, so react automatically mounts/unmounts
       * the component such that rules of react are followed.
       */}
      <NestedViewContainer
        hideBackButtonOnFirstStep
        key={`NestedViewContainer-${viewItem.entityType}-${viewItem.type}`}
      />
    </CardContainer>
  );
};

export default withSearch(SettingsContentContainer, {});
