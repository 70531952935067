import type { MouseEvent, ReactNode } from 'react';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import { GlobalAdminFilterProperties } from 'components/ui/filters/interfaces/globalFilter';
import SearchInput from 'components/ui/forms/shared/SearchInput';
import CloseIcon from 'components/ui/icons/CloseIcon';
import FilterSlidersIcon from 'components/ui/icons/FilterSlidersIcon';
import { HeaderSection } from 'components/ui/layouts/CardLayout';
import GlobalRooftopGroupSelector from 'components/ui/menus/GlobalRooftopGroupSelector';
import ToggleFilters from 'components/ui/menus/ToggleFilters';
import { ItemViewType } from 'enums/ItemViewType';
import type { RoutePath } from 'enums/routePath';
import { ElementTestId } from 'enums/testing';
import { useSearch } from 'hooks/contexts/useSearch';
import { useUser } from 'hooks/contexts/useUser';
import { useRouter } from 'hooks/useRouter';
import { withTooltip } from 'hooks/withTooltip';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';
import { CARD_WIDTH } from 'styles/layouts';
import { SPACE_200, SPACE_300 } from 'styles/tokens';
import { Z_INDEX_4 } from 'styles/z-index';
import { isGlobalFiltersAvailableForRoute } from 'utils/filterUtils';
import { translate } from 'utils/intlUtils';
import { getInitialFacetFilterToggledState } from 'utils/persistanceUtils';
import { getTableColumnParams } from 'utils/urlUtils';

import { PrimaryArrowPosition, SecondaryArrowPosition, TooltipStyle } from '../shared/Tooltip';

import EditButton from './EditButton';
import MenuButton, { MenuPosition } from './MenuButton';

export type ItemSearchFiltersStatusType = { isOpen: boolean };

const SearchFiltersContainer = styled(HeaderSection)<{ isOpen: boolean; viewMode: ItemViewType }>`
  column-gap: ${SPACE_200};
  padding: ${SPACE_300};
  flex-direction: row;
  z-index: ${Z_INDEX_4};

  /* The second child is reserved for the search field input */
  > :nth-child(2) {
    max-width: 100%;
  }

  ${({ isOpen, viewMode }) =>
    !isOpen &&
    viewMode === ItemViewType.SPLIT_VIEW &&
    css`
      width: ${CARD_WIDTH};
    `};
`;

const ToggleFiltersWithTooltip = withTooltip(ToggleFilters);

const urlColumns = getTableColumnParams(window.location.search);

const GlobalRooftopAndGroupFilters = () => {
  const { globalSearchParams, updateGlobalSearchParams } = useSearch();

  return (
    <GlobalRooftopGroupSelector
      onGroupSelected={item => updateGlobalSearchParams(GlobalAdminFilterProperties.GROUPS, item)}
      onRooftopSelected={items =>
        updateGlobalSearchParams(GlobalAdminFilterProperties.ROOFTOPS, items?.length ? items : undefined)
      }
      savedItems={globalSearchParams}
    />
  );
};

interface Props {
  viewMode: ItemViewType;
  title: string;
  filtersOpenStatus: ItemSearchFiltersStatusType;
  setHasFiltersOpen: (isOpen: boolean) => void;
  activeFiltersCount: number;
  searchParams: any;
  updateSearchParam: (name: string, value: string) => void;
  onToggleEditMode?: (event: MouseEvent<HTMLButtonElement, MouseEvent>, active: boolean) => Promise<void>;
  isUpdating?: boolean;
  isEditAllowed: boolean;
  menuItems?: ReactNode;
  hasSelections?: boolean;
}

const ItemSearchFilters = ({
  viewMode,
  title,
  filtersOpenStatus,
  setHasFiltersOpen,
  activeFiltersCount,
  searchParams,
  updateSearchParam,
  onToggleEditMode,
  isUpdating,
  isEditAllowed,
  menuItems,
  hasSelections = false,
}: Props) => {
  const router = useRouter();
  const { isWhiteLabelScoped, hasPermissions } = useUser();

  const [isColumnsParamInUrl, setIsColumnsParamInUrl] = useState(false);

  // Tooltip
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const tooltipLabel = useMemo(
    () => translate.tPlural('facet_filters_affected_results_tooltip', activeFiltersCount, [activeFiltersCount]),
    [activeFiltersCount]
  );

  const { isOpen: hasFiltersOpen } = filtersOpenStatus || {
    hasFiltersOpen: getInitialFacetFilterToggledState({ forPathname: router.sectionPath }),
  };

  useEffect(() => {
    if (urlColumns.length > 0) {
      setIsColumnsParamInUrl(true);
    }
  }, [isColumnsParamInUrl]);

  /**
   * When filter open status changes, dispatch `resize` event to
   * properly update any UI dependent on changes in screen size.
   *
   * e.g. To format grid cells properly.
   */
  useLayoutEffect(() => {
    const resizeEvent = window.document.createEvent('UIEvents') as any;
    resizeEvent.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);
  }, [hasFiltersOpen]);

  return (
    <SearchFiltersContainer isOpen={hasFiltersOpen} viewMode={viewMode}>
      <ToggleFiltersWithTooltip
        activeFiltersCount={activeFiltersCount}
        icons={{
          openIcon: <FilterSlidersIcon />,
          closedIcon: <CloseIcon />,
        }}
        isOpen={hasFiltersOpen}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        setIsOpen={setHasFiltersOpen}
        testId={ElementTestId.FACET_FILTER_TOGGLE_BUTTON}
        tooltip={{
          shouldShow: showTooltip,
          styleVariant: TooltipStyle.NORMAL,
          isAnimated: true,
          children: tooltipLabel,
          width: 100,
          margin: { x: 8, y: 8 },
          arrowPosition: { primary: PrimaryArrowPosition.BOTTOM, secondary: SecondaryArrowPosition.LEFT },
          wrapComponent: true,
        }}
      />
      {isWhiteLabelScoped &&
        hasPermissions([{ resource: ResourceType.ROOFTOPS, level: AccessLevel.BASIC }]) &&
        isGlobalFiltersAvailableForRoute(router.sectionPath as RoutePath) && <GlobalRooftopAndGroupFilters />}
      <SearchInput
        defaultValue={searchParams.keyword}
        onChange={keyword => updateSearchParam('keyword', keyword.trim() || undefined)}
        placeholder={translate.t('search_x', [title])}
        testId={ElementTestId.FILTER_PANEL_SEARCH_INPUT}
      />
      {menuItems && viewMode === ItemViewType.TABLE_VIEW && (
        <MenuButton
          disabled={hasSelections}
          position={MenuPosition.TOP_RIGHT}
          testId={ElementTestId.MENU_BULK_ACTIONS_CONTAINER}
          text={translate.t('bulk_action_other')}
        >
          {menuItems}
        </MenuButton>
      )}
      {isEditAllowed && (
        <EditButton
          disabled={isUpdating}
          isColumnsParamInUrl={isColumnsParamInUrl}
          isHidden={viewMode !== ItemViewType.TABLE_VIEW}
          onClick={(event, active) => void onToggleEditMode?.(event, active)}
        />
      )}
    </SearchFiltersContainer>
  );
};

export default ItemSearchFilters;
