import { memo, useMemo } from 'react';
import 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText, { SecondaryMultiLineText } from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import ConversationsIcon from 'components/ui/icons/ConversationsIcon';
import TestDriveIcon from 'components/ui/icons/TestDriveIcon';
import UserIcon from 'components/ui/icons/UserIcon';
import WalkInIcon from 'components/ui/icons/WalkInIcon';
import Image from 'components/ui/images/Images';
import {
  ListItem,
  ListItemDetails,
  ListItemSeparator,
  MAX_LIST_ITEM_TEXT_LENGTH,
} from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import { ArchiveBadge } from 'components/ui/shared/ArchiveBadge';
import Badge, { BadgeColors, Badges } from 'components/ui/shared/Badge';
import { RooftopBadges } from 'components/ui/shared/RooftopBadges';
import { ImageSize, ImageType } from 'enums/imageType';
import { StatusColors } from 'enums/statusColors';
import type { LeadActivityCondensedListQuery } from 'store/api/graph/interfaces/types';
import type { LeadResponseType } from 'store/api/graph/responses/responseTypes';
import { formatFullName, formatTextForPreview } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';
import { truncate } from 'utils/stringUtils';

const { t } = translate;

export const LeadListItemDetails = ({ displayName, firstName, lastName, summary, phoneNumber }: LeadResponseType) => (
  <>
    <TextRow>
      <PrimaryText>{formatFullName({ firstName, lastName, displayName })}</PrimaryText>
    </TextRow>
    {(!!firstName || !!lastName) && phoneNumber && (
      <TextRow>
        <SecondaryText>{phoneNumber}</SecondaryText>
      </TextRow>
    )}
    {summary && (
      <SecondaryMultiLineText>{formatTextForPreview(summary, MAX_LIST_ITEM_TEXT_LENGTH)}</SecondaryMultiLineText>
    )}
  </>
);

type LeadListItemTagsProps =
  | LeadResponseType
  | LeadActivityCondensedListQuery['connection']['edges'][0]['node']['leadName'];

export const LeadListItemTags = ({
  status,
  rooftopName,
  statusName,
  conversationsCount,
  walkInCount,
  testDriveCount,
  assignedToName,
  ...lead
}: LeadListItemTagsProps) => (
  <TextRow>
    <Badges>
      {(lead as LeadResponseType).subscribedEmail === false && (
        <Badge color={BadgeColors.NEGATIVE} title={`${t('email_subscription')}`}>
          {t('unsubscribed')}
        </Badge>
      )}
      <ArchiveBadge isArchived={isItemArchived(lead)} />
      <Badge color={StatusColors[status]} title={`${t('status')}: ${statusName}`}>
        {statusName}
      </Badge>
      <Badge title={`${t('conversations')}: ${conversationsCount}`}>
        <ConversationsIcon css="margin-right: 1ch;" height={10} width={10} />
        {conversationsCount}
      </Badge>
      <Badge title={`${t('walk_ins')}: ${walkInCount}`}>
        <WalkInIcon css="margin-right: 1ch;" height={10} width={10} />
        {walkInCount}
      </Badge>
      <Badge title={`${t('test_drives')}: ${testDriveCount}`}>
        <TestDriveIcon css="margin-right: 1ch;" height={10} width={10} />
        {testDriveCount}
      </Badge>
      {!!assignedToName?.displayName && (
        <Badge title={`${t('assigned_to')}: ${assignedToName?.displayName}`}>
          <UserIcon css="margin-right: 1ch;" height={10} width={10} />
          {truncate(assignedToName?.displayName)}
        </Badge>
      )}
      <RooftopBadges value={rooftopName} />
    </Badges>
  </TextRow>
);

interface LeadListItemProps extends LeadResponseType, ListItemProps {}

const LeadListItem = ({ suffixIcon, listItemType, ...lead }: LeadListItemProps) => {
  const { firstName, rgbHex, lastName, avatar } = useMemo(() => lead, [lead]);

  return (
    <ListItem isArchived={isItemArchived(lead)} listItemType={listItemType} suffixIcon={suffixIcon}>
      <Image
        fallbackSrc={{ firstName, lastName, rgbHex }}
        size={ImageSize.LIST_ITEM}
        src={avatar?.url}
        type={ImageType.USER}
      />
      <ListItemDetails>
        <LeadListItemDetails {...lead} />
        <LeadListItemTags {...lead} />
      </ListItemDetails>
      <ListItemSeparator />
    </ListItem>
  );
};

export default memo(LeadListItem);
