import { memo, useCallback } from 'react';

import { cloneDeep } from 'lodash-es';

import type { MonetaryFieldDataTypeSettings } from 'components/sections/shared/ItemGridCell';
import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import {
  RooftopLeasePricingSegment,
  RooftopLeasePricingSegmentSettings,
  RooftopPricingSegment,
} from 'enums/columns/rooftopPricingSegments';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const { t } = translate;

const {
  NAME,
  DESCRIPTION,
  ORDER,
  CONFIGURATION_STARTING_PRICE_FIELD_NAME,
  CONFIGURATION_ENABLED,
  CONFIGURATION_DISCLAIMER,
  CONFIGURATION_INCLUDE_TAX,
  CONFIGURATION_INCLUDE_FREIGHT,
  CONFIGURATION_MIN_DISPLAY_PRICE,
  TARGETS,
  CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE_FIELD_NAME,
  CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL,
  CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE,
} = RooftopPricingSegment;

const { CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES, CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY } =
  RooftopLeasePricingSegment;

const { BOLD, RICH_TEXT } = ItemGridRowDisplayType;

const gridConfig = (hasAutoPriceAdjustment: boolean): ItemGridRowProps[] => [
  { fields: [NAME, CONFIGURATION_ENABLED, TARGETS], displayType: [BOLD], canEdit: true },
  {
    fields: [
      CONFIGURATION_STARTING_PRICE_FIELD_NAME,
      ...(hasAutoPriceAdjustment
        ? [CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE_FIELD_NAME, CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL]
        : []),
    ],
    displayType: [BOLD],
    canEdit: true,
  },
  {
    fields: hasAutoPriceAdjustment ? [CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE] : [],
    displayType: [BOLD],
    canEdit: true,
  },
  { fields: [CONFIGURATION_INCLUDE_TAX, CONFIGURATION_INCLUDE_FREIGHT], displayType: [BOLD], canEdit: true },
  {
    fields: [ORDER, CONFIGURATION_MIN_DISPLAY_PRICE],
    displayType: [BOLD],
    canEdit: true,
    cellSettings: { disableColorHighlighting: true } as MonetaryFieldDataTypeSettings,
  },
  { fields: [CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES], displayType: [BOLD], canEdit: true },
  { fields: [CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY], displayType: [BOLD], canEdit: true },
  { fields: [DESCRIPTION], displayType: [RICH_TEXT], canEdit: true },
  { fields: [CONFIGURATION_DISCLAIMER], displayType: [RICH_TEXT], canEdit: true },
];

export const LeaseSegmentDetailsTab = memo<ItemTabProps>(({ item, metadata, onEdit }) => {
  const onEditCallback = useCallback(
    (field?: string) => onEdit(field, `${t('pricing_segment_one')} (${t('lease')})`, cloneDeep(item)),
    [onEdit, item]
  );

  return item ? (
    <>
      {gridConfig(!!item?.configuration?.autoPriceAdjustmentConfiguration).map(config => (
        <ItemGridRowSection
          config={config}
          item={item}
          key={formatItemGridRowSectionKey(config.fields)}
          metadata={metadata}
          onEdit={authorizedCallback({
            cb: onEditCallback,
            isAuth: !!onEdit,
          })}
          settings={RooftopLeasePricingSegmentSettings}
        />
      ))}
    </>
  ) : null;
});
