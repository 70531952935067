import { memo, useCallback, useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { cloneDeep, get, isNil } from 'lodash-es';
import { css } from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import { isBannerLinksToVdpFieldVisible } from 'components/sections/createModify/retailBulkAdjustment/utils';
import type { PercentageFieldDataTypeSettings } from 'components/sections/shared/ItemGridCell';
import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import Image from 'components/ui/images/Images';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { BuilderType } from 'enums/builderType';
import { RetailBulkAdjustment, RetailBulkAdjustmentSettings } from 'enums/columns/retailBulkAdjustment';
import { FeatureBundleSet } from 'enums/featureBundle';
import { FieldDataType } from 'enums/fieldDataType';
import { ImageSize, ImageType } from 'enums/imageType';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useUser } from 'hooks/contexts/useUser';
import useEditField from 'hooks/useEditField';
import type { RetailBulkAdjustment as RetailBulkAdjustmentGqlType } from 'store/api/graph/interfaces/types';
import {
  EntityType,
  PaymentOption,
  RetailBulkAdjustmentType,
  RetailPriceAdjustmentType,
} from 'store/api/graph/interfaces/types';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { getFormattedFixedPrice, getFormattedPercentagePrice } from 'utils/formatting/createModifyFormatUtils';
import { formatCurrency, PLACEHOLDER } from 'utils/formatUtils';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const { BOLD, RICH_TEXT } = ItemGridRowDisplayType;

const { t } = translate;

/**
 * Filter Bulk Price Adjustment fields based on whether the Luxury Tax has been enabled. Will return true if the field
 * is going to be shown, false if it should be hidden.
 *
 * @param field - The field in the bulk price adjustment
 * @param isLuxuryTaxEnabled - Whether this price adjustment has the luxury tax enabled
 * @param isFeeAdjustment - Whether this price adjustment is a fee, false would mean it's a discount
 */
const filterPriceAdjustmentFieldsForLuxuryTax = (
  field: ItemGridRowProps,
  isLuxuryTaxEnabled: boolean,
  isFeeAdjustment: boolean
): boolean => {
  if (field.fields.includes(RetailBulkAdjustment.AMOUNT) || field.fields.includes(RetailBulkAdjustment.DISCLAIMER)) {
    // AMOUNT field and DISCLAIMER field are hidden if luxury tax is enabled
    return !isLuxuryTaxEnabled;
  } else if (field.fields.includes(RetailBulkAdjustment.LUXURY_TAX_DISCLAIMER)) {
    // LUXURY_TAX_DISCLAIMER field is hidden if luxury tax is not enabled
    return isLuxuryTaxEnabled;
  } else if (field.fields.includes(RetailBulkAdjustment.LUXURY_TAX_ENABLED)) {
    // LUXURY_TAX_ENABLED field will be hidden if this adjustment is a discount (tax cannot be a discount)
    return isLuxuryTaxEnabled ? true : isFeeAdjustment;
  } else {
    return true;
  }
};

export const RooftopRetailBulkAdjustmentsDetailsTab = memo<ItemTabProps>(({ item: retailBulkAdjustment, metadata }) => {
  const { builderConfig } = useBuilderConfig();
  const { hasPermissions } = useUser();
  const flags = useFlags();

  const isAdjustmentScheduled =
    retailBulkAdjustment[RetailBulkAdjustment.STARTING] || retailBulkAdjustment[RetailBulkAdjustment.ENDING];
  const fixedAmount = get(retailBulkAdjustment, RetailBulkAdjustment.FIXED_AMOUNT);
  const percentageAmount = get(retailBulkAdjustment, RetailBulkAdjustment.PERCENTAGE);
  const paymentOptions = get(retailBulkAdjustment, RetailBulkAdjustment.PAYMENT_OPTIONS);

  const isCashPaymentOptionFeatureEnabled = isFeatureEnabledForRooftop({
    rooftop: retailBulkAdjustment.rooftopName,
    feature: FeatureBundleSet.RETAIL_CASH_PAYMENT_OPTION,
    featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
  });

  const isFinancePaymentOptionFeatureEnabled = isFeatureEnabledForRooftop({
    rooftop: retailBulkAdjustment.rooftopName,
    feature: FeatureBundleSet.RETAIL_FINANCE_PAYMENT_OPTION,
    featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
  });

  const isLeasePaymentOptionFeatureEnabled = isFeatureEnabledForRooftop({
    rooftop: retailBulkAdjustment.rooftopName,
    feature: FeatureBundleSet.RETAIL_LEASE_PAYMENT_OPTION,
    featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
  });

  const getAttributesGridConfigByType = useCallback(
    (bulkAdjustment: RetailBulkAdjustmentGqlType) => {
      const adjustmentType = get(bulkAdjustment, RetailBulkAdjustment.TYPE);

      switch (adjustmentType) {
        case RetailBulkAdjustmentType.PRICE: {
          return [
            {
              fields: [RetailBulkAdjustment.DISCOUNT_OR_FEE],
              displayType: [BOLD],
              canEdit: true,
              gridCellRenderMethod: () => {
                const isNegative = (fixedAmount || percentageAmount) < 0;
                return <PrimaryText>{`${t(isNegative ? 'discount' : 'fee')}`}</PrimaryText>;
              },
            },
            {
              fields: [RetailBulkAdjustment.INCLUDE_CASH],
              displayType: [BOLD],
              canEdit: true,
              gridCellRenderMethod: (_, item, id) => (
                <PrimaryText>{t(paymentOptions?.includes(PaymentOption.CASH) ? 'yes' : 'no')}</PrimaryText>
              ),
            },
            {
              fields: [RetailBulkAdjustment.INCLUDE_FINANCE],
              displayType: [BOLD],
              canEdit: true,
              gridCellRenderMethod: (_, item, id) => (
                <PrimaryText>{t(paymentOptions?.includes(PaymentOption.FINANCE) ? 'yes' : 'no')}</PrimaryText>
              ),
            },
            {
              fields: [RetailBulkAdjustment.INCLUDE_LEASE],
              displayType: [BOLD],
              canEdit: true,
              gridCellRenderMethod: (_, item, id) => (
                <PrimaryText>{t(paymentOptions?.includes(PaymentOption.LEASE) ? 'yes' : 'no')}</PrimaryText>
              ),
            },
            {
              fields: [RetailBulkAdjustment.TARGETS],
              displayType: [BOLD],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.LABEL],
              displayType: [BOLD],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.LUXURY_TAX_ENABLED],
              displayType: [BOLD],
              canEdit: true,
              gridCellRenderMethod: () => (
                <PrimaryText>{`${
                  get(bulkAdjustment, RetailBulkAdjustment.PRICE_TYPE) === RetailPriceAdjustmentType.LUXURY_TAX
                    ? t('yes')
                    : t('no')
                }`}</PrimaryText>
              ),
            },
            {
              fields: [RetailBulkAdjustment.DISCLAIMER],
              displayType: [BOLD],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.LUXURY_TAX_DISCLAIMER],
              displayType: [BOLD],
              canEdit: true,
              gridCellRenderMethod: () => (
                <PrimaryText>{`${
                  get(bulkAdjustment, `${RetailBulkAdjustment.DISCLAIMER}.value`) || PLACEHOLDER
                }`}</PrimaryText>
              ),
            },
            {
              fields: [RetailBulkAdjustment.AMOUNT],
              displayType: [BOLD],
              canEdit: true,
              gridCellRenderMethod: () => {
                const fixedAmountFormatted = isNil(fixedAmount)
                  ? undefined
                  : formatCurrency({ amount: getFormattedFixedPrice(fixedAmount) });
                const percentageAmountFormatted = isNil(percentageAmount)
                  ? undefined
                  : `${getFormattedPercentagePrice(percentageAmount)}%`;

                return (
                  <PrimaryText>{`${fixedAmountFormatted || percentageAmountFormatted || PLACEHOLDER}`}</PrimaryText>
                );
              },
            },
          ].filter(field => {
            if (field.fields.includes(RetailBulkAdjustment.INCLUDE_FINANCE)) {
              return isFinancePaymentOptionFeatureEnabled;
            } else if (field.fields.includes(RetailBulkAdjustment.INCLUDE_LEASE)) {
              return isLeasePaymentOptionFeatureEnabled;
            } else if (field.fields.includes(RetailBulkAdjustment.INCLUDE_CASH)) {
              return isCashPaymentOptionFeatureEnabled;
            }

            const isLuxuryTaxEnabled =
              get(bulkAdjustment, RetailBulkAdjustment.PRICE_TYPE) === RetailPriceAdjustmentType.LUXURY_TAX;
            const isFeeAdjustment = (fixedAmount || percentageAmount) > 0;

            return filterPriceAdjustmentFieldsForLuxuryTax(field, isLuxuryTaxEnabled, isFeeAdjustment);
          });
        }

        case RetailBulkAdjustmentType.OVERLAY: {
          return [
            {
              fields: [RetailBulkAdjustment.OVERLAY_IMAGE_URL],
              displayType: [BOLD],
              canEdit: true,
              gridCellRenderMethod: () => {
                const imageUrl = get(bulkAdjustment, RetailBulkAdjustment.OVERLAY_IMAGE_URL);
                return imageUrl ? (
                  <Image
                    css={css`
                      height: 120px;
                    `}
                    size={ImageSize.FILL}
                    src={imageUrl}
                    type={ImageType.PHOTO}
                  />
                ) : (
                  <PrimaryText>{PLACEHOLDER}</PrimaryText>
                );
              },
            },
            {
              fields: [RetailBulkAdjustment.PRIMARY_PHOTO_ONLY],
              displayType: [BOLD],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.OVERLAY_POSITION_NAME],
              displayType: [BOLD],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.OVERLAY_SCALING_DIMENSION_NAME],
              displayType: [BOLD],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.OVERLAY_SCALE],
              displayType: [BOLD],
              canEdit: true,
              cellSettings: { decimalPlaces: 0 } as PercentageFieldDataTypeSettings,
            },
          ];
        }

        case RetailBulkAdjustmentType.DESCRIPTION: {
          return [
            {
              fields: [RetailBulkAdjustment.DESCRIPTION_TEXT],
              displayType: [RICH_TEXT],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.DESCRIPTION_PLACEMENT_NAME],
              displayType: [BOLD],
              canEdit: true,
            },
          ];
        }

        case RetailBulkAdjustmentType.PROMOTION: {
          return [
            {
              fields: [RetailBulkAdjustment.BANNER_IMAGE_URL],
              displayType: [BOLD],
              canEdit: true,
              gridCellRenderMethod: () => {
                const imageUrl = get(bulkAdjustment, RetailBulkAdjustment.BANNER_IMAGE_URL);
                return imageUrl ? (
                  <Image
                    css={css`
                      height: 120px;
                    `}
                    size={ImageSize.FILL}
                    src={imageUrl}
                    type={ImageType.PHOTO}
                  />
                ) : (
                  <PrimaryText>{PLACEHOLDER}</PrimaryText>
                );
              },
            },
            {
              fields: [RetailBulkAdjustment.SHOW_ON_VLP],
              displayType: [BOLD],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.SHOW_ON_VDP],
              displayType: [BOLD],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.POSITION_NAME],
              displayType: [BOLD],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.ALT_TEXT],
              displayType: [BOLD],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.BANNER_URL],
              displayType: [BOLD],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.OPEN_LINK_IN_NEW_TAB],
              displayType: [BOLD],
              canEdit: true,
            },
            {
              fields: [RetailBulkAdjustment.BANNER_LINKS_TO_VDP],
              displayType: [BOLD],
              // Field is not editable if there is a defined banner url
              canEdit: !get(bulkAdjustment, RetailBulkAdjustment.BANNER_URL),
            },
          ].filter(field => {
            const imagePosition = get(bulkAdjustment, RetailBulkAdjustment.POSITION);
            const showOnVlp = get(bulkAdjustment, RetailBulkAdjustment.SHOW_ON_VLP);

            // Banner links to VDP field is only shown when the banner position is WITHIN and show on vlp = true
            return field.fields.includes(RetailBulkAdjustment.BANNER_LINKS_TO_VDP)
              ? isBannerLinksToVdpFieldVisible(imagePosition, showOnVlp)
              : true;
          });
        }

        case RetailBulkAdjustmentType.DISCLAIMER: {
          return [
            {
              fields: [RetailBulkAdjustment.DISCLAIMER_TEMPLATE],
              displayType: [RICH_TEXT],
              canEdit: true,
            },
          ];
        }

        default: {
          return [];
        }
      }
    },
    [
      fixedAmount,
      paymentOptions,
      percentageAmount,
      isCashPaymentOptionFeatureEnabled,
      isFinancePaymentOptionFeatureEnabled,
      isLeasePaymentOptionFeatureEnabled,
    ]
  );

  /**
   * Note: This grid is always used in a nested view, so will always see one field per row.
   *
   * TODO [#2351]: Update custom cells
   *  - Use range localized labels for range objects from api
   */
  const gridConfig: ItemGridRowProps[] = useMemo(
    () =>
      [
        {
          fields: [RetailBulkAdjustment.ROOFTOP_NAME],
          displayType: [BOLD],
          canEdit: false,
        },
        {
          fields: [RetailBulkAdjustment.TYPE],
          displayType: [BOLD],
          canEdit: false,
        },
        { fields: [RetailBulkAdjustment.NAME, RetailBulkAdjustment.ACTIVE], displayType: [BOLD], canEdit: true },
        { fields: [RetailBulkAdjustment.ORDER], displayType: [BOLD], canEdit: true },
        {
          fields: [RetailBulkAdjustment.SCHEDULE_ADJUSTMENT],
          displayType: [BOLD],
          canEdit: true,
          gridCellRenderMethod: (_, item, id) => <PrimaryText>{t(isAdjustmentScheduled ? 'yes' : 'no')}</PrimaryText>,
        },
        {
          fields: isAdjustmentScheduled ? [RetailBulkAdjustment.STARTING, RetailBulkAdjustment.ENDING] : [],
          displayType: [BOLD],
          canEdit: true,
        },
        ...getAttributesGridConfigByType(retailBulkAdjustment),
      ].filter(({ fields }) => fields.length > 0),
    [isAdjustmentScheduled, retailBulkAdjustment, getAttributesGridConfigByType]
  );

  const settings = useMemo(
    () => ({ ...RetailBulkAdjustmentSettings, __typename: { label: 'type', type: FieldDataType.DEFAULT } }),
    []
  );

  // Callbacks
  const editField = useEditField({
    tierData: {
      itemId: retailBulkAdjustment?.id,
      title: t('adjustment_one'),
      type: BuilderType.RETAIL_BULK_ADJUSTMENT_MODIFY,
      entityType: EntityType.RETAIL_ITEM,
    },
  });

  const onEdit = useCallback(
    (field?: string) => editField(field!, undefined, cloneDeep(retailBulkAdjustment)),
    [editField, retailBulkAdjustment]
  );

  return retailBulkAdjustment ? (
    <>
      {gridConfig.map(config => (
        <ItemGridRowSection
          config={config}
          item={retailBulkAdjustment}
          key={formatItemGridRowSectionKey(config.fields)}
          metadata={metadata.mutation}
          onEdit={authorizedCallback({
            cb: onEdit,
            isAuth: hasPermissions(builderConfig[BuilderType.RETAIL_BULK_ADJUSTMENT_MODIFY].requiredPermissions),
          })}
          settings={settings}
        />
      ))}
    </>
  ) : null;
});
