import type { DetailsViewExceptionsEntityType } from 'components/ui/lists/ListItemClickable';
import type { EntityType } from 'store/api/graph/interfaces/types';

export enum CustomEntity {
  ROOFTOP = 'ROOFTOP',
  ROOFTOP_CONTACTS = 'ROOFTOP_CONTACTS',
  ROOFTOP_INTEGRATIONS = 'ROOFTOP_INTEGRATIONS',
  ROOFTOP_LEAD_FORWARDING = 'ROOFTOP_LEAD_FORWARDING',
  ROOFTOP_TAGS = 'ROOFTOP_TAGS',
  ROOFTOP_USER_ROLES = 'ROOFTOP_USER_ROLES',
  USER = 'USER',
  // Bulk adjustments on individual retail item
  RETAIL_BULK_ADJUSTMENT = 'RETAIL_BULK_ADJUSTMENT',
  // Bulk adjustments on a whole rooftop
  ROOFTOP_BULK_ADJUSTMENT = 'ROOFTOP_BULK_ADJUSTMENT',
  ROOFTOP_PRICING_SEGMENTS = 'ROOFTOP_PRICING_SEGMENTS',
  ROOFTOP_SHOPPING_TOOLS = 'ROOFTOP_SHOPPING_TOOLS',
  ROOFTOP_DEPARTMENTS = 'ROOFTOP_DEPARTMENTS',
  LEAD_CREATE_VEHICLE_OF_INTEREST = 'LEAD_CREATE_VEHICLE_OF_INTEREST',
  LEAD_VEHICLE_PURCHASED = 'LEAD_VEHICLE_PURCHASED',
  RETAIL_AND_TRADE_IN_INVENTORY = 'RETAIL_AND_TRADE_IN_INVENTORY',
  // Settings
  SETTINGS_ROOFTOP_SHOPPING_TOOLS = 'SETTINGS_ROOFTOP_SHOPPING_TOOLS',
}

export type ExtendedEntityType = EntityType | CustomEntity | DetailsViewExceptionsEntityType;
