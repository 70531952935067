import { memo, type SVGProps } from 'react';

const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="m8.154 13.137 4.667 4.667c.228.228.338.495.332.802a1.132 1.132 0 0 1-.351.801c-.228.212-.495.32-.802.327a1.065 1.065 0 0 1-.802-.332l-6.6-6.6a1.053 1.053 0 0 1-.245-.373A1.201 1.201 0 0 1 4.276 12c0-.15.025-.292.077-.429.052-.136.133-.26.245-.373l6.606-6.605c.216-.216.479-.323.79-.323a1.138 1.138 0 0 1 1.15 1.134c0 .312-.114.583-.342.81l-4.648 4.649h10.912c.319 0 .588.11.808.329.22.22.33.489.33.808 0 .32-.11.589-.33.808a1.1 1.1 0 0 1-.808.33H8.154Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(ArrowLeftIcon);
