import { memo, type SVGProps } from 'react';

const EDealerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height={80} viewBox="0 0 91 80" width={91} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h0v0H0z" fill="url(#prefix__a)" transform="translate(-678 -188)" />
      <path
        d="M9.437 0h71.59a9.313 9.313 0 019.313 9.313v.022a9.313 9.313 0 01-9.313 9.313H9.437A9.313 9.313 0 01.124 9.335v-.018A9.317 9.317 0 019.437 0zm-.102 60.962h51.91a9.303 9.303 0 019.272 9.335 9.303 9.303 0 01-9.271 9.335H9.335a9.303 9.303 0 01-9.263-9.335 9.303 9.303 0 019.263-9.335zm0-30.493h29.67a9.335 9.335 0 010 18.67H9.335a9.335 9.335 0 010-18.67z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default memo(EDealerIcon);
