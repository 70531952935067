import type { MemoExoticComponent, SVGProps } from 'react';

import {
  MSRP_PRICING_AND_MILEAGE_FIELD,
  RetailItemPricingInfoBuilderFields,
} from 'components/sections/createModify/retailItemPricing/retailItemPricingInfo/steps/interfaces';
import MotorcycleIcon from 'components/ui/icons/MotorcycleIcon';
import VehicleIcon from 'components/ui/icons/VehicleIcon';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { InventoryItemType, PaymentOption, PriceField } from 'store/api/graph/interfaces/types';
import type { RetailItemResponseType } from 'store/api/graph/responses/responseTypes';
import type { Intl, IntlPlural } from 'utils/intlUtils';

type InventoryItemCommonConfig = {
  /** The translation key for the types title */
  titleLabel: Intl;
  /** The translation key for the types affected items label. */
  affectedItemsLabel: IntlPlural;
  /** Associated milage field for inventory item type */
  mileageField: InventoryItem | undefined;
  /** Icon for associated type */
  icon: MemoExoticComponent<(props: SVGProps<SVGSVGElement>) => JSX.Element>;
};
export type InventoryItemSharedConfigType = {
  [key: string]: InventoryItemCommonConfig;
};

export const getInventoryItemTypeConfig = (type: InventoryItemType): InventoryItemCommonConfig => {
  switch (type) {
    case InventoryItemType.VEHICLE: {
      return {
        titleLabel: 'vehicle',
        affectedItemsLabel: 'vehicles_affected',
        mileageField: InventoryItem.VEHICLE_MILEAGE,
        icon: VehicleIcon,
      } as InventoryItemCommonConfig;
    }

    case InventoryItemType.MOTORCYCLE: {
      return {
        titleLabel: 'motorcycle',
        affectedItemsLabel: 'motorcycle_affected',
        mileageField: InventoryItem.MOTORCYCLE_MILEAGE,
        icon: MotorcycleIcon,
      } as InventoryItemCommonConfig;
    }

    // TODO: [ED-8911]
    default: {
      return {
        titleLabel: 'vehicle',
        affectedItemsLabel: 'vehicles_affected',
        mileageField: InventoryItem.VEHICLE_MILEAGE,
        icon: VehicleIcon,
      } as InventoryItemCommonConfig;
    }
  }
};

// TODO: [ED-8911]
/** Determines which attributes are unnecessary */
export function getRemovedAttributes(data: any): string[] {
  return [InventoryItemType.MOTORCYCLE, InventoryItemType.VEHICLE]
    .filter(item => item !== data.type)
    .map(item => `${item.toLowerCase()}Attributes`);
}

export function getPricingField(startingPriceField?: PriceField): string {
  return {
    [PriceField.MSRP]: MSRP_PRICING_AND_MILEAGE_FIELD,
    [PriceField.PRICE_1]: RetailItemPricingInfoBuilderFields.PRICE_1,
    [PriceField.PRICE_2]: RetailItemPricingInfoBuilderFields.PRICE_2,
  }[startingPriceField || PriceField.PRICE_1];
}

/**
 * Determines whether the `purchasePriceBelowMin` property of `cashRetailPricing`, `financeRetailPricing`, or
 * `leaseRetailPricing` is true for a given RetailItem. If `purchasePriceBelowMin` is true, the corresponding
 * `PaymentOption` will be returned. If none of the properties are true, it will be return undefined.
 */
export const getPaymentOptionWithPriceBelowMin = (retailItem?: RetailItemResponseType): PaymentOption | undefined => {
  if (retailItem?.cashRetailPricing?.purchasePriceBelowMin) {
    return PaymentOption.CASH;
  } else if (retailItem?.financeRetailPricing?.purchasePriceBelowMin) {
    return PaymentOption.FINANCE;
  } else if (retailItem?.leaseRetailPricing?.purchasePriceBelowMin) {
    return PaymentOption.LEASE;
  }

  return undefined;
};
