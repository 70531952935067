import { cloneDeep, get, set } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import type {
  Make,
  Model,
  RetailFiltersMetaQuery,
  RetailFiltersMetaQueryVariables,
  RetailItemConnectionFilter,
} from 'store/api/graph/interfaces/types';
import { InventoryItemType } from 'store/api/graph/interfaces/types';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { getStepField } from 'utils/formatting/createModifyFormatUtils';

import { RetailFilterBuilderFields } from '../components/sections/createModify/shared/steps/interfaces';

const mapSelectedValues = (field: RetailFilterBuilderFields, fields: StepField<any, any, any>[]): any[] =>
  getStepField(field, fields).selectedValue?.map(item => item.id);

/**
 * Used when overriding `RetailFilterStep` if the filter is nested within it's parent.
 * This function will retrieve the `RetailItemConnectionFilter` based on the fields data.
 */
export const getRetailItemConnectionFilterFromFields = (
  /** StepFields for a given step that extends `RetailFilterStep` */
  fields: StepField<any, any, any>[]
): RetailItemConnectionFilter => {
  const flags = getStepField(RetailFilterBuilderFields.FLAGS, fields)?.options as StepField<any, any, any>[];
  const rooftopField = getStepField(RetailFilterBuilderFields.ROOFTOP_ID, fields);

  const rooftopId = Array.isArray(rooftopField.selectedValue)
    ? mapSelectedValues(RetailFilterBuilderFields.ROOFTOP_ID, fields)
    : [rooftopField.selectedValue];

  const makeId = mapSelectedValues(RetailFilterBuilderFields.FILTER_MAKE_ID, fields);
  const makeIdExcluded = mapSelectedValues(RetailFilterBuilderFields.FILTER_MAKE_ID_EXCLUDED, fields);
  const modelId = mapSelectedValues(RetailFilterBuilderFields.FILTER_MODEL_ID, fields);
  const subModelId = mapSelectedValues(RetailFilterBuilderFields.FILTER_SUBMODEL_ID, fields);
  const tagId = mapSelectedValues(RetailFilterBuilderFields.FILTER_TAG_ID, fields);
  const fuelType = mapSelectedValues(RetailFilterBuilderFields.FILTER_VEHICLE_ATTRIBUTES_FUEL_TYPE, fields);
  const bodyType = mapSelectedValues(RetailFilterBuilderFields.FILTER_VEHICLE_ATTRIBUTES_BODY_TYPE, fields);
  const status = mapSelectedValues(RetailFilterBuilderFields.FILTER_STATUS, fields);
  const transformedFilter: RetailItemConnectionFilter = {
    rooftopId: rooftopId?.length ? rooftopId : undefined,
    type: getStepField(RetailFilterBuilderFields.FILTER_TYPE, fields).selectedValue,
    condition: getStepField(RetailFilterBuilderFields.FILTER_CONDITION, fields).selectedValue?.id,
    yearRange: getStepField(RetailFilterBuilderFields.FILTER_YEAR_RANGE, fields).selectedValue,
    daysInStock: getStepField(RetailFilterBuilderFields.FILTER_DAYS_IN_STOCK, fields).selectedValue,
    vehicleAttributes: {
      mileage: getStepField(RetailFilterBuilderFields.FILTER_VEHICLE_ATTRIBUTES_MILEAGE, fields).selectedValue,
      fuelType,
      bodyType,
    },
    makeId: makeId?.length ? makeId : undefined,
    makeIdExcluded: makeIdExcluded?.length ? makeIdExcluded : undefined,
    modelId: modelId?.length ? modelId : undefined,
    subModelId: subModelId?.length ? subModelId : undefined,
    tagId: tagId?.length ? tagId : undefined,
    status: status?.length ? status : undefined,
    mapped: getStepField(RetailFilterBuilderFields.FILTER_MAPPED, flags).selectedValue,
    asIs: getStepField(RetailFilterBuilderFields.FILTER_AS_IS, flags).selectedValue,
    certified: getStepField(RetailFilterBuilderFields.FILTER_CERTIFIED, flags).selectedValue,
    demo: getStepField(RetailFilterBuilderFields.FILTER_DEMO, flags).selectedValue,
    featured: getStepField(RetailFilterBuilderFields.FILTER_FEATURED, flags).selectedValue,
    onOrder: getStepField(RetailFilterBuilderFields.FILTER_ON_ONDER, flags).selectedValue,
    inTransit: getStepField(RetailFilterBuilderFields.FILTER_IN_TRANSIT, flags).selectedValue,
    complete: getStepField(RetailFilterBuilderFields.FILTER_COMPLETE, flags).selectedValue,
    hasMileage: getStepField(RetailFilterBuilderFields.FILTER_HAS_MILEAGE, flags).selectedValue,
    hasPrice: getStepField(RetailFilterBuilderFields.FILTER_HAS_PRICE, flags).selectedValue,
  };
  return transformedFilter;
};

export const combineDetailAndMetadataResponses = (
  /** Response for details query for parent entity containing filter */
  detailsResponse: CustomQueryResult<any, any>,
  /** Response for retail item filter metadata query */
  metadataResponse: CustomQueryResult<RetailFiltersMetaQuery, RetailFiltersMetaQueryVariables>,
  /**
   * Override for pointer to filter object. Do not include trailing `.`
   * Defaults to 'data.item' if left undefined
   */
  filterPointer = 'data.item'
) => {
  const combinedResponse = {
    ...cloneDeep(detailsResponse),
    // Set `isLoading` based on parallel fetches
    isLoading: metadataResponse.isLoading,
  };

  // Set metadata path 1:1 with details path if loaded
  if (!metadataResponse.isLoading) {
    set(
      combinedResponse,
      'data.metadata.mutation.filter',
      get(metadataResponse, 'data.metadata.mutation.inventoryItem')
    );
    transformFilterIdsToOptions(
      combinedResponse,
      `${filterPointer}.filter.status`,
      get(metadataResponse, 'data.metadata.mutation.inventoryItem.retailItem.status')
    );
    transformFilterIdsToOptions(combinedResponse, `${filterPointer}.filter.tagId`, metadataResponse?.data?.tags);
    transformFilterIdsToOptions(combinedResponse, `${filterPointer}.filter.makeId`, metadataResponse?.data?.makes);
    transformFilterIdsToOptions(
      combinedResponse,
      `${filterPointer}.filter.makeIdExcluded`,
      metadataResponse?.data?.makes
    );
    transformFilterIdsToOptions(combinedResponse, `${filterPointer}.filter.modelId`, metadataResponse?.data?.models);
    transformFilterIdsToOptions(
      combinedResponse,
      `${filterPointer}.filter.subModelId`,
      metadataResponse?.data?.subModels
    );
  }

  return combinedResponse;
};

/**
 * Transforming filter arrayId's (filter.tagId, filter.makeId, filter.modelId, filter.subModelId)
 * into select option values
 */
const transformFilterIdsToOptions = (
  data: Record<string, any>,
  dataTarget: string,
  metadata: Array<{ id: string; [key: string]: any }> = []
) => {
  const targetData = get(data, dataTarget) || [];

  // Only transform if it's not already a `SelectOption` kind of array of values
  if (targetData.some(({ name }) => !name)) {
    set(data, dataTarget, targetData.map(targetId => metadata.find(({ id }) => id === targetId)).filter(Boolean));
  }
};

export type ExtendedRetailItemConnectionFilter = Omit<
  RetailItemConnectionFilter,
  'makeId' | 'makeIdExcluded' | 'modelId'
> & {
  makeId?: (string | Make)[];
  makeIdExcluded?: (string | Make)[];
  modelId?: (string | Model)[];
};

export const getRetailFiltersMetaQueryVariables = (
  /** Response for retail item filter metadata query */
  rooftopId: string | string[] | null,
  /** Response for details query for parent entity containing filter */
  filter?: ExtendedRetailItemConnectionFilter
): RetailFiltersMetaQueryVariables => ({
  rooftopId: rooftopId ? (Array.isArray(rooftopId) ? rooftopId : [rooftopId]) : null,
  makeIds: (filter?.makeId || filter?.makeIdExcluded || []).map(item => (typeof item === 'string' ? item : item.id)),
  modelIds: (filter?.modelId || []).map(item => (typeof item === 'string' ? item : item.id)),
  inventoryItemType: filter?.type || InventoryItemType.VEHICLE,
  /**
   * Implicit checks, if the details contains data for the specific MMS,
   * then it can be assumed that the dependant Make/Models also exist for that call
   */
  d_makesOn: !!filter?.makeId?.length || !!filter?.makeIdExcluded?.length,
  d_modelsOn: !!filter?.modelId?.length,
  d_subModelsOn: !!filter?.subModelId?.length,
});
