import { useCallback, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { css } from 'styled-components/macro';

import { leadModify } from 'components/sections/createModify/leads/LeadCreateModifyQuery';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import PromptDialog from 'components/ui/dialogs/PromptDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { FeatureBundleSet } from 'enums/featureBundle';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import { EntityType, LeadStatus } from 'store/api/graph/interfaces/types';
import type { LeadResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { RED_500 } from 'styles/tokens';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props extends MenuItemProps {
  item: LeadResponseType;
}

const LeadMenuItems = ({ item, onUpdateArchiveStatus }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const { hasPermissions } = useUser();
  const flags = useFlags();
  const { toggleTier } = useCreateModify();
  const isArchived = isItemArchived(item);
  const { id, rooftopName, email, spam, subscribedEmail, status } = item || {};
  const [isMarkSpamDialogOpen, setMarkSpamDialogOpen] = useState<boolean>(false);

  const onModifyClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.LEAD_MODIFY,
        entityType: EntityType.LEAD,
        title: t('modify_lead'),
        isCreating: false,
        itemId: id,
      }),
    [id, toggleTier]
  );

  const onCreateTaskClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.TASK_CREATE,
        entityType: EntityType.TASK,
        title: t('create_task'),
        isCreating: true,
        seededData: {
          leadId: id,
          rooftopName,
        },
      }),
    [id, toggleTier, rooftopName]
  );

  const onCreateLeadClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.LEAD_ACTIVITY_CREATE,
        entityType: EntityType.LEAD_ACTIVITY,
        title: t('log_lead_activity'),
        isCreating: true,
        seededData: {
          leadName: item,
          rooftopName,
        },
      }),
    [toggleTier, item, rooftopName]
  );

  const onCreateAppointmentClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.APPOINTMENT_CREATE,
        entityType: EntityType.APPOINTMENT,
        title: t('create_appointment'),
        isCreating: true,
        seededData: {
          leadId: id,
          rooftopName,
        },
      }),
    [id, rooftopName, toggleTier]
  );

  const onCreateTradeInClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.TRADE_IN_ITEMS_CREATE,
        entityType: EntityType.TRADE_IN_ITEM,
        title: t('create_trade_in'),
        isCreating: true,
        seededData: {
          lead: item,
          rooftopName,
        },
      }),
    [item, rooftopName, toggleTier]
  );

  const onPurchaseActivityClicked = useCallback(() => {
    toggleTier(CreateModifyTiers.TIER_0, {
      tierId: CreateModifyTiers.TIER_0,
      type: BuilderType.PURCHASE_ACTIVITY_CREATE,
      entityType: EntityType.LEAD_ACTIVITY,
      title: t('log_purchase_activity'),
      isCreating: true,
      seededData: {
        leadName: item,
        rooftopName: item?.rooftopName,
      },
      itemId: item?.purchaseActivity?.[0]?.id,
    });
  }, [item, toggleTier]);

  const onEmailClicked = useCallback(() => (window.location.href = `mailto:${email}`), [email]);

  const menuItemsConfig: MenuItemConfig[] = [
    { label: t('email_one'), onClick: onEmailClicked, disabled: !email || !subscribedEmail },
    {
      label: t('create_task'),
      onClick: onCreateTaskClicked,
      disabled:
        !isFeatureEnabledForRooftop({
          rooftop: item?.rooftopName,
          feature: FeatureBundleSet.TASK,
          featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
        }) || !hasPermissions(builderConfig[BuilderType.TASK_CREATE].requiredPermissions),
    },
    {
      label: t('log_lead_activity'),
      onClick: onCreateLeadClicked,
      disabled: !hasPermissions(builderConfig[BuilderType.LEAD_ACTIVITY_CREATE].requiredPermissions),
    },
    {
      label: t('create_appointment'),
      onClick: onCreateAppointmentClicked,
      disabled:
        !isFeatureEnabledForRooftop({
          rooftop: item?.rooftopName,
          feature: FeatureBundleSet.APPOINTMENT,
          featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
        }) || !hasPermissions(builderConfig[BuilderType.APPOINTMENT_CREATE].requiredPermissions),
    },
    {
      label: t('create_trade_in'),
      onClick: onCreateTradeInClicked,
      disabled:
        !isFeatureEnabledForRooftop({
          rooftop: item?.rooftopName,
          feature: FeatureBundleSet.TRADE_IN,
          featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
        }) || !hasPermissions(builderConfig[BuilderType.TRADE_IN_ITEMS_CREATE].requiredPermissions),
    },
    {
      label: t('log_purchase_activity'),
      onClick: onPurchaseActivityClicked,
      disabled:
        !isFeatureEnabledForRooftop({
          rooftop: item?.rooftopName,
          feature: FeatureBundleSet.RETAIL,
          featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
        }) ||
        !hasPermissions(
          builderConfig[
            status === LeadStatus.SOLD ? BuilderType.PURCHASE_ACTIVITY_MODIFY : BuilderType.PURCHASE_ACTIVITY_CREATE
          ].requiredPermissions
        ),
    },
    {
      label: t(spam ? 'unmark_as_spam' : 'mark_as_spam'),
      onClick: () => setMarkSpamDialogOpen(true),
      disabled: !hasPermissions(builderConfig[BuilderType.LEAD_MODIFY].requiredPermissions),
    },
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled: !hasPermissions(builderConfig[BuilderType.LEAD_MODIFY].requiredPermissions),
    },
    {
      label: t(isArchived ? 'unarchive' : 'archive'),
      onClick: () => onUpdateArchiveStatus?.(),
      disabled: !onUpdateArchiveStatus,
      isArchivable: false,
      titleCss: css`
        color: ${RED_500};
      `,
    },
  ];

  return (
    <>
      <MenuItems isArchived={isArchived} items={menuItemsConfig} />
      <PromptDialog
        isOpen={isMarkSpamDialogOpen}
        message={t(spam ? 'unmark_lead_as_spam_message' : 'mark_lead_as_spam_message')}
        onCancel={() => setMarkSpamDialogOpen(false)}
        onComplete={(success: boolean) => success && setTimeout(() => setMarkSpamDialogOpen(false), 700)}
        onConfirm={useCallback(
          async () =>
            client.mutate({
              mutation: leadModify,
              variables: { id, spam: !spam },
              refetchQueries: ['LeadConnectionQuery', 'LeadDetailsContainerQuery'],
            }),
          [id, spam]
        )}
        title={t(spam ? 'unmark_as_spam' : 'mark_as_spam')}
      />
    </>
  );
};

export default LeadMenuItems;
