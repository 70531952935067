import { useCallback, useMemo, useState } from 'react';

import { DateTimeFormat } from 'enums/dateTimeFormat';
import { ElementTestId } from 'enums/testing';
import { useMountEffect } from 'hooks/useMountEffect';
import { getDateTime, getFormattedDateTimeString } from 'utils/dateUtils';
import type { TimeObject } from 'utils/timeUtils';
import { MeridiemIndicator } from 'utils/timeUtils';

import NumberList, { NumberLists } from './NumberList';

const { MERIDIEM_FORMAT, HOUR_FORMAT, MINUTE_FORMAT } = DateTimeFormat;

export interface TimeListPickerSettings {
  /** A step interval used to filter the minute options */
  minutesStepNumber?: number;
}

interface TimeListPickerProps {
  settings?: TimeListPickerSettings;
  /** Initial or currently selected `TimeObject`. Default to the current time if omitted. */
  selectedValue?: TimeObject | null;
  /** Callback when a time unit is clicked, emits a `TimeObject` */
  onChange?: (newTime: TimeObject) => void;
}

const TimeListPicker = ({ selectedValue, onChange, settings }: TimeListPickerProps) => {
  const { minutesStepNumber } = settings || {};
  const [selectedTime, setSelectedTime] = useState<TimeObject>(() => {
    // Use provided initial value
    if (selectedValue) {
      return selectedValue;
    }

    // Use current time
    const currentDateTime = getDateTime();
    const meridiem = getFormattedDateTimeString(currentDateTime, MERIDIEM_FORMAT) as MeridiemIndicator;
    const hour = Number.parseInt(getFormattedDateTimeString(currentDateTime, HOUR_FORMAT) || '');
    const minute = Number.parseInt(getFormattedDateTimeString(currentDateTime, MINUTE_FORMAT) || '');

    return { hour, minute, meridiem };
  });

  const meridiemIndicators = useMemo(() => [MeridiemIndicator.AM, MeridiemIndicator.PM], []);
  const meridiemLabels = useMemo(
    () =>
      meridiemIndicators.reduce((labels, meridianName, index) => {
        labels[index] = meridianName;
        return labels;
      }, {}),
    [meridiemIndicators]
  );

  const minuteLabels = useMemo(
    () =>
      Array.from({ length: 60 }, (_, i) => i).reduce((labels, minuteName, index) => {
        labels[index] = minuteName.toString().padStart(2, '0');
        return labels;
      }, {}),
    []
  );

  const emitOnChange = useCallback((newTime: TimeObject) => onChange?.(newTime), [onChange]);

  const onSelectTimeUnit = useCallback(
    (unitOption: Record<string, unknown>) => {
      const newSelectedTime = { ...selectedTime, ...unitOption };

      emitOnChange(newSelectedTime);
      setSelectedTime(newSelectedTime);
    },
    [emitOnChange, setSelectedTime, selectedTime]
  );

  const onSelectHour = useCallback(hour => onSelectTimeUnit({ hour }), [onSelectTimeUnit]);

  const onSelectMinute = useCallback(minute => onSelectTimeUnit({ minute }), [onSelectTimeUnit]);

  const onSelectMeridian = useCallback(
    meridiemIndex => onSelectTimeUnit({ meridiem: meridiemIndicators[meridiemIndex] }),
    [onSelectTimeUnit, meridiemIndicators]
  );

  useMountEffect(() => emitOnChange(selectedTime));

  return (
    <NumberLists>
      {/** Hours */}
      <NumberList
        fromNumber={1}
        onSelectNumber={onSelectHour}
        selectedNumber={selectedTime.hour}
        testid={ElementTestId.BUILDER_HOUR_PICKER}
        toNumber={12}
      />
      {/** Minutes */}
      <NumberList
        fromNumber={0}
        numberLabels={minuteLabels}
        onSelectNumber={onSelectMinute}
        selectedNumber={selectedTime.minute}
        stepNumber={minutesStepNumber}
        testid={ElementTestId.BUILDER_MINUTE_PICKER}
        toNumber={59}
      />
      {/** AM/PM */}
      <NumberList
        fromNumber={0}
        numberLabels={meridiemLabels}
        onSelectNumber={onSelectMeridian}
        selectedNumber={meridiemIndicators.indexOf(selectedTime.meridiem)}
        testid={ElementTestId.BUILDER_MERIDIEN_PICKER}
        toNumber={1}
      />
    </NumberLists>
  );
};

export default TimeListPicker;
