import { memo, type SVGProps } from 'react';

const GlobeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M12 22.203a9.844 9.844 0 0 1-3.953-.805 10.377 10.377 0 0 1-3.25-2.195 10.375 10.375 0 0 1-2.195-3.25A9.844 9.844 0 0 1 1.797 12c0-1.407.268-2.728.805-3.962a10.406 10.406 0 0 1 2.195-3.241 10.376 10.376 0 0 1 3.25-2.195A9.844 9.844 0 0 1 12 1.797c1.407 0 2.728.268 3.962.805a10.406 10.406 0 0 1 3.241 2.195 10.408 10.408 0 0 1 2.195 3.24A9.823 9.823 0 0 1 22.203 12a9.844 9.844 0 0 1-.805 3.953 10.377 10.377 0 0 1-2.195 3.25 10.408 10.408 0 0 1-3.24 2.195 9.824 9.824 0 0 1-3.963.805Zm-.012-2.319c.418-.592.785-1.212 1.101-1.86.317-.648.575-1.334.775-2.06H10.13c.2.726.455 1.412.766 2.06.31.648.675 1.268 1.092 1.86Zm-2.57-.4a11.339 11.339 0 0 1-.787-1.7 16.566 16.566 0 0 1-.551-1.82H5.166a8.07 8.07 0 0 0 1.785 2.157 7.096 7.096 0 0 0 2.467 1.363Zm5.146 0a7.157 7.157 0 0 0 2.473-1.363 8.018 8.018 0 0 0 1.791-2.157h-2.914a15.74 15.74 0 0 1-.556 1.82 11.798 11.798 0 0 1-.794 1.7ZM4.322 13.982H7.68A12.892 12.892 0 0 1 7.53 12a12.858 12.858 0 0 1 .15-1.982H4.322A7.82 7.82 0 0 0 4.072 12a7.841 7.841 0 0 0 .25 1.982Zm5.358 0h4.634c.05-.333.086-.66.107-.981a16.1 16.1 0 0 0 0-2.005 11.84 11.84 0 0 0-.107-.978H9.68A12.858 12.858 0 0 0 9.53 12a12.892 12.892 0 0 0 .15 1.982Zm6.634 0h3.352a7.852 7.852 0 0 0 .25-1.982 7.83 7.83 0 0 0-.25-1.982h-3.352A13.97 13.97 0 0 1 16.452 12a14.008 14.008 0 0 1-.138 1.982Zm-.4-5.952h2.914a7.972 7.972 0 0 0-1.791-2.154 7.182 7.182 0 0 0-2.473-1.36c.308.542.572 1.108.793 1.697.222.59.407 1.196.557 1.817Zm-5.784 0h3.734a11.453 11.453 0 0 0-.769-2.057 13.282 13.282 0 0 0-1.107-1.857 12.993 12.993 0 0 0-1.092 1.857 12.24 12.24 0 0 0-.766 2.057Zm-4.964 0H8.08c.15-.621.334-1.227.55-1.817.217-.59.48-1.155.788-1.697a7.121 7.121 0 0 0-2.467 1.36A8.023 8.023 0 0 0 5.166 8.03Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(GlobeIcon);
