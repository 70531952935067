import { memo, type SVGProps } from 'react';

const ReloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M6.126 12.05c0 .726.138 1.434.416 2.125.277.69.708 1.331 1.292 1.923l.202.203v-1.26c0-.294.1-.542.302-.742.202-.2.45-.299.746-.299.295 0 .543.1.742.3.2.2.3.447.3.742v3.94c0 .32-.11.589-.33.808a1.1 1.1 0 0 1-.808.33H5.054c-.296 0-.543-.101-.742-.303-.2-.201-.3-.45-.3-.745 0-.296.1-.543.303-.743.201-.2.45-.299.745-.299h1.505l-.322-.29c-.871-.79-1.487-1.684-1.85-2.68a8.75 8.75 0 0 1-.542-3.01c0-1.607.416-3.064 1.25-4.373A8.17 8.17 0 0 1 8.45 4.682a.817.817 0 0 1 .806.002c.276.143.46.366.552.668.087.286.076.569-.034.849s-.297.494-.562.643A5.985 5.985 0 0 0 6.964 9.01a5.751 5.751 0 0 0-.838 3.041Zm11.748-.1a5.65 5.65 0 0 0-.415-2.125c-.278-.69-.709-1.331-1.293-1.923l-.202-.203v1.26c0 .294-.1.542-.3.741-.199.2-.446.3-.742.3-.295 0-.543-.1-.745-.303a1.013 1.013 0 0 1-.303-.745V5.018c0-.32.11-.589.33-.808.22-.22.489-.33.808-.33h3.934c.296 0 .543.1.742.3.2.2.3.447.3.742 0 .296-.1.544-.3.746-.199.201-.446.302-.742.302h-1.51l.322.29c.828.833 1.434 1.736 1.817 2.71.383.975.575 1.968.575 2.98 0 1.607-.417 3.064-1.251 4.373a8.17 8.17 0 0 1-3.348 2.994.817.817 0 0 1-.806-.002 1.068 1.068 0 0 1-.551-.667 1.257 1.257 0 0 1 .033-.849c.11-.28.297-.494.562-.643a5.985 5.985 0 0 0 2.247-2.165c.559-.922.838-1.935.838-3.041Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(ReloadIcon);
