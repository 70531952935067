import { css } from 'styled-components/macro';

import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { Conversation } from 'enums/columns/conversation';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { ConversationResponseType } from 'store/api/graph/responses/responseTypes';
import { RED_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

interface Props extends MenuItemProps {
  item?: ConversationResponseType;
}

const ConversationMenuItems = ({ item, onUpdateArchiveStatus }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const { t } = translate;
  const isArchived = isItemArchived(item);
  const { hasPermissions } = useUser();
  const { toggleTier } = useCreateModify();

  // Permissions
  const hasConversationModifyPermissions = hasPermissions(
    builderConfig[BuilderType.CONVERSATION_MODIFY].requiredPermissions
  );

  // onClick callbacks
  const onInviteParticipantsClicked = () => {
    toggleTier(CreateModifyTiers.TIER_0, {
      tierId: CreateModifyTiers.TIER_0,
      type: BuilderType.CONVERSATION_MODIFY,
      entityType: EntityType.CONVERSATION,
      title: t('modify_conversation'),
      isCreating: false,
      itemId: item?.id,
      activeField: Conversation.PARTICIPANTS,
    });
  };

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('invite_participants'),
      onClick: onInviteParticipantsClicked,
      disabled: !hasConversationModifyPermissions,
    },
    {
      label: t(isArchived ? 'unarchive' : 'archive'),
      onClick: () => onUpdateArchiveStatus?.(),
      disabled: !onUpdateArchiveStatus,
      isArchivable: false,
      titleCss: css`
        color: ${RED_500};
      `,
    },
  ];

  return <MenuItems isArchived={isArchived} items={menuItemsConfig} />;
};

export default ConversationMenuItems;
