import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import { StepFieldType } from 'enums/stepFieldType';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

import { GroupDetailsBuilderFields } from './interfaces';

const DetailsFields = defineDetailsFields({
  [GroupDetailsBuilderFields.NAME]: {
    label: 'name',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
  },
  [GroupDetailsBuilderFields.WHITE_LABEL_ID]: {
    label: 'white_label',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
  },
});

export default DetailsFields;
