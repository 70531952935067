import styled from 'styled-components/macro';

import { TertiaryLabel } from 'components/core/typography/Label';
import { CollapsibleSectionHeaderWrapper } from 'components/ui/details/collapsibleSections/CollapsibleSection';
import { Section } from 'components/ui/layouts/CardLayout';
import Badge, { Badges, BadgeSizes } from 'components/ui/shared/Badge';
import type { Sort } from 'store/api/graph/interfaces/types';
import { translate } from 'utils/intlUtils';

import type { SortingSectionProps } from './interfaces';

const { t } = translate;

const SortRuleSection = styled(Section)`
  padding-top: 0;
  margin-right: 17px;
`;

const InitialItemSortingSection = ({ items, metadata }: SortingSectionProps) =>
  items?.map((sort: Sort, index) => {
    const { name, customOrderOptions } =
      metadata?.websiteSortOptions?.find(
        ({ columnId, direction }) => columnId === sort.columnId && direction === sort.direction
      ) || {};

    return (
      <SortRuleSection key={`${sort.columnId}-${sort.direction}`}>
        <CollapsibleSectionHeaderWrapper>
          <TertiaryLabel>{t(index > 0 ? 'then_by' : 'sort_by')}</TertiaryLabel>
        </CollapsibleSectionHeaderWrapper>
        <Badges>
          {name && (
            <Badge key={name} size={BadgeSizes.LARGE} title={name}>
              {name}
            </Badge>
          )}
          {!!sort.customOrder?.length &&
            sort.customOrder.map(customOrderId => {
              const orderName = customOrderOptions?.find(({ id }) => id === customOrderId)?.name || customOrderId;
              return (
                <Badge key={orderName} size={BadgeSizes.LARGE} title={orderName}>
                  {orderName}
                </Badge>
              );
            })}
        </Badges>
      </SortRuleSection>
    );
  });

export default InitialItemSortingSection;
