import type { ComponentProps } from 'react';
import { forwardRef, useCallback, useMemo } from 'react';

import styled, { css } from 'styled-components/macro';
import type { SetOptional } from 'type-fest';

import type { StepFieldInputConfig } from 'components/core/createModify/stepFields/interfaces';
import { getSupportedEditorLanguages, MultilingualEditorLocaleSettings } from 'components/ui/editor/helpers/utils';
import RichTextEditor from 'components/ui/editor/RichTextEditor';
import { RichTextClientType, type RichTextEditorContextInterface } from 'contexts/RichTextEditorContext';
import { useUser } from 'hooks/contexts/useUser';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import type { MultilingualString } from 'store/api/graph/interfaces/types';
import { SPACE_12 } from 'styles/spacing';
import { MultilingualStringValue } from 'utils/intlUtils';

const Wrapper = styled.div<{ $height?: string }>`
  margin-top: ${SPACE_12};

  ${props =>
    props.$height &&
    css`
      height: ${props.$height};
    `}
`;

type RichTextEditorProps = ComponentProps<typeof RichTextEditor>;

interface Props extends Omit<RichTextEditorProps, 'availableLanguages' | 'defaultContent' | 'onEditorUpdate'> {
  /** The height of the editor box (includes units px/%/etc) */
  height?: string;
  /** Callback for when the contents of the editor changes */
  onChange: StepFieldInputConfig['onChange'];
  /** The value of the contents for the editor */
  selectedValue: MultilingualString | null;
  /** The style variant for the editor theme */
  styleVariant?: RichTextClientType;
}

const RichTextInput = forwardRef<RichTextEditorContextInterface, Props>(
  (
    {
      defaultLanguage,
      height,
      onChange,
      onGenerateText,
      selectedValue,
      styleVariant = RichTextClientType.WEB,
      textGenerationEnabledLanguages = [MultilingualStringValue.EN],
      ...props
    },
    ref
  ) => {
    const { flags } = useFeatureFlags();
    const { user } = useUser();

    const currentLanguage = useMemo<MultilingualStringValue>(
      () =>
        defaultLanguage ||
        MultilingualEditorLocaleSettings.find(({ locale }) => locale === user.locale?.languageTag)?.language ||
        MultilingualEditorLocaleSettings[0].language,
      [defaultLanguage, user.locale?.languageTag]
    );

    const availableLanguages = useMemo(() => getSupportedEditorLanguages(), []);

    /**
     * Handles editor `onChange` event, return changed values when editor updates
     */
    const handleOnEditorUpdate = useCallback<NonNullable<RichTextEditorProps['onEditorUpdate']>>(
      value => onChange?.({ currentTarget: { value } }),
      [onChange]
    );

    return (
      <Wrapper $height={height}>
        <RichTextEditor
          availableLanguages={availableLanguages}
          defaultContent={selectedValue}
          defaultLanguage={currentLanguage}
          onEditorUpdate={handleOnEditorUpdate}
          onGenerateText={flags.retailDescriptionTextGenerationEnabled ? onGenerateText : undefined}
          ref={ref}
          textGenerationEnabledLanguages={textGenerationEnabledLanguages}
          {...props}
        />
      </Wrapper>
    );
  }
);

export type RichTextInputSettings = SetOptional<Props, 'onChange' | 'selectedValue'>;
export default RichTextInput;
