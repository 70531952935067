import { memo, useCallback, useMemo } from 'react';

import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import HeroSection from 'components/ui/details/HeroSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { BuilderType } from 'enums/builderType';
import { User, UserSettings } from 'enums/columns/user';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { CustomEntity } from 'enums/extendedEntityType';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import type { UserResponseType } from 'store/api/graph/responses/responseTypes';
import { formatCommonCells, formatItemGridRowSectionKey, formatUserGridSection } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const { t } = translate;

const {
  FIRST_NAME,
  LAST_NAME,
  TITLE,
  PHONE_NUMBER,
  CELL_NUMBER,
  EMAIL,
  SUBSCRIBED_EMAIL,
  LOCALE,
  WHITE_LABEL_NAME,
  GROUP_NAME,
  ROOFTOP_NAME,
  CREATED,
  UPDATED,
  LAST_ACTIVE,
} = User;

const { BOLD } = ItemGridRowDisplayType;

const gridConfig: ItemGridRowProps[] = [
  { fields: [EMAIL, TITLE], displayType: [BOLD], canEdit: true },
  { fields: [FIRST_NAME, LAST_NAME], displayType: [BOLD], canEdit: true },
  { fields: [PHONE_NUMBER, CELL_NUMBER], displayType: [BOLD], canEdit: true },
  { fields: [SUBSCRIBED_EMAIL], displayType: [BOLD], canEdit: true },
  { fields: [LOCALE], displayType: [BOLD], canEdit: true },
  { fields: [WHITE_LABEL_NAME, GROUP_NAME], displayType: [BOLD] },
  { fields: [ROOFTOP_NAME], displayType: [BOLD] },
  { fields: [CREATED, UPDATED], displayType: [BOLD] },
  { fields: [LAST_ACTIVE], displayType: [BOLD] },
];

const MyAccountDetailsTab = memo<ItemTabProps>(({ item, metadata, onEdit }) => {
  const { builderConfig } = useBuilderConfig();
  const user = item as UserResponseType;
  const { toggleTier, findActiveStep } = useCreateModify();

  const onEditClicked = useCallback(
    activeField => {
      const activeStep = findActiveStep(activeField, builderConfig[BuilderType.USER_MODIFY]);

      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.USER_MODIFY,
        entityType: CustomEntity.USER,
        title: t('modify_x', [t('my_account')]),
        isCreating: false,
        itemId: user.id,
        data: { ...user },
        activeField,
        activeStep,
      });
    },
    [findActiveStep, builderConfig, toggleTier, user]
  );

  // Filtering out irrelevant fields that don't have to do with the user's scope
  const [heroGridSection, fullWidthGridSection] = useMemo(() => {
    // First, format common cells based on the users scope
    const formattedGrid = formatCommonCells(gridConfig, user.scope, { whiteLabel: WHITE_LABEL_NAME });
    // Then format the remaining configuration into a hero section and full width section
    return formatUserGridSection(formattedGrid, user, user => (user as UserResponseType)?.editableByMe);
  }, [user]);

  return (
    <>
      <HeroSection
        fallbackSrc={user}
        image={user?.avatar?.url}
        isAvatar
        onEdit={authorizedCallback({
          cb: () => onEditClicked('avatarImage'),
          isAuth: user?.editableByMe,
        })}
        ratioOfImageToContent={0.25}
      >
        {heroGridSection.map(config => (
          <ItemGridRowSection
            config={config}
            item={user}
            key={formatItemGridRowSectionKey(config.fields)}
            metadata={metadata}
            onEdit={authorizedCallback({ cb: onEditClicked, isAuth: user?.editableByMe })}
            settings={UserSettings}
          />
        ))}
      </HeroSection>
      {fullWidthGridSection.map(config => (
        <ItemGridRowSection
          config={config}
          item={user}
          key={formatItemGridRowSectionKey(config.fields)}
          metadata={metadata}
          onEdit={authorizedCallback({ cb: onEditClicked, isAuth: user?.editableByMe })}
          settings={UserSettings}
        />
      ))}
    </>
  );
});

export default MyAccountDetailsTab;
