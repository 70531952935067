import { memo, type SVGProps } from 'react';

const ArrowToTopIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M2.598 17.75a1.056 1.056 0 0 1-.332-.805c.006-.313.115-.577.326-.792l5.221-5.278c.44-.44.978-.659 1.617-.659.638 0 1.177.22 1.616.659l2.384 2.403 4.901-4.863h-1.397a1.1 1.1 0 0 1-.808-.33c-.22-.22-.33-.489-.33-.808 0-.32.11-.588.33-.808.22-.22.49-.33.808-.33h4.132c.319 0 .588.11.808.33.22.22.33.489.33.808v4.126c0 .319-.11.588-.33.808a1.1 1.1 0 0 1-.808.33 1.1 1.1 0 0 1-.808-.33c-.22-.22-.33-.49-.33-.808v-1.397l-4.882 4.882c-.439.44-.978.659-1.616.659-.639 0-1.177-.22-1.617-.659L9.43 12.51l-5.24 5.24a1.079 1.079 0 0 1-.796.317c-.32 0-.584-.105-.796-.317Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(ArrowToTopIcon);
