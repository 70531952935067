import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import DisclaimerPreview from 'components/sections/createModify/rooftopPricingSegments/DisclaimerPreview';
import { RooftopLeaseSegmentDetailsBuilderFields } from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/interfaces';
import { type MultilingualToggleInputSettings, TextAreaSize } from 'components/ui/forms/shared/MultilingualToggleInput';
import { RooftopLeasePricingSegment, RooftopPricingSegment } from 'enums/columns/rooftopPricingSegments';
import { RooftopPricingSegmentConfigurationPointer } from 'enums/rooftopPricingSegmentConfigurationPointer';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import {
  AutoPriceAdjustmentConfigurationInputParameter,
  LeaseRetailPricingConfigurationInputParameter,
  PaymentOption,
  RooftopRetailPricingModifyParameter,
} from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';
import { getNestedConfigurationField } from 'utils/formatting/pricingUtils';

const DetailFields = defineDetailsFields({
  [RooftopLeaseSegmentDetailsBuilderFields.NAME]: {
    label: 'segment_configuration_name',
    queryAlias: [RooftopPricingSegment.NAME],
    clear: { field: RooftopRetailPricingModifyParameter._name },
    hasSeparator: true,
  },
  [RooftopLeaseSegmentDetailsBuilderFields.LEASE_CONFIGURATION_ENABLED]: {
    queryAlias: [RooftopPricingSegment.CONFIGURATION_ENABLED],
    label: 'enabled',
    groupType: StepFieldType.SWITCH,
    hasSeparator: true,
  },
  [RooftopLeaseSegmentDetailsBuilderFields.TARGETS]: {
    label: 'target_other',
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.rooftopRetailPricing.targets',
    required: true,
    hasSeparator: true,
  },
  [RooftopLeaseSegmentDetailsBuilderFields.LEASE_CONFIGURATION_STARTING_PRICE_FIELD]: {
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_STARTING_PRICE_FIELD_NAME,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_STARTING_PRICE_FIELD),
    ],
    label: 'starting_lease_price',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.rooftopRetailPricing.leaseConfiguration.startingPriceField',
    hasSeparator: true,
  },
  [RooftopLeaseSegmentDetailsBuilderFields.LEASE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_ENABLED]: {
    label: 'automated_price_adjustment_one',
    groupType: StepFieldType.SWITCH,
    tooltipContents: 'automated_price_adjustment_tooltip',
    displayType: [StepFieldDisplayType.OMITTED],
    clear: {
      field: LeaseRetailPricingConfigurationInputParameter._autoPriceAdjustmentConfiguration,
      target: RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION,
    },
    hasSeparator: true,
  },
  [RooftopLeaseSegmentDetailsBuilderFields.LEASE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE]: {
    label: 'target_price',
    placeholder: 'target_price',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE_FIELD,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE_FIELD),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.rooftopRetailPricing.leaseConfiguration.autoPriceAdjustmentConfiguration.targetPriceField',
    required: true,
  },
  [RooftopLeaseSegmentDetailsBuilderFields.LEASE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL]: {
    label: 'label',
    placeholder: 'label',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL),
    ],
    required: true,
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
  },
  [RooftopLeaseSegmentDetailsBuilderFields.LEASE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE]: {
    label: 'disclaimer',
    placeholder: 'disclaimer',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE),
    ],
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    settings: {
      textAreaSize: TextAreaSize.MEDIUM,
    } as MultilingualToggleInputSettings,
    clear: {
      field: AutoPriceAdjustmentConfigurationInputParameter._disclaimerTemplate,
      target: RooftopPricingSegmentConfigurationPointer.AUTO_PRICE_ADJUSTMENT_CONFIGURATION,
    },
    hasSeparator: true,
  },
  [RooftopLeaseSegmentDetailsBuilderFields.LEASE_CONFIGURATION_INCLUDE_TAX]: {
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_INCLUDE_TAX,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_INCLUDE_TAX),
    ],
    label: 'include_tax_in_payment',
    groupType: StepFieldType.SWITCH,
  },
  [RooftopLeaseSegmentDetailsBuilderFields.LEASE_CONFIGURATION_INCLUDE_FREIGHT]: {
    label: 'include_freight',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_INCLUDE_FREIGHT,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_INCLUDE_FREIGHT),
    ],
    groupType: StepFieldType.SWITCH,
    hasSeparator: true,
  },
  [RooftopLeaseSegmentDetailsBuilderFields.LEASE_CONFIGURATION_MIN_DISPLAY_PRICE]: {
    label: 'minimum_display_price',
    groupType: StepFieldType.CURRENCY,
    tooltipContents: 'minimum_display_price_tooltip',
    hasSeparator: true,
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_MIN_DISPLAY_PRICE,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_MIN_DISPLAY_PRICE),
    ],
    clear: {
      field: LeaseRetailPricingConfigurationInputParameter._minDisplayPrice,
      target: RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION,
    },
  },
  [RooftopLeaseSegmentDetailsBuilderFields.ORDER]: {
    label: 'order',
    queryAlias: [RooftopPricingSegment.ORDER],
    groupType: StepFieldType.NUMBER,
    tooltipContents: 'order_tooltip',
    clear: { field: RooftopRetailPricingModifyParameter._order },
    hasSeparator: true,
  },
  [RooftopLeaseSegmentDetailsBuilderFields.LEASE_CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES]: {
    label: 'available_payment_period_other',
    queryAlias: [
      RooftopLeasePricingSegment.CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES,
      getNestedConfigurationField(RooftopLeasePricingSegment.CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES),
    ],
    groupType: StepFieldType.BADGES,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.DEFAULT],
  },
  [RooftopLeaseSegmentDetailsBuilderFields.LEASE_CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY]: {
    label: 'default_payment_period',
    queryAlias: [
      RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY,
      getNestedConfigurationField(RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
  },
  [RooftopLeaseSegmentDetailsBuilderFields.DESCRIPTION]: {
    label: 'description',
    queryAlias: [RooftopPricingSegment.DESCRIPTION],
    groupType: StepFieldType.TEXT_AREA,
    hasSeparator: true,
    clear: { field: RooftopRetailPricingModifyParameter._description },
  },
  [RooftopLeaseSegmentDetailsBuilderFields.LEASE_CONFIGURATION_DISCLAIMER_TEMPLATE]: {
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_DISCLAIMER,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_DISCLAIMER),
    ],
    label: 'lease_disclaimer',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    tooltipContents: 'pricing_segment_disclaimer_tooltip',
    settings: {
      textAreaSize: TextAreaSize.MEDIUM,
    } as MultilingualToggleInputSettings,
    previewContents: ({ fieldValue, language }) => (
      <DisclaimerPreview fieldValue={fieldValue} language={language} paymentOption={PaymentOption.LEASE} />
    ),
    required: true,
  },
});

export default DetailFields;
