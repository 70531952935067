import styled from 'styled-components/macro';

import { TertiaryLabel } from 'components/core/typography/Label';
import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TertiaryText from 'components/core/typography/TertiaryText';
import TextRow from 'components/core/typography/TextRow';
import UserActivity from 'components/ui/details/UserActivity';
import { Section } from 'components/ui/layouts/CardLayout';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import { NEUTRAL_050 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

import { RetailItemActivityIcon } from './RetailItemActivityIcon';

const ActivityDivider = styled.div`
  height: 11px;
  width: 2px;
  margin: 4px 20px;
  background: ${NEUTRAL_050};
`;

const RetailItemActivityItem = ({ type, typeName, details, created }) => (
  <ListItem>
    <RetailItemActivityIcon type={type} />
    <ListItemDetails>
      <TextRow>
        <PrimaryText css="text-transform: capitalize;">{typeName}</PrimaryText>
        <TertiaryText>&bull; {created}</TertiaryText>
      </TextRow>
      <SecondaryText>{details}</SecondaryText>
    </ListItemDetails>
  </ListItem>
);

/*
 * TODO: Use real data for activity
 * TODO: Possibly create a generic Activity component if other sections in the future need activity reporting
 */
const RetailItemActivity = ({ retailItem }) => (
  <Section>
    <TertiaryLabel>{translate.t('activity_one')}</TertiaryLabel>
    <RetailItemActivityItem
      created="Mar 4, 1:09 pm"
      details="Received call regarding this vehicle"
      type="PHONE_CALL"
      typeName="Phone Call"
    />
    <ActivityDivider />
    <RetailItemActivityItem
      created="Mar 3, 10:25 am"
      details={`Cory Holloway test drove this vehicle`}
      type="TEST_DRIVE"
      typeName="Test Drive"
    />
    <ActivityDivider />
    <RetailItemActivityItem
      created="Mar 2, 1:05 pm"
      details={`Cory Holloway requested a Test Drive`}
      type="APPOINTMENT"
      typeName="Appointment"
    />
    <ActivityDivider />
    <UserActivity
      action="interested"
      created="Mar 2, 9:30 am"
      details="A lead is interested in this vehicle"
      user={{ firstName: 'Cory', lastName: 'Holloway' }}
    />
    <ActivityDivider />
    <RetailItemActivityItem
      created="Mar 1, 10:05 am"
      details="Received call regarding this vehicle"
      type="PHONE_CALL"
      typeName="Phone Call"
    />
  </Section>
);

export default RetailItemActivity;
