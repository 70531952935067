import { useEffect, useState } from 'react';

import { rooftopImportIntegrationsCountQuery } from 'components/sections/shared/ItemMetaQueries';
import type { RooftopIntegrationQuery, RooftopIntegrationQueryVariables } from 'store/api/graph/interfaces/types';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import type { RequiredPermissions } from 'types/Permissions';

export const LOCKING_FIELDS_REQUIRED_PERMISSIONS: RequiredPermissions = [
  { resource: ResourceType.ROOFTOPS, level: AccessLevel.ADVANCED },
];

/**
 *  A hook for checking a rooftop for import integrations, to determine whether locking fields can be displayed
 *
 * @param rooftopId The rooftop to search for required integrations
 * @param hasPermissions A flag that should be set by checking the user for required permissions
 */
export const useIsFieldLockingVisible = (rooftopId?: string, hasPermissions?: boolean) => {
  const [isFieldLockingVisible, setIsFieldLockingVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!rooftopId || !hasPermissions) {
      return;
    }

    void client
      .query<
        RooftopIntegrationQuery,
        RooftopIntegrationQueryVariables
      >({ query: rooftopImportIntegrationsCountQuery, variables: { rooftopId: [rooftopId] } })
      .then(res => {
        setIsFieldLockingVisible(Boolean(res.data.importIntegrations?.pageInfo?.totalEdges));
      });
  }, [hasPermissions, rooftopId]);

  return isFieldLockingVisible;
};
