import styled from 'styled-components/macro';

import Image from 'components/ui/images/Images';
import { ImageSize, ImageType } from 'enums/imageType';
import { PREVIEW_IMAGE_HEIGHT } from 'styles/layouts';
import { NEUTRAL_900 } from 'styles/tokens';
import { Z_INDEX_1, Z_INDEX_2 } from 'styles/z-index';
import { hexToRGBA } from 'utils/styledUtils';

const clipMaskId = 'imageClipMask';

const ImageOverlay = styled.div`
  z-index: ${Z_INDEX_1};
  top: ${-Number.parseInt(PREVIEW_IMAGE_HEIGHT) / 2}px; /* centering in container */
  height: ${PREVIEW_IMAGE_HEIGHT};
  position: relative;
  filter: drop-shadow(0 2px 4px ${hexToRGBA(NEUTRAL_900, '0.50')});
`;

const ImageContainer = styled(Image)`
  width: 100%;
  height: 100%;
  clip-path: url(#${clipMaskId});
  background: white;

  img {
    animation-name: fadeIn;
    animation-duration: 200ms;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
`;

const OverlayContainer = styled.div.attrs<{ top: number | string; left: number | string; zIndex: number }>(props => ({
  style: {
    ...props,
  },
}))<{ top: number | string; left: number | string; zIndex: number }>`
  position: fixed;
`;

interface Props {
  url: string;
  top: number | string;
  left: number | string;
  zIndex?: number;
}

const ItemsOverlay = ({ url, zIndex = Z_INDEX_2, ...props }: Props) => (
  <OverlayContainer zIndex={zIndex} {...props}>
    <ImageOverlay>
      <ImageContainer size={ImageSize.DEFAULT} src={url} type={ImageType.INVENTORY_ITEM} />
    </ImageOverlay>
    <svg height={214} width={300}>
      <defs>
        <clipPath id={clipMaskId}>
          <path d="M295 0a5 5 0 015 5v204a5 5 0 01-5 5H21a5 5 0 01-5-5v-86.202L.857 107.657a2 2 0 010-2.829l15.141-15.142L16 5a5 5 0 015-5h274z" />
        </clipPath>
      </defs>
      <use fill="#FFF" fillRule="evenodd" />
    </svg>
  </OverlayContainer>
);

export default ItemsOverlay;
