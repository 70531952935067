import { useEffect, useRef, useState } from 'react';

import { isEqual } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import { FacetContainer, FilterSearchSection, SelectedFilterSectionHeader } from 'components/ui/filters/common';
import type { SearchInputRef } from 'components/ui/forms/shared/SearchInput';
import SearchInput from 'components/ui/forms/shared/SearchInput';
import ArrowLeftIcon from 'components/ui/icons/ArrowLeftIcon';
import TrashCanIcon from 'components/ui/icons/TrashCanIcon';
import { Clickable } from 'components/ui/shared/Button';
import Scrollable from 'components/ui/shared/Scrollable';
import { ElementTestId } from 'enums/testing';
import { usePrevious } from 'hooks/usePrevious';
import type { SavedConnectionFilterFragment } from 'store/api/graph/interfaces/types';
import { BODY_TEXT } from 'styles/color';
import { BLUE_050, BLUE_500, NEUTRAL_0 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const FacetCheckboxText = styled.div<{ isSelected?: boolean }>`
  display: flex;

  /* Label */
  ${Text} {
    max-width: 205px;
    ${({ isSelected }) => css`
      color: ${isSelected ? BLUE_500 : BODY_TEXT};
    `}
  }
`;

const SavedFilterViewOption = styled(FacetContainer)<{ isSelected?: boolean }>`
  ${({ isSelected }) => css`
    background: ${isSelected ? BLUE_050 : NEUTRAL_0};
  `}
`;

const SelectedFacetFilterContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SavedFilterViewList = ({
  savedFilters,
  selectedSaveFilterViewId,
  onSelect,
  onDelete,
  onClose,
}: {
  /** The saved filter views to show in the list */
  savedFilters: SavedConnectionFilterFragment[];
  /** The currently selected filter view id */
  selectedSaveFilterViewId?: string;
  /** Callback for whenever a filter view option is selected */
  onSelect: (filter?: SavedConnectionFilterFragment) => void;
  /** Callback for when an item is deleted */
  onDelete: (id: string) => void;
  /** Callback for when the list is closed */
  onClose: () => void;
}) => {
  const prevSavedFilters = usePrevious(savedFilters);
  const searchInputRef = useRef<SearchInputRef>();

  const [keywordSearch, setKeywordSearch] = useState<string>();

  useEffect(() => {
    if (!isEqual(prevSavedFilters, savedFilters)) {
      setKeywordSearch(undefined);
      searchInputRef?.current?.clear?.();
    }
  }, [savedFilters, prevSavedFilters]);

  return (
    <SelectedFacetFilterContainer>
      <SelectedFilterSectionHeader>
        <Clickable onClick={onClose}>
          <ArrowLeftIcon />
        </Clickable>
        <Label>{translate.t('saved_filters_other')}</Label>
      </SelectedFilterSectionHeader>
      <FilterSearchSection>
        <SearchInput
          autoFocus
          onChange={keyword => setKeywordSearch(keyword)}
          placeholder={translate.t('search_x', [translate.t('saved_filters_other')])}
          ref={searchInputRef}
        />
      </FilterSearchSection>
      <Scrollable>
        {savedFilters
          .filter(filter => filter.name.includes(keywordSearch || ''))
          .map(savedFilter => (
            <SavedFilterViewOption isSelected={selectedSaveFilterViewId === savedFilter.id} key={savedFilter.id}>
              <Clickable
                data-testid={`${ElementTestId.FACET_FILTER_SAVED_FILTER_LIST_ITEM}-${savedFilter.id}`}
                onClick={() => onSelect(selectedSaveFilterViewId === savedFilter.id ? undefined : savedFilter)}
              >
                <FacetCheckboxText isSelected={selectedSaveFilterViewId === savedFilter.id}>
                  <Text>{savedFilter.name}</Text>
                </FacetCheckboxText>
              </Clickable>
              <Clickable onClick={() => onDelete(savedFilter.id)}>
                <TrashCanIcon color={BODY_TEXT} />
              </Clickable>
            </SavedFilterViewOption>
          ))}
      </Scrollable>
    </SelectedFacetFilterContainer>
  );
};

export default SavedFilterViewList;
