import type { ElementType, ReactNode, RefObject } from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import styled, { css } from 'styled-components/macro';
import type { Merge } from 'type-fest';

import LoggingService from 'components/core/logging/LoggingService';
import { WrapLink } from 'components/core/navigation/shared/WrapLink';
import { TertiaryLabel } from 'components/core/typography/Label';
import { ListItemContainer } from 'components/ui/layouts/ListItem';
import type { CondensedListNestedCreateProps } from 'components/ui/lists/CondensedListNestedCreate';
import CondensedListNestedCreate from 'components/ui/lists/CondensedListNestedCreate';
import ListItemClickable from 'components/ui/lists/ListItemClickable';
import Loader from 'components/ui/loading/Loader';
import NoResultsPlaceholder from 'components/ui/placeholders/NoResultsPlaceholder';
import type { ScrollableRef } from 'components/ui/shared/interfaces/Scrollable';
import Scrollable from 'components/ui/shared/Scrollable';
import { ListItemType } from 'enums/listItemType';
import { ScrollableType } from 'enums/scrollableType';
import { ElementTestId } from 'enums/testing';
import { useSearch } from 'hooks/contexts/useSearch';
import { useConditionalMountEffect } from 'hooks/useConditionalMountEffect';
import type { PageInfo } from 'store/api/graph/interfaces/types';
import { DIVIDER } from 'styles/color';
import { CARD_WIDTH } from 'styles/layouts';
import { ENTITY_PADDING } from 'styles/spacing';
import { BLUE_050, NEUTRAL_0 } from 'styles/tokens';
import { type Intl, translate } from 'utils/intlUtils';

import { CondensedListPagination } from './CondensedListPagination';

const CondensedListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${NEUTRAL_0};
  min-width: ${CARD_WIDTH};
  overflow-y: auto;
`;

const CondensedListInner = styled.div`
  height: 100%;
  position: relative;
`;

export const CondensedListItemStyles = css`
  :hover {
    background: ${BLUE_050};
  }

  width: 100%;
`;

export const CondensedListItem = styled(WrapLink)<{ selected?: boolean }>`
  padding: 0;
  ${ListItemContainer} {
    ${CondensedListItemStyles}
    ${({ selected }) =>
      selected &&
      css`
        background: ${BLUE_050};
      `}
  }
`;

export const ListItemBorders = styled.div<{ isNested?: boolean }>`
  ${ListItemContainer} {
    border-bottom: 1px solid ${DIVIDER};
  }

  ${({ isNested }) =>
    isNested &&
    css`
      padding-right: 15px;
    `}
`;

export const HEADER_HEIGHT = 44;

const ListItemHeader = styled.div`
  align-items: center;
  background-color: ${NEUTRAL_0};
  display: flex;
  height: ${HEADER_HEIGHT}px;
  padding-left: ${ENTITY_PADDING};
  border-top: none;
  border-bottom: 1px solid ${DIVIDER};
`;

/*
 * The scrollable area takes up the full height, minus the height of the pagination footer (which is 65px high), the
 * optional nested create button (which is 68px high), and the calculated header height
 */
const ScrollableContainer = styled.div<{ headerHeight: number; hasPageInfo: boolean; hasNestedCreateButton?: boolean }>`
  height: ${({ headerHeight, hasPageInfo, hasNestedCreateButton }) =>
    hasPageInfo
      ? `calc(100% - 65px - ${headerHeight}px - ${hasNestedCreateButton ? '68px' : '0px'})`
      : `calc(100% - ${hasNestedCreateButton ? '68px' : '0px'})`};
  width: ${CARD_WIDTH};
  min-width: 100%;
`;

interface CondensedListItemHeaderProps {
  className?: string;
  title?: string;
}

export const CondensedListItemHeader = ({ className, title }: CondensedListItemHeaderProps) => (
  <ListItemHeader className={className}>
    <TertiaryLabel>{title}</TertiaryLabel>
  </ListItemHeader>
);

const CondensedListHeader = styled(CondensedListItemHeader)``;

export interface CondensedListItemProps {
  id: string;
  __typename: string;
  isUnclickable?: boolean;
}

export interface CondensedListProps {
  // Any list items that have isUnclickable set to true will not open in a nested view
  items: CondensedListItemProps[];
  getItemUrl?: (id: string) => string;
  renderItem?: (item, isDisabled?: boolean) => ReactNode;
  renderElement?: ElementType;
  renderSettings?: any;
  selectedItemId?: string;
  isLoading: boolean;
  isNested?: boolean;
  pageInfo?: PageInfo;
  condensedListOverride?: ElementType;
  /** Overrides the scroll container type used to wrap this list. `ScrollableType.TRANSLATE` by default. */
  scrollableTypeOverride?: ScrollableType;
  noResults?: ElementType | null;
  requestPagination?: (pageInfo: any) => void; // TODO: Type pageInfo by common connection params
  target?: string;
  ref?: any; // TODO: Move away from using 'any' as type for forward refs
  // A create button can be shown for lists that are embedded in a nested view
  nestedListCreateButton?: Merge<CondensedListNestedCreateProps, { createButtonLabel?: Intl }>;
}

export interface CondensedListRef {
  /** Scroll the condensed list so that the given item id is in view */
  scrollToItemId: (id: string) => void;
  /** Scroll to the top of the condensed list */
  scrollContentToTop: () => void;
}

/**
 * Helper method for scrolling an element into view
 * @param {object} element - the element that needs to be scrolled into view
 * @param {object} scrollRef - the scrollable container reference
 * @param {function} resetScroll - if a reset scroll method is provided, it will be called if the element is undefined
 */
const scrollToElement = (element: HTMLElement, scrollRef: RefObject<ScrollableRef>, resetScroll?: () => void) =>
  element ? scrollRef?.current?.updateScroll(null, -(element.offsetTop - 50)) : resetScroll?.();

const CondensedList = forwardRef<CondensedListRef | undefined, CondensedListProps>((props, ref) => {
  const {
    items,
    getItemUrl = () => '',
    renderElement: RenderElement,
    renderSettings,
    selectedItemId = '',
    isLoading,
    isNested,
    pageInfo,
    requestPagination,
    noResults: NoResults = NoResultsPlaceholder,
    condensedListOverride: CondensedList,
    scrollableTypeOverride,
    target = '',
    nestedListCreateButton,
  } = useMemo(() => props, [props]);
  const scrollableContentRef = useRef<ScrollableRef>(null);
  const elementCallback = useRef<() => HTMLElement | undefined | null>();
  const renderListItem = useCallback(
    item => (RenderElement ? <RenderElement {...item} {...renderSettings} /> : null),
    [renderSettings, RenderElement]
  );
  const scrollContentToTop = useCallback(() => {
    if (scrollableContentRef.current?.container()) {
      scrollableContentRef.current?.updateScroll(null, 0);
    }
  }, [scrollableContentRef]);
  const { searchParams } = useSearch();
  const [headerTitle, setHeaderTitle] = useState();
  // Reset scroll position when a change occurs in searchParams
  useEffect(() => {
    scrollContentToTop();
  }, [scrollContentToTop, searchParams]);

  useEffect(() => {
    elementCallback.current = () =>
      scrollableContentRef?.current?.container()?.querySelector<HTMLElement>(`#item-${selectedItemId}`);
  }, [selectedItemId]);

  // Scroll to selected item when navigating from table view
  useConditionalMountEffect(
    async () => scrollToElement(elementCallback.current?.() as HTMLElement, scrollableContentRef, scrollContentToTop),
    () => !!elementCallback.current?.()
  );

  useImperativeHandle(
    ref,
    (): CondensedListRef => ({
      scrollToItemId: id =>
        scrollToElement(
          scrollableContentRef.current?.container()?.querySelector<HTMLElement>(`#item-${id}`) as HTMLElement,
          scrollableContentRef,
          scrollContentToTop
        ),
      scrollContentToTop,
    })
  );

  if (!isLoading && items.length === 0 && NoResults !== null) {
    return <NoResults />;
  }

  return (
    <CondensedListContainer {...props}>
      <CondensedListInner>
        {headerTitle && <CondensedListHeader title={headerTitle} />}
        <ScrollableContainer
          data-testid={ElementTestId.CONDENSED_LIST_SCROLL}
          hasNestedCreateButton={!!nestedListCreateButton}
          hasPageInfo={!!pageInfo && !!requestPagination}
          headerHeight={headerTitle ? HEADER_HEIGHT : 0}
        >
          <Scrollable ref={scrollableContentRef} scrollableType={scrollableTypeOverride || ScrollableType.TRANSLATE}>
            {CondensedList ? (
              <CondensedList
                getItemUrl={getItemUrl}
                isNested={isNested}
                items={items}
                renderElement={RenderElement!}
                renderSettings={{ ...renderSettings, listItemType: ListItemType.CONDENSED_LIST }}
                requestPagination={requestPagination}
                selectedItemId={selectedItemId}
                setHeaderTitle={setHeaderTitle}
                target={target}
              />
            ) : (
              <ListItemBorders data-testid={ElementTestId.FILTER_PANEL_SEARCH_RESULTS_CONTAINER} isNested={isNested}>
                {items.map(item => {
                  if (!isNested && !item.isUnclickable) {
                    return (
                      <CondensedListItem
                        id={`item-${item.id}`}
                        key={item.id}
                        selected={item.id === selectedItemId}
                        target={target}
                        to={getItemUrl(item.id)}
                      >
                        {renderListItem(item)}
                      </CondensedListItem>
                    );
                  } else if (isNested && !item.isUnclickable && RenderElement) {
                    return (
                      <ListItemClickable
                        item={item}
                        key={item.id}
                        {...props}
                        renderElement={RenderElement}
                        renderSettings={{ ...renderSettings, listItemType: ListItemType.CONDENSED_LIST }}
                        testId={`${ElementTestId.LIST_ITEM_CLICKABLE}-${item.id}`}
                      />
                    );
                  } else if (RenderElement) {
                    return <RenderElement key={item.id} {...item} listItemType={ListItemType.CONDENSED_LIST} />;
                  } else {
                    LoggingService.debug({ message: 'No RenderElement defined for condensed list!' });
                    return null;
                  }
                })}
              </ListItemBorders>
            )}
          </Scrollable>
        </ScrollableContainer>
        {!!nestedListCreateButton && !isLoading && (
          <CondensedListNestedCreate
            activeCreateBuilder={nestedListCreateButton.activeCreateBuilder}
            createButtonLabel={
              nestedListCreateButton.createButtonLabel && translate.t(nestedListCreateButton.createButtonLabel)
            }
          />
        )}
        {!!pageInfo && !!requestPagination && (
          <CondensedListPagination
            onPageChange={scrollContentToTop}
            pageInfo={pageInfo}
            requestPagination={requestPagination}
          />
        )}
      </CondensedListInner>
      {isLoading && <Loader />}
    </CondensedListContainer>
  );
});

export default CondensedList;
