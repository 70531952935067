import { memo } from 'react';
import 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails, ListItemSeparator } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import type { RooftopDepartment } from 'store/api/graph/interfaces/types';

interface Props extends RooftopDepartment, ListItemProps {}

const DepartmentListItem = ({ suffixIcon, listItemType, ...department }: Props) => {
  const { typeName, email, phoneNumber } = department;
  return (
    <ListItem listItemType={listItemType} suffixIcon={suffixIcon}>
      <ListItemDetails>
        <TextRow>
          <PrimaryText>{typeName}</PrimaryText>
        </TextRow>
        {!!email && (
          <TextRow>
            <SecondaryText>{email}</SecondaryText>
          </TextRow>
        )}
        {!!phoneNumber && (
          <TextRow>
            <SecondaryText>{phoneNumber}</SecondaryText>
          </TextRow>
        )}
      </ListItemDetails>
      <ListItemSeparator />
    </ListItem>
  );
};

export default memo(DepartmentListItem);
