import 'styled-components/macro';

import RooftopsIcon from 'components/ui/icons/RooftopsIcon';
import Badge from 'components/ui/shared/Badge';
import { useUser } from 'hooks/contexts/useUser';
import { translate } from 'utils/intlUtils';

interface Props {
  value: any;
}

export const RooftopBadges = ({ value }: Props) => {
  const { isWhiteLabelScoped, canAccessMultipleRooftops } = useUser();
  const showRooftops = isWhiteLabelScoped || canAccessMultipleRooftops;
  const rooftops = Array.isArray(value) ? value : [value];

  return showRooftops && !!value ? (
    <>
      {rooftops.map(({ id, name }) => (
        <Badge key={id} title={`${translate.t('rooftop')}: ${name.value}`}>
          <RooftopsIcon css="margin-right: 1ch;" height={10} width={10} />
          {name.value}
        </Badge>
      ))}
    </>
  ) : null;
};
