import { get, isEmpty, isNil } from 'lodash-es';

import { InventoryItem } from 'enums/columns/inventoryItem';
import type { InventoryItemAttributesPointer } from 'enums/inventoryItemAttributesPointer';
import type { RetailItemResponseType, TradeInItemResponseType } from 'store/api/graph/responses/responseTypes';
import { formatAmount, PLACEHOLDER } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';
import { joinStrings } from 'utils/stringUtils';

const { t } = translate;

/**
 * Gets the user facing field value of an object.
 *
 * Returns a translated field value if available,
 * otherwise a non translated value or as a last resort a placeholder value,
 * similar to ladash's get().
 *
 * e.g. Drive Train -> returns "4x4" from the metadata instead of the enum value FOUR_BY_FOUR.
 *
 * TODO: Add tests and refactor (Post Release)
 */
export const getField: (
  object: any,
  field: string,
  metadata?: any,
  fallbackField?: string,
  placeholder?: any
) => any = (object, field, metadata?, fallbackField?, placeholder = PLACEHOLDER) => {
  // Raw field value direct from api
  const fieldValue = get(object, field);

  // Helper method to get field value determined by available metadata
  const getFieldMetaValue = (fieldValue, metadata, field) => {
    const associatedMetadata = get(metadata, field, []);

    if (isNil(fieldValue) || isEmpty(associatedMetadata)) {
      return;
    }

    // Get field value/values from metadata
    const foundMetadata = associatedMetadata.filter(({ id }) =>
      [...(Array.isArray(fieldValue) ? fieldValue : [fieldValue])].includes(id)
    );
    const metadataDerivedFieldNames = foundMetadata.map(({ name }) => name?.value || name);
    const fieldMetaValue = Array.isArray(fieldValue) ? metadataDerivedFieldNames : metadataDerivedFieldNames?.[0];

    return fieldMetaValue;
  };

  return getFieldMetaValue(fieldValue, metadata, field) || fieldValue || get(object, fallbackField || '', placeholder);
};

export const formatYMMT = (inventoryItem: RetailItemResponseType | TradeInItemResponseType) => {
  if (!inventoryItem) {
    return '';
  }

  return joinStrings(
    [
      inventoryItem.year,
      inventoryItem.makeName?.name?.value,
      inventoryItem.modelName?.name?.value,
      inventoryItem.subModelName?.name?.value,
      inventoryItem.trimName?.name?.value,
    ],
    ' '
  );
};

export const formatCylinders = (cylinders: string | number | null) => {
  if (!cylinders) {
    return;
  }

  const isMetadataFormatted = typeof cylinders === 'string';
  return isMetadataFormatted ? cylinders : t('x_cyl', [cylinders]);
};

export const formatStockNumber = (stockNumber: string | null | undefined) => (stockNumber ? `#${stockNumber}` : '');

const {
  VEHICLE_TRANSMISSION,
  VEHICLE_EXTERIOR_COLOR,
  VEHICLE_DISPLACEMENT,
  VEHICLE_CYLINDERS,
  VEHICLE_FUEL_TYPE,
  MOTORCYCLE_COLOR,
  MOTORCYCLE_DISPLACEMENT,
  MOTORCYCLE_CYLINDERS,
  MOTORCYCLE_FUEL_TYPE,
} = InventoryItem;

const getAttribute = (
  inventoryItem: RetailItemResponseType | TradeInItemResponseType,
  vehicleAttribute = '',
  motorcycleAttribute = ''
) => get(inventoryItem, vehicleAttribute, get(inventoryItem, motorcycleAttribute));

export const formatInventoryItemDetails = (inventoryItem: RetailItemResponseType | TradeInItemResponseType) => {
  const cylinders = formatCylinders(getAttribute(inventoryItem, VEHICLE_CYLINDERS, MOTORCYCLE_CYLINDERS));
  const displacement = formatAmount(getAttribute(inventoryItem, VEHICLE_DISPLACEMENT, MOTORCYCLE_DISPLACEMENT));

  return joinStrings([
    getAttribute(inventoryItem, `${VEHICLE_EXTERIOR_COLOR}Name`, `${MOTORCYCLE_COLOR}Name`),
    getAttribute(inventoryItem, `${VEHICLE_TRANSMISSION}Name`),
    displacement + ((cylinders && ' ' + cylinders) || ''),
    getAttribute(inventoryItem, `${VEHICLE_FUEL_TYPE}Name`, `${MOTORCYCLE_FUEL_TYPE}Name`),
  ]);
};

/**
 * This util will return the StepField identifier for a given attribute (engine, displacement, etc) and a given
 * type of inventory item (motorcycle or vehicle).
 * @param inventoryType - The inventory type
 * @param attribute - The attribute for this inventory type
 */
export const getInventoryAttributesField = (inventoryType: InventoryItemAttributesPointer, attribute: string): string =>
  `${inventoryType}.${attribute}`;
