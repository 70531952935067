import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import LocationListItem from 'components/ui/lists/LocationListItem';
import { Lead } from 'enums/columns/lead';
import { StepFieldType } from 'enums/stepFieldType';
import { LeadModifyParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

import { LeadGeneralInformationBuilderFields } from './interfaces';

const LeadGeneralInformationFields = defineDetailsFields({
  [LeadGeneralInformationBuilderFields.SALUTATION]: {
    label: 'salutation',
    queryAlias: Lead.SALUTATION,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.lead.salutation',
    clear: { field: LeadModifyParameter._salutation },
  },
  [LeadGeneralInformationBuilderFields.GENDER]: {
    label: 'gender',
    queryAlias: Lead.GENDER,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.lead.gender',
    clear: { field: LeadModifyParameter._gender },
  },
  [LeadGeneralInformationBuilderFields.MARITAL_STATUS]: {
    label: 'marital_status',
    queryAlias: Lead.MARITAL_STATUS,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.lead.maritalStatus',
    clear: { field: LeadModifyParameter._maritalStatus },
  },
  [LeadGeneralInformationBuilderFields.COMPANY_NAME]: {
    label: 'company_name',
    queryAlias: Lead.COMPANY_NAME,
    clear: { field: LeadModifyParameter._company },
  },
  [LeadGeneralInformationBuilderFields.DRIVERS_LICENSE]: {
    label: 'drivers_license_number',
    queryAlias: Lead.DRIVERS_LICENSE_NUMBER,
    clear: { field: LeadModifyParameter._driversLicenseNumber },
  },
  [LeadGeneralInformationBuilderFields.DECLARED_BANKRUPTCY]: {
    label: 'declared_bankruptcy',
    groupType: StepFieldType.SWITCH,
    queryAlias: Lead.DECLARED_BANKRUPTCY,
    clear: { field: LeadModifyParameter._declaredBankruptcy },
  },
  [LeadGeneralInformationBuilderFields.COSIGNER]: {
    label: 'cosigner',
    groupType: StepFieldType.SWITCH,
    queryAlias: Lead.COSIGNER,
    clear: { field: LeadModifyParameter._cosigner },
  },
  [LeadGeneralInformationBuilderFields.ADDRESS]: {
    label: 'address',
    groupType: StepFieldType.RENDER_OBJECT,
    queryAlias: Lead.ADDRESS,
    subStep: [SubStepType.CUSTOM],
    renderElement: LocationListItem,
    clear: { field: LeadModifyParameter._address },
  },
  [LeadGeneralInformationBuilderFields.HOME_STATUS]: {
    label: 'home_status',
    queryAlias: Lead.CURRENT_HOME_STATUS,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.lead.homeStatus',
    clear: { field: LeadModifyParameter._homeStatus },
  },
  [LeadGeneralInformationBuilderFields.DURATION_YEARS]: {
    label: 'duration_years',
    queryAlias: Lead.CURRENT_DURATION_YEARS,
    groupType: StepFieldType.NUMBER,
  },
  [LeadGeneralInformationBuilderFields.DURATION_MONTHS]: {
    label: 'duration_months',
    queryAlias: Lead.CURRENT_DURATION_MONTHS,
    groupType: StepFieldType.NUMBER,
    hasSeparator: true,
  },
  [LeadGeneralInformationBuilderFields.PREVIOUS_ADDRESS]: {
    label: 'previous_address',
    groupType: StepFieldType.RENDER_OBJECT,
    queryAlias: Lead.PREVIOUS_ADDRESS,
    subStep: [SubStepType.CUSTOM],
    renderElement: LocationListItem,
    clear: { field: LeadModifyParameter._previousAddress },
  },
  [LeadGeneralInformationBuilderFields.PREVIOUS_DURATION_YEARS]: {
    label: 'duration_years',
    queryAlias: Lead.PREVIOUS_DURATION_YEARS,
    groupType: StepFieldType.NUMBER,
  },
  [LeadGeneralInformationBuilderFields.PREVIOUS_DURATION_MONTHS]: {
    label: 'duration_months',
    queryAlias: Lead.PREVIOUS_DURATION_MONTHS,
    groupType: StepFieldType.NUMBER,
  },
});

export default LeadGeneralInformationFields;
