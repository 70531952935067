import type { FormEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { isEqual, isNil } from 'lodash-es';

import { usePrevious } from 'hooks/usePrevious';

import type { Props } from './InputText';
import { Input } from './InputText';

export interface NumberInputSettings {
  /** Whether or not to allow decimal in the input. e.g. Useful for currency types. */
  allowDecimal?: boolean;
  /** Whether or not to allow negative numbers in the input. e.g. Useful for discount fees */
  allowNegative?: boolean;
  /** Whether or not to sanitize input. e.g. Useful when toggling between different inputs with different settings. */
  shouldSanitizeInput?: boolean;
}

const defaultSettings = {
  allowDecimal: false,
  allowNegative: false,
  shouldSanitizeInput: false,
} as NumberInputSettings;

type NumberInputProps = Props & {
  /** Optional settings to customize behavior of the number input. */
  settings?: NumberInputSettings;
  /** Optional onChange event that coerces the value into a number. */
  onChange?: (e: Record<'currentTarget', { value: number }>) => void;
};

type ValidateNumberProps = {
  /** The index where the incoming character is being inserted */
  insertIndex: number;
  /** The incoming character code */
  incomingCharCode: number | undefined;
  /** The entire string */
  currentString?: string;
  /** Are decimals allowed */
  isDecimalAllowed: boolean;
  /** Are negative numbers allowed */
  isNegativeAllowed: boolean;
};

/**
 * Given a string, and an incoming character being inserted at a certain index, determine if the resulting string
 * is a valid number.
 * @param selectionStart
 * @param incomingCharCode
 * @param currentString
 * @param isDecimalAllowed
 * @param isNegativeAllowed
 */
export const isIncomingStringANumber = ({
  insertIndex,
  incomingCharCode,
  currentString,
  isDecimalAllowed,
  isNegativeAllowed,
}: ValidateNumberProps): boolean => {
  // Digits 0 - 9
  const isDigit = !!incomingCharCode && incomingCharCode > 47 && incomingCharCode < 58;

  /**
   * Decimal point.
   * Only one is allowed to be entered.
   * For -ve numbers, must be any position but the first position which is
   * reserved for -ve sign.
   */
  const isDecimal = incomingCharCode === 46;
  const isPositionAllowed = currentString?.includes('-') ? insertIndex >= 1 : insertIndex >= 0;
  const noDecimalExists = !currentString?.includes('.');
  const allowDecimalSign = isDecimal && noDecimalExists && isPositionAllowed && isDecimalAllowed;

  /**
   * Minus sign for -ve numbers.
   * Only one is allowed to be entered at beginning of number.
   */
  const isMinus = incomingCharCode === 45;
  const noMinusExistAtBeginning = !currentString?.includes('-') && insertIndex === 0;
  const allowMinusSign = isMinus && noMinusExistAtBeginning && isNegativeAllowed;

  // Allow input entry
  return allowMinusSign || allowDecimalSign || isDigit;
};

/**
 * Input to capture `number` types.
 * TODO: Potentially refactor to use `onChange` with regex.
 */
const NumberInput = ({ onChange, defaultValue, ...props }: NumberInputProps) => {
  const { allowDecimal, allowNegative, shouldSanitizeInput } = { ...defaultSettings, ...props?.settings };
  const inputRef = useRef<HTMLInputElement>(null);
  const prevSettings = usePrevious(props?.settings);
  const [value, setValue] = useState(isNil(defaultValue) ? '' : defaultValue);

  useEffect(() => {
    setValue(isNil(defaultValue) ? '' : defaultValue);
  }, [defaultValue]);

  // Sanitize input when settings are changed
  useEffect(() => {
    if (
      !isEqual(prevSettings, props?.settings) &&
      inputRef.current &&
      inputRef.current.value &&
      inputRef.current.value.replaceAll &&
      shouldSanitizeInput
    ) {
      const shouldSanitizeDecimalSign = !allowDecimal && inputRef.current.value.includes('.');
      const shouldSanitizeNegativeSign = !allowNegative && inputRef.current.value.includes('-');
      if (shouldSanitizeDecimalSign) {
        inputRef.current.value = inputRef.current.value.replaceAll('.', '');
      }
      if (shouldSanitizeNegativeSign) {
        inputRef.current.value = inputRef.current.value.replaceAll('-', '');
      }
      if (shouldSanitizeDecimalSign || shouldSanitizeNegativeSign) {
        // Cast as `any` since we only care for the value to be simulated for `onChange`
        onChange?.({ currentTarget: { value: inputRef.current.value } } as any);
      }
    }
  }, [allowDecimal, allowNegative, prevSettings, onChange, props?.settings, shouldSanitizeInput]);

  const onChangeCallback = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const lastChar: string = e.currentTarget.value?.charAt(e.currentTarget.value?.length - 1);
      const coerceNumber: number | string = e.currentTarget.value?.length
        ? Number.parseFloat(e.currentTarget.value)
        : e.currentTarget.value;
      /*
       *  To support floating point numbers we have to check if the last character is a number and if it
       *  is not then we set the value to the string value to preserve the ability to add decimal points.
       *  Without the check the value when the user tries to type 'x.' gets coerced and set to x
       */
      const value = Number.isNaN(Number(lastChar)) ? e.currentTarget.value : coerceNumber;
      setValue(value);
      onChange?.({
        currentTarget: {
          value: coerceNumber as number,
        },
      });
    },
    [onChange]
  );

  return (
    <Input
      onKeyPress={e => {
        const selectionStart = e.currentTarget.selectionStart || 0;
        const charCode = e.which || e.keyCode;

        if (
          isIncomingStringANumber({
            insertIndex: selectionStart,
            incomingCharCode: charCode,
            currentString: e.currentTarget.value,
            isDecimalAllowed: !!allowDecimal,
            isNegativeAllowed: !!allowNegative,
          })
        ) {
          return true;
        } else {
          e.preventDefault();
          return false;
        }
      }}
      onPaste={e => {
        // TODO: ED-6752 Remove commas as these can still technically be valid numbers
        const pastedText = e.clipboardData?.getData('Text');
        const currentText = e.currentTarget.value || '';
        const caretPosition = e.currentTarget.selectionStart || 0;
        const newText =
          currentText.slice(0, Math.max(0, caretPosition)) + pastedText + currentText.slice(Math.max(0, caretPosition));

        if (
          [...(newText || [])].every((char, index) =>
            isIncomingStringANumber({
              insertIndex: index,
              incomingCharCode: char.codePointAt(0),
              currentString: newText.slice(0, Math.max(0, index)),
              isDecimalAllowed: !!allowDecimal,
              isNegativeAllowed: !!allowNegative,
            })
          )
        ) {
          return true;
        } else {
          e.preventDefault();
          return false;
        }
      }}
      ref={inputRef}
      {...props}
      onChange={onChangeCallback}
      value={value}
    />
  );
};

export default NumberInput;
