import DynamicImport from 'components/core/DynamicImport';
import type ItemTab from 'components/ui/shared/interfaces/ItemTab';
import { TabType } from 'enums/tabType';
import { ConversationType } from 'store/api/graph/interfaces/types';
import type { ConversationResponseType } from 'store/api/graph/responses/responseTypes';
import { formatFullName } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

export const conversationDetailsTabs = [
  {
    tabId: TabType.DETAILS,
    component: DynamicImport(() => import('components/sections/conversations/ConversationDetailsTab')),
  },
  {
    tabId: TabType.CONVERSATION_INQUIRY,
    component: DynamicImport(() => import('components/sections/conversations/ConversationInquiryTab')),
    label: 'inquiry',
  },
  { tabId: TabType.LINKED, component: DynamicImport(() => import('components/sections/leads/ConversationLinkedTab')) },
] satisfies ItemTab[];

export const getConversationDetailsTitle = (item: ConversationResponseType) =>
  item?.type === ConversationType.EMAIL
    ? item?.subject || t('no_subject')
    : `${t('chat_with')}: ${formatFullName(item?.leadName)}`;
