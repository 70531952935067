import type { ReactNode } from 'react';
import { memo } from 'react';

import styled, { css } from 'styled-components/macro';

import MultiNavLinkSubItems, { MultiNavLinkSubItemsContainer } from 'components/core/navigation/MultiNavLinkSubItems';
import { navItemSizeStyles, navItemStyles } from 'components/core/navigation/shared/CommonNavItems';
import { WrapLink } from 'components/core/navigation/shared/WrapLink';
import VisuallyHiddenText from 'components/core/typography/VisuallyHiddenText';
import { useUser } from 'hooks/contexts/useUser';
import { useRouter } from 'hooks/useRouter';
import type { ResourceType, UserScope } from 'store/api/graph/interfaces/types';
import { AccessLevel } from 'store/api/graph/interfaces/types';
import { BLUE_500, NEUTRAL_0, NEUTRAL_300, NEUTRAL_700, NEUTRAL_800, RED_500 } from 'styles/tokens';
const borderSize = '3px';
const NotificationBadge = styled.div`
  position: absolute;
  bottom: -${borderSize};
  right: -${borderSize};
  width: 13px;
  height: 13px;
  background: ${RED_500};
  border-radius: 10px;
  border: ${borderSize} solid ${NEUTRAL_800};
`;

const NavLinkItem = styled(WrapLink)<{ disabled: boolean }>`
  ${navItemStyles}
  ${navItemSizeStyles}
  color: ${NEUTRAL_300};
  background: ${NEUTRAL_700};
  position: relative;

  &.active {
    background: ${BLUE_500};
    color: ${NEUTRAL_0};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const MultiNavLinkItem = styled.div`
  position: relative;

  &:hover ${MultiNavLinkSubItemsContainer} {
    display: block;
  }
`;

interface Props {
  accessLevel?: AccessLevel;
  icon: ReactNode;
  resourceType?: ResourceType;
  to: string;
  title: string;
  scope?: UserScope[];
  hasNotification?: boolean;
  testId?: string;
}

const TitledNavLinkItem = ({
  to,
  title,
  resourceType,
  accessLevel = AccessLevel.BASIC,
  icon,
  scope,
  hasNotification,
  testId,
}: Props) => {
  const {
    hasPermissions,
    user: { scope: userScope },
  } = useUser();
  const { pathname } = useRouter();

  return (!resourceType || hasPermissions([{ resource: resourceType, level: accessLevel }])) &&
    (!scope || scope?.includes(userScope)) ? (
    <MultiNavLinkItem data-testid={testId}>
      <NavLinkItem disabled={pathname.indexOf(to) === 0} to={to}>
        {hasNotification && <NotificationBadge />}
        <VisuallyHiddenText>{title}</VisuallyHiddenText>
        {icon}
      </NavLinkItem>
      <MultiNavLinkSubItems items={[{ url: to, label: title }]} />
    </MultiNavLinkItem>
  ) : null;
};

export default memo(TitledNavLinkItem);
