import { memo, type SVGProps } from 'react';

import { NEUTRAL_300 } from 'styles/tokens';

const NoResultsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={88} viewBox="0 0 24 24" width={88} {...props}>
    <path
      d="m6.994 17.831 1.334 1.354a.571.571 0 0 0 .832 0c.12-.12.18-.262.18-.425a.581.581 0 0 0-.18-.426L7.83 17.006l1.36-1.36a.571.571 0 0 0 0-.83.571.571 0 0 0-.832 0l-1.353 1.353-1.36-1.36a.571.571 0 0 0-.83 0 .57.57 0 0 0 0 .832l1.353 1.353-1.36 1.36a.57.57 0 0 0 0 .83.571.571 0 0 0 .832 0l1.353-1.353ZM7 22c-1.383 0-2.563-.488-3.538-1.462C2.487 19.562 2 18.383 2 17s.487-2.563 1.462-3.537C4.438 12.488 5.617 12 7 12s2.563.488 3.537 1.463C11.512 14.438 12 15.617 12 17s-.488 2.563-1.463 3.538C9.563 21.512 8.383 22 7 22Zm7.212-7.197-.03.015a.116.116 0 0 0-.03.021 7.793 7.793 0 0 0-.616-.854 14.66 14.66 0 0 0-.708-.798 4.354 4.354 0 0 0 1.48-1.553c.37-.646.555-1.358.555-2.134 0-1.214-.424-2.245-1.271-3.092s-1.878-1.27-3.092-1.27c-1.214 0-2.245.423-3.092 1.27S6.138 8.286 6.138 9.5c0 .1.003.195.009.284.006.09.018.183.034.279-.343.03-.718.1-1.125.212-.406.112-.766.25-1.082.416A6.269 6.269 0 0 1 3.862 9.5c0-1.853.644-3.422 1.93-4.708s2.856-1.93 4.708-1.93c1.852 0 3.422.644 4.708 1.93s1.93 2.856 1.93 4.708c0 .717-.11 1.396-.329 2.037a6.41 6.41 0 0 1-.905 1.745l5.527 5.558c.212.211.32.473.327.786.006.313-.102.582-.326.805a1.08 1.08 0 0 1-.796.317c-.32 0-.585-.105-.796-.317l-5.628-5.628Z"
      fill={props?.color || NEUTRAL_300}
    />
  </svg>
);

export default memo(NoResultsIcon);
