import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import { MMSTBuilderFields } from 'components/sections/createModify/MMST/steps/interfaces';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

import { DetailsInventoryItemBuilderFields } from '../inventoryItems/steps/interfaces';

export const makeFields = defineDetailsFields({
  [MMSTBuilderFields.NAME]: {
    label: 'make_one',
    placeholder: 'enter_make',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    active: true,
    required: true,
  },
});

export const modelFields = defineDetailsFields({
  [MMSTBuilderFields.NAME]: {
    label: 'model_one',
    placeholder: 'enter_model',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    active: true,
    required: true,
  },
});

export const subModelFields = defineDetailsFields({
  [MMSTBuilderFields.NAME]: {
    label: 'submodel_one',
    placeholder: 'enter_submodel',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    active: true,
    required: true,
  },
});

export const trimFields = defineDetailsFields({
  [MMSTBuilderFields.NAME]: {
    label: 'trim',
    placeholder: 'enter_trim',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    active: true,
    required: true,
  },
});

export const trimFiltersFields = defineDetailsFields({
  [DetailsInventoryItemBuilderFields.SUB_MODEL_ID]: {
    label: 'submodel_one',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
  },
  [DetailsInventoryItemBuilderFields.TRIM_ID]: {
    label: 'trim',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
  },
  [DetailsInventoryItemBuilderFields.VEHICLE_EXTERIOR_COLOR]: {
    label: 'exterior_color',
    queryAlias: 'exteriorColor',
    groupType: StepFieldType.COLOR,
    groupSubTypes: [StepFieldSubType.COLOR],
    subStep: [SubStepType.ASYNC],
  },
});
