import { useCallback, useMemo, useState } from 'react';

import { get } from 'lodash-es';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components/macro';

import Text from 'components/core/typography/Text';
import Checkbox from 'components/ui/forms/shared/Checkbox';
import { Clickable } from 'components/ui/shared/Button';
import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { DIVIDER } from 'styles/color';
import { LIST_ITEM_HEIGHT } from 'styles/layouts';
import { NEUTRAL_300 } from 'styles/tokens';

import DraggableIcon from '../icons/DraggableIcon';

import { getTableSettingsId } from './TableHelpers';
import type { TableSubType, TableType } from './tableSettings/TableSettings';
import { TableSettings } from './tableSettings/TableSettings';

const FiltersListItem = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
  height: ${LIST_ITEM_HEIGHT};
  padding: 18px 18px;
  border-bottom: ${DIVIDER} 1px solid;
  cursor: pointer;

  &:last-child {
    border: none;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
  padding-left: 18px;
  justify-content: space-between;
  width: 100%;

  button {
    margin-left: 20px;
  }
`;

type DropResult = {
  dropTarget: TableCellData;
};

interface Props {
  filterOption: TableCellData;
  tableType: TableType;
  tableSubType?: TableSubType;
  onFilterToggle: (filter: TableCellData, isEnabling: boolean) => void;
  onDropped: (dragTarget: TableCellData, dropTarget: TableCellData) => void;
}

const TableFiltersListItem = (props: Props) => {
  const { filterOption, tableType, tableSubType, onFilterToggle, onDropped } = useMemo(() => props, [props]);
  const [isEnabled, setIsEnabled] = useState<boolean>(filterOption.enabled);

  const content = useMemo(
    () =>
      get(
        TableSettings[getTableSettingsId(tableType, tableSubType)].find(col => col.columnId === filterOption.columnId),
        'content'
      ) || filterOption.columnId,
    [filterOption.columnId, tableSubType, tableType]
  );

  const [, drag] = useDrag({
    type: 'filters',
    item: { dragTarget: filterOption },
    canDrag: filterOption.canReorder,
    end: (_, monitor) => {
      if (monitor.didDrop()) {
        onDropped?.(filterOption, monitor.getDropResult<DropResult>()!.dropTarget);
      }
    },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  const [, drop] = useDrop({
    accept: 'filters',
    drop: () => ({ dropTarget: filterOption }),
  });

  return (
    <FiltersListItem
      onClick={useCallback(() => {
        filterOption.enabled = !filterOption.enabled;
        setIsEnabled(filterOption.enabled);
        onFilterToggle?.(filterOption, filterOption.enabled);
      }, [filterOption, onFilterToggle])}
      ref={useCallback(node => drag(drop(node)), [drag, drop])}
    >
      <Checkbox checked={isEnabled} />
      <LabelContainer>
        <Text>{content}</Text>
        <Clickable>
          <DraggableIcon color={NEUTRAL_300} />
        </Clickable>
      </LabelContainer>
    </FiltersListItem>
  );
};

export default TableFiltersListItem;
