import type { ReactNode } from 'react';

import styled from 'styled-components/macro';

import type { Props as BaseDialogProps } from 'components/ui/dialogs/Dialog';
import Dialog from 'components/ui/dialogs/Dialog';
import {
  DialogBodyContentContainer,
  DialogBodyCSS,
  DialogContentContainer,
  DialogHeader,
} from 'components/ui/layouts/DialogLayout';
import { ElementTestId } from 'enums/testing';

interface FormDialogProps {
  /** Label used in title of dialog. */
  title?: string;
}

const FormContents = styled.div`
  ${DialogBodyCSS}

  min-width: 400px;
`;

interface Props extends FormDialogProps, BaseDialogProps {
  children?: ReactNode;
}

/**
 * FormDialog is a wrapper component that will display a form component in a dialog model.
 */
const FormDialog = ({ isOpen, isFixedToTop, onCancel, title, children }: Props) => (
  <Dialog isFixedToTop={isFixedToTop} isOpen={isOpen} onCancel={onCancel}>
    <DialogContentContainer>
      {title && <DialogHeader onClose={onCancel} title={title} />}
      <DialogBodyContentContainer css="max-height: initial" data-testid={ElementTestId.PROMPT_DIALOG_CONTAINER}>
        <FormContents>{children}</FormContents>
      </DialogBodyContentContainer>
    </DialogContentContainer>
  </Dialog>
);

export default FormDialog;
