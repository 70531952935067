import { Component } from 'react';

import { get, isEqual } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import type StepField from 'components/core/createModify/interfaces/stepField';
import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';
import Label from 'components/core/typography/Label';
import type { SearchInputRef } from 'components/ui/forms/shared/SearchInput';
import SearchInput from 'components/ui/forms/shared/SearchInput';
import AddIcon from 'components/ui/icons/AddIcon';
import CloseIcon from 'components/ui/icons/CloseIcon';
import ListBulletedIcon from 'components/ui/icons/ListBulletedIcon';
import Button, { Clickable, CTAButton } from 'components/ui/shared/Button';
import { ListSelectionStyle } from 'enums/listSelection';
import { builderListSelectionCategoryTestId, ElementTestId } from 'enums/testing';
import { BODY_TEXT, BODY_TEXT_TERTIARY, DIVIDER } from 'styles/color';
import {
  BORDER_RADIUS_200,
  NEUTRAL_0,
  NEUTRAL_050,
  NEUTRAL_100,
  NEUTRAL_800,
  RED_500,
  SPACE_200,
  SPACE_300,
} from 'styles/tokens';
import { FONT_SIZE_13 } from 'styles/typography';
import { Z_INDEX_1 } from 'styles/z-index';
import { type Intl, translate } from 'utils/intlUtils';
import { variants } from 'utils/styledUtils';

import type { ListSelectionEmptyPlaceholderProps, ListSelectionProps } from './interfaces';
import type { ListCondition } from './listCondition';
import { Container } from './ListSelectionContainer';
import ListSelectionCustomRender from './ListSelectionCustomRender';
import ListSelectionDatePicker from './ListSelectionDatePicker';
import ListSelectionOptions from './ListSelectionOptions';
import ListSelectionPlaceHolder, { ListSelectionEmptyPlaceHolder } from './ListSelectionPlaceholder';
import ListSelectionSortable from './ListSelectionSortable';
import ListSelectionTimePicker from './ListSelectionTimePicker';
import ListSelectionToggles from './ListSelectionToggles';

const { t } = translate;

const Header = styled.div<{ styleVariant?: ListSelectionStyle }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  column-gap: ${SPACE_200};
  padding: ${SPACE_300};
  border-bottom: 1px solid ${DIVIDER};
  width: 100%;
  background: ${NEUTRAL_0};
  z-index: ${Z_INDEX_1};

  ${variants<ListSelectionStyle>('styleVariant', {
    [ListSelectionStyle.CONDENSED_STYLE]: css`
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    `,
    [ListSelectionStyle.DEFAULT]: css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `,
  })}
`;

const ActionButton = styled(Button)`
  border-radius: ${BORDER_RADIUS_200};
  padding: ${SPACE_200};
`;

const CategoriesContainer = styled.div<{ showCategoryTabs?: boolean }>`
  display: ${({ showCategoryTabs }) => (showCategoryTabs ? 'flex' : 'none')};
  align-items: center;
  width: 100%;
  height: 44px;
  border-bottom: 1px solid ${DIVIDER};
  background: ${NEUTRAL_050};
`;

const CategoryOption = styled(Clickable)<{ active: boolean }>`
  flex: 1;
  height: 100%;
  min-width: 50%;
  ${({ active }) =>
    active &&
    css`
      background: ${NEUTRAL_0};
    `}

  ${Label} {
    text-align: center;
    background: none;
    ${({ active }) =>
      !active &&
      css`
        color: ${NEUTRAL_100};
      `}
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  flex: 1;

  & > div {
    position: relative;
    overflow: auto;
  }
`;

export const SubmitButtonContainer = styled.div`
  background: ${NEUTRAL_0};
  padding: 15px;
`;

export const SubmitButton = styled(CTAButton)`
  background: ${NEUTRAL_800};
`;

const ClearSelectionContainer = styled(Clickable)<{ styleVariant?: ListSelectionStyle }>`
  padding: 15px;
  color: ${RED_500};
  background: ${NEUTRAL_050};
  border-top: 1px solid ${DIVIDER};
  text-align: left;
  font-size: ${FONT_SIZE_13};

  ${variants<ListSelectionStyle>('styleVariant', {
    [ListSelectionStyle.CONDENSED_STYLE]: css`
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    `,
    [ListSelectionStyle.DEFAULT]: css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `,
  })}
`;

type State = { searchKeyword?: string; selectedCategory?: ListCondition };

class ListSelection extends Component<ListSelectionProps, State> {
  private searchInput?: SearchInputRef;
  private listContainer?: HTMLElement;
  private baseListItemClassName: string;

  constructor(props) {
    super(props);
    this.state = { searchKeyword: '' };
    this.onSearch = this.onSearch.bind(this);
    this.baseListItemClassName = 'list-item';
  }

  componentDidMount() {
    this.searchInput?.focus();
    this.setState({
      selectedCategory: this.props.subStepCategories?.[0],
    });
  }

  componentDidUpdate(prevProps: Readonly<ListSelectionProps>): void {
    const { type: typePrev, subStepCategories: prevCategories } = prevProps;
    const { type, isDisabled, subStepCategories, selectedValue } = this.props;

    if (!isDisabled && type !== typePrev) {
      this.onSearch('');
      this.searchInput?.focus();
    }

    if (!isEqual(subStepCategories, prevCategories)) {
      this.setState({
        selectedCategory:
          subStepCategories?.find(category => category.conditionValue === selectedValue?.[category.conditionProp]) ||
          subStepCategories?.[0],
      });
    }
  }

  onSearch(keyword: string) {
    const { searchKeyword: keywordPrev } = this.state;

    this.props.onSearchOverride?.(keyword, keywordPrev);
    this.setState({ searchKeyword: keyword });

    if (keyword === '') {
      this.searchInput?.clear();
    }
  }

  /** Scrolls the list to a target number (e.g. 0 for top) or string id (e.g. '12345' for list item)  */
  scrollTo(target: number | string = 0) {
    if (!this.listContainer) {
      return;
    }

    if (typeof target === 'number') {
      this.listContainer.scrollTop = target;
    } else {
      const targetEl = this.listContainer.querySelector(`.${this.baseListItemClassName}-${target}`) as HTMLElement;
      this.listContainer.scrollTop = targetEl?.offsetTop - targetEl?.offsetHeight / 2 || 0;
    }
  }

  renderCategories() {
    const { selectedCategory } = this.state;
    const { subStepCategories, options } = this.props;
    const showCategoryTabs = (subStepCategories?.length || 0) > 1;

    return (
      !!subStepCategories?.length && (
        <CategoriesContainer showCategoryTabs={showCategoryTabs}>
          {subStepCategories.map(category => (
            <CategoryOption
              active={selectedCategory === category}
              data-testid={builderListSelectionCategoryTestId(category.conditionValue)}
              key={category.conditionValue}
              onClick={() => this.setState({ selectedCategory: category })}
            >
              <Label>
                {`${t(category.label as Intl)} (${
                  options?.filter(item => get(item, category.conditionProp) === category.conditionValue)?.length || 0
                })`}
              </Label>
            </CategoryOption>
          ))}
        </CategoriesContainer>
      )
    );
  }

  renderDatePicker = () => (
    <ListSelectionDatePicker key={`ListSelectionDatePicker-${this.props.queryVar}`} {...this.props} />
  );

  renderTimePicker = () => (
    <ListSelectionTimePicker key={`ListSelectionTimePicker-${this.props.queryVar}`} {...this.props} />
  );

  renderList = () => {
    const { t } = translate;
    const { searchKeyword, selectedCategory } = this.state;
    const {
      type,
      label,
      options = [],
      onAdd,
      onDone = () => {},
      onCancel,
      onEdit,
      onDelete,
      onClearSelection,
      isDate = false,
      isTime = false,
      isMultiSelect = false,
      isFieldGroup = false,
      isToggle = false,
      isSortable = false,
      isCheckbox = false,
      renderElement,
      overrideSubStepRenderElement,
      subStepAddConfig,
      subStepCategories = [],
      styleVariant = ListSelectionStyle.DEFAULT,
      settings,
      onListItemDrag = () => {},
    } = this.props;

    const getFilteredOptions = (): (SubStepOption | StepField)[] => {
      /**
       * If there are categories but none have been selected yet, return an empty array
       * to avoid duplicate key errors due to the same item being in two different categories
       */
      if (subStepCategories.length > 0 && !selectedCategory) {
        return [];
      }

      // Filter out items based on keyword if applicable
      const keywordFilteredOptions = this.props.onSearchOverride
        ? options
        : options.filter(item =>
            [item.name, item.label]
              .filter(item => !!item)
              // If this item is a Multilingual string, get the 'value' property
              .map(item => (get(item, 'value', item) as string)?.toLowerCase())
              .some(item => !!(item && item.includes(searchKeyword!.toLowerCase())))
          );

      // Filter out items that don't match a selectedCategory's condition
      if (selectedCategory) {
        return keywordFilteredOptions.filter(
          item => get(item, selectedCategory.conditionProp) === selectedCategory.conditionValue
        );
      }
      return keywordFilteredOptions;
    };
    const filteredOptions = getFilteredOptions();
    const noOptionsAvailable = filteredOptions.length === 0;
    const isEmptySearch = !!(searchKeyword?.length && noOptionsAvailable);

    const isCustomSelection = isDate || isTime;

    const LeftActionBarButton =
      (settings?.listSelectionEmptyPlaceholder as ListSelectionEmptyPlaceholderProps)?.leftActionBarButtonIcon || null;

    return (
      <Container>
        {!isCustomSelection && !settings?.hideSearchHeader && (
          <Header styleVariant={styleVariant}>
            {!!LeftActionBarButton && <LeftActionBarButton />}
            {settings?.hideSearchInput ? (
              <div css="width: 100%"></div>
            ) : (
              <SearchInput
                data-testid={ElementTestId.BUILDER_LIST_SELECTION_SEARCH_INPUT}
                onChange={this.onSearch}
                placeholder={t('search_x', [label || type || ''])}
                ref={input => {
                  this.searchInput = input as SearchInputRef;
                }}
              />
            )}
            {onAdd && (
              <ActionButton
                data-testid={ElementTestId.LIST_SELECTION_ADD_BUTTON}
                onClick={onAdd?.bind?.(null, searchKeyword)}
                selected
              >
                <AddIcon />
              </ActionButton>
            )}
            {onCancel && (
              <ActionButton
                css={css`
                  background: ${BODY_TEXT};
                `}
                onClick={onCancel}
                selected
              >
                <CloseIcon />
              </ActionButton>
            )}
          </Header>
        )}
        {this.renderCategories()}
        <List>
          <div
            css="flex: 1;"
            data-testid={ElementTestId.BUILDER_LIST_SELECTION}
            ref={div => {
              this.listContainer = div as HTMLElement;
            }}
          >
            {overrideSubStepRenderElement || renderElement ? (
              <ListSelectionCustomRender
                baseClassName={this.baseListItemClassName}
                filteredOptions={filteredOptions as SubStepOption[]}
                isCheckbox={isCheckbox}
                onDelete={onDelete}
                onEdit={onEdit}
                styleVariant={styleVariant}
                {...this.props}
                renderElement={(overrideSubStepRenderElement || renderElement)!}
              />
            ) : !isFieldGroup && !isToggle && !isSortable ? (
              <ListSelectionOptions
                baseClassName={this.baseListItemClassName}
                filteredOptions={filteredOptions as SubStepOption[]}
                styleVariant={styleVariant}
                {...this.props}
              />
            ) : isSortable ? (
              <ListSelectionSortable
                baseClassName={this.baseListItemClassName}
                filteredOptions={filteredOptions as SubStepOption[]}
                hasSearchKeyword={!!searchKeyword?.length}
                onListItemDrag={onListItemDrag}
                styleVariant={styleVariant}
                {...this.props}
              />
            ) : (
              <ListSelectionToggles
                baseClassName={this.baseListItemClassName}
                filteredOptions={filteredOptions as StepField[]}
                {...this.props}
              />
            )}
            {noOptionsAvailable && (
              <ListSelectionEmptyPlaceHolder
                buttonLabel={settings?.listSelectionEmptyPlaceholder?.buttonLabel}
                icon={
                  settings?.listSelectionEmptyPlaceholder?.icon || (
                    <ListBulletedIcon color={BODY_TEXT_TERTIARY} height={88} width={88} />
                  )
                }
                onClick={settings?.listSelectionEmptyPlaceholder?.onClick}
                subtitle={settings?.listSelectionEmptyPlaceholder?.subtitle}
                title={settings?.listSelectionEmptyPlaceholder?.title}
              />
            )}
            {isEmptySearch && (
              <ListSelectionPlaceHolder
                label={subStepAddConfig?.builderTitle || (label as Intl) || (type as Intl)}
                onAdd={onAdd?.bind?.(undefined, searchKeyword)}
                onSearch={this.onSearch}
                searchKeyword={searchKeyword}
              />
            )}
          </div>
          {isMultiSelect && !noOptionsAvailable && settings?.enableDoneButton && (
            <SubmitButtonContainer>
              <SubmitButton data-testid={ElementTestId.BUILDER_LIST_SELECTION_DONE_BUTTON} onClick={onDone}>
                {t('done')}
              </SubmitButton>
            </SubmitButtonContainer>
          )}
          {onClearSelection && (
            <ClearSelectionContainer onClick={onClearSelection} styleVariant={styleVariant}>
              {t('clear_selection')}
            </ClearSelectionContainer>
          )}
        </List>
      </Container>
    );
  };

  render() {
    const { isDate, isTime } = this.props;

    if (isDate) {
      return this.renderDatePicker();
    }

    if (isTime) {
      return this.renderTimePicker();
    }

    return this.renderList();
  }
}

export default ListSelection;
