import type { ReactNode } from 'react';
import { Children, memo, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import { TertiaryLabel } from 'components/core/typography/Label';
import ChevronDownIcon from 'components/ui/icons/ChevronDownIcon';
import ChevronUpIcon from 'components/ui/icons/ChevronUpIcon';
import EditIcon from 'components/ui/icons/EditIcon';
import { Section } from 'components/ui/layouts/CardLayout';
import TooltipButton from 'components/ui/shared/TooltipButton';
import { BODY_TEXT, DIVIDER } from 'styles/color';
import { ENTITY_PADDING, SECTION_PADDING } from 'styles/spacing';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;
export const CollapsibleSectionHeaderWrapper = styled.div`
  margin: 12px 0;
`;

export const EditIconWrapper = styled(EditIcon)`
  color: ${BODY_TEXT};
  cursor: pointer;
`;

export const ChevronDownIconWrapper = styled(ChevronDownIcon)`
  color: ${BODY_TEXT};
`;

export const SectionTogglerWrapper = styled.div<{ nestedLevel?: number; canExpand: boolean }>`
  width: 100%;
  ${({ canExpand }) => css`
    cursor: ${canExpand ? 'pointer' : 'default'};
  `}
  padding: ${SECTION_PADDING};

  /* If this section is nested, then override its left-side padding as it will be slightly less */
  ${({ nestedLevel }) =>
    !!nestedLevel &&
    css`
      padding-left: 8px;
    `}

  > :not(:first-child) {
    margin-top: 6px;
  }
`;

export const SectionTogglerTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ChildrenContainer = styled.div<{ isHidden: boolean; nestedLevel: number }>`
  /* If this section is nested, then its left-side margin will be slightly less */
  margin-left: ${({ nestedLevel }) => (nestedLevel ? '10px' : SECTION_PADDING)};
  margin-right: ${({ nestedLevel }) => (nestedLevel ? SECTION_PADDING : '0')};

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}

  ${Section}:last-child {
    border-bottom: none;
  }
`;

export const BottomBorder = styled.hr`
  padding: 0;
  margin: 0;
  background: ${DIVIDER};
  height: 1px;
  border: none;
`;

export interface CollapsibleSectionProps {
  /** The function to call when editing */
  onEdit?: () => void;
  /** The header text of this section */
  label: string;
  /** Placeholder text for when there is no data */
  placeholder?: string;
  /** Child nodes to be rendered in expanded section */
  children?: ReactNode;
  /** Whether or not to add a bottom border to this section */
  addBottomBorder?: boolean;
  /**
   * The nested level of this collapsible panel. The deeper the nested level the more left side padding. Count starts
   * at 0.
   */
  nestedLevel?: number;
  /**
   * If translated tooltip info text is provided, there will be an info icon next to the section label that will
   * show a tooltip on hover
   */
  tooltipInfoText?: string;
  /** A testing ID to add to this section */
  testId?: string;
  /** Render prop to render additional summary details for this section */
  renderSummary?: () => ReactNode;
  /** A testing ID to add to the edit icon for this section */
  editIconTestId?: string;
}

/**
 * A section that can be collapsed.
 */
const CollapsibleSection = memo<CollapsibleSectionProps>(
  ({
    onEdit,
    label,
    children = null,
    addBottomBorder = true,
    nestedLevel = 0,
    tooltipInfoText,
    testId,
    editIconTestId,
    renderSummary,
  }) => {
    const [isCollapsed, setCollapsed] = useState<boolean>(true);

    const hasChildren = Children.count(children) > 0;
    /*
     * The border under the section label will always be visible when expanded, except when there are no children to
     * display. This prevents an issue where a collapsible section with no content will appear to have a double
     * bottom border when expanded.
     */
    const showSectionLabelBorder = isCollapsed ? addBottomBorder : hasChildren;

    return (
      <div data-testid={testId}>
        <SectionTogglerWrapper
          canExpand={hasChildren}
          nestedLevel={nestedLevel}
          onClick={() => {
            // If there are no children, then don't expand
            if (hasChildren) {
              setCollapsed(!isCollapsed);
            }
          }}
        >
          <SectionTogglerTitle>
            <RowContainer>
              <TertiaryLabel>{label}</TertiaryLabel>
              {tooltipInfoText && (
                <TooltipButton
                  tooltip={{
                    margin: { x: 0, y: 8 },
                  }}
                >
                  {tooltipInfoText}
                </TooltipButton>
              )}
            </RowContainer>
            <RowContainer>
              {hasChildren &&
                (isCollapsed ? <ChevronDownIcon color={BODY_TEXT} /> : <ChevronUpIcon color={BODY_TEXT} />)}
              {!!onEdit && (
                <EditIconWrapper
                  data-testid={editIconTestId || `edit-icon-${testId}`}
                  onClick={e => {
                    e.stopPropagation();
                    onEdit();
                  }}
                />
              )}
            </RowContainer>
          </SectionTogglerTitle>
          {renderSummary?.()}
        </SectionTogglerWrapper>
        {showSectionLabelBorder && (
          <BottomBorder
            css={css`
              margin-right: ${nestedLevel ? ENTITY_PADDING : 0};
            `}
          />
        )}

        <ChildrenContainer
          data-testid={testId ? `${testId}-children` : undefined}
          isHidden={isCollapsed || !hasChildren}
          nestedLevel={nestedLevel}
        >
          {children}
        </ChildrenContainer>

        {!isCollapsed && addBottomBorder && (
          <BottomBorder
            css={css`
              margin-right: ${nestedLevel ? ENTITY_PADDING : 0};
            `}
          />
        )}
      </div>
    );
  }
);

export default CollapsibleSection;
