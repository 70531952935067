import type { ReactNode } from 'react';

import styled, { css } from 'styled-components/macro';

import Text from 'components/core/typography/Text';
import { Clickable } from 'components/ui/shared/Button';
import { BannerStyle } from 'enums/bannerStyle';
import { DIVIDER } from 'styles/color';
import { NEUTRAL_0, NEUTRAL_050, NEUTRAL_800, RED_500, SPACE_200 } from 'styles/tokens';
import {
  FONT_SIZE_14,
  FONT_WEIGHT_NORMAL,
  FONT_WEIGHT_SEMI_BOLD,
  LETTER_SPACING_DENSE,
  LINE_HEIGHT_DEFAULT,
} from 'styles/typography';
import { Z_INDEX_5 } from 'styles/z-index';
import { translate } from 'utils/intlUtils';
import { variants } from 'utils/styledUtils';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 0;
  width: 100%;
`;

const BannerContainer = styled.div<{ styleVariant?: BannerStyle }>`
  border: 1px solid ${DIVIDER};
  padding: 8px 16px 8px 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;

  ${Text} {
    white-space: normal;
    text-transform: none;
    font-size: ${FONT_SIZE_14};
    font-style: normal;
    letter-spacing: ${LETTER_SPACING_DENSE};
    line-height: ${LINE_HEIGHT_DEFAULT};
    font-weight: ${FONT_WEIGHT_NORMAL};
  }

  ${variants<BannerStyle>('styleVariant', {
    [BannerStyle.NEUTRAL]: css`
      background: ${NEUTRAL_050};
      border-radius: 8px;
      color: ${NEUTRAL_800};
      ${Text} {
        color: ${NEUTRAL_800};
      }
    `,
    [BannerStyle.WARNING]: css`
      background: ${RED_500};
      color: ${NEUTRAL_0};
      ${Text} {
        color: ${NEUTRAL_0};
      }
    `,
    [BannerStyle.IMPERSONATION]: css`
      z-index: ${Z_INDEX_5};
      background: ${RED_500};
      color: ${NEUTRAL_0};
      border: none;
      ${Text} {
        color: ${NEUTRAL_0};
      }
      ${TextContainer} {
        justify-content: center;
        gap: ${SPACE_200};
      }
    `,
  })}
`;

const BannerLink = styled(Text)`
  text-decoration: underline;
  font-style: normal;
  font-weight: ${FONT_WEIGHT_SEMI_BOLD};
`;

const { t } = translate;

interface Props {
  /** Banners title text */
  title: string;
  /** Banner style */
  styleVariant?: BannerStyle;
  /** CTA label on right side of banner */
  ctaLabel?: string;
  /** Optional icon to be rendered to the left of the banners title */
  icon?: ReactNode;
  /** Callback function for when CTA is clicked */
  onClick?: () => void;
  /** Optional data-testid for the banner */
  testId?: string;
  /** Optional data-testid for CTA button that is rendered */
  ctaTestId?: string;
}

const Banner = ({ title, styleVariant = BannerStyle.NEUTRAL, ctaLabel, icon, onClick, testId, ctaTestId }: Props) => (
  <BannerContainer data-testid={testId} styleVariant={styleVariant}>
    <FlexRow>
      {icon}
      <TextContainer>
        <Text>{title}</Text>
        {!!onClick && !!ctaLabel && (
          <Clickable data-testid={ctaTestId} onClick={onClick}>
            <BannerLink>{ctaLabel}</BannerLink>
          </Clickable>
        )}
      </TextContainer>
    </FlexRow>
  </BannerContainer>
);

export default Banner;
