import type { ElementType } from 'react';

import styled from 'styled-components/macro';

/**
 * Supported types that the ActionInput supports. Any input components used will have an onChange event, and this event
 * will include the value used by the input component. Example, if NumberInput is used, then this type will need a
 * number type included
 */
type ActionInputSupportedTypes = string;

/** Configurable settings for the ActionInput */
export interface ActionInputSettings {
  /**
   * The input to use in the ActionInput component. Can be any type of input (TextInput, NumberInput, MileageInput, etc)
   */
  input: ElementType;
  /** The component to use as a CTA. Can be any component as long as it supports onClick() */
  actionButton: ElementType;
  /** Whether or not the action button should be disabled */
  disabled?: boolean;
}

interface ActionInput {
  /** Settings for configuring the ActionInput */
  settings: ActionInputSettings;
  /** The currently selectedValue of the Input */
  selectedValue?: ActionInputSupportedTypes;
  /** Whenever the input changes this callback is called */
  onChange?: (value: Record<'currentTarget', { value: ActionInputSupportedTypes }>) => void;
  /** Whenever the action button is clicked this callback is called */
  onAction?: () => void;
}

const ActionInputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionInput = ({
  selectedValue,
  onChange,
  onAction,
  settings: { input: Input, actionButton: ActionButton, disabled = false, ...otherSettings },
  ...props
}: ActionInput) => (
  <ActionInputContainer>
    <Input onChange={onChange} settings={otherSettings} value={selectedValue} {...props} />
    {!disabled && <ActionButton onClick={onAction} />}
  </ActionInputContainer>
);

export default ActionInput;
