import { memo, type SVGProps } from 'react';

const LanguageES = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M6.072 14.785H9.88c.312 0 .574.107.788.32.213.214.32.476.32.787 0 .312-.107.574-.32.788a1.07 1.07 0 0 1-.788.32H4.934c-.319 0-.588-.11-.808-.33a1.095 1.095 0 0 1-.33-.808V8.138c0-.319.11-.588.33-.808.22-.22.49-.329.808-.329H9.88c.312 0 .574.107.788.32.213.214.32.476.32.788 0 .31-.107.573-.32.787a1.07 1.07 0 0 1-.788.32H6.072v1.707H8.91c.304 0 .559.104.767.312.207.207.311.463.311.766s-.104.559-.311.766a1.041 1.041 0 0 1-.767.312H6.072v1.707ZM14.992 17a2.012 2.012 0 0 1-1.473-.614 2.012 2.012 0 0 1-.615-1.476c0-.276.094-.508.281-.695a.945.945 0 0 1 .695-.28h.449c.24 0 .443.08.606.244a.823.823 0 0 1 .244.606h2.75v-1.737h-2.935a2.012 2.012 0 0 1-1.476-.614 2.012 2.012 0 0 1-.614-1.476V9.084c0-.573.205-1.064.614-1.472.41-.408.901-.612 1.476-.612h3.126c.573 0 1.063.204 1.471.612.408.408.612.899.612 1.472a.948.948 0 0 1-.284.697.949.949 0 0 1-.698.285h-.442a.823.823 0 0 1-.606-.245.823.823 0 0 1-.245-.606H15.18v1.737h2.94c.574 0 1.064.204 1.472.612.408.408.612.899.612 1.472v1.874c0 .575-.204 1.067-.612 1.476-.408.41-.899.614-1.472.614h-3.126Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(LanguageES);
