import type { User } from 'store/api/graph/interfaces/types';
import type { LoggerOptions } from 'types/Client';
import { isLoggingEnabled } from 'utils/loggingUtils';

import DatadogLogger from './DatadogLogger';
import { trackEvent } from './GTMLogger';

export { GTMEvents } from './GTMLogger';

// eslint-disable-next-line unicorn/no-static-only-class
class LoggingService {
  /**
   * Initialize logger
   */
  static init(): void {
    if (!LoggingService.isEnabled()) {
      return;
    }

    DatadogLogger.init(this);
  }

  /**
   * Checks if LoggingService is enabled.
   *
   * @example Used for gating calls.
   */
  static isEnabled(): boolean {
    return isLoggingEnabled() && !process.env.STORYBOOK;
  }

  /**
   * Set set current active user
   *
   * @param user current active user
   * @example LoggingService.setUser(User)
   */
  static setUser(user?: User): void {
    if (!LoggingService.isEnabled() || !user) {
      return;
    }

    DatadogLogger.setUser({
      id: user.id,
      email: user.email,
      displayName: user.displayName,
      scope: user.scope,
      group: user.group,
    });
  }

  /**
   * Clear current active user
   *
   * @example LoggingService.clearUser()
   */
  static clearUser(): void {
    if (!LoggingService.isEnabled()) {
      return;
    }

    DatadogLogger.clearUser();
  }

  /**
   * Capture Error Exception
   *
   * @param error Current error object
   * @param info Additional error information to be captured
   * @example LoggingService.captureException(new Error('Error'), { scope: 'error-boundary' })
   */
  static captureException(error: Error, info: Record<string, any>) {
    if (!LoggingService.isEnabled()) {
      return;
    }

    DatadogLogger.captureException(error, info);
  }

  /**
   * Track page view
   *
   * @param path Current location path
   * @param context Additional context to be tracked
   * @example LoggingService.trackPageView('/my-custom-page-view', { foo: 'bar' })
   */
  static trackPageView(path: string, context?: Record<string, any>): void {
    if (!LoggingService.isEnabled()) {
      return;
    }

    DatadogLogger.trackPageView(path);
  }

  /**
   * Track user interaction
   *
   * @param action User action
   * @param context Additional context to be tracked
   * @example LoggingService.trackAction('UPDATE', { foo: 'bar' })
   */
  static trackAction(action: string, context?: Record<string, any>): void {
    if (!LoggingService.isEnabled()) {
      return;
    }

    DatadogLogger.trackAction(action, context);
    trackEvent({ event: action, eventInfo: context });
  }

  /**
   * Replaces console.debug.
   *
   * Development only, logs not sent to DD.
   */
  static debug(options: LoggerOptions) {
    const { message } = options;
    console.debug(message, options);
  }

  /**
   * Replaces console.log and console.info
   *
   * Logs to browser always but conditionally sends to DD
   */
  static log(options: Pick<LoggerOptions, 'message' | 'messageContext' | 'error'>) {
    const { message, messageContext, error } = options;
    console.log(message, options);

    if (!LoggingService.isEnabled()) {
      return;
    }
    DatadogLogger.log({ message, messageContext, status: 'info', error });
  }

  /**
   * Replaces console.warn
   *
   * Logs to browser always but conditionally sends to DD
   */
  static warn(options: Pick<LoggerOptions, 'message' | 'messageContext'>) {
    const { message, messageContext } = options;
    console.warn(message, options);

    if (!LoggingService.isEnabled()) {
      return;
    }
    DatadogLogger.log({ message, messageContext, status: 'warn' });
  }

  /**
   * Replaces console.error
   *
   * Logs to browser always but conditionally sends to DD
   */
  static error(options: Pick<LoggerOptions, 'message' | 'messageContext' | 'error'>) {
    const { message, messageContext, error } = options;
    console.error(message, options);

    if (!LoggingService.isEnabled()) {
      return;
    }
    DatadogLogger.log({ message, messageContext, status: 'error', error });
  }
}

export default LoggingService;
