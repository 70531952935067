import { memo, type SVGProps } from 'react';

const CheckEmptyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="m10.582 13.59-1.995-1.994a1.062 1.062 0 0 0-.783-.31c-.315 0-.577.103-.784.31-.207.207-.31.469-.308.784.002.315.107.576.314.784l2.755 2.754c.229.228.496.342.802.342.306 0 .573-.114.8-.342l5.561-5.56c.207-.208.311-.468.311-.781s-.104-.574-.311-.78a1.062 1.062 0 0 0-.784-.312c-.315 0-.576.104-.783.311l-4.795 4.795ZM12 22.204a9.946 9.946 0 0 1-3.983-.803 10.3 10.3 0 0 1-3.238-2.179 10.3 10.3 0 0 1-2.18-3.238A9.945 9.945 0 0 1 1.798 12c0-1.415.267-2.743.803-3.983a10.3 10.3 0 0 1 2.179-3.238 10.3 10.3 0 0 1 3.238-2.18A9.945 9.945 0 0 1 12 1.798c1.415 0 2.743.267 3.983.803a10.3 10.3 0 0 1 3.238 2.179 10.3 10.3 0 0 1 2.18 3.238A9.946 9.946 0 0 1 22.202 12a9.946 9.946 0 0 1-.803 3.983 10.3 10.3 0 0 1-2.179 3.238 10.3 10.3 0 0 1-3.238 2.18 9.946 9.946 0 0 1-3.983.802Zm0-2.275c2.217 0 4.093-.767 5.627-2.3 1.534-1.535 2.301-3.41 2.301-5.628 0-2.217-.767-4.093-2.3-5.627-1.535-1.534-3.41-2.301-5.628-2.301-2.217 0-4.093.767-5.627 2.3C4.839 7.908 4.072 9.783 4.072 12c0 2.217.767 4.093 2.3 5.627 1.535 1.534 3.41 2.301 5.628 2.301Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(CheckEmptyIcon);
