import { memo, type SVGProps } from 'react';

const MoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M5.817 14.09a2.015 2.015 0 0 1-1.477-.614A2.01 2.01 0 0 1 3.725 12c0-.578.205-1.07.614-1.478.41-.408.901-.612 1.476-.612.576 0 1.07.204 1.48.612.41.407.615.9.615 1.475 0 .576-.205 1.069-.614 1.478-.41.41-.903.615-1.479.615Zm6.186 0a2.016 2.016 0 0 1-1.478-.614A2.01 2.01 0 0 1 9.91 12c0-.578.205-1.07.614-1.478.41-.408.901-.612 1.476-.612.578 0 1.07.204 1.478.612.408.407.612.9.612 1.475a2.02 2.02 0 0 1-.612 1.478c-.407.41-.9.615-1.475.615Zm6.184 0c-.577 0-1.07-.205-1.481-.614A2.008 2.008 0 0 1 16.09 12c0-.578.205-1.07.616-1.478.41-.408.904-.612 1.481-.612.577 0 1.069.204 1.476.612.408.407.612.9.612 1.475a2.02 2.02 0 0 1-.611 1.478c-.408.41-.9.615-1.477.615Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(MoreIcon);
