import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { userMetaQueries } from 'components/sections/shared/ItemMetaQueries';
import { userDetailsQuery } from 'containers/users/UsersContainerQuery';
import type { UserContextInterface } from 'contexts/UserContext';
import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type { UserMetaQueriesQuery, UserMetaQueriesQueryVariables } from 'store/api/graph/interfaces/types';
import { AccessLevel, ResourceType, UserScope } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';

const UserQueryMethods: QueryMethods = {
  getMetaData: async (onMetaUpdate, _, userContext?: UserContextInterface) => {
    const {
      user: { scope },
      hasPermissions,
    } = userContext!;

    const observable = client.watchQuery<UserMetaQueriesQuery, UserMetaQueriesQueryVariables>({
      query: userMetaQueries,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      variables: {
        d_canAccessWhiteLabels:
          scope === UserScope.GLOBAL &&
          hasPermissions([{ resource: ResourceType.ROOFTOPS, level: AccessLevel.ADVANCED }]),
        d_isUsersAdmin: hasPermissions([{ resource: ResourceType.USERS, level: AccessLevel.FULL }]),
      },
    });
    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: res => {
          const metadata = res.data;
          resolve({ subscription, metadata });
          onMetaUpdate(metadata);
        },
        error: (error: Error) => {
          logApiError(error);
          reject(error);
        },
      });
    });
  },

  getData: async (id: string, userContext: UserContextInterface) => {
    const { hasPermissions } = userContext;
    const response = await client.query({
      query: userDetailsQuery,
      variables: { id, d_isUsersAdmin: hasPermissions([{ resource: ResourceType.USERS, level: AccessLevel.FULL }]) },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: [
    'UserConnectionQuery',
    'UserCondensedListQuery',
    'RooftopDetailsContainerQuery',
    'UserDetailsContainerQuery',
    'SettingsNavigationItemCountQuery',
  ],
};

export default UserQueryMethods;
