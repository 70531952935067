import { memo } from 'react';

import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import { SecondaryMultiLineText } from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import AppointmentTimeBlock from 'components/sections/appointments/AppointmentTimeBlock';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import { ArchiveBadge } from 'components/ui/shared/ArchiveBadge';
import Badge, { Badges } from 'components/ui/shared/Badge';
import { RooftopBadges } from 'components/ui/shared/RooftopBadges';
import type { AppointmentResponseType } from 'store/api/graph/responses/responseTypes';
import { DIVIDER } from 'styles/color';
import { ENTITY_PADDING } from 'styles/spacing';
import { translate } from 'utils/intlUtils';

const Container = styled(ListItem)`
  position: relative;
  display: flex;

  > div:first-child {
    margin-right: 8px;
  }
`;

// TODO: Possibly extract into a reusable vertical separator?
const Separator = styled.div`
  width: 1px;
  background-color: ${DIVIDER};
  margin-right: ${ENTITY_PADDING};
  flex-shrink: 0;
`;

interface Props extends ListItemProps, AppointmentResponseType {}

const AppointmentListItem = ({ suffixIcon, listItemType, ...appointment }: Props) => {
  const { title, dateTo, dateFrom, note, rooftopName, tagName } = appointment;

  return (
    <Container isArchived={isItemArchived(appointment)} listItemType={listItemType} suffixIcon={suffixIcon}>
      <AppointmentTimeBlock dateFrom={dateFrom} dateTo={dateTo} />
      <Separator />
      <ListItemDetails>
        <TextRow>
          <PrimaryText>{title}</PrimaryText>
        </TextRow>
        <TextRow>
          <SecondaryMultiLineText>{note}</SecondaryMultiLineText>
        </TextRow>
        <Badges>
          <ArchiveBadge isArchived={isItemArchived(appointment)} />
          <RooftopBadges value={rooftopName} />
          {!!tagName?.length &&
            tagName?.map(tag => (
              <Badge key={tag.id} title={`${translate.t('tag')}: ${tag.name?.value}`}>
                {tag.name?.value}
              </Badge>
            ))}
        </Badges>
      </ListItemDetails>
    </Container>
  );
};

export default memo(AppointmentListItem);
