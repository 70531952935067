import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import {
  CommonInventoryItemFields,
  commonInventoryItemNoteField,
} from 'components/sections/createModify/inventoryItems/steps/DetailsInventoryItemFields';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import type { ActionInputSettings } from 'components/ui/forms/shared/ActionInput';
import InputText from 'components/ui/forms/shared/InputText';
import RefreshActionButton from 'components/ui/forms/shared/RefreshActionButton';
import { BuilderType } from 'enums/builderType';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { ElementTestId } from 'enums/testing';
import { TradeInItemModifyParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

const VinRefreshButton = ({ onClick }) => (
  <RefreshActionButton onClick={onClick} testingId={ElementTestId.VIN_ACTION_BUTTON_REFRESH} />
);

const CarfaxRefreshButton = ({ onClick }) => (
  <RefreshActionButton onClick={onClick} testingId={ElementTestId.CARFAX_ACTION_BUTTON_REFRESH} />
);

const DetailsFields = defineDetailsFields<DetailsInventoryItemBuilderFields>({
  // Variation of vin step fields carried over
  [DetailsInventoryItemBuilderFields.STATUS]: {
    label: 'status',
    queryAlias: InventoryItem.TRADE_IN_ITEM_STATUS,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.item.tradeInItem.status',
  },
  [DetailsInventoryItemBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: 'rooftop',
    // This rooftop field is hidden from the user, it is needed to pre-seed the rooftop id to MMST substeps
    displayType: [StepFieldDisplayType.HIDDEN, StepFieldDisplayType.OMITTED],
  },
  [DetailsInventoryItemBuilderFields.VIN]: {
    label: 'vin',
    clear: { field: TradeInItemModifyParameter._vin },
    groupType: StepFieldType.ACTION_INPUT,
    settings: {
      input: InputText,
      actionButton: VinRefreshButton,
    } as ActionInputSettings,
    hasSeparator: true,
  },
  ...CommonInventoryItemFields(),
  [DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID]: {
    label: 'carfax_report_id',
    queryAlias: InventoryItem.CARFAX_REPORT,
    hasSeparator: true,
    groupType: StepFieldType.ACTION_INPUT,
    settings: {
      input: InputText,
      actionButton: CarfaxRefreshButton,
    } as ActionInputSettings,
    clear: { field: TradeInItemModifyParameter._carfaxReportId },
  },
  // This field won't be visible to users, but is required to add a new carfax report
  [DetailsInventoryItemBuilderFields.CARFAX_REPORT_BADGES]: {
    displayType: [StepFieldDisplayType.HIDDEN],
    clear: { field: TradeInItemModifyParameter._carfaxReportBadges, allowEmptyArray: true },
  },
  // Dummy field that must always be false
  [DetailsInventoryItemBuilderFields.CARFAX_REPORT_SHOW_WEB]: {
    displayType: [StepFieldDisplayType.HIDDEN],
    clear: { field: TradeInItemModifyParameter._carfaxReportShowWeb },
  },
  [DetailsInventoryItemBuilderFields.TAG_IDS]: {
    label: 'tags',
    queryAlias: 'tagName',
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.ASYNC],
    subStepAddConfig: SubStepAddConfigs[BuilderType.TAGS_CREATE],
    hasSeparator: true,
  },
  ...commonInventoryItemNoteField,
});

export default DetailsFields;
