import type Tier from 'components/core/createModify/interfaces/tier';
import { BannerStyle } from 'enums/bannerStyle';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import type { PriceField } from 'store/api/graph/interfaces/types';
import { EntityType, PaymentOption } from 'store/api/graph/interfaces/types';
import type { RetailItemResponseType } from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';
import { getPaymentOptionWithPriceBelowMin, getPricingField } from 'utils/inventoryItemUtils';

import ErrorIcon from '../icons/ErrorIcon';
import Banner from '../shared/Banner';

const { t } = translate;

function getStartingPriceField(
  paymentOption: PaymentOption | undefined,
  item?: RetailItemResponseType
): PriceField | undefined {
  const priceFieldMap = {
    [PaymentOption.CASH]: item?.cashRetailPricing?.configuration?.startingPriceField || undefined,
    [PaymentOption.LEASE]: item?.leaseRetailPricing?.configuration?.startingPriceField || undefined,
    [PaymentOption.FINANCE]: item?.financeRetailPricing?.configuration?.startingPriceField || undefined,
  };

  return paymentOption ? priceFieldMap[paymentOption] : undefined;
}

const MinimumDisplayPriceBanner = (item?: RetailItemResponseType) => {
  const { builderConfig } = useBuilderConfig();
  const { findActiveStep, toggleTier } = useCreateModify();
  const { hasPermissions } = useUser();

  const paymentOptionWithPriceBelowMin = getPaymentOptionWithPriceBelowMin(item);
  const startingPriceField = getStartingPriceField(paymentOptionWithPriceBelowMin, item);

  const activeField = getPricingField(startingPriceField);
  const foundActiveStep = findActiveStep(activeField, builderConfig[BuilderType.RETAIL_ITEMS_MODIFY]);
  const tier = {
    itemId: item?.id,
    title: translate.t('modify_x', [item?.type?.toLowerCase() || '']),
    type: BuilderType.RETAIL_ITEMS_MODIFY,
    entityType: EntityType.RETAIL_ITEM,
    activeField,
    isCreating: false,
    ...(!!foundActiveStep && { activeStep: foundActiveStep }),
  };

  return paymentOptionWithPriceBelowMin ? (
    <Banner
      ctaLabel={t('update')}
      icon={<ErrorIcon />}
      onClick={
        hasPermissions(builderConfig[BuilderType.RETAIL_ITEMS_MODIFY].requiredPermissions)
          ? () => {
              toggleTier(CreateModifyTiers.TIER_0, tier as Tier);
            }
          : undefined
      }
      styleVariant={BannerStyle.WARNING}
      title={t('minimum_display_price_banner_title')}
    />
  ) : undefined;
};

export default MinimumDisplayPriceBanner;
