import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import { InventoryItemTypeSettings } from 'components/sections/createModify/inventoryItems/steps/DetailsInventoryItemFields';
import { RetailFilterBuilderFields } from 'components/sections/createModify/shared/steps/interfaces';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import NumberInput from 'components/ui/forms/shared/NumberInput';
import type { RangeInputSettings } from 'components/ui/forms/shared/RangeInput';
import { BuilderType } from 'enums/builderType';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

const RetailFilterFields = defineDetailsFields({
  // Needed to get certain metadata
  [RetailFilterBuilderFields.ROOFTOP_ID]: {
    label: 'rooftops',
    queryAlias: 'rooftopName',
    displayType: [StepFieldDisplayType.HIDDEN, StepFieldDisplayType.OMITTED],
    groupType: StepFieldType.BADGES,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.ASYNC],
    hasSeparator: true,
  },
  [RetailFilterBuilderFields.FILTER_TYPE]: {
    groupType: StepFieldType.TYPES,
    settings: {
      ...InventoryItemTypeSettings,
    },
  },
  [RetailFilterBuilderFields.FILTER_CONDITION]: {
    label: 'condition',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.inventoryItem.condition',
    hasSeparator: true,
  },
  [RetailFilterBuilderFields.FILTER_STATUS]: {
    label: 'status',
    groupType: StepFieldType.TAGS,
    subStep: [SubStepType.DEFAULT],
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    options: 'mutation.inventoryItem.retailItem.status',
    hasSeparator: true,
  },
  [RetailFilterBuilderFields.FILTER_YEAR_RANGE]: {
    label: 'year_one',
    groupType: StepFieldType.RANGE,
    settings: {
      input: NumberInput,
    } as RangeInputSettings,
    hasSeparator: true,
  },
  [RetailFilterBuilderFields.FILTER_DAYS_IN_STOCK]: {
    label: 'days_in_stock',
    groupType: StepFieldType.RANGE,
    settings: {
      input: NumberInput,
    } as RangeInputSettings,
    hasSeparator: true,
  },
  [RetailFilterBuilderFields.FILTER_VEHICLE_ATTRIBUTES_MILEAGE]: {
    label: 'mileage_km',
    groupType: StepFieldType.RANGE,
    settings: {
      input: NumberInput,
    } as RangeInputSettings,
    hasSeparator: true,
  },
  [RetailFilterBuilderFields.FILTER_MOTORCYCLE_ATTRIBUTES_MILEAGE]: {
    label: 'mileage_km',
    groupType: StepFieldType.RANGE,
    settings: {
      input: NumberInput,
    } as RangeInputSettings,
    hasSeparator: true,
  },
  [RetailFilterBuilderFields.FILTER_MAKE_ID]: {
    label: 'included_make',
    queryAlias: 'makeId',
    groupType: StepFieldType.TAGS,
    subStep: [SubStepType.ASYNC],
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    hasSeparator: true,
    options: 'makes',
  },
  [RetailFilterBuilderFields.FILTER_MAKE_ID_EXCLUDED]: {
    label: 'excluded_make',
    queryAlias: 'makeIdExcluded',
    groupType: StepFieldType.TAGS,
    subStep: [SubStepType.ASYNC],
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    hasSeparator: true,
    options: 'makes',
  },
  [RetailFilterBuilderFields.FILTER_MODEL_ID]: {
    label: 'model_one',
    queryAlias: 'modelId',
    groupType: StepFieldType.TAGS,
    subStep: [SubStepType.ASYNC],
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    hasSeparator: true,
    options: 'models',
  },
  [RetailFilterBuilderFields.FILTER_SUBMODEL_ID]: {
    label: 'submodel_one',
    queryAlias: 'subModelId',
    groupType: StepFieldType.TAGS,
    subStep: [SubStepType.ASYNC],
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    hasSeparator: true,
    options: 'subModels',
  },
  [RetailFilterBuilderFields.FILTER_VEHICLE_ATTRIBUTES_BODY_TYPE]: {
    label: 'body_type',
    groupType: StepFieldType.TAGS,
    subStep: [SubStepType.DEFAULT],
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    options: 'mutation.inventoryItem.vehicleAttributes.bodyType',
    hasSeparator: true,
  },
  [RetailFilterBuilderFields.FILTER_VEHICLE_ATTRIBUTES_FUEL_TYPE]: {
    label: 'fuel_type',
    groupType: StepFieldType.TAGS,
    subStep: [SubStepType.DEFAULT],
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    options: 'mutation.inventoryItem.vehicleAttributes.fuelType',
    hasSeparator: true,
  },
  [RetailFilterBuilderFields.FLAGS]: {
    label: 'flags',
    groupType: StepFieldType.FLAGS,
    groupSubTypes: [StepFieldSubType.TOGGLE, StepFieldSubType.FIELD_GROUP],
    subStep: [SubStepType.DEFAULT],
    displayType: [StepFieldDisplayType.OMITTED],
    hasSeparator: true,
  },
  [RetailFilterBuilderFields.FILTER_TAG_ID]: {
    label: 'tags',
    queryAlias: 'filter.tagName',
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.ASYNC],
    subStepAddConfig: SubStepAddConfigs[BuilderType.TAGS_CREATE],
    options: 'tags',
  },
});

export default RetailFilterFields;
