import { useCallback, useEffect } from 'react';

import styled, { css } from 'styled-components/macro';

import { Clickable } from 'components/ui/shared/Button';
import useLinkedList from 'hooks/useLinkedList';

import type { PanelProps } from './Panel';
import { PanelBodyShared, PanelChevronIcon, PanelLabel, PanelValue } from './Panel';

const PanelBodyButton = styled(Clickable)<{ isInteractive?: boolean }>`
  ${PanelBodyShared}
  ${({ isInteractive }) =>
    !isInteractive &&
    css`
      cursor: auto;
    `}
`;

export interface LinkedPanelProps extends PanelProps {
  /** Whether or not this section is nested */
  isNested?: boolean;
  /** Settings for nested view linked list */
  linkedListConfig: any;
  /** The test id to use for reference */
  testId?: string;
}

const LinkedPanel = ({ label, value, linkedListConfig, isNested, testId }: LinkedPanelProps) => {
  const { listEntity, title } = linkedListConfig || {};
  const { showList, closeList, autoClose } = useLinkedList(listEntity, title, isNested);
  const isInteractive = !!value;

  const onPanelClick = useCallback(() => {
    if (!isInteractive) {
      return;
    }

    showList?.();
  }, [showList, isInteractive]);

  // Autoclose panels
  useEffect(() => {
    if (autoClose) {
      closeList?.();
    }
  }, [closeList, autoClose]);

  return (
    <PanelBodyButton data-testid={testId} isInteractive={isInteractive} onClick={onPanelClick}>
      <PanelValue css="text-align: start;">{value}</PanelValue>
      {isInteractive && <PanelChevronIcon />}
      <PanelLabel>{label}</PanelLabel>
    </PanelBodyButton>
  );
};

export default LinkedPanel;
