import type { ElementType } from 'react';
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import type { ApolloError, ServerError } from '@apollo/client';
import { isEqual, isNil } from 'lodash-es';
import styled from 'styled-components/macro';

import NoResultsIcon from 'components/ui/icons/NoResultsIcon';
import type { CondensedListItemProps, CondensedListRef } from 'components/ui/lists/CondensedList';
import CondensedList from 'components/ui/lists/CondensedList';
import CondensedListNestedCreate from 'components/ui/lists/CondensedListNestedCreate';
import Placeholder from 'components/ui/placeholders/Placeholder';
import type { BuilderType } from 'enums/builderType';
import { ListItemType } from 'enums/listItemType';
import type { PaginationProps } from 'enums/pagination';
import { Pagination } from 'enums/pagination';
import { ElementTestId } from 'enums/testing';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useGlobalDialog } from 'hooks/contexts/useGlobalDialog';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { useUser } from 'hooks/contexts/useUser';
import { usePrevious } from 'hooks/usePrevious';
import { getApiErrors } from 'store/api/graph/interfaces/apiErrors';
import { useNestedViewQueryProxy } from 'utils/graphQueryUtils';
import { translate } from 'utils/intlUtils';
import { hasMinimumScope } from 'utils/permissionUtils';
import { deferredRenderCall } from 'utils/renderingUtils';

import type { SecondaryListTabViewProps, SecondaryListViewProps } from './NestedViewSettings';

const { t } = translate;

const CondensedListNestedCreateContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

interface NestedViewListRendererProps {
  /** Configuration needed for rendering this list view (query, queryVars, etc) */
  configuration: SecondaryListViewProps;
  /** Current keyword search */
  keyword?: string;
  /** Callback for when data from the query has loaded */
  onDataLoaded?: (data: any) => void;
  /** Callback for when user wants to navigate to a different page in the list */
  onPaginationChange: (pageInfo: any) => void;
  /** The current pagination state */
  pagination?: PaginationProps;
}

interface QueryResponseItem {
  id: string;
  __typename: string;
  name: string | { value: string };
}

// TODO: Separate details from list type rendering into different sub components
const NestedViewListRenderer = ({
  configuration,
  onPaginationChange,
  pagination,
  keyword,
  onDataLoaded,
}: NestedViewListRendererProps) => {
  const { builderConfig } = useBuilderConfig();
  const {
    viewItems: [viewItem],
    back,
    lastOpenedItem,
  } = useNestedView();
  const { setConfig } = useGlobalDialog();

  // State
  const [, setError] = useState();

  // Refs
  const condensedListRef = useRef<CondensedListRef>(null);

  const variables = {
    after: undefined,
    first: Pagination.LIST_LENGTH,
    ...configuration.queryVars,
    ...pagination,
  };

  const {
    isLoaded,
    isLoading,
    error,
    data,
    data: { metadata, connection },
  } = useNestedViewQueryProxy(
    configuration.query,
    isNil(keyword) ? variables : { ...variables, keyword },
    configuration.useQueryOverride,
    viewItem?.seededData
  );

  // Query derived data
  const { edges, pageInfo } = useMemo(() => connection || { edges: [], pageInfo: {} }, [connection]);
  const items = useMemo<QueryResponseItem[]>(() => edges?.map(edge => edge.node || edge), [edges]);
  const dataPrev = usePrevious(data);
  const isLoaderVisible = isLoading && !!configuration.query;

  // Permissions
  const {
    user: { scope },
    hasPermissions,
  } = useUser();

  const listConfig = viewItem.config as SecondaryListTabViewProps;

  const hasCreatePermission = useMemo(() => {
    let createBuilderType: BuilderType | undefined;
    if (listConfig) {
      if (configuration.createBuilder) {
        createBuilderType = configuration.createBuilder(viewItem.seededData).type as BuilderType;
      } else if (listConfig.tabs?.length) {
        createBuilderType = listConfig.tabs[0]?.createBuilder?.(viewItem.seededData).type as BuilderType;
      }
    }
    return createBuilderType ? hasPermissions(builderConfig[createBuilderType].requiredPermissions) : false;
  }, [builderConfig, configuration, hasPermissions, listConfig, viewItem.seededData]);

  const hasMinimumCreateScope = configuration.minimumRequiredCreateScope
    ? hasMinimumScope(scope, configuration.minimumRequiredCreateScope)
    : true;

  const onPaginate = useCallback(
    paginationInfo => {
      onPaginationChange(paginationInfo);
    },
    [onPaginationChange]
  );

  // Filter items based on keyword if it is using client-side data as opposed to server-side queries
  const filteredItems = useMemo(
    () =>
      isNil(keyword)
        ? items
        : items.filter(
            item =>
              !item.name ||
              (typeof item.name === 'string' ? item.name : item.name.value)
                .toLowerCase()
                .includes(keyword.toLowerCase())
          ),
    [items, keyword]
  );

  const scrollToLastItem = useCallback(() => {
    if (lastOpenedItem && condensedListRef.current) {
      condensedListRef.current.scrollToItemId(lastOpenedItem);
    }
  }, [condensedListRef, lastOpenedItem]);

  useLayoutEffect(() => {
    scrollToLastItem();
  });

  useEffect(() => {
    if (!isEqual(data, dataPrev) && !isLoading && isLoaded) {
      onDataLoaded?.(data);
    }
  }, [onDataLoaded, isLoading, isLoaded, data, dataPrev]);

  if (error?.message) {
    const serverError = (error as ApolloError).networkError as ServerError;
    if (serverError.statusCode === 403) {
      /**
       * Required due to rendering order issues with NestedView
       * calling the global context too early and causing an error
       */
      deferredRenderCall(() => {
        back();
        setConfig({
          errorsOverride: getApiErrors(error),
        });
      });
    } else {
      setError(() => {
        throw new Error(error?.message);
      });
    }
  }

  const RenderElement: ElementType = configuration.renderData;

  const noResultsPlaceholder =
    configuration.noResultsPlaceholderOverride ??
    (() => (
      <>
        <Placeholder
          css="flex: 1;"
          icon={<NoResultsIcon />}
          subtitle={t(
            configuration.noResultsPlaceholderLabels?.subLabelTranslationKey ||
              'dashboard_list_no_results_placeholder_message'
          )}
          title={t(configuration.noResultsPlaceholderLabels?.labelTranslationKey || 'no_results')}
        />
        {hasMinimumCreateScope && hasCreatePermission && (
          <CondensedListNestedCreateContainer>
            <CondensedListNestedCreate
              activeCreateBuilder={configuration.createBuilder}
              createButtonLabel={
                configuration.overrideCreateButtonTranslationKey
                  ? translate.t(configuration.overrideCreateButtonTranslationKey)
                  : undefined
              }
            />
          </CondensedListNestedCreateContainer>
        )}
      </>
    ));

  const listItems = filteredItems.map(item => {
    const itemWithUnclickableCheck: CondensedListItemProps = {
      ...item,
      isUnclickable:
        typeof configuration.isUnclickable === 'function'
          ? configuration.isUnclickable(item)
          : configuration.isUnclickable,
    };
    return itemWithUnclickableCheck;
  });

  return (
    <CondensedList
      condensedListOverride={configuration.containerOverride}
      css="height: 100%"
      data-testid={ElementTestId.NESTED_VIEW_LIST_CONTAINER}
      isLoading={isLoaderVisible}
      isNested={true}
      items={listItems}
      nestedListCreateButton={
        hasMinimumCreateScope && hasCreatePermission && configuration.alwaysShowCreateButton
          ? {
              activeCreateBuilder: configuration.createBuilder,
              createButtonLabel: configuration.overrideCreateButtonTranslationKey,
            }
          : undefined
      }
      noResults={noResultsPlaceholder as ElementType}
      pageInfo={pageInfo}
      ref={condensedListRef}
      renderElement={RenderElement}
      renderSettings={{ metadata, listItemType: ListItemType.CONDENSED_LIST }}
      requestPagination={configuration.disablePagination ? undefined : onPaginate}
    />
  );
};

export default memo(NestedViewListRenderer);
