import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import 'styled-components/macro';

import Label from 'components/core/typography/Label';
import MultiLineText from 'components/core/typography/MultiLineText';
import { userModify } from 'components/sections/createModify/users/UserCreateModifyQuery';
import type { Props as BaseDialogProps } from 'components/ui/dialogs/Dialog';
import Dialog from 'components/ui/dialogs/Dialog';
import { DialogIconContainer } from 'components/ui/dialogs/PromptDialog';
import { OptionButton, OptionsContainer } from 'components/ui/dialogs/PromptDialogOptions';
import CheckIcon from 'components/ui/icons/CheckIcon';
import ErrorIcon from 'components/ui/icons/ErrorIcon';
import { DialogBodyContentContainer, DialogContentContainer, DialogHeader } from 'components/ui/layouts/DialogLayout';
import { ListItems } from 'components/ui/lists/CommonList';
import { Spinner } from 'components/ui/loading/Loader';
import { useUser } from 'hooks/contexts/useUser';
import { getApiErrorsMessage, logApiError } from 'store/api/graph/interfaces/apiErrors';
import { client } from 'store/apollo/ApolloClient';
import { BLUE_500, RED_500 } from 'styles/tokens';
import { MultilingualStringValue, setLocale, translate } from 'utils/intlUtils';

const { t } = translate;

const LanguageToggle = (props: BaseDialogProps) => {
  const {
    user: { id, metadata },
  } = useUser();

  const { onCancel } = useMemo(() => props, [props]);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  const setProcessingRef = useRef(setIsProcessing);

  useEffect(() => {
    setProcessingRef.current = setIsProcessing;
  }, [setIsProcessing]);

  const onLanguageChange = useCallback(
    locale => async () => {
      setProcessingRef.current(true);

      try {
        await client.mutate({
          mutation: userModify,
          variables: {
            id,
            locale,
          },
        });

        setProcessingRef.current(false);
        setIsSuccess(true);
        setLocale(locale);

        // Auto refresh after .7 seconds
        setTimeout(() => window.location.reload(), 700);
      } catch (error: any) {
        setProcessingRef.current(false);
        logApiError(error);
        setErrors(error);
      }
    },
    [setIsSuccess, id]
  );

  const onClose = useCallback(() => {
    onCancel?.();
    setErrors(null);
    setIsProcessing(false);
  }, [onCancel, setErrors, setIsProcessing]);

  // [#2893] Re-enable Spanish support when needed.
  const LanguageListItems = useMemo(
    () =>
      metadata?.locale
        ?.filter(locale => !locale?.id.startsWith(MultilingualStringValue.ES))
        .map(item => ({ label: item.name, id: item.id, onClick: onLanguageChange(item.id) })),
    [metadata, onLanguageChange]
  );

  //  TODO: Extract and standardize these full page error/processings/success states
  return (
    <Dialog {...props} isDismissible={!isProcessing && isSuccess}>
      {errors ? (
        <>
          <OptionsContainer>
            <ErrorIcon color={RED_500} css="padding-bottom: 10px;" height={72} width={72} />
            <MultiLineText>{getApiErrorsMessage(errors)}</MultiLineText>
            <div>
              <OptionButton onClick={onClose}>
                <Label>{t('okay')}</Label>
              </OptionButton>
            </div>
          </OptionsContainer>
        </>
      ) : isProcessing ? (
        <DialogIconContainer>
          <Spinner />
        </DialogIconContainer>
      ) : isSuccess ? (
        <DialogIconContainer css="padding: 0px;">
          <CheckIcon color={BLUE_500} height={48} width={48} />
        </DialogIconContainer>
      ) : (
        <DialogContentContainer css="min-width: 306px;">
          <DialogHeader onClose={onClose} showDivider title={t('language')} />
          <DialogBodyContentContainer>
            <ListItems items={LanguageListItems} />
          </DialogBodyContentContainer>
        </DialogContentContainer>
      )}
    </Dialog>
  );
};

export default LanguageToggle;
