import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

/*
 * Based off of fields from `LeadConnection`
 * TODO: [#1117] Derive from columns in connection
 */
export enum Lead {
  // Details tab
  APPOINTMENTS = 'appointments',
  NOTE = 'note',
  BIRTHDATE = 'birthdate',
  CONTACT_METHOD = 'contactMethod',
  CONTACT_METHOD_NAME = 'contactMethodName',
  CONTACT_TIME = 'contactTime',
  CONTACT_TIME_NAME = 'contactTimeName',
  ROOFTOP_NAME = 'rooftopName',
  GROUP_NAME = 'groupName',
  STATUS = 'status',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  SUMMARY = 'summary',
  PAYMENT_OPTIONS = 'paymentOptionsNames',
  ASSIGNED_TO_NAME = 'assignedToName',
  TAG_NAME = 'tagName',
  TASKS = 'tasks',
  RETAIL_ITEMS_OF_INTEREST = 'retailItemsOfInterest',
  RETAIL_ITEMS_OF_INTEREST_COUNT = 'retailItemsOfInterestCount',
  TRADE_IN_ITEMS_COUNT = 'tradeInItemsCount',
  TRADE_IN_ITEMS = 'tradeInItems',
  APPOINTMENTS_COUNT = 'appointmentsCount',
  CONVERSATIONS_COUNT = 'conversationsCount',
  WALK_IN_COUNT = 'walkInCount',
  TEST_DRIVE_COUNT = 'testDriveCount',
  PHONE_CALL_COUNT = 'phoneCallCount',
  LEADS_COUNT = 'leadsCount',
  ARCHIVED = 'archived',
  SUBSCRIBED_EMAIL = 'subscribedEmail',
  SUBSCRIBED_PROMOTIONS = 'subscribedPromotions',
  CREATED_BY_NAME = 'createdByName',
  LAST_ACTIVE = 'lastActive',
  CREATED = 'created',
  UPDATED = 'updated',
  PURCHASE_ACTIVITY = 'purchaseActivity',
  // Custom referencing: fields that don't exist but used for indexing
  CONTACT = 'contact',
  LOCALE = 'locale',

  // Finance tab - General Information section
  SALUTATION = 'salutation',
  GENDER = 'gender',
  MARITAL_STATUS = 'maritalStatus',
  COMPANY_NAME = 'company',
  DRIVERS_LICENSE_NUMBER = 'driversLicenseNumber',
  DECLARED_BANKRUPTCY = 'declaredBankruptcy',
  COSIGNER = 'cosigner',
  ADDRESS = 'address',
  PREVIOUS_ADDRESS = 'previousAddress',
  CURRENT_HOME_STATUS = 'homeStatus',
  CURRENT_DURATION_MONTHS = 'currentAddressMonths',
  /** Years are calculated from months */
  CURRENT_DURATION_YEARS = 'currentDurationYears',
  PREVIOUS_DURATION_MONTHS = 'previousAddressMonths',
  /** Years are calculated from months */
  PREVIOUS_DURATION_YEARS = 'previousDurationYears',

  // Finance tab - Employment Information section
  /** Use this as a parent for the Employment object fields */
  EMPLOYMENT = 'employment',
  /** Use this as a parent for the Employment object fields */
  PREVIOUS_EMPLOYMENT = 'previousEmployment',
}

export enum Employment {
  TYPE = 'type',
  STATUS = 'status',
  EMPLOYER = 'employer',
  OCCUPATION = 'occupation',
  SIN = 'sin',
  DURATION_MONTHS = 'durationMonths',
  /** Years are calculated from months */
  DURATION_YEARS = 'durationYears',
  WORK_PHONE = 'phoneNumber',
  MONTHLY_INCOME = 'monthlyIncome',
  OTHER_INCOME = 'otherIncome',
  OTHER_INCOME_SOURCE = 'otherIncomeSource',
}

/*
 * Settings with type derived from API docs
 * TODO: [#1117] Refactor when moving over to `columns` in connection for specifying labels
 */
const LeadConfig: TableSettingsType = {
  // Details tab
  [Lead.ROOFTOP_NAME]: { label: 'rooftop', type: FieldDataType.NAME },
  [Lead.NOTE]: { label: 'note_one', type: FieldDataType.DEFAULT, tooltip: 'for_internal_dealer_use_only' },
  [Lead.CONTACT_METHOD]: { label: 'preferred_contact_method', type: FieldDataType.DEFAULT },
  [Lead.CONTACT_TIME]: { label: 'preferred_contact_time', type: FieldDataType.DEFAULT },
  [Lead.CONTACT_METHOD_NAME]: { label: 'preferred_contact_method', type: FieldDataType.DEFAULT },
  [Lead.CONTACT_TIME_NAME]: { label: 'preferred_contact_time', type: FieldDataType.DEFAULT },
  [Lead.BIRTHDATE]: { label: 'date_of_birth', type: FieldDataType.DATE },
  [Lead.GROUP_NAME]: { label: 'group', type: FieldDataType.GROUP_NAME },
  [Lead.STATUS]: { label: 'status', type: FieldDataType.STATUS },
  [Lead.LOCALE]: { label: 'locale', type: FieldDataType.DISPLAY_NAME },
  [Lead.FIRST_NAME]: { label: 'first_name', type: FieldDataType.DEFAULT },
  [Lead.LAST_NAME]: { label: 'last_name', type: FieldDataType.DEFAULT },
  [Lead.EMAIL]: { label: 'email_one', type: FieldDataType.DEFAULT },
  [Lead.PHONE_NUMBER]: { label: 'phone_number_one', type: FieldDataType.DEFAULT },
  [Lead.SUMMARY]: { label: 'summary', type: FieldDataType.DEFAULT },
  [Lead.PAYMENT_OPTIONS]: { label: 'payment_option_other', type: FieldDataType.BADGE_LIST },
  [Lead.ASSIGNED_TO_NAME]: { label: 'assigned_to', type: FieldDataType.USER_NAME },
  [Lead.TAG_NAME]: { label: 'tags', type: FieldDataType.BADGE_LIST },
  [Lead.RETAIL_ITEMS_OF_INTEREST_COUNT]: { label: 'items_of_interest_count', type: FieldDataType.DEFAULT }, // TODO: confirm label
  [Lead.TRADE_IN_ITEMS_COUNT]: { label: 'trade_in_items_count', type: FieldDataType.DEFAULT },
  [Lead.APPOINTMENTS_COUNT]: { label: 'appointments_count', type: FieldDataType.DEFAULT },
  [Lead.CONVERSATIONS_COUNT]: { label: 'conversations', type: FieldDataType.DEFAULT },
  [Lead.WALK_IN_COUNT]: { label: 'walk_ins', type: FieldDataType.DEFAULT },
  [Lead.TEST_DRIVE_COUNT]: { label: 'test_drives', type: FieldDataType.DEFAULT },
  [Lead.LEADS_COUNT]: { label: 'lead_activity_other', type: FieldDataType.DEFAULT },
  [Lead.PHONE_CALL_COUNT]: { label: 'phone_calls', type: FieldDataType.DEFAULT },
  [Lead.ARCHIVED]: {
    label: 'archived',
    type: FieldDataType.BOOLEAN,
  },
  [Lead.SUBSCRIBED_EMAIL]: {
    label: 'email_subscription',
    type: FieldDataType.BOOLEAN,
    yesNoOverride: { yesTranslationKey: 'subscribed', noTranslationKey: 'unsubscribed' },
  },
  [Lead.SUBSCRIBED_PROMOTIONS]: {
    label: 'promotion_subscription',
    type: FieldDataType.BOOLEAN,
    yesNoOverride: { yesTranslationKey: 'subscribed', noTranslationKey: 'unsubscribed' },
  },
  [Lead.CREATED_BY_NAME]: { label: 'created_by', type: FieldDataType.USER_NAME },
  [Lead.LAST_ACTIVE]: { label: 'last_active', type: FieldDataType.DAY_TIME },
  [Lead.CREATED]: { label: 'created', type: FieldDataType.DAY_TIME },
  [Lead.UPDATED]: { label: 'updated', type: FieldDataType.DAY_TIME },
  // Custom referencing: fields that don't exist but used for indexing
  [Lead.CONTACT]: { label: 'contact', type: FieldDataType.USER_NAME },

  // Finance tab - General Information section
  [Lead.SALUTATION]: { label: 'salutation', type: FieldDataType.ENUM },
  [Lead.GENDER]: { label: 'gender', type: FieldDataType.ENUM },
  [Lead.MARITAL_STATUS]: { label: 'marital_status', type: FieldDataType.ENUM },
  [Lead.COMPANY_NAME]: { label: 'company_name', type: FieldDataType.DEFAULT },
  [Lead.DRIVERS_LICENSE_NUMBER]: { label: 'drivers_license_number', type: FieldDataType.DEFAULT },
  [Lead.DECLARED_BANKRUPTCY]: { label: 'declared_bankruptcy', type: FieldDataType.BOOLEAN },
  [Lead.COSIGNER]: { label: 'cosigner', type: FieldDataType.BOOLEAN },
  [Lead.ADDRESS]: { label: 'address', type: FieldDataType.LOCATION },
  [Lead.CURRENT_HOME_STATUS]: { label: 'home_status', type: FieldDataType.ENUM },
  [Lead.CURRENT_DURATION_MONTHS]: { label: 'duration_months', type: FieldDataType.DEFAULT },
  [Lead.CURRENT_DURATION_YEARS]: { label: 'duration_years', type: FieldDataType.DEFAULT },
  [Lead.PREVIOUS_ADDRESS]: { label: 'previous_address', type: FieldDataType.LOCATION },
  [Lead.PREVIOUS_DURATION_MONTHS]: { label: 'duration_months', type: FieldDataType.DEFAULT },
  [Lead.PREVIOUS_DURATION_YEARS]: { label: 'duration_years', type: FieldDataType.DEFAULT },
};

const BaseEmploymentConfig: TableSettingsType = {
  [Employment.DURATION_MONTHS]: { label: 'duration_months', type: FieldDataType.DEFAULT },
  [Employment.DURATION_YEARS]: { label: 'duration_years', type: FieldDataType.DEFAULT },
  [Employment.EMPLOYER]: { label: 'employer', type: FieldDataType.DEFAULT },
  [Employment.MONTHLY_INCOME]: { label: 'monthly_income', type: FieldDataType.MONETARY },
  [Employment.OCCUPATION]: { label: 'occupation', type: FieldDataType.DEFAULT },
  [Employment.OTHER_INCOME_SOURCE]: { label: 'other_income_source', type: FieldDataType.DEFAULT },
  [Employment.OTHER_INCOME]: { label: 'other_income', type: FieldDataType.MONETARY },
  [Employment.STATUS]: { label: 'status', type: FieldDataType.ENUM },
  [Employment.TYPE]: { label: 'type', type: FieldDataType.ENUM },
  [Employment.WORK_PHONE]: { label: 'work_phone', type: FieldDataType.DEFAULT },
};

const EmploymentConfig: TableSettingsType = {
  ...prefixKeysWithParent(Lead.EMPLOYMENT, BaseEmploymentConfig),
  [Employment.SIN]: { label: 'sin', type: FieldDataType.DEFAULT },
};

const PreviousEmploymentconfig: TableSettingsType = prefixKeysWithParent(
  Lead.PREVIOUS_EMPLOYMENT,
  BaseEmploymentConfig
);

/**
 * Prefixes all keys in the given config with a parent selector.
 * @example prefixKeysWithParent('c', [{ 'a': {...}, 'b': {...} }]) -> [{ 'c.a': {...}, 'c.b': {...} }]
 */
function prefixKeysWithParent(parentSelector: string, config: TableSettingsType): TableSettingsType {
  return Object.fromEntries(Object.entries(config).map(([k, v]) => [`${parentSelector}.${k}`, v]));
}

export const LeadSettings = translateFields(LeadConfig);
export const CurrentEmploymentSettings = translateFields(EmploymentConfig);
export const PreviousEmploymentSettings = translateFields(PreviousEmploymentconfig);
