import LinkedFields from 'components/sections/createModify/shared/steps/LinkedFields';
import { Lead } from 'enums/columns/lead';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

const LeadLinkedFields = defineDetailsFields({
  assignedToId: {
    ...LinkedFields.assignedToId,
    queryAlias: Lead.ASSIGNED_TO_NAME,
    hasSeparator: true,
  },
});

export default LeadLinkedFields;
