import { cloneDeep, set } from 'lodash-es';

import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { tagsMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { tagDetailQuery } from 'containers/rooftops/tags/RooftopTagsContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import { client } from 'store/apollo/ApolloClient';
import type { FilterTagParams } from 'utils/filterUtils';
import { filterTagItems } from 'utils/filterUtils';

const RooftopTagsQueryMethods: QueryMethods = {
  getMetaData: async (onMetaUpdate, defaultData, userContext, seededData) => {
    const observable = client.watchQuery({
      query: tagsMetaQuery,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });

    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: res => {
          const metadata = res.data.metadata;
          const bundle = seededData?.rooftop || defaultData?.rooftop;

          const filterTags: FilterTagParams = {
            response: metadata.mutation.item.type,
            rooftop: bundle,
            flags: userContext?.featureFlags,
          };

          const filteredTags = filterTagItems(filterTags);

          const filteredResponse = cloneDeep(metadata);
          set(filteredResponse, 'mutation.item.type', filteredTags);

          resolve({ subscription, metadata: filteredResponse });
          onMetaUpdate(filteredResponse);
        },
        error: (error: Error) => {
          logApiError(error);
          reject(error);
        },
      });
    });
  },
  getData: async (id: string) => {
    const response = await client.query({
      query: tagDetailQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },
  refetchQueries: ['RooftopConnectionQuery', 'RooftopDetailsContainerQuery', 'SettingsNavigationItemCountQuery'],
};

export default RooftopTagsQueryMethods;
