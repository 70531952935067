import { useCallback, useEffect, useMemo, useRef } from 'react';

import { gql } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { isEqual, orderBy, unionBy, uniqBy } from 'lodash-es';

import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { getStoredTableConfiguration } from 'components/ui/tables/TableHelpers';
import { TableType } from 'components/ui/tables/tableSettings/TableSettings';
import { ApolloFetchPolicy } from 'enums/apollo';
import { Conversation } from 'enums/columns/conversation';
import { ItemsColumn } from 'enums/itemsColumn';
import { useUser } from 'hooks/contexts/useUser';
import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import type { CondensedListSubscriptionConfig } from 'hooks/useSubscription';
import useSubscription, { useCondensedListSubscription } from 'hooks/useSubscription';
import { colorMetadata } from 'store/api/graph/fragments/colorMetadata';
import { columns } from 'store/api/graph/fragments/columns';
import { conversationDetail } from 'store/api/graph/fragments/conversationDetail';
import { conversationItem } from 'store/api/graph/fragments/conversationItem';
import { conversationList } from 'store/api/graph/fragments/conversationList';
import { filters } from 'store/api/graph/fragments/filters';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import { tag } from 'store/api/graph/fragments/tag';
import { userName } from 'store/api/graph/fragments/userName';
import type { ConversationDetailFragment, ConversationItem } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parseConnectionParams } from 'utils/apiUtils';
import { isLoggedIn } from 'utils/authUtils';
import { gqlFormatTableColumnFields } from 'utils/gqlUtils';

export const conversationDetailsQuery = gql`
  query ConversationDetailsContainerQuery($id: ID!) {
    ## Important: 'item' alias is required for data reading
    item: conversation(id: $id) {
      ...ConversationDetailFragment
    }
    metadata {
      ...ColorMetadataFragment
    }
  }
  ${conversationDetail}
  ${colorMetadata}
`;

export const conversationCondensedListQuery = gql`
  query ConversationCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $filter: ConversationConnectionFilterInput
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: conversationConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...ConversationListFragment
        }
      }
    }
  }
  ${conversationList}
  ${pageInfo}
`;

export const conversationContainerQuery = gql`
  query ConversationConnectionQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $searchFilter: FacetSearchInput
    $filter: ConversationConnectionFilterInput
    $d_groupNameOn: Boolean!
    $d_rooftopNameOn: Boolean!
    $d_participantsOn: Boolean!
    $d_tagsOn: Boolean!
    $d_hasAttachmentsOn: Boolean!
    $d_createdOn: Boolean!
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: conversationConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      columns {
        ...ColumnsFragment
      }
      filters {
        ...FiltersFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...ConversationListFragment
          groupName: rooftop @include(if: $d_groupNameOn) {
            id
            group {
              id
              name {
                ...MultilingualStringFragment
              }
            }
          }
          rooftopName: rooftop @include(if: $d_rooftopNameOn) {
            id
            name {
              ...MultilingualStringFragment
            }
          }
          participants @include(if: $d_participantsOn) {
            ...UserNameFragment
          }
          tags @include(if: $d_tagsOn) {
            ...TagFragment
          }
          hasAttachments @include(if: $d_hasAttachmentsOn)
          created @include(if: $d_createdOn)
        }
      }
    }
  }
  ${columns}
  ${filters}
  ${pageInfo}
  ${MultilingualString}
  ${tag}
  ${userName}
  ${conversationList}
`;

const staticColumns = [
  ItemsColumn.SELECT,
  ItemsColumn.PHOTOS,
  Conversation.TYPE,
  Conversation.LEAD_NAME,
  Conversation.MESSAGE_SNIPPET,
  Conversation.ITEMS_COUNT,
  Conversation.SUBJECT,
  Conversation.READ_BY_ME,
  Conversation.REPLIED,
  Conversation.ASSIGNED_TO_NAME,
] as string[];

const conversationSubscriptionQuery = gql`
  subscription ConversationSubscriptionQuery($id: ID!) {
    item: onConversationItemCreated(conversationId: $id) {
      id
      previousId
      conversationId
      conversationItem {
        ...ConversationItemFragment
      }
      timestamp
    }
  }
  ${conversationItem}
`;

const conversationsListSubscriptionQuery = gql`
  subscription ConversationsListSubscriptionQuery {
    item: onConversationConnectionUpdated {
      id
      previousId
      updatedItem: conversation {
        ...ConversationListFragment
      }
    }
  }
  ${conversationList}
`;

export const conversationListSubscriptionConfig: CondensedListSubscriptionConfig = {
  subscriptionQuery: conversationsListSubscriptionQuery,
  ignoredFilters: [Conversation.ARCHIVED_BY_ME as string],
  sortBy: { target: 'lastItemCreated', dir: 'desc' },
};

export const useConversationConnectionQuery = (variables = {}, options?: any): CustomQueryResult => {
  const formattedConnectionParams = useMemo(() => parseConnectionParams(variables), [variables]);
  const tableConfigurationNext: TableCellData[] = getStoredTableConfiguration(TableType.CONVERSATIONS);
  const formattedTableColumns = gqlFormatTableColumnFields(
    conversationContainerQuery,
    tableConfigurationNext,
    staticColumns
  );
  const containerQueryVariables = { ...formattedConnectionParams, ...formattedTableColumns };
  const response = useQuery(conversationContainerQuery, {
    variables: containerQueryVariables,
    options,
  });
  const {
    refetch,
    data: { connection },
    isLoaded,
  } = useMemo(() => response, [response]);
  const { isWhiteLabelScoped } = useUser();

  useCondensedListSubscription(
    conversationListSubscriptionConfig,
    containerQueryVariables,
    conversationContainerQuery,
    connection?.edges.map(edge => edge.node),
    refetch,
    !isWhiteLabelScoped && isLoaded
  );

  return response;
};

// Writing conversation items to the Apollo cache directly
export const writeToConversationItemsCache = (newItems: ConversationItem[], conversationId: string) => {
  if (!isLoggedIn()) {
    return null;
  }

  const id = `Conversation:${conversationId}`;
  const data = client.readFragment<ConversationDetailFragment>({
    id,
    fragment: conversationDetail,
    fragmentName: 'ConversationDetailFragment',
  });

  // If the cache is missing data for any of the query's fields, readFragment returns null
  if (!data) {
    return;
  }

  const items = uniqBy([...newItems, ...data.items].flat(), 'id');

  client.writeFragment({
    id,
    fragment: conversationDetail,
    fragmentName: 'ConversationDetailFragment',
    data: {
      ...data,
      items,
    },
  });

  return items;
};

export const useConversationDetailsQuery = (query: DocumentNode, config: QueryConfig): CustomQueryResult => {
  const response = useQuery(query, config);
  const prevConvoItems = useRef(response.data.item?.items);
  const subscriptionVariables = useMemo(
    () => (config.variables.id ? { id: config.variables.id } : undefined),
    [config.variables.id]
  );

  // Silently updates the cache with new data from the server
  const silentRefetch = useCallback(() => {
    void client.query({
      query,
      variables: config.variables,
      ...config.options,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });
  }, [query, config.variables, config.options]);

  // On subscription update
  const onUpdate = useCallback(
    ({ conversationItem }) =>
      (prevConvoItems.current = writeToConversationItemsCache([conversationItem], config.variables.id)),
    [config.variables.id]
  );

  // Merges old and new datasets if they are out of sync from a modify or load update
  useEffect(() => {
    const newItems = response.data.item?.items;
    if (
      !!prevConvoItems.current &&
      response.data.item.id === config.variables.id &&
      !isEqual(prevConvoItems.current.item?.items, newItems)
    ) {
      // Data has changed, merge if necessary
      const items = orderBy(unionBy(prevConvoItems.current, newItems as ConversationItem[], 'id'), 'created', 'desc');
      // Clearing out previous items as it is now being merged
      prevConvoItems.current = [];

      // Write merged items
      writeToConversationItemsCache(items, config.variables.id);
    }
  }, [response.data.item, config.variables]);

  useSubscription(conversationSubscriptionQuery, subscriptionVariables, onUpdate, silentRefetch);

  return response;
};
