import { memo, type SVGProps } from 'react';

const LinkExternalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 20 20" width={20} {...props}>
    <path
      d="M4.226 17.67c-.525 0-.972-.186-1.342-.555a1.827 1.827 0 0 1-.554-1.342V4.226c0-.525.185-.972.554-1.341.37-.37.817-.555 1.342-.555h4.826c.266 0 .49.092.673.275a.916.916 0 0 1 .274.673c0 .266-.091.49-.274.674a.916.916 0 0 1-.673.274H4.226v11.547h11.547v-4.825c0-.266.091-.49.274-.674a.915.915 0 0 1 .674-.274c.266 0 .49.091.673.274a.915.915 0 0 1 .275.674v4.825c0 .525-.185.972-.555 1.342-.37.37-.816.554-1.341.554H4.226ZM15.773 5.546l-6.878 6.878a.885.885 0 0 1-.658.261.902.902 0 0 1-.658-.266.896.896 0 0 1-.264-.66c0-.265.088-.485.264-.661l6.873-6.873h-1.838a.916.916 0 0 1-.673-.274.916.916 0 0 1-.275-.674c0-.266.092-.49.275-.673a.916.916 0 0 1 .673-.275h4.107c.266 0 .49.092.673.275a.916.916 0 0 1 .275.673v4.107c0 .266-.092.49-.275.674a.916.916 0 0 1-.673.274.915.915 0 0 1-.674-.274.916.916 0 0 1-.274-.674V5.547Z"
      fill="currentcolor"
    />
  </svg>
);

export default memo(LinkExternalIcon);
