import { RooftopLeaseSegmentAvailableMileageAllowanceBuilderFields } from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/interfaces';
import { StepFieldType } from 'enums/stepFieldType';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

const AvailableMileageAllowanceFields = defineDetailsFields({
  [RooftopLeaseSegmentAvailableMileageAllowanceBuilderFields.AVAILABLE_MILEAGE_ALLOWANCES]: {
    label: 'mileage_allowance',
    groupType: StepFieldType.MILEAGE,
  },
});

export default AvailableMileageAllowanceFields;
