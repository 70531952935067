import { gql } from '@apollo/client';

import { MultilingualString } from 'store/api/graph/fragments/multilingualString';

export const groupSettingsDetailsQuery = gql`
  query GroupSettingsDetailsQuery($id: ID!) {
    group(id: $id) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${MultilingualString}
`;

export const groupSettingsListQuery = gql`
  query GroupSettingsListQuery($first: Int = 100, $keyword: String) {
    groupConnection(first: $first, keyword: $keyword) {
      edges {
        node {
          id
          name {
            ...MultilingualStringFragment
          }
        }
      }
    }
  }

  ${MultilingualString}
`;

export const rooftopSettingsDetailsQuery = gql`
  query RooftopSettingsDetailsQuery($id: ID!) {
    rooftop(id: $id) {
      id
      name {
        ...MultilingualStringFragment
      }
      group {
        id
        name {
          ...MultilingualStringFragment
        }
      }
      shoppingToolsConfiguration {
        buildAndPriceConfiguration {
          __typename
        }
        showroomConfiguration {
          __typename
        }
      }
      bundle {
        features {
          appointment {
            enabled
          }
          lead {
            conversations
            enabled
            forwarding
          }
          oem
          retail {
            buildAndPrice
            enabled
            paymentOptions
            showroom
          }
          retailPortal {
            enabled
          }
          task {
            enabled
          }
          tradeIn {
            enabled
          }
        }
      }
    }
  }
  ${MultilingualString}
`;

export const rooftopSettingsListQuery = gql`
  query RooftopSettingsListQuery($first: Int = 100, $keyword: String, $groupId: ID) {
    rooftopConnection(first: $first, keyword: $keyword, filter: { groupId: $groupId }) {
      edges {
        node {
          id
          name {
            ...MultilingualStringFragment
          }
        }
      }
    }
  }

  ${MultilingualString}
`;

export const settingsNavigationItemCount = gql`
  query SettingsNavigationItemCountQuery(
    $rooftopId: ID!
    $groupId: ID!
    $d_hasRooftopId: Boolean!
    $d_hasGroupId: Boolean!
  ) {
    _otherCounts: rooftop(id: $rooftopId) @include(if: $d_hasRooftopId) {
      tagsCount
    }

    contacts: contactConnection(first: 1, filter: { rooftopId: [$rooftopId] }) @include(if: $d_hasRooftopId) {
      pageInfo {
        totalEdges
      }
    }

    bulkAdjustments: retailBulkAdjustmentConnection(first: 1, filter: { rooftopId: [$rooftopId] })
      @include(if: $d_hasRooftopId) {
      pageInfo {
        totalEdges
      }
    }
    users: userConnection(first: 1, filter: { groupId: $groupId }) @include(if: $d_hasGroupId) {
      pageInfo {
        totalEdges
      }
    }
  }
`;
