import { memo } from 'react';

import { isString } from 'lodash-es';
import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import TertiaryText from 'components/core/typography/TertiaryText';
import Text from 'components/core/typography/Text';
import TextRow from 'components/core/typography/TextRow';
import EditIcon from 'components/ui/icons/EditIcon';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import {
  type MultilingualString,
  type RetailPriceAdjustment,
  RetailPriceAdjustmentType,
} from 'store/api/graph/interfaces/types';
import { GREEN_500, RED_500 } from 'styles/tokens';
import { FONT_WEIGHT_BOLD } from 'styles/typography';
import { translate } from 'utils/intlUtils';
import { decimalPercentToString } from 'utils/stringUtils';

const { t } = translate;

interface Props extends ListItemProps, Omit<RetailPriceAdjustment, 'label'> {
  label: string | MultilingualString;
}

const PriceAjdustmentTextRow = styled(TextRow)<{ $amount: number }>`
  ${PrimaryText} {
    color: ${({ $amount }) => ($amount < 0 ? RED_500 : GREEN_500)};
  }

  ${TertiaryText} {
    font-weight: ${FONT_WEIGHT_BOLD};
  }
`;

/**
 * Note about the typings: This list item is used in the RetailItemPricingBuilder to display the list of
 * adjustment options. Since that list uses a multi-select StepFieldSubType, the options need to include
 * a string type 'label' property for searching purposes. However, RetailPriceAdjustment also has a 'label'
 * property, but it's a MultilingualString type. As a workaround, the typing for this component accepts both
 * string and MultilingualString label types.
 */
const PriceAdjustmentListItem = ({ id, label, fixed, percentage, listItemType, type, onEdit }: Props) => {
  const amount: number | undefined = fixed ? fixed.amount : percentage?.valueOf();
  const amountFormatted = fixed ? fixed.formattedAmountRounded : decimalPercentToString(percentage as number);

  return (
    <ListItem css="width: 100%" listItemType={listItemType}>
      <ListItemDetails>
        {amount && (
          <PriceAjdustmentTextRow $amount={amount}>
            <PrimaryText>{amountFormatted}</PrimaryText>
            <TertiaryText>&bull; {amount < 0 ? t('discount') : t('fee')}</TertiaryText>
          </PriceAjdustmentTextRow>
        )}
        <TextRow>
          <Text>{isString(label) ? label : label?.value}</Text>
        </TextRow>
      </ListItemDetails>
      {/* TODO: Refactor ListItem to accept icons with onClick callbacks */}
      {type !== RetailPriceAdjustmentType.AUTO && (
        <div>
          <EditIcon
            onClick={e => {
              e.stopPropagation();
              onEdit?.(id);
            }}
          />
        </div>
      )}
    </ListItem>
  );
};

export default memo(PriceAdjustmentListItem);
