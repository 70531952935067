import type { ReactNode } from 'react';
import { Children, memo, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import { TertiaryLabel } from 'components/core/typography/Label';
import ChevronDownIcon from 'components/ui/icons/ChevronDownIcon';
import ChevronUpIcon from 'components/ui/icons/ChevronUpIcon';
import TrashCanIcon from 'components/ui/icons/TrashCanIcon';
import TooltipButton from 'components/ui/shared/TooltipButton';
import { ElementTestId } from 'enums/testing';
import { BODY_TEXT } from 'styles/color';
import { ENTITY_PADDING } from 'styles/spacing';

import type { CollapsibleSectionProps } from './CollapsibleSection';
import {
  BottomBorder,
  ChildrenContainer,
  EditIconWrapper,
  RowContainer,
  SectionTogglerTitle,
  SectionTogglerWrapper,
} from './CollapsibleSection';

const DeleteIconWrapper = styled(TrashCanIcon)`
  color: ${BODY_TEXT};
  cursor: pointer;
`;

interface CollapsibleRouteSectionProps extends CollapsibleSectionProps {
  /** Callback function when user clicks delete icon */
  onDelete?: () => void;
  /** A prop for rendering JSX above the title for the collapsible section */
  bannerJSX?: ReactNode;
}

/**
 * A section that can be collapsed.
 */
const CollapsibleRouteSection = memo<CollapsibleRouteSectionProps>(
  ({
    onEdit,
    onDelete,
    label,
    children = null,
    addBottomBorder = true,
    nestedLevel = 0,
    tooltipInfoText,
    testId,
    renderSummary,
    bannerJSX,
  }) => {
    const [isCollapsed, setCollapsed] = useState<boolean>(true);

    // We have children if not: null | null[]
    const hasChildren = Children.toArray(children).some(Boolean);

    /*
     * The border under the section label will always be visible when expanded, except when there are no children to
     * display. This prevents an issue where a collapsible section with no content will appear to have a double
     * bottom border when expanded.
     */
    const showSectionLabelBorder = isCollapsed ? addBottomBorder : hasChildren;
    return (
      <div data-testid={testId}>
        <SectionTogglerWrapper
          canExpand={hasChildren}
          nestedLevel={nestedLevel}
          onClick={() => {
            // If there are no children, then don't expand
            if (hasChildren) {
              setCollapsed(!isCollapsed);
            }
          }}
        >
          {bannerJSX}
          <SectionTogglerTitle>
            <RowContainer>
              <TertiaryLabel>{label}</TertiaryLabel>
              {tooltipInfoText && (
                <TooltipButton
                  tooltip={{
                    margin: { x: 0, y: 8 },
                  }}
                >
                  {tooltipInfoText}
                </TooltipButton>
              )}
            </RowContainer>
            <RowContainer>
              {hasChildren &&
                (isCollapsed ? <ChevronDownIcon color={BODY_TEXT} /> : <ChevronUpIcon color={BODY_TEXT} />)}
              {!!onEdit && (
                <EditIconWrapper
                  data-testid={`${ElementTestId.EDIT_ICON}-${testId}`}
                  height={16}
                  onClick={e => {
                    e.stopPropagation();
                    onEdit();
                  }}
                  width={16}
                />
              )}
              {!!onDelete && (
                <DeleteIconWrapper
                  data-testid={`${ElementTestId.DELETE_ICON}-${testId}`}
                  height={16}
                  onClick={e => {
                    e.stopPropagation();
                    onDelete();
                  }}
                  width={16}
                />
              )}
            </RowContainer>
          </SectionTogglerTitle>
          {renderSummary?.()}
        </SectionTogglerWrapper>
        {showSectionLabelBorder && (
          <BottomBorder
            css={css`
              margin-right: ${nestedLevel ? ENTITY_PADDING : 0};
            `}
          />
        )}

        <ChildrenContainer
          data-testid={testId ? `${testId}-children` : undefined}
          isHidden={isCollapsed || !hasChildren}
          nestedLevel={nestedLevel}
        >
          {children}
        </ChildrenContainer>

        {!isCollapsed && addBottomBorder && (
          <BottomBorder
            css={css`
              margin-right: ${nestedLevel ? ENTITY_PADDING : 0};
            `}
          />
        )}
      </div>
    );
  }
);

export default CollapsibleRouteSection;
