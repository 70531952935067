import NoConnectionIcon from 'components/ui/icons/NoConnectionIcon';
import Placeholder from 'components/ui/placeholders/Placeholder';
import { BODY_TEXT } from 'styles/color';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const NoConnection = () => (
  <Placeholder
    buttonText={t('refresh')}
    icon={<NoConnectionIcon color={BODY_TEXT} height={64} width={64} />}
    onClick={() => window.location.reload()}
    subtitle={t('connection_error_placeholder_message')}
    title={t('no_connection')}
  />
);

export default NoConnection;
