import { memo, type SVGProps } from 'react';

const DraggableIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M8.912 20.185a2.02 2.02 0 0 1-1.481-.616 2.02 2.02 0 0 1-.616-1.481c0-.577.205-1.069.616-1.477.41-.407.904-.611 1.481-.611.577 0 1.069.204 1.476.611.408.408.612.9.612 1.477 0 .577-.204 1.07-.611 1.481-.408.41-.9.616-1.477.616Zm6.18 0a2.012 2.012 0 0 1-1.478-.616A2.022 2.022 0 0 1 13 18.088c0-.577.205-1.069.614-1.477.41-.407.901-.611 1.476-.611.576 0 1.07.204 1.48.611.41.408.615.9.615 1.477 0 .577-.205 1.07-.614 1.481-.41.41-.903.616-1.479.616Zm-6.18-6.095c-.577 0-1.07-.205-1.481-.614A2.009 2.009 0 0 1 6.815 12c0-.578.205-1.07.616-1.478.41-.408.904-.612 1.481-.612.577 0 1.069.204 1.476.612.408.407.612.9.612 1.475a2.02 2.02 0 0 1-.611 1.478c-.408.41-.9.615-1.477.615Zm6.18 0a2.015 2.015 0 0 1-1.478-.614A2.01 2.01 0 0 1 13 12c0-.578.205-1.07.614-1.478.41-.408.901-.612 1.476-.612.576 0 1.07.204 1.48.612.41.407.615.9.615 1.475 0 .576-.205 1.069-.614 1.478-.41.41-.903.615-1.479.615ZM8.912 8c-.577 0-1.07-.205-1.481-.614a2.01 2.01 0 0 1-.616-1.476c0-.576.205-1.07.616-1.48a2.02 2.02 0 0 1 1.481-.615c.577 0 1.069.205 1.476.615.408.41.612.902.612 1.478a2.02 2.02 0 0 1-.611 1.478c-.408.41-.9.614-1.477.614Zm6.18 0a2.015 2.015 0 0 1-1.478-.614A2.012 2.012 0 0 1 13 5.91c0-.576.205-1.07.614-1.48a2.01 2.01 0 0 1 1.476-.615c.576 0 1.07.205 1.48.615.41.41.615.902.615 1.478 0 .575-.205 1.068-.614 1.478-.41.41-.903.614-1.479.614Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(DraggableIcon);
