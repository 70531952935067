import { memo, type SVGProps } from 'react';

const AttachmentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M18.311 15.81c0 1.785-.636 3.297-1.909 4.536-1.272 1.238-2.805 1.857-4.598 1.857-1.793 0-3.324-.619-4.592-1.857-1.269-1.24-1.903-2.751-1.903-4.536V6.428c0-1.294.46-2.389 1.378-3.286.919-.897 2.03-1.345 3.331-1.345 1.302 0 2.41.448 3.325 1.345s1.372 1.992 1.372 3.286v8.876c0 .802-.283 1.481-.85 2.036a2.838 2.838 0 0 1-2.061.833c-.807 0-1.492-.277-2.055-.832-.563-.556-.845-1.235-.845-2.037V7.16c0-.31.107-.573.32-.787a1.07 1.07 0 0 1 .788-.32c.311 0 .574.107.787.32.214.214.32.476.32.788v8.142c0 .193.065.35.195.474a.68.68 0 0 0 .49.186.709.709 0 0 0 .495-.183c.134-.121.201-.28.201-.477v-8.87a2.39 2.39 0 0 0-.735-1.727c-.476-.467-1.059-.701-1.747-.701a2.46 2.46 0 0 0-1.763.698 2.29 2.29 0 0 0-.731 1.724v9.382c-.013 1.175.403 2.166 1.246 2.973.843.807 1.856 1.211 3.04 1.211 1.17 0 2.171-.404 3.002-1.21.83-.808 1.258-1.799 1.284-2.974V6.934c0-.31.107-.573.32-.787a1.07 1.07 0 0 1 .787-.32c.311 0 .574.107.788.32.213.214.32.476.32.787v8.876Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(AttachmentIcon);
