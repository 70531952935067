import { Fragment, useCallback, useMemo } from 'react';

import { get } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';
import { multilineCss } from 'components/core/typography/MultiLineText';
import Text from 'components/core/typography/Text';
import { Clickable } from 'components/ui/shared/Button';
import Swatch from 'components/ui/shared/Swatch';
import { ElementTestId, listSelectionItemTestId } from 'enums/testing';
import { DIVIDER } from 'styles/color';
import { LIST_ITEM_HEIGHT } from 'styles/layouts';
import { NEUTRAL_300 } from 'styles/tokens';
import { getGroupedOptions } from 'utils/formatting/createModifyFormatUtils';
import { formatColorFromData } from 'utils/formatUtils';

import type { ListSelectionOptionsProps } from './interfaces';
import ListSelectionCheckbox from './ListSelectionCheckbox';
import ListSelectionGroupHeader from './ListSelectionGroupHeader';
import { isGroupChecked, isGroupFullyDisabled, isGroupIndeterminate, onSelectAll } from './listSelectionUtils';

const ListItem = styled(Clickable)`
  display: flex;
  width: 100%;
  height: auto;
  min-height: ${LIST_ITEM_HEIGHT};
  padding: 0 15px;
  align-items: center;
  border-bottom: 1px solid ${DIVIDER};

  ${Text} {
    margin-right: auto;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const ColorBadge = styled(Swatch)`
  width: 25px;
  height: 25px;
  margin-left: auto;
`;

export const OptionText = styled(Text)<{ disabled?: boolean }>`
  ${multilineCss}
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${NEUTRAL_300};
    `}
`;

// TODO: [#2269] ListSelection grouped options
const ListSelectionOptions = ({
  selectedOptions = [],
  filteredOptions = [],
  subStepGroups,
  onSelect = () => {},
  isColor = false,
  isMultiSelect = false,
  onSelectGroup,
  baseClassName,
  styleVariant,
  label,
}: ListSelectionOptionsProps) => {
  // Categorizing by group if applicable
  const filteredGroups = useMemo(
    () => getGroupedOptions(filteredOptions, subStepGroups),
    [subStepGroups, filteredOptions]
  );

  const itemIsActive = useCallback(
    (itemId: string) => !!selectedOptions.some(entry => entry === itemId || entry?.id === itemId),
    [selectedOptions]
  );

  const handleSelectAll = useCallback(
    (id: string | undefined, groupItems: SubStepOption[]) => {
      onSelectAll(id, selectedOptions, groupItems, onSelect);
    },
    [onSelect, selectedOptions]
  );

  const onGroupHeaderClick = useCallback(
    (id: string | undefined, label: string | undefined, items: SubStepOption[]) => {
      if (onSelectGroup) {
        return () => onSelectGroup({ id, label });
      }

      if (isMultiSelect) {
        return () => handleSelectAll(id || label, items);
      }
    },
    [handleSelectAll, isMultiSelect, onSelectGroup]
  );

  return (
    <>
      {filteredGroups.map(({ items, label: groupLabel, id: groupId }) => (
        <Fragment key={`${groupLabel || 'filteredGroup'}-${items.length}`}>
          <ListSelectionGroupHeader
            disabled={isGroupFullyDisabled(items)}
            isChecked={
              onSelectGroup
                ? // This is to accomodate GlobalRooftopGroupSelector
                  !!groupId && itemIsActive(groupId)
                : isGroupChecked(groupId || groupLabel, selectedOptions, items)
            }
            isIndeterminate={isGroupIndeterminate(groupId || groupLabel, selectedOptions, items)}
            isMultiSelect={isMultiSelect}
            label={
              /*
               * Multiselects and category lists should display a header using the group label
               * when mutliple groups exist, or the field label when 1 group exists
               */
              filteredGroups.length > 1 || (filteredGroups.length === 1 && isMultiSelect)
                ? groupLabel || label
                : undefined
            }
            onClick={onGroupHeaderClick(groupId, groupLabel, items)}
            styleVariant={styleVariant}
            testId={listSelectionItemTestId(ElementTestId.SELECT_ALL_TOGGLE)}
          />
          {items.map(item => (
            <ListItem
              className={`${baseClassName}-${item.id}`}
              data-testid={item?.id ? listSelectionItemTestId(item.id) : undefined}
              key={item.id}
              onClick={item.disabled ? undefined : onSelect.bind(null, item)}
            >
              <ListSelectionCheckbox checked={itemIsActive(item.id)} disabled={item.disabled} round={!isMultiSelect} />
              <OptionText disabled={item.disabled}>{get(item.name, 'value', item.name)}</OptionText>
              {isColor && <ColorBadge color={formatColorFromData(item.data, item.name)} />}
            </ListItem>
          ))}
        </Fragment>
      ))}
    </>
  );
};

export default ListSelectionOptions;
