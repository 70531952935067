import { get } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type StepFieldHyperlink from 'components/core/createModify/interfaces/stepFieldHyperlink';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps, StepComponentState } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore, { defaultState } from 'components/core/createModify/stepFields/StepComponentCore';
import {
  CarfaxReportErrors,
  RetailItemDetailsBuilderFields,
} from 'components/sections/createModify/inventoryItems/retailItem/steps/interfaces';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { ManufacturerOptions } from 'components/sections/createModify/inventoryItems/steps/OptionsFields';
import { TradeInItemMMSTFieldsImplementer } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/implementers/TradeInItemMMSTFields';
import { TradeInItemVinDecodeImplementer } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/implementers/TradeInItemVinDecode';
import { TrimSelectionFields } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/interfaces';
import { getManufacturerOptions, getTagOptions } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { InventoryItem, InventoryItemSettings } from 'enums/columns/inventoryItem';
import { StepFieldType } from 'enums/stepFieldType';
import {
  InventoryItemType,
  MakeModelSubModelTrimSource,
  RetailItemModifyParameter,
  VehicleBodyType,
} from 'store/api/graph/interfaces/types';
import {
  addDisplayType,
  defineFieldValues,
  getOptionsFromStepField,
  getStepField,
  objectToStepFieldArray,
  parseCategoryGroups,
  removeDisplayType,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';
import { getRemovedAttributes } from 'utils/inventoryItemUtils';

const { t } = translate;

interface InventoryDetailsStepState extends StepComponentState {
  hasShownMMSTWarning: boolean;
}

class TradeInItemDetailsStep extends StepComponentCore {
  state: InventoryDetailsStepState = {
    ...defaultState,
    hasShownMMSTWarning: false,
  };
  selectedTrim: StepField;

  // Implementers
  mmstFields: TradeInItemMMSTFieldsImplementer;
  vinDecoder: TradeInItemVinDecodeImplementer;

  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { data, metadata, isCreating, entityType, activeStep },
    } = props;

    this.context = context;

    // Initialize implementers
    this.vinDecoder = new TradeInItemVinDecodeImplementer(this);
    this.mmstFields = new TradeInItemMMSTFieldsImplementer(this);

    this.selectedTrim = {
      label: t('trim'),
      groupType: StepFieldType.DROPDOWN,
      queryVar: TrimSelectionFields.DECODED_TRIM_ID,
      subStep: [SubStepType.DEFAULT],
      options: [],
    };

    const removedAttributes = getRemovedAttributes(data);

    // If this vehicle is not a truck or commercial type, then the cab type and cargo bed fields need to be hidden
    const isTruckOrCommercialVehicle = [VehicleBodyType.COMMERCIAL, VehicleBodyType.TRUCK].includes(
      data.vehicleAttributes?.bodyType
    );

    const trimHasChromeSource = data.trimName?.source === MakeModelSubModelTrimSource.CHROME;

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      // Presets
      rooftopId: {
        selectedValue: { ...data.rooftop, name: data.rooftop.name?.value },
      },
      status: {
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !!isCreating },
          { type: StepFieldDisplayType.OMITTED, active: !!isCreating },
        ]),
      },
      year: {
        selectedValue: data.year ?? null,
      },
      makeId: {
        selectedValue: data.makeName
          ? {
              id: data.makeName.id,
              name: data.makeName.name?.value,
            }
          : null,
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.OMITTED, active: true }]),
      },
      modelId: {
        selectedValue: data.modelName
          ? {
              id: data.modelName.id,
              name: data.modelName.name?.value,
            }
          : null,
        displayType: setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !data.makeName?.id }),
      },
      subModelId: {
        selectedValue: data.subModelName
          ? {
              id: data.subModelName.id,
              name: data.subModelName.name?.value,
            }
          : null,
        displayType: setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !data.modelName?.id }),
      },
      trimId: {
        label: t('trim'),
        selectedValue: data.subModelName
          ? {
              ...data.trimName,
              id: data.trimName?.id || null,
              name: data.trimName?.name?.value || t('other_trim'),
            }
          : null,
        displayType: setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !data.subModelName?.id }),
      },
      tagIds: {
        selectedValue: data.tagName || [],
      },
      carfaxReportId: {
        selectedValue: data.carfaxReport?.id,
        hyperlink: data.carfaxReport?.url
          ? ({
              url: data.carfaxReport.url,
              title: t('view_report'),
            } as StepFieldHyperlink)
          : undefined,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: data.type === InventoryItemType.MOTORCYCLE,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: data.type === InventoryItemType.MOTORCYCLE,
          },
        ]),
        hasSeparator: true,
        settings: {
          disabled: !data.rooftop.carfaxId,
        },
      },
      // We do not need to display the 'Show carfax badge' toggle field for Trade-In items
      carfaxReportShowWeb: {
        selectedValue: data.carfaxReport?.id ? false : null,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: true,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: data.type === InventoryItemType.MOTORCYCLE,
          },
          {
            type: StepFieldDisplayType.DISABLED,
            active: !data.carfaxReport?.id,
          },
        ]),
        hasSeparator: true,
      },
      carfaxReportBadges: {
        selectedValue: data.carfaxReport?.badges,
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.HIDDEN, active: true }]),
      },
      [InventoryItem.VEHICLE_CAB_TYPE]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isTruckOrCommercialVehicle,
          },
        ]),
      },
      [InventoryItem.VEHICLE_CARGO_BED_LENGTH]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isTruckOrCommercialVehicle,
          },
        ]),
      },
      flags: {
        displayType: setDisplayTypes({ type: StepFieldDisplayType.OMITTED, active: true }),
        options: [
          // InventoryItem.MAPPED,  // TODO: Add in when modifiable
          InventoryItem.SHOW_WEB,
          InventoryItem.FEATURED,
          InventoryItem.AS_IS,
          InventoryItem.CERTIFIED,
          InventoryItem.DEMO,
          InventoryItem.ON_ORDER,
          InventoryItem.IN_TRANSIT,
          // InventoryItem.COMPLETE,  // TODO: Add in when modifiable
        ].map(flag => {
          // Clear flag derives from normal InventoryItem variable
          const clear = [
            RetailItemModifyParameter._asIs,
            RetailItemModifyParameter._certified,
            RetailItemModifyParameter._demo,
            RetailItemModifyParameter._featured,
            RetailItemModifyParameter._onOrder,
            RetailItemModifyParameter._inTransit,
          ]
            .map(field => ({ field }))
            .find(target => target.field.split('_')[1] === flag);

          return {
            label: InventoryItemSettings[flag].label,
            queryVar: flag,
            clear,
            // Non-clearable fields are binary true/false, clearable fields may pass `undefined`
            selectedValue: get(data, flag, !clear),
          };
        }),
      },
      [RetailItemDetailsBuilderFields.MANUFACTURER_VEHICLE_OPTION_CODES]: {
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: !trimHasChromeSource,
        }),
        selectedValue: get(data, InventoryItem.VEHICLE_MANUFACTURER_OPTIONS),
        renderElement: ManufacturerOptions,
      },
      // TODO: Improve filter logic or use setter for attribute names
    }).filter(stepField => !removedAttributes.includes(stepField.queryVar.split('.')[0]));

    // Setting predefined values where applicable
    this.fields = defineFieldValues(this.fields, data, metadata);

    const trimField = getStepField('trimId', this.fields);
    const manufacturerOptionsField = getStepField(InventoryItem.VEHICLE_MANUFACTURER_OPTIONS, this.fields);

    // Async subpanel configurations
    this.asyncConfigurations = {
      tagIds: {
        request: () => getTagOptions({ rooftopId: [data?.rooftop?.id], entityType: entityType! }),
        disableKeywordRefetch: true,
      },
      [RetailItemDetailsBuilderFields.MANUFACTURER_VEHICLE_OPTION_CODES]: {
        request: async () => {
          const data = await getManufacturerOptions(trimField.selectedValue?.id);
          manufacturerOptionsField.subStepGroups = parseCategoryGroups(data);
          return data;
        },
        disableKeywordRefetch: true,
      },
    };
  }

  async refreshVin() {
    const { toggleClosePrompt } = this.props;

    const vinField = getStepField(DetailsInventoryItemBuilderFields.VIN, this.fields);

    // Clear any open alerts
    this.setTier({ alert: undefined });

    if (vinField.selectedValue) {
      // Warn the user first that updating the VIN will change vehicle data
      toggleClosePrompt({
        message: t('update_vin_message_vehicle_details_only'),
        title: t('change_vin'),
        confirmText: t('yes_continue'),
        cancelText: t('dont_update'),
        onClose: () => {
          toggleClosePrompt(undefined);
        },
        onConfirm: async () => this.vinDecoder.performVinDecode({ isRefreshing: true }),
        onComplete: success => {
          // On complete, close this prompt
          toggleClosePrompt(undefined);
          this.setOnClosePrompt(success ? undefined : this.defaultClosePrompt);
        },
        onCancel: () => {
          // On cancel, clear the close prompt and mark this step as complete
          this.setOnClosePrompt(undefined);
        },
      });
    }
  }

  async refreshCarfax() {
    const { setParentLoader, toggleClosePrompt } = this.props;
    const rooftop = getStepField(DetailsInventoryItemBuilderFields.ROOFTOP_ID, this.fields).selectedValue;

    setParentLoader(true);
    const errors = await this.vinDecoder.performGetCarfaxReport({ rooftop });
    setParentLoader(false);

    if (errors) {
      toggleClosePrompt({
        title: t('carfax_report'),
        errorsOverride: [
          {
            message:
              errors === CarfaxReportErrors.NO_CARFAX_FOUND
                ? t('no_carfax_report_found')
                : t('no_carfax_account_on_this_rooftop'),
          },
        ],
        onComplete: () => {
          toggleClosePrompt(undefined);
        },
      });
    }
  }

  async onFieldActionClick(stepField): Promise<void> {
    if (stepField.queryVar === DetailsInventoryItemBuilderFields.VIN) {
      await this.refreshVin();
    } else if (stepField.queryVar === DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID) {
      await this.refreshCarfax();
    }
  }

  /**
   * Callback override for YMMT handling & cabType handling
   */
  async onFieldSelection(selectedField: StepField, value: any) {
    const { setParentLoader } = this.props;

    const stepField = getStepField(selectedField.queryVar, this.fields);

    super.onFieldSelection(selectedField, value);

    // If a new value for a YMMT field has been selected, we'll need to show a warning prompt
    if (this.mmstFields.isMMSTField(stepField.queryVar)) {
      this.mmstFields.onMMSTFieldSelection(stepField, value);
    }

    switch (selectedField.queryVar) {
      case InventoryItem.VEHICLE_BODY_TYPE: {
        /*
         * If the body type has changed to non-commercial or truck, then clear and hide the
         * cargo bed and cab type fields
         */
        const isTruckOrCommercial =
          !!stepField.selectedValue?.id &&
          [VehicleBodyType.COMMERCIAL, VehicleBodyType.TRUCK].includes(stepField.selectedValue?.id);

        const cabField = getStepField(InventoryItem.VEHICLE_CAB_TYPE, this.fields);
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isTruckOrCommercial }, cabField);
        if (!isTruckOrCommercial) {
          cabField.selectedValue = null;
        }

        const cargoBedField = getStepField(InventoryItem.VEHICLE_CARGO_BED_LENGTH, this.fields);
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isTruckOrCommercial }, cargoBedField);
        if (!isTruckOrCommercial) {
          cargoBedField.selectedValue = null;
        }
        this.forceUpdate();
        break;
      }

      case 'flags': {
        getStepField(stepField.queryVar, this.fields).options = value;
        break;
      }

      case TrimSelectionFields.DECODED_TRIM_ID: {
        this.selectedTrim.selectedValue = value;
        this.forceUpdate();

        setParentLoader(true);

        if (selectedField.queryAlias?.includes(TrimSelectionFields.VIN_REFRESHING)) {
          // If the selected trim came from vin refreshing, then just decode the vin data
          await this.vinDecoder.performVinDecode();
        } else {
          // If the selected trim came from saving the current step, then decode the vin and get new carfax report
          const rooftopField = getStepField(DetailsInventoryItemBuilderFields.ROOFTOP_ID, this.fields);
          await this.vinDecoder.performVinDecodeAndGetCarfaxReport({ rooftop: rooftopField.selectedValue });
        }

        this.setOnClosePrompt(this.defaultClosePrompt);
        setParentLoader(false);
        break;
      }
    }
  }

  onFieldChange(stepField: StepField, e) {
    super.onFieldChange(stepField, e, true);

    const {
      tier: {
        data: { carfaxReport },
      },
    } = this.props;

    // Clear trims if the vin field changes its user input value
    if (
      stepField.queryVar === DetailsInventoryItemBuilderFields.VIN &&
      getOptionsFromStepField(this.selectedTrim, this.props.tier.metadata).length > 0
    ) {
      this.vinDecoder.clearTrimSubStep();
      this.setState({ currentStepField: stepField });
      this.setTier({
        alert: undefined,
      });
    } else if (stepField.queryVar === 'type') {
      this.vinDecoder.clearTrimSubStep();
    }

    if (stepField.queryVar === DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID) {
      const carfaxReportField = getStepField(DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID, this.fields);
      const carfaxBadgesField = getStepField(DetailsInventoryItemBuilderFields.CARFAX_REPORT_BADGES, this.fields);
      const carfaxShowBadgesField = getStepField(DetailsInventoryItemBuilderFields.CARFAX_REPORT_SHOW_WEB, this.fields);

      // If the user has manually changed the carfax report id, then clear the 'View Report' link
      carfaxReportField.hyperlink =
        carfaxReportField.selectedValue === carfaxReport?.id
          ? ({
              url: carfaxReport.url,
              title: t('view_report'),
            } as StepFieldHyperlink)
          : undefined;

      if (carfaxReportField.selectedValue === carfaxReport?.id) {
        // If the user has re-entered the same carfax report id, then re-assign the carfax badges
        carfaxBadgesField.selectedValue = carfaxReport?.badges;
        removeDisplayType(carfaxShowBadgesField, StepFieldDisplayType.DISABLED);
      } else if (carfaxReportField.selectedValue) {
        /*
         * If the user has entered a carfax report id, then set the carfax badges to empty list ([]). This is a
         * workaround as the server doesn't know the badges associated with this report id. Also, now that there is
         * a carfax ID set, the show badges toggle can be enabled
         */
        carfaxBadgesField.selectedValue = [];
        removeDisplayType(carfaxShowBadgesField, StepFieldDisplayType.DISABLED);
      } else {
        /*
         * If the user has cleared the field, then mark the badges as null so that they can be cleared. Also, now
         * that there is no carfax report id, disable the show badges field
         */
        carfaxBadgesField.selectedValue = null;
        addDisplayType(carfaxShowBadgesField, StepFieldDisplayType.DISABLED);
      }

      this.forceUpdate();
    }
  }

  /**
   * Callback override for YMMT handling
   */
  async toggleSubPanel(stepField: StepField) {
    const { currentStepField } = this.state;
    const {
      tier: { metadata },
    } = this.props;

    // Making the call to get the next set of data, do not execute calls again if the step field is the same
    if (currentStepField !== stepField) {
      // Resetting any previously seeded values when opening fresh
      if (currentStepField) {
        currentStepField.seededValues = undefined;
      }

      // Dynamically formatting this.asyncConfigurations based on selecting field
      if (this.mmstFields.isMMSTField(stepField?.queryVar)) {
        this.mmstFields.toggleMMSTSubPanel(stepField);
      }

      if (
        stepField &&
        stepField.queryVar === DetailsInventoryItemBuilderFields.VIN &&
        getOptionsFromStepField(this.selectedTrim, metadata).length > 0
      ) {
        stepField.active = true;
        this.setState({
          currentStepField: this.selectedTrim,
          childrenBeforeSubStep: this.vinDecoder.renderTrimWarning(),
        });
      } else {
        this.setState({ childrenBeforeSubStep: undefined });
      }
      if (!stepField || stepField.queryVar !== DetailsInventoryItemBuilderFields.VIN) {
        // Manual override to disable vin active
        getStepField(DetailsInventoryItemBuilderFields.VIN, this.fields).active = false;
        this.setTier({
          alert: undefined,
        });
      }

      void super.toggleSubPanel(stepField);
    }
  }

  async performSave(): Promise<boolean> {
    const { setTier } = this.context!;
    const {
      tier: {
        tierId,
        steps,
        isCreating,
        data: { type, leadId },
        data,
      },
    } = this.props;

    const carfaxReportField = getStepField(
      DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID,
      this.fields
    ).selectedValue;
    const carfaxShowBadgesField = getStepField(DetailsInventoryItemBuilderFields.CARFAX_REPORT_SHOW_WEB, this.fields);

    /**
     * If there is no carfax report, then the show badges should be cleared (ie set to null). If there is a carfax
     * report, then ensure that the show badges does not have a null value as it can not be cleared when
     * there is an active report.
     */
    carfaxShowBadgesField.selectedValue = carfaxReportField ? false : null;

    const variableOverrides = {};
    if (isCreating) {
      variableOverrides['rooftopId'] = data.rooftop?.id;
    }

    const variablePresets = {};
    if (isCreating) {
      variablePresets['type'] = type;
      variablePresets['leadId'] = leadId;
    }

    const success = await super.save(variablePresets, variableOverrides);
    if (!success) {
      return false;
    }

    // Enabling other steps for edit/navigation
    for (const step of steps!) {
      if (!['VIN', 'CAPTURE'].includes(step.id)) {
        step.isEnabled = true;
      }
    }
    setTier(tierId, { steps });
    return true;
  }

  /*
   * Overriding core async save method with custom formatted fields, preset/overriden variables,
   * and dynamic queries based on create/modify context
   */
  async save(): Promise<boolean> {
    const {
      tier: { data },
      toggleClosePrompt,
      onStepComplete,
    } = this.props;

    const vinField = getStepField(DetailsInventoryItemBuilderFields.VIN, this.fields);
    const rooftopField = getStepField(DetailsInventoryItemBuilderFields.ROOFTOP_ID, this.fields);

    if (vinField.selectedValue === data.vin) {
      return this.performSave();
    } else {
      // If the user has entered a different VIN, show prompt for decoding this new VIN
      toggleClosePrompt({
        message: t('change_vin_message'),
        title: t('change_vin'),
        confirmText: t('yes_continue'),
        cancelText: t('dont_update'),
        onClose: () => {
          toggleClosePrompt(undefined);
        },
        onConfirm: async () =>
          this.vinDecoder.performVinDecodeAndGetCarfaxReport({ rooftop: rooftopField.selectedValue }),
        onComplete: success => {
          // On complete, close this prompt
          toggleClosePrompt(undefined);

          if (success) {
            // If VIN decode was a success, clear the close prompt and mark this step complete
            this.setOnClosePrompt(undefined);
            onStepComplete(false);
          } else {
            // If VIN decode was not a success, then reset the close prompt to default settings
            this.setOnClosePrompt(this.defaultClosePrompt);
          }
        },
        onCancel: () => {
          // On cancel, clear the close prompt and mark this step as complete
          this.setOnClosePrompt(undefined);
          onStepComplete(true);
        },
      });
      return false;
    }
  }
}

export default TradeInItemDetailsStep;
