import { memo } from 'react';
import 'styled-components/macro';

import { useFlags } from 'launchdarkly-react-client-sdk';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import FalseIcon from 'components/ui/icons/FalseIcon';
import TrueIcon from 'components/ui/icons/TrueIcon';
import Image from 'components/ui/images/Images';
import { ListItem, ListItemDetails, ListItemSeparator } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import { ArchiveBadge } from 'components/ui/shared/ArchiveBadge';
import Badge, { Badges } from 'components/ui/shared/Badge';
import { RooftopBadges } from 'components/ui/shared/RooftopBadges';
import { FeatureBundleSet } from 'enums/featureBundle';
import { ImageSize, ImageType } from 'enums/imageType';
import type { ContactResponseType } from 'store/api/graph/responses/responseTypes';
import { GREEN_500, RED_500 } from 'styles/tokens';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { formatFullName } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

interface Props extends ContactResponseType, ListItemProps {}

const ContactListItem = ({ suffixIcon, listItemType, ...contact }: Props) => {
  const { t } = translate;
  const { rgbHex, firstName, lastName, email, phoneNumber, defaultAppraiser, rooftop, avatar, displayName } = contact;
  const flags = useFlags();

  const isTradeInEnabled = isFeatureEnabledForRooftop({
    rooftop,
    feature: FeatureBundleSet.TRADE_IN,
    featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
  });

  return (
    <ListItem isArchived={isItemArchived(contact)} listItemType={listItemType} suffixIcon={suffixIcon}>
      <Image
        fallbackSrc={{ firstName, lastName, rgbHex }}
        size={ImageSize.LIST_ITEM}
        src={avatar?.url}
        type={ImageType.USER}
      />
      <ListItemDetails>
        <TextRow>
          <PrimaryText>{formatFullName({ displayName, firstName, lastName })}</PrimaryText>
        </TextRow>
        <TextRow>
          <SecondaryText>{email}</SecondaryText>
          <SecondaryText>&bull;</SecondaryText>
          <SecondaryText>{phoneNumber}</SecondaryText>
        </TextRow>
        <Badges>
          <ArchiveBadge isArchived={isItemArchived(contact)} />
          <RooftopBadges value={rooftop} />
          {isTradeInEnabled && (
            <Badge
              css="padding-right: 4px"
              title={`${t('default_appraiser')}: ${defaultAppraiser ? t('yes') : t('no')}`}
            >
              {t('default_appraiser')}
              {defaultAppraiser ? (
                <TrueIcon color={GREEN_500} css="margin-left: 1ch;" height={10} width={10} />
              ) : (
                <FalseIcon color={RED_500} css="margin-left: 1ch;" height={10} width={10} />
              )}
            </Badge>
          )}
        </Badges>
      </ListItemDetails>
      <ListItemSeparator />
    </ListItem>
  );
};

export default memo(ContactListItem);
