import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { cloneDeep, get, isEmpty, isEqual, isNil, isNull, omit, omitBy, pick, pickBy, set, without } from 'lodash-es';

import type {
  GlobalAdminFilterProperties,
  GlobalAdminFilters,
  GlobalFilterOption,
} from 'components/ui/filters/interfaces/globalFilter';
import type { SavedFilterViewSettingsType } from 'containers/filters/SavedFilterViewSettings';
import { SavedFilterViewSettings } from 'containers/filters/SavedFilterViewSettings';
import { ConnectionSearchContext } from 'contexts/ConnectionSearchContext';
import { ItemViewType } from 'enums/ItemViewType';
import type { RoutePath } from 'enums/routePath';
import { SubRoute } from 'enums/routePath';
import { useMountEffect } from 'hooks/useMountEffect';
import { usePrevious } from 'hooks/usePrevious';
import { useRouter } from 'hooks/useRouter';
import type { Column, ConnectionFilter, SavedConnectionFilterFragment } from 'store/api/graph/interfaces/types';
import { InventoryItemType, SortDirection } from 'store/api/graph/interfaces/types';
import {
  acceptableEmptyParams,
  getInitialLocalSearchParams,
  isGlobalFiltersEmpty,
  mergeGlobalAdminAndLocalFilters,
  transformGlobalAdminFilters,
} from 'utils/filterUtils';
import {
  getInitialFacetFilters,
  getInitialGlobalAdminFilters,
  getInitialLayoutView,
  getInitialSavedFilterViewState,
  saveInitialFacetFilters,
  saveInitialGlobalAdminFilters,
  saveInitialSavedFilterViewState,
} from 'utils/persistanceUtils';
import { areParamsEqual, getUrlDeeplinkParams, getUrlSearchParams, stringifyParams } from 'utils/urlUtils';

const resetPaginationParams = {
  first: undefined,
  after: undefined,
  last: undefined,
  before: undefined,
};

/* Names of searchParams to be ignored when clearing; unless forced */
const defaultNonClearableSearchParams = ['keyword'];

/**
 * Formats our search params so they don't include:
 *  - empty or undefined values
 *  - non-clearable keys
 * And that the following primitives are correctly formatted:
 *  - booleans
 *
 *  @param {object} searchParams - Object of search parameters to format
 *  @param {boolean} emptyKeysOnly - Ignores `nonClearableParams` if true
 *  @param {string[]} nonClearableParams - The list of non-clearable search parameters. The user can still 'clear' these
 *  params by omitting them from the URL params however.
 */
const formatSearchParams = (
  searchParams,
  emptyKeysOnly = false,
  nonClearableParams: string[] = defaultNonClearableSearchParams
) =>
  pickBy(searchParams, (value, key) => {
    // Convert boolean strings to boolean primitives
    if (value === 'true' || value === 'false') {
      searchParams[key] = value === 'true';
    }

    const isAcceptableEmptySearchParam = value === '' && acceptableEmptyParams.includes(key);
    const isValid = value !== undefined && (value !== '' || isAcceptableEmptySearchParam);
    return emptyKeysOnly ? isValid : isValid && !nonClearableParams.includes(key);
  });

/**
 * Given a searchParam that was just changed to a provided value, clear any incompatible existing search params.
 *
 * @param allSearchParams - All search params in a single object. This util will modify this object directly.
 * @param searchParam - The searchParam that was just changed
 * @param value - The new value of searchParam
 */
export const clearIncompatibleSearchParams = (allSearchParams: Record<string, any>, searchParam: string, value) => {
  if (searchParam === 'makeId') {
    set(allSearchParams, 'modelId', undefined);
    set(allSearchParams, 'subModelId', undefined);
  }
  if (searchParam === 'modelId') {
    set(allSearchParams, 'subModelId', undefined);
  }
  /*
   * If a VEHICLE/MOTORCYCLE type is selected we need to clear the attributes
   * TODO: [ED-8912]
   */
  if (searchParam === 'type') {
    if (value !== InventoryItemType.VEHICLE) {
      set(allSearchParams, 'vehicleAttributes', undefined);
    }
    if (value !== InventoryItemType.MOTORCYCLE) {
      set(allSearchParams, 'motorcycleAttributes', undefined);
    }
  }
};

// TODO: [ED-10975] [WEB] Investigate why `rooftopId` has to be `string` if it has only a selected value
const getRooftopIdValueFromFilters = (
  filters: SavedConnectionFilterFragment[SavedFilterViewSettingsType['connectionFilterQueryAliasPath']]
) => {
  if (!filters || !('rooftopId' in filters) || !filters.rooftopId) {
    return null;
  }

  return filters.rooftopId.length === 1 ? filters.rooftopId[0] : filters.rooftopId;
};

interface ConnectionSearchProviderProps {
  initialParams?: any;
  nonClearable?: string[];
}

interface ConnectionSearchProviderInterface extends ConnectionSearchProviderProps {
  children: ReactNode;
}

export const ConnectionSearchProvider = ({
  children,
  initialParams = {},
  nonClearable,
}: ConnectionSearchProviderInterface) => {
  const router = useRouter();
  const didClickBrowserBack = useRef(false);
  // Include 'keyword' in the list of non-clearable search params
  const nonClearableSearchParams = useMemo(
    () => [...defaultNonClearableSearchParams, ...(nonClearable || [])],
    [nonClearable]
  );

  const initialUrlParams = useMemo(() => {
    const initialGlobalSearchParams = getInitialGlobalAdminFilters();
    const params = formatSearchParams(getUrlSearchParams(window.location), true);
    return getInitialLocalSearchParams({ searchParams: params, globalSearchParams: initialGlobalSearchParams });
  }, []);

  const [globalSearchParams, setGlobalSearchParams] = useState<GlobalAdminFilters>(() =>
    getInitialGlobalAdminFilters()
  );
  const prevGlobalSearchParams = usePrevious(globalSearchParams);
  const hasGlobalSearchParams = !isGlobalFiltersEmpty(globalSearchParams);

  const [localSearchParams, setLocalSearchParams] = useState(() => ({
    ...omit(getInitialFacetFilters(router.sectionPath) ?? initialParams, nonClearableSearchParams),
    ...initialUrlParams,
  }));
  const prevLocalSearchParams = usePrevious(localSearchParams);

  const getSavedFilterSearchParams = useCallback(
    (savedFilterView: SavedConnectionFilterFragment | undefined) => {
      if (!savedFilterView) {
        return;
      }

      const savedFilterSettings = SavedFilterViewSettings[router.sectionPath];
      const selectedFilters = savedFilterView[savedFilterSettings.connectionFilterQueryAliasPath];

      return omitBy({ ...selectedFilters, rooftopId: getRooftopIdValueFromFilters(selectedFilters) }, isNull);
    },
    [router.sectionPath]
  );

  const [searchParams, setSearchParams] = useState(() => {
    const globalSearchParams = getInitialGlobalAdminFilters();
    const localSearchParams = omit(
      getInitialFacetFilters(router.sectionPath) ?? initialParams,
      nonClearableSearchParams
    );

    return mergeGlobalAdminAndLocalFilters({
      globalAdminFilters: transformGlobalAdminFilters(globalSearchParams),
      // If the filters from the URL params do not match the saved filters, we use the URL params as an override
      localSearchFilters:
        !isEqual(localSearchParams, initialUrlParams) && !isEmpty(initialUrlParams)
          ? initialUrlParams
          : localSearchParams,
      requestedRoute: router.sectionPath as RoutePath,
    });
  });

  const [activeSavedFilterView, setActiveSavedFilterView] = useState<SavedConnectionFilterFragment | undefined>(() => {
    const initialSavedFilterView = getInitialSavedFilterViewState({ forPathname: router.sectionPath });
    const savedFilterSearchParams = getSavedFilterSearchParams(initialSavedFilterView);

    if (initialSavedFilterView && isEqual(savedFilterSearchParams, searchParams)) {
      return initialSavedFilterView;
    }

    return;
  });

  const prevActiveSavedFilterView = usePrevious(activeSavedFilterView);

  /*
   * After mounting a new route for the first time, we need to replace (instead of push) the history with the
   * initial search params. This ensures that the user will not have to hit the back button twice to navigate
   * between sections that use initial search params.
   */
  useMountEffect(() => {
    if (!areParamsEqual(searchParams, window.location)) {
      const deeplinkParams = getUrlDeeplinkParams(window.location.search);
      const initialLayoutView = getInitialLayoutView({
        forPathname: router.pathname,
        usePersistedView: router.isDefaultSectionPath,
      });
      const pathname = router.isDefaultSectionPath
        ? `${router.sectionPath}${initialLayoutView === ItemViewType.TABLE_VIEW ? SubRoute.TABLE : ''}`
        : router.pathname;
      router.replace({ pathname, search: stringifyParams({ ...searchParams, ...deeplinkParams }) });
    }
  });

  useEffect(() => {
    // Listen to browser location change, only update filters when browser back button clicked
    if (router.navigationType === 'POP') {
      didClickBrowserBack.current = true;
      setSearchParams(getUrlSearchParams(window.location));
    }
  }, [router, router.navigationType]);

  useEffect(() => {
    // Only push onto browser history when user changes search params
    if (!didClickBrowserBack.current && !areParamsEqual(searchParams, window.location)) {
      router.push({ search: stringifyParams(searchParams) });
      saveInitialFacetFilters({
        routeFilters: localSearchParams,
        route: router.sectionPath,
      });
    }
    didClickBrowserBack.current = false;
  }, [router, router.navigationType, localSearchParams, searchParams, router.sectionPath]);

  useEffect(() => {
    /* Combine the local search filters, global filters, and any active saved filters */
    if (
      (!isEqual(prevGlobalSearchParams, globalSearchParams) && prevGlobalSearchParams !== undefined) ||
      (!isEqual(prevLocalSearchParams, localSearchParams) && prevLocalSearchParams !== undefined) ||
      !isEqual(prevActiveSavedFilterView, activeSavedFilterView)
    ) {
      const savedFilterSettings = SavedFilterViewSettings[router.sectionPath];
      saveInitialSavedFilterViewState({ forPathname: router.sectionPath, value: activeSavedFilterView });

      setSearchParams({
        ...mergeGlobalAdminAndLocalFilters({
          globalAdminFilters: transformGlobalAdminFilters(globalSearchParams),
          localSearchFilters: omitBy(
            {
              ...localSearchParams,
              ...(activeSavedFilterView?.[savedFilterSettings.connectionFilterQueryAliasPath] as ConnectionFilter),
            },
            isNil
          ),
          requestedRoute: router.sectionPath as RoutePath,
        }),
      });
    }
  }, [
    globalSearchParams,
    localSearchParams,
    prevGlobalSearchParams,
    prevLocalSearchParams,
    router.sectionPath,
    activeSavedFilterView,
    prevActiveSavedFilterView,
  ]);

  const updateSearchParam = useCallback(
    (name, value) => {
      didClickBrowserBack.current = false;
      setLocalSearchParams(searchParams => {
        const newParams = cloneDeep(searchParams);

        /**
         * Search params and local search params value format has to match for saved filters to work properly
         *
         * This is to match how we handle rooftopId in `utils/filterUtils.ts:197`
         */
        // TODO: [ED-10975] [WEB] Investigate why `rooftopId` has to be `string` if it has only a selected value
        if (name === 'rooftopId' && Array.isArray(value) && value.length === 1) {
          value = value[0];
        }

        set(newParams, name, value);
        clearIncompatibleSearchParams(newParams, name, value);

        if (activeSavedFilterView) {
          setActiveSavedFilterView(undefined);
        }

        return {
          ...searchParams,
          ...newParams,
          ...resetPaginationParams,
        };
      });
    },
    [activeSavedFilterView]
  );

  const getSearchParam = useCallback(name => get(searchParams, name), [searchParams]);
  const getSearchParamAsArray = useCallback(name => [get(searchParams, name, [])].flat(), [searchParams]);

  const updateGlobalSearchParams = <
    TName extends GlobalAdminFilterProperties,
    TValue = TName extends GlobalAdminFilterProperties.ROOFTOPS
      ? GlobalFilterOption[]
      : TName extends GlobalAdminFilterProperties.GROUPS
        ? GlobalFilterOption
        : never,
  >(
    name: TName,
    value: TValue | undefined
  ) => {
    setGlobalSearchParams(currentParams => {
      const newParams = cloneDeep(currentParams || {});

      set(newParams, name, value);
      clearIncompatibleSearchParams(newParams, name, value);

      /*
       * If the current global filters are empty, we are now adding new global filters, so we must clear any existing
       * pagination parameters
       */
      if (isGlobalFiltersEmpty(currentParams)) {
        updatePaginationParams(resetPaginationParams);
      }

      const combinedParams = { ...currentParams, ...newParams };

      /**
       * Clear active saved filter if there is a global filter selected. Unset `rooftopId` and `groupId` from local
       * search parameters
       */
      if (!isGlobalFiltersEmpty(combinedParams)) {
        setActiveSavedFilterView(undefined);
        setLocalSearchParams(searchParams => ({
          ...searchParams,
          rooftopId: undefined,
          groupId: undefined,
        }));
      }

      /* Save the global filters to local storage */
      saveInitialGlobalAdminFilters({ filters: combinedParams });

      return combinedParams;
    });
  };

  const updatePaginationParams = useCallback(parameters => {
    setLocalSearchParams(searchParams => ({
      ...searchParams,
      ...resetPaginationParams,
      ...parameters,
    }));
  }, []);

  const updateActiveSavedFilterView = useCallback(
    (filters: SavedConnectionFilterFragment | undefined) => {
      /**
       * If a new active saved filter view is being opened, reset the pagination parameters and
       * update the local search params.
       */
      if (filters !== undefined) {
        const savedFilterSettings = SavedFilterViewSettings[router.sectionPath];
        const selectedFilters = filters[savedFilterSettings.connectionFilterQueryAliasPath];
        const savedFilterSearchParams = omitBy(
          {
            ...selectedFilters,
            rooftopId: getRooftopIdValueFromFilters(selectedFilters),
            ...(hasGlobalSearchParams ? { rooftopId: undefined, groupId: undefined } : {}),
          },
          isNull
        );

        saveInitialFacetFilters({ routeFilters: savedFilterSearchParams, route: router.sectionPath });
        setLocalSearchParams(savedFilterSearchParams);
        updatePaginationParams(resetPaginationParams);
      }

      if (!hasGlobalSearchParams) {
        setActiveSavedFilterView(filters);
      }
    },
    [hasGlobalSearchParams, router.sectionPath, updatePaginationParams]
  );

  const clearGlobalSearchParams = () => {
    setGlobalSearchParams({});
    /* Save the global filters to local storage */
    saveInitialGlobalAdminFilters({ filters: undefined });
  };

  const clearSearchParams = useCallback(
    (clearAll?: boolean) => {
      if (activeSavedFilterView) {
        setActiveSavedFilterView(undefined);
      }

      setLocalSearchParams(searchParams => (clearAll ? {} : pick(searchParams, nonClearableSearchParams)));
    },
    [activeSavedFilterView, nonClearableSearchParams]
  );

  // Clear all keyword searches by default
  const clearKeywordSearchParams = (keywords: string[] = ['searchFilter']) =>
    setLocalSearchParams(searchParams => omit(searchParams, without(keywords, ...nonClearableSearchParams)));

  const isPristine = () => {
    const formattedSearchParams = formatSearchParams(searchParams, false, nonClearableSearchParams);
    return Object.keys(formattedSearchParams).length === 0;
  };

  // Updating sort order
  const updateSortOrder = useCallback(
    (sortId: string, columns?: Column[], persistent?: boolean) => {
      columns = cloneDeep(columns); // Clonedeep to make readonly columns writable
      const newSortOrders = cloneDeep(searchParams?.sort || []);
      const sortOption = newSortOrders.find(sortItem => sortItem.id === sortId);

      /**
       * Cycles through 3 states: ASCENDING -> DESCENDING -> UNDEFINED
       * If `persistent`, toggle between ASCENDING <-> DESCENDING
       *
       */
      if (sortOption && (sortOption.sortDirection === SortDirection.DESCENDING || persistent)) {
        sortOption.sortDirection =
          sortOption.sortDirection === SortDirection.DESCENDING ? SortDirection.ASCENDING : SortDirection.DESCENDING;
      }

      // Remove sort
      else if (sortOption) {
        const currentColumnState = columns?.find(({ id }) => id === sortId);
        if (currentColumnState) {
          currentColumnState.sortDirection = null;
        }

        newSortOrders.splice(newSortOrders.indexOf(sortOption), 1);
      } else {
        const currentColumnState = columns?.find(({ sortDirection, id }) => !!sortDirection && id === sortId);
        const updateSortDirection =
          !currentColumnState?.sortDirection || currentColumnState?.sortDirection === SortDirection.ASCENDING
            ? SortDirection.DESCENDING
            : SortDirection.ASCENDING;
        newSortOrders.push({ id: sortId, sortDirection: updateSortDirection });
      }

      updateSearchParam('sort', newSortOrders);
    },
    [updateSearchParam, searchParams]
  );

  return (
    <ConnectionSearchContext.Provider
      value={{
        activeSavedFilterView,
        updateActiveSavedFilterView,
        searchParams,
        setSearchParams,
        localSearchParams,
        setLocalSearchParams,
        globalSearchParams,
        setGlobalSearchParams,
        clearGlobalSearchParams,
        updateSearchParam,
        updateGlobalSearchParams,
        updateSortOrder,
        getSearchParam,
        getSearchParamAsArray,
        updatePaginationParams,
        clearSearchParams,
        clearKeywordSearchParams,
        isPristine,
      }}
    >
      {children}
    </ConnectionSearchContext.Provider>
  );
};
