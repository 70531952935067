import type { ReactElement } from 'react';

import styled from 'styled-components/macro';

import MultiLineText from 'components/core/typography/MultiLineText';
import Text from 'components/core/typography/Text';
import type { Props as BaseDialogProps } from 'components/ui/dialogs/Dialog';
import type { StructuredDialogButton } from 'components/ui/dialogs/StructuredDialog';
import { StructuredDialog } from 'components/ui/dialogs/StructuredDialog';
import CheckIcon from 'components/ui/icons/CheckIcon';
import ErrorIcon from 'components/ui/icons/ErrorIcon';
import WarningIcon from 'components/ui/icons/WarningIcon';
import { CircleContainer, Spinner } from 'components/ui/loading/Loader';
import BaseClass from 'components/ui/shared/BaseClass';
import { ElementTestId } from 'enums/testing';
import type { ApiError } from 'store/api/graph/interfaces/apiErrors';
import { getApiErrors, getApiErrorsMessages, logApiError } from 'store/api/graph/interfaces/apiErrors';
import { GREEN_600, RED_500, YELLOW_500 } from 'styles/tokens';
import { LINE_HEIGHT_DEFAULT } from 'styles/typography';
import { translate } from 'utils/intlUtils';

import { OptionButtonStyle } from './PromptDialogOptions';

const { t } = translate;

/** Icons that can be used in the PromptDialog message */
export enum PromptDialogIcon {
  WARNING = 'warning',
}

/** Maps icon components to the given PromptDialogIcon type. */
const iconMap: { [key in PromptDialogIcon]: ReactElement } = {
  [PromptDialogIcon.WARNING]: <WarningIcon color={YELLOW_500} />,
};

const ErrorList = styled.ul`
  padding: 0;
  text-align: center;
  list-style-type: none;
`;

const ErrorMessage = styled(Text)`
  line-height: ${LINE_HEIGHT_DEFAULT};
  white-space: pre-line;
`;

export interface PromptConfig {
  /** Title in the Prompt Dialog box */
  title?: string;
  /** Callback for when the user clicks the `Confirm` button */
  onConfirm?: () => Promise<unknown>;
  /** Callback for when the user clicks the `Cancel` button */
  onCancel?: () => void;
  /**
   * Callback for when the user clicks the 'x' Close button. If no onClose is defined, onCancel will be used for both
   * the 'x' button and Cancel button
   */
  onClose?: () => void;
  /** Messaging shown when confirm/cancel dialog shows */
  message?: string;
  /** If a more dynamically styled message is needed, can supply a JSX Element block */
  messageJSX?: JSX.Element;
  /** Icon to show above the message */
  messageIcon?: PromptDialogIcon;
  /** Messaging shown when successful action */
  successMessage?: string;
  /** Copy for the `Cancel` button */
  cancelText?: string;
  /** Copy for the `Confirm` button */
  confirmText?: string;
  /** Callback for when the dialog has completed its actions, success conditions explained in `onActionCallback` */
  onComplete?: (success: boolean) => void;
  /** Error override that bypasses the regular prompt to show a forced error */
  errorsOverride?: ApiError[];
  /**
   * If the dialogs confirm is a destructive action, then the callbacks will be switched, onConfirm will be
   * the callback linked to the red button (usually used for cancel), and the onCancel callback linked to the green
   * button (usually used for confirm).
   */
  isConfirmDestructive?: boolean;
}

export const DialogIconContainer = styled(CircleContainer)`
  position: fixed;
  transform: translate(-50%, -50%);
`;

interface Props extends BaseDialogProps, PromptConfig {
  errorLabel?: string;
}

interface State {
  errors?: ApiError[];
  isProcessing: boolean;
  isSuccess: boolean;
}

const initialState: State = { errors: undefined, isProcessing: false, isSuccess: false };

class PromptDialog extends BaseClass<Props, State> {
  state = initialState;

  static defaultProps = {
    get confirmText() {
      return t('yes');
    },
    get cancelText() {
      return t('cancel');
    },
    get errorLabel() {
      return t('okay');
    },
  };

  componentDidUpdate(propsPrev) {
    // Reset state on reopen of mounted Dialog
    if (this.props.isOpen && !propsPrev.isOpen) {
      this.setState(initialState);
    }
  }

  onActionCallback = async (action?: () => Promise<unknown>) => {
    const { onComplete, onConfirm } = this.props;

    this.setStateSynchronous({ isProcessing: true });

    if (action) {
      await action()
        /*
         * If the action has a returned result, then isSuccess will depend on that returned value. Since the shape
         * of the returned result is unknown, we can only test basic truthiness of the results. If the action does
         * not return anything, then isSuccess can be assumed to be true, if no error exceptions were caught.
         */
        .then((result: unknown) => this.setStateSynchronous({ isSuccess: result === undefined ? true : !!result }))
        .catch((error: Error) => {
          logApiError(error);
          this.setStateSynchronous({ errors: getApiErrors(error) });
        });
    }

    this.setStateSynchronous({ isProcessing: false });

    if (onComplete) {
      /*
       * `success` param is always true for non-query driven prompts
       * Otherwise, determined dynamically from success state of query.
       */
      void onComplete(!action || (action === onConfirm && this.state.isSuccess));
    }
  };

  /**
   * If an onClose callback is provided, the [x] button in the dialog prompt will call this method, instead of
   * onDialogCancel. The close callback will simply call the provided function and call onComplete with success
   * flag set to false.
   */
  onDialogClose = () => {
    const { onClose } = this.props;
    onClose?.();
  };

  onDialogConfirm = async () => {
    const { onConfirm } = this.props;
    void this.onActionCallback(onConfirm);
  };

  onDialogCancel = () => {
    const { onCancel } = this.props;
    void this.onActionCallback(async () => onCancel?.());
  };

  /** Returns the proper icon based on the state and given icon type. */
  getDialogIcon = () => {
    const { messageIcon, errorsOverride } = this.props;
    const { errors = errorsOverride, isSuccess } = this.state;

    if (errors) {
      return <ErrorIcon color={RED_500} css="padding-bottom: 10px;" height={72} width={72} />;
    }

    if (isSuccess) {
      return <CheckIcon color={GREEN_600} height={48} width={48} />;
    }

    if (messageIcon) {
      return iconMap[messageIcon];
    }
  };

  getDialogButtons = (): StructuredDialogButton[] => {
    const { confirmText = '', cancelText = '', errorLabel = '', errorsOverride, isConfirmDestructive } = this.props;
    const { errors = errorsOverride, isProcessing, isSuccess } = this.state;

    if (isProcessing) {
      return [];
    }

    if (errors) {
      return [
        {
          label: errorLabel,
          onClick: this.onDialogCancel,
          buttonStyle: OptionButtonStyle.DESTRUCTIVE,
        },
      ];
    }

    if (isSuccess) {
      return [];
    }

    return [
      {
        label: cancelText,
        onClick: this.onDialogCancel,
        buttonStyle: OptionButtonStyle.CANCEL,
        testId: ElementTestId.PROMPT_DIALOG_CANCEL,
      },
      {
        label: confirmText,
        onClick: () => void this.onDialogConfirm(),
        buttonStyle: isConfirmDestructive ? OptionButtonStyle.DESTRUCTIVE : OptionButtonStyle.CONFIRMATION,
        testId: ElementTestId.PROMPT_DIALOG_CONFIRM,
      },
    ];
  };

  getDialogContents = () => {
    const { message, messageJSX, errorsOverride, successMessage } = this.props;
    const { errors = errorsOverride, isProcessing, isSuccess } = this.state;

    if (isProcessing) {
      return <Spinner />;
    }

    if (errors) {
      const errorMessages = getApiErrorsMessages(errors);

      return (
        <ErrorList>
          {errorMessages.map(m => (
            <li key={m}>
              <ErrorMessage>{m}</ErrorMessage>
            </li>
          ))}
        </ErrorList>
      );
    }

    if (isSuccess) {
      return successMessage ? <MultiLineText>{successMessage}</MultiLineText> : null;
    }

    return (
      <>
        {message && <MultiLineText>{message}</MultiLineText>}
        {messageJSX}
      </>
    );
  };

  render() {
    const { isDismissible, title } = this.props;
    const { isProcessing } = this.state;

    return (
      <StructuredDialog
        {...this.props}
        buttons={this.getDialogButtons()}
        dialogCss="max-width: 400px;"
        header={title}
        icon={this.getDialogIcon()}
        isDismissible={!isProcessing && isDismissible}
        onClose={this.onDialogCancel}
        testId={ElementTestId.PROMPT_DIALOG_CONTAINER}
      >
        {this.getDialogContents()}
      </StructuredDialog>
    );
  }
}

export default PromptDialog;
