import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { MaskInputSettings } from 'components/ui/forms/shared/MaskedInput';
import { StepFieldType } from 'enums/stepFieldType';
import { NEUTRAL_700 } from 'styles/tokens';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

export enum PasswordBuilderFields {
  ID = 'id',
  PASSWORD = 'password',
  PASSWORD_CONFIRM = 'password_confirm',
}

const modifyPasswordFields = defineDetailsFields<PasswordBuilderFields>({
  [PasswordBuilderFields.ID]: {
    displayType: [StepFieldDisplayType.HIDDEN],
  },
  [PasswordBuilderFields.PASSWORD]: {
    label: 'new_password',
    settings: {
      inputLabel: 'new_password',
      suffixIconColour: NEUTRAL_700,
    } as MaskInputSettings,
    groupType: StepFieldType.MASKED_INPUT,
    required: true,
  },
  [PasswordBuilderFields.PASSWORD_CONFIRM]: {
    label: 'confirm_new_password',
    settings: {
      inputLabel: 'confirm_new_password',
      suffixIconColour: NEUTRAL_700,
    } as MaskInputSettings,
    groupType: StepFieldType.MASKED_INPUT,
    required: true,
    displayType: [StepFieldDisplayType.OMITTED],
  },
});

export default modifyPasswordFields;
