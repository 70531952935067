import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import LeadListItem from 'components/ui/lists/LeadListItem';
import UserListItem from 'components/ui/lists/UserListItem';
import { StepFieldType } from 'enums/stepFieldType';
import { TaskModifyParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

const LinkedFields = defineDetailsFields({
  assignedToId: {
    label: 'assigned_to',
    groupType: StepFieldType.RENDER_OBJECT,
    renderElement: UserListItem,
    subStep: [SubStepType.ASYNC],
    clear: { field: TaskModifyParameter._assignedToId },
    hasSeparator: true,
  },
  leadId: {
    label: 'lead_one',
    groupType: StepFieldType.RENDER_OBJECT,
    renderElement: LeadListItem,
    subStep: [SubStepType.ASYNC],
    clear: { field: TaskModifyParameter._leadId },
    hasSeparator: true,
  },
  inventoryItemId: {
    label: 'inventory_item',
    groupType: StepFieldType.RENDER_OBJECT,
    subStep: [SubStepType.ASYNC],
    clear: { field: TaskModifyParameter._inventoryItemId },
    hasSeparator: true,
  },
});

export default LinkedFields;
