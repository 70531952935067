import { memo, type SVGProps } from 'react';

const CogIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 20 20" width={20} {...props}>
    <path
      d="M8.926 18.503c-.392 0-.729-.13-1.011-.388a1.566 1.566 0 0 1-.507-.954l-.188-1.38c-.154-.06-.3-.13-.438-.215a6.674 6.674 0 0 1-.406-.268l-1.287.542a1.45 1.45 0 0 1-1.086.044 1.558 1.558 0 0 1-.848-.694l-1.074-1.883a1.392 1.392 0 0 1-.174-1.063c.085-.376.28-.685.585-.928l1.1-.833a1.934 1.934 0 0 1-.016-.242V9.76c0-.077.005-.158.015-.242L2.492 8.69a1.588 1.588 0 0 1-.587-.928 1.415 1.415 0 0 1 .176-1.069l1.074-1.877c.201-.333.483-.564.845-.692a1.433 1.433 0 0 1 1.084.042l1.302.542a5.74 5.74 0 0 1 .41-.266c.14-.082.281-.153.424-.212l.188-1.385c.056-.381.225-.701.507-.96.282-.258.62-.387 1.011-.387h2.148c.391 0 .728.13 1.01.388.283.258.452.578.508.959l.187 1.385c.154.06.3.13.438.212.139.083.274.171.407.266l1.287-.542a1.44 1.44 0 0 1 1.086-.042c.364.128.646.359.848.692l1.073 1.877c.201.337.26.693.177 1.069-.083.376-.28.685-.587.928l-1.105.828c.011.084.016.165.016.242V10c0 .084 0 .164-.002.241a1.09 1.09 0 0 1-.034.242l1.104.828c.308.243.504.552.587.928.083.376.025.732-.177 1.068l-1.094 1.883a1.576 1.576 0 0 1-.845.692 1.433 1.433 0 0 1-1.084-.042l-1.26-.542c-.133.095-.27.184-.41.268a3.14 3.14 0 0 1-.425.215l-.187 1.38a1.565 1.565 0 0 1-.507.954c-.283.259-.62.388-1.011.388H8.926Zm1.1-5.586a2.81 2.81 0 0 0 2.063-.854A2.81 2.81 0 0 0 12.943 10a2.81 2.81 0 0 0-.854-2.063 2.81 2.81 0 0 0-2.062-.854c-.816 0-1.507.285-2.07.854A2.824 2.824 0 0 0 7.11 10c0 .806.282 1.493.846 2.063.564.569 1.254.854 2.07.854Z"
      fill="currentcolor"
    />
  </svg>
);

export default memo(CogIcon);
