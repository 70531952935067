import { memo, type SVGProps } from 'react';

const TableViewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M4 15.5a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 14.5V14a.97.97 0 0 1 .288-.713A.967.967 0 0 1 4 13h16a.97.97 0 0 1 .712.287c.192.192.288.43.288.713v.5a.97.97 0 0 1-.288.713.968.968 0 0 1-.712.287H4ZM4 11a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 10v-.5a.97.97 0 0 1 .288-.713A.968.968 0 0 1 4 8.5h16a.97.97 0 0 1 .712.287c.192.192.288.43.288.713v.5a.97.97 0 0 1-.288.713A.968.968 0 0 1 20 11H4Zm0-4.5a.968.968 0 0 1-.712-.287A.968.968 0 0 1 3 5.5V5a.97.97 0 0 1 .288-.713A.968.968 0 0 1 4 4h16a.97.97 0 0 1 .712.287c.192.192.288.43.288.713v.5a.97.97 0 0 1-.288.713A.968.968 0 0 1 20 6.5H4ZM4 20a.967.967 0 0 1-.712-.288A.968.968 0 0 1 3 19v-.5c0-.283.096-.52.288-.712A.967.967 0 0 1 4 17.5h16c.283 0 .52.096.712.288A.965.965 0 0 1 21 18.5v.5c0 .283-.096.52-.288.712A.968.968 0 0 1 20 20H4Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(TableViewIcon);
