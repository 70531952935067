import { css } from 'styled-components/macro';

import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import LinkedFields from 'components/sections/createModify/shared/steps/LinkedFields';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import { BuilderType } from 'enums/builderType';
import { LeadActivity } from 'enums/columns/leadActivity';
import { StepFieldType } from 'enums/stepFieldType';
import { LeadActivityModifyParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

import { DetailsLeadActivityBuilderFields, DetailsLeadActivityBuilderFieldsQueryAlias } from './interfaces';

const DetailFields = defineDetailsFields({
  [DetailsLeadActivityBuilderFields.TYPE]: {
    groupType: StepFieldType.TYPES,
  },
  [DetailsLeadActivityBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: LeadActivity.ROOFTOP_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
    hasSeparator: true,
  },
  [DetailsLeadActivityBuilderFields.LEAD_ID]: {
    ...LinkedFields.leadId,
    label: 'lead_one',
    queryAlias: LeadActivity.LEAD_NAME,
    required: true,
    clear: undefined,
    subStepAddConfig: SubStepAddConfigs[BuilderType.LEAD_CREATE],
    hasSeparator: true,
  },
  [DetailsLeadActivityBuilderFields.SOURCE_ID]: {
    label: 'lead_source',
    queryAlias: LeadActivity.SOURCE_NAME,
    required: true,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    hasSeparator: true,
    clear: { field: LeadActivityModifyParameter._sourceId },
  },
  [DetailsLeadActivityBuilderFields.RETAIL_ITEM_OF_INTEREST_ID]: {
    ...LinkedFields.inventoryItemId,
    label: 'retail_items_one',
    queryAlias: DetailsLeadActivityBuilderFieldsQueryAlias.ATTRIBUTES_NAME_RETAIL_ITEM_OF_INTEREST_ID,
    hasSeparator: true,
  },
  [DetailsLeadActivityBuilderFields.NOTE]: {
    label: 'note_other',
    queryAlias: LeadActivity.NOTE,
    groupType: StepFieldType.TEXT_AREA,
    clear: { field: LeadActivityModifyParameter._note },
    containerInnerStyles: css`
      textarea {
        height: 321px;
      }
    `,
    hasSeparator: true,
  },
});

export const DetailLinkedRetailFields = defineDetailsFields({
  ...DetailFields,
});
DetailLinkedRetailFields.inventoryItemId = {
  ...DetailFields.inventoryItemId,
  label: 'retail_items_one',
  subStepCategories: [{ conditionProp: '__typename', conditionValue: 'RetailItem', label: 'retail' }],
};

export default DetailFields;
