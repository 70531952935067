import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { RetailItemCaptureVinDecodeImplementer } from 'components/sections/createModify/inventoryItems/retailItem/steps/implementers/RetailItemCaptureVinDecode';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { TrimSelectionFields } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/interfaces';
import {
  getRooftopOptionsForNonWhitelabelScopedUser,
  getRooftopOptionsForWhitelabelScopedUser,
} from 'components/sections/shared/ItemMetaHelpers';
import { rooftopImportIntegrationsCountQuery } from 'components/sections/shared/ItemMetaQueries';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { CreateModifyContext } from 'contexts/CreateModifyContext';
import { FeatureBundleSet } from 'enums/featureBundle';
import { StepFieldType } from 'enums/stepFieldType';
import { LOCKING_FIELDS_REQUIRED_PERMISSIONS } from 'hooks/useIsFieldLockingVisible';
import type { RooftopIntegrationQuery, RooftopIntegrationQueryVariables } from 'store/api/graph/interfaces/types';
import { InventoryItemType } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import {
  getOptionsFromStepField,
  getStepField,
  getUsersRooftop,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

class CaptureVinStep extends StepComponentCore {
  static contextType = CreateModifyContext;
  selectedTrim: StepField;

  vinDecoder: RetailItemCaptureVinDecodeImplementer;

  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { metadata, activeStep },
    } = props;

    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped, canAccessMultipleRooftops },
        featureFlags,
      },
    } = context;

    this.context = context;

    this.vinDecoder = new RetailItemCaptureVinDecodeImplementer(this);

    this.selectedTrim = {
      label: t('trim'),
      groupType: StepFieldType.DROPDOWN,
      queryVar: TrimSelectionFields.DECODED_TRIM_ID,
      subStep: [SubStepType.DEFAULT],
      options: [],
    };

    const currentType = metadata.type || InventoryItemType.VEHICLE;
    const rooftop = getUsersRooftop(user);

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      type: {
        selectedValue: currentType,
      },
      rooftopId: {
        selectedValue: rooftop,
        displayType: setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !canAccessMultipleRooftops }),
      },
    });

    // Async subpanel configurations
    this.asyncConfigurations = {
      rooftopId: {
        request: async keyword =>
          isWhiteLabelScoped
            ? getRooftopOptionsForWhitelabelScopedUser({
                keyword,
                features: { retail: { enabled: true } },
              })
            : getRooftopOptionsForNonWhitelabelScopedUser({
                user,
                filterRooftopsByFeatureFunction: rooftop =>
                  isFeatureEnabledForRooftop({
                    rooftop,
                    feature: FeatureBundleSet.RETAIL,
                    featureFlagOn: featureFlags.rooftopPackageEnabled,
                  }),
              }),
        disableKeywordRefetch: !isWhiteLabelScoped,
      },
    };

    void this.updateFieldLockingVisibility(rooftop?.id);
  }

  async updateFieldLockingVisibility(rooftopId?: string) {
    if (!rooftopId) {
      return;
    }

    const { hasPermissions } = this.context?.subContexts?.userContext || {};

    const userCanViewLockedFields = hasPermissions?.(LOCKING_FIELDS_REQUIRED_PERMISSIONS);

    if (userCanViewLockedFields) {
      const res = await client.query<RooftopIntegrationQuery, RooftopIntegrationQueryVariables>({
        query: rooftopImportIntegrationsCountQuery,
        variables: { rooftopId: [rooftopId] },
      });

      this.setTier({
        metadata: {
          ...this.props.tier.metadata,
          isFieldLockingVisible: Boolean(res.data.importIntegrations?.pageInfo?.totalEdges),
        },
      });
    }
  }

  async onFieldSelection(stepField: StepField, value: any) {
    if (stepField.queryVar === TrimSelectionFields.DECODED_TRIM_ID) {
      this.selectedTrim.selectedValue = value;
      this.forceUpdate();
    } else {
      super.onFieldSelection(stepField, value);
    }

    if (stepField.queryVar === DetailsInventoryItemBuilderFields.ROOFTOP_ID) {
      void this.updateFieldLockingVisibility(value.id);
    }
  }

  /** Overriding toggleSubPanel */
  async toggleSubPanel(stepField?: StepField) {
    const {
      tier: { metadata },
    } = this.props;
    const isTrimSelectionRequired =
      stepField && stepField.queryVar === 'vin' && getOptionsFromStepField(this.selectedTrim, metadata).length > 0;

    if (isTrimSelectionRequired) {
      stepField.active = true;
      this.setState({
        currentStepField: this.selectedTrim,
        childrenBeforeSubStep: this.vinDecoder.renderTrimWarning(),
      });
    } else {
      void super.toggleSubPanel(stepField);
      this.setState({ childrenBeforeSubStep: undefined });
    }
    if (!stepField || stepField.queryVar !== 'vin') {
      // Manual override to disable vin active
      getStepField('vin', this.fields).active = false;
      this.setTier({
        alert: undefined,
      });
    }
  }

  // Overriding field change
  onFieldChange(stepField: StepField, e) {
    // Clear trims if the vin field changes its user input value
    if (
      stepField.queryVar === 'vin' &&
      getOptionsFromStepField(this.selectedTrim, this.props.tier.metadata).length > 0
    ) {
      this.vinDecoder.clearTrimSubStep();
      this.setState({ currentStepField: stepField });
      this.setTier({
        alert: undefined,
      });
    } else if (stepField.queryVar === 'type') {
      this.vinDecoder.clearTrimSubStep();
    }
    super.onFieldChange(stepField, e, true);
  }

  async save(): Promise<boolean> {
    if (!this.validateFields()) {
      return false;
    }

    const rooftop = getStepField(DetailsInventoryItemBuilderFields.ROOFTOP_ID, this.fields).selectedValue;
    const vin = getStepField(DetailsInventoryItemBuilderFields.VIN, this.fields).selectedValue;

    const decodedVin = await this.vinDecoder.decodeVin({ rooftop });

    if (decodedVin === null) {
      return false;
    } else {
      const carfaxReportResponse = await this.vinDecoder.getCarfaxReport(vin, rooftop);
      this.vinDecoder.saveVinDecodedDataAndNavigateNextStep(decodedVin, carfaxReportResponse.carfaxReport ?? null);
      return true;
    }
  }
}

export default CaptureVinStep;
