import { useCallback, useEffect, useRef, useState } from 'react';

import { uniqBy } from 'lodash-es';
import styled from 'styled-components/macro';

import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';
import type { ListCondition } from 'components/core/createModify/stepFields/subSteps/listCondition';
import ListSelection from 'components/core/createModify/stepFields/subSteps/ListSelection';
import type { GlobalAdminFilters, GlobalFilterOption } from 'components/ui/filters/interfaces/globalFilter';
import { GlobalAdminFilterProperties } from 'components/ui/filters/interfaces/globalFilter';
import RooftopsIcon from 'components/ui/icons/RooftopsIcon';
import Loader from 'components/ui/loading/Loader';
import {
  getInitialListOfRooftops,
  getRooftops,
  updateGlobalFilterListSelection,
} from 'components/ui/menus/globalRooftopGroupSelectorUtils';
import ToggleFilters from 'components/ui/menus/ToggleFilters';
import OutsideClick from 'components/ui/shared/OutsideClick';
import { ListSelectionStyle } from 'enums/listSelection';
import { ElementTestId } from 'enums/testing';
import { useMountEffect } from 'hooks/useMountEffect';
import { Z_INDEX_5 } from 'styles/z-index';
import { transformGlobalAdminFilters } from 'utils/filterUtils';
import { getInitialGlobalAdminFilters } from 'utils/persistanceUtils';

const RooftopSelectorContainer = styled.div`
  position: relative;
`;

const RooftopSelectorListContainer = styled.div`
  position: absolute;
  top: 40px;
  height: 400px;
  width: 300px;
  z-index: ${Z_INDEX_5};
  background: white;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

interface GlobalRooftopGroupSelectorProps {
  /** The current saved filters */
  savedItems?: GlobalAdminFilters;
  /** Callback for when a rooftop is selected */
  onRooftopSelected: (items?: GlobalFilterOption[]) => void;
  /** Callback for when a group is selected */
  onGroupSelected: (item?: GlobalFilterOption) => void;
}

const GlobalRooftopGroupSelector = ({
  savedItems,
  onRooftopSelected,
  onGroupSelected,
}: GlobalRooftopGroupSelectorProps) => {
  const toggleButtonRef = useRef<HTMLDivElement>(null);
  const [isRooftopSelectorOpen, setRooftopSelectorOpen] = useState(false);
  const [isSearchLoading, setSearchLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<GlobalFilterOption[]>([]);
  const [rooftopItems, setRooftopItems] = useState<GlobalFilterOption[]>([]);
  const [groupItems, setGroupItems] = useState<GlobalFilterOption[]>([]);
  const [listConditions, setListConditions] = useState<ListCondition[]>([]);

  const savedFilters = getInitialGlobalAdminFilters();

  const onSearch = useCallback(
    keyword => {
      const doSearch = async keyword => {
        // If the keyword has been cleared we want to show the saved items
        setSearchLoading(true);
        if (keyword) {
          const rooftops = await getRooftops({ keyword });
          setRooftopItems(rooftops);
        } else {
          const initialRooftops = await getInitialListOfRooftops(String(savedFilters?.groups?.id));
          setRooftopItems(uniqBy([...(savedFilters?.rooftops || []), ...initialRooftops], 'id'));
        }
        setSearchLoading(false);
      };

      void doSearch(keyword);
    },
    [savedFilters?.groups?.id, savedFilters?.rooftops]
  );

  const onToggleRooftopSelector = useCallback(() => {
    // When closing the selector clear the search, as an improvement we could persist the search
    if (!isRooftopSelectorOpen) {
      onSearch('');
    }

    setRooftopSelectorOpen(!isRooftopSelectorOpen);
  }, [isRooftopSelectorOpen, onSearch]);

  const updateSelectedRooftops = useCallback(
    newItems => {
      // Call the onRooftopSelected callback to update the selected rooftops
      onRooftopSelected(newItems?.length ? newItems : undefined);
    },
    [onRooftopSelected]
  );

  const updateSelectedGroup = useCallback(
    newGroup => {
      // Call the onGroupSelected callback to update the selected group
      onGroupSelected(newGroup);
    },
    [onGroupSelected]
  );

  const onSelectRooftop = useCallback(
    e => {
      // Determine which GlobalFilterOption was selected
      const selectedItem =
        rooftopItems.find(rooftop => rooftop.id === e.id) || groupItems.find(group => group.id === e.id);

      // Somehow the selected item was not an option in the list? If this bizarre case happens then just return
      if (!selectedItem) {
        return;
      }

      // Whenever a list option is clicked on we need to update the list selection
      const newSelectedItems = updateGlobalFilterListSelection({
        currentSelectedOptions: selectedItems,
        newSelectedOption: selectedItem,
      });

      // Update the selected rooftops with the new selection changes
      updateSelectedRooftops(newSelectedItems?.filter(item => item.type === GlobalAdminFilterProperties.ROOFTOPS));
      // Update the selected group with the new selection changes
      updateSelectedGroup(newSelectedItems.find(item => item.type === GlobalAdminFilterProperties.GROUPS));
    },
    [selectedItems, updateSelectedRooftops, updateSelectedGroup, rooftopItems, groupItems]
  );

  const initializeRooftops = useCallback(async () => {
    setSearchLoading(true);

    // Get the initial rooftops when this component is first mounted
    const initialRooftops = await getInitialListOfRooftops(String(savedFilters?.groups?.id));

    setSearchLoading(false);

    // Set the list of options in this list with the initial list of rooftops, ensure there are no duplicates
    setRooftopItems(uniqBy([...(savedFilters?.rooftops || []), ...initialRooftops], 'id'));
  }, [savedFilters?.groups?.id, savedFilters?.rooftops]);

  useMountEffect(() => {
    void initializeRooftops();
  });

  useEffect(() => {
    // Whenever the list of options changes we need to create the ListConditions that will sort this list by group
    const groups: GlobalFilterOption[] = [];

    // From the list of rooftop option, determine the list of possible groups
    for (const item of rooftopItems) {
      if (!groups.some(group => group.id === item.groupName.id)) {
        groups.push({
          id: item.groupName.id,
          groupName: item.groupName,
          label: item.groupName.name.value,
          type: GlobalAdminFilterProperties.GROUPS,
        });
      }
    }

    setGroupItems(groups);

    // For each group, create a ListCondition, which will sort the rooftops into these respected groups
    setListConditions(
      groups.map(group => ({
        conditionProp: 'groupName.id',
        conditionValue: String(group.id),
        label: group.label || String(group.id),
        id: String(group.id),
      }))
    );
  }, [rooftopItems]);

  useEffect(() => {
    // Transform the GlobalAdminFilters data into the filter data
    const transformedFilterData = transformGlobalAdminFilters(savedItems);

    if (transformedFilterData) {
      // From the list of items determine which ones are selected
      const selected = rooftopItems.filter(item => transformedFilterData.rooftopId?.includes(String(item.id)));
      // Determine which group is selected
      const selectedGroup = groupItems.find(group => group.id === transformedFilterData.groupId);

      const newSelectedItems: GlobalFilterOption[] = [...selected];

      // If there is a selected group, include this in the list of selected items
      if (selectedGroup?.id) {
        newSelectedItems.push(selectedGroup);
      }

      setSelectedItems(newSelectedItems);
    } else {
      setSelectedItems([]);
    }
  }, [savedItems, listConditions, rooftopItems, groupItems]);

  return (
    <RooftopSelectorContainer>
      <div ref={toggleButtonRef}>
        <ToggleFilters
          activeFiltersCount={(savedFilters?.rooftops?.length || 0) + (savedFilters?.groups ? 1 : 0)}
          icons={{ openIcon: <RooftopsIcon /> }}
          isOpen={isRooftopSelectorOpen}
          setIsOpen={onToggleRooftopSelector}
          testId={ElementTestId.FACET_GLOBAL_FILTER_TOGGLE_BUTTON}
        />
      </div>
      {isRooftopSelectorOpen && (
        <OutsideClick ignoredElements={[toggleButtonRef.current]} onClick={() => setRooftopSelectorOpen(false)}>
          <RooftopSelectorListContainer>
            {isSearchLoading && <Loader />}
            <ListSelection
              isMultiSelect
              isShowing={true}
              onClearSelection={() => {
                updateSelectedGroup(undefined);
                updateSelectedRooftops(undefined);
              }}
              onMultiSelectItemChecked={() => {}}
              onSearchOverride={keyword => {
                onSearch(keyword);
              }}
              onSelect={e => {
                onSelectRooftop(e);
              }}
              onSelectGroup={e => onSelectRooftop(e)}
              onSelected={() => {}}
              options={rooftopItems as SubStepOption[]}
              queryVar={'rooftop-list-selection'}
              selectedOptions={selectedItems}
              // These are required when using the ListSelection in a builder, but not needed here
              styleVariant={ListSelectionStyle.CONDENSED_STYLE}
              subStepGroups={listConditions}
            />
          </RooftopSelectorListContainer>
        </OutsideClick>
      )}
    </RooftopSelectorContainer>
  );
};

export default GlobalRooftopGroupSelector;
