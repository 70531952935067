import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import { RooftopTag } from 'enums/columns/rooftopTag';
import { StepFieldType } from 'enums/stepFieldType';
import { TagModifyParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

import { RooftopTagDetailsBuilderFields } from './interfaces';

const DetailFields = defineDetailsFields({
  [RooftopTagDetailsBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: RooftopTag.ROOFTOP,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
  },
  [RooftopTagDetailsBuilderFields.TYPE]: {
    label: 'type',
    queryAlias: RooftopTag.TYPE,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.item.type',
  },
  [RooftopTagDetailsBuilderFields.NAME]: {
    label: 'name',
    placeholder: 'name',
    queryAlias: RooftopTag.NAME,
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    clear: { field: TagModifyParameter._name },
  },
});

export default DetailFields;
