import { memo, type SVGProps } from 'react';

const UnderlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 20 20" width={20} {...props}>
    <path
      d="M5 17.5a.806.806 0 0 1-.594-.24.806.806 0 0 1-.24-.593c0-.236.08-.434.24-.594.16-.16.358-.24.594-.24h10c.236 0 .434.08.594.24.16.16.24.358.24.594s-.08.434-.24.593a.806.806 0 0 1-.594.24H5Zm5-3.333c-1.403 0-2.493-.438-3.27-1.313-.779-.875-1.167-2.035-1.167-3.479V3.562c0-.291.107-.541.323-.75.215-.208.468-.312.76-.312s.542.104.75.313c.208.208.312.458.312.75V9.5c0 .778.195 1.41.584 1.896.389.486.958.729 1.708.729s1.32-.243 1.708-.73c.39-.485.584-1.117.584-1.895V3.562c0-.291.107-.541.323-.75.215-.208.468-.312.76-.312s.542.104.75.313c.208.208.313.458.313.75v5.812c0 1.444-.39 2.604-1.167 3.48-.778.874-1.868 1.312-3.27 1.312Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(UnderlineIcon);
