import { useCallback } from 'react';

import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { CustomEntity } from 'enums/extendedEntityType';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import type { UserResponseType } from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';

interface Props extends MenuItemProps {
  item: UserResponseType;
}

const MyAccountMenuItems = ({ item }: Props) => {
  const { t } = translate;

  const { toggleTier } = useCreateModify();
  const { id, canLoginWithPassword, editableByMe } = item || {};

  // onClick callbacks
  const onModifyClicked = toggleTier.bind(null, CreateModifyTiers.TIER_0, {
    tierId: CreateModifyTiers.TIER_0,
    type: BuilderType.USER_MODIFY,
    entityType: CustomEntity.USER,
    title: t('modify'),
    isCreating: false,
    itemId: id,
  });

  const onModifyPasswordClicked = useCallback(() => {
    toggleTier(CreateModifyTiers.TIER_0, {
      tierId: CreateModifyTiers.TIER_0,
      type: BuilderType.USER_PASSWORD_MODIFY,
      title: t('change_password'),
    });
  }, [t, toggleTier]);

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled: !editableByMe,
    },
    {
      label: t('change_password'),
      onClick: onModifyPasswordClicked,
      disabled: !(canLoginWithPassword && editableByMe),
    },
  ];

  return <MenuItems items={menuItemsConfig} />;
};

export default MyAccountMenuItems;
