import { memo, type SVGProps } from 'react';

const CircularArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} viewBox="0 0 24 24" width={20} {...props}>
    <path
      d="M9.646 20.861a8.068 8.068 0 0 1-4.218-2.92c-1.088-1.433-1.631-3.076-1.631-4.929 0-.926.15-1.811.45-2.656A8.49 8.49 0 0 1 5.52 8.021c.2-.252.46-.384.783-.397.322-.012.612.11.868.367.196.195.3.437.311.725a1.27 1.27 0 0 1-.262.82 5.88 5.88 0 0 0-.85 1.628 5.743 5.743 0 0 0-.298 1.848c0 1.314.38 2.487 1.14 3.52a5.803 5.803 0 0 0 2.948 2.136c.245.082.446.227.604.434.157.207.236.431.236.672 0 .389-.136.694-.407.916-.27.221-.587.279-.947.171Zm4.708 0c-.36.108-.676.047-.947-.18-.271-.229-.407-.537-.407-.926 0-.224.079-.44.236-.65.158-.21.36-.355.604-.438a5.986 5.986 0 0 0 2.945-2.15c.762-1.027 1.143-2.195 1.143-3.505 0-1.623-.562-3.008-1.687-4.155-1.125-1.146-2.497-1.735-4.115-1.767h-.1l.406.406c.196.195.293.44.293.732a.995.995 0 0 1-.293.733.994.994 0 0 1-.732.293.995.995 0 0 1-.733-.293L8.71 6.706a1.022 1.022 0 0 1-.242-.37 1.221 1.221 0 0 1-.074-.426c0-.149.024-.29.074-.426.05-.134.13-.257.242-.37l2.256-2.26a.994.994 0 0 1 .733-.294c.293 0 .537.098.732.293.196.196.293.44.293.733a.994.994 0 0 1-.293.733l-.489.49h.105c2.277 0 4.205.797 5.786 2.39 1.58 1.594 2.37 3.532 2.37 5.813 0 1.84-.544 3.48-1.632 4.916a8.089 8.089 0 0 1-4.218 2.933Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(CircularArrowIcon);
