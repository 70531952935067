import { memo, useCallback, useMemo } from 'react';

import PrimaryText from 'components/core/typography/PrimaryText';
import Text from 'components/core/typography/Text';
import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import { type ItemGridRowProps, ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { BuilderType } from 'enums/builderType';
import { User, UserSettings } from 'enums/columns/user';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { CustomEntity } from 'enums/extendedEntityType';
import { FieldDataType } from 'enums/fieldDataType';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import type { AccessLevelDescription } from 'store/api/graph/interfaces/types';
import { AccessLevel, ResourceTier, ResourceType } from 'store/api/graph/interfaces/types';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback, hasWhiteLabelScopedAccess } from 'utils/permissionUtils';
import type { TableSettingsType } from 'utils/tableUtils';

const { SCOPE_NAME, ROLE_NAME } = User;
const { BOLD } = ItemGridRowDisplayType;
const { t } = translate;

const UserPermissionsTab = memo<ItemTabProps>(({ item: user, metadata, onEdit }) => {
  const { builderConfig } = useBuilderConfig();
  const { toggleTier, findActiveStep } = useCreateModify();
  const { hasPermissions } = useUser();

  const onEditClicked = useCallback(
    activeField => {
      const activeStep = findActiveStep(activeField, builderConfig[BuilderType.USER_MODIFY]);

      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.USER_MODIFY,
        entityType: CustomEntity.USER,
        title: t('modify_x', [user.displayName]),
        isCreating: false,
        itemId: user.id,
        data: { ...user },
        activeField,
        activeStep,
      });
    },
    [findActiveStep, builderConfig, toggleTier, user]
  );

  // User specific, custom grid cell rendering
  const customPermissionRenderMethod = useCallback((_, item, id, metadata) => {
    const userPermission = item.permissions.find(({ resource }) => resource === id);
    const { levelName, description }: AccessLevelDescription = metadata
      .find(({ resource }) => resource === id)
      .levels.find(({ level }) => level === userPermission?.level) || { levelName: t('no_access') };

    return (
      <>
        <PrimaryText>{levelName}</PrimaryText>
        <Text>{description}</Text>
      </>
    );
  }, []);

  // Dynamic permission settings based on user
  const filteredMetadata = useMemo(
    () => metadata.filter(({ tier }) => tier !== ResourceTier.INTERNAL || hasWhiteLabelScopedAccess(user.scope)),
    [metadata, user.scope]
  );

  const permissionSettings: TableSettingsType = useMemo(
    () =>
      filteredMetadata
        ?.map(({ resource, resourceName }) => ({ label: resourceName, type: FieldDataType.DEFAULT, resource }))
        .reduce((acc, { resource, ...settings }) => (acc[resource] = settings) && acc, {}) || {},
    [filteredMetadata]
  );

  const permissionsConfig = useMemo<ItemGridRowProps[]>(() => {
    const config: ItemGridRowProps[] = filteredMetadata?.map(({ resource }) => {
      const itemConfig: ItemGridRowProps = {
        fields: [resource],
        canEdit: true,
        gridCellRenderMethod: customPermissionRenderMethod,
      };
      return itemConfig;
    });
    return config;
  }, [customPermissionRenderMethod, filteredMetadata]);

  const gridConfig = useMemo<ItemGridRowProps[]>(
    () => [
      {
        fields: [
          { id: SCOPE_NAME, canEdit: false },
          {
            id: ROLE_NAME,
            canEdit: hasPermissions([{ resource: ResourceType.USERS, level: AccessLevel.FULL }]),
            gridCellRenderMethod: (_, item, id) => <PrimaryText>{item[id]?.name.value || t('custom')}</PrimaryText>,
          },
        ],
        displayType: [BOLD],
      },
    ],
    [hasPermissions]
  );

  return (
    <>
      {gridConfig.map(config => (
        <ItemGridRowSection
          config={config}
          item={user}
          key={formatItemGridRowSectionKey(config.fields)}
          metadata={filteredMetadata}
          onEdit={authorizedCallback({ cb: onEditClicked, isAuth: !!onEdit && !!user?.editableByMe })}
          settings={UserSettings}
        />
      ))}
      {/* Custom Permissions row */}
      {permissionsConfig.map(config => (
        <ItemGridRowSection
          config={config}
          item={user}
          key={formatItemGridRowSectionKey(config.fields)}
          metadata={filteredMetadata}
          onEdit={authorizedCallback({ cb: onEditClicked, isAuth: !!onEdit && !!user?.editableByMe })}
          settings={permissionSettings}
        />
      ))}
    </>
  );
});

export default UserPermissionsTab;
