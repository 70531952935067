import { memo, type SVGProps } from 'react';

import { NEUTRAL_700 } from 'styles/tokens';

const PositionTopRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={24} viewBox="0 0 24 24" width={24} {...props}>
    <path
      clipRule="evenodd"
      d="M5.333 2h13.334A3.333 3.333 0 0 1 22 5.333v13.334A3.333 3.333 0 0 1 18.667 22H5.333A3.333 3.333 0 0 1 2 18.667V5.333A3.333 3.333 0 0 1 5.333 2Zm10 1.667h3.334c.92 0 1.666.746 1.666 1.666v3.334c0 .92-.746 1.666-1.666 1.666h-3.334c-.92 0-1.666-.746-1.666-1.666V5.333c0-.92.746-1.666 1.666-1.666Z"
      fill={NEUTRAL_700}
      fillRule="evenodd"
    />
  </svg>
);

export default memo(PositionTopRightIcon);
