import { memo, useCallback } from 'react';

import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { Mailbox, MailboxSettings } from 'enums/columns/mailbox';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const {
  NAME,
  ROOFTOP_NAME,
  ACTIVE,
  PHONE_NUMBER,
  EMAIL,
  DESCRIPTION,
  CONVERSATION_TYPE,
  SOURCE_NAME,
  CREATED,
  UPDATED,
} = Mailbox;

const { BOLD, RICH_TEXT } = ItemGridRowDisplayType;

export const RooftopMailboxDetailsTab = memo<ItemTabProps>(({ item: mailbox, metadata, onEdit }) => {
  const gridConfig: ItemGridRowProps[] = [
    { fields: [ROOFTOP_NAME], displayType: [BOLD] },
    { fields: [NAME], displayType: [BOLD], canEdit: true },
    { fields: [ACTIVE], displayType: [BOLD] },
    { fields: [CONVERSATION_TYPE], displayType: [BOLD] },
    { fields: [SOURCE_NAME], displayType: [BOLD] },
    { fields: [PHONE_NUMBER], displayType: [BOLD] },
    { fields: [EMAIL], displayType: [BOLD] },
    { fields: [DESCRIPTION], displayType: [RICH_TEXT], canEdit: true },
    { fields: [CREATED, UPDATED], displayType: [BOLD] },
  ];

  // Callbacks
  const onEditCallback = useCallback((field?: string) => onEdit(field, translate.t('mailbox')), [onEdit]);

  // TODO: Use a reusable hook/structured component for this common return block
  return mailbox ? (
    <>
      {gridConfig.map(config => (
        <ItemGridRowSection
          config={config}
          item={mailbox}
          key={formatItemGridRowSectionKey(config.fields)}
          metadata={metadata}
          onEdit={authorizedCallback({ cb: onEditCallback, isAuth: !!onEdit })}
          settings={MailboxSettings}
        />
      ))}
    </>
  ) : null;
});
