import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { StepFieldType } from 'enums/stepFieldType';
import { ResourceType } from 'store/api/graph/interfaces/types';
import { setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

import { UserPermissionBuilderFields } from './interfaces';

/**
 *  A special set of fields that are derived from ResourceTypes, and used as
 *  a basis for PermissionsStep which populates all the field info based off of `query.resources`
 */
const Resources: StepFields = Object.values(ResourceType)
  .map(value => ({
    queryVar: value,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT, SubStepType.CUSTOM],
    displayType: setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: true }),
  }))
  .reduce((acc, { queryVar, ...settings }) => (acc[queryVar] = settings) && acc, {});

const PermissionsFields = defineDetailsFields({
  [UserPermissionBuilderFields.ROLE]: {
    label: 'role',
    queryAlias: 'roleName',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT, SubStepType.ASYNC],
    hasSeparator: true,
  },
  ...Resources,
});

export default PermissionsFields;
