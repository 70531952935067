import type { ElementType } from 'react';
import { useCallback, useMemo } from 'react';

import styled, { css } from 'styled-components/macro';

import LoggingService from 'components/core/logging/LoggingService';
import { ListItemContainer } from 'components/ui/layouts/ListItem';
import { SecondaryViewType } from 'containers/nestedView/interfaces';
import { NestedViewDetailsSettings } from 'containers/nestedView/NestedViewSettings';
import type { ExtendedEntityType } from 'enums/extendedEntityType';
import { CustomEntity } from 'enums/extendedEntityType';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { MatchPattern, useRouter } from 'hooks/useRouter';
import { EntityType, LeadActivityType } from 'store/api/graph/interfaces/types';
import { BLUE_050 } from 'styles/tokens';
import type { ExtractUnionFromArray } from 'utils/typeUtils';

import { Clickable } from '../shared/Button';

export const ListItemClickableContainer = styled(Clickable)<{ isSelected: boolean }>`
  display: flex;
  position: relative;
  width: 100%;

  > ${ListItemContainer} {
    flex-grow: 1;
    width: 100%;
    ${({ isSelected }) =>
      isSelected &&
      css`
        background: ${BLUE_050};
      `}
  }
`;

// A list of entity types with detail views that will always render as nested
export const DetailsViewExceptions = [
  EntityType.LEAD_ACTIVITY,
  LeadActivityType.FORM,
  LeadActivityType.INQUIRY,
  LeadActivityType.PHONE_CALL,
  LeadActivityType.WALK_IN,
  LeadActivityType.TEST_DRIVE,
  CustomEntity.RETAIL_BULK_ADJUSTMENT,
  CustomEntity.ROOFTOP_INTEGRATIONS,
];

export type DetailsViewExceptionsEntityType = ExtractUnionFromArray<typeof DetailsViewExceptions>;

interface Props {
  item: any;
  entity?: ExtendedEntityType;
  disabled?: boolean;
  isNested?: boolean;
  renderElement: ElementType;
  renderSettings?: any;
  testId?: string;
  /** Clicking the item opens up its Details View by default. Use this property to override that behaviour. */
  onClickOverride?: () => void;
}

const ListItemClickable = (props: Props) => {
  const router = useRouter();
  const matches = router.match(MatchPattern.SPLIT_VIEW);
  const { itemId } = matches?.params || {};
  const {
    renderElement: RenderElement,
    renderSettings,
    disabled = false,
    item,
    isNested,
    entity,
    testId,
    onClickOverride,
  } = useMemo(() => props, [props]);
  const { setView, setLastOpenedItem, isDetailsShown, viewItems } = useNestedView();

  const isSelected = useMemo(() => {
    const lastViewItem = viewItems?.slice?.(-1)?.[0];

    return lastViewItem?.seededData
      ? /**
         * Prevent ListItemClickable in separate Linked Sections from being highlighted simultaneously
         * when multiple ListItemClickable share the same `item.id` and `lastViewItem.seededData.id`.
         * Ex. /rooftops > "Linked" > "Inventory Disclaimer"/"Showroom Configuration"
         */
        lastViewItem.seededData.__typename === item.__typename && lastViewItem.seededData.id === item.id
      : lastViewItem?.queryVars?.id === item.id;
  }, [viewItems, item]);
  const targetEntity = useMemo(() => entity ?? viewItems[0]?.entityType, [entity, viewItems]);
  const detailSettings = useMemo(() => NestedViewDetailsSettings[targetEntity]?.(item), [targetEntity, item]);
  const isDisabled = useMemo(
    () =>
      disabled ||
      (isNested &&
        // Is disabled a nested item's details were already shown in the primary or secondary views
        (item.id === itemId || !!viewItems.some(viewItem => viewItem?.queryVars?.id === item.id))),
    [disabled, isNested, viewItems, item, itemId]
  );

  const showItemDetails = useCallback(() => {
    // TODO: Potentially remove the `DetailsViewExceptions` check and allow endless nesting?
    if (!DetailsViewExceptions.includes(targetEntity) && isDetailsShown && isNested) {
      window.open(`${window.location.origin}${detailSettings.base}/${item.id}`, '_blank');
    } else {
      setView({
        entityType: targetEntity,
        viewType: SecondaryViewType.DETAILS,
        title: detailSettings.title,
        queryVars: {
          id: item.id,
          ...detailSettings.queryVars,
        },
        replaceViewItems: !isNested,
        data: item,
      });
    }

    setLastOpenedItem(item.id);
  }, [setView, setLastOpenedItem, item, detailSettings, targetEntity, isDetailsShown, isNested]);

  if (!onClickOverride && !detailSettings) {
    LoggingService.debug({ message: `No settings found for entity: ${targetEntity} links will be broken.` });
  }

  return (
    <ListItemClickableContainer
      data-testid={testId}
      disabled={isDisabled}
      id={item?.id ? `item-${item.id}` : undefined}
      isSelected={isSelected}
      onClick={onClickOverride || showItemDetails}
    >
      <RenderElement key={item.id} {...item} {...renderSettings} suffixIcon={!isSelected && !isDisabled} />
    </ListItemClickableContainer>
  );
};

export default ListItemClickable;
