import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { rooftopDepartmentsMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { rooftopDepartmentDetailsQuery } from 'containers/rooftops/RooftopsDepartmentsQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type {
  RooftopDepartmentsMetaQuery,
  RooftopDepartmentsMetaQueryVariables,
} from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';

const RooftopDepartmentsQueryMethods: QueryMethods = {
  getMetaData: async onMetaUpdate => {
    const observable = client.watchQuery<RooftopDepartmentsMetaQuery, RooftopDepartmentsMetaQueryVariables>({
      query: rooftopDepartmentsMetaQuery,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });

    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: res => {
          const metadata = res.data;
          resolve({ subscription, metadata });
          onMetaUpdate(metadata);
        },
        error: (error: Error) => {
          logApiError(error);
          reject(error);
        },
      });
    });
  },
  getData: async (id: string) => {
    const response = await client.query({
      query: rooftopDepartmentDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },
  refetchQueries: ['RooftopDetailsContainerQuery', 'RooftopDepartmentsQuery'],
};

export default RooftopDepartmentsQueryMethods;
