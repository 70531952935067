import ActionableTitle from 'components/ui/details/ActionableTitle';
import CirclePlusIcon from 'components/ui/icons/CirclePlusIcon';
import { Section } from 'components/ui/layouts/CardLayout';
import { ListItems } from 'components/ui/layouts/ListItem';
import ExternalAppraiserListItem from 'components/ui/lists/ExternalAppraiserListItem';
import type { TradeInAppraisalExternal } from 'store/api/graph/interfaces/types';
import { NEUTRAL_700 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

interface Props {
  appraisers: TradeInAppraisalExternal[];
  onAdd?: () => void;
}

const AppraiserSection = ({ appraisers, onAdd }: Props) => {
  const { t } = translate;
  const addIcon = onAdd && <CirclePlusIcon color={NEUTRAL_700} />;

  return appraisers.length > 0 ? (
    <Section>
      <ActionableTitle icon={addIcon} label={t('awaiting_appraisal_from')} onEdit={onAdd} />
      {appraisers.length > 0 && (
        <ListItems>
          {appraisers.map(appraiser => (
            <ExternalAppraiserListItem key={appraiser.id} {...appraiser} />
          ))}
        </ListItems>
      )}
    </Section>
  ) : (
    <Section>
      <ActionableTitle icon={addIcon} label={t('invite_appraisers')} onEdit={onAdd} />
    </Section>
  );
};

export default AppraiserSection;
