import { memo, useMemo } from 'react';

import styled, { css } from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText, { SecondaryMultiLineText } from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import Image from 'components/ui/images/Images';
import {
  ListItem,
  ListItemDetails,
  ListItemSeparator,
  MAX_LIST_ITEM_TEXT_LENGTH,
} from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import { ArchiveBadge } from 'components/ui/shared/ArchiveBadge';
import { RooftopBadges } from 'components/ui/shared/RooftopBadges';
import { ImageSize, ImageType } from 'enums/imageType';
import { TaskStatus } from 'store/api/graph/interfaces/types';
import type { TaskResponseType } from 'store/api/graph/responses/responseTypes';
import { BODY_TEXT, BODY_TEXT_TERTIARY } from 'styles/color';
import { GREEN_600, NEUTRAL_0, NEUTRAL_050, YELLOW_500 } from 'styles/tokens';
import { formatTextForPreview } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';
import { variants } from 'utils/styledUtils';
import { getRelativeTime } from 'utils/timeUtils';

import CheckEmptyIcon from '../icons/CheckEmptyIcon';
import CircleIcon from '../icons/CircleIcon';
import ClockIcon from '../icons/ClockIcon';
import Badge, { Badges } from '../shared/Badge';

interface IconProps {
  size: ImageSize;
  status: any;
}

const TaskIconContainer = styled(Image)<{ status: TaskStatus }>`
  > div {
    color: ${NEUTRAL_0};
    ${variants<TaskStatus>('status', {
      [TaskStatus.COMPLETE]: css`
        background: ${GREEN_600};
      `,
      [TaskStatus.IN_PROGRESS]: css`
        background: ${YELLOW_500};
      `,
      [TaskStatus.NOT_STARTED]: css`
        background: ${NEUTRAL_050};
      `,
    })}
  }
`;

// TODO: Potentially move this somewhere more generic
export const TaskIcon = ({ size = ImageSize.LIST_ITEM, status }: IconProps) => (
  <TaskIconContainer
    fallbackSrc={useMemo(
      () =>
        ({
          [TaskStatus.COMPLETE]: <CheckEmptyIcon />,
          [TaskStatus.IN_PROGRESS]: <ClockIcon />,
          [TaskStatus.NOT_STARTED]: <CircleIcon color={BODY_TEXT} />,
        })[status],
      [status]
    )}
    size={size}
    status={status}
    type={ImageType.ICON}
  />
);

const TaskListItem = ({ suffixIcon, listItemType, ...task }: TaskResponseType & ListItemProps) => {
  const { title, status, dateDue, dateCompleted, note, rooftopName, tagName } = task;

  const dueDateText = useMemo(() => {
    if (status === TaskStatus.COMPLETE && dateCompleted) {
      return getRelativeTime(dateCompleted, { todayInsteadOfNow: true });
    }
    if (!dateDue) {
      return null;
    }
    return translate.t('due_x', [getRelativeTime(dateDue, { todayInsteadOfNow: true }) || '']);
  }, [dateCompleted, dateDue, status]);

  const notePreview = useMemo(() => formatTextForPreview(note, MAX_LIST_ITEM_TEXT_LENGTH), [note]);

  return (
    <ListItem isArchived={isItemArchived(task)} listItemType={listItemType} suffixIcon={suffixIcon}>
      <TaskIcon size={ImageSize.LIST_ITEM} status={status} />
      <ListItemDetails>
        <TextRow>
          <PrimaryText title={title}>{title}</PrimaryText>
        </TextRow>
        {!!dueDateText && (
          <TextRow>
            <SecondaryText
              css={css`
                color: ${BODY_TEXT_TERTIARY};
              `}
            >
              {dueDateText}
            </SecondaryText>
          </TextRow>
        )}
        {!!notePreview && (
          <TextRow>
            <SecondaryMultiLineText>{notePreview}</SecondaryMultiLineText>
          </TextRow>
        )}
        <Badges>
          <ArchiveBadge isArchived={isItemArchived(task)} />
          <RooftopBadges value={rooftopName} />
          {!!tagName?.length &&
            tagName?.map(tag => (
              <Badge key={tag.id} title={`${translate.t('tag')}: ${tag.name?.value}`}>
                {tag.name?.value}
              </Badge>
            ))}
        </Badges>
      </ListItemDetails>
      <ListItemSeparator />
    </ListItem>
  );
};

export default memo(TaskListItem);
