import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { keyBy, uniqueId } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import { Input, InputContainer, InputPrefix, InputSuffix } from 'components/ui/forms/shared/InputText';
import CirclePlusIcon from 'components/ui/icons/CirclePlusIcon';
import FalseIcon from 'components/ui/icons/FalseIcon';
import { DASHED_BLUE } from 'components/ui/shared/Borders';
import { Clickable } from 'components/ui/shared/Button';
import { BODY_TEXT } from 'styles/color';
import { RED_500 } from 'styles/tokens';
import { FONT_SIZE_13 } from 'styles/typography';
import { Z_INDEX_1 } from 'styles/z-index';
import { type Intl, translate } from 'utils/intlUtils';

const { t } = translate;

const AddButton = styled(Clickable)`
  padding: 12px;
  color: ${BODY_TEXT};
  font-size: ${FONT_SIZE_13};
  width: 100%;
  border: 0;
  outline: 0;
  cursor: pointer;
  margin-top: 10px;
  background-image: url('data:image/svg+xml, ${DASHED_BLUE}');
  border-radius: 8px;
`;

const AddButtonIcon = styled(CirclePlusIcon)`
  vertical-align: sub;
  margin-right: 8px;
  width: 11px;
`;

const DefaultValues = [''];

interface Props {
  onChange: (
    e: Record<
      'currentTarget',
      {
        value: any;
      }
    >
  ) => void;
  values?: string[];
  addButtonLabel?: Intl;
  inputPrefix?: string;
}

const MultiInput = ({
  onChange,
  values = DefaultValues,
  addButtonLabel = 'add_field',
  inputPrefix,
  ...props
}: Props) => {
  const [fields, setFields] = useState(keyBy(values, () => `multi-fields-${uniqueId()}`));
  const onChangeCallback = useCallback(
    (fields: Record<string, string>) => {
      onChange({
        currentTarget: {
          value: Object.values(fields)
            ?.map(field => field?.trim())
            ?.filter(Boolean)
            .map(field => (inputPrefix ? `${inputPrefix}${field}` : field)),
        },
      });
    },
    [onChange, inputPrefix]
  );

  const updateValues = useCallback(
    (e: ChangeEvent<HTMLInputElement>, field: string) => {
      fields[field] = e.currentTarget.value;
      setFields(fields);
      onChangeCallback(fields);
    },
    [fields, setFields, onChangeCallback]
  );

  const deleteField = useCallback(
    (field: string) => {
      delete fields[field];
      setFields(fields);
      onChangeCallback(fields);
    },
    [fields, setFields, onChangeCallback]
  );

  const addField = useCallback(() => {
    const newField = `multi-fields-${uniqueId()}`;
    fields[newField] = '';
    setFields(fields);
  }, [fields, setFields]);

  const renderFields = useMemo(
    () =>
      Object.keys(fields).map((field, index) => (
        <InputContainer key={field}>
          {inputPrefix && (
            <InputPrefix
              css={css`
                z-index: ${Z_INDEX_1};
              `}
            >
              {inputPrefix}
            </InputPrefix>
          )}
          <Input css="padding: 0 40px 0 30px;" onChange={e => updateValues(e, field)} {...props} />
          {!!index && (
            <InputSuffix isInteractive={true} onClick={() => deleteField(field)}>
              <FalseIcon color={RED_500} height={14} width={14} />
            </InputSuffix>
          )}
        </InputContainer>
      )),
    [fields, inputPrefix, deleteField, updateValues, props]
  );

  return (
    <>
      {renderFields}
      <AddButton onClick={addField}>
        <AddButtonIcon />
        {t(addButtonLabel)}
      </AddButton>
    </>
  );
};

export default MultiInput;
