import type { ReactNode } from 'react';

import styled from 'styled-components/macro';

import SecondaryText from 'components/core/typography/SecondaryText';
import ArrowToBottomIcon from 'components/ui/icons/ArrowToBottomIcon';
import ArrowToTopIcon from 'components/ui/icons/ArrowToTopIcon';
import { Section } from 'components/ui/layouts/CardLayout';
import { GridSectionTitle } from 'components/ui/layouts/Grid';
import { ListItems } from 'components/ui/layouts/ListItem';
import AppraisalListItem from 'components/ui/lists/AppraisalListItem';
import { PanelBody, PanelRow, Panels, PanelValue } from 'components/ui/panels/Panel';
import type { TradeInAppraisal, TradeInAppraisals } from 'store/api/graph/interfaces/types';
import { ENTITY_PADDING } from 'styles/spacing';
import { GREEN_600, RED_500 } from 'styles/tokens';
import { formatAmountRounded, formatFullName } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';
import { getRelativeTime } from 'utils/timeUtils';

const AppraisalPanels = styled(Panels)`
  margin-left: calc(-${ENTITY_PADDING} / 2);
  margin-right: calc(-${ENTITY_PADDING} / 2);
`;

const Container = styled.div`
  flex: 1;
  min-width: 285px; /** 315px (smallest tab width) - 15px (left margin) - 15px (right margin) */
  margin-left: calc(${ENTITY_PADDING} / 2);
  margin-right: calc(${ENTITY_PADDING} / 2);
`;

interface AppraisalPanelProps {
  title: string;
  appraisal?: TradeInAppraisal | null;
  icon: ReactNode;
}

const AppraisalPanel = ({ title, appraisal, icon }: AppraisalPanelProps) => {
  if (!appraisal) {
    return null;
  }

  const { amount, updated } = appraisal;
  return (
    <Container>
      <GridSectionTitle>{title}</GridSectionTitle>
      <PanelBody css="margin-top: 12px;">
        <PanelRow>
          <PanelValue>{icon}</PanelValue>
          <PanelValue css="flex: 1;">{formatAmountRounded(amount)}</PanelValue>
          <SecondaryText css="align-self: flex-start;">{getRelativeTime(updated)}</SecondaryText>
        </PanelRow>
        <PanelRow>
          <SecondaryText>{formatFullName(appraisal)}</SecondaryText>
        </PanelRow>
      </PanelBody>
    </Container>
  );
};

interface AppraisalsSectionProps {
  appraisals: TradeInAppraisals;
}

const AppraisalsSection = ({ appraisals }: AppraisalsSectionProps) => {
  const { t } = translate;
  const { lowAppraisal, highAppraisal, list } = appraisals;

  return appraisals?.list?.length ? (
    <Section>
      <AppraisalPanels>
        <AppraisalPanel
          appraisal={highAppraisal}
          icon={<ArrowToTopIcon color={GREEN_600} css="margin-right: 15px;" />}
          title={t('high_appraisal')}
        />
        <AppraisalPanel
          appraisal={lowAppraisal}
          icon={<ArrowToBottomIcon color={RED_500} css="margin-right: 15px;" />}
          title={t('low_appraisal')}
        />
      </AppraisalPanels>
      <GridSectionTitle css="padding-top: 3px;">{t('all_appraisals')}</GridSectionTitle>
      <ListItems>
        {list.map(appraisal => (
          <AppraisalListItem key={appraisal.id} {...appraisal} />
        ))}
      </ListItems>
    </Section>
  ) : null;
};

export default AppraisalsSection;
