import { useCallback, useState } from 'react';

import { rooftopDepartmentDelete } from 'components/sections/createModify/rooftopDepartments/RooftopDepartmentsCreateModifyQueries';
import PromptDialog from 'components/ui/dialogs/PromptDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { ElementTestId } from 'enums/testing';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { useUser } from 'hooks/contexts/useUser';
import type { RooftopDepartment } from 'store/api/graph/interfaces/types';
import { EntityType } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props extends MenuItemProps {
  item: RooftopDepartment;
}

const DepartmentMenuItems = ({ item }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const { toggleTier } = useCreateModify();
  const { hasPermissions } = useUser();
  const { back: backToListView } = useNestedView();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const isAllowedToEdit = hasPermissions(builderConfig[BuilderType.ROOFTOP_DEPARTMENTS_MODIFY].requiredPermissions);

  const onModifyClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.ROOFTOP_DEPARTMENTS_MODIFY,
        entityType: EntityType.ROOFTOP,
        title: t('modify_x', [item.typeName]),
        isCreating: false,
        itemId: item.id,
      }),
    [item, toggleTier]
  );

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled: !isAllowedToEdit,
      testId: ElementTestId.MODIFY_MENU_ITEM,
    },
    {
      label: t('delete'),
      onClick: () => setDeleteDialogOpen(true),
      disabled: !isAllowedToEdit,
      testId: ElementTestId.DELETE_MENU_ITEM,
    },
  ];

  return (
    <>
      <MenuItems items={menuItemsConfig} />
      <PromptDialog
        cancelText={t('cancel')}
        confirmText={t('delete')}
        isConfirmDestructive
        isOpen={isDeleteDialogOpen}
        message={t('delete_department_message')}
        onCancel={() => setDeleteDialogOpen(false)}
        onComplete={useCallback(
          (success: boolean) => {
            if (success) {
              // Hide the success dialog after 0.7 seconds, if the user hasn't already dismissed it
              setTimeout(() => {
                backToListView();
              }, 700);
            }
          },
          [backToListView]
        )}
        onConfirm={useCallback(
          async () =>
            client.mutate({
              mutation: rooftopDepartmentDelete,
              variables: { id: item.id },
              refetchQueries: ['RooftopDetailsContainerQuery', 'RooftopDepartmentQuery'],
            }),
          [item]
        )}
        title={t('delete_department')}
      />
    </>
  );
};

export default DepartmentMenuItems;
