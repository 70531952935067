import type { ReactNode } from 'react';

import styled, { css } from 'styled-components/macro';

import { TertiaryLabel } from 'components/core/typography/Label';
import { GridSectionTitle } from 'components/ui/layouts/Grid';
import { Clickable } from 'components/ui/shared/Button';
import { ENTITY_PADDING } from 'styles/spacing';

interface LinkedSectionProps {
  /* Title of the linked section header */
  title: string;
  /* The onAdd callback called when adding or editing a linked section list */
  onAdd?: () => void;
  /*
   * Icon with the onAdd callback attached to its click event, positioned to the far right of the section header. If no
   * onAdd callback is given, this icon does not appear
   */
  suffixIcon?: ReactNode;
  /* Icon positioned to the left of the section title. Default is no icon */
  prefixIcon?: ReactNode;
  /*
   * If the Linked Section has items, then the headers bottom padding is reduced to compensate for the linked sections
   * own internal padding
   */
  hasItems?: boolean;
  /** A testing ID to add to this section */
  testId?: string;
}

const LinkedSectionHeaderContainer = styled.div<{ hasItems?: boolean }>`
  display: flex;
  flex-direction: row;

  ${GridSectionTitle} {
    padding: ${ENTITY_PADDING};

    /*  Linked section items have an internal padding of 10px, 
        so when there are linked items, the bottom padding must be 
        subtracted by 10px to compensate for the linked items internal top padding of 10px.
     */
    ${({ hasItems }) =>
      !!hasItems &&
      css`
        padding-bottom: 10px;
      `}

    /* Add a right margin to any prefix icons */
    > svg {
      &:first-child {
        margin-right: 10px;
      }

      align-self: center;
    }
  }
`;

const LinkedSectionLabel = styled(TertiaryLabel)`
  flex: 1;
  white-space: initial;
`;

export const LinkedSectionHeader = ({ title, onAdd, suffixIcon, prefixIcon, hasItems, testId }: LinkedSectionProps) => (
  <LinkedSectionHeaderContainer data-testid={testId} hasItems={hasItems}>
    <Clickable css="width: 100%;" disabled={!onAdd} onClick={onAdd}>
      <GridSectionTitle hasIcon>
        {prefixIcon}
        <LinkedSectionLabel css="flex: 1">{title}</LinkedSectionLabel>
        {!!onAdd && suffixIcon}
      </GridSectionTitle>
    </Clickable>
  </LinkedSectionHeaderContainer>
);
