import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import { RetailItemDetailsBuilderFields } from 'components/sections/createModify/inventoryItems/retailItem/steps/interfaces';
import type { ButtonSettings } from 'components/ui/shared/Button';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { StepFieldType } from 'enums/stepFieldType';
import { RetailItemModifyParameter } from 'store/api/graph/interfaces/types';
import { RED_500 } from 'styles/tokens';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

import { RetailItemPricingInfoBuilderFields } from './interfaces';

const RetailItemPricingInfoFields = defineDetailsFields({
  [RetailItemDetailsBuilderFields.LOCKED_FIELDS]: {
    displayType: [StepFieldDisplayType.HIDDEN],
  },
  [RetailItemPricingInfoBuilderFields.COST]: {
    label: 'cost',
    groupType: StepFieldType.CURRENCY,
    clear: { field: RetailItemModifyParameter._cost },
  },
  [RetailItemPricingInfoBuilderFields.MSRP]: {
    label: 'msrp',
    groupType: StepFieldType.CURRENCY,
    clear: { field: RetailItemModifyParameter._msrp },
  },
  [RetailItemPricingInfoBuilderFields.PRICE_1]: {
    label: 'price_1',
    groupType: StepFieldType.CURRENCY,
    tooltipContents: 'price_1_and_2_tooltip',
    clear: { field: RetailItemModifyParameter._price1 },
  },
  [RetailItemPricingInfoBuilderFields.PRICE_2]: {
    label: 'price_2',
    groupType: StepFieldType.CURRENCY,
    tooltipContents: 'price_1_and_2_tooltip',
    clear: { field: RetailItemModifyParameter._price2 },
    hasSeparator: true,
  },
  [RetailItemPricingInfoBuilderFields.CASH_CONFIGURATION_ENABLED]: {
    label: 'cash_pricing_enabled',
    queryAlias: [InventoryItem.CASH_RETAIL_PRICING],
    groupType: StepFieldType.SWITCH,
  },
  [RetailItemPricingInfoBuilderFields.FINANCE_CONFIGURATION_ENABLED]: {
    label: 'finance_pricing_enabled',
    queryAlias: [InventoryItem.FINANCE_RETAIL_PRICING],
    groupType: StepFieldType.SWITCH,
  },
  [RetailItemPricingInfoBuilderFields.LEASE_CONFIGURATION_ENABLED]: {
    label: 'lease_pricing_enabled',
    queryAlias: [InventoryItem.LEASE_RETAIL_PRICING],
    groupType: StepFieldType.SWITCH,
  },
  [RetailItemPricingInfoBuilderFields.REVERT_CONFIGURATION]: {
    groupType: StepFieldType.FIELD_BUTTON,
    settings: {
      buttonLabel: 'revert_to_segment_values',
      colour: RED_500,
    } as ButtonSettings,
  },
});

export default RetailItemPricingInfoFields;
