import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import { StepFieldType } from 'enums/stepFieldType';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

import { InitialItemSortingBuilderFields } from './interfaces';

const InitialItemSortingFields = defineDetailsFields({
  [InitialItemSortingBuilderFields.USE_CUSTOM_SORT_RULE]: {
    label: 'use_custom_sort_rule',
    groupType: StepFieldType.SWITCH,
    hasSeparator: true,
    tooltipContents: 'use_custom_sort_rule_tooltip',
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [InitialItemSortingBuilderFields.ADD_SORTING_RULE]: {
    groupType: StepFieldType.FIELD_BUTTON,
    settings: {
      buttonLabel: 'add_sort_level',
    },
    displayType: [StepFieldDisplayType.DISABLED, StepFieldDisplayType.OMITTED],
  },
});

export default InitialItemSortingFields;
