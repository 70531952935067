import { EditorContent as TipTapEditorContent } from '@tiptap/react';
import styled, { css } from 'styled-components/macro';

import ActiveLinkDialog from 'components/ui/editor/components/dialogs/ActiveLinkDialog';
import EditLinkDialog from 'components/ui/editor/components/dialogs/EditLinkDialog';
import { CONTENT_PADDING } from 'components/ui/editor/constants/styles';
import Spinner from 'components/ui/loading/Spinner';
import { LinkLikeButton } from 'components/ui/shared/Button';
import { ElementTestId } from 'enums/testing';
import { useRichTextEditor } from 'hooks/contexts/useRichTextEditor';
import { NEUTRAL_800, RED_500 } from 'styles/tokens';
import { FONT_SIZE_14, LETTER_SPACING_DENSE } from 'styles/typography';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const baseEditorStyles = css`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
`;

const EditorContent = styled(TipTapEditorContent)<{ $isLoading: boolean }>`
  ${baseEditorStyles}
  display: ${({ $isLoading }) => ($isLoading ? 'none' : 'block')};
  overflow: hidden;

  & > div.tiptap {
    height: 100%;
    overflow-y: auto;
    padding: ${CONTENT_PADDING}px;
    position: relative;
  }
`;

const LoadingWrapper = styled.div<{ isLoading: boolean }>`
  ${baseEditorStyles}
  display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
`;

const LoadingText = styled.div`
  color: ${NEUTRAL_800};
  font-size: ${FONT_SIZE_14};
  letter-spacing: ${LETTER_SPACING_DENSE};
`;

const CancelButton = styled(LinkLikeButton)`
  color: ${RED_500};
`;

const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  padding: 0 48px;
  row-gap: 8px;
  text-align: center;
  width: 100%;
`;

const Content = () => {
  const { contentRef, editor, isLoading, isGeneratingText, generateTextCancel } = useRichTextEditor();

  return (
    <>
      {!!editor?.commands.setLink && (
        <>
          <EditLinkDialog />
          <ActiveLinkDialog />
        </>
      )}
      <EditorContent
        $isLoading={isLoading || isGeneratingText}
        data-testid={ElementTestId.EDITOR_CONTENT}
        editor={editor}
        ref={contentRef}
      />
      <LoadingWrapper
        data-testid={ElementTestId.INPUT_RICH_TEXT_GENERATE_TEXT_LOADING}
        isLoading={isLoading || isGeneratingText}
      >
        <LoadingContainer>
          <Spinner />
          {isGeneratingText && (
            <>
              <LoadingText>{t('generate_description_loading')}</LoadingText>
              <CancelButton onClick={generateTextCancel}>{t('cancel')}</CancelButton>
            </>
          )}
        </LoadingContainer>
      </LoadingWrapper>
    </>
  );
};

export default Content;
