import { memo, type SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  /**
   * The radius of the square.
   */
  radius?: number;
}

const ColorSquare = ({ height = 20, width = 20, radius = 4, ...props }: Props) => (
  <svg height={height} viewBox={`0 0 ${width} ${height}`} width={width} {...props}>
    <rect fill="currentColor" height={height} rx={radius} width={width} />
  </svg>
);

export default memo(ColorSquare);
