import { Mark, mergeAttributes } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    highlight: {
      /**
       * Set a highlight mark
       * @example editor.commands.setLinkHighlight()
       */
      setLinkHighlight: () => ReturnType;
      /**
       * Unset a highlight mark
       * @example editor.commands.unsetLinkHighlight()
       */
      unsetLinkHighlight: () => ReturnType;
    };
  }
}

/**
 * Highlight a link
 */
const commandSetLinkHighlight =
  (name: string) =>
  ({ commands }) =>
    commands.setMark(name);

/**
 * Unset a highlight mark
 */
const commandUnsetLinkHighlight =
  (name: string) =>
  ({ commands }) =>
    commands.unsetMark(name, { extendEmptyMarkRange: true });

export const LinkHighlight = Mark.create({
  name: 'linkHighlight',

  renderHTML({ HTMLAttributes }) {
    return ['mark', mergeAttributes({ class: 'highlighted-link' }, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setLinkHighlight: () => commandSetLinkHighlight(this.name),
      unsetLinkHighlight: () => commandUnsetLinkHighlight(this.name),
    };
  },
});
