import { useState } from 'react';

import styled from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import OverlayImagePreviewDialog from 'components/ui/dialogs/OverlayImagePreviewDialog';
import ExpandIcon from 'components/ui/icons/ExpandIcon';
import { Clickable } from 'components/ui/shared/Button';
import { ElementTestId } from 'enums/testing';
import type {
  OverlayRetailBulkAdjustmentPosition,
  OverlayRetailBulkAdjustmentScalingDimension,
} from 'store/api/graph/interfaces/types';
import { BODY_TEXT } from 'styles/color';
import { ENTITY_PADDING } from 'styles/spacing';
import { NEUTRAL_0 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

import { OverlayImagePreviewBox } from './OverlayImagePreviewBox';

const { t } = translate;

const PreviewFrame = styled.div`
  padding: ${ENTITY_PADDING};
  background: ${NEUTRAL_0};
  border-radius: 10px;
  width: 100%;
  margin-bottom: ${ENTITY_PADDING};
`;

const PreviewFrameHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${ENTITY_PADDING};
`;

/**
 * The configuration props for rendering the overlay image preview
 */
export interface OverlayImagePreviewProps {
  // The imageUrl of the overlay image. Can be a http url or base64 data url.
  imageUrl: string;
  // The position of the overlay image
  position: OverlayRetailBulkAdjustmentPosition;
  // The scaling dimension of the overlay image
  scalingDimension: OverlayRetailBulkAdjustmentScalingDimension;
  // The amount to scale the overlay image (between 0 and 100)
  scale: number;
}

/**
 * Main previewing component, will show preview panels for 4:3 and 1:1 aspect ratios
 */
const OverlayImagePreview = (props: OverlayImagePreviewProps) => {
  const [isAspectRatio1DialogOpen, setAspectRatio1DialogOpen] = useState(false);
  const [isAspectRatio2DialogOpen, setAspectRatio2DialogOpen] = useState(false);

  return (
    <>
      <PreviewFrame>
        <PreviewFrameHeader>
          <Label>{t('four_to_three_overlay_preview')}</Label>
          <Clickable
            css={'margin-left: auto'}
            data-testid={ElementTestId.OVERLAY_IMAGE_EXPAND_ICON_4_BY_3}
            onClick={() => setAspectRatio1DialogOpen(true)}
          >
            <ExpandIcon color={BODY_TEXT} />
          </Clickable>
        </PreviewFrameHeader>
        <OverlayImagePreviewBox {...props} altImageText={t('four_to_three_overlay_preview')} aspectRatio={4 / 3} />
      </PreviewFrame>

      <PreviewFrame>
        <PreviewFrameHeader>
          <Label>{t('one_to_one_overlay_preview')}</Label>
          <Clickable
            css={'margin-left: auto'}
            data-testid={ElementTestId.OVERLAY_IMAGE_EXPAND_ICON_1_BY_1}
            onClick={() => setAspectRatio2DialogOpen(true)}
          >
            <ExpandIcon color={BODY_TEXT} />
          </Clickable>
        </PreviewFrameHeader>
        <OverlayImagePreviewBox {...props} altImageText={t('one_to_one_overlay_preview')} aspectRatio={1} />
      </PreviewFrame>

      <OverlayImagePreviewDialog
        {...props}
        altImageText={t('four_to_three_overlay_preview')}
        aspectRatio={4 / 3}
        isOpen={isAspectRatio1DialogOpen}
        onCancel={() => setAspectRatio1DialogOpen(false)}
        testId={ElementTestId.OVERLAY_IMAGE_PREVIEW_4_BY_3}
      />
      <OverlayImagePreviewDialog
        {...props}
        altImageText={t('one_to_one_overlay_preview')}
        aspectRatio={1}
        isOpen={isAspectRatio2DialogOpen}
        onCancel={() => setAspectRatio2DialogOpen(false)}
        testId={ElementTestId.OVERLAY_IMAGE_PREVIEW_1_BY_1}
      />
    </>
  );
};

export default OverlayImagePreview;
