import { useEffect, useState } from 'react';

import { isNil } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { ListSection, ListSectionTitle } from 'components/ui/lists/CommonList';
import { CTAButton } from 'components/ui/shared/Button';
import Scrollable from 'components/ui/shared/Scrollable';
import { ToggleTitle } from 'components/ui/shared/Toggles';
import { useSearch } from 'hooks/contexts/useSearch';
import { NEUTRAL_800 } from 'styles/tokens';
import { Z_INDEX_2 } from 'styles/z-index';
import { formatNumber } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

import { FacetFilterSection } from './common';
import FacetTagFilter from './FacetTagFilter';
import type { CustomFilterType } from './Filters';
import { DefaultFilters } from './interfaces/filters';
import RangeFilter from './RangeFilter';
import { SortFilterSection } from './SortFilter';
import { ToggleGroupFilters } from './ToggleGroupFilter';

const FilterSectionFooterActions = styled.div`
  position: sticky;
  bottom: 0;
  padding: 15px;
  background: rgba(0, 0, 0, 0);
  z-index: ${Z_INDEX_2};

  button {
    box-shadow:
      0 1px 0 0 rgba(0, 0, 0, 0.04),
      0 2px 8px 0 rgba(0, 0, 0, 0.12);
  }
`;

const rangeFilterSectionLabel = ({ selectedRange, filter, lteLabel, gteLabel, format }) => {
  if (!filter.range) {
    return 'N/A';
  }

  const rangeIsEmpty = !selectedRange || (selectedRange.gte === null && selectedRange.lte === null);
  const selectedRangeMatchesFullRange =
    selectedRange.gte === filter.range.gte && selectedRange.lte === filter.range.lte;

  if (rangeIsEmpty || selectedRangeMatchesFullRange) {
    return translate.t('any');
  }
  if (Number(selectedRange.gte) === filter.range.gte || selectedRange.gte === null) {
    return `${format(selectedRange.lte)} ${translate.t('and')} ${lteLabel}`;
  }
  if (Number(selectedRange.lte) === filter.range.lte || selectedRange.lte === null) {
    return `${format(selectedRange.gte)} ${translate.t('and')} ${gteLabel}`;
  }
  return `${format(selectedRange.gte)} - ${format(selectedRange.lte)}`;
};

export const RangeFilterSection = ({
  filter,
  gteLabel = translate.t('more'),
  lteLabel = translate.t('less'),
  format = value => `${formatNumber(value)} ${filter.unit || ''}`,
}) => {
  const { getSearchParam, updateSearchParam } = useSearch();
  const searchParam = getSearchParam(filter.id) || { gte: null, lte: null };
  const [selectedRange, setSelectedRange] = useState(searchParam);

  useEffect(() => {
    setSelectedRange({
      gte: isNil(searchParam.gte) ? null : Number.parseInt(searchParam.gte),
      lte: isNil(searchParam.lte) ? null : Number.parseInt(searchParam.lte),
    });
  }, [searchParam.gte, searchParam.lte]);

  return (
    <ListSection disabled={!filter.range}>
      <ListSectionTitle>
        <Label>{filter.name}</Label>
        <Label>{rangeFilterSectionLabel({ selectedRange, filter, lteLabel, gteLabel, format })}</Label>
      </ListSectionTitle>
      <RangeFilter
        filter={filter}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        updateSearchParam={updateSearchParam}
      />
    </ListSection>
  );
};

const ToggleGroupFilterSection = ({ filter }) =>
  filter.toggles.length > 0 ? (
    <ListSection>
      <ToggleTitle />
      <ToggleGroupFilters toggles={filter.toggles} />
    </ListSection>
  ) : null;

export const FacetFilterWithFewFacets = ({ filter }) => (
  <ListSection>
    <ListSectionTitle>{filter.name}</ListSectionTitle>
    <FacetTagFilter filter={filter} />
  </ListSection>
);

const FacetFilterWithManyFacets = ({ filter, setSelectedFacetFilterId }) => (
  <FacetFilterSection filter={filter} onClick={() => setSelectedFacetFilterId(filter.id)}>
    {filter.name}
  </FacetFilterSection>
);

const FilterSections = ({
  customFilterSections,
  searchParams,
  filters,
  setSelectedFacetFilterId,
  overrideSection,
  containerRef,
  columns,
  disabledFilters,
  /**
   * Callback for whenever the on save filter button is clicked. If no callback is provided, no button will be
   * rendered
   */
  onSaveFilterButtonClick,
}) => {
  const allFilters = [
    ...(customFilterSections?.({ connectionSearchParams: searchParams, serverFilters: filters }) || []),
  ].reduce(
    (acc, curr: CustomFilterType) => {
      if (!curr.enabled) {
        return acc;
      }

      // Insert custom filter in proper order
      acc.splice(curr.order, 0, curr);
      return acc;
    },
    [...filters]
  );

  return (
    <Scrollable ref={containerRef}>
      {!disabledFilters.includes(DefaultFilters.SORT) && (
        <SortFilterSection columns={columns} setSelectedFacetFilterId={setSelectedFacetFilterId} />
      )}
      {allFilters.map(filter => {
        // Rendering of custom filters
        if (!filter.__typename) {
          return filter.renderElement;
        }

        // Overriding of server filters
        const override = overrideSection(filter);
        if (override || override === null) {
          return override;
        }
        switch (filter.__typename) {
          case 'FacetFilter': {
            return (
              <FacetFilterWithManyFacets
                filter={filter}
                key={filter.id}
                setSelectedFacetFilterId={setSelectedFacetFilterId}
              />
            );
          }

          case 'ToggleGroupFilter': {
            return <ToggleGroupFilterSection filter={filter} key={filter.id} />;
          }

          case 'RangeFilter': {
            return <RangeFilterSection filter={filter} key={filter.id} />;
          }
        }
        return null;
      })}

      {!!onSaveFilterButtonClick && (
        <FilterSectionFooterActions>
          <CTAButton
            css={css`
              background: ${NEUTRAL_800};
            `}
            onClick={() => onSaveFilterButtonClick()}
          >
            {translate.t('save_filter_one')}
          </CTAButton>
        </FilterSectionFooterActions>
      )}
    </Scrollable>
  );
};

export default FilterSections;
