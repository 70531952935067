import { t } from 'i18next';
import { get, isNil, set } from 'lodash-es';

import type { MetadataResponse } from 'components/core/createModify/interfaces/queryMethods';
import { websiteRetailFilterMetaquery } from 'components/sections/shared/ItemMetaQueries';
import { ApolloFetchPolicy } from 'enums/apollo';
import { InventoryItem } from 'enums/columns/inventoryItem';
import type { SegmentFilterFieldSettings } from 'enums/columns/inventoryItemSegmentFilter';
import { InventoryItemSegmentFilter, inventoryItemSegmentFilterFields } from 'enums/columns/inventoryItemSegmentFilter';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type {
  IntRange,
  MultilingualStringInput,
  RetailItemConnectionFilter,
  RetailItemConnectionFilterFragment,
  WebsiteDetailFragment,
  WebsiteRetailFilterMetaquery,
  WebsiteRetailFilterMetaqueryVariables,
} from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import { getField } from 'utils/formatting/inventoryItemFormatUtils';
import { formatCurrency, formatRange } from 'utils/formatUtils';

import { RetailFilterBuilderFields } from '../shared/steps/interfaces';

import { websiteRouteDelete } from './WebsiteRouteCreateModifyQuery';
import type { RouteFilterGroup } from './WebsiteRouteFilterBadges';

/** Necessary parameters to pass to WebsiteRetailFilterMetaquery */
export type WebsiteMetadataParams = {
  groupId: string;
  groupRooftopIds: string[];
  rooftopIds: string[];
  makeIds: string[];
  modelIds: string[];
  subModelIds: string[];
};

/** Gets all the appropriate metadata needed to display a RetailItemConnectionFilter */
export const getMetadataForFilter = async (
  /** Filter to get metadata for */
  filter: RetailItemConnectionFilter,
  /** GroupID of the website the filter belongs to */
  groupId: string
) => {
  const rooftopId = filter?.rooftopId;

  const params = {
    groupId,
    rooftopIds: rooftopId || [],
    modelIds: filter.modelId || [],
    makeIds: filter.makeId || filter.makeIdExcluded || [],
    subModelIds: filter.subModelId || [],
  } as WebsiteMetadataParams;

  return fetchWebsiteRetailFilterMetaquery(params);
};

/** A custom formatted metadata object derived from WebsiteRetailFilterMetaquery */
export type FetchWebsiteRetailFilterMetaqueryMetadata = {
  metadata: undefined;
  mutation: undefined;
  [RetailFilterBuilderFields.ROOFTOP_ID]: WebsiteRetailFilterMetaquery['rooftopId']['rooftops'];
  [InventoryItem.CONDITION]: WebsiteRetailFilterMetaquery['metadata']['mutation']['inventoryItem']['condition'];
  [InventoryItem.STATUS]: WebsiteRetailFilterMetaquery['metadata']['mutation']['inventoryItem']['retailItem']['status'];
  vehicleAttributes: {
    // InventoryItem.VEHICLE_FUEL_TYPE
    fuelType: WebsiteRetailFilterMetaquery['metadata']['mutation']['inventoryItem']['vehicleAttributes']['fuelType'];
    // InventoryItem.VEHICLE_BODY_TYPE
    bodyType: WebsiteRetailFilterMetaquery['metadata']['mutation']['inventoryItem']['vehicleAttributes']['bodyType'];
  };
  [InventoryItemSegmentFilter.MAKE_ID]: WebsiteRetailFilterMetaquery['makeId'];
  [InventoryItemSegmentFilter.MAKE_ID_EXCLUDED]: WebsiteRetailFilterMetaquery['makeId'];
  [InventoryItemSegmentFilter.MODEL_ID]: WebsiteRetailFilterMetaquery['modelId'];
  [InventoryItemSegmentFilter.SUBMODEL_ID]: WebsiteRetailFilterMetaquery['subModelId'];
  [InventoryItemSegmentFilter.TAG_ID]: WebsiteRetailFilterMetaquery['tagId'];
  websiteSortOptions: WebsiteRetailFilterMetaquery['websiteSortOptions'];
} & Omit<WebsiteRetailFilterMetaquery, 'metadata'>;

/** Fetches metadata for a website routes RetailItemConnectionFilter */
export const fetchWebsiteRetailFilterMetaquery = async (
  /** Parameters for meta query */
  { groupId, rooftopIds, makeIds, modelIds, subModelIds }: WebsiteMetadataParams
) => {
  const observable = client.watchQuery<WebsiteRetailFilterMetaquery, WebsiteRetailFilterMetaqueryVariables>({
    query: websiteRetailFilterMetaquery,
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    variables: {
      groupId,
      rooftopIds,
      makeIds,
      modelIds,
      d_makesOn: makeIds.length > 0,
      d_modelsOn: modelIds.length > 0,
      d_subModelsOn: subModelIds.length > 0,
    },
  });

  return new Promise<MetadataResponse<FetchWebsiteRetailFilterMetaqueryMetadata>>((resolve, reject) => {
    const subscription = observable.subscribe({
      next: res => {
        /**
         *  Top level `metadata` from response not used, contents are
         *  Instead spread for builder `metadata` structure consistency
         */
        const metadata = {
          ...res.data.metadata,
          ...res.data,
          metadata: undefined,
          mutation: undefined,
        };

        // Bring metadata values to top level of response so they match field ids
        set(metadata, RetailFilterBuilderFields.ROOFTOP_ID, res?.data?.rooftopId?.rooftops);
        set(metadata, InventoryItem.CONDITION, res?.data?.metadata?.mutation?.inventoryItem?.condition);
        set(metadata, InventoryItem.STATUS, res?.data?.metadata?.mutation?.inventoryItem?.retailItem?.status);
        set(
          metadata,
          InventoryItem.VEHICLE_FUEL_TYPE,
          res?.data?.metadata?.mutation?.inventoryItem?.vehicleAttributes?.fuelType
        );
        set(
          metadata,
          InventoryItem.VEHICLE_BODY_TYPE,
          res?.data?.metadata?.mutation?.inventoryItem?.vehicleAttributes?.bodyType
        );

        set(metadata, InventoryItemSegmentFilter.MAKE_ID_EXCLUDED, res?.data?.makeId);

        resolve({ subscription, metadata: metadata as FetchWebsiteRetailFilterMetaqueryMetadata });
      },
      error: (error: Error) => {
        logApiError(error);
        reject(error);
      },
    });
  });
};

/** Extracts all required parameters for a metadata query of a given website */
export function getParametersForMetadata(
  /** Website to retrieve parameters from */
  item?: WebsiteDetailFragment
): WebsiteMetadataParams {
  const groupId = item?.groupName.id;
  const rooftopIds = item?.routes?.reduce((acc: string[], route) => {
    for (const filter of route.inventoryItemSegment.filters) {
      if (filter.rooftopId) {
        acc = [...acc, ...filter.rooftopId];
      }
    }
    return acc;
  }, []);

  const makeIds = item?.routes?.reduce((acc: string[], route) => {
    for (const filter of route.inventoryItemSegment.filters) {
      if (filter.makeId) {
        acc = [...acc, ...filter.makeId];
      }
      if (filter.makeIdExcluded) {
        acc = [...acc, ...filter.makeIdExcluded];
      }
    }
    return acc;
  }, []);

  const modelIds = item?.routes.reduce((acc: string[], route) => {
    for (const filter of route.inventoryItemSegment.filters) {
      if (filter.modelId) {
        acc = [...acc, ...filter.modelId];
      }
    }
    return acc;
  }, []);

  const subModelIds = item?.routes.reduce((acc: string[], route) => {
    for (const filter of route.inventoryItemSegment.filters) {
      if (filter.subModelId) {
        acc = [...acc, ...filter.subModelId];
      }
    }
    return acc;
  }, []);

  const groupRooftopIds = item?.groupName?.rooftops?.map(({ id }) => id) || [];

  return {
    groupId,
    groupRooftopIds,
    rooftopIds: [...new Set(rooftopIds)],
    makeIds: [...new Set(makeIds)],
    modelIds: [...new Set(modelIds)],
    subModelIds: [...new Set(subModelIds)],
  } as WebsiteMetadataParams;
}

/** Used to generate a route filter group array that can then be displayed as badges */
export function getRouteFilterGroup(
  /** Filter to turn into a RouteFilterGroup array */
  filter: RetailItemConnectionFilterFragment,
  /** Necessary metadata for displaying rooftops, models, submodels and tags correctly */
  metadata?: any
): RouteFilterGroup[] {
  const filterStrings: RouteFilterGroup[] = [];
  for (const fieldSettings of inventoryItemSegmentFilterFields) {
    const rawFieldValue = get(filter, fieldSettings.key);
    const metadataDerivedFieldValue = getField(filter, fieldSettings.key, metadata, undefined, null);
    const displayedFieldValue = metadataDerivedFieldValue || rawFieldValue;
    if ((rawFieldValue as IntRange) && rawFieldValue.gte === null && rawFieldValue.lte === null) {
      continue;
    }
    if (!isNil(displayedFieldValue)) {
      filterStrings.push(getRouteFilterGroupForField(fieldSettings, displayedFieldValue));
    }
  }
  return filterStrings;
}

function getRouteFilterGroupForField(
  fieldSettings: SegmentFilterFieldSettings,
  fieldValue: string | IntRange | string[] | boolean
): RouteFilterGroup {
  let filterValues: string[] = [];
  switch (fieldSettings.key) {
    // Boolean Fields
    case InventoryItemSegmentFilter.HAS_MILEAGE:
    case InventoryItemSegmentFilter.HAS_PHOTOS:
    case InventoryItemSegmentFilter.HAS_PRICE:
    case InventoryItem.MAPPED:
    case InventoryItem.SHOW_WEB:
    case InventoryItem.AS_IS:
    case InventoryItem.CERTIFIED:
    case InventoryItem.DEMO:
    case InventoryItem.FEATURED:
    case InventoryItem.ON_ORDER:
    case InventoryItem.IN_TRANSIT:
    case InventoryItem.COMPLETE: {
      const flag = fieldValue as boolean;
      filterValues = flag ? [t('yes')] : [t('no')];
      break;
    }

    // Single Select Enum Fields
    case InventoryItem.CONDITION:
    case InventoryItem.TYPE: {
      const condition = fieldValue as string;
      filterValues = [condition];
      break;
    }

    // Int Range Fields - Currency
    case InventoryItemSegmentFilter.PURCHASE_PRICE: {
      const priceRange = fieldValue as IntRange;
      const formattedPriceRange = formatRange({
        gte: priceRange.gte && formatCurrency({ amount: priceRange.gte }),
        lte: priceRange.lte && formatCurrency({ amount: priceRange.lte }),
      });
      filterValues = [formattedPriceRange];
      break;
    }

    // Int Range Fields
    case InventoryItemSegmentFilter.YEAR:
    case InventoryItemSegmentFilter.DAYS_IN_STOCK:
    case InventoryItem.VEHICLE_MILEAGE: {
      const range = fieldValue as IntRange;
      const formattedRange = formatRange(
        range,
        fieldSettings.key === InventoryItem.VEHICLE_MILEAGE ? t('km') : undefined
      );
      filterValues = [formattedRange];
      break;
    }

    default: {
      filterValues = fieldValue as string[];
    }
  }

  return {
    label: fieldSettings.label,
    filterValues,
  };
}

/**
 * Generates a filter name for RetailItemConnectionFilterStepOption using it's index
 * This value is needed because StepOptions need a name field to be displayed in a ListSelection component
 */
export function getFilterName(index: number): string {
  return 'filter_' + index;
}

/**
 * Generates a random path to use when validating a filter. We want to ensure the only errors returned
 * are relating to the filter object, so we generate a valid random path to pass to the mutation.
 */
export function generateRandomPath(): MultilingualStringInput {
  const randomPath = Math.floor(Math.random() * 900000000) + 100000000;
  return {
    en: `/${randomPath}`,
    fr: `/${randomPath}-fr`,
    es: `/${randomPath}-es`,
  } as MultilingualStringInput;
}

export const deleteWebsiteRoute = async (id: string) =>
  client.mutate({
    mutation: websiteRouteDelete,
    variables: { id },
    refetchQueries: ['WebsiteDetailsContainerQuery'],
  });
