import LoggingService from 'components/core/logging/LoggingService';
import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import type { FieldDataType } from 'enums/fieldDataType';
import type { RetailItemLockableField, VehicleAttributesLockableField } from 'store/api/graph/interfaces/types';

import type { Intl } from './intlUtils';
import { convertedNestedString } from './stringUtils';

type BaseFieldSettings = {
  /** The translation key for the label */
  label: string;
  /** Type of field to be displayed */
  type?: FieldDataType;
  /** The translation key for the tooltip. */
  tooltip?: string;
  /** An optional override for specifying the API columnId that maps to this field */
  columnIdOverride?: string;
  /** The id of the field that corresponds to lockedFields or vehicleAttributes.lockedFields */
  lockedFieldId?: RetailItemLockableField | VehicleAttributesLockableField;
};
export type BooleanFieldSettings = {
  /** The translation key for the label */
  label: string;
  type: FieldDataType.BOOLEAN;
  /** The translation key for the tooltip. */
  tooltip?: string;
  /** Translation keys to override the default "Yes/No" boolean representation with. */
  yesNoOverride?: { yesTranslationKey: Intl; noTranslationKey: Intl };
};
export type TableSettingsType = { [key: string]: BaseFieldSettings | BooleanFieldSettings };

/*
 * Construct cell data of a given column type and table configuration data
 *
 * @param tableSettings - the `TableSettingsType`, table configuration data files are defined in enum directory
 * @param columnId - the column id to lookup in the table configuration data, this used fetch label and type properties
 * @param customCellData - custom data to override default cell data
 */
export const getCellDataByItemColumnType = (
  columnId: string,
  tableSettings?: TableSettingsType | null,
  customCellData?: Partial<TableCellData>
): TableCellData => ({
  rowId: 'header',
  columnId,
  enabled: true,
  width: 120,
  minWidth: 60,
  resizable: true,
  canReorder: true,
  cellType: tableSettings?.[columnId]?.type,
  columnIdOverride: (tableSettings?.[columnId] as BaseFieldSettings)?.columnIdOverride,
  ...customCellData,
  get content() {
    return (
      customCellData?.content || tableSettings?.[columnId]?.label || (customCellData?.content === undefined && columnId)
    );
  },
});

/**
 * Utility method that returns a cell className based off of the columnId the cell belongs to
 */
export const getCellClassName = ({ columnId }: Pick<TableCellData, 'columnId'>): string =>
  `cell-${convertedNestedString(columnId)}`;

/**
 * Utility method that finds & sets the cell width by columnId in an element
 */
export const setPhysicalCellWidth = (el: HTMLDivElement, cellData: TableCellData) => {
  const selector = `.${getCellClassName(cellData)}`;
  const target = el.querySelector(selector);
  if (!target) {
    LoggingService.debug({ message: `Unable to set width, cell for: ${cellData.columnId} (${selector}) not found` });
    return;
  }

  target['style'].width = `${cellData.width}px`;
};

/**
 * Sort table columns based on a provided sort order
 * @param array - The list of columns that need to be sorted
 * @param sortOrder - The order in which these columns should be sorted
 */
export const getSortedColumns = (array: TableCellData[], sortOrder: string[]): TableCellData[] => {
  /**
   * We only want to sort table columns that are found within the sort order. If there is a table column that is not
   * found in the sort order list, then we just want to ignore it, as no instruction has been given on how this column
   * should be sorted.
   */
  const parsingObject: { sortable: TableCellData[]; ignore: TableCellData[] } = { sortable: [], ignore: [] };
  const parsedItems = array.reduce((parsed, currentItem) => {
    if (sortOrder.includes(currentItem.columnId)) {
      parsed.sortable.push(currentItem);
    } else {
      parsed.ignore.push(currentItem);
    }
    return parsed;
  }, parsingObject);

  const sorted = parsedItems.sortable.sort((a, b) => {
    const index1 = sortOrder.indexOf(a.columnId);
    const index2 = sortOrder.indexOf(b.columnId);

    return index1 - index2;
  });

  return [...sorted, ...parsedItems.ignore];
};
