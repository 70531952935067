import type { MouseEvent, ReactNode } from 'react';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { isFunction } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import Text from 'components/core/typography/Text';
import ChevronDownIcon from 'components/ui/icons/ChevronDownIcon';
import ChevronUpIcon from 'components/ui/icons/ChevronUpIcon';
import MoreIcon from 'components/ui/icons/MoreIcon';
import type { ListItemType } from 'components/ui/lists/CommonList';
import { ListItem, ListSectionItemTitle, RevisionListItem } from 'components/ui/lists/CommonList';
import IconButton from 'components/ui/shared/IconButton';
import OutsideClick from 'components/ui/shared/OutsideClick';
import type { ItemViewType } from 'enums/ItemViewType';
import { ElementTestId } from 'enums/testing';
import { NEUTRAL_0, SPACE_200, SPACE_300, WIDTH_750 } from 'styles/tokens';
import { FONT_WEIGHT_SEMI_BOLD } from 'styles/typography';
import { Z_INDEX_1, Z_INDEX_4 } from 'styles/z-index';
import { formatTitleString } from 'utils/formatUtils';
import { variants } from 'utils/styledUtils';

import ActionsBarButton from './ActionsBarButton';

export enum MenuButtonType {
  DEFAULT = 'DEFAULT',
  MORE = 'MORE',
  CUSTOM = 'CUSTOM',
  TIMESTAMP = 'TIMESTAMP',
}

export enum MenuPosition {
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
}

export const MenuItem = styled(ListItem)<{ disabled?: boolean }>`
  background-color: ${NEUTRAL_0};
  padding: 14px 17px;
  display: block;
  > ${ListSectionItemTitle} {
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      ${Text} {
        opacity: 0.3;
      }

      svg {
        display: none;
      }
    `}
`;

export const RevisionMenuItem = styled(RevisionListItem)`
  background-color: ${NEUTRAL_0};
  padding: 14px 17px;
  display: block;
  > ${ListSectionItemTitle} {
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
  }
`;

export const MenuIconContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export interface MenuItemConfig extends ListItemType {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  /**
   * Whether or not to check the `archived` property of the item when `disabled`.
   * Disabled for special conditions e.g. the `archive` button itself and items with no `archive` property.
   */
  isArchivable?: boolean;
  testId?: string;
}

export interface MenuItemProps {
  type: ItemViewType;
  item?: any; // TODO [#1635]: Make non optional once all sections migrated and responseTypes added
  onToggleEditMode?: (event: MouseEvent<HTMLButtonElement, MouseEvent>, active: boolean) => Promise<void>;
  setIsDetailsUpdating: (isUpdating: boolean) => void;
  isUpdating: boolean;
  onUpdateArchiveStatus?: () => void;
}

const { DEFAULT, MORE, CUSTOM, TIMESTAMP } = MenuButtonType;

const { TOP_LEFT, TOP_RIGHT, BOTTOM_LEFT } = MenuPosition;

const Container = styled.div`
  position: relative;
  display: flex;

  /* This needs to have a z-index of 4, because section filters use a z-index of 2, and page loaders use a z-index
   * of 3 which causes the user menu to appear hidden behind the filters
   */
  z-index: ${Z_INDEX_4};
`;

export const MenuItemsContainer = styled.div<{ position?: MenuPosition; isOpen?: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  margin-top: 42px;
  z-index: ${Z_INDEX_1};
  ${variants<MenuPosition>(
    'position',
    {
      [TOP_LEFT]: css`
        top: 0;
        left: 0;
      `,
      [TOP_RIGHT]: css`
        top: 0;
        right: 0;
      `,
      [BOTTOM_LEFT]: css`
        bottom: 0;

        /* Icon width + Global Nav Padding + Offset */
        left: calc(${WIDTH_750} + ${SPACE_300} + ${SPACE_200});
        margin-top: 0;
      `,
    },
    TOP_LEFT
  )};
`;

interface MenuItemsProps {
  items: MenuItemConfig[];
  isArchived?: boolean;
}

export const MenuItems = ({ items, isArchived }: MenuItemsProps) => (
  <>
    {items
      .filter(({ disabled, isArchivable = true }) => (isArchivable ? !isArchived && !disabled : !disabled))
      .map(({ label, onClick, testId, titleCss }) => (
        <MenuItem
          icon={null}
          key={label}
          label={formatTitleString(label)}
          onClick={onClick}
          testId={testId}
          titleCss={titleCss}
        />
      ))}
  </>
);

interface Props {
  type?: MenuButtonType;
  position?: MenuPosition;
  autoClose?: boolean;
  leftIcon?: ReactNode;
  // The customIcon property can be either a function that expects isOpen state, or it can just be a ReactNode
  customIcon?: ReactNode | ((isOpen: boolean) => ReactNode);
  text?: string;
  children?: ReactNode;
  testId?: string;
  disabled?: boolean;
}

const MenuButton = ({
  customIcon = null,
  type = DEFAULT,
  position = TOP_LEFT,
  autoClose = true,
  leftIcon = null,
  text = '',
  disabled = false,
  testId,
  ...props
}: Props) => {
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const rightIcon = isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />;
  const toggleMenu = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const closeMenu = useCallback(() => autoClose && setIsOpen(false), [autoClose]);
  const [menuItemsCount, setMenuItemsCount] = useState(menuButtonRef?.current?.nextElementSibling?.childElementCount);
  const showMenuButton = menuItemsCount === undefined || menuItemsCount > 0;
  const renderedCustomIcon = isFunction(customIcon) ? customIcon(isOpen) : customIcon;
  let menuButton;

  useLayoutEffect(
    () => {
      setMenuItemsCount(menuButtonRef?.current?.nextElementSibling?.childElementCount);
    },
    // Ignoring because the count needs to update based specifically on children

    [menuButtonRef?.current?.nextElementSibling]
  );

  switch (type) {
    case DEFAULT: {
      menuButton = (
        <ActionsBarButton disabled={disabled} leftIcon={leftIcon} rightIcon={rightIcon} testId={testId} text={text} />
      );
      break;
    }

    case MORE: {
      menuButton = (
        <IconButton data-testid={testId}>
          <MoreIcon />
        </IconButton>
      );
      break;
    }

    case CUSTOM: {
      menuButton = renderedCustomIcon;
      break;
    }

    case TIMESTAMP: {
      menuButton = (
        <ActionsBarButton
          css={'justify-content: space-between'}
          disabled={disabled}
          leftIcon={leftIcon}
          rightIcon={
            <>
              {renderedCustomIcon}
              {rightIcon}
            </>
          }
          testId={testId}
          text={text}
        />
      );
      break;
    }

    default: {
      break;
    }
  }

  return (
    <Container
      css={css`
        display: ${showMenuButton ? 'flex' : 'none'};
      `}
    >
      <MenuIconContainer onClick={disabled ? () => {} : toggleMenu} ref={menuButtonRef}>
        {menuButton}
      </MenuIconContainer>
      <OutsideClick ignoredElements={[menuButtonRef.current]} onClick={closeMenu}>
        <MenuItemsContainer
          data-testid={ElementTestId.MENU_ITEMS_CONTAINER}
          isOpen={isOpen}
          onClick={closeMenu}
          position={position}
        >
          {props.children}
        </MenuItemsContainer>
      </OutsideClick>
    </Container>
  );
};

export default MenuButton;
