import { memo, type SVGProps } from 'react';

import { ElementTestId } from 'enums/testing';

const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg data-testid={ElementTestId.LOCK_ICON} fill="none" height={12} width={12} {...props}>
    <path
      d="M2.951 11.62c-.374 0-.689-.127-.943-.381a1.281 1.281 0 0 1-.381-.943V5.198c0-.375.127-.69.381-.944s.569-.38.943-.38h.2v-1c0-.8.278-1.479.834-2.037A2.74 2.74 0 0 1 6 0c.788 0 1.46.279 2.016.837.555.558.833 1.237.833 2.037v1h.2c.375 0 .689.126.943.38s.381.569.381.944v5.098c0 .374-.127.689-.38.943a1.281 1.281 0 0 1-.944.381H2.95Zm3.051-2.674c.324 0 .6-.115.829-.345.229-.23.344-.508.344-.831 0-.324-.116-.6-.346-.83a1.136 1.136 0 0 0-.83-.343c-.324 0-.6.115-.83.346-.229.23-.343.507-.343.83 0 .324.115.6.345.83.23.229.508.343.831.343ZM4.476 3.873h3.048v-1c0-.424-.148-.788-.445-1.093A1.454 1.454 0 0 0 6 1.324c-.424 0-.784.152-1.08.456a1.513 1.513 0 0 0-.443 1.094v1Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(LockIcon);
