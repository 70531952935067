import type { ElementType } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';

import type { ApolloError, ServerError } from '@apollo/client';
import { isEqual, isFunction } from 'lodash-es';

import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import Loader from 'components/ui/loading/Loader';
import { ElementTestId } from 'enums/testing';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useGlobalDialog } from 'hooks/contexts/useGlobalDialog';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { useUser } from 'hooks/contexts/useUser';
import useEditField from 'hooks/useEditField';
import { usePrevious } from 'hooks/usePrevious';
import { getApiErrors } from 'store/api/graph/interfaces/apiErrors';
import { useNestedViewQueryProxy } from 'utils/graphQueryUtils';
import { authorizedCallback } from 'utils/permissionUtils';
import { deferredRenderCall } from 'utils/renderingUtils';

import type { SecondaryDetailViewProps } from './NestedViewSettings';

interface NestedViewDetailRendererProps {
  /** Configuration needed for rendering this details view (query, queryVars, etc) */
  configuration: SecondaryDetailViewProps;
  /** The current element to render in this details view */
  renderElement?: ElementType;
  /** Callback for when data from the query has been loaded */
  onDataLoaded?: (data: any) => void;
}

// TODO: Separate details from list type rendering into different sub components
const NestedViewDetailRenderer = ({
  configuration,
  renderElement: RenderElement,
  onDataLoaded,
}: NestedViewDetailRendererProps) => {
  const { builderConfig } = useBuilderConfig();
  const {
    viewItems: [viewItem],
    back,
  } = useNestedView();
  const { setConfig } = useGlobalDialog();
  const { hasPermissions } = useUser();

  // State
  const [, setError] = useState();

  const {
    isLoaded,
    isLoading,
    error,
    data: {
      metadata,
      // Note: `item` only appears for `details` views, else the content is populate by `connection.edges`
      item = viewItem?.seededData,
    },
    data,
  } = useNestedViewQueryProxy(
    configuration.query,
    viewItem.queryVars,
    configuration.useQueryOverride,
    viewItem?.seededData
  );

  const dataPrev = usePrevious(data);

  // Callbacks
  const onEdit = useEditField({
    tierData: {
      itemId: item?.id,
      title: viewItem.title,
      type: isFunction(configuration.editBuilder) ? configuration.editBuilder(item) : configuration.editBuilder,
      entityType: viewItem.entityType,
    },
  });

  // Permissions
  const hasEditPermission = useMemo(() => {
    const builder = isFunction(configuration.editBuilder) ? configuration.editBuilder(item) : configuration.editBuilder;

    return builder && builderConfig[builder] ? hasPermissions(builderConfig[builder].requiredPermissions) : false;
  }, [configuration, item, builderConfig, hasPermissions]);
  const isEditAllowed = hasEditPermission && !isItemArchived(item);

  useEffect(() => {
    if (!isEqual(data, dataPrev) && !isLoading && isLoaded) {
      onDataLoaded?.(item);
    }
  }, [data, dataPrev, isLoading, isLoaded, item, onDataLoaded]);

  if (error?.message) {
    const serverError = (error as ApolloError).networkError as ServerError;
    if (serverError.statusCode === 403) {
      /**
       * Required due to rendering order issues with NestedView
       * calling the global context too early and causing an error
       */
      deferredRenderCall(() => {
        back();
        setConfig({
          errorsOverride: getApiErrors(error),
        });
      });
    } else {
      setError(() => {
        throw new Error(error?.message);
      });
    }
  }

  if (!RenderElement) {
    return null;
  }

  return isLoading ? (
    <Loader css="background: transparent;" />
  ) : (
    <div data-testid={ElementTestId.NESTED_VIEW_DETAILS_CONTAINER}>
      <RenderElement
        {...configuration.renderDataProps}
        isNested={true}
        item={item}
        metadata={metadata}
        onEdit={authorizedCallback({
          cb: onEdit,
          isAuth: isEditAllowed,
        })}
      />
    </div>
  );
};

export default memo(NestedViewDetailRenderer);
