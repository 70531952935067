import type OriginalStepField from 'components/core/createModify/interfaces/stepField';
import type { Intl } from 'utils/intlUtils';

interface StepField<
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
  TReturnValue = any,
> extends OriginalStepField<TData, TMetaData, TReturnValue> {
  /**
   * The label key of the field to be displayed
   */
  label: Intl;
  /**
   * The placeholder key of the field to be displayed
   */
  placeholder: Intl;
  /**
   * The sub-label key of the field to be displayed
   */
  subLabel: Intl;
  /**
   * The tooltip key of the field to be displayed
   */
  tooltip: Intl;
}

type StepFields<TData extends Record<string, any> = any, TMetaData extends Record<string, any> = any> = {
  [key: string]: Partial<StepField<TData, TMetaData>>;
};

/**
 * This util will take a list of step fields and will return a new object with the step fields defined. This
 * is useful for defining step fields in a more readable way.
 *
 * @param fields - The list of step fields
 * @returns A new object with the step fields defined
 */
export const defineDetailsFields = <
  TKey extends string = any,
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
  TReturnValue = any,
>(fields: {
  [key in TKey]?: Partial<StepField<TData, TMetaData, TReturnValue>>;
}): StepFields<TData, TMetaData> => {
  const stepFields: StepFields<TData, TMetaData> = {};

  for (const [key, field] of Object.entries<Partial<StepField<TData, TMetaData, TReturnValue>> | undefined>(fields)) {
    if (field) {
      stepFields[key] = field;
    }
  }

  return stepFields;
};
