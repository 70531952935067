import type { ElementType } from 'react';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import type { DocumentNode } from 'graphql';
import styled, { css } from 'styled-components/macro';

import LoggingService from 'components/core/logging/LoggingService';
import ArchiveDialog, { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import type { CustomFilterInputType, CustomFilterType } from 'components/ui/filters/Filters';
import Filters from 'components/ui/filters/Filters';
import type FilterGroup from 'components/ui/filters/interfaces/filterGroup';
import type { DefaultFilters } from 'components/ui/filters/interfaces/filters';
import NoResultsIcon from 'components/ui/icons/NoResultsIcon';
import CondensedList from 'components/ui/lists/CondensedList';
import Loader, { PageLoader } from 'components/ui/loading/Loader';
import type { ItemSearchFiltersStatusType } from 'components/ui/menus/ItemsContainerSearchFilters';
import ItemsContainerSearchFilters from 'components/ui/menus/ItemsContainerSearchFilters';
import NoResultsPlaceholder from 'components/ui/placeholders/NoResultsPlaceholder';
import Placeholder from 'components/ui/placeholders/Placeholder';
import type ItemTab from 'components/ui/shared/interfaces/ItemTab';
import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import type { ItemsTableConfigProps } from 'components/ui/tables/ItemsTable';
import ItemsTable from 'components/ui/tables/ItemsTable';
import TableBulkActionMenuItems from 'components/ui/tables/TableBulkActionMenuItems';
import ArchiveConfigSettings from 'containers/ArchiveConfigSettings';
import type { BuilderType } from 'enums/builderType';
import type { ExtendedEntityType } from 'enums/extendedEntityType';
import { ItemViewType } from 'enums/ItemViewType';
import { ListItemType } from 'enums/listItemType';
import { RoutePath } from 'enums/routePath';
import type { ScrollableType } from 'enums/scrollableType';
import { ElementTestId } from 'enums/testing';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { useSearch } from 'hooks/contexts/useSearch';
import { useUser } from 'hooks/contexts/useUser';
import { useActiveFiltersCount } from 'hooks/useActiveFiltersCount';
import useEntityView from 'hooks/useEntityView';
import useIsLoaded from 'hooks/useIsLoaded';
import { useMountEffect } from 'hooks/useMountEffect';
import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import { useRouter } from 'hooks/useRouter';
import { getApiErrorsMessage } from 'store/api/graph/interfaces/apiErrors';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { CARD_WIDTH, GLOBAL_NAV_WIDTH } from 'styles/layouts';
import { NEUTRAL_100 } from 'styles/tokens';
import { Z_INDEX_4 } from 'styles/z-index';
import { impersonationManager } from 'utils/impersonationUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';
import { getInitialFacetFilterToggledState, saveInitialFacetFilterToggledState } from 'utils/persistanceUtils';
import { deferredRenderCall } from 'utils/renderingUtils';
import { userIdStorage } from 'utils/storage/auth';
import { storageManager, StorageType } from 'utils/storageUtils';
import { getTableColumnParams, getUrlDeeplinkParams } from 'utils/urlUtils';

import DefaultListSettings from './DefaultListSettings';
import type { ItemDetailsConfigProps } from './ItemDetailsContainer';
import ItemDetailsContainer from './ItemDetailsContainer';
import NestedViewContainer from './nestedView/NestedViewContainer';

export const Container = styled.div`
  position: relative;
  background: ${NEUTRAL_100};
  display: flex;
  flex-direction: row;
  height: 100%;

  > :first-child {
    margin-bottom: 1px;
  }
`;

const CondensedListTableContainer = styled.div<{ viewMode?: ItemViewType }>`
  overflow-y: auto;
  display: flex;

  ${({ viewMode }) =>
    viewMode === ItemViewType.SPLIT_VIEW &&
    css`
      max-width: ${CARD_WIDTH};
    `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex: 1;

  > * {
    flex-shrink: 0;
    margin-right: 6px;

    /** This targets the dynamically sized right-most content, which is either the loader, table, or details view */
    &:last-child {
      flex-grow: 1;
      margin-right: 0;
    }
  }

  > ${CondensedListTableContainer} {
    margin-right: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: ${CARD_WIDTH};
  max-width: calc(100vw - ${GLOBAL_NAV_WIDTH});

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export type CommonItemsConfigProps = {
  /** The base router path that this view exists in */
  basePath: string;
  /** The builder called when modifying a row */
  editBuilder: BuilderType;
  /** The type of entities being rendered by this table, also used to derive which entity to archive */
  entityType: ExtendedEntityType;
};

export type CondensedListConfigProps = {
  /** The base router path that this view exists in */
  basePath: RoutePath;
  /**
   * The rendering class that is used in the condensed list in details view,
   * if none is provided, renders without filters or list in details
   */
  condensedListItem?: ElementType;
  /** Custom component to override CondensedList */
  condensedListOverride?: ElementType;
  /** Overrides the scroll container type used to wrap this list. `ScrollableType.TRANSLATE` by default. */
  scrollableTypeOverride?: ScrollableType;
};

/*
 * The Items Container Config props are a combination of Details and Table specific configuration properties as
 * well as container specific properties
 */
export type ItemsContainerConfigProps = CommonItemsConfigProps &
  ItemDetailsConfigProps &
  CondensedListConfigProps &
  ItemsTableConfigProps & {
    /** The title of the container section */
    title: string;
    /** Callback to signal load has completed. See RooftopConfigurationContainer */
    onLoaded?: (isLoaded: boolean) => void;
    /** The data connection to be used when retrieving items */
    useConnectionQuery: (variables?: Record<string, unknown>, options?: any) => CustomQueryResult;
    /**
     * The optional data connection for details to be used when retrieving data on a specific item.
     * Defaults to `useQuery`
     */
    useDetailsQuery?: (query: DocumentNode, config: QueryConfig) => CustomQueryResult;
    /** The in-depth details specific query that populates the item in details view, used by tabs */
    detailsQuery: DocumentNode;
    /** The tab sections displayed in the details view  */
    detailsTabs: ItemTab[];
    /** The Entity specific menu items being rendered, supports both details and list views */
    menuItems: ElementType;
    /** A list of default filters that are disabled */
    disabledFilters?: DefaultFilters[];
    /** Overridden server search components used to render in the filters on the left most panel */
    overrideItemsFilterSection?: (filter: FilterGroup) => void;
    /** Custom filter sections to render */
    customFilterSections?: (customFilterinput: CustomFilterInputType) => CustomFilterType[];
    /** Custom banner to render above details tabs */
    customBanner?: (item?: any) => JSX.Element | undefined;
  };

const ItemsContainer = (props: ItemsContainerConfigProps) => {
  const {
    onLoaded = () => {},
    useConnectionQuery,
    useDetailsQuery = useQuery,
    condensedListItem: CondensedListItem,
    condensedListOverride,
    detailsQuery,
    detailsTabs,
    basePath,
    overrideItemsFilterSection = () => {},
    customFilterSections,
    disabledFilters = [],
    menuItems: MenuItems,
    title = '',
    entityType,
    scrollableTypeOverride,
    tableType,
    customBanner,
  } = useMemo(() => props, [props]);
  const { builderConfig } = useBuilderConfig();
  const { t } = translate;
  const { itemId, viewMode } = useEntityView();
  const { viewItems, close, setView } = useNestedView();
  const router = useRouter();

  const updateArchiveStatusConfig = ArchiveConfigSettings[entityType];
  const currentFacetToggleState = getInitialFacetFilterToggledState({ forPathname: router.sectionPath });

  // Common contexts & accessors
  const { searchParams, isPristine, updateSearchParam, setLocalSearchParams, updatePaginationParams, updateSortOrder } =
    useSearch();
  const { hasPermissions, user } = useUser();
  const activeFiltersCount = useActiveFiltersCount();

  const urlColumns = getTableColumnParams(window.location.search);

  const isColumnParamEnabled = urlColumns.length > 0;

  // Queries
  const detailsVariables = useMemo(() => ({ variables: { id: itemId }, ignore: !itemId }), [itemId]);
  const { isLoading, isLoaded, data = {}, refetch, error, queryString, queryVars } = useConnectionQuery(searchParams);
  const {
    isLoaded: isDetailsLoaded,
    isLoading: isDetailsLoading,
    error: detailsError,
    data: detailsData,
  } = useDetailsQuery(detailsQuery, detailsVariables);

  // Internal state management
  const [filtersOpenStatus, setFiltersOpenStatus] = useState<ItemSearchFiltersStatusType>({
    isOpen: currentFacetToggleState,
  });
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(isColumnParamEnabled);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDetailsUpdating, setIsDetailsUpdating] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);

  // ItemsTable state management
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [columnHeaders, setColumnHeaders] = useState<TableCellData[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // Item data
  const { connection = { edges: [], filters: [], pageInfo: {}, columns: [] }, metadata } = useMemo(() => data, [data]);
  const { edges, filters, pageInfo, columns } = useMemo(() => connection, [connection]);
  const items = useMemo(() => edges.map(edge => edge.node || edge), [edges]);
  const firstItemId = useMemo(() => items[0]?.id, [items]);

  // Permissions
  const hasEditPermission = hasPermissions(builderConfig[props.editBuilder].requiredPermissions);
  const isEditAllowed = hasEditPermission && !isItemArchived(detailsData?.item);

  // Derived
  const supportedTableExportColumns = useMemo(
    () => columns.filter(({ exportable }) => !!exportable).map(({ id }) => id),
    [columns]
  );
  const enabledTableColumnIds = useMemo(
    () =>
      columnHeaders
        .filter(header => header.enabled)
        .map(header => {
          if (header.columnIdOverride) {
            return header.columnIdOverride;
          }

          return header.columnId;
        })
        .filter(id => supportedTableExportColumns.includes(id)),
    [columnHeaders, supportedTableExportColumns]
  );

  useMountEffect(() => {
    const { nestedView } = getUrlDeeplinkParams(window.location.search);

    // If there are available deeplink props for the nested view, open up the nested view
    try {
      if (nestedView?.entity && nestedView?.type) {
        setView({
          entityType: nestedView.entity,
          title: '',
          viewType: nestedView.type,
          queryVars: nestedView?.id ? { id: nestedView.id } : {},
          replaceViewItems: false,
          data: JSON.parse(nestedView.data || {}),
        });
      }
    } catch (error_) {
      LoggingService.warn({
        message: 'Issue parsing nested view url deeplink params',
        messageContext: error_ as Error,
      });
    }
  });

  /**
   * Checks and updates the `isOpen` state for the filters
   */
  useEffect(() => {
    setFiltersOpenStatus({ isOpen: currentFacetToggleState });
  }, [currentFacetToggleState]);

  useEffect(() => {
    if (viewMode === ItemViewType.SPLIT_VIEW && !itemId && firstItemId) {
      router.replace(`${basePath}/${firstItemId}${window.location.search}`);
    }
  }, [basePath, firstItemId, itemId, router, viewMode, urlColumns]);

  useLayoutEffect(() => {
    if (itemId) {
      deferredRenderCall(() => {
        setIsTableVisible(false);
        setIsEditing(false);
      });
    } else {
      deferredRenderCall(() => setIsTableVisible(true));
    }
  }, [itemId]);

  // Closing secondary view every time `itemId` changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(close, [itemId]);

  // Full page loading state
  useIsLoaded(itemId ? isLoaded && isDetailsLoaded : isLoaded && isTableVisible, onLoaded);

  const onParamsChange = useCallback(() => {
    setLocalSearchParams(searchParams => ({ ...searchParams }));
  }, [setLocalSearchParams]);

  const onSetFiltersOpen = useCallback(
    isOpen => {
      setFiltersOpenStatus({ isOpen });
      saveInitialFacetFilterToggledState({ forPathname: router.sectionPath, isToggled: isOpen });
    },
    [router.sectionPath]
  );

  // Callback functions for ItemsTable
  const onSelectionChange = useCallback(
    ids => {
      setSelectedIds([...ids]);
    },
    [setSelectedIds]
  );

  const onColumnChange = useCallback(
    columnHeaders => {
      setColumnHeaders([...columnHeaders]);
    },
    [setColumnHeaders]
  );

  const onProcessing = useCallback(
    isProcessing => {
      setIsProcessing(isProcessing);
    },
    [setIsProcessing]
  );

  const onAllSelected = useCallback(
    allSelected => {
      setIsAllSelected(allSelected);
    },
    [setIsAllSelected]
  );

  // Asynchronous edit mode call to show loader while React updates its render
  const toggleEditMode = useCallback(async () => {
    setIsUpdating(true);
    await new Promise(resolve => {
      deferredRenderCall(() => {
        setIsEditing(!isEditing);
        resolve(undefined);
      });
    });
    setIsUpdating(false);
  }, [isEditing]);

  // Passed methods
  const redirectToBasePath = useCallback(() => router.push(RoutePath.HOME), [router]);
  const refetchAllResults = useCallback(() => refetch(null, null), [refetch]);
  const getItemUrl = useCallback(id => `${basePath}/${id}${window.location.search}`, [basePath]);
  const updateArchiveStatus = useCallback(
    () => setIsArchiveDialogOpen(!isArchiveDialogOpen),
    [setIsArchiveDialogOpen, isArchiveDialogOpen]
  );
  const updateArchiveStatusCallback = authorizedCallback({
    cb: updateArchiveStatus,
    isAuth: updateArchiveStatusConfig?.canUpdateArchiveStatus(hasEditPermission, user, detailsData?.item),
  });

  /**
   * Full Page Placeholders
   *
   * TODO [ED-3631]: Refactor so that placeholder logic is per layout view.
   *                 Currently, table view has it's own.
   */
  if (!isLoading && items.length === 0 && viewMode !== ItemViewType.TABLE_VIEW && !itemId) {
    return !!activeFiltersCount || searchParams.keyword ? (
      <NoResultsPlaceholder />
    ) : (
      <Placeholder
        buttonText={t('refresh')}
        css="height: 100%;"
        icon={<NoResultsIcon />}
        onClick={refetchAllResults}
        subtitle={t('refresh_message')}
        title={t('no_results')}
      />
    );
  }

  // If there was an API error fetching items, display error placeholder
  if (error || detailsError) {
    return (
      <Placeholder
        buttonText={t('refresh')}
        css="height: 100%;"
        icon={<NoResultsIcon />}
        onClick={() => {
          // Reset filters to default
          storageManager.clearStorage({
            ofType: StorageType.LOCAL,
            forUserId: impersonationManager.getImpersonatedUserId() || userIdStorage.get(),
            sectionPath: router.sectionPath,
          });
          setLocalSearchParams(DefaultListSettings[router.sectionPath]?.filter);

          if (detailsError) {
            redirectToBasePath?.();
          } else {
            refetchAllResults?.();
          }

          return;
        }}
        subtitle={t('error_contact_us')}
        title={getApiErrorsMessage(error || detailsError)}
      />
    );
  }

  return (
    <Container data-testid={ElementTestId.ITEMS_CONTAINER}>
      {viewMode !== ItemViewType.TABLE_DETAILS_VIEW && (
        <CardContainer
          css={
            viewMode === ItemViewType.TABLE_VIEW || (!isDetailsLoading && !itemId)
              ? css`
                  flex: 1;
                `
              : css`
                  flex-shrink: 0;
                `
          }
          data-testid={ElementTestId.ITEMS_CONTAINER_FILTERS}
        >
          <ItemsContainerSearchFilters
            activeFiltersCount={activeFiltersCount}
            filtersOpenStatus={filtersOpenStatus}
            hasSelections={selectedIds.length === 0}
            isEditAllowed={hasEditPermission}
            isUpdating={isUpdating}
            menuItems={
              <TableBulkActionMenuItems
                columnIds={enabledTableColumnIds}
                disabled={false}
                ids={selectedIds}
                isAllSelected={isAllSelected}
                isEditAllowed={hasEditPermission}
                onProcessing={onProcessing}
                onSelectionChange={onSelectionChange}
                query={queryString}
                tableType={tableType}
                variables={queryVars}
              />
            }
            onToggleEditMode={toggleEditMode}
            searchParams={searchParams}
            setHasFiltersOpen={onSetFiltersOpen}
            title={title}
            updateSearchParam={updateSearchParam}
            viewMode={viewMode}
          />
          <ContentContainer>
            {filtersOpenStatus.isOpen && (viewMode === ItemViewType.TABLE_VIEW || !!CondensedListItem) && (
              <Filters
                columns={columns || []}
                customFilterSections={customFilterSections}
                disabledFilters={disabledFilters}
                filters={filters || []}
                isPristine={isPristine()}
                overrideSection={overrideItemsFilterSection}
                searchParams={searchParams}
                totalEdges={pageInfo?.totalEdges || items?.length || 0}
              />
            )}
            <CondensedListTableContainer viewMode={viewMode}>
              {viewMode === ItemViewType.SPLIT_VIEW ? (
                <CondensedList
                  condensedListOverride={condensedListOverride}
                  getItemUrl={getItemUrl}
                  isLoading={isLoading}
                  items={items}
                  pageInfo={pageInfo}
                  renderElement={CondensedListItem}
                  renderSettings={{ metadata, listItemType: ListItemType.CONDENSED_LIST }}
                  requestPagination={updatePaginationParams}
                  scrollableTypeOverride={scrollableTypeOverride}
                  selectedItemId={itemId}
                />
              ) : isTableVisible ? (
                <ItemsTable
                  {...props}
                  data={items}
                  exportQueryString={queryString}
                  exportQueryVars={queryVars}
                  hasPermissions={hasPermissions}
                  isEditAllowed={hasEditPermission}
                  isEditing={isEditing}
                  isLoading={isLoading || isUpdating || isProcessing}
                  metadata={metadata}
                  onColumnChange={onColumnChange}
                  onFiltersChange={onParamsChange}
                  onSelectedAll={onAllSelected}
                  onSelectionChange={onSelectionChange}
                  pageInfo={pageInfo}
                  searchParams={searchParams}
                  selectedIds={selectedIds}
                  sortColumns={columns}
                  updateSortOrder={updateSortOrder}
                />
              ) : null}
              {viewMode === ItemViewType.TABLE_VIEW && !isTableVisible && <Loader css="position:relative" />}
            </CondensedListTableContainer>
          </ContentContainer>
        </CardContainer>
      )}
      {viewMode !== ItemViewType.TABLE_VIEW && !isTableVisible && !!itemId ? (
        <>
          <CardContainer css="flex: 1;">
            <ItemDetailsContainer
              {...props}
              customBanner={customBanner}
              isEditAllowed={isEditAllowed}
              isUpdating={isDetailsUpdating || isDetailsLoading}
              itemData={detailsData}
              itemId={itemId}
              menuItems={
                <MenuItems
                  isUpdating={isUpdating}
                  item={detailsData.item}
                  onToggleEditMode={toggleEditMode}
                  onUpdateArchiveStatus={updateArchiveStatusCallback}
                  setIsDetailsUpdating={setIsDetailsUpdating}
                  type={viewMode}
                />
              }
              onUpdateArchiveStatus={updateArchiveStatusCallback}
              preLoadedItem={items.find(item => item?.id === itemId)}
              setIsDetailsUpdating={setIsDetailsUpdating}
              shouldDisplayBackButton={viewMode === ItemViewType.TABLE_DETAILS_VIEW}
              tabs={detailsTabs}
            />
          </CardContainer>
          {viewItems.length > 0 && (
            <CardContainer
              css={css`
                width: ${viewItems[0].width};
              `}
            >
              {/**
               * Manually set key based on dependencies, so react automatically mounts/unmounts
               * the component such that rules of react are followed.
               */}
              <NestedViewContainer key={`NestedViewContainer-${viewItems[0].entityType}-${viewItems[0].type}`} />
            </CardContainer>
          )}
        </>
      ) : (
        viewMode === ItemViewType.SPLIT_VIEW && !!itemId && <Loader css="position:relative" />
      )}
      {(!data.connection || (viewMode === ItemViewType.SPLIT_VIEW && !isDetailsLoaded)) && (
        <PageLoader zIndex={Z_INDEX_4} />
      )}

      {!!updateArchiveStatusConfig && (
        <ArchiveDialog
          archiveParameter={updateArchiveStatusConfig.overrideArchiveParameter}
          customMessage={updateArchiveStatusConfig.customMessage}
          entityTypeLabel={translate.t(updateArchiveStatusConfig.entityTypeLabel)}
          isDialogOpen={isArchiveDialogOpen}
          item={detailsData.item}
          mutation={updateArchiveStatusConfig.mutation}
          refetchQueryNames={updateArchiveStatusConfig.refetchQueries}
          setIsDialogOpen={setIsArchiveDialogOpen}
        />
      )}
    </Container>
  );
};

export default ItemsContainer;
