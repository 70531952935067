import styled from 'styled-components/macro';

import { PrimaryLabel } from 'components/core/typography/Label';
import ArrowTopToBottomIcon from 'components/ui/icons/ArrowTopToBottomIcon';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import type { AppointmentResponseType } from 'store/api/graph/responses/responseTypes';
import { BLUE_500 } from 'styles/tokens';
import { getFormattedDateTimeString } from 'utils/dateUtils';

const { TIME_STAMP_FORMAT } = DateTimeFormat;

const Container = styled.div`
  display: flex;
`;

const ContainerInner = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  color: ${BLUE_500};
  min-width: 7ch;
`;

type Props = Pick<AppointmentResponseType, 'dateFrom' | 'dateTo'>;

const AppointmentTimeBlock = ({ dateFrom, dateTo }: Props) => (
  <Container>
    <ContainerInner>
      <PrimaryLabel>{getFormattedDateTimeString(dateFrom, TIME_STAMP_FORMAT)}</PrimaryLabel>
      <ArrowTopToBottomIcon css="margin: 5px 0" height={16} width={16} />
      <PrimaryLabel>{getFormattedDateTimeString(dateTo, TIME_STAMP_FORMAT)}</PrimaryLabel>
    </ContainerInner>
  </Container>
);

export default AppointmentTimeBlock;
