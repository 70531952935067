import { memo, type SVGProps } from 'react';

const ListBulletedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="currentColor" height={24} viewBox="0 0  24 24" width={24} {...props}>
    <path
      d="M10.179 19.137c-.32 0-.588-.11-.808-.329a1.1 1.1 0 0 1-.33-.808c0-.32.11-.589.33-.808.22-.22.489-.33.808-.33h9.886c.32 0 .59.11.809.33.22.22.329.489.329.808 0 .32-.11.589-.33.808a1.1 1.1 0 0 1-.808.33H10.18Zm0-6c-.32 0-.588-.11-.808-.329a1.1 1.1 0 0 1-.33-.808c0-.32.11-.589.33-.808.22-.22.489-.33.808-.33h9.886c.32 0 .59.11.809.33.22.22.329.489.329.808 0 .32-.11.589-.33.808a1.1 1.1 0 0 1-.808.33H10.18Zm0-6c-.32 0-.588-.11-.808-.329-.22-.22-.33-.489-.33-.808 0-.32.11-.589.33-.808.22-.22.489-.33.808-.33h9.886c.32 0 .59.11.809.33.22.22.329.489.329.808 0 .32-.11.589-.33.808a1.1 1.1 0 0 1-.808.33H10.18ZM4.915 20.127a2.043 2.043 0 0 1-1.501-.625 2.05 2.05 0 0 1-.623-1.503c0-.584.208-1.085.624-1.5a2.05 2.05 0 0 1 1.503-.624c.584 0 1.085.209 1.5.625.416.417.624.918.624 1.503 0 .584-.209 1.085-.625 1.5a2.05 2.05 0 0 1-1.502.623Zm0-6a2.043 2.043 0 0 1-1.501-.625 2.05 2.05 0 0 1-.623-1.502c0-.585.208-1.086.624-1.501a2.05 2.05 0 0 1 1.503-.623c.584 0 1.085.208 1.5.624.416.417.624.917.624 1.502s-.209 1.086-.625 1.501a2.05 2.05 0 0 1-1.502.623Zm0-6A2.043 2.043 0 0 1 3.414 7.5a2.05 2.05 0 0 1-.623-1.502c0-.585.208-1.086.624-1.501a2.05 2.05 0 0 1 1.503-.623c.584 0 1.085.208 1.5.624.416.417.624.918.624 1.503 0 .584-.209 1.085-.625 1.5a2.05 2.05 0 0 1-1.502.624Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(ListBulletedIcon);
