import type { ReactNode, UIEvent } from 'react';
import { Component } from 'react';

import { clamp, debounce, isEqual } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import { WrapLink } from 'components/core/navigation/shared/WrapLink';
import Loader from 'components/ui/loading/Loader';
import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import type TableData from 'components/ui/tables/interfaces/tableData';
import { ElementTestId } from 'enums/testing';
import { OVERLAY_DARKEST } from 'styles/blanket';
import { DIVIDER } from 'styles/color';
import { LIST_ITEM_HEIGHT } from 'styles/layouts';
import { NEUTRAL_0, NEUTRAL_050, NEUTRAL_900, RED_100 } from 'styles/tokens';
import { Z_INDEX_2 } from 'styles/z-index';
import { disableScrollEventCapturing, enableScrollEventCapturing } from 'utils/domUtils';
import { hexToRGBA } from 'utils/styledUtils';
import { setPhysicalCellWidth } from 'utils/tableUtils';

import { isItemArchived } from '../dialogs/ArchiveDialog';

import TableCell from './TableCell';
import TableHeaderRow from './TableHeaderRow';
import TableScrollbar from './TableScrollbar';

const TableContainer = styled.div`
  position: relative;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${NEUTRAL_0};
`;

const FooterContainer = styled.div`
  flex-shrink: 0;
  height: 75px;
`;

const TableRowBorder = css`
  &:not(:first-child) {
    border-top: 1px solid ${DIVIDER};
  }
`;

const HorizontallyScrollableArea = styled.div`
  max-height: calc(100% - 75px); /* subtract FooterContainer height */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
`;

type RowContainerType = { isActive?: boolean; isEditing?: boolean; isArchived?: boolean };
const TableHeader = styled.div.attrs<RowContainerType>(props => ({
  style: { ...props },
}))<RowContainerType>`
  background: ${({ isEditing }) => (isEditing ? NEUTRAL_0 : NEUTRAL_0)};
  display: inline-flex;
  border-bottom: 1px solid ${DIVIDER};

  > * {
    flex-shrink: 0;
  }

  flex-shrink: 0;
  min-width: 100%;
`;

const DisabledOverlay = styled.div`
  height: 100%;
  width: 100%;
  background: ${OVERLAY_DARKEST};
  position: absolute;
  left: 0;
  top: 0;
  z-index: ${Z_INDEX_2};
`;

const TableContentContainer = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 100%;
`;

const TableContent = styled.div.attrs<RowContainerType>(props => ({
  style: { ...props },
}))<RowContainerType>`
  overflow: visible;
  background: ${NEUTRAL_0};
  display: inline-flex;
  flex-direction: column;
  position: relative;
  transform: translateZ(0);
  min-width: 100%;

  > ${WrapLink} {
    ${TableRowBorder}
    height: ${LIST_ITEM_HEIGHT};
  }
`;

const TableRowContainer = styled.div.attrs<RowContainerType>(props => ({
  style: { ...props },
}))<RowContainerType>`
  display: flex;
  cursor: pointer;
  background: ${({ isArchived }) => (isArchived ? RED_100 : NEUTRAL_0)};
  height: ${LIST_ITEM_HEIGHT};

  ${TableRowBorder}
  ${({ isEditing, isArchived }) =>
    isEditing &&
    css`
      cursor: default;
      background: ${isArchived ? RED_100 : NEUTRAL_0};
    `}
  > * {
    flex-shrink: 0;
  }
`;

const TableHeaderContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
`;

const FixedColumns = styled.div<{ isScrolled: boolean }>`
  top: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  min-height: 100%;
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0) 1px 0 10px;
  ${({ isScrolled }) =>
    isScrolled &&
    css`
      box-shadow: ${hexToRGBA(NEUTRAL_900, '0.21')} 1px 0 15px;
    `}
`;

/**
 * A minimally styled table component
 */
class Table extends Component<
  {
    isLoading: boolean;
    /**
     * Object used to populate header and content cells
     */
    tableData: TableData;
    isEditing?: boolean;
    disabled?: boolean;
    /**
     * Callback method for when a cell in a row gets resized, passes back the row and cell in question
     */
    onRowResize?: (rowData: TableCellData[], cellData: TableCellData) => void;
    onRowReorder?: (rowData: TableCellData[], cellData: TableCellData) => void;
    onScroll?: (e: UIEvent<HTMLElement>) => void;
    /**
     * Shared styles that are applied to all content cells
     */
    contentCellStyles?: string | string[];
    /**
     * Shared styles that are applied to all header cells
     */
    headerCellStyles?: string | string[];
    footer?: ReactNode;
  },
  {
    parsedTableData?: { fixedTableContent: TableData; tableContent: TableData };

    isScrolled: boolean;
    tableRows: HTMLDivElement[];
    fixedRows: HTMLDivElement[];
    scrollHeight: number;
    scrollWidth: number;
    contentWidth: number;
    fixedColumnsOffset: number;
  }
> {
  tableContentRef?: HTMLDivElement;
  tableContentScrollYRef?: HTMLDivElement;
  tableContentScrollXRef?: HTMLDivElement;
  tableHeaderRef?: HTMLDivElement;
  fixedScrollContentRef?: HTMLDivElement;
  horizontalScrollAreaRef?: HTMLDivElement;
  currentScroll: { x: number; y: number };

  constructor(props) {
    super(props);

    this.state = {
      parsedTableData: undefined,
      isScrolled: false,
      tableRows: [],
      fixedRows: [],
      scrollHeight: 0,
      scrollWidth: 0,
      contentWidth: 0,
      fixedColumnsOffset: 0,
    };

    this.currentScroll = { x: 0, y: 0 };
  }

  componentDidMount() {
    const { tableData } = this.props;
    const parsedTableData = this.getTableData(tableData);
    const fixedColumnsOffset = parsedTableData.fixedTableContent.headerData
      .map(item => item.width as number) // Note: Fixed columns are always assumed to be numbers
      .reduce((acc, item) => (acc += item), 0);

    this.setState(
      {
        parsedTableData,
        fixedColumnsOffset,
      },
      // Attach event listener after ref becomes available when parsedTableData state is set
      () => enableScrollEventCapturing(this.tableContentRef)
    );

    this.debouncedOnWidthResize();
    window.addEventListener('resize', this.debouncedOnWidthResize);
  }

  onWidthResize = () => {
    if (this.horizontalScrollAreaRef) {
      this.setState({
        contentWidth: this.horizontalScrollAreaRef.offsetWidth - this.state.fixedColumnsOffset,
      });
    } else {
      requestAnimationFrame(this.debouncedOnWidthResize);
    }
  };

  debouncedOnWidthResize = debounce(this.onWidthResize, 100);

  componentDidUpdate(prevProps) {
    const { tableData } = prevProps;
    const { scrollHeight, scrollWidth } = this.state;

    if (!isEqual(tableData, this.props.tableData)) {
      const parsedTableData = this.getTableData(this.props.tableData);
      this.setState({
        parsedTableData,
      });
    }

    if (
      this.tableContentRef &&
      (scrollHeight !== this.tableContentRef.scrollHeight || scrollWidth !== this.tableContentRef.scrollWidth)
    ) {
      this.setState({
        scrollHeight: this.tableContentRef.scrollHeight,
        scrollWidth: this.tableContentRef.scrollWidth,
      });
    }
  }

  componentWillUnmount() {
    disableScrollEventCapturing(this.tableContentRef);
    window.removeEventListener('resize', this.debouncedOnWidthResize);
  }

  scrollTableContentToTop = (resetBothAxis = false) => {
    if (this.tableContentRef && this.fixedScrollContentRef) {
      this.setState({ isScrolled: false }, () => {
        const scrollX = resetBothAxis ? 0 : this.currentScroll.x;
        this.setState({ isScrolled: !!scrollX }, () => {
          this.updateScroll(scrollX, 0);
        });
      });
    }
  };

  updateScroll = (x: number, y: number) => {
    const scrollbarSize = this.tableContentScrollYRef!.offsetHeight - this.tableContentScrollYRef!.clientHeight;
    const offsetY = scrollbarSize + 45; // Subtracting 45px for header

    this.currentScroll.x = clamp(
      x,
      -(this.tableContentRef!.scrollWidth - this.horizontalScrollAreaRef!.offsetWidth) - scrollbarSize,
      0
    );
    this.currentScroll.y = clamp(
      y,
      this.tableContentRef!.scrollHeight + offsetY > this.horizontalScrollAreaRef!.offsetHeight
        ? -(this.tableContentRef!.scrollHeight + offsetY - this.horizontalScrollAreaRef!.offsetHeight)
        : 0,
      0
    );

    this.tableContentScrollYRef!.scrollTop = -y;
    this.tableContentScrollXRef!.scrollLeft = -x;

    this.fixedScrollContentRef!.style.transform = `translateY(${this.currentScroll.y}px)`;
    this.tableContentRef!.style.transform = `translateY(${this.currentScroll.y}px) translateX(${this.currentScroll.x}px)`;
    this.tableHeaderRef!.style.transform = `translateX(${this.currentScroll.x}px)`;
  };

  onRowResizeCallback = (rowData: TableCellData[], cellData: TableCellData) => {
    const { onRowResize } = this.props;
    if (onRowResize) {
      onRowResize(rowData, cellData);
    }
    this.tableContentRef!.style.userSelect = this.tableHeaderRef!.style.userSelect = 'initial';
  };

  debouncedOnRowResizeCallback = debounce(this.onRowResizeCallback, 200);

  onHeaderResize = (rowData: TableCellData[], cellData: TableCellData) => {
    const { tableRows } = this.state;

    setPhysicalCellWidth(this.tableHeaderRef!, cellData);
    for (const item of tableRows.filter(Boolean)) {
      setPhysicalCellWidth(item, cellData);
    }

    this.tableContentRef!.style.userSelect = this.tableHeaderRef!.style.userSelect = 'none';

    // TODO: Only call this on resize end (or mouseup);
    this.debouncedOnRowResizeCallback(rowData, cellData);
  };

  toggleActiveRow = (e, rowData: TableCellData[]) => {
    const { tableRows, fixedRows } = this.state;
    const { isEditing } = this.props;
    const isArchived = isItemArchived(rowData[0]?.itemData);
    const isEditingAllowed = !isArchived && isEditing;
    const attribute = 'data-rowid';
    const targets = [...tableRows, ...fixedRows].filter(
      item => item.getAttribute(attribute) === e.currentTarget.getAttribute(attribute)
    );
    const defaultRowBackgroundColor = isArchived ? RED_100 : NEUTRAL_0;
    const color = e.type === 'mouseover' ? NEUTRAL_050 : isEditingAllowed ? NEUTRAL_0 : defaultRowBackgroundColor;
    for (const item of targets) {
      item.style.background = color;
    }
  };

  onTableScroll = e => {
    const { isScrolled } = this.state;
    const { onScroll = () => {}, disabled } = this.props;
    if (disabled) {
      return;
    }

    let scrollX: number;
    let scrollY: number;

    if ([this.tableContentScrollXRef, this.tableContentScrollYRef].includes(e.currentTarget)) {
      scrollX = -this.tableContentScrollXRef!.scrollLeft;
      scrollY = -this.tableContentScrollYRef!.scrollTop;
    } else {
      scrollY = this.currentScroll.y - e.deltaY;
      scrollX = this.currentScroll.x - e.deltaX;
    }
    this.updateScroll(scrollX, scrollY);

    if (this.currentScroll.x && !isScrolled) {
      this.setState({ isScrolled: true });
    } else if (!this.currentScroll.x && isScrolled) {
      this.setState({ isScrolled: false });
    }

    onScroll(e);
  };

  generateTableContent = (
    rowsData: TableCellData[][],
    contentCellStyles: string | string[] = '',
    tableRows?: HTMLDivElement[]
  ) => {
    const { isEditing } = this.props;

    if (tableRows) {
      tableRows.splice(0, tableRows.length);
    }

    return rowsData.map((rowData: TableCellData[]) => {
      const rowKey = `row-container-${rowData[0]?.rowId}`;
      const isArchived = isItemArchived(rowData[0]?.itemData);

      const content = !!rowData[0]?.rowId && (
        <TableRowContainer
          isArchived={isArchived}
          isEditing={isEditing}
          key={rowKey}
          onMouseOut={e => this.toggleActiveRow(e, rowData)}
          onMouseOver={e => this.toggleActiveRow(e, rowData)}
          ref={el => {
            if (tableRows && el) {
              tableRows.push(el);
              el.dataset.rowid = rowData[0].rowId;
            }
          }}
        >
          {rowData
            .filter(cell => cell.enabled)
            .map((cellData: TableCellData) => (
              <TableCell
                cellData={cellData}
                cellStyles={contentCellStyles}
                contentWidth={this.state.contentWidth}
                isEditing={isEditing}
                key={`${cellData.rowId}-${cellData.columnId}`}
              />
            ))}
        </TableRowContainer>
      );

      return isEditing || !rowData[0]?.link ? (
        content
      ) : (
        <WrapLink data-id={rowData[0]?.rowId} key={rowKey} to={rowData[0].link}>
          {content}
        </WrapLink>
      );
    });
  };

  getTableData(tableData: TableData): { fixedTableContent: TableData; tableContent: TableData } {
    return {
      tableContent: {
        headerData: tableData.headerData.map(item => (item.isFixedColumn ? { ...item, render: () => null } : item)),
        data: tableData.data.map(row =>
          row.map(cell => (cell.isFixedColumn ? { ...cell, content: null, render: undefined } : cell))
        ),
      },
      fixedTableContent: {
        headerData: tableData.headerData.filter(item => item.isFixedColumn),
        data: tableData.data.map(row => row.filter(item => item.isFixedColumn)),
      },
    };
  }

  render() {
    const { isLoading, isEditing, disabled, footer, onRowReorder, contentCellStyles, headerCellStyles } = this.props;
    const { parsedTableData, isScrolled, tableRows, fixedRows, scrollHeight, scrollWidth, contentWidth } = this.state;
    return (
      <TableContainer data-testid={ElementTestId.TABLE_CONTAINER}>
        {disabled && <DisabledOverlay />}
        {parsedTableData ? (
          <HorizontallyScrollableArea
            onWheel={this.onTableScroll}
            ref={div => {
              this.horizontalScrollAreaRef = div!;
            }}
          >
            <TableHeaderContainer>
              <TableHeader
                data-testid={ElementTestId.TABLE_CONTAINER_HEADER}
                isEditing={isEditing}
                ref={div => {
                  this.tableHeaderRef = div!;
                }}
              >
                <TableHeaderRow
                  cellStyles={headerCellStyles}
                  contentWidth={contentWidth}
                  onRowReorder={onRowReorder}
                  onRowResize={this.onHeaderResize}
                  rowData={parsedTableData.tableContent.headerData}
                />
              </TableHeader>
            </TableHeaderContainer>
            <TableContentContainer>
              <TableContent
                data-testid={ElementTestId.TABLE_CONTENT}
                ref={div => {
                  this.tableContentRef = div!;
                }}
              >
                {this.generateTableContent(parsedTableData.tableContent.data, contentCellStyles, tableRows)}
              </TableContent>
            </TableContentContainer>
            <FixedColumns isScrolled={isScrolled}>
              <TableHeader isEditing={isEditing}>
                <TableHeaderRow
                  cellStyles={headerCellStyles}
                  onRowReorder={onRowReorder}
                  onRowResize={this.onHeaderResize}
                  rowData={parsedTableData.fixedTableContent.headerData}
                />
              </TableHeader>
              <TableContentContainer>
                <TableContent ref={div => (this.fixedScrollContentRef = div!)}>
                  {this.generateTableContent(parsedTableData.fixedTableContent.data, contentCellStyles, fixedRows)}
                </TableContent>
              </TableContentContainer>
            </FixedColumns>
            <TableScrollbar
              onScroll={this.onTableScroll}
              ref={div => {
                this.tableContentScrollYRef = div!;
              }}
              scrollHeight={scrollHeight}
            />
            <TableScrollbar
              onScroll={this.onTableScroll}
              ref={div => {
                this.tableContentScrollXRef = div!;
              }}
              scrollWidth={scrollWidth}
            />
          </HorizontallyScrollableArea>
        ) : (
          <div />
        )}
        {footer && <FooterContainer>{footer}</FooterContainer>}
        {isLoading && <Loader />}
      </TableContainer>
    );
  }
}

export default Table;
