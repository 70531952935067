import { InventoryItem } from 'enums/columns/inventoryItem';
import type { InventoryItemPhoto } from 'store/api/graph/interfaces/types';
import { InventoryItemPhotoType } from 'store/api/graph/interfaces/types';
import type { InventoryItemResponseType } from 'store/api/graph/responses/responseTypes';

interface ValidatedImageSizes {
  validFiles: File[];
  overSizedFiles: File[];
}

/**
 * Determines if each image's size is below the max size
 *
 * @param {File[]} images
 * @param {number} maxSize - Max file size in megabytes
 */
export const filterImagesByMaxSize = (images: File[] = [], maxSize = 5): ValidatedImageSizes => {
  const maxSizeKB = maxSize * 1024; // Size in KB
  const maxSizeBytes = maxSizeKB * 1024; // File size is returned in Bytes
  const validFiles: File[] = [];
  const overSizedFiles: File[] = [];

  for (const file of images) {
    if (file.size <= maxSizeBytes) {
      validFiles.push(file);
    } else {
      overSizedFiles.push(file);
    }
  }
  return { validFiles, overSizedFiles };
};

/**
 * Get photos of a particular type
 *
 * @param {InventoryItemPhoto[]} photos
 * @param {InventoryItemPhotoType} type - the type of photos to return
 */
export const getInventoryPhotosByType = (
  photos: InventoryItemPhoto[] = [],
  type: InventoryItemPhotoType
): InventoryItemPhoto[] => photos.filter((photo: InventoryItemPhoto) => photo.type === type);

/**
 * Given an upload File, get the image contents as a data url
 *
 * @param file - the image File
 */
export const getImageDataURLFromFile = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
    reader.addEventListener('error', e => reject(e));
    reader.addEventListener('loadend', () => resolve(reader.result));

    reader.readAsDataURL(file);
  });

/**
 * Check if stock photos is enabled
 */
export const isStockPhotosEnabled = ({
  inventoryItem,
  fieldName,
  photoType,
}: {
  /** The inventory item to check */
  inventoryItem: InventoryItemResponseType;
  /** The fieldName containing the stock photos */
  fieldName?: InventoryItem;
  /** The category of stock photos */
  photoType?: InventoryItemPhotoType;
}) => {
  const isEnabledForInventoryItemType = inventoryItem.__typename === 'RetailItem';
  let isEnabledForPhotoType = false;

  if (photoType) {
    isEnabledForPhotoType = [InventoryItemPhotoType.INTERIOR, InventoryItemPhotoType.EXTERIOR].includes(photoType);
  } else if (fieldName) {
    isEnabledForPhotoType = [InventoryItem.PHOTOS_INTERIOR, InventoryItem.PHOTOS_EXTERIOR].includes(fieldName);
  }

  return isEnabledForInventoryItemType && isEnabledForPhotoType;
};
