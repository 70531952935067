import { get, isNil } from 'lodash-es';
import styled from 'styled-components/macro';

import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';
import FalseIcon from 'components/ui/icons/FalseIcon';
import TrueIcon from 'components/ui/icons/TrueIcon';
import { InputToggleButton } from 'components/ui/shared/Button';
import { ElementTestId } from 'enums/testing';
import { BODY_TEXT } from 'styles/color';
import { BLUE_500, GREEN_500, NEUTRAL_0, NEUTRAL_050, RED_500 } from 'styles/tokens';
import { FONT_WEIGHT_NORMAL } from 'styles/typography';
import { convertEnumToString } from 'utils/stringUtils';

import type StepField from '../interfaces/stepField';

const BadgesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: hidden;
  margin-bottom: -7px;

  :empty {
    display: none;
  }
`;

const Badge = styled(InputToggleButton)<{ selected?: boolean }>`
  margin-right: 7px;
  margin-bottom: 7px;
  background: ${props => (props.selected ? BLUE_500 : NEUTRAL_050)};
  color: ${props => (props.selected ? NEUTRAL_0 : BODY_TEXT)};
  font-weight: ${FONT_WEIGHT_NORMAL};
  text-transform: uppercase;
  padding: 9px 10px 8px;
  border-radius: 4px;
`;

export interface BadgesStepFieldType {
  selectedValue?: SubStepOption[];
  options?: any;
  active?: boolean;
}

export const Flags = ({ options }: BadgesStepFieldType) => {
  const filteredOptions: StepField[] = options?.filter(
    flag => (!flag.clear && !flag.settings?.isClearable) || !isNil(flag.selectedValue)
  );

  return filteredOptions.length > 0 ? (
    <BadgesContainer>
      {filteredOptions.map(flag => (
        <Badge as="span" data-testid={`${ElementTestId.INPUT_FLAGS}-${flag.queryVar}`} key={flag.queryVar}>
          {flag.label}
          {flag.selectedValue ? (
            <TrueIcon
              color={GREEN_500}
              css="margin-left: 1ch;"
              data-testid={ElementTestId.ICON_TRUE}
              height={10}
              width={10}
            />
          ) : (
            <FalseIcon
              color={RED_500}
              css="margin-left: 1ch;"
              data-testid={ElementTestId.ICON_FALSE}
              height={10}
              width={10}
            />
          )}
        </Badge>
      ))}
    </BadgesContainer>
  ) : null;
};

export const Tags = ({ selectedValue: tags = [], active, options }: BadgesStepFieldType) => {
  const shouldRenderBadges = tags?.length && options?.length;

  return shouldRenderBadges ? (
    <BadgesContainer>
      {tags
        .map(tag => {
          // Option has metadata required for `Badge` component
          const matchedOption = options?.find(opt => (tag.id ? opt.id === tag.id : opt.id === tag));
          return matchedOption;
        })
        .filter(Boolean)
        .map(option => (
          <Badge as="span" key={get(option, 'id', option) as string} selected={active}>
            {convertEnumToString(get(option, 'name.value', option.name || option))}
          </Badge>
        ))}
    </BadgesContainer>
  ) : null;
};

// TODO: Potentially consolidate this with Tags with a no-filter option
export const Badges = ({ selectedValue: badges = [], active, options }: BadgesStepFieldType) =>
  badges?.length ? (
    <BadgesContainer>
      {badges.map(badges => (
        <Badge as="span" key={get(badges, 'id', badges) as string} selected={active}>
          {convertEnumToString(typeof badges.name === 'string' ? badges.name : badges.name?.value)}
        </Badge>
      ))}
    </BadgesContainer>
  ) : null;
