import { Navigate, Route } from 'react-router-dom';

import DynamicImport from 'components/core/DynamicImport';
import CoreLayout from 'components/ui/layouts/CoreLayout';
import ForgotPassword from 'containers/auth/ForgotPassword';
import Login from 'containers/auth/Login';
import ResetPassword from 'containers/auth/ResetPassword';
import { MobileRichTextEditorContainer } from 'containers/editor/MobileRichTextEditorContainer';
import WebsitesContainer from 'containers/websites/WebsitesContainer';
import { RoutePath, SubRoute } from 'enums/routePath';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';
import { nonWhiteLabelScopes } from 'utils/permissionUtils';

import CoreRoute from './CoreRoute';
import RestrictedRoute from './RestrictedRoute';
import SettingRoutes from './SettingsRoutes';

const RetailItemsContainer = DynamicImport(() => import('containers/inventoryItems/RetailItemsContainer'));
const TradeInItemsContainer = DynamicImport(() => import('containers/inventoryItems/TradeInItemsContainer'));
const RooftopsContainer = DynamicImport(() => import('containers/rooftops/RooftopsContainer'));
const Error404 = DynamicImport(() => import('components/core/errors/Error404'));
const LeadsContainer = DynamicImport(() => import('containers/leads/LeadsContainer'));
const UsersContainer = DynamicImport(() => import('containers/users/UsersContainer'));
const TasksContainer = DynamicImport(() => import('containers/tasks/TasksContainer'));
const ConversationsContainer = DynamicImport(() => import('containers/conversations/ConversationsContainer'));
const LeadActivitiesContainer = DynamicImport(() => import('containers/leadActivities/LeadActivitiesContainer'));
const AppointmentsContainer = DynamicImport(() => import('containers/appointments/AppointmentsContainer'));
const MyAccountContainer = DynamicImport(() => import('containers/myAccount/MyAccountContainer'));
const DashboardController = DynamicImport(() => import('containers/dashboard/DashboardController'));

/**
 * Rationale for Route composition:
 * https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
 */
export default (
  <Route element={<CoreRoute />}>
    <Route
      element={<Navigate replace to={RoutePath.DASHBOARD} />}
      handle={{ pathPattern: RoutePath.HOME }}
      path={RoutePath.HOME}
    />

    <Route
      element={<MobileRichTextEditorContainer />}
      handle={{ pathPattern: RoutePath.MOBILE_RICH_TEXT_EDITOR }}
      path={RoutePath.MOBILE_RICH_TEXT_EDITOR}
    />

    {/* Authentication */}
    <Route element={<Login />} handle={{ pathPattern: RoutePath.LOGIN }} path={RoutePath.LOGIN} />
    <Route
      element={<ForgotPassword />}
      handle={{ pathPattern: RoutePath.FORGOT_PASSWORD }}
      path={RoutePath.FORGOT_PASSWORD}
    />
    <Route
      element={<ResetPassword />}
      handle={{ pathPattern: `${RoutePath.RESET_PASSWORD}/:id` }}
      path={`${RoutePath.RESET_PASSWORD}/*`}
    />

    {/* Main sections (as seen GlobalNav) */}
    <Route
      element={<CoreLayout component={DashboardController} />}
      handle={{ pathPattern: RoutePath.DASHBOARD }}
      path={RoutePath.DASHBOARD}
    />

    <Route
      element={
        <RestrictedRoute
          authorizedPermissions={[{ resource: ResourceType.APPOINTMENTS, level: AccessLevel.BASIC }]}
          component={AppointmentsContainer}
        />
      }
      handle={{ pathPattern: `${RoutePath.APPOINTMENTS}/:id` }}
      path={`${RoutePath.APPOINTMENTS}/*`}
    />

    <Route
      element={
        <RestrictedRoute
          authorizedPermissions={[{ resource: ResourceType.LEADS, level: AccessLevel.BASIC }]}
          component={ConversationsContainer}
        />
      }
      handle={{ pathPattern: `${RoutePath.CONVERSATIONS}/:id` }}
      path={`${RoutePath.CONVERSATIONS}/*`}
    />

    <Route
      element={
        <RestrictedRoute
          authorizedPermissions={[{ resource: ResourceType.LEADS, level: AccessLevel.BASIC }]}
          authorizedUserScopes={nonWhiteLabelScopes}
          component={LeadActivitiesContainer}
        />
      }
      handle={{ pathPattern: `${RoutePath.LEAD_ACTIVITIES}/:id` }}
      path={`${RoutePath.LEAD_ACTIVITIES}/*`}
    />

    <Route
      element={
        <RestrictedRoute
          authorizedPermissions={[{ resource: ResourceType.TASKS, level: AccessLevel.BASIC }]}
          component={TasksContainer}
        />
      }
      handle={{ pathPattern: `${RoutePath.TASKS}/:id` }}
      path={`${RoutePath.TASKS}/*`}
    />

    <Route
      element={
        <RestrictedRoute
          authorizedPermissions={[{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.BASIC }]}
          component={RetailItemsContainer}
        />
      }
      /**
       * Mainly use for analytics purposes to track path with `/retail/:id` instead of `/retail/?` or `/retail/<real-id>`
       * https://github.com/DataDog/rum-react-integration-examples/tree/master/src/Router#overview
       *
       * Work around to retrieve path pattern for each route until React-Router decides
       * to expose this somehow. https://github.com/remix-run/react-router/issues/8785#issuecomment-1251719772
       */
      handle={{ pathPattern: `${RoutePath.RETAIL_ITEMS}/:id` }}
      path={`${RoutePath.RETAIL_ITEMS}/*`}
    />

    <Route
      element={
        <RestrictedRoute
          authorizedPermissions={[{ resource: ResourceType.TRADE_IN_ITEMS, level: AccessLevel.BASIC }]}
          component={TradeInItemsContainer}
        />
      }
      handle={{ pathPattern: `${RoutePath.TRADE_IN_ITEMS}/:id` }}
      path={`${RoutePath.TRADE_IN_ITEMS}/*`}
    />

    <Route
      element={
        <RestrictedRoute
          authorizedPermissions={[{ resource: ResourceType.LEADS, level: AccessLevel.BASIC }]}
          component={LeadsContainer}
        />
      }
      handle={{ pathPattern: `${RoutePath.LEADS}/:id` }}
      path={`${RoutePath.LEADS}/*`}
    />

    <Route
      element={
        <RestrictedRoute
          authorizedPermissions={[{ resource: ResourceType.USERS, level: AccessLevel.BASIC }]}
          component={UsersContainer}
        />
      }
      handle={{ pathPattern: `${RoutePath.USERS}/:id` }}
      path={`${RoutePath.USERS}/*`}
    />

    <Route
      element={
        <RestrictedRoute
          authorizedPermissions={[{ resource: ResourceType.ROOFTOPS, level: AccessLevel.BASIC }]}
          component={RooftopsContainer}
        />
      }
      handle={{ pathPattern: `${RoutePath.ROOFTOPS}/:id` }}
      path={`${RoutePath.ROOFTOPS}/*`}
    />

    <Route
      element={<Navigate to={`${RoutePath.SETTINGS}/${SubRoute.ACCOUNT}`} />}
      handle={{ pathPattern: RoutePath.MY_ACCOUNT }}
      path={RoutePath.MY_ACCOUNT}
    />

    <Route
      element={
        <RestrictedRoute
          authorizedPermissions={[{ resource: ResourceType.WEBSITES, level: AccessLevel.BASIC }]}
          component={WebsitesContainer}
        />
      }
      handle={{ pathPattern: `${RoutePath.WEBSITES}/:id` }}
      path={`${RoutePath.WEBSITES}/*`}
    />

    {SettingRoutes()}

    {/* Errors */}
    <Route element={<CoreLayout component={Error404} />} path="*" />
  </Route>
);
