import styled from 'styled-components/macro';

import type IntRange from 'components/ui/filters/interfaces/range';
import Range from 'components/ui/forms/shared/Range';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
`;

const mockedRange = { gte: 0, lte: 1 };

interface Props {
  filter: any;
  selectedRange: IntRange;
  setSelectedRange: (range: IntRange) => void;
  updateSearchParam: (name: string, value?: Record<string, number | null>) => void;
}

const RangeFilter = ({
  filter: { id, range: actualRange, increment },
  selectedRange,
  setSelectedRange,
  updateSearchParam,
}: Props) => {
  const range = actualRange || mockedRange;
  return (
    <Container>
      <Range
        max={range.lte}
        min={range.gte}
        onAfterChange={([gte, lte]) => {
          const resetMinRange = range.gte === gte;
          const resetMaxRange = range.lte === lte;
          const hasResetRange = resetMinRange && resetMaxRange;

          const lteValue = resetMaxRange ? null : lte;
          const gteValue = resetMinRange ? null : gte;

          updateSearchParam(id, hasResetRange ? undefined : { gte: gteValue, lte: lteValue });
        }}
        onChange={([gte, lte]) => {
          setSelectedRange({ gte, lte });
        }}
        step={increment}
        value={[
          selectedRange.gte !== null && selectedRange.gte !== undefined ? selectedRange.gte : range.gte,
          selectedRange.lte !== null && selectedRange.lte !== undefined ? selectedRange.lte : range.lte,
        ]}
      />
    </Container>
  );
};
export default RangeFilter;
