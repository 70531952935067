import type { DocumentNode } from 'graphql';

import { appointmentModify } from 'components/sections/createModify/appointments/AppointmentCreateModifyQuery';
import { conversationModify } from 'components/sections/createModify/conversations/ConversationCreateModifyQuery';
import { retailItemModify } from 'components/sections/createModify/inventoryItems/retailItem/RetailItemCreateModifyQuery';
import { tradeInItemModify } from 'components/sections/createModify/inventoryItems/tradeInItem/TradeInItemCreateModifyQuery';
import { leadActivityModify } from 'components/sections/createModify/leadActivities/LeadActivityCreateModifyQuery';
import { leadModify } from 'components/sections/createModify/leads/LeadCreateModifyQuery';
import { taskModify } from 'components/sections/createModify/tasks/TaskCreateModifyQuery';
import { Conversation as ConversationEnum } from 'enums/columns/conversation';
import type { Conversation } from 'store/api/graph/interfaces/types';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { UserType } from 'store/api/graph/responses/responseTypes';
import type { Intl } from 'utils/intlUtils';

export type ArchiveConfigProps = {
  /** The refetchQueries to run after the item's archive status is updated */
  refetchQueries?: string[];
  /** The query that will update the item's archive status */
  mutation: DocumentNode;
  /** The entity type of this item (will be used in the prompt dialog messaging). This is a lokalize key. */
  entityTypeLabel: Intl;
  /** Callback to determine if the user can update the archive status.  */
  canUpdateArchiveStatus: (hasEditPermission: boolean, user: UserType, item: any) => boolean;
  /** Optional custom archive message to use in the prompt */
  customMessage?: Intl;
  /** Optional field to override the default archive parameter in the mutation query */
  overrideArchiveParameter?: string;
};

const ArchiveConfigSettings: { [key: string]: ArchiveConfigProps } = {
  [EntityType.APPOINTMENT]: {
    entityTypeLabel: 'appointment',
    mutation: appointmentModify,
    canUpdateArchiveStatus: hasEditPermission => hasEditPermission,
  },
  [EntityType.CONVERSATION]: {
    entityTypeLabel: 'conversation',
    mutation: conversationModify,
    overrideArchiveParameter: ConversationEnum.ARCHIVED_BY_ME,
    canUpdateArchiveStatus: (hasEditPermission, user, item) =>
      hasEditPermission && (item as Conversation)?.participants?.some(participant => participant.id === user.id),
  },
  [EntityType.LEAD]: {
    entityTypeLabel: 'lead_one',
    mutation: leadModify,
    canUpdateArchiveStatus: hasEditPermission => hasEditPermission,
  },
  [EntityType.LEAD_ACTIVITY]: {
    entityTypeLabel: 'lead_activity_one',
    mutation: leadActivityModify,
    customMessage: 'archive_lead_activity',
    canUpdateArchiveStatus: hasEditPermission => hasEditPermission,
  },
  [EntityType.RETAIL_ITEM]: {
    entityTypeLabel: 'retail_items_one',
    mutation: retailItemModify,
    canUpdateArchiveStatus: hasEditPermission => hasEditPermission,
  },
  [EntityType.TASK]: {
    entityTypeLabel: 'task',
    mutation: taskModify,
    canUpdateArchiveStatus: hasEditPermission => hasEditPermission,
  },
  [EntityType.TRADE_IN_ITEM]: {
    entityTypeLabel: 'trade_ins_one',
    mutation: tradeInItemModify,
    canUpdateArchiveStatus: hasEditPermission => hasEditPermission,
  },
};

export default ArchiveConfigSettings;
