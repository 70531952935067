import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { shoppingToolsBuilderMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { shoppingToolsDetailsQuery } from 'containers/rooftops/shoppingTools/ShoppingToolsContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type {
  ShoppingToolsBuilderMetaQuery,
  ShoppingToolsBuilderMetaQueryVariables,
} from 'store/api/graph/interfaces/types';
import { InventoryItemType } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';

const ShoppingToolsQueryMethods: QueryMethods = {
  getMetaData: async (onMetaUpdate, defaultData, _, seededData) => {
    // If there is no rooftop id in the default data, then we cannot fetch any makes in the metadata
    const rooftopId = seededData?.id || defaultData?.id;
    if (!rooftopId) {
      return null;
    }

    const observable = client.watchQuery<ShoppingToolsBuilderMetaQuery, ShoppingToolsBuilderMetaQueryVariables>({
      query: shoppingToolsBuilderMetaQuery,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      variables: {
        rooftopId,
        type: InventoryItemType.VEHICLE,
      },
    });
    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: res => {
          const metadata = res.data
            ? {
                makes: res.data?.makeOptions,
                showroomConfiguration:
                  res.data?.metadata?.mutation?.rooftop?.shoppingToolsConfiguration?.showroomConfiguration,
                buildAndPriceConfiguration:
                  res.data?.metadata?.mutation?.rooftop?.shoppingToolsConfiguration?.buildAndPriceConfiguration,
              }
            : null;
          resolve({ subscription, metadata });
          onMetaUpdate(metadata);
        },
        error: (error: Error) => {
          logApiError(error);
          reject(error);
        },
      });
    });
  },

  getData: async (id: string) => {
    const response = await client.query({
      query: shoppingToolsDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: ['ShoppingToolsDetailsQuery', 'RooftopDetailsContainerQuery'],
};

export default ShoppingToolsQueryMethods;
