import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { SelectOption } from 'store/api/graph/responses/responseTypes';

const DescriptiveSecondaryText = styled(SecondaryText)`
  white-space: normal;
`;

type DescriptiveListItemProps = {
  /** Data object containing the select options description */
  data: {
    /** Description to be rendered in list item */
    description: string;
  };
} & Omit<SelectOption, 'data'>;

/**
 * DescriptiveListItem shows a single select option which displays a name and description
 */
export const DescriptiveListItem = ({ name, data }: DescriptiveListItemProps) => (
  <ListItem>
    <ListItemDetails>
      <TextRow>
        <PrimaryText>{name}</PrimaryText>
      </TextRow>
      <TextRow>
        <DescriptiveSecondaryText>{data.description}</DescriptiveSecondaryText>
      </TextRow>
    </ListItemDetails>
  </ListItem>
);
