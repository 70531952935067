import { memo } from 'react';

import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { Website } from 'enums/columns/website';

import { LinkedTab, LinkedTabSection } from '../shared/linkedTab/LinkedTab';

const WebsiteLinkedTab = memo<ItemTabProps>(itemTabProps => (
  <LinkedTab
    builderTitle="website_one"
    itemTabProps={itemTabProps}
    sections={[
      {
        type: LinkedTabSection.WEBSITE_INTEGRATIONS,
        settings: {
          sectionTitle: 'integration_other',
          fieldName: Website.PRIMARY_ROOFTOP_INTEGRATIONS,
        },
      },
      {
        type: LinkedTabSection.CREATED_BY,
        settings: {
          fieldName: Website.CREATED_BY_NAME,
        },
      },
    ]}
  />
));

export default WebsiteLinkedTab;
