import type { HTMLAttributes } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { deferredRenderCall } from 'utils/renderingUtils';

import TableHeaderCell from './TableHeaderCell';

interface Props extends HTMLAttributes<HTMLDivElement> {
  rowData: TableCellData[];
  cellStyles?: string | string[];
  onRowResize?: (rowData: TableCellData[], cellData: TableCellData) => void;
  onRowReorder?: (rowData: TableCellData[], cellData: TableCellData) => void;
  contentWidth?: number;
}

const TableHeaderRow = (props: Props) => {
  const { rowData, onRowResize, onRowReorder, cellStyles, contentWidth } = useMemo(() => props, [props]);
  const [resizingCellId, setResizingCellId] = useState<string>();

  const onDrop = useCallback(
    (dragTarget: TableCellData, dropTarget: TableCellData) => {
      if (dropTarget.canReorder) {
        const insertIndex = rowData.indexOf(dropTarget);
        const insertObject = rowData.splice(rowData.indexOf(dragTarget), 1)[0];
        rowData.splice(insertIndex, 0, insertObject);

        deferredRenderCall(() => {
          onRowReorder?.(rowData, dropTarget);
        });
      }
    },
    [onRowReorder, rowData]
  );

  const onCellResized = useCallback(
    (cellData: TableCellData) => onRowResize?.(rowData, cellData),
    [onRowResize, rowData]
  );
  const onResizeStartAndEnd = useCallback(cellId => setResizingCellId(cellId), []);

  return (
    <>
      {rowData
        .filter(cell => cell.enabled)
        .map((cellData: TableCellData) => (
          <TableHeaderCell
            cellData={cellData}
            cellStyles={cellStyles}
            contentWidth={contentWidth}
            disabled={!!resizingCellId}
            key={cellData.columnId}
            onCellResized={onCellResized}
            onDropped={onDrop}
            onResizeStartAndEnd={onResizeStartAndEnd}
          />
        ))}
    </>
  );
};

export default TableHeaderRow;
