import type { ComponentType, MouseEventHandler, ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { autoUpdate, computePosition, flip } from '@floating-ui/dom';
import { paramCase } from 'change-case';
import { merge } from 'lodash-es';
import { css } from 'styled-components/macro';
import styled, { type FlattenSimpleInterpolation } from 'styled-components/macro';
import type { RequireAtLeastOne } from 'type-fest';

import EditorIconButton, {
  type Props as EditorIconProps,
} from 'components/ui/editor/components/shared/EditorIconButton';
import { Clickable } from 'components/ui/shared/Button';
import OutsideClick from 'components/ui/shared/OutsideClick';
import { ElementTestId } from 'enums/testing';
import { DIVIDER } from 'styles/color';
import { NEUTRAL_0, NEUTRAL_800 } from 'styles/tokens';
import { Z_INDEX_1 } from 'styles/z-index';
import { hexToRGBA } from 'utils/styledUtils';

const IconDropdownContainer = styled.div`
  position: relative;
`;

const Content = styled.div`
  background-color: ${NEUTRAL_0};
  border-radius: 8px;
  box-shadow:
    0 0 1px 0 ${hexToRGBA(NEUTRAL_800, '0.24')},
    0 8px 16px 0 ${hexToRGBA(NEUTRAL_800, '0.15')};
  left: 0;
  margin-top: 4px;
  padding: 8px;
  position: absolute;
  top: 0;
  width: max-content;
  z-index: ${Z_INDEX_1};
`;

const ListContainer = styled.div<{ listContainerCss: FlattenSimpleInterpolation | undefined }>`
  display: grid;
  grid-auto-rows: 1fr;
  list-style: none;
  margin: 0;
  padding: 0;
  row-gap: 2px;

  ${props => props.listContainerCss}
`;

const ListItemClickable = styled(Clickable)<{ $isActive: boolean }>`
  align-items: center;
  color: ${NEUTRAL_800};
  column-gap: 8px;
  display: flex;
  padding: 8px;

  &:hover {
    background-color: ${DIVIDER};
    border-radius: 4px;
  }

  ${props =>
    props.$isActive &&
    css`
      background-color: ${DIVIDER};
      border-radius: 4px;
    `}
`;

type DropdownListItem = RequireAtLeastOne<
  {
    /** Optional icon to be displayed for the item */
    icon?: ComponentType<any>;
    /** Optional boolean to indicate if the item is active. */
    isActive?: boolean;
    /** The label to display for the item */
    label?: string;
    /** Optional function to customize the rendering of the label */
    labelRender?: (label: string | undefined) => ReactNode;
    /** The click event handler for the item. */
    onClick: MouseEventHandler<HTMLButtonElement>;
  },
  'label' | 'labelRender'
>;

interface Props<T extends ComponentType<any> = ComponentType<any>> extends EditorIconProps<T> {
  /** List of items to be displayed in the dropdown */
  items: DropdownListItem[];
  /** Optional CSS to be applied to the list container */
  listContainerCss?: FlattenSimpleInterpolation;
  /** Optional test id for the component */
  testId?: string;
}

const IconDropdown = <T extends ComponentType<any> = ComponentType<any>>({
  items,
  listContainerCss,
  showChevron = true,
  testId,
  ...props
}: Props<T>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  /**
   * Update the dropdown position when the dropdown is opened.
   */
  useEffect(() => {
    const buttonCurrent = buttonRef.current;
    const dropdownCurrent = dropdownRef.current;

    if (!isOpen || !buttonCurrent || !dropdownCurrent) {
      return;
    }

    const cleanup = autoUpdate(buttonCurrent, dropdownCurrent, () => {
      void computePosition(buttonCurrent, dropdownCurrent, {
        placement: 'bottom-start',
        middleware: [flip()],
        strategy: 'fixed',
      }).then(({ x, y }) => {
        merge(dropdownCurrent.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    });

    return () => cleanup();
  }, [isOpen]);

  /**
   * Close the dropdown when the user clicks outside of it.
   */
  const handleOutsideClick = useCallback(() => setIsOpen(false), []);

  /**
   * Toggle the dropdown when the button is clicked.
   */
  const handleDropdownToggle = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => void setIsOpen(prevIsOpen => !prevIsOpen),
    []
  );

  /**
   * Handle the click event for each item in the dropdown.
   */
  const handleDropdownItemClick = useCallback(
    (item: DropdownListItem): MouseEventHandler<HTMLButtonElement> =>
      event => {
        item.onClick(event);

        handleDropdownToggle(event);
      },
    [handleDropdownToggle]
  );

  return (
    <IconDropdownContainer data-testid={testId || ElementTestId.ICON_DROPDOWN}>
      <EditorIconButton
        forceActive={isOpen}
        onClick={handleDropdownToggle}
        ref={buttonRef}
        showChevron={showChevron}
        {...props}
      />
      {isOpen && (
        <Content ref={dropdownRef}>
          <OutsideClick ignoredElements={[buttonRef.current]} onClick={handleOutsideClick}>
            <ListContainer listContainerCss={listContainerCss}>
              {items.map(({ icon: Icon, ...item }, index) => {
                const testId = `${ElementTestId.EDITOR_ICON_DROP_DOWN_ITEM_PREFIX}${item.label ? paramCase(item.label) : index}`;

                return (
                  <ListItemClickable
                    $isActive={!!item.isActive}
                    data-testid={testId}
                    key={testId}
                    onClick={handleDropdownItemClick(item)}
                  >
                    {Icon && <Icon height={16} width={16} />}
                    {item.labelRender ? item.labelRender(item.label) : item.label}
                  </ListItemClickable>
                );
              })}
            </ListContainer>
          </OutsideClick>
        </Content>
      )}
    </IconDropdownContainer>
  );
};

export default IconDropdown;
