import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import LinkedStep from 'components/sections/createModify/shared/steps/LinkedStep';
import { getTagOptions, getUserOptions } from 'components/sections/shared/ItemMetaHelpers';
import { participantsListQuery } from 'components/sections/shared/ItemMetaQueries';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

class ConversationLinkedStep extends LinkedStep {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props, context);
    const {
      tier: { data, entityType },
    } = props;

    this.fields.find(({ queryVar }) => queryVar === 'tagIds')!.selectedValue = data.tagName || [];

    const rooftopId = data.rooftopName?.id;
    // Async subpanel configurations
    this.asyncConfigurations = {
      ...this.asyncConfigurations,
      tagIds: {
        request: () => getTagOptions({ rooftopId: [rooftopId], entityType: entityType! }),
        disableKeywordRefetch: true,
      },
      participants: {
        request: async keyword => {
          const userOptions = await getUserOptions(keyword, { rooftopId }, undefined, participantsListQuery);

          const currentParticipants = (data.participants || []).filter(
            ({ id }) => !userOptions.some(({ id: userId }) => userId === id)
          );

          return [
            ...userOptions.filter(
              // Filtering out users who do not have conversation write/admin permissions
              ({ permissions }) =>
                permissions &&
                permissions.some(
                  permission =>
                    permission.resource === ResourceType.LEADS &&
                    [AccessLevel.ADVANCED, AccessLevel.FULL].includes(permission.level)
                )
            ),
            ...currentParticipants,
          ];
        },
      },
    };
  }
}

export default ConversationLinkedStep;
