import type { FormEvent } from 'react';
import { useCallback, useState } from 'react';

import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import styled from 'styled-components/macro';

import { Input, InputContainer, InputSuffix } from 'components/ui/forms/shared/InputText';
import EyeIcon from 'components/ui/icons/EyeIcon';
import EyeSlashedIcon from 'components/ui/icons/EyeSlashedIcon';
import { ElementTestId, maskedInputButtonTestId } from 'enums/testing';
import { type Intl, translate } from 'utils/intlUtils';

const MaskedInputContainer = styled(Input)<{ disableInputWhileMasked?: boolean }>`
  ${({ disableInputWhileMasked }) =>
    !!disableInputWhileMasked &&
    `
    &:disabled {
      opacity: 1;
      
      & + ${InputSuffix} {
        opacity: 1;
      }
    }
  `}
`;

const MasketIconContainer = styled(InputSuffix)`
  transform: translateY(-10px);
`;

/** Settings for the masked input */
export interface MaskInputSettings {
  /** The label for this input */
  inputLabel?: Intl;
  /** Whether this field should be autofocused */
  autoFocus?: boolean;
  /** The colour of the suffix icon for hiding/showing the contents of the input */
  suffixIconColour?: string;
  /** Whether the mask is turned on by default or not */
  isInitiallyMasked?: boolean;
  /** Whether the input is disabled if the mask is currently on */
  disableInputWhileMasked?: boolean;
}

/** Configuration to control the masked input */
export interface MaskInputConfig {
  /** Callback for when the input value is changed */
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  /**
   * Callback for when the mask is requesting to be toggled. Will pass the new requested state, and this callback
   * should return the results of the mask state (true = mask is on, false = mask is off)
   */
  onRequestToggleMask?: (isMaskOn: boolean) => Promise<boolean>;
  /** The value of the input */
  value: string;
  /** The test id to use for this input */
  testId?: string;
}

interface Props extends MaskInputConfig, MaskInputSettings {}

/**
 * A controlled masked input field with functionality to show/hide values sensitive values.
 */
const MaskedInput = ({
  value,
  onChange,
  autoFocus,
  inputLabel,
  suffixIconColour,
  onRequestToggleMask,
  isInitiallyMasked = true,
  disableInputWhileMasked,
  testId,
  ...props
}: Props) => {
  const [isMaskOn, setIsMaskOn] = useState(isInitiallyMasked);

  /* Wrapper function that calls the async onRequestToggleMask callback and sets state to the results */
  const doUnmaskRequest = async (newMaskState, onRequestToggleMaskFunction) => {
    const results = await onRequestToggleMaskFunction(newMaskState);
    setIsMaskOn(results);
  };

  const onToggleIsMaskOn = useCallback(() => {
    if (onRequestToggleMask) {
      void doUnmaskRequest(!isMaskOn, onRequestToggleMask);
    } else {
      setIsMaskOn(!isMaskOn);
    }
  }, [isMaskOn, onRequestToggleMask]);

  return (
    <InputContainer>
      <MaskedInputContainer
        {...props}
        autoFocus={autoFocus}
        data-dd-privacy={DefaultPrivacyLevel.MASK}
        data-testid={testId || ElementTestId.INPUT_PASSWORD}
        disableInputWhileMasked={disableInputWhileMasked}
        disabled={disableInputWhileMasked ? isMaskOn : false}
        onChange={onChange}
        placeholder={inputLabel ? translate.t(inputLabel) : ''}
        type={isMaskOn ? 'password' : 'text'}
        value={value}
      />
      <MasketIconContainer
        colour={suffixIconColour}
        data-testid={testId ? maskedInputButtonTestId(testId) : undefined}
        isInteractive
        onClick={onToggleIsMaskOn}
      >
        {isMaskOn ? <EyeSlashedIcon /> : <EyeIcon />}
      </MasketIconContainer>
    </InputContainer>
  );
};

export default MaskedInput;
