import { useCallback, useMemo, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import Range from 'components/ui/forms/shared/Range';
import { ENTITY_PADDING } from 'styles/spacing';
import { GREEN_500 } from 'styles/tokens';
import { decimalPercentToString } from 'utils/stringUtils';

import { InputContainer } from './InputText';

export interface SliderInputSettings {
  min: number;
  max: number;
  step: number;
  /** Show the current slider value as a percentage */
  showPercentage?: boolean;
  /** The label colour for the sliders current value */
  labelColour?: string;
}

export interface SliderInputProps {
  settings: SliderInputSettings;
  defaultValue: number;
  onChange?: (val: Record<'currentTarget', { value: any }>) => void;
  /** Testing ID to reference this step field input */
  testId?: string;
}

const SliderLabel = styled(Label)<{ labelColour: string }>`
  ${({ labelColour }) => css`
    color: ${labelColour};
  `}
  position: absolute;
  right: 0;
  top: calc(-100% - ${ENTITY_PADDING} - 3px); /** Subtracting 3 pixels to align with input label */
`;

const Slider = styled(Range)`
  margin-top: ${ENTITY_PADDING};
`;

/**
 * Slider input to capture a limited value (e.g. between 0 and 10)
 */
const SliderInput = (props: SliderInputProps) => {
  const {
    settings: { max, min = 0, step, showPercentage, labelColour = GREEN_500 },
    onChange,
    defaultValue,
    testId,
  } = useMemo(() => props, [props]);
  const [currentValue, setCurrentValue] = useState<number>(defaultValue || 0);
  const rangeValue = useMemo<number[]>(() => [min, currentValue], [currentValue, min]);

  return (
    <InputContainer>
      <SliderLabel labelColour={labelColour}>
        {showPercentage ? `${decimalPercentToString(currentValue / max)}` : `${currentValue}/${max}`}
      </SliderLabel>
      <Slider
        isSlider
        max={max}
        min={min}
        onChange={useCallback(
          updatedValues => {
            const value = updatedValues[1];
            setCurrentValue(value);
            onChange?.({ currentTarget: { value } });
          },
          [onChange]
        )}
        step={step}
        testId={testId}
        value={rangeValue}
      />
    </InputContainer>
  );
};

export default SliderInput;
