import isChromatic from 'chromatic/isChromatic';
import * as i18next from 'i18next';
import i18nextSprintf from 'i18next-sprintf-postprocessor';
import { cloneDeep, get, isString } from 'lodash-es';
import { Settings } from 'luxon';

import locales from '../locales';

import { getTimeZone } from './dateUtils';
import { localeStorage, timeZoneStorage } from './storage/intl';

export enum Locale {
  EN_CA = 'en-CA',
  EN_US = 'en-US',
  FR_CA = 'fr-CA',
  ES_US = 'es-US',
}

export enum MultilingualStringValue {
  EN = 'en',
  FR = 'fr',
  ES = 'es',
}

export const getMultilingualValueFromLocale = (locale: Locale) =>
  ({
    [Locale.EN_CA]: MultilingualStringValue.EN,
    [Locale.EN_US]: MultilingualStringValue.EN,
    [Locale.FR_CA]: MultilingualStringValue.FR,
    [Locale.ES_US]: MultilingualStringValue.ES,
  })[locale];

export const getLocaleFromMultilingualValue = (value: MultilingualStringValue): Locale =>
  ({
    [MultilingualStringValue.EN]: Locale.EN_CA, // `EN_US` has same key and `isEnUS` not used
    [MultilingualStringValue.FR]: Locale.FR_CA,
    [MultilingualStringValue.ES]: Locale.ES_US,
  })[value];

export const isMatchingLocale = (locale: Locale) => localeStorage.get() === locale;
export const isEnCA = () => isMatchingLocale(Locale.EN_CA);
// export const isEnUS = () => isMatchingLocale('en-US');
export const isFrCA = () => isMatchingLocale(Locale.FR_CA);
export const isEsUs = () => isMatchingLocale(Locale.ES_US);

/**
 * This bootSequence is what gets called before the app is rendered. It will try to see if we need to load the `Intl`
 * polyfill, load it if need be, load the extra necessary locale data for the lang to work, add the locale to storage
 * (handled inside `loadLocaleData`), and finally resolve a Promise with the locale code.
 */
export const bootSequence = (locale = Locale.EN_CA): Promise<Locale> =>
  /*
   * TODO: loadIntlPolyfill()
   * TODO: loadLocaleData()
   */

  new Promise(resolve => {
    let currentLocale = localeStorage.get();
    const currentTimeZone = getTimeZone();

    // TimeZone is set during boot and not dynamically to avoid issues from timeZone changes while using the app.
    timeZoneStorage.set(currentTimeZone);

    if (!currentLocale) {
      currentLocale = locale;
      localeStorage.set(currentLocale);
    }

    // Init Luxon
    Settings.defaultZone = currentTimeZone;

    // Init i18next
    void i18next.use(i18nextSprintf).init({
      interpolation: {
        skipOnVariables: false,
      },
      debug: process.env.REACT_APP_SERVICES_ENV === 'dev' && process.env.REACT_APP_FEATURE_I18NEXT_DEBUG === 'true',
      resources: locales,
      /**
       *  We want to force english locale on chromatic ui-test to omit any inconsistent
       *  snapshot behaviour due to locale
       *
       *  @link https://www.chromatic.com/docs/ischromatic
       */
      lng: isChromatic() ? Locale.EN_CA : currentLocale,
      fallbackLng: Locale.EN_CA,
      returnEmptyString: false,
      preload: [Locale.EN_CA],
    });
    resolve(currentLocale);
  });

/**
 * A type that represents all the keys in the (en|fr).json file. This is used to ensure that
 * the keys are typed correctly when using the `t` function.
 */
type LokaliseKeys = keyof (typeof locales)[Locale.EN_CA]['translation'];

/** The type for the `translate.t()` function */
export type Translate = typeof translate;

export type IntlPlural = StripOneOrOther<LokaliseKeys>;

export type Intl = LokaliseKeys;

export type TranslateFn = (namespace: Intl, args?: (string | number)[], options?: i18next.TOptions) => string;

/**
 * I18next translation util for both classes and functional components.
 */
export const translate = Object.freeze({
  /**
   * Get i18next translations with/without sprintf support.
   * Used with static singular/plural keys, for dynamic keys based on
   * a `count`, use `tPlural` instead.
   *
   * Example:
   *  import { translate } from 'utils/intlUtils';
   *  const { t } = translate;
   *
   *  // Without sprintf support
   *  t('key');
   *
   *  // With sprintf support
   *  t('key', ['5', 'days']); // Due in %d %s ---> 'Due in 5 days'
   *
   * @param {Intl} namespace - The key name (singular/plural) of the desired translation
   * @param {(string|number)[]} args - An array of sprintf tokens to process
   * @param {i18next.TOptions} options - Conforms to useTranslation's options config and not localized.
   */
  t: (namespace: Intl, args: (string | number)[] = [], options: i18next.TOptions = {}) => {
    if (args.length > 0) {
      return i18next.t(namespace, { postProcess: 'sprintf', sprintf: args, ...options });
    }

    const hasCapitalLetters = namespace !== namespace.toLocaleLowerCase();
    const hasWhiteSpace = namespace.includes(' ');
    const isInvalidLokaliseKeyFormat = hasCapitalLetters || hasWhiteSpace;
    if (isInvalidLokaliseKeyFormat) {
      // Key is already translated or incorrect format
      return namespace;
    }

    return i18next.t(namespace, options);
  },

  /**
   * Gets the singular or plural variation of a translation based on the `count` provided.
   * Different languages have different rules for plurals, thus the dynamic key to be used
   * is automatically handled by `i18next`.
   *
   * English language example:
   *  tPlural('car', 0); // 'Cars'
   *  tPlural('car', 1); // 'Car'
   *  tPlural('car', 2); // 'Cars'
   *  tPlural('car', amountOfCars, [amountOfCars]); // '5 Cars', '1 Car'
   *
   * You can read more on i18next plurals here:
   * https://www.i18next.com/translation-function/plurals
   * https://edealer-wiki.netlify.app/documentation/localization.html#clients
   *
   * @param {Intl} namespace - The singular key name of the desired translation
   * @param {object} count - The value to determine whether to use the singular/plural key
   */
  tPlural: (
    namespace: IntlPlural | IntlPlural[],
    count: number,
    args: (string | number)[] = [],
    options: i18next.TOptions = {}
  ) => i18next.t(namespace, { count: count || 0, postProcess: 'sprintf', sprintf: args, ...options }),
});

/*
 * Many objects that need translating follow a similiar pattern of having a label, placeholder or subLabel field.
 * StepField and TableSettings are two of the most common, so this is used as a default argument for translating
 * objects.
 */
export const defaultTranslateFields = ['label', 'placeholder', 'subLabel', 'tooltip'];

/**
 * Helper method that iterates through all nested objects/arrays and runs `translateField` conversions. Since
 * the primary objects that need to be translated are StepField or TableSettings, the defaultTranslateFields is used
 * as a default argument for the fields parameter.
 */
export const translateFields = (
  translatableFields: Record<string, any>,
  fields: string[] | null = defaultTranslateFields,
  ignoreFields: string[] = ['id'],
  deepCopy = true
): any => {
  const translationObject = deepCopy ? cloneDeep(translatableFields) : translatableFields;
  for (const field in translationObject) {
    if (typeof translationObject[field] === 'object') {
      translateFields(translationObject[field], fields, ignoreFields, false);
    }
  }

  return !!translationObject && translateField(translationObject, fields, ignoreFields);
};

/**
 * Helper method that converts an object's properties specified by fields into a dynamic getter that
 * translates that properties string value
 *
 * - translateField(obj, ['a','b','c']) <-  only a, b, c will be translated getters
 * - translateField(obj, defaultTranslatedFields.concat(['a','b','c'])) <-  a, b, c will be translated getters in
 * addition to label, placeholder, sublabel
 * - translateField(obj) <-  label, placeholder, sublabel will be translated getters
 * - translateField(obj, null) <-  all fields in obj that is a string value will be a translated getter
 * - translateField(obj, null, ['ignore_me']) <-  all fields except for 'ignore_me' in obj that is a string value
 * will be a translated getter
 */
export const translateField = (
  translateObjectFields: Record<string, any>,
  fields: string[] | null = defaultTranslateFields,
  ignoreFields: string[] = ['id']
): any => {
  for (const prop of Object.keys(translateObjectFields).filter(
    field => (!fields || fields.includes(field)) && !ignoreFields.includes(field)
  )) {
    const originalProp: Intl = get(translateObjectFields, prop);
    if (isString(originalProp)) {
      Object.defineProperty(translateObjectFields, prop, { get: () => translate.t(originalProp) });
    }
  }
  return translateObjectFields;
};

/**
 * Simple method that sets the locale to the provided locale and refreshes the page
 */
export const setLocale = (locale: Locale) => {
  localeStorage.set(locale);
  if (i18next.default.language !== locale) {
    void i18next?.changeLanguage(locale);
  }
  if (Settings.defaultLocale !== locale) {
    Settings.defaultLocale = locale;
  }
};

/**
 * Format a month title based on locale. Given a date, will return full month name + year.
 */
export const customFormatMonthTitle = (date: Date, locale: Locale) => {
  const month = date.toLocaleString(locale, { month: 'long' });
  return `${month} ${date.getFullYear()}`;
};
