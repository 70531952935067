import type { ReactElement, ReactNode } from 'react';
import { memo, useRef } from 'react';

import styled, { css } from 'styled-components/macro';

import { GRID_ITEM_GAP, MIN_GRID_SECTION_WIDTH } from 'components/sections/shared/ItemGridRowSection';
import ImageActions from 'components/ui/images/ImageActions';
import type { ImagePropsForHeroType } from 'components/ui/images/Images';
import Image, { FallbackImage, HeroImage } from 'components/ui/images/Images';
import { Section } from 'components/ui/layouts/CardLayout';
import { ImageLoaderContainer } from 'components/ui/loading/ImageLoader';
import { ImageSize, ImageType } from 'enums/imageType';
import { useFlexGrid } from 'hooks/useFlexGrid';
import { DIVIDER } from 'styles/color';
import { PREVIEW_IMAGE_HEIGHT } from 'styles/layouts';

interface HeroSectionProps {
  /** Whether the HeroSection items are vertically stacked or not. */
  isStackedView?: boolean;
  /** A number between 0 - 1 to determine area of hero section that the image occupies. Defaults to 0.50 or 50%. */
  ratioOfImageToContent?: number;
  /** If the hero image is an avatar, then the aspect ratio will be fixed to 1 */
  isAvatar?: boolean;
}

const HeroContainer = styled.div<HeroSectionProps>`
  display: flex;
  ${({ isStackedView }) => css`
    flex-direction: ${isStackedView ? 'column' : 'row'};
    height: ${isStackedView ? PREVIEW_IMAGE_HEIGHT : 'auto'};
  `}

  > * {
    flex: 1;

    :first-child {
      flex-grow: 0;
      flex-shrink: 0;
      padding: 15px;
      flex-basis: ${({ ratioOfImageToContent }) => `calc(100% * ${ratioOfImageToContent || 0.5})`};
      height: auto;
      min-height: 100%;
      ${({ isStackedView }) =>
        isStackedView &&
        css`
          height: ${PREVIEW_IMAGE_HEIGHT};
        `}
      ${({ isAvatar }) =>
        isAvatar &&
        css`
          height: ${PREVIEW_IMAGE_HEIGHT};
          width: ${PREVIEW_IMAGE_HEIGHT};
          min-width: ${PREVIEW_IMAGE_HEIGHT};
          margin: auto;
        `}
    }
  }

  ${Section}:last-of-type {
    border-bottom: 0;
  }

  ${HeroImage}, ${FallbackImage}, ${ImageLoaderContainer} {
    border-radius: 10px;
  }

  ${FallbackImage} {
    > svg {
      margin: auto 0;
    }
  }
`;

const HeroGrid = styled.div<HeroSectionProps>`
  height: 100%;
  ${({ isStackedView }) => `border-${isStackedView ? 'top' : 'left'}: 1px solid ${DIVIDER}`}
`;

const HeroSection = memo<{
  image?: string;
  onEdit?: () => void;
  children: ReactNode | ReactElement[];
  ratioOfImageToContent?: number;
  fallbackSrc: ImagePropsForHeroType['fallbackSrc'];
  isAvatar?: boolean;
}>(({ image, ratioOfImageToContent, isAvatar, onEdit, fallbackSrc, children }) => {
  const heroRef = useRef<HTMLDivElement>(null);
  const flexGridInfo = useFlexGrid(heroRef, MIN_GRID_SECTION_WIDTH, GRID_ITEM_GAP);
  const isStackedView = flexGridInfo.columnsToFill <= 2;

  return (
    <>
      <Section css="padding: 0;">
        <HeroContainer
          isAvatar={isAvatar}
          isStackedView={isStackedView}
          ratioOfImageToContent={ratioOfImageToContent}
          ref={heroRef}
        >
          <div>
            {onEdit ? (
              <ImageActions onEdit={onEdit}>
                <Image
                  css="height: 100%"
                  fallbackSrc={fallbackSrc}
                  size={ImageSize.HERO}
                  src={image}
                  type={ImageType.HERO}
                />
              </ImageActions>
            ) : (
              <Image
                css="height: 100%"
                fallbackSrc={fallbackSrc}
                size={ImageSize.HERO}
                src={image}
                type={ImageType.HERO}
              />
            )}
          </div>
          {!isStackedView && <HeroGrid isStackedView={isStackedView}>{children}</HeroGrid>}
        </HeroContainer>
      </Section>
      {isStackedView && children}
    </>
  );
});

export default HeroSection;
