/* All of the available route paths */

export enum RoutePath {
  APPOINTMENTS = '/appointments',
  WEBSITES = '/websites',
  CONVERSATIONS = '/conversations',
  DASHBOARD = '/dashboard',
  FORGOT_PASSWORD = '/forgot-password',
  HOME = '/',
  LEAD_ACTIVITIES = '/incoming',
  LEADS = '/leads',
  LOGIN = '/login',
  MOBILE_RICH_TEXT_EDITOR = '/mobile-rich-text-editor',
  REQUEST_INVITE = '/request-invite',
  RESET_PASSWORD = '/reset-password',
  RETAIL_ITEMS = '/retail',
  ROOFTOPS = '/rooftops',
  TASKS = '/tasks',
  TRADE_IN_ITEMS = '/trade-ins',
  MY_ACCOUNT = '/my-account',
  USERS = '/users',
  SETTINGS = '/settings',
}

export enum SubRoute {
  TABLE = '/table',
  /**
   * Settings
   */
  CONTACTS = 'contacts',
  BULK_ADJUSTMENTS = 'bulk-adjustments',
  DEPARTMENTS = 'departments',
  GENERAL = 'general',
  GROUP = 'group',
  INTEGRATIONS = 'integrations',
  LEAD_FORWARDING = 'lead-forwarding',
  MAILBOXES = 'mailboxes',
  ACCOUNT = 'account',
  PERMISSIONS = 'permissions',
  PRICING_SEGMENTS = 'pricing-segments',
  ROOFTOP = 'rooftop',
  SHOWROOM_BUILD_AND_PRICE = 'showroom-build-and-price',
  TAGS = 'tags',
  USERS = 'users',
}

export enum ExternalSites {
  V4_INVENTORY = '/inventory',
}
