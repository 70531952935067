import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { debounce, sortBy } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import { ButtonContainer, Buttons, ButtonText, Clickable, FillWithClickable } from 'components/ui/shared/Button';
import { ClientFacetFilterId } from 'enums/clientFacetFilter';
import { useSearch } from 'hooks/contexts/useSearch';
import type { Column } from 'store/api/graph/interfaces/types';
import { BLUE_050, BLUE_500, NEUTRAL_0 } from 'styles/tokens';
import { FONT_SIZE_14 } from 'styles/typography';
import { translate } from 'utils/intlUtils';

import SearchInput from '../forms/shared/SearchInput';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import Scrollable from '../shared/Scrollable';
import SortableIcon from '../shared/SortableIcon';

import {
  ClickableWorkaroundWrapper,
  FacetContainer,
  FacetFilterSectionContainer,
  FacetFilterSectionTitle,
  FilterSearchSection,
  SelectedFacetFilterContainer,
  SelectedFilterSectionHeader,
} from './common';

const { t } = translate;

const FilterSectionContainer = styled(SelectedFacetFilterContainer)`
  display: flex;
  flex-direction: column;

  ${Label} {
    top: 0;
  }
`;

const ListItemContainer = styled(Clickable)<{ isSorted: boolean }>`
  width: 100%;
  ${({ isSorted }) =>
    isSorted &&
    css`
      background: ${BLUE_050};
      ${Text} {
        color: ${BLUE_500};
      }
    `}
  ${FacetContainer} {
    height: 53px;
    width: 100%;
    justify-content: unset;
  }
`;

const SortPillContainer = styled(ButtonContainer)`
  svg,
  span {
    font-size: ${FONT_SIZE_14};
    fill: ${NEUTRAL_0};
    color: ${NEUTRAL_0};
  }
`;

interface SortPillProps extends Column {
  onUpdateColumn: () => void;
}

const SortPill = ({ name, sortDirection, sortPriority, onUpdateColumn }: SortPillProps) => (
  <SortPillContainer onClick={onUpdateColumn} selected={true}>
    <ButtonText>{name}</ButtonText>
    <SortableIcon sortDirection={sortDirection} sortPriority={sortPriority} />
  </SortPillContainer>
);

interface SortFilterProps {
  columns: Column[];
  setSelectedFacetFilterId: Dispatch<SetStateAction<string | undefined>>;
}

export const SortFilterSection = ({ columns, setSelectedFacetFilterId }: SortFilterProps) => {
  const [facetSearchQuery, setFacetSearchQuery] = useState('');
  const {
    updateSortOrder,
    searchParams: { sort = [] },
  } = useSearch();

  const updateColumn = useCallback(
    (id: string, persistent = false) =>
      debounce(() => {
        updateSortOrder(id, columns, persistent);
      }, 200),
    [columns, updateSortOrder]
  );

  const title = t('sort_by');
  const [, selectedColumns] = useMemo(
    () => [
      columns.filter(
        ({ sortable, name }) =>
          sortable && (!facetSearchQuery || name.toLowerCase().includes(facetSearchQuery.toLowerCase()))
      ),
      sortBy(
        columns.filter(({ sortDirection }) => !!sortDirection),
        ({ sortPriority }) => sortPriority
      ),
    ],
    // Ignoring because sort params are a required dependency that updates the columns from outside for display purposes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facetSearchQuery, columns, sort]
  );

  return (
    <FacetFilterSectionContainer hasSelectedFacets={selectedColumns.length > 0}>
      <FillWithClickable
        onClick={() => {
          setSelectedFacetFilterId(ClientFacetFilterId.SORT_BY);
          setFacetSearchQuery('');
        }}
      />
      <FacetFilterSectionTitle>
        {title}
        <ChevronRightIcon />
      </FacetFilterSectionTitle>
      {selectedColumns.length > 0 && (
        <ClickableWorkaroundWrapper>
          <Buttons>
            {selectedColumns.map(column => (
              <SortPill key={column.id} onUpdateColumn={updateColumn(column.id, true)} {...column} />
            ))}
          </Buttons>
        </ClickableWorkaroundWrapper>
      )}
    </FacetFilterSectionContainer>
  );
};

export const SortSelectedFacetFilterContainer = ({ columns, setSelectedFacetFilterId }: SortFilterProps) => {
  const [facetSearchQuery, setFacetSearchQuery] = useState('');
  const {
    updateSortOrder,
    searchParams: { sort = [] },
  } = useSearch();

  const updateColumn = useCallback(
    (id: string, persistent = false) =>
      debounce(() => {
        updateSortOrder(id, columns, persistent);
      }, 200),
    [columns, updateSortOrder]
  );

  const title = t('sort_by');
  const [filteredColumns] = useMemo(
    () => [
      columns.filter(
        ({ sortable, name }) =>
          sortable && (!facetSearchQuery || name.toLowerCase().includes(facetSearchQuery.toLowerCase()))
      ),
      sortBy(
        columns.filter(({ sortDirection }) => !!sortDirection),
        ({ sortPriority }) => sortPriority
      ),
    ],
    // Ignoring because sort params are a required dependency that updates the columns from outside for display purposes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facetSearchQuery, columns, sort]
  );

  return (
    <FilterSectionContainer>
      <SelectedFilterSectionHeader>
        <Clickable onClick={() => setSelectedFacetFilterId(undefined)}>
          <ArrowLeftIcon />
        </Clickable>
        <Label>{title}</Label>
      </SelectedFilterSectionHeader>
      <FilterSearchSection>
        <SearchInput
          autoFocus
          defaultValue={''}
          onChange={setFacetSearchQuery}
          placeholder={t('search_x', [t('columns')])}
        />
      </FilterSearchSection>
      <Scrollable>
        {filteredColumns.map(({ id, name, sortDirection, sortPriority }) => (
          <ListItemContainer isSorted={!!sortDirection} key={id} onClick={updateColumn(id)}>
            <FacetContainer>
              <SortableIcon sortDirection={sortDirection} sortPriority={sortPriority} />
              <Text>{name}</Text>
            </FacetContainer>
          </ListItemContainer>
        ))}
      </Scrollable>
    </FilterSectionContainer>
  );
};
