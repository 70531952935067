import ActionableTitle from 'components/ui/details/ActionableTitle';
import CirclePlusIcon from 'components/ui/icons/CirclePlusIcon';
import EditIcon from 'components/ui/icons/EditIcon';
import { Section } from 'components/ui/layouts/CardLayout';
import { ListItems } from 'components/ui/layouts/ListItem';
import AppraisalListItem from 'components/ui/lists/AppraisalListItem';
import type { TradeInAppraisal } from 'store/api/graph/interfaces/types';
import { NEUTRAL_700 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props {
  appraisal: TradeInAppraisal;
  onAdd?: () => void;
}

const UserAppraisalsSection = ({ appraisal, onAdd }: Props) => (
  <Section>
    <ActionableTitle
      icon={appraisal ? <EditIcon /> : <CirclePlusIcon color={NEUTRAL_700} />}
      label={appraisal ? t('my_appraisal') : t('submit_your_appraisal')}
      onEdit={onAdd}
    />
    {!!appraisal && (
      <ListItems>
        <AppraisalListItem key={appraisal.id} {...appraisal} />
      </ListItems>
    )}
  </Section>
);

export default UserAppraisalsSection;
