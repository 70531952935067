import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { SliderInputSettings } from 'components/ui/forms/shared/SliderInput';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { InventoryItemAttributesPointer } from 'enums/inventoryItemAttributesPointer';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import {
  MotorcycleAttributesInputParameter,
  TradeInItemModifyParameter,
  VehicleAttributesInputParameter,
} from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

const MileageAndConditionFields = defineDetailsFields({
  'vehicleAttributes.mileage': {
    label: 'mileage',
    queryAlias: 'mileage',
    groupType: StepFieldType.MILEAGE,
    clear: { field: VehicleAttributesInputParameter._mileage, target: InventoryItemAttributesPointer.VEHICLE },
    hasSeparator: true,
  },
  'motorcycleAttributes.mileage': {
    label: 'mileage',
    queryAlias: 'mileage',
    groupType: StepFieldType.MILEAGE,
    clear: { field: MotorcycleAttributesInputParameter._mileage, target: InventoryItemAttributesPointer.MOTORCYCLE },
    hasSeparator: true,
  },
  conditionScore: {
    label: 'condition',
    groupType: StepFieldType.SLIDER,
    clear: { field: TradeInItemModifyParameter._conditionScore },
    hasSeparator: true,
    settings: {
      max: 10,
      min: 0,
      step: 1,
    } as SliderInputSettings,
  },
  accidentAmount: {
    groupType: StepFieldType.CURRENCY,
    label: 'estimated_damage_amount',
    clear: { field: TradeInItemModifyParameter._accidentAmount },
    hasSeparator: true,
  },
  numKeys: {
    groupType: StepFieldType.NUMBER,
    label: 'number_of_keys',
    clear: { field: TradeInItemModifyParameter._numKeys },
    hasSeparator: true,
  },
  [InventoryItem.CONDITION_DECLARATIONS]: {
    label: 'declaration_other',
    queryAlias: 'conditionDeclarations',
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    options: `mutation.item.conditionDeclarations`,
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
  },
});

export default MileageAndConditionFields;
