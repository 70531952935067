import { gql } from '@apollo/client';

import { integration } from './integration';

export const featureSet = gql`
  fragment FeatureSetFragment on FeatureSet {
    appointment {
      enabled
    }
    lead {
      enabled
      conversations
      smsMailboxLimit
      forwarding
    }
    retail {
      enabled
      buildAndPrice
      itemLimit
      paymentOptions
      paymentOptionsNames
      showroom
      premiumExportIntegrations {
        ...IntegrationFragment
      }
    }
    retailPortal {
      enabled
    }
    task {
      enabled
    }
    tradeIn {
      enabled
      guaranteedOfferPackageName
      guaranteedOfferPackage
    }
    oem
    oemName
  }
  ${integration}
`;
