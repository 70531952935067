import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';

import { TestingCommands } from 'enums/testing';
import type { User } from 'store/api/graph/interfaces/types';
import type { LoggerOptions } from 'types/Client';
import { getReleaseIdentifier } from 'utils/deviceInfoUtils';
import { isLoggingEnabled } from 'utils/loggingUtils';

import type LoggingService from './LoggingService';

// eslint-disable-next-line unicorn/no-static-only-class
class DatadogLogger {
  static LoggingService: typeof LoggingService;

  /**
   * Initialize logger
   */
  static init(_LogginService: typeof LoggingService) {
    if (!DatadogLogger.isEnabled()) {
      return;
    }

    this.LoggingService = _LogginService;

    const commonInitProperties = {
      site: 'datadoghq.com',
      sessionSampleRate: 100,
      clientToken: process.env.REACT_APP_FEATURE_DATADOG_RUM_CLIENT_TOKEN || '',
      service: process.env.REACT_APP_FEATURE_DATADOG_RUM_SERVICE || '',
      version: getReleaseIdentifier(),
      env: process.env.REACT_APP_FEATURE_DATADOG_ENV_NAME || '',
      telemetrySampleRate: 0, // Opt out of telemetry
    };

    // RUM - window.DD_RUM
    datadogRum.init({
      ...commonInitProperties,
      sessionReplaySampleRate: 100, // Enable Session Replay
      allowedTracingUrls: [process.env.REACT_APP_BASE_URL, process.env.REACT_APP_GRAPH_BASE]
        .filter(url => url !== undefined)
        .map(String),
      applicationId: process.env.REACT_APP_FEATURE_DATADOG_RUM_APPLICATION_ID || '',
      /**
       * All user input is not sensitive, we can track all for better monitoring and
       * explicitly mask certain fields.
       * Search for `DD_PRIVACY_HTML_ATTRIBUTE` to see the specific fields that are masked.
       *
       * More info: https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/#privacy-options
       */
      defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
      trackUserInteractions: true,
      startSessionReplayRecordingManually: true,
    });
    datadogRum.setGlobalContextProperty('cypress', !!window[TestingCommands.CYPRESS]);
    datadogRum.setGlobalContextProperty('storybook', !!process.env.STORYBOOK);

    // LOGS - window.DD_LOGS
    datadogLogs.init({
      ...commonInitProperties,

      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      forwardReports: 'all',
    });
    datadogLogs.setGlobalContextProperty('cypress', !!window[TestingCommands.CYPRESS]);
    datadogLogs.setGlobalContextProperty('storybook', !!process.env.STORYBOOK);
  }

  /**
   * Checks if DatadogLogger is enabled.
   *
   * @example Used for gating calls.
   */
  static isEnabled(): boolean {
    return (
      isLoggingEnabled() &&
      !!process.env.REACT_APP_FEATURE_DATADOG_RUM_APPLICATION_ID &&
      !!process.env.REACT_APP_FEATURE_DATADOG_RUM_CLIENT_TOKEN &&
      !!process.env.REACT_APP_FEATURE_DATADOG_RUM_SERVICE &&
      !!process.env.REACT_APP_FEATURE_DATADOG_ENV_NAME &&
      !process.env.STORYBOOK &&
      !window[TestingCommands.CYPRESS]
    );
  }

  /**
   * Set logged in User
   *
   * @param user
   * @example DatadogLogger.setUser(User)
   */
  static setUser({ displayName, group, ...props }: Pick<User, 'displayName' | 'id' | 'email' | 'scope' | 'group'>) {
    if (!DatadogLogger.isEnabled()) {
      return;
    }

    datadogRum.setUser({
      name: displayName,
      group: group?.name.value,
      ...props,
    });
  }

  /**
   * Clear current active user
   *
   * @example DatadogLogger.clearUser()
   */
  static clearUser() {
    if (!DatadogLogger.isEnabled()) {
      return;
    }

    datadogRum.clearUser();
  }

  /**
   * Capture Error Exception
   * Useful for manually logging an error event in DD UI without invoking error boundary.
   *
   * @param error
   * @param info
   * @example DatadogLogger.captureException(new Error('Error'), { scope: 'error-boundary' })
   */
  static captureException(error: Error, info: Record<string, any>) {
    if (!DatadogLogger.isEnabled()) {
      return;
    }

    datadogRum.addError(error, info);
  }

  /**
   * Track page view
   *
   * @param path
   * @example DatadogLogger.trackPageView('/my-custom-page-view')
   */
  static trackPageView(path: string) {
    if (!DatadogLogger.isEnabled()) {
      return;
    }

    datadogRum.startView(path);
  }

  /**
   * Track user interactions and/or custom events.
   * Will be seen in datadog `Sessions` UI.
   *
   * @param action
   * @param context
   * @example DatadogLogger.trackAction('CLICK', { id: 'someEventId' })
   */
  static trackAction(action: string, context?: Record<string, any>) {
    if (!DatadogLogger.isEnabled()) {
      return;
    }

    this.LoggingService.log({
      message: `Custom Action: ${action}`,
      messageContext: { ...context },
    });
    datadogRum.addAction(action, context);
  }

  /**
   * Generic logger function for JS console logs
   *
   * @example https://docs.datadoghq.com/logs/log_collection/javascript/#generic-logger-function
   */
  static log(options: LoggerOptions) {
    // TODO [ED-8261]: Potentially enable custom logger (remove `true` clause)
    // eslint-disable-next-line no-constant-condition
    if (!DatadogLogger.isEnabled() || true) {
      return;
    }
    /*
     *Const { message, messageContext, status, error } = options;
     * // Need to send object as other types would silently fail e.g. arrays
     *const messageContextObject: Record<string, any> = { messageContext };
     *
     * // Stringify for debug comparisons and ensure data available to debug issue. Can remove in future.
     *if (messageContext) {
     *  messageContextObject.messageContextStr = JSON.stringify(
     *    messageContext,
     *    Object.getOwnPropertyNames(messageContext)
     *  );
     *}
     *if (error) {
     *  messageContextObject.errorStr = JSON.stringify(error, Object.getOwnPropertyNames(error));
     *}
     *
     *datadogLogs.logger.log(message, messageContextObject, status, error);
     */
  }

  /**
   * Starts a session replay recording
   *
   * @example When init of the user is run such as login.
   */
  static startSessionReplayRecording() {
    if (!DatadogLogger.isEnabled()) {
      return;
    }

    datadogRum.startSessionReplayRecording();
  }

  /**
   * Stops a session replay recording
   *
   * @example Manually stop recording. Also automatically handled by DD.
   */
  static stopSessionReplayRecording() {
    if (!DatadogLogger.isEnabled()) {
      return;
    }

    datadogRum.stopSessionReplayRecording();
    datadogRum.stopSession();
  }
}

export default DatadogLogger;
