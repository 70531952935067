import { useCallback } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { css } from 'styled-components/macro';

import { retailItemModify } from 'components/sections/createModify/inventoryItems/retailItem/RetailItemCreateModifyQuery';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { FeatureBundleSet } from 'enums/featureBundle';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import { EntityType, RetailItemStatus } from 'store/api/graph/interfaces/types';
import type { RetailItemResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { RED_500 } from 'styles/tokens';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { translate } from 'utils/intlUtils';
import { includeUrlProtocol } from 'utils/urlUtils';

const { t } = translate;
const { FOR_SALE, SOLD } = RetailItemStatus;

interface Props extends MenuItemProps {
  item: RetailItemResponseType;
}

const RetailItemMenuItems = ({ item, setIsDetailsUpdating, onUpdateArchiveStatus }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const { toggleTier } = useCreateModify();
  const { hasPermissions } = useUser();
  const flags = useFlags();

  const isArchived = isItemArchived(item);
  const { status: statusPrev, id } = item || {};
  const status = statusPrev === FOR_SALE ? SOLD : FOR_SALE;
  const statusName = statusPrev === FOR_SALE ? t('sold') : t('for_sale');

  // onClick callbacks
  const onModifyClicked = toggleTier.bind(null, CreateModifyTiers.TIER_0, {
    tierId: CreateModifyTiers.TIER_0,
    type: BuilderType.RETAIL_ITEMS_MODIFY,
    entityType: EntityType.RETAIL_ITEM,
    title: t('modify_x', [item?.typeName]),
    isCreating: false,
    itemId: id,
    seededData: {
      rooftopId: item?.rooftop?.id,
    },
  });

  const onCreateTaskClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.TASK_CREATE,
        entityType: EntityType.TASK,
        title: t('create_task'),
        isCreating: true,
        seededData: {
          inventoryItemId: item?.id,
          rooftopName: item?.rooftop,
        },
      }),
    [toggleTier, item]
  );

  const onCreateLeadActivityClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.LEAD_ACTIVITY_CREATE,
        entityType: EntityType.LEAD_ACTIVITY,
        title: t('log_lead_activity'),
        isCreating: true,
        seededData: {
          inventoryItemName: item,
          rooftopName: item?.rooftop,
        },
      }),
    [toggleTier, item]
  );

  const onMarkStatusClicked = useCallback(async () => {
    if (status === SOLD && hasPermissions(builderConfig[BuilderType.PURCHASE_ACTIVITY_CREATE].requiredPermissions)) {
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.PURCHASE_ACTIVITY_CREATE,
        entityType: EntityType.LEAD_ACTIVITY,
        title: t('log_purchase_activity'),
        isCreating: true,
        seededData: {
          inventoryItemName: item,
          rooftopName: item?.rooftop,
        },
      });
    } else {
      setIsDetailsUpdating(true);
      await client
        .mutate({
          mutation: retailItemModify,
          variables: { id, status },
        })
        .then(() => setIsDetailsUpdating(false))
        .catch((error: Error) => {
          setIsDetailsUpdating(false);
          logApiError(error);
        });
    }
  }, [status, hasPermissions, builderConfig, toggleTier, item, setIsDetailsUpdating, id]);

  const onCreateAppointmentClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.APPOINTMENT_CREATE,
        entityType: EntityType.APPOINTMENT,
        title: t('create_appointment'),
        isCreating: true,
        seededData: {
          inventoryItemId: item?.id,
          rooftopName: item?.rooftop,
        },
      }),
    [item, toggleTier]
  );

  const onOpenWindowSticker = useCallback(
    () => item.windowStickerUrl && window.open(item.windowStickerUrl, '_blank'),
    [item]
  );

  const disabled = !hasPermissions(builderConfig[BuilderType.RETAIL_ITEMS_MODIFY].requiredPermissions);

  const listingUrl = includeUrlProtocol(item?.listingUrl);

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('log_lead_activity'),
      onClick: onCreateLeadActivityClicked,
      disabled:
        !isFeatureEnabledForRooftop({
          rooftop: item?.rooftop,
          feature: FeatureBundleSet.LEAD,
          featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
        }) || !hasPermissions(builderConfig[BuilderType.LEAD_ACTIVITY_CREATE].requiredPermissions),
    },
    {
      label: t('create_appointment'),
      onClick: onCreateAppointmentClicked,
      disabled:
        !isFeatureEnabledForRooftop({
          rooftop: item?.rooftop,
          feature: FeatureBundleSet.APPOINTMENT,
          featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
        }) || !hasPermissions(builderConfig[BuilderType.APPOINTMENT_CREATE].requiredPermissions),
    },
    {
      label: t('create_task'),
      onClick: onCreateTaskClicked,
      disabled:
        !isFeatureEnabledForRooftop({
          rooftop: item?.rooftop,
          feature: FeatureBundleSet.TASK,
          featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
        }) || !hasPermissions(builderConfig[BuilderType.TASK_CREATE].requiredPermissions),
    },
    {
      label: t('mark_item_as_status', [item?.typeName, statusName]),
      onClick: () => void onMarkStatusClicked(),
      disabled,
    },
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled,
    },
    {
      label: t('view_on_website'),
      onClick: () => !!listingUrl && window.open(listingUrl, '_blank'),
      disabled: !listingUrl,
      isArchivable: false,
    },
    {
      label: t('download_window_sticker'),
      onClick: onOpenWindowSticker,
      disabled: !item?.windowStickerUrl,
    },
    {
      label: t(isArchived ? 'unarchive' : 'archive'),
      onClick: () => onUpdateArchiveStatus?.(),
      disabled: !onUpdateArchiveStatus,
      isArchivable: false,
      titleCss: css`
        color: ${RED_500};
      `,
    },
  ];

  return <MenuItems isArchived={isArchived} items={menuItemsConfig} />;
};

export default RetailItemMenuItems;
