// TODO: Create tests for this class

/**
 * Capitalize each word
 *
 * @example capitalize("clark kent") // "Clark Kent"
 * @param {string} str
 * @returns {string}
 */
export const capitalize = (str: string) =>
  str
    .split(/\s/g)
    .map(word => `${(word[0] || '').toUpperCase()}${word.slice(1)}`)
    .join(' ');

/**
 * Capitalize first character
 *
 * @example: capitalizeFirstChar("hello world!") // "Hello world!"
 * @param str
 * @returns {string}
 */
export const capitalizeFirstChar = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Format string to replace substring with a newValue:
 *
 * @example substringReplace("+1 416-321-1111") // "416-321-1111"
 * @example substringReplace("data:image/png;base64,iVBO...") // "iVBO..."
 * @param {string} str
 * @param {string} substring
 * @param {string} newValue
 * @returns {string}
 */
export const substringReplace = (str: string, substring: string, newValue: string) =>
  str && str.includes(substring) ? str.replace(substring, newValue) : str;

/**
 * Replace camel cased string to normal string:
 *
 * @example decamelize('hourlyAuction') // 'hourly auction'
 * @example decamelize('runList') // 'run list'
 * @param str {string}
 * @returns {string}
 */
export const decamelize = (str: string) => str.replaceAll(/[A-Z]/g, m => ` ${m.toLowerCase()}`);

/**
 * Nullify an empty string:
 *
 * @example nullifyEmptyString("") // null
 * @param {string} str
 * @returns {null|string}
 */
export const nullifyEmptyString = (str: string) => {
  if (str && str.length > 0) {
    return str;
  }
  return null;
};

/**
 * Coverts an enum to a readable capitalized string:
 *
 * @example convertEnumToString('DELIVERED') // 'Delivered'
 * @example convertEnumToString('PENDING_PICKUP') // 'Pending Pickup'
 */
export const convertEnumToString = (str?: string, capAll = true) => {
  if (!str) {
    return str;
  }
  const capFunc = capAll ? capitalize : capitalizeFirstChar;
  return capFunc(str.split('_').join(' ').toLowerCase());
};

/**
 * Converts readable string to enum:
 *
 * @example convertStringToEnum("Pending Delivery") // "PENDING_DELIVERY"
 * @param {string} str
 * @returns {string}
 */
export const convertStringToEnum = (str: string) => {
  if (!str) {
    return str;
  }
  return str.split(' ').join('_').toUpperCase();
};

/**
 * Converts nested string variables into readable strings
 *
 * @example convertedNestedString("vehicleAttributes.mileage") // "vehicleAttributes_mileage"
 * @param {string} str
 * @returns {string}
 */
export const convertedNestedString = (str: string) => {
  if (!str) {
    return str;
  }
  return str.split('.').join('_').replaceAll(/[[\]]/g, '');
};

/**
 * Truncates a string to the max-length supplied, and adds an ellipsis if desired.
 *
 * @example truncate("testString", 3) // "tes..."
 * @param str {string}
 * @param maxLength {number}
 * @param hasEllipsis {boolean}
 * @returns {string|*}
 */
export const truncate = (str: string, maxLength = 25, hasEllipsis = true) => {
  if (str && str.length > maxLength) {
    return [str.slice(0, Math.max(0, maxLength)).trim(), hasEllipsis && '...'].join('');
  }
  return str;
};

/**
 * Truncates a string between the indexes provided.
 * Useful for strings such as previewing download links for added security.
 *
 * @example truncateBetween("https://tesla.com/pictures/cars/secret/truck.jpg", 20, 10)
 * -> "https://tesla.com/pi.../truck.jpg"
 */
export const truncateBetween = (str: string, fromIndex: number, toIndex: number): string => {
  if (str.length < fromIndex + toIndex) {
    return str;
  }
  return str.slice(0, Math.max(0, fromIndex)) + '...' + str.slice(str.length - toIndex);
};

const ELLIPSIS = '...';

/**
 * Truncates a string in the middle so that it always within `maxLength` characters,
 * adding ellipsis inbetween, and trimming out any extra spaces around the ellipsis.
 * Also removes spaces from the ends of the string.
 * If the desired length results in an uneven split, the beginning characters are preserved.
 * Used to shorten long strings down while keeping as much of it visible as possible.
 *
 * @example truncateMiddleToLength("1234567890", 5) -> "0...9"
 * @example truncateMiddleToLength(" 1 2 3456 7 89 0 ", 5) -> "0...9"
 * @example truncateMiddleToLength('12345', 4) -> "1..."
 */
export const truncateMiddleToLength = (str: string, maxLength: number) => {
  if (maxLength < ELLIPSIS.length) {
    throw new Error(
      `truncateMiddleToLength: The ellipsis itself is ${ELLIPSIS.length} characters long. Please pass in a length greater than that.`
    );
  }

  const base = str.trim();
  const stringLength = base.length;

  if (stringLength > maxLength) {
    const delta = (stringLength - maxLength) / 2 + ELLIPSIS.length / 2;
    const halfwayPoint = stringLength / 2;

    const fromIndex = Math.ceil(halfwayPoint - delta);
    const toIndex = Math.ceil(halfwayPoint + delta);

    const firstHalf = base.slice(0, Math.max(0, fromIndex));
    const latterHalf = base.slice(Math.max(0, toIndex));

    const trimmedFirstHalf = firstHalf.trim();
    const trimmedLatterHalf = latterHalf.trim();

    /**
     * Trimming off spaces results in the string being shorter than `maxLength`,
     * so we check if a trim occured.
     * If so, attempt to let the opposing part have an extra character to even things out.
     */
    if (firstHalf.length > trimmedFirstHalf.length) {
      return `${trimmedFirstHalf}${ELLIPSIS}${base.slice(Math.max(0, toIndex - 1)).trim()}`;
    } else if (latterHalf.length > trimmedLatterHalf.length) {
      return `${base.slice(0, Math.max(0, fromIndex + 1)).trim()}${ELLIPSIS}${trimmedLatterHalf}`;
    }

    return `${trimmedFirstHalf}${ELLIPSIS}${trimmedLatterHalf}`;
  }

  return base;
};

/**
 * Formats booleans:
 *
 * @example formatBoolean(true) // "True"
 * @param boolean {boolean}
 * @returns {string}
 */
export const formatBoolean = (boolean: boolean) => capitalize(String(boolean));

/**
 * Converts a decimal percent 0-1 to string percent 0-100%. Do not include decimals for 0.
 */
export const decimalPercentToString = (percent: number, toFixed = 0) =>
  percent === 0 ? '0%' : `${(percent * 100).toFixed(toFixed)}%`;

/**
 * Joins together a potentially nested array of strings, numbers or any other values and filters out falsy values
 */
export const joinStrings = (strings: any[] = [], separator = ', '): string =>
  strings.flat().filter(Boolean).join(separator);

/**
 * Test url
 *
 * @param {string} text
 * @returns {boolean}
 */
export const isURL = (text: string) => /[\w#%&+./:=?@~-]{2,256}\.[a-z]{2,4}\b(\/[\w#%&+./:=?@~-]*)?/gi.test(text);

/**
 * Test email
 *
 * @param {string} text
 * @returns {boolean}
 */
export const isEmail = (text: string) => /^\w+@[_a-z]+?\.[a-z]{2,3}$/gi.test(text);

/**
 * Taken from https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
 *
 * @param {string} text
 * @returns {boolean}
 */
export const isPhoneNumber = (text: string) => /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/gi.test(text);

export const conditionalString = (show: boolean, str: string, defaultStr = ''): string => (show ? str : defaultStr);

/**
 * Removes all non-ASCII character codes
 *
 */
export const stripNonAscii = (str: string): string => str.replaceAll(/[^\u0020-\u007E]/g, '');

/**
 * Removes all HTML tags from a string
 *
 */
export const stripHTMLTags = (str: string): string => str.replaceAll(/(<([^>]+)>)/gi, '');

/**
 * Converts px values to number by stripping out the 'px' string
 *
 */
export const convertPixelToNumber = (str: string): number => Number(str.replace('px', ''));

/**
 * Removes the specified range within a string and inserts a replacement string.
 * No characters will be removed if `selectedRange.from` and to `selectedRange.to` match.
 */
export const insertString = (original: string, textToInsert: string, selectedRange?: { from: number; to: number }) => {
  const { from, to } = { from: 0, to: 0, ...selectedRange };
  const textBeforeSelection = original.slice(0, from);
  const textAfterSelection = original.slice(to > original.length ? original.length : to);

  return `${textBeforeSelection}${textToInsert}${textAfterSelection}`;
};
