import { memo } from 'react';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import Badge, { BadgeColors, Badges } from 'components/ui/shared/Badge';
import type { RooftopRetailPricing } from 'store/api/graph/interfaces/types';
import { translate } from 'utils/intlUtils';

interface Props extends Omit<RooftopRetailPricing, 'rooftop'>, ListItemProps {}

const RooftopRetailPricingListItem = ({ suffixIcon, listItemType, name, description, configuration }: Props) => {
  const { t } = translate;

  const startingPriceField = configuration?.startingPriceFieldName;
  const taxLabel = configuration?.includeTax ? t('include_tax') : t('exclude_tax');

  return (
    <ListItem listItemType={listItemType} suffixIcon={suffixIcon}>
      <ListItemDetails>
        <TextRow>
          <PrimaryText>{name}</PrimaryText>
        </TextRow>
        <TextRow>
          <SecondaryText>{description}</SecondaryText>
        </TextRow>
        <TextRow>
          <Badges>
            {!configuration?.enabled && (
              <Badge color={BadgeColors.NEGATIVE} title={t('disabled')}>
                {t('disabled')}
              </Badge>
            )}
            {configuration?.pricingSourceName && (
              <Badge title={`${t('incentives_and_rates_source')}: ${configuration.pricingSourceName}`}>
                {configuration.pricingSourceName}
              </Badge>
            )}
            {startingPriceField && <Badge title={startingPriceField}>{startingPriceField}</Badge>}
            <Badge title={taxLabel}>{taxLabel}</Badge>
          </Badges>
        </TextRow>
      </ListItemDetails>
    </ListItem>
  );
};

export default memo(RooftopRetailPricingListItem);
