import type { ElementType, ReactNode } from 'react';
import { forwardRef, useId, useLayoutEffect } from 'react';

import { usePopoverContext } from 'hooks/contexts/usePopoverContext';

type PopoverHeadingProps = {
  /** The element to wrap the children with */
  element: ElementType;
  /** The content for the heading */
  children: ReactNode;
};

const PopoverHeading = forwardRef<HTMLElement, PopoverHeadingProps>(({ element: Element, ...props }, ref) => {
  const { setLabelId } = usePopoverContext();
  const id = useId();

  /*
   * Only sets `aria-labelledby` on the Popover root element
   * if this component is mounted inside it.
   */
  useLayoutEffect(() => {
    setLabelId(id);
    return () => setLabelId(undefined);
  }, [id, setLabelId]);

  return (
    <Element {...props} id={id} ref={ref}>
      {props.children}
    </Element>
  );
});

export default PopoverHeading;
