import { useMemo } from 'react';

import type StepFieldPreviewContents from 'components/core/createModify/interfaces/stepFieldPreviewContents';
import { ShoppingToolsTypes } from 'components/sections/createModify/shoppingTools/steps/interfaces';
import Preview from 'components/ui/shared/DisclaimerPreview';
import type { MultilingualString } from 'store/api/graph/interfaces/types';
import { replaceDislaimerTemplateTags } from 'utils/disclaimerTemplateUtils';
import { getLocaleFromMultilingualValue, type Intl, MultilingualStringValue, translate } from 'utils/intlUtils';

const { t } = translate;

/**
 * Translation keys for the disclaimer labels. Includes the tag header, list of available tags, and the preview header
 * text
 */
const disclaimerLabels = (type: ShoppingToolsTypes): Record<string, Intl> => ({
  tagHeader:
    type === ShoppingToolsTypes.SHOWROOM ? 'showroom_disclaimer_tag_other' : 'build_and_price_disclaimer_tag_other',
  tags: 'showroom_disclaimer_all_tags',
  previewHeader:
    type === ShoppingToolsTypes.SHOWROOM ? 'showroom_disclaimer_preview' : 'build_and_price_disclaimer_preview',
});

const tags = {
  [MultilingualStringValue.EN]: {
    make: 'make',
    website: 'website',
    oem: 'oem',
  },
  [MultilingualStringValue.FR]: {
    make: 'marque',
    website: 'siteWeb',
    oem: 'oem',
  },
} as const;

const placeholderData = {
  make: 'Chevrolet',
  oem: 'GM',
};

interface Props extends StepFieldPreviewContents {
  /** The value of the disclaimer field. This will be used to generate a preview. Expects a Multilingual input  */
  fieldValue: MultilingualString | undefined;
  /** The type of shopping tool this disclaimer is for. */
  type: ShoppingToolsTypes;
}

const DisclaimerPreview = ({ fieldValue, language, tier, type }: Props) => {
  const lng = useMemo(() => getLocaleFromMultilingualValue(language), [language]);
  const fieldValueLanguage = fieldValue?.[language];
  const placeholderWithWebsite = useMemo(() => {
    const languageTags = tags[language];
    const disclaimerPlaceholder = { ...placeholderData, website: tier?.data?.website || 'www.example.com' };

    return Object.fromEntries(
      Object.entries<string>(languageTags).map(([key, value]) => [value, disclaimerPlaceholder[key]])
    );
  }, [language, tier?.data?.website]);

  const previewText = useMemo(
    () => (fieldValueLanguage ? replaceDislaimerTemplateTags(fieldValueLanguage, placeholderWithWebsite) : ''),
    [fieldValueLanguage, placeholderWithWebsite]
  );

  return (
    <Preview
      noteText={t(
        'disclaimer_preview_note',
        [type === ShoppingToolsTypes.SHOWROOM ? t('showroom') : t('build_and_price')],
        { lng }
      )}
      previewText={previewText}
      previewTitle={t(disclaimerLabels(type).previewHeader, undefined, { lng })}
      tags={t(disclaimerLabels(type).tags, undefined, { lng })}
      title={t(disclaimerLabels(type).tagHeader, undefined, { lng })}
    />
  );
};

export default DisclaimerPreview;
