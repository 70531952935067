import { useCallback, useState } from 'react';

import { css } from 'styled-components/macro';

import { leadModify } from 'components/sections/createModify/leads/LeadCreateModifyQuery';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import PromptDialog from 'components/ui/dialogs/PromptDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { RoutePath } from 'enums/routePath';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import { useRouter } from 'hooks/useRouter';
import { EntityType, LeadActivityType } from 'store/api/graph/interfaces/types';
import type { LeadActivityResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { RED_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props extends MenuItemProps {
  item: LeadActivityResponseType;
}

const LeadActivityMenuItems = ({ item, onUpdateArchiveStatus }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const [isMarkSpamDialogOpen, setMarkSpamDialogOpen] = useState<boolean>(false);
  const { toggleTier } = useCreateModify();
  const { hasPermissions } = useUser();
  const router = useRouter();
  const isArchived = isItemArchived(item);
  const { id, leadName: lead, type } = item || {};
  const isPurchaseType = type === LeadActivityType.PURCHASE;
  const builderType = isPurchaseType ? BuilderType.PURCHASE_ACTIVITY_MODIFY : BuilderType.LEAD_ACTIVITY_MODIFY;

  const onModifyClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: builderType,
        entityType: EntityType.LEAD_ACTIVITY,
        title: isPurchaseType ? t('modify_purchase_activity') : t('modify_lead_activity'),
        isCreating: false,
        itemId: id,
      }),
    [id, toggleTier, builderType, isPurchaseType]
  );

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled: !hasPermissions(builderConfig[builderType].requiredPermissions),
    },
    {
      label: t(lead?.spam ? 'unmark_as_spam' : 'mark_as_spam'),
      onClick: () => setMarkSpamDialogOpen(true),
      disabled: !hasPermissions(builderConfig[BuilderType.LEAD_MODIFY].requiredPermissions),
    },
    {
      label: t(isArchived ? 'unarchive' : 'archive'),
      onClick: () => onUpdateArchiveStatus?.(),
      isArchivable: false,
      disabled: !onUpdateArchiveStatus,
      titleCss: css`
        color: ${RED_500};
      `,
    },
  ];

  return (
    <>
      <MenuItems isArchived={isArchived} items={menuItemsConfig} />
      <PromptDialog
        isOpen={isMarkSpamDialogOpen}
        message={t(lead?.spam ? 'unmark_lead_activity_as_spam_message' : 'mark_lead_activity_as_spam_message')}
        onCancel={() => setMarkSpamDialogOpen(false)}
        onComplete={useCallback(
          (success: boolean) => {
            if (success) {
              setTimeout(() => {
                setMarkSpamDialogOpen(false);
                if (!lead.spam) {
                  router.replace(RoutePath.LEAD_ACTIVITIES);
                }
              }, 700);
            }
          },
          [lead, router]
        )}
        onConfirm={useCallback(
          async () =>
            client.mutate({
              mutation: leadModify,
              variables: { id: lead.id, spam: !lead?.spam },
              refetchQueries: ['LeadActivityDetailsContainerQuery', 'LeadActivityConnectionQuery'],
            }),
          [lead]
        )}
        title={t(lead?.spam ? 'unmark_as_spam' : 'mark_as_spam')}
      />
    </>
  );
};

export default LeadActivityMenuItems;
