import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import { type MultilingualToggleInputSettings, TextAreaSize } from 'components/ui/forms/shared/MultilingualToggleInput';
import { StepFieldType } from 'enums/stepFieldType';
import { RetailItemModifyParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

import { RetailItemDetailsBuilderFields } from './interfaces';

const DescriptionFields = defineDetailsFields({
  [RetailItemDetailsBuilderFields.LOCKED_FIELDS]: {
    displayType: [StepFieldDisplayType.HIDDEN],
  },
  description: {
    label: 'description',
    placeholder: 'enter_description',
    groupType: StepFieldType.RICH_TEXT,
    settings: {
      height: '400px',
    },
    shouldExpandBuilderOnEdit: true,
    clear: { field: RetailItemModifyParameter._description },
  },
  highlights: {
    label: 'highlights',
    placeholder: 'highlights',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    tooltipContents: 'highlights_tooltip',
    clear: { field: RetailItemModifyParameter._highlights },
    shouldExpandBuilderOnEdit: true,
    settings: {
      textAreaSize: TextAreaSize.SMALL,
    } as MultilingualToggleInputSettings,
  },
});

export default DescriptionFields;
