import type { HTMLAttributes, ReactNode } from 'react';

import styled, { css } from 'styled-components/macro';

import { BORDER_DEFAULT } from 'styles/color';
import { BLUE_500, NEUTRAL_0, NEUTRAL_075 } from 'styles/tokens';
import { variants } from 'utils/styledUtils';

export enum CheckboxSizes {
  REGULAR = 'regular',
  LARGE = 'large',
}

export const CheckboxInput = styled.input<HTMLAttributes<HTMLInputElement>>`
  display: none;
`;

CheckboxInput.defaultProps = {
  type: 'checkbox',
  onClick: event => event.stopPropagation(),
};

const Icon = styled.div`
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

interface CheckboxContainerProps {
  checked: boolean;
  indeterminate: boolean;
  round: boolean;
  icon: boolean;
  size?: CheckboxSizes;
  disabled?: boolean;
}

const CheckboxContainer = styled.label<CheckboxContainerProps>`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  height: 16px;
  width: 16px;
  border-radius: ${props => (props.round ? '50%' : '3px')};
  border: 1px solid
    ${props => {
      if (props.disabled) {
        return NEUTRAL_075;
      } else {
        return props.checked || props.indeterminate ? (props.icon ? 'none' : BLUE_500) : BORDER_DEFAULT;
      }
    }};
  background-color: ${props => {
    if (props.disabled) {
      return props.checked ? NEUTRAL_075 : NEUTRAL_0;
    } else {
      return (props.checked || props.indeterminate) && !props.icon ? BLUE_500 : NEUTRAL_0;
    }
  }};
  justify-content: center;
  align-items: center;
  ${({ indeterminate, checked }) =>
    !checked &&
    indeterminate &&
    ` 
      ::after {
        content: " ";
        position: relative;
        display: block;
        width: 8px;
        height: 2px;
        background-color:${NEUTRAL_0};
      }
  `}
  ${variants<CheckboxSizes>(
    'size',
    {
      [CheckboxSizes.REGULAR]: css`
        height: 16px;
        width: 16px;
      `,
      [CheckboxSizes.LARGE]: css`
        height: 20px;
        width: 20px;
      `,
    },
    CheckboxSizes.REGULAR
  )}
  flex-shrink: 0;
  position: relative;
`;

interface Props {
  checked?: boolean;
  indeterminate?: boolean;
  round?: boolean;
  icon?: ReactNode;
  onChange?: (isChecked: boolean) => void;
  size?: CheckboxSizes;
  disabled?: boolean;
  // ID used for referencing this input in tests
  testId?: string;
}

const Checkbox = ({
  disabled,
  checked = false,
  indeterminate = false,
  round = false,
  icon,
  onChange = _ => {},
  testId,
  ...props
}: Props) => (
  <CheckboxContainer
    checked={checked}
    disabled={disabled}
    icon={!!icon}
    indeterminate={indeterminate}
    round={round}
    {...props}
  >
    {!disabled && <CheckboxInput checked={checked} data-testid={testId} onChange={() => onChange(!checked)} />}
    {checked && icon && <Icon>{icon}</Icon>}
  </CheckboxContainer>
);

export default Checkbox;
