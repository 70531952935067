import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import EditColumnsIcon from 'components/ui/icons/EditColumnsIcon';
import Button, { Clickable } from 'components/ui/shared/Button';
import OutsideClick from 'components/ui/shared/OutsideClick';
import Scrollable from 'components/ui/shared/Scrollable';
import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { DIVIDER } from 'styles/color';
import { LIST_ITEM_HEIGHT } from 'styles/layouts';
import { BLUE_500, NEUTRAL_0, NEUTRAL_900 } from 'styles/tokens';
import { Z_INDEX_1, Z_INDEX_4 } from 'styles/z-index';
import { translate } from 'utils/intlUtils';
import { deferredRenderCall } from 'utils/renderingUtils';
import { hexToRGBA } from 'utils/styledUtils';

import TableFiltersListItem from './TableFiltersListItem';
import type { TableSubType, TableType } from './tableSettings/TableSettings';

const FiltersContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  position: relative;
  height: 100%;
  ${({ isOpen }) =>
    isOpen &&
    css`
      z-index: ${Z_INDEX_4};
    `};
`;

const FiltersListOverlay = styled.div`
  filter: drop-shadow(0 2px 4px ${hexToRGBA(NEUTRAL_900, '0.25')});
  position: absolute;
  top: -5px;
  left: 0;
`;

const FiltersListContainer = styled.div`
  min-width: 220px;
  min-height: 160px;
  max-height: 482px;
  height: calc(100vh - 260px);
  border-radius: 0 5px 5px 5px;
  background: ${NEUTRAL_0};
`;

const ScrollableContainer = styled.div`
  height: 100%;
  max-height: calc(100% - ${LIST_ITEM_HEIGHT});

  > div {
    position: relative;
  }
`;

const FiltersToggle = styled(Clickable)`
  height: 100%;
  position: relative;
  margin: auto;
  z-index: ${Z_INDEX_1};
`;

const FiltersToggleBackground = styled.div`
  height: 40px;
  width: 35px;
  border-radius: 5px 5px 0 0;
  background: ${NEUTRAL_0};
`;

const CloseButton = styled(Button)`
  background: ${BLUE_500};
  color: ${NEUTRAL_0};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 5px;
`;

const CloseContainer = styled.div`
  width: 100%;
  height: ${LIST_ITEM_HEIGHT};
  padding: 10px;
  border-top: ${DIVIDER} 1px solid;
`;

interface Props {
  data: TableCellData[];
  tableType: TableType;
  tableSubType?: TableSubType;
  onFiltersChanged?: (filters: TableCellData[], cell: TableCellData, isEnabling: boolean) => void;
  onOpenClose: (isOpen: boolean) => void;
}

const TableFilters = (props: Props) => {
  const { t } = translate;
  const { data, onFiltersChanged, tableType, tableSubType, onOpenClose } = useMemo(() => props, [props]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = useCallback(() => {
    setIsOpen(true);
    onOpenClose(true);
  }, [onOpenClose]);

  const toggleClose = useCallback(() => {
    setIsOpen(false);
    onOpenClose(false);
  }, [onOpenClose]);

  const onDrop = useCallback(
    (dragTarget: TableCellData, dropTarget: TableCellData) => {
      if (dropTarget.canReorder) {
        data.splice(data.indexOf(dropTarget), 0, data.splice(data.indexOf(dragTarget), 1)[0]);

        deferredRenderCall(() => {
          onFiltersChanged?.(data, dropTarget, false);
        });
      }
    },
    [data, onFiltersChanged]
  );

  const onFilterToggle = useCallback(
    (filter: TableCellData, isEnabling: boolean) => onFiltersChanged?.(data, filter, isEnabling),
    [data, onFiltersChanged]
  );

  useLayoutEffect(() => {
    toggleClose();
  }, [tableType, toggleClose]);

  return (
    <FiltersContainer isOpen={isOpen}>
      <FiltersToggle onClick={toggleOpen}>
        <EditColumnsIcon color={BLUE_500} height={24} width={24} />
      </FiltersToggle>
      {isOpen && (
        <FiltersListOverlay>
          <FiltersToggleBackground />
          <OutsideClick onClick={toggleClose}>
            <FiltersListContainer>
              <ScrollableContainer>
                <Scrollable>
                  {data.map(
                    filterOption =>
                      filterOption.resizable && (
                        <TableFiltersListItem
                          filterOption={filterOption}
                          key={filterOption.columnId}
                          onDropped={onDrop}
                          onFilterToggle={onFilterToggle}
                          tableSubType={tableSubType}
                          tableType={tableType}
                        />
                      )
                  )}
                </Scrollable>
              </ScrollableContainer>
              <CloseContainer>
                <CloseButton onClick={toggleClose}>{t('close')}</CloseButton>
              </CloseContainer>
            </FiltersListContainer>
          </OutsideClick>
        </FiltersListOverlay>
      )}
    </FiltersContainer>
  );
};

export default TableFilters;
