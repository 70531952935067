import { Component } from 'react';

import { isEqual } from 'lodash-es';
import styled from 'styled-components/macro';

import type { LocationInput } from 'store/api/graph/interfaces/types';
import { onMapsApiLoaded } from 'utils/mapUtils';

const MapContainer = styled.div`
  display: block;
  height: 150px;
  width: 300px;
  cursor: pointer;
`;

/**
 * @param {object} location - Assumes `latitude` and `longitude` are attached.
 */
export const openMapLink = location => {
  if (location) {
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`;
    window.open(mapUrl, '_blank');
  }
};

interface MapProps {
  /**
   * List of locations to be displayed on the map with markers.
   * Map will be centered around the first location in the list
   */
  locations: Partial<LocationInput>[];
  /** Whether or not clicking the map will open google maps in a new tab */
  isClickEnabled?: boolean;
  /** The className for the map container */
  className?: string;
  /** The maximum zoom level which will be displayed on the map. Default is 20 */
  maxZoom?: number;
  /** The minimum zoom level which will be displayed on the map. Default is 0  */
  minZoom?: number;
  /** The initial Map zoom level. Default is 13 */
  zoom?: number;
  /**
   * The scale factor for the marker size. You can either upscale or downscale the pin size.
   *
   * @type {number}
   * @default 1
   *
   * @example
   * // Default size (100%)
   * <Map />
   *
   * @example
   * // Increase size by 50%
   * <Map markerScale={1.5} />
   *
   * @example
   * // Decrease size by 50%
   * <Map markerScale={0.5} />
   */
  markerScale?: number;
}

class Map extends Component<MapProps> {
  map?: google.maps.Map;
  mapNode?: HTMLElement;
  mapsApi?: any; // This is actually a namespace ref google.maps which cannot be cast as a type
  mapBounds?: google.maps.LatLngBounds;
  mapMarkers: google.maps.marker.AdvancedMarkerElement[];
  heartbeat?: ReturnType<typeof setTimeout>;

  constructor(props) {
    super(props);

    this.mapMarkers = [];
  }

  componentDidUpdate(prevProps) {
    const { locations } = prevProps;
    const { locations: locationsNext } = this.props;

    const hasChanged = !isEqual(locations, locationsNext);
    const isValid = locations.every(({ latitude, longitude }) => latitude && longitude);
    const coordinates = { lat: locationsNext[0].latitude!, lng: locationsNext[0].longitude! };

    if (hasChanged && isValid && this.map) {
      this.clearMarkers();
      this.setMarkers();

      if (locations.length === 1) {
        this.map?.setCenter(coordinates);
      }
    }
  }

  onOpenMap = () => {
    const { locations, isClickEnabled } = this.props;

    if (isClickEnabled && locations.length === 1) {
      openMapLink(locations[0]);
    }
  };

  setMarkers() {
    const { locations, markerScale } = this.props;
    this.mapBounds = new this.mapsApi.LatLngBounds();

    for (const l of locations) {
      const markerSettings: google.maps.marker.AdvancedMarkerElementOptions = {
        position: new this.mapsApi.LatLng(l.latitude, l.longitude),
        map: this.map,
      };
      if (markerScale) {
        const pin: google.maps.marker.PinElement = new google.maps.marker.PinElement({
          scale: markerScale,
        });
        markerSettings.content = pin.element;
      }
      const marker: google.maps.marker.AdvancedMarkerElement = new this.mapsApi.marker.AdvancedMarkerElement(
        markerSettings
      );
      if (marker.position) {
        this.mapBounds?.extend(marker.position);
        this.mapMarkers.push(marker);
      }
    }

    if (locations.length > 1) {
      this.map?.fitBounds(this.mapBounds!);
    }
  }

  clearMarkers() {
    for (const marker of this.mapMarkers) {
      marker.map = null;
    }
    this.mapMarkers = [];
  }

  initMap = mapNode => {
    const { locations, isClickEnabled, zoom = 13, minZoom = 0, maxZoom = 20 } = this.props;
    const coordinates = { lat: locations[0].latitude, lng: locations[0].longitude };

    if (!mapNode || !coordinates.lat || !coordinates.lng) {
      this.map = undefined;
      this.mapsApi = undefined;
      this.clearMarkers();
      this.refresh(mapNode || this.mapNode);
      return;
    }

    this.mapNode = mapNode;
    onMapsApiLoaded(mapsApi => {
      this.mapsApi = mapsApi;
      this.mapBounds = new mapsApi.LatLngBounds();

      this.map = new mapsApi.Map(mapNode, {
        zoom,
        minZoom,
        maxZoom,
        center: coordinates,
        clickableIcons: false,
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        draggable: false,
        draggableCursor: isClickEnabled ? 'pointer' : 'default',
        draggingCursor: 'pointer',
        fullscreenControl: false,
        keyboardShortcuts: false,
        mapTypeControl: false,
        mapTypeId: 'roadmap',
        overviewMapControl: false,
        panControl: false,
        rotateControl: false,
        scaleControl: false,
        scrollwheel: false,
        streetViewControl: false,
        zoomControl: false,
        // TODO: [ED-11163] https://developers.google.com/maps/documentation/get-map-id
        mapId: 'DEMO_MAP_ID',
      });

      this.setMarkers();
    });
  };

  refresh(mapNode) {
    if (this.heartbeat) {
      clearTimeout(this.heartbeat);
    }

    this.heartbeat = setTimeout(() => {
      this.heartbeat = undefined;
      this.initMap(mapNode);
    }, 250);
  }

  render() {
    return (
      <MapContainer
        className={this.props.className}
        data-chromatic="ignore"
        onClick={this.onOpenMap}
        onKeyDown={() => {}}
        ref={this.initMap}
        role="presentation"
      />
    );
  }
}

export default Map;
