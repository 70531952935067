import type { MouseEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import SemiBoldText from 'components/core/typography/SemiBoldText';
import EditIcon from 'components/ui/icons/EditIcon';
import ActionsBarButton from 'components/ui/menus/ActionsBarButton';
import { BLUE_500, NEUTRAL_0, NEUTRAL_700 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const EditActionButton = styled(ActionsBarButton)<{ isActive?: boolean; isHidden?: boolean }>`
  height: 100%;

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${BLUE_500};
      fill: ${NEUTRAL_0};
      border-color: ${BLUE_500};
      ${SemiBoldText}, svg {
        color: ${NEUTRAL_0};
      }
    `}
`;

interface Props {
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement, MouseEvent>, active: boolean) => void;
  // Required for rendering purposes instead of conditional inclusion, so fallback fonts don't get triggered
  isHidden?: boolean;
  // Check if columns param is in the url
  isColumnsParamInUrl: boolean;
}

/*
 * An Edit specific ActionsBarButton that handles its active/inactive state internally for deferred rendering
 * and immediate user feedback purposes
 */
const EditButton = ({ disabled = false, onClick = e => {}, isHidden, isColumnsParamInUrl }: Props) => {
  const { t } = translate;

  // Set active class style on by default if `isColumnsParamInUrl` is true
  const [isActive, setIsActive] = useState<boolean>(!!isColumnsParamInUrl);
  const onToggle = useCallback(
    e => {
      let active;
      if (!disabled) {
        setIsActive(!isActive);
        onClick(e, active);
      }
    },
    [disabled, isActive, onClick]
  );

  /** If the edit button gets hidden then reset the active state to false, the button cannot be hidden AND active */
  useEffect(() => {
    if (isHidden) {
      setIsActive(false);
    }
  }, [isHidden]);

  return (
    <EditActionButton
      isActive={isActive}
      isHidden={isHidden}
      leftIcon={<EditIcon color={isActive ? NEUTRAL_0 : NEUTRAL_700} />}
      onClick={onToggle}
      text={t('edit')}
    />
  );
};

export default EditButton;
