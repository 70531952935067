import styled from 'styled-components/macro';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import {
  CaptureMethods,
  CommunicationPreferenceOptions,
  TradeCaptureBuilderFields,
} from 'components/sections/createModify/inventoryItems/tradeInItem/steps/interfaces';
import { tradeInItemSendAppInvite } from 'components/sections/createModify/inventoryItems/tradeInItem/TradeInItemCreateModifyQuery';
import {
  getLeadOptions,
  getRooftopOptionsForNonWhitelabelScopedUser,
  getRooftopOptionsForWhitelabelScopedUser,
} from 'components/sections/shared/ItemMetaHelpers';
import { BuilderButton } from 'components/ui/shared/Button';
import { CopyButton } from 'components/ui/shared/CopyButton';
import { SecondaryArrowPosition, TooltipStyle } from 'components/ui/shared/Tooltip';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { FeatureBundleSet } from 'enums/featureBundle';
import { ElementTestId } from 'enums/testing';
import { withTooltip } from 'hooks/withTooltip';
import type { ApiError } from 'store/api/graph/interfaces/apiErrors';
import { getApiErrors, logApiError } from 'store/api/graph/interfaces/apiErrors';
import type { Rooftop } from 'store/api/graph/interfaces/types';
import { LeadContactMethod, TradeInItemSource } from 'store/api/graph/interfaces/types';
import { BODY_TEXT } from 'styles/color';
import { NEUTRAL_050 } from 'styles/tokens';
import { isFeatureEnabledForRooftop, isGuaranteedOfferEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import {
  getStepField,
  objectToStepFieldArray,
  removeDisplayType,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { Locale, translate } from 'utils/intlUtils';

const { t } = translate;

const CopyTradeInLinkButtonRenderElement = styled(BuilderButton)`
  width: auto;
  background: ${NEUTRAL_050};

  > div {
    color: ${BODY_TEXT};
  }
`;
const CopyTradeInLinkButtonContent = props => <div {...props}>{t('copy_trade_in_link')}</div>;
const CopyTradeInLinkButtonContentWithTooltip = withTooltip(CopyTradeInLinkButtonContent);
const CopyTradeInLinkButtonChildren = ({ isCopied }) => (
  <CopyTradeInLinkButtonContentWithTooltip
    style={{ color: BODY_TEXT, verticalAlign: 'middle' }}
    title={translate.t('copy')}
    tooltip={{
      shouldShow: isCopied,
      styleVariant: TooltipStyle.CONDENSED,
      isAnimated: true,
      arrowPosition: { secondary: SecondaryArrowPosition.CENTER },
      margin: { x: 8, y: 0 },
      children: translate.t('copied'),
      width: 100,
      wrapComponent: true,
    }}
  />
);

const getDefaultRadioOptions = t =>
  [
    {
      id: CaptureMethods.SEND_TO_PHONE,
      name: t('send_to_phone_number'),
      data: { description: t('send_to_phone_message') },
      __typename: 'SelectStringOption',
    },
    {
      id: CaptureMethods.SEND_TO_LEAD,
      name: t('send_to_lead'),
      data: { description: t('send_to_lead_message') },
      __typename: 'SelectStringOption',
    },
    {
      id: CaptureMethods.CAPTURE_MANUALLY,
      name: t('capture_manually'),
      data: { description: t('capture_manually_message') },
      __typename: 'SelectStringOption',
    },
  ] as StepFieldOptions[];

const getGuaranteedOfferRadioOptions = t => {
  const extendedOptions = getDefaultRadioOptions(t);

  extendedOptions.splice(1, 0, {
    id: CaptureMethods.SEND_TO_EMAIL,
    name: t('send_to_email_address'),
    data: { description: t('send_to_email_address_message') },
    __typename: 'SelectStringOption',
  });

  return extendedOptions;
};

class CaptureStep extends StepComponentCore {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { activeStep, data, seededData },
    } = props;

    const {
      subContexts: {
        userContext: {
          user,
          isWhiteLabelScoped,
          canAccessMultipleRooftops,
          featureFlags: { rooftopPackageEnabled },
        },
      },
    } = context;

    const lead = seededData?.lead || data.lead;
    const rooftop = lead?.rooftopName || user.rooftops?.[0];

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [TradeCaptureBuilderFields.ROOFTOP]: {
        selectedValue: rooftop,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.DISABLED, active: !!lead },
          { type: StepFieldDisplayType.HIDDEN, active: !canAccessMultipleRooftops },
        ]),
      },
      [TradeCaptureBuilderFields.LEAD]: {
        selectedValue: lead,
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.HIDDEN, active: !lead }]),
      },
      // If a lead was seeded, then auto-select the continue to capture option
      [TradeCaptureBuilderFields.CAPTURE_METHOD]: {
        selectedValue: lead ? CaptureMethods.CAPTURE_MANUALLY : null,
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.HIDDEN, active: !rooftop }]),
        options: isGuaranteedOfferEnabledForRooftop(rooftop)
          ? getGuaranteedOfferRadioOptions(t)
          : getDefaultRadioOptions(t),
      },
      [TradeCaptureBuilderFields.COMMUNICATION_PREFERENCE]: {
        options: [
          {
            id: CommunicationPreferenceOptions.PHONE,
            name: t('phone'),
          },
          {
            id: CommunicationPreferenceOptions.EMAIL,
            name: t('email_address'),
          },
          {
            id: CommunicationPreferenceOptions.PHONE_AND_EMAIL,
            name: t('phone_number_and_email_address'),
          },
        ],
      },
    });

    // Async subpanel configurations
    const rooftopField = getStepField(TradeCaptureBuilderFields.ROOFTOP, this.fields);
    this.asyncConfigurations = {
      [TradeCaptureBuilderFields.ROOFTOP]: {
        request: async keyword =>
          isWhiteLabelScoped
            ? getRooftopOptionsForWhitelabelScopedUser({
                keyword,
                groupId: seededData?.userGroupId,
                features: { tradeIn: { enabled: true } },
              })
            : getRooftopOptionsForNonWhitelabelScopedUser({
                user,
                filterRooftopsByFeatureFunction: rooftop =>
                  isFeatureEnabledForRooftop({
                    rooftop,
                    feature: FeatureBundleSet.TRADE_IN,
                    featureFlagOn: rooftopPackageEnabled,
                  }),
              }),
      },
      [TradeCaptureBuilderFields.LEAD]: {
        request: keyword => getLeadOptions(rooftopField.selectedValue.id, keyword),
      },
    };
  }

  addFooterCopyTradeInLinkButton({ rooftop }: { rooftop?: Rooftop } = {}) {
    const rooftopValue = rooftop || getStepField(TradeCaptureBuilderFields.ROOFTOP, this.fields).selectedValue;
    const rooftopId = rooftop?.id || rooftopValue?.id;
    const rooftopLocale = rooftopValue?.locale?.languageTag;
    const supportedGuaranteedOfferLocales = [Locale.EN_CA, Locale.FR_CA];
    const locale = rooftopLocale && supportedGuaranteedOfferLocales.includes(rooftopLocale) ? rooftopLocale : '';

    if (!rooftopId || !isGuaranteedOfferEnabledForRooftop(rooftopValue)) {
      this.setTier({
        footerContent: null,
      });
      return;
    }

    const flowId = TradeInItemSource.GO_NON_WP3_CA;

    let subdomain = 'dev.';
    if (process.env.REACT_APP_SERVICES_ENV === 'stage') {
      subdomain = 'stage.';
    } else if (process.env.REACT_APP_SERVICES_ENV === 'prod') {
      subdomain = '';
    }

    const url = `https://app.${subdomain}findmyguaranteedoffer.com/new?rooftopId=${rooftopId}&flowId=${flowId}&locale=${locale}`;

    this.setTier({
      footerContent: (
        <CopyButton
          renderElement={CopyTradeInLinkButtonRenderElement}
          testId={ElementTestId.BUILDER_COPY_TRADE_IN_BUTTON}
          textToCopy={url}
        >
          {({ isCopied }) => <CopyTradeInLinkButtonChildren isCopied={isCopied} />}
        </CopyButton>
      ),
    });
  }

  componentDidMount(): void {
    super.componentDidMount();
    this.addFooterCopyTradeInLinkButton();
  }

  onFieldSelection(stepField: StepField, value: any, persistSeededValues = false, advance = true) {
    if (stepField.queryVar === TradeCaptureBuilderFields.ROOFTOP) {
      const radioGroup = getStepField(TradeCaptureBuilderFields.CAPTURE_METHOD, this.fields);
      const communicationPreference = getStepField(TradeCaptureBuilderFields.COMMUNICATION_PREFERENCE, this.fields);
      const captureMethod = getStepField(TradeCaptureBuilderFields.CAPTURE_METHOD, this.fields);
      removeDisplayType(radioGroup, StepFieldDisplayType.HIDDEN);
      this.addFooterCopyTradeInLinkButton({ rooftop: value });

      // Limit capture methods, and hide or show communication preference based on rooftop selection
      if (isGuaranteedOfferEnabledForRooftop(value)) {
        captureMethod.options = getGuaranteedOfferRadioOptions(t);
        // If send to lead is already selected, show communication preferenece
        if (captureMethod?.selectedValue?.id === CaptureMethods.SEND_TO_LEAD) {
          this.showField(communicationPreference);
          communicationPreference.required = true;
        }
      } else {
        this.hideField(communicationPreference);
        captureMethod.options = getDefaultRadioOptions(t);

        if (captureMethod.selectedValue?.id === CaptureMethods.SEND_TO_EMAIL) {
          const emailField = getStepField(TradeCaptureBuilderFields.EMAIL_ADDRESS, this.fields);
          captureMethod.selectedValue = null;
          this.hideField(emailField);
        }
      }
    }

    if (stepField.queryVar === TradeCaptureBuilderFields.CAPTURE_METHOD) {
      const { id } = value;
      const selectedValue = stepField.selectedValue?.id || stepField.selectedValue;
      const isDeselecting = selectedValue === id;

      if (isDeselecting) {
        this.hideField(getStepField(TradeCaptureBuilderFields.PHONE_NUMBER, this.fields));
        this.hideField(getStepField(TradeCaptureBuilderFields.LEAD, this.fields));
        this.hideField(getStepField(TradeCaptureBuilderFields.COMMUNICATION_PREFERENCE, this.fields));
        this.hideField(getStepField(TradeCaptureBuilderFields.EMAIL_ADDRESS, this.fields));
      }

      if (!isDeselecting) {
        switch (id) {
          case CaptureMethods.SEND_TO_PHONE: {
            this.showField(getStepField(TradeCaptureBuilderFields.PHONE_NUMBER, this.fields));
            this.hideField(getStepField(TradeCaptureBuilderFields.LEAD, this.fields));
            this.hideField(getStepField(TradeCaptureBuilderFields.EMAIL_ADDRESS, this.fields));
            this.hideField(getStepField(TradeCaptureBuilderFields.COMMUNICATION_PREFERENCE, this.fields));
            this.setTier({ nextButtonLabel: t('send'), activeField: TradeCaptureBuilderFields.PHONE_NUMBER });
            break;
          }

          case CaptureMethods.SEND_TO_LEAD: {
            const communicationPreference = getStepField(
              TradeCaptureBuilderFields.COMMUNICATION_PREFERENCE,
              this.fields
            );
            const rooftop = getStepField(TradeCaptureBuilderFields.ROOFTOP, this.fields).selectedValue;

            if (rooftop && isGuaranteedOfferEnabledForRooftop(rooftop)) {
              this.showField(communicationPreference);
              communicationPreference.required = true;
            }

            this.showField(getStepField(TradeCaptureBuilderFields.LEAD, this.fields));
            this.hideField(getStepField(TradeCaptureBuilderFields.PHONE_NUMBER, this.fields));
            this.hideField(getStepField(TradeCaptureBuilderFields.EMAIL_ADDRESS, this.fields));
            this.setTier({ nextButtonLabel: t('send_link'), activeField: TradeCaptureBuilderFields.LEAD });
            break;
          }

          case CaptureMethods.SEND_TO_EMAIL: {
            this.showField(getStepField(TradeCaptureBuilderFields.EMAIL_ADDRESS, this.fields));
            this.hideField(getStepField(TradeCaptureBuilderFields.PHONE_NUMBER, this.fields));
            this.hideField(getStepField(TradeCaptureBuilderFields.LEAD, this.fields));
            this.hideField(getStepField(TradeCaptureBuilderFields.COMMUNICATION_PREFERENCE, this.fields));
            this.setTier({ nextButtonLabel: t('send'), activeField: TradeCaptureBuilderFields.LEAD });
            break;
          }

          case CaptureMethods.CAPTURE_MANUALLY: {
            this.showField(getStepField(TradeCaptureBuilderFields.LEAD, this.fields));
            this.hideField(getStepField(TradeCaptureBuilderFields.PHONE_NUMBER, this.fields));
            this.hideField(getStepField(TradeCaptureBuilderFields.EMAIL_ADDRESS, this.fields));
            this.hideField(getStepField(TradeCaptureBuilderFields.COMMUNICATION_PREFERENCE, this.fields));
            this.setTier({ nextButtonLabel: t('save_and_continue'), activeField: TradeCaptureBuilderFields.LEAD });
            break;
          }
        }
      }
    }

    super.onFieldSelection(stepField, value, persistSeededValues, advance);
  }

  onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>) {
    super.onFieldChange(stepField, e, true);
  }

  hideField(stepField: StepField) {
    setDisplayTypes(
      [
        { type: StepFieldDisplayType.OMITTED, active: true },
        { type: StepFieldDisplayType.HIDDEN, active: true },
      ],
      stepField
    );
    stepField.selectedValue = undefined;
  }

  showField(stepField: StepField) {
    setDisplayTypes(
      [
        { type: StepFieldDisplayType.OMITTED, active: false },
        { type: StepFieldDisplayType.HIDDEN, active: false },
      ],
      stepField
    );
  }

  onSendAppInviteError(errors: ApiError[]) {
    logApiError(errors);
    this.setTier({ errors });
  }

  sendAppInvite = async variables => {
    const rooftop = getStepField(TradeCaptureBuilderFields.ROOFTOP, this.fields).selectedValue;

    try {
      return await this.client.mutate({
        mutation: tradeInItemSendAppInvite,
        variables: {
          rooftopId: rooftop.id,
          ...variables,
        },
      });
    } catch (error) {
      this.onSendAppInviteError(getApiErrors(error));
      // Rethrow for catching in save method
      throw new Error('sendAppInvite error');
    }
  };

  clearPrompt = (hideTier = true) => {
    const {
      tier: { activeStep },
      onTierHide,
    } = this.props;

    /* Clear the default close prompt */
    if (activeStep?.onClosePrompt === this.defaultClosePrompt) {
      this.setOnClosePrompt(undefined);
    }

    if (hideTier) {
      onTierHide();
    }
  };

  async save() {
    if (!this.validateFields()) {
      return false;
    }

    const {
      tier: { steps, activeStep },
    } = this.props;
    const leadId = getStepField(TradeCaptureBuilderFields.LEAD, this.fields).selectedValue?.id;
    const rooftop = getStepField(TradeCaptureBuilderFields.ROOFTOP, this.fields).selectedValue;
    const tradeOptionsField = getStepField(TradeCaptureBuilderFields.CAPTURE_METHOD, this.fields);
    const tradeOptions = tradeOptionsField.selectedValue?.id || tradeOptionsField.selectedValue;
    const phoneNumber = getStepField(TradeCaptureBuilderFields.PHONE_NUMBER, this.fields).selectedValue;

    /** A callback to run when saving process has completed successfully */
    let onSuccess: (() => void) | undefined;

    try {
      switch (tradeOptions) {
        case CaptureMethods.CAPTURE_MANUALLY: {
          activeStep!.isEnabled = false;

          steps!.find(step => step.id === 'VIN')!.isEnabled = true;
          this.setTier({
            steps,
            data: {
              rooftop,
              leadId,
            },
          });

          onSuccess = this.clearPrompt.bind(null, false);
          break;
        }

        case CaptureMethods.SEND_TO_PHONE: {
          await this.sendAppInvite({ phoneNumber, leadContactMethod: LeadContactMethod.SMS });
          onSuccess = this.clearPrompt;
          break;
        }

        case CaptureMethods.SEND_TO_LEAD: {
          const lead = getStepField(TradeCaptureBuilderFields.LEAD, this.fields).selectedValue;
          const communicationPreference = getStepField(TradeCaptureBuilderFields.COMMUNICATION_PREFERENCE, this.fields)
            .selectedValue?.id;

          if (!communicationPreference || communicationPreference === CommunicationPreferenceOptions.PHONE) {
            await this.sendAppInvite({
              leadId: lead.id,
              leadContactMethod: LeadContactMethod.SMS,
            });
          } else if (communicationPreference === CommunicationPreferenceOptions.EMAIL) {
            await this.sendAppInvite({
              leadId: lead.id,
              leadContactMethod: LeadContactMethod.EMAIL,
            });
          } else if (communicationPreference === CommunicationPreferenceOptions.PHONE_AND_EMAIL) {
            if (lead.phoneNumber) {
              // If lead doesn't have an email this will catch
              await this.sendAppInvite({
                leadId: lead.id,
                leadContactMethod: LeadContactMethod.EMAIL,
              });
            }

            // If lead doesn't have a phone this will catch. By this point we know an email invite has been sent.
            await this.sendAppInvite({
              leadId: lead.id,
              leadContactMethod: LeadContactMethod.SMS,
            });
          }
          onSuccess = this.clearPrompt;
          break;
        }

        case CaptureMethods.SEND_TO_EMAIL: {
          const email = getStepField(TradeCaptureBuilderFields.EMAIL_ADDRESS, this.fields).selectedValue;

          await this.sendAppInvite({ email, leadContactMethod: LeadContactMethod.EMAIL });
          onSuccess = this.clearPrompt;
          break;
        }

        default: {
          // No option was selected in the radio group, there is no specific API error for this, so we'll use our own
          this.setTier({
            errors: [
              {
                message: translate.t('select_capture_type'),
                extensions: { fields: [TradeCaptureBuilderFields.CAPTURE_METHOD] },
              } as ApiError,
            ],
          });
        }
      }

      onSuccess?.();
      return true;
    } catch {
      onSuccess = undefined;
      return false;
    }
  }
}

export default CaptureStep;
