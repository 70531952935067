import { type ComponentProps, useCallback, useMemo } from 'react';

import { css } from 'styled-components/macro';
import type { Spread } from 'type-fest';

import IconDropdown from 'components/ui/editor/components/shared/IconDropdown';
import AddIcon from 'components/ui/icons/AddIcon';
import { ElementTestId } from 'enums/testing';
import { useRichTextEditor } from 'hooks/contexts/useRichTextEditor';
import type { SelectMultilingualStringOption } from 'store/api/graph/interfaces/types';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const listContainerCss = css`
  grid-auto-rows: unset;
`;

type IconDropdownItem = ComponentProps<typeof IconDropdown>['items'][number];

export type MultilingualInsertOption = Spread<
  Pick<SelectMultilingualStringOption, 'name' | 'value'>,
  Pick<IconDropdownItem, 'icon'>
>;

type MultilingualInsertMenuProps = {
  /** The options to render in the menu list */
  options: MultilingualInsertOption[];
};

const MultilingualInsertMenu = ({ options }: MultilingualInsertMenuProps) => {
  const { editor, language } = useRichTextEditor();

  /**
   * Handle the click event for each item in the dropdown.
   */
  const handleInsertContent = useCallback(
    (text: string | undefined) => () => {
      if (!text) {
        return;
      }

      editor?.chain().focus().insertContent(text).run();
    },
    [editor]
  );

  /**
   * Create an array of objects for the dropdown items.
   */
  const insertList = useMemo(
    () =>
      options.map<IconDropdownItem>(({ icon, name, value }) => {
        const selectedValue = value[language] || undefined;

        return {
          icon,
          label: name,
          onClick: handleInsertContent(selectedValue),
        };
      }),
    [options, language, handleInsertContent]
  );

  return (
    <IconDropdown
      data-testid={ElementTestId.MULTILINGUAL_TEXT_INPUT_BUTTON_INSERT}
      icon={AddIcon}
      items={insertList}
      label={t('insert')}
      listContainerCss={listContainerCss}
      showChevron
      tooltip={{ children: t('insert_elements') }}
    />
  );
};

export default MultilingualInsertMenu;
