import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import { StepFieldType } from 'enums/stepFieldType';
import { LeadRouteModifyParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

import { LeadRoutingBuilderFields } from './interfaces';

const DetailFields = defineDetailsFields({
  [LeadRoutingBuilderFields.VLP_VDP_LEADS]: {
    label: 'vlp_vdp_leads',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
  },
  [LeadRoutingBuilderFields.VLP_VDP_ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: [LeadRoutingBuilderFields.VLP_VDP_ROOFTOP_ID, 'rooftop'],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
  },
  [LeadRoutingBuilderFields.OTHER_LEADS]: {
    label: 'all_other_leads',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
  },
  [LeadRoutingBuilderFields.OTHER_ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: 'rooftop',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    clear: { field: LeadRouteModifyParameter._rooftopId },
  },
});

export default DetailFields;
