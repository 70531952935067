import { useCallback, useMemo } from 'react';

import ImageLightBox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { usePhotoPreviewContext } from 'hooks/contexts/usePhotoPreviewContext';

const PhotoPreviewsContainer = () => {
  const {
    galleryData = { photos: undefined, currentPhotoIndex: 0 },
    setPhotoGallery,
    closePhotoGallery,
  } = usePhotoPreviewContext();
  const { photos, currentPhotoIndex } = useMemo(() => galleryData, [galleryData]);
  const updateActivePhoto = useCallback(
    currentPhotoIndex => () => setPhotoGallery({ ...galleryData, currentPhotoIndex }),
    [setPhotoGallery, galleryData]
  );

  const getSource = useCallback(photo => photo?.lightboxPhoto || photo?.gridPhoto || '', []);

  if (!photos) {
    return null;
  }

  return (
    <ImageLightBox
      mainSrc={getSource(photos[currentPhotoIndex])}
      nextSrc={getSource(photos[(currentPhotoIndex + 1) % photos.length])}
      onCloseRequest={closePhotoGallery}
      onMoveNextRequest={updateActivePhoto((currentPhotoIndex + 1) % photos.length)}
      onMovePrevRequest={updateActivePhoto((currentPhotoIndex + (photos.length - 1)) % photos.length)}
      prevSrc={getSource(photos[(currentPhotoIndex + (photos.length - 1)) % photos.length])}
    />
  );
};

export default PhotoPreviewsContainer;
