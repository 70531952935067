import { useCallback, useState } from 'react';

import styled from 'styled-components/macro';

import { InputToggleButton } from 'components/ui/shared/Button';
import { MileageUnit } from 'store/api/graph/interfaces/types';

import { InputContainer, InputSuffix } from './InputText';
import NumberInput from './NumberInput';

const MileageUnits = styled(InputSuffix)`
  pointer-events: all;
  right: 8px;
  display: flex;
  transform: translateY(-12px); /* - half height */
`;

const defaultMileage = { amount: null, unit: MileageUnit.KILOMETERS };

export interface MileageInputType {
  mileage?: {
    amount: number;
    unit: MileageUnit;
  };
  onChange?: (e: Record<'currentTarget', { value: any }>) => void;
}

const MileageInput = ({ mileage, onChange, ...props }: MileageInputType) => {
  const [mileageInput, setMileageInput] = useState({ ...defaultMileage, ...mileage });
  const setAmount = useCallback(value => setMileageInput(value), [setMileageInput]);
  const setUnit = useCallback(
    unit => {
      const value = { ...mileageInput, unit };
      setMileageInput(value);

      if (onChange) {
        onChange({ currentTarget: { value } });
      }
    },
    [mileageInput, onChange]
  );
  const onAmountChange = useCallback(
    e => {
      const value = { ...mileageInput, amount: e.currentTarget.value };
      setAmount(value);

      if (onChange) {
        onChange({ currentTarget: { value } });
      }
    },
    [mileageInput, onChange, setAmount]
  );

  return (
    <InputContainer>
      <NumberInput
        css="padding-right: 90px"
        defaultValue={mileageInput.amount === null ? undefined : mileageInput.amount}
        onChange={onAmountChange}
        {...props}
      />
      <MileageUnits>
        <InputToggleButton
          onClick={setUnit.bind(null, MileageUnit.KILOMETERS)}
          selected={mileageInput.unit === MileageUnit.KILOMETERS}
        >
          KM
        </InputToggleButton>
        <InputToggleButton
          onClick={setUnit.bind(null, MileageUnit.MILES)}
          selected={mileageInput.unit === MileageUnit.MILES}
        >
          MI
        </InputToggleButton>
      </MileageUnits>
    </InputContainer>
  );
};

export default MileageInput;
