import DynamicImport from 'components/core/DynamicImport';
import type ItemTab from 'components/ui/shared/interfaces/ItemTab';
import { TabType } from 'enums/tabType';
import type { TradeInItemResponseType } from 'store/api/graph/responses/responseTypes';
import { formatYMMT } from 'utils/formatting/inventoryItemFormatUtils';

export const tradeInItemsDetailsTabs = [
  {
    tabId: TabType.DETAILS,
    component: DynamicImport(() => import('components/sections/inventoryItems/tradeInItems/TradeInItemDetailsTab')),
  },
  {
    tabId: TabType.TRADE_IN_VALUE,
    component: DynamicImport(() => import('components/sections/inventoryItems/tradeInItems/TradeInItemValueTab')),
    label: 'value_one',
  },
  {
    tabId: TabType.MEDIA,
    component: DynamicImport(() => import('components/sections/inventoryItems/shared/InventoryItemMediaTab')),
  },
  {
    tabId: TabType.LINKED,
    component: DynamicImport(() => import('components/sections/inventoryItems/tradeInItems/TradeInItemLinkedTab')),
  },
] satisfies ItemTab[];

export const getTradeInItemDetailsTitle = (tradeInItem: TradeInItemResponseType) => formatYMMT(tradeInItem);
