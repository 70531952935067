import 'styled-components/macro';
import { memo } from 'react';

import { get } from 'lodash-es';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { RetailItemBadges } from 'components/sections/inventoryItems/retailItems/RetailItemBadges';
import RetailItemPurchasePriceSection from 'components/sections/inventoryItems/retailItems/RetailItemPurchasePriceSection';
import { ItemColorPreview } from 'components/sections/shared/ItemColorPreview';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import Image from 'components/ui/images/Images';
import { ListItem, ListItemDetails, ListItemImageContainer, ListItemLabel } from 'components/ui/layouts/ListItem';
import { CompletionPercentage } from 'components/ui/shared/Badge';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { ImageType } from 'enums/imageType';
import type { RetailItemResponseType } from 'store/api/graph/responses/responseTypes';
import { formatInventoryItemDetails, formatStockNumber, formatYMMT } from 'utils/formatting/inventoryItemFormatUtils';
import { formatAmount } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';
import { joinStrings } from 'utils/stringUtils';

import { PrimaryArrowPosition, SecondaryArrowPosition } from '../shared/Tooltip';

import type { ListItemProps } from './interfaces/ListItemProps';

const getAttribute = (retailItem: RetailItemResponseType, vehicleAttribute = '', motorcycleAttribute = '') =>
  get(retailItem, vehicleAttribute, get(retailItem, motorcycleAttribute));
const { VEHICLE_EXTERIOR_COLOR, VEHICLE_MILEAGE, MOTORCYCLE_COLOR, MOTORCYCLE_MILEAGE, PURCHASE_PRICE } = InventoryItem;

interface RetailListItemProps extends ListItemProps, RetailItemResponseType {
  isHighlighted?: boolean;
}

export const RetailListItem = memo(
  ({ suffixIcon, listItemType, metadata, isHighlighted, ...retailItem }: RetailListItemProps) => {
    const exteriorColor = getAttribute(retailItem, VEHICLE_EXTERIOR_COLOR, MOTORCYCLE_COLOR);
    const mileage = formatAmount(getAttribute(retailItem, VEHICLE_MILEAGE, MOTORCYCLE_MILEAGE));
    const ymmt = formatYMMT(retailItem);
    const details = formatInventoryItemDetails(retailItem);
    const vinAndStockNumber = joinStrings([retailItem.vin, formatStockNumber(retailItem.stockNumber)], ' ');
    const imageSrc = get(retailItem, 'primaryPhoto.listPhoto');
    const purchasePrice = get(retailItem, PURCHASE_PRICE);

    return (
      <ListItem
        isArchived={isItemArchived(retailItem)}
        isHighlighted={isHighlighted}
        listItemType={listItemType}
        suffixIcon={suffixIcon}
      >
        <section>
          <ListItemImageContainer>
            <Image src={imageSrc} type={ImageType.INVENTORY_ITEM} />
            <ListItemLabel hasImage={!!imageSrc}>{translate.t('retail')}</ListItemLabel>
          </ListItemImageContainer>
          <CompletionPercentage value={retailItem.completePercent} />
        </section>
        <ListItemDetails css="text-align: left;">
          <PrimaryText title={ymmt}>{ymmt}</PrimaryText>
          {(purchasePrice || mileage) && (
            <TextRow>
              <RetailItemPurchasePriceSection
                retailItem={retailItem}
                showWarningTooltip
                tooltipPosition={{ primary: PrimaryArrowPosition.LEFT, secondary: SecondaryArrowPosition.CENTER }}
              />
              {purchasePrice && mileage && <SecondaryText>&bull;</SecondaryText>}
              <SecondaryText>{mileage}</SecondaryText>
            </TextRow>
          )}
          {details && (
            <TextRow>
              {exteriorColor && (
                <ItemColorPreview color={exteriorColor} css="transform: translateY(-1px)" metadata={metadata} />
              )}
              <SecondaryText css="text-transform: capitalize;" title={details}>
                {details}
              </SecondaryText>
            </TextRow>
          )}
          <SecondaryText title={vinAndStockNumber}>{vinAndStockNumber}</SecondaryText>
          <RetailItemBadges retailItem={retailItem} />
        </ListItemDetails>
      </ListItem>
    );
  }
);
