import { RooftopIntegrationDetailsFields } from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import { RooftopIntegration } from 'enums/columns/rooftopIntegration';
import { RooftopIntegrationAttributesPointer } from 'enums/rooftopIntegrationAttributesPointer';
import { OemAccountExportRooftopIntegrationAttributesInputParameter } from 'store/api/graph/interfaces/types';
import { defineDetailsFields } from 'utils/formatting/detailsFieldsUtils';

const URLFields = defineDetailsFields({
  [RooftopIntegrationDetailsFields.ABOUT_US_URL]: {
    label: 'about_us',
    placeholder: 'about_us',
    queryAlias: RooftopIntegration.ABOUT_US_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._aboutUsUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.CONTACT_US_URL]: {
    label: 'contact_us',
    placeholder: 'contact_us',
    queryAlias: RooftopIntegration.CONTACT_US_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._contactUsUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.ALL_INVENTORY_URL]: {
    label: 'all_inventory',
    placeholder: 'all_inventory',
    queryAlias: RooftopIntegration.ALL_INVENTORY_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._allInventoryUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.NEW_INVENTORY_URL]: {
    label: 'new_inventory',
    placeholder: 'new_inventory',
    queryAlias: RooftopIntegration.NEW_INVENTORY_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._newInventoryUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.USED_INVENTORY_URL]: {
    label: 'used_inventory',
    placeholder: 'used_inventory',
    queryAlias: RooftopIntegration.USED_INVENTORY_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._usedInventoryUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.CPO_INVENTORY_URL]: {
    label: 'cpo_inventory',
    placeholder: 'cpo_inventory',
    queryAlias: RooftopIntegration.CPO_INVENTORY_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._cpoInventoryUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.SERVICE_URL]: {
    label: 'service',
    placeholder: 'service',
    queryAlias: RooftopIntegration.SERVICE_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._serviceUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.SERVICE_MENU_URL]: {
    label: 'service_menu',
    placeholder: 'service_menu',
    queryAlias: RooftopIntegration.SERVICE_MENU_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._serviceMenuUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.SERVICE_APPOINTMENT_URL]: {
    label: 'service_appointment',
    placeholder: 'service_appointment',
    queryAlias: RooftopIntegration.SERVICE_APPOINTMENT_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._serviceAppointmentUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.TIRE_FINDER_URL]: {
    label: 'tire_finder',
    placeholder: 'tire_finder',
    queryAlias: RooftopIntegration.TIRE_FINDER_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._tireFinderUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.TIRE_REQUEST_QUOTE_URL]: {
    label: 'tire_request_a_quote',
    placeholder: 'tire_request_a_quote',
    queryAlias: RooftopIntegration.TIRE_REQUEST_QUOTE_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._tireRequestQuoteUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.PARTS_REQUEST_URL]: {
    label: 'parts_request',
    placeholder: 'parts_request',
    queryAlias: RooftopIntegration.PARTS_REQUEST_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._partsRequestUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.PERFORMANCE_PARTS_URL]: {
    label: 'performance_parts',
    placeholder: 'performance_parts',
    queryAlias: RooftopIntegration.PERFORMANCE_PARTS_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._performancePartsUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.ACCESSORIES_URL]: {
    label: 'accessories',
    placeholder: 'accessories',
    queryAlias: RooftopIntegration.ACCESSORIES_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._accessoriesUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.MAP_DIRECTIONS_URL]: {
    label: 'map_directions',
    placeholder: 'map_directions',
    queryAlias: RooftopIntegration.MAP_DIRECTIONS_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._mapDirectionsUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.SPECIALS_URL]: {
    label: 'specials',
    placeholder: 'specials',
    queryAlias: RooftopIntegration.SPECIALS_URL,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._specialsUrl,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
});

export default URLFields;
